import { doc, onSnapshot } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { db } from '../../../config/firebase'
import {
  FormMedicalRecord,
  FormNonWebEnableForm,
  FormWebEnableForm,
} from '../Components'

function FillableView() {
  const params = useParams()
  const [formView, setFormView] = useState()
  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, 'Form', params.id), snapShot => {
      if (snapShot.exists) {
        setFormView({
          ...snapShot.data(),
          id: snapShot.id,
          startOneDate: snapShot.data()?.startOneDate
            ? snapShot.data()?.startOneDate?.toDate()?.toLocaleString('en-US')
            : '',
          endOneDate: snapShot.data()?.endOneDate
            ? snapShot.data()?.endOneDate?.toDate()?.toLocaleString('en-US')
            : '',

          startDateRecords: snapShot.data()?.startDateRecords
            ? snapShot
                .data()
                ?.startDateRecords?.toDate()
                ?.toLocaleString('en-US')
            : '',
          endDateRecords: snapShot.data()?.endDateRecords
            ? snapShot.data()?.endDateRecords?.toDate()?.toLocaleString('en-US')
            : '',
        })
      }
    })
    return () => {
      unsubscribe()
      setFormView(null)
    }
  }, [params.id])
  return (
    <>
      {formView?.type === 'WebEnableForm' && (
        <>
          <FormWebEnableForm formView={formView} />
        </>
      )}
      {formView?.type === 'NonWebEnableForm' && (
        <>
          <FormNonWebEnableForm formView={formView} />
        </>
      )}
      {formView?.type === 'MedicalRecord' && (
        <>
          <FormMedicalRecord formView={formView} />
        </>
      )}
    </>
  )
}

export default FillableView
