import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Center,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Heading,
  HStack,
  Input,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBreakpoint,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'
import SignatureCanvas from 'react-signature-canvas'
import React, { useRef, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { addDoc, collection } from 'firebase/firestore'
import { db } from '../../config/firebase'
import { SwitchInputComponent } from '../../utils'
import { FaRegEdit } from 'react-icons/fa'
import { resizeFile, switchCanvas } from './InfectionsAndEmergencyVisitForm'

export const tbHighRiskQuestion = [
  'Contact with persons with diagnosed TB?',
  'HIV Infection?',
  'Recent (within 5 yrs) immigrant from a country With high rates of TB?',
  'Intravenous drug abuse?',
  'Radiographic findings consistent with prior TB?',
  'Carcinoma of head, neck, or lungs?',
  'Health care worker with exposure to TB?',
  'Recent TB infection (conversion of TB skin test Within last 2 years)?',
  'Silicosis?',
  'Diabetes Mellitus?',
  'Chronic Renal Failure/ Dialysis',
  'Gastrectomy?',
  'Solid organ transplant?',
]

export default function TBHighRiskForm({ user }) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    reset,
    watch,
    setValue,
    trigger,
  } = useForm({
    defaultValues: {
      form: {
        name: user?.fullName || '',
      },
    },
  })
  const toast = useToast()
  const breakPoint = useBreakpoint()
  const [loading, setLoading] = useState(false)
  const { isOpen, onToggle, onClose } = useDisclosure()

  const sigCanvas = useRef({})
  const fileUploadInputRef = useRef(null)

  const signatureCollector = watch('form.signatureCollector') || ''

  const clear = () => sigCanvas.current.clear()
  const save = () =>
    setValue(
      'form.signatureCollector',
      sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')
    )

  async function onUploadChange(event) {
    const file = event.target?.files[0]

    if (
      file?.type === 'image/jpg' ||
      file?.type === 'image/jpeg' ||
      file?.type === 'image/png'
    ) {
      const image = await resizeFile(file)
      setValue('form.signatureCollector', image)
      toast({
        duration: 3000,
        title: 'successfully uploaded.',
        status: 'success',
        position: 'top',
        isClosable: true,
      })
      onClose()
    } else {
      toast({
        duration: 3000,
        title: 'only file .jpg, jpeg and .png are supported.',
        status: 'info',
        position: 'top',
        isClosable: true,
      })
    }
  }

  function onClickFileUpload() {
    fileUploadInputRef && fileUploadInputRef.current.click()
  }

  const onSubmit = ({ form }) => {
    try {
      setLoading(true)
      addDoc(collection(db, 'EmployeeForm'), {
        ...form,
        createdAt: new Date(),
        notification: true,
        status: 'Pending',
        type: 'TB High Risk',
      })
      setLoading(false)
      setValue('form.signatureCollector', '')
      reset()
      toast({
        title: 'Submit Successfully',
        description: 'Form has been submmited.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch (error) {
      toast({
        title: 'Submit Failed',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
      setLoading(false)
    }
  }

  return (
    <Container maxW="100%" mt="5" mb="10">
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
        bg="gray.100"
        p="1.5"
        mt="5"
        borderRadius="0.3em"
      >
        <BreadcrumbItem>
          <BreadcrumbLink>TB High Risk Questionnaire Form</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex
          flexDirection={['column', 'column', 'row', 'row', 'row', 'row']}
          mt={5}
          w="100%"
          gap={6}
        >
          <FormControl isInvalid={errors?.form?.name ?? false} isRequired>
            <FormLabel>Employee Name</FormLabel>
            <Input
              placeholder={'Name'}
              name="form.name"
              ref={register({
                required: true,
              })}
            />
          </FormControl>
          <FormControl
            id="date"
            mb="5"
            isRequired
            isInvalid={errors?.form?.date ?? false}
          >
            <FormLabel>Date</FormLabel>
            <Input
              name="form.date"
              type="date"
              placeholder="Select Date"
              ref={register({
                required: true,
              })}
            />
          </FormControl>
        </Flex>
        <VStack alignItems="start" mb={5}>
          <Heading as="h5" size="sm">
            Targeted TB Testing will only be done if any of the following
            questions are answered “Yes”
          </Heading>
          <Heading as="h5" size="sm">
            Does the employee have any of the following risk factors/Conditions?
          </Heading>
        </VStack>
        {tbHighRiskQuestion.map((item, index) => {
          return (
            <FormControl
              mb="5"
              width={['100%', '100%', '100%', '80%']}
              key={index}
              isInvalid={errors?.form?.answers && errors?.form.answers[index]}
            >
              <FormLabel>{item}</FormLabel>
              <Controller
                name={`form.answers[${index}]`}
                control={control}
                rules={{ required: 'Please select answer' }}
                defaultValue={''}
                render={field => (
                  <RadioGroup {...field}>
                    <Stack>
                      <Radio value="yes">Yes</Radio>
                      <Radio value="no">No</Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
              <FormErrorMessage>
                {errors?.form?.answers && errors?.form?.answers[index]?.message}
              </FormErrorMessage>
            </FormControl>
          )
        })}
        <Heading as="h5" size="sm">
          The above recommendations for targeted TB testing were issued by the
          US Public Health Service Advisory Council on Elimination of
          Tuberculosis. If the employee falls into any of the above risk groups,
          TB testing is indicated and treatment for Latent TB infection if shown
          to be present will be offered.
        </Heading>
        <FormControl
          w="30vw"
          isInvalid={errors?.form?.signatureCollector || false}
        >
          <Controller
            name="form.signatureCollector"
            control={control}
            rules={{ required: 'Please sign your signature' }}
            defaultValue={''}
            render={({ field }) => {
              return (
                <GridItem {...field}>
                  <VStack spacing="0px" justifyContent={'flex-start'}>
                    <Center h={{ base: '80px' }}>
                      <Text fontWeight="bold" fontSize="15" textAlign="center">
                        Employee Signature
                      </Text>
                    </Center>
                    <HStack
                      h="40px"
                      w="100%"
                      flexDirection="row"
                      borderBottom={'1px solid #777'}
                    >
                      <Center w="100%">
                        <SwitchInputComponent data={signatureCollector} />
                      </Center>
                      <Flex
                        h="40px"
                        alignItems="flex-end"
                        justifyContent="flex-end"
                      >
                        <Popover
                          placement="top"
                          isOpen={isOpen}
                          onClose={onClose}
                        >
                          <PopoverTrigger p="0px">
                            <Button
                              bg="white"
                              onClick={() => {
                                setValue('form.signatureCollector', ' ')
                                onToggle()
                              }}
                            >
                              <FaRegEdit size="25" color="gray" />
                            </Button>
                          </PopoverTrigger>
                          <Portal size="auto">
                            <PopoverContent
                              w={{
                                base: '20rem',
                                sm: '20rem',
                                md: '30rem',
                              }}
                              h="auto"
                              boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                            >
                              <PopoverArrow />
                              <Box w="100%" h="100%">
                                <Text p="0.75rem" fontSize="12px" color="grey">
                                  <span style={{ color: 'red' }}>{`*`}</span>
                                  {`Any signature (including any electronic symbol
                                or process attached to, or associated with, a
                                contract or other record and adopted by a Person
                                with the intent to sign, authenticate or accept
                                such contract or record)`}
                                </Text>

                                <Box p="0.75rem" pt="1rem" h="auto">
                                  <Tabs variant="enclosed">
                                    <TabList>
                                      <Tab
                                        onClick={() => {
                                          setValue(
                                            'form.signatureCollector',
                                            ' '
                                          )
                                        }}
                                      >
                                        Draw Signature
                                      </Tab>
                                      <Tab
                                        onClick={() => {
                                          setValue(
                                            'form.signatureCollector',
                                            ' '
                                          )
                                          clear()
                                        }}
                                      >
                                        Upload Signature
                                      </Tab>
                                    </TabList>
                                    <TabPanels h="auto">
                                      <TabPanel
                                        h="22rem"
                                        display="flex"
                                        flexDirection="column"
                                      >
                                        <Box
                                          h="100%"
                                          border="1px solid #F0F0F0"
                                        >
                                          <SignatureCanvas
                                            style={{
                                              backgroundColor: 'red',
                                            }}
                                            penColor="black"
                                            canvasProps={{
                                              width: switchCanvas(
                                                breakPoint,
                                                'width'
                                              ),
                                              height: switchCanvas(
                                                breakPoint,
                                                'height'
                                              ),
                                            }}
                                            ref={sigCanvas}
                                          />
                                          <Flex
                                            pl="10px"
                                            pb="5px"
                                            pr="10px"
                                            justifyContent="space-between"
                                            h="max-content"
                                            alignItems="end"
                                          >
                                            <Center>
                                              <Button
                                                fontSize="14px"
                                                px="1rem"
                                                h="1.7rem"
                                                colorScheme="blackAlpha"
                                                onClick={() => clear()}
                                              >
                                                Clear
                                              </Button>
                                            </Center>
                                            <Button
                                              mt={4}
                                              h="1.8rem"
                                              fontSize="14px"
                                              w="4.5rem"
                                              bg="#17A2B8"
                                              color="white"
                                              colorScheme="cyan"
                                              onClick={async () => {
                                                let triggerValue =
                                                  await trigger(
                                                    'form.signatureCollector'
                                                  )
                                                if (triggerValue) {
                                                  onClose()
                                                  save()
                                                } else {
                                                }
                                              }}
                                            >
                                              Confirm
                                            </Button>
                                          </Flex>
                                        </Box>
                                      </TabPanel>
                                      <TabPanel
                                        h="22rem"
                                        display="flex"
                                        flexDirection="column"
                                      >
                                        <Center h="100%" w="100%">
                                          <Input
                                            type="file"
                                            display="none"
                                            ref={fileUploadInputRef}
                                            onChange={onUploadChange}
                                          />
                                          <Button
                                            color="#AEAEAE"
                                            border="2px solid #AEAEAE"
                                            bg="transparent"
                                            _hover={{
                                              bg: '#AEAEAE',
                                              color: '#fff',
                                              opacity: '0.5',
                                            }}
                                            _active={{
                                              bg: '#AEAEAE',
                                              color: '#fff',
                                              opacity: '1',
                                            }}
                                            onClick={onClickFileUpload}
                                          >
                                            Upload
                                          </Button>
                                        </Center>
                                        <Flex
                                          pl="10px"
                                          pb="5px"
                                          pr="10px"
                                          justifyContent="flex-end"
                                          h="max-content"
                                          alignItems="end"
                                        >
                                          <Button
                                            mt={4}
                                            h="1.8rem"
                                            fontSize="14px"
                                            w="4.5rem"
                                            bg="#17A2B8"
                                            color="white"
                                            colorScheme="cyan"
                                            onClick={async () => {
                                              let triggerValue = await trigger(
                                                'form.signaturePatient'
                                              )
                                              if (triggerValue) {
                                                onClose()
                                              } else {
                                              }
                                            }}
                                          >
                                            Confirm
                                          </Button>
                                        </Flex>
                                      </TabPanel>
                                    </TabPanels>
                                  </Tabs>
                                </Box>
                              </Box>
                            </PopoverContent>
                          </Portal>
                        </Popover>
                      </Flex>
                    </HStack>
                  </VStack>
                </GridItem>
              )
            }}
          />
          <FormErrorMessage>
            {errors?.form?.signatureCollector &&
              errors?.form?.signatureCollector.message}
          </FormErrorMessage>
        </FormControl>
        <Center>
          <Button
            my={'10'}
            type="submit"
            colorScheme={'blue'}
            isLoading={loading}
          >
            Submit
          </Button>
        </Center>
      </form>
    </Container>
  )
}
