import { Box, useStyleConfig } from '@chakra-ui/react'
import React from 'react'

function CardContent(props) {
  const { variant, children, ...rest } = props
  const styles = useStyleConfig('Card', { variant })
  return (
    <Box
      boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
      p={{
        base: '20px 20px 20px 20px',
        sm: '60px 50px 50px 50px',
        md: '60px 50px 50px 50px',
        lg: '60px 50px 50px 50px',
        xl: '60px 50px 50px 50px',
      }}
      w={{
        base: '95%',
        sm: '80%',
        md: '60%',
        lg: '60%',
        xl: '60%',
        '2xl': '70rem',
      }}
      bg={{ base: 'white', sm: 'white', md: 'white', lg: 'white', xl: 'white' }}
      borderRadius="30px"
      {...rest}
      __css={styles}
    >
      {children}
    </Box>
  )
}

export { CardContent }
