import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Container,
  Center,
  Heading,
  Icon,
  Stack,
  Badge,
  SimpleGrid,
} from '@chakra-ui/react'
import {
  BsCollection,
  BsCalendar,
  BsFillPeopleFill,
  BsFillKanbanFill,
} from 'react-icons/bs'
import { BiSupport } from 'react-icons/bi'
import { GrCertificate, GrDocumentText } from 'react-icons/gr'
import { RiCalendarEventLine } from 'react-icons/ri'
import { collection, query, where, onSnapshot } from 'firebase/firestore'
import { db } from '../../config/firebase'
import roleFilters from '../../config/roleFilters'

function Dashboard({ user }) {
  const history = useHistory()
  const [ticketNotification, setTicketNotification] = useState([])
  const [employeeFormNotification, setEmployeeFromNotification] = useState([])
  const [appointmentRequest, setAppointmentRequest] = useState([])

  useEffect(() => {
    const queryTickets = query(
      collection(db, 'Tickets'),
      where('notification', '==', true)
    )
    const unsubTickets = onSnapshot(queryTickets, querySnapshot => {
      const tickets = []
      querySnapshot.forEach(doc => {
        tickets.push(doc.data())
      })
      setTicketNotification(tickets)
    })

    const queryEmployeeForm = query(
      collection(db, 'EmployeeForm'),
      where('notification', '==', true)
    )
    const unsubEmplotyeeForm = onSnapshot(queryEmployeeForm, querySnapshot => {
      const employeeForm = []
      querySnapshot.forEach(form => {
        employeeForm.push(form)
      })
      setEmployeeFromNotification(employeeForm)
    })

    const queryAppointment = query(
      collection(db, 'Appointment'),
      where('notification', '==', true)
    )
    const unsubAppointment = onSnapshot(queryAppointment, querySnapshot => {
      const appointment = []
      querySnapshot.forEach(request => {
        appointment.push(request)
      })
      setAppointmentRequest(appointment)
    })

    return () => {
      unsubTickets()
      unsubEmplotyeeForm()
      unsubAppointment()
    }
  }, [])

  return (
    <>
      {user.roles ? (
        <Box minH="100vh">
          <Container maxW="100%" mt="10" mb="10">
            <SimpleGrid columns={[1, 1, 3, 3]} spacing="40px">
              {roleFilters(user.roles, 'Scheduler') && (
                <Box
                  cursor="pointer"
                  onClick={() => history.push('/employee-form')}
                  p="4"
                  mt={['5', '5', '0', '0']}
                  h="200px"
                  borderWidth="1px"
                  borderRadius="lg"
                  overflow="hidden"
                >
                  <Center h="100%">
                    <Stack direction="column" w="100%" align="center">
                      <Icon as={BsCollection} fontSize="30px" />
                      <Heading size="sm">Employee Form</Heading>
                      &nbsp;&nbsp;
                      {roleFilters(user.roles, 'Scheduler') &&
                        employeeFormNotification.length > 0 && (
                          <Badge colorScheme="red" borderRadius="md">
                            {employeeFormNotification.length}
                          </Badge>
                        )}
                    </Stack>
                  </Center>
                </Box>
              )}

              {roleFilters(user.roles, 'Patient Coordinator') && (
                <Box
                  cursor="pointer"
                  onClick={() => history.push('/appointment')}
                  p="4"
                  mt={['5', '5', '0', '0']}
                  h="200px"
                  borderWidth="1px"
                  borderRadius="lg"
                  overflow="hidden"
                >
                  <Center h="100%">
                    <Stack direction="column" w="100%" align="center">
                      <Icon as={BsCalendar} fontSize="30px" />
                      <Heading size="sm">Appointment</Heading>
                      &nbsp;&nbsp;
                      {appointmentRequest.length > 0 && (
                        <Badge colorScheme="red" borderRadius="md">
                          {appointmentRequest.length}
                        </Badge>
                      )}
                    </Stack>
                  </Center>
                </Box>
              )}
              <Box
                cursor="pointer"
                onClick={() => history.push('/schedule')}
                p="4"
                mt={['5', '5', '0', '0']}
                h="200px"
                borderWidth="1px"
                borderRadius="lg"
                overflow="hidden"
              >
                <Center h="100%">
                  <Stack direction="column" w="100%" align="center">
                    <Icon as={BsFillKanbanFill} fontSize="30px" />
                    <Heading size="sm">Schedule</Heading>
                  </Stack>
                </Center>
              </Box>
              <Box
                cursor="pointer"
                onClick={() =>
                  history.push(
                    `${
                      roleFilters(user.roles, 'IT Staff')
                        ? 'tickets'
                        : 'help-desk'
                    }`
                  )
                }
                p="4"
                mt={['5', '5', '0', '0']}
                h="200px"
                borderWidth="1px"
                borderRadius="lg"
                overflow="hidden"
              >
                <Center h="100%">
                  <Stack direction="column" w="100%" align="center">
                    <Icon as={BiSupport} fontSize="30px" />
                    <Heading size="sm">
                      {roleFilters(user.roles, 'IT Staff')
                        ? 'Tickets'
                        : 'Help desk'}
                    </Heading>
                    &nbsp;&nbsp;
                    {roleFilters(user.roles, 'IT Staff') &&
                      ticketNotification.length > 0 && (
                        <Badge colorScheme="red" borderRadius="md">
                          {ticketNotification.length}
                        </Badge>
                      )}
                  </Stack>
                </Center>
              </Box>
              {roleFilters(user.roles, 'Admin') && (
                <Box
                  cursor="pointer"
                  onClick={() => history.push('/staff')}
                  p="4"
                  mt={['5', '5', '0', '0']}
                  h="200px"
                  borderWidth="1px"
                  borderRadius="lg"
                  overflow="hidden"
                >
                  <Center h="100%">
                    <Stack direction="column" w="100%" align="center">
                      <Icon as={BsFillPeopleFill} fontSize="30px" />
                      <Heading size="sm">Staff</Heading>
                    </Stack>
                  </Center>
                </Box>
              )}
              {roleFilters(user.roles, 'Credentialing') && (
                <Box
                  cursor="pointer"
                  onClick={() => history.push('/credentialing')}
                  p="4"
                  mt={['5', '5', '0', '0']}
                  h="200px"
                  borderWidth="1px"
                  borderRadius="lg"
                  overflow="hidden"
                >
                  <Center h="100%">
                    <Stack direction="column" w="100%" align="center">
                      <Icon as={GrCertificate} fontSize="30px" />
                      <Heading size="sm">Credentialing</Heading>
                    </Stack>
                  </Center>
                </Box>
              )}
              {roleFilters(user.roles, 'Event') && (
                <Box
                  cursor="pointer"
                  onClick={() => history.push('/event')}
                  p="4"
                  mt={['5', '5', '0', '0']}
                  h="200px"
                  borderWidth="1px"
                  borderRadius="lg"
                  overflow="hidden"
                >
                  <Center h="100%">
                    <Stack direction="column" w="100%" align="center">
                      <Icon as={RiCalendarEventLine} fontSize="30px" />
                      <Heading size="sm">Event</Heading>
                    </Stack>
                  </Center>
                </Box>
              )}

              {roleFilters(user.roles, 'Infections And Emergency') && (
                <Box
                  cursor="pointer"
                  onClick={() =>
                    history.push('/infections-and-emergency-visit')
                  }
                  p="4"
                  mt={['5', '5', '0', '0']}
                  h="200px"
                  borderWidth="1px"
                  borderRadius="lg"
                  overflow="hidden"
                >
                  <Center h="100%">
                    <Stack direction="column" w="100%" align="center">
                      <Icon as={BsCollection} fontSize="30px" />
                      <Heading size="sm">Infections And Emergency Form</Heading>
                      {/* &nbsp;&nbsp;
                    {roleFilters(user.roles, 'Scheduler') &&
                      employeeFormNotification.length > 0 && (
                        <Badge colorScheme="red" borderRadius="md">
                          {employeeFormNotification.length}
                        </Badge>
                      )} */}
                    </Stack>
                  </Center>
                </Box>
              )}

              {roleFilters(user.roles, 'Patient Form') && (
                <Box
                  cursor="pointer"
                  onClick={() => history.push('/fillable')}
                  p="4"
                  mt={['5', '5', '0', '0']}
                  h="200px"
                  borderWidth="1px"
                  borderRadius="lg"
                  overflow="hidden"
                >
                  <Center h="100%">
                    <Stack direction="column" w="100%" align="center">
                      <Icon as={GrDocumentText} fontSize="30px" />
                      <Heading size="sm">Patient Form</Heading>
                      {/* <Heading size="sm">Fillable Form</Heading> */}
                    </Stack>
                  </Center>
                </Box>
              )}

              {roleFilters(user.roles, 'Questionnaire') && (
                <Box
                  cursor="pointer"
                  onClick={() => history.push('/patient')}
                  p="4"
                  mt={['5', '5', '0', '0']}
                  h="200px"
                  borderWidth="1px"
                  borderRadius="lg"
                  overflow="hidden"
                >
                  <Center h="100%">
                    <Stack direction="column" w="100%" align="center">
                      <Icon as={BsFillPeopleFill} fontSize="30px" />
                      <Heading size="sm">Questionnaire</Heading>
                      {/* <Heading size="sm">Patient Form</Heading> */}
                    </Stack>
                  </Center>
                </Box>
              )}

              {roleFilters(user.roles, 'Peer Review') && (
                <Box
                  cursor="pointer"
                  onClick={() => history.push('/peer-review')}
                  p="4"
                  mt={['5', '5', '0', '0']}
                  h="200px"
                  borderWidth="1px"
                  borderRadius="lg"
                  overflow="hidden"
                >
                  <Center h="100%">
                    <Stack direction="column" w="100%" align="center">
                      <Icon as={BsFillPeopleFill} fontSize="30px" />
                      <Heading size="sm">Peer Review Form</Heading>
                    </Stack>
                  </Center>
                </Box>
              )}
            </SimpleGrid>
          </Container>
        </Box>
      ) : (
        <Box></Box>
      )}
    </>
  )
}

export default Dashboard
