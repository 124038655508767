import React, { useEffect } from 'react'
import {
  Center,
  Box,
  Text,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Textarea,
  Stack,
  CheckboxGroup,
  Grid,
  VStack,
  Checkbox,
  GridItem,
  Flex,
} from '@chakra-ui/react'
import { CardContent } from '../../../../../../Components/Card/card'
import { Controller, useForm } from 'react-hook-form'
import moment from 'moment'
import { SwitchInputComponent } from '../../../switchInput'
import { totalNonWebEnabledPage } from '../..'

const _ = require('underscore')

function SecondSignatureCancellation({ formView }) {
  const {
    // handleSubmit,
    // reset,
    // register,
    // errors,
    control,
    setValue,
    // clearErrors,
  } = useForm()

  useEffect(() => {
    if (formView) {
      const data = formView
      _.keys(data).map(e => {
        return setValue(e, data[e])
      })
    }
  }, [formView, setValue])
  return (
    <CardContent>
      <Box w="100%">
        <Text>
          This authorization must be filled-out, dated and signed by the patient
          or by a person authorized by law to give authorization.
        </Text>

        <Box pt="20px">
          <Controller
            name="authorize"
            control={control}
            defaultValue=""
            render={props => (
              <FormControl>
                <FormLabel>I authorize</FormLabel>
                <Input
                  _readOnly={{
                    border: '1px solid #ccc',
                  }}
                  variant="no-effects"
                  bg="#F9F9F9"
                  border="1px solid black"
                  isReadOnly
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                />
              </FormControl>
            )}
          />
        </Box>
        <Text pt="10px" pl="5px">
          to release a copy of the medical information for
        </Text>

        <HStack
          pt="10px"
          flexDirection={{ base: 'column', sm: 'column', md: 'row', lg: 'row' }}
          spacing={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}
        >
          <Controller
            name="namePatient"
            control={control}
            defaultValue=""
            render={props => (
              <FormControl>
                <FormLabel>Name of Patient</FormLabel>
                <Input
                  _readOnly={{
                    border: '1px solid #ccc',
                  }}
                  variant="no-effects"
                  bg="#F9F9F9"
                  border="1px solid black"
                  isReadOnly
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                />
              </FormControl>
            )}
          />

          <Controller
            name="DOB"
            control={control}
            defaultValue={moment(new Date()).format('yyyy-MM-DD')}
            render={props => (
              <FormControl>
                <FormLabel>DOB (mm/dd/yy)</FormLabel>
                <Input
                  _readOnly={{
                    border: '1px solid #ccc',
                  }}
                  variant="no-effects"
                  bg="#F9F9F9"
                  border="1px solid black"
                  isReadOnly
                  type="date"
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                />
              </FormControl>
            )}
          />
        </HStack>
        <HStack
          pt="10px"
          flexDirection={{ base: 'column', sm: 'column', md: 'row', lg: 'row' }}
          spacing={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}
        >
          <Controller
            name="nameRecipient"
            control={control}
            defaultValue=""
            render={props => (
              <FormControl>
                <FormLabel>{`to (Name of Recipient)`}</FormLabel>
                <Input
                  _readOnly={{
                    border: '1px solid #ccc',
                  }}
                  variant="no-effects"
                  bg="#F9F9F9"
                  border="1px solid black"
                  isReadOnly
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                />
              </FormControl>
            )}
          />

          <Controller
            name="maillingAddress"
            control={control}
            defaultValue=""
            render={props => (
              <FormControl>
                <FormLabel>Mailling Address</FormLabel>
                <Input
                  _readOnly={{
                    border: '1px solid #ccc',
                  }}
                  variant="no-effects"
                  bg="#F9F9F9"
                  border="1px solid black"
                  isReadOnly
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                />
              </FormControl>
            )}
          />
        </HStack>

        <HStack
          pt="10px"
          flexDirection={{ base: 'column', sm: 'column', md: 'row', lg: 'row' }}
          spacing={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}
        >
          <Controller
            name="fax"
            control={control}
            defaultValue=""
            render={props => (
              <FormControl>
                <FormLabel>or Fax No.</FormLabel>
                <Input
                  _readOnly={{
                    border: '1px solid #ccc',
                  }}
                  variant="no-effects"
                  bg="#F9F9F9"
                  border="1px solid black"
                  isReadOnly
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                />
              </FormControl>
            )}
          />
          <Box w="100%"></Box>
        </HStack>
        <Box pt="10px">
          <Controller
            name="information"
            control={control}
            defaultValue=""
            render={props => (
              <FormControl>
                <FormLabel>
                  The information will be used on my behalf for the following
                  purpose(s)
                </FormLabel>
                <Textarea
                  _readOnly={{
                    border: '1px solid #ccc',
                  }}
                  variant="no-effects"
                  bg="#F9F9F9"
                  border="1px solid black"
                  isReadOnly
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                />
              </FormControl>
            )}
          />
        </Box>
        <Text pt="20px">
          By initialing the spaces below, I specifically authorize the release
          of the following records, if such records exist:
        </Text>
        <Stack pt="20px">
          <Controller
            name="racords"
            control={control}
            render={props => (
              <FormControl>
                <CheckboxGroup
                  onChange={e => {
                    setValue('racords', e)
                    // clearErrors("racords")
                  }}
                  value={props.value}
                  onBlur={props.onBlur}
                  w="100%"
                  isDisabled
                >
                  <Grid
                    w="100%"
                    templateColumns={{
                      base: 'repeat(1,1fr)',
                      sm: 'repeat(1,1fr)',
                      md: 'repeat(1,1fr)',
                      lg: 'repeat(1, 1fr)',
                    }}
                    gap={4}
                  >
                    <VStack alignItems="flex-start">
                      <Checkbox value="hospitalrecord">
                        All hospital records (including nursing records and
                        progress notes)
                      </Checkbox>

                      <Checkbox value="transcribreports" pt="5px">
                        Transcribed hospital reports
                      </Checkbox>

                      <Checkbox value="medicalrecord" pt="5px">
                        Medical records needed for continuity of care
                      </Checkbox>

                      <Checkbox value="yearhistory" pt="5px">
                        Most recent five year history
                      </Checkbox>

                      <Checkbox value="laboratory" pt="5px">
                        Laboratory reports
                      </Checkbox>

                      <Checkbox value="pathology" pt="5px">
                        Pathology reports
                      </Checkbox>

                      <Checkbox value="diagnostic" pt="5px">
                        Diagnostic imaging reports
                      </Checkbox>

                      <Checkbox value="clinician" pt="5px">
                        Clinician office chart notes
                      </Checkbox>

                      <Checkbox value="dental" pt="5px">
                        Dental records
                      </Checkbox>

                      <Checkbox value="therapy" pt="5px">
                        Physical Therapy records
                      </Checkbox>

                      <Checkbox value="care" pt="5px">
                        Emergency and urgency care records
                      </Checkbox>

                      <Checkbox value="statement" pt="5px">
                        Billing Statements
                      </Checkbox>

                      <HStack>
                        <Checkbox value="Other" pt="5px" />
                        <Controller
                          name="other"
                          control={control}
                          defaultValue=""
                          render={props => (
                            <FormControl pt="5px">
                              <Input
                                _readOnly={{
                                  border: '1px solid #ccc',
                                }}
                                variant="no-effects"
                                bg="#F9F9F9"
                                border="1px solid black"
                                isReadOnly
                                placeholder="Other"
                                value={props.value}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                              />
                            </FormControl>
                          )}
                        />
                      </HStack>
                    </VStack>
                  </Grid>
                </CheckboxGroup>
              </FormControl>
            )}
          />
        </Stack>
        <Text pt="20px">
          Please send the entire medical record (all information) to the above
          named recipient. The recipient understands this record may be
          voluminous and agrees to pay all reasonable charges associated with
          providing this record.
        </Text>
        <Flex pt="20px">
          <Controller
            name="racords"
            control={control}
            defaultValue={formView?.racords || 'No'}
            render={props => (
              <CheckboxGroup
                onChange={props.onChange}
                value={props.value}
                w="100%"
                isDisabled
              >
                <Grid
                  w="100%"
                  templateColumns={{
                    base: 'repeat(1,1fr)',
                    sm: 'repeat(1,1fr)',
                    md: 'repeat(1,1fr)',
                    lg: 'repeat(1, 1fr)',
                  }}
                  gap={4}
                >
                  <GridItem w="100%">
                    <VStack alignItems="flex-start">
                      <Checkbox value="hiv">*HIV/AIDS-related records</Checkbox>

                      <Checkbox value="genetic">
                        *Genetic testing information
                      </Checkbox>
                    </VStack>
                  </GridItem>
                </Grid>
              </CheckboxGroup>
            )}
          />
        </Flex>
        <Text pt="10px">
          *Must be specifically selected to be included in other documents.
        </Text>

        <Flex pt="10px">
          <Controller
            name="racords"
            control={control}
            defaultValue={formView?.racords || 'No'}
            render={props => (
              <CheckboxGroup
                onChange={props.onChange}
                value={props.value}
                w="100%"
                isDisabled
              >
                <Grid
                  w="100%"
                  templateColumns={{
                    base: 'repeat(1,1fr)',
                    sm: 'repeat(1,1fr)',
                    md: 'repeat(1,1fr)',
                    lg: 'repeat(1, 1fr)',
                  }}
                  gap={4}
                >
                  <GridItem w="100%">
                    <VStack alignItems="flex-start">
                      <Checkbox value="drug">
                        **Drug/Alcohol diagnosis, treatment or referral
                        information
                      </Checkbox>

                      <HStack pl="1.5rem">
                        <Controller
                          name="otherDrug"
                          control={control}
                          defaultValue=""
                          render={props => (
                            <FormControl>
                              <Input
                                _readOnly={{
                                  border: '1px solid #ccc',
                                }}
                                variant="no-effects"
                                bg="#F9F9F9"
                                border="1px solid black"
                                isReadOnly
                                value={props.value}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                              />
                            </FormControl>
                          )}
                        />
                      </HStack>
                    </VStack>
                  </GridItem>
                </Grid>
              </CheckboxGroup>
            )}
          />
        </Flex>
        <Text pt="10px">
          **Federal Regulation 42 CFR Part 2, requires a description of how much
          and what kind of information is to be disclosed.
        </Text>

        <Flex pt="10px">
          <Controller
            name="federal"
            control={control}
            defaultValue={
              formView?.federal === 'treatment' ? [' '] : ['treatment']
            }
            render={props => (
              <CheckboxGroup
                onChange={props.onChange}
                value={props.value}
                w="100%"
                isDisabled
              >
                <Grid
                  w="100%"
                  templateColumns={{
                    base: 'repeat(1,1fr)',
                    sm: 'repeat(1,1fr)',
                    md: 'repeat(1,1fr)',
                    lg: 'repeat(1, 1fr)',
                  }}
                  gap={4}
                >
                  <GridItem w="100%">
                    <VStack alignItems="flex-start">
                      <Checkbox value="treatment">
                        This authorization is limited to the following treatment
                      </Checkbox>

                      <HStack pl="1.5rem">
                        <Controller
                          name="otherFederal"
                          control={control}
                          defaultValue=""
                          render={props => (
                            <FormControl>
                              <Input
                                _readOnly={{
                                  border: '1px solid #ccc',
                                }}
                                variant="no-effects"
                                bg="#F9F9F9"
                                border="1px solid black"
                                isReadOnly
                                value={props.value}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                              />
                            </FormControl>
                          )}
                        />
                      </HStack>
                    </VStack>
                  </GridItem>
                </Grid>
              </CheckboxGroup>
            )}
          />
        </Flex>
        <Flex pt="10px">
          <Controller
            name="period"
            control={control}
            defaultValue={formView?.racords === 'limited' ? [' '] : ['limited']}
            render={props => (
              <CheckboxGroup
                onChange={props.onChange}
                value={props.value}
                w="100%"
                isDisabled
              >
                <Grid
                  w="100%"
                  templateColumns={{
                    base: 'repeat(1,1fr)',
                    sm: 'repeat(1,1fr)',
                    md: 'repeat(1,1fr)',
                    lg: 'repeat(1, 1fr)',
                  }}
                  gap={4}
                >
                  <GridItem w="100%">
                    <VStack alignItems="flex-start">
                      <Checkbox value="limited">
                        This authorization is limited to the time period
                      </Checkbox>

                      <HStack pl="1.5rem">
                        <Controller
                          name="otherPeriod"
                          control={control}
                          defaultValue=""
                          render={props => (
                            <FormControl>
                              <Input
                                _readOnly={{
                                  border: '1px solid #ccc',
                                }}
                                variant="no-effects"
                                bg="#F9F9F9"
                                border="1px solid black"
                                isReadOnly
                                value={props.value}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                              />
                            </FormControl>
                          )}
                        />
                      </HStack>
                    </VStack>
                  </GridItem>
                </Grid>
              </CheckboxGroup>
            )}
          />
        </Flex>
        <Text pt="20px">
          This authorization may be revoked at any time. The only exception is
          when action has been taken in reliance on the authorization, unless
          revoked earlier, this consent will expire 180 days from the date of
          signing or shall remain in effect for the period reasonably needed to
          complete the request.
        </Text>
        <Grid
          templateColumns={{ base: 'repeat(1, 1fr)', xl: 'repeat(2, 1fr)' }}
          alignItems="start"
          gap={0}
          h="max-content"
        >
          <Controller
            name="patientSigning"
            control={control}
            defaultValue=""
            render={props => (
              <FormControl>
                <GridItem
                  onChange={props.onChange}
                  value={props.value}
                  onBlur={props.onBlur}
                  w="100%"
                >
                  <VStack spacing="0px">
                    <Center h={{ base: '80px' }}>
                      <Text fontWeight="bold" fontSize="15">
                        Signature of patient
                      </Text>
                    </Center>
                    <HStack h="40px" w="100%" flexDirection="row">
                      <Center w="100%">
                        <Flex ml="0px">
                          <Text fontFamily={'Homemade Apple'}>
                            {formView.patientSigning}
                          </Text>
                        </Flex>
                      </Center>
                    </HStack>
                  </VStack>

                  <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
                </GridItem>
                <Center></Center>
              </FormControl>
            )}
          />

          <GridItem w="100%">
            <VStack spacing="0px">
              <Center h={{ base: '80px' }}>
                <Text fontWeight="bold" fontSize="15">
                  Date
                </Text>
              </Center>

              <Center h="40px">
                <Text>
                  {new Date().toLocaleDateString('en-US', { hour12: false })}
                </Text>
              </Center>
            </VStack>
            <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
          </GridItem>
        </Grid>

        <Grid
          templateColumns={{ base: 'repeat(1, 1fr)', xl: 'repeat(2, 1fr)' }}
          alignItems="start"
          gap={0}
          h="max-content"
        >
          <Controller
            name="person"
            control={control}
            defaultValue=""
            rules={{
              required: {
                value: true,
                message: ' Please signature of person authorized by law.',
              },
            }}
            render={props => (
              <FormControl>
                <GridItem
                  onChange={props.onChange}
                  value={props.value}
                  onBlur={props.onBlur}
                  w="100%"
                >
                  <VStack spacing="0px">
                    <Center h={{ base: '80px' }}>
                      <Text fontWeight="bold" fontSize="15">
                        Signature of person authorized by law
                      </Text>
                    </Center>
                    <HStack h="40px" w="100%" flexDirection="row">
                      <Center w="100%">
                        <Flex ml="50px">
                          <SwitchInputComponent
                            formView={formView.signaturePatientLaw || ''}
                          />
                        </Flex>
                      </Center>
                    </HStack>
                  </VStack>

                  <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
                </GridItem>
                <Center></Center>
              </FormControl>
            )}
          />

          <GridItem w="100%">
            <VStack spacing="0px">
              <Center h={{ base: '80px' }}>
                <Text fontWeight="bold" fontSize="15">
                  Date
                </Text>
              </Center>

              <Center h="40px">
                <Text>
                  {new Date().toLocaleDateString('en-US', { hour12: false })}
                </Text>
              </Center>
            </VStack>
            <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
          </GridItem>
        </Grid>
      </Box>
      <Center pt="2rem">{`17/${totalNonWebEnabledPage}`}</Center>
    </CardContent>
  )
}

export { SecondSignatureCancellation }
