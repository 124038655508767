import { Image, Text, View } from '@react-pdf/renderer'
import moment from 'moment'
import React from 'react'

function PatientDemographics({ fillableData, styles }) {
  console.log(fillableData)

  return (
    <View style={{ ...styles.gapBox }}>
      <View
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          fontSize: 8,
        }}
      >
        <Image
          style={{ width: '150px', objectFit: 'cover' }}
          src="/paincare-web-v2.png"
        />
        <View style={styles.boxtitle}>
          <Text>2480 Liberty Street NE, Suite 180, Salem, OR 97301</Text>
        </View>
        <View style={styles.boxtitle}>
          <Text>Office: (503) 371-1010 Fax: (503) 371-0805</Text>
        </View>
      </View>
      <View
        style={{
          width: '450px',
          paddingTop: '14px',
          gap: '8px',
        }}
      >
        <View style={{ width: '100%' }}>
          <View
            style={{
              ...styles.paddingLeftForm,
              ...styles.paddingLeftFormTitle,
            }}
          >
            <Text>Patient Demographics</Text>
          </View>
          <View
            style={{
              ...styles.paddingLeftForm,
              width: '100%',
              flexDirection: 'column',
              gap: '5px',
            }}
          >
            <View
              style={{
                ...styles.boxRowData,
                paddingTop: 6,
              }}
            >
              <Text>Name :</Text>
              <Text style={styles.paddingLeftFormData}>
                {fillableData.namePatient ? fillableData.namePatient : ''}
              </Text>
            </View>
            <View style={styles.boxRowData}>
              <Text>Address :</Text>
              <Text style={styles.paddingLeftFormData}>
                {fillableData.addressPatient ? fillableData.addressPatient : ''}
              </Text>
            </View>
            <View style={styles.boxRowData}>
              <View
                style={{
                  ...styles.boxRowData,
                  width: '100%',
                  gap: '0px',
                }}
              >
                <Text style={{ width: '90px' }}>Home Phone :</Text>
                <Text style={{ ...styles.paddingLeftFormData }}>
                  {fillableData.homephonePatient
                    ? fillableData.homephonePatient
                    : ''}
                </Text>
              </View>
              <View
                style={{
                  ...styles.boxRowData,
                  width: '100%',
                  gap: '0px',
                }}
              >
                <Text style={{ width: '75px' }}>Cell Phone :</Text>
                <Text style={{ ...styles.paddingLeftFormData }}>
                  {fillableData.cellphonePatient
                    ? fillableData.cellphonePatient
                    : ''}
                </Text>
              </View>
            </View>
            <View style={styles.boxRowData}>
              <View
                style={{
                  ...styles.boxRowData,
                  width: '100%',
                  gap: '0px',
                }}
              >
                <Text style={{ width: '40px' }}>SSN# :</Text>
                <Text style={{ ...styles.paddingLeftFormData }}>
                  {fillableData.ssnPatient ? fillableData.ssnPatient : ''}
                </Text>
              </View>
              <View
                style={{
                  ...styles.boxRowData,
                  width: '100%',
                  gap: '0px',
                }}
              >
                <Text style={{ width: '125px' }}>DOB (mm/dd/yyyy) :</Text>
                <Text
                  style={{
                    ...styles.paddingLeftFormData,
                    textAlign: 'center',
                  }}
                >
                  {fillableData?.dobPatient
                    ? moment(fillableData?.dobPatient).format('MM/DD/YYYY')
                    : ''}
                </Text>
              </View>
            </View>
            <View
              style={{
                ...styles.boxRowData,
              }}
            >
              <Text style={{ width: '145px' }}>Primary Care Physician :</Text>
              <Text style={styles.paddingLeftFormData}>
                {fillableData.primaryPatient ? fillableData.primaryPatient : ''}
              </Text>
            </View>
          </View>
        </View>
        <View style={{ width: '100%' }}>
          <View
            style={{
              ...styles.paddingLeftForm,
              ...styles.paddingLeftFormTitle,
            }}
          >
            <Text>Emergency Contact</Text>
          </View>
          <View
            style={{
              ...styles.paddingLeftForm,
              width: '100%',
              flexDirection: 'column',
              gap: '5px',
            }}
          >
            <View
              style={{
                ...styles.boxRowData,
                paddingTop: 6,
              }}
            >
              <Text>Name :</Text>
              <Text style={styles.paddingLeftFormData}>
                {fillableData.nameEmergency ? fillableData.nameEmergency : ''}
              </Text>
            </View>

            <View style={styles.boxRowData}>
              <View
                style={{
                  ...styles.boxRowData,
                  width: '100%',
                  gap: '0px',
                }}
              >
                <Text style={{ width: '90px' }}>Relationship :</Text>
                <Text style={{ ...styles.paddingLeftFormData }}>
                  {fillableData.relationshipEmergency
                    ? fillableData.relationshipEmergency
                    : ''}
                </Text>
              </View>
              <View
                style={{
                  ...styles.boxRowData,
                  width: '100%',
                  gap: '0px',
                }}
              >
                <Text style={{ width: '45px' }}>Phone :</Text>
                <Text style={{ ...styles.paddingLeftFormData }}>
                  {fillableData.phoneEmergency
                    ? fillableData.phoneEmergency
                    : ''}
                </Text>
              </View>
            </View>
            <View
              style={{
                ...styles.boxRowData,
                alignContent: 'center',
                gap: '20px',
              }}
            >
              <Text>Grant Permission to access medical information</Text>
              <View style={{ ...styles.trueFalseChoiceRow }}>
                <View style={{ ...styles.trueFalseChoicewidth }}>
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.radioGrantPermission === 'yes' && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>Yes</Text>
                </View>
                <View style={{ ...styles.trueFalseChoiceContainer }}>
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.radioGrantPermission === 'no' && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>No</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={{ width: '100%' }}>
          <View
            style={{
              ...styles.paddingLeftForm,
              ...styles.paddingLeftFormTitle,
            }}
          >
            <Text>Race and Ethnicity Survey</Text>
          </View>
          <View
            style={{
              ...styles.paddingLeftForm,
              width: '100%',
              flexDirection: 'column',
              gap: '5px',
              paddingTop: 6,
            }}
          >
            <Text>
              Please place a check indicating one choice under race and one
              choice under ethnicity. You are under no obligation to answer any
              of the questions below.
            </Text>
            <View style={{ gap: '4px' }}>
              <Text style={{ fontWeight: 'bold' }}>Race</Text>

              <View
                style={{
                  ...styles.trueFalseChoiceRow,
                  ...styles.paddingLeftForm,
                  justifyContent: 'space-between',
                }}
              >
                <View style={{ ...styles.trueFalseChoicewidth, width: '50%' }}>
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.raceSurvey === 'americanRace' && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>American Indian or Alaska Native</Text>
                </View>
                <View
                  style={{ ...styles.trueFalseChoiceContainer, width: '50%' }}
                >
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.raceSurvey === 'asianRace' && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>Asian</Text>
                </View>
              </View>
              <View
                style={{
                  ...styles.trueFalseChoiceRow,
                  ...styles.paddingLeftForm,
                  justifyContent: 'space-between',
                }}
              >
                <View style={{ ...styles.trueFalseChoicewidth, width: '50%' }}>
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.raceSurvey === 'blackRace' && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>Black or African American</Text>
                </View>
                <View
                  style={{ ...styles.trueFalseChoiceContainer, width: '50%' }}
                >
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.raceSurvey === 'nativeRace' && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>Native Hawaiian or Pacific Islander</Text>
                </View>
              </View>
              <View
                style={{
                  ...styles.trueFalseChoiceRow,
                  ...styles.paddingLeftForm,
                  justifyContent: 'space-between',
                }}
              >
                <View style={{ ...styles.trueFalseChoicewidth, width: '50%' }}>
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.raceSurvey === 'whiteRace' && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>White</Text>
                </View>
                <View
                  style={{ ...styles.trueFalseChoiceContainer, width: '50%' }}
                >
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.raceSurvey === 'declineRace' && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>Decline</Text>
                </View>
              </View>
              <View
                style={{
                  ...styles.trueFalseChoiceRow,
                  ...styles.paddingLeftForm,
                  justifyContent: 'space-between',
                }}
              >
                <View style={{ ...styles.trueFalseChoicewidth, width: '50%' }}>
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.raceSurvey === 'unknownRace' && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>Unknown</Text>
                </View>
                <View
                  style={{ ...styles.trueFalseChoiceContainer, width: '50%' }}
                >
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.raceSurvey === 'otherRace' && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 4,
                    }}
                  >
                    <Text>Other</Text>
                    <Text
                      style={{
                        width: '165px',
                        borderBottom: '1px dotted #000',
                        paddingLeft: 4,
                      }}
                    >
                      {fillableData.raceOther ? fillableData.raceOther : ''}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={{ gap: '4px', paddingTop: 4 }}>
              <Text style={{ fontWeight: 'bold' }}>Ethnicity</Text>

              <View
                style={{
                  ...styles.trueFalseChoiceRow,
                  ...styles.paddingLeftForm,
                  justifyContent: 'space-between',
                }}
              >
                <View style={{ ...styles.trueFalseChoicewidth, width: '50%' }}>
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.ethnicitySurvey === 'hispanicEthnicity' && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>Hispanic or Latino Ethnicity</Text>
                </View>
                <View
                  style={{ ...styles.trueFalseChoiceContainer, width: '50%' }}
                >
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.ethnicitySurvey ===
                      'nonhispanicEthnicity' && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>Non-Hispanic or Latino Ethnicity</Text>
                </View>
              </View>
              <View
                style={{
                  ...styles.trueFalseChoiceRow,
                  ...styles.paddingLeftForm,
                  justifyContent: 'space-between',
                }}
              >
                <View style={{ ...styles.trueFalseChoicewidth, width: '50%' }}>
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.ethnicitySurvey === 'declineEthnicity' && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>Decline</Text>
                </View>
                <View
                  style={{ ...styles.trueFalseChoiceContainer, width: '50%' }}
                >
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.ethnicitySurvey === 'unknownEthnicity' && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>Unknown</Text>
                </View>
              </View>
            </View>

            <View style={{ gap: '4px', paddingTop: 4 }}>
              <Text style={{ fontWeight: 'bold' }}>Language</Text>
              <View
                style={{
                  ...styles.trueFalseChoiceRow,
                  ...styles.paddingLeftForm,
                  justifyContent: 'space-between',
                }}
              >
                <View style={{ ...styles.trueFalseChoicewidth, width: '50%' }}>
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.languageSurvey.includes(
                      'englishLanguage'
                    ) && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>English</Text>
                </View>
                <View
                  style={{ ...styles.trueFalseChoiceContainer, width: '50%' }}
                >
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.languageSurvey.includes(
                      'spanishLanguage'
                    ) && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>Spanish</Text>
                </View>
              </View>
              <View
                style={{
                  ...styles.trueFalseChoiceRow,
                  ...styles.paddingLeftForm,
                  justifyContent: 'space-between',
                }}
              >
                <View style={{ ...styles.trueFalseChoicewidth, width: '50%' }}>
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.languageSurvey.includes(
                      'russianLanguage'
                    ) && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>Russian</Text>
                </View>
                <View
                  style={{ ...styles.trueFalseChoiceContainer, width: '50%' }}
                >
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.languageSurvey.includes('otherLanguage') && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 4,
                    }}
                  >
                    <Text>Other</Text>
                    {` `}
                    <Text
                      style={{
                        width: '165px',
                        borderBottom: '1px dotted #000',
                      }}
                    >
                      {fillableData.otherLanguageSpecify
                        ? fillableData.otherLanguageSpecify
                        : ''}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  ...styles.trueFalseChoiceRow,
                  ...styles.paddingLeftForm,
                  justifyContent: 'space-between',
                }}
              >
                <View style={{ ...styles.trueFalseChoicewidth, width: '50%' }}>
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.languageSurvey.includes(
                      'indianLanguage'
                    ) && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>Indian (includes Hindi & Tamil)</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={{ width: '100%' }}>
          <View
            style={{
              ...styles.paddingLeftForm,
              ...styles.paddingLeftFormTitle,
            }}
          >
            <Text>Advance Directive</Text>
          </View>
          <View
            style={{
              ...styles.paddingLeftForm,
              width: '100%',
              flexDirection: 'column',
              gap: '5px',
            }}
          >
            <View style={{ gap: '4px', paddingTop: 4 }}>
              <View
                style={{
                  ...styles.trueFalseChoiceRow,
                  ...styles.paddingLeftForm,
                  justifyContent: 'space-between',
                }}
              >
                <View style={{ ...styles.trueFalseChoicewidth, width: '50%' }}>
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.advanceDirective === 'advanceAdvance' && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>Advance Directive</Text>
                </View>
                <View
                  style={{ ...styles.trueFalseChoiceContainer, width: '50%' }}
                >
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.advanceDirective === 'notAdvance' && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>Do Not Resuscitate Order (DNR)</Text>
                </View>
              </View>
            </View>
            <View style={{ gap: '4px' }}>
              <View
                style={{
                  ...styles.trueFalseChoiceRow,
                  ...styles.paddingLeftForm,
                  justifyContent: 'space-between',
                }}
              >
                <View style={{ ...styles.trueFalseChoicewidth, width: '50%' }}>
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.advanceDirective === 'noneAdvance' && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>None on File</Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={{ width: '100%' }}>
          <View
            style={{
              ...styles.paddingLeftForm,
              ...styles.paddingLeftFormTitle,
            }}
          >
            <Text>Grant Permission to leave Message</Text>
          </View>
          <View
            style={{
              ...styles.paddingLeftForm,
              width: '100%',
              flexDirection: 'column',
              gap: '5px',
            }}
          >
            <View style={{ gap: '4px', paddingTop: 4 }}>
              <View
                style={{
                  ...styles.trueFalseChoiceRow,
                  ...styles.paddingLeftForm,
                  justifyContent: 'space-between',
                }}
              >
                <View
                  style={{
                    flexDirection: 'column',
                    display: 'flex',
                    width: '50%',
                    gap: '4px',
                  }}
                >
                  <View
                    style={{ ...styles.trueFalseChoicewidth, width: '100%' }}
                  >
                    <View style={styles.stylesCheckImage}>
                      {fillableData?.grantMessage.includes(
                        'homePhoneGrantMessage'
                      ) && (
                        <Image
                          style={{
                            ...styles.imgcheck,
                          }}
                          src="/check.png"
                        />
                      )}
                    </View>
                    <Text>Leave message on home phone</Text>
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '20px',
                      justifyContent: 'center',
                    }}
                  >
                    <View
                      style={{
                        ...styles.trueFalseChoicewidth,
                        marginLeft: '-50px',
                      }}
                    >
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.grantMessage.includes(
                          'voiceHomephone'
                        ) && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Voice</Text>
                    </View>
                    <View
                      style={{
                        ...styles.trueFalseChoicewidth,
                      }}
                    >
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.grantMessage.includes(
                          'textHomephone'
                        ) && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Text</Text>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'column',
                    display: 'flex',
                    width: '50%',
                    gap: '4px',
                  }}
                >
                  <View
                    style={{
                      ...styles.trueFalseChoiceContainer,
                      width: '100%',
                    }}
                  >
                    <View style={styles.stylesCheckImage}>
                      {fillableData?.grantMessage.includes(
                        'cellphoneGrantMessage'
                      ) && (
                        <Image
                          style={{
                            ...styles.imgcheck,
                          }}
                          src="/check.png"
                        />
                      )}
                    </View>
                    <Text>Leave message on cell phone*</Text>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '20px',
                      justifyContent: 'center',
                    }}
                  >
                    <View
                      style={{
                        ...styles.trueFalseChoicewidth,
                        marginLeft: '-50px',
                      }}
                    >
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.grantMessage.includes(
                          'voiceCellphone'
                        ) && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Voice</Text>
                    </View>
                    <View
                      style={{
                        ...styles.trueFalseChoicewidth,
                      }}
                    >
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.grantMessage.includes(
                          'textCellphone'
                        ) && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Text</Text>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  ...styles.paddingLeftForm,
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '6px',
                  fontSize: 10,
                  width: '100%',
                  paddingTop: 4,
                }}
              >
                <View style={{ width: '14px' }}>
                  <View style={styles.stylesCheckImage}>
                    {/* {fillableData?.raceSurvey === 'Email' && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )} */}
                    {fillableData?.grantMessage?.includes('Email') && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 4,
                  }}
                >
                  <Text>Email message to my email address:</Text>
                  <Text
                    style={{
                      width: '250px',
                      borderBottom: '1px dotted #000',
                      paddingLeft: 4,
                    }}
                  >
                    {fillableData.emailGrantMessage
                      ? fillableData.emailGrantMessage
                      : ''}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={{ paddingTop: 12 }}>
            <Text>
              * Charges may be added by your carrier per your plan policy.
            </Text>
          </View>
        </View>
      </View>
    </View>
  )
}

export default PatientDemographics
