import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Container,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  Td,
  Spinner,
  Button,
  Input,
  Box,
  InputGroup,
  InputLeftElement,
  HStack,
  Select,
  IconButton,
} from '@chakra-ui/react'
import { search } from 'ss-search'
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore'
import { ExportToCsv } from 'export-to-csv'
import { db } from '../../config/firebase'
import { ChevronRightIcon, SearchIcon } from '@chakra-ui/icons'
import { FaFileCsv } from 'react-icons/fa'
import { BsViewStacked } from 'react-icons/bs'
import renderDate from '../../config/renderDate'

function EventList() {
  const [registerList, setRegisterLists] = useState([])
  const [searchText, setSearchText] = useState(null)
  const [searchResults, setSearchResults] = useState([])
  const [status, setStatus] = useState('All')
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    const registerRef = collection(db, 'RegisterList')
    const queryRegister =
      status === 'All'
        ? query(registerRef, orderBy('createdAt', 'desc'))
        : query(
            registerRef,
            orderBy('createdAt', 'desc'),
            where('status', '==', status)
          )

    const unsubscribe = onSnapshot(queryRegister, async snap => {
      const registers = snap.docs.map(doc => ({
        createdAt: renderDate(doc.data().createdAt),
        ref: doc.data().ref,
        firstName: doc.data().firstName,
        middleName: doc.data().middleName ? doc.data().middleName : ' ',
        lastName: doc.data().lastName,
        jobTitle: doc.data().jobTitle,
        organization: doc.data().organization,
        email: doc.data().email,
        mobileNo: doc.data().mobileNo,
        status: doc.data().status,
        eventName: doc.data().eventName,
        id: doc.id,
        invoiceRef: doc.data().invoiceRef.id,
      }))
      setRegisterLists(registers)
      setSearchResults(registers)
      setLoading(false)
    })
    return () => {
      unsubscribe()
    }
  }, [status])

  useEffect(() => {
    const searchKeys = [
      'firstName',
      'middleName',
      'lastName',
      'ref',
      'jobTitle',
    ]
    let results = search(registerList, searchKeys, searchText)
    setSearchResults(results)
  }, [searchText, status, registerList])

  function ExportCSV(data) {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      filename: 'CME_Registration',
      title: 'CME Registration List',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: [
        'Date Time',
        'Reference Code',
        'First Name',
        'Middle Name',
        'Last Name',
        'Job Title',
        'Organization',
        'Email',
        'Mobile No',
        'Status',
        'Invoice Reference',
        'Event Name',
        'Database ID',
        'Invoice ID',
      ],
    }
    const csvExporter = new ExportToCsv(options)
    csvExporter.generateCsv(data)
  }

  return (
    <Container maxW="100%" mt="5">
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
        bg="gray.100"
        p="1.5"
        borderRadius="0.3em"
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/dashboard">
            Dashbaord
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/event">
            Event
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Box mt="1rem" display="flex" justifyContent="flex-end">
        <Box>
          <HStack>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<SearchIcon color="gray.300" />}
              />
              <Input
                type="text"
                placeholder="Search Text..."
                onChange={e => setSearchText(e.currentTarget.value)}
              />
            </InputGroup>
            <Select
              defaultValue="All"
              onChange={e => {
                console.log(e.currentTarget.value)
                setStatus(e.currentTarget.value)
              }}
            >
              <option>All</option>
              <option value="Waiting for Payment">Waiting for Payment</option>
              <option value="Paid">Paid</option>
              <option value="Check In">Check In</option>
            </Select>
            <IconButton
              icon={<FaFileCsv />}
              colorScheme="green"
              onClick={() => ExportCSV(searchResults)}
            />
          </HStack>
        </Box>
      </Box>
      <Table size="sm" mt="10">
        <Thead>
          <Tr>
            <Th>Register Date</Th>
            <Th>Register's Name</Th>
            <Th>Event Name</Th>
            <Th>Status</Th>
            <Th textAlign="center">Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {isLoading ? (
            <Tr>
              <Td colSpan="4" textAlign="center">
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              </Td>
            </Tr>
          ) : (
            searchResults.map((data, index) => {
              return (
                <Tr key={index}>
                  <Td>{data.createdAt}</Td>
                  <Td>
                    {data.firstName} {data.middleName}{' '}
                    {data.lastName},{data.jobTitle}
                  </Td>
                  <Td>{data.eventName}</Td>
                  <Td>{data.status}</Td>
                  <Td textAlign="center">
                    <Button
                      size="xs"
                      leftIcon={<BsViewStacked />}
                      as={Link}
                      to={`/event/register/${data.id}`}
                    >
                      View
                    </Button>
                  </Td>
                </Tr>
              )
            })
          )}
        </Tbody>
      </Table>
    </Container>
  )
}

export default EventList
