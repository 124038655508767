import { ChevronRightIcon } from '@chakra-ui/icons'
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Container,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Spacer,
  Button,
  FormErrorMessage,
  RadioGroup,
  Radio,
  Center,
  useToast,
  Text,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FaCloudDownloadAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { db } from '../../config/firebase'
import { addDoc, collection } from 'firebase/firestore'
import moment from 'moment'

function MedecalPracticeManagement({ user }) {
  //State hook
  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    reset,
  } = useForm()
  const toast = useToast()
  const [isDisabled, setIsDisabled] = useState(false)

  //function
  function submitData(value) {
    setIsDisabled(true)
    // value.date = value.date.format('YYYY-MM-DD')
    addDoc(collection(db, 'EmployeeForm'), {
      ...value,
      name: user.fullName,
      type: 'Annual Clinical Competencies',
      createdAt: new Date(),
      notification: true,
      status: 'Pending',
    }).then(() => {
      toast({
        title: 'Submit Successfully',
        description: 'Form has been submmited.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      setIsDisabled(false)
      reset()
    })
  }
  return (
    <Container maxW="100%" mt={5}>
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
        bg="gray.100"
        p="1.5"
        borderRadius="0.3em"
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/dashboard">
            Dashbaord
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/anuql-clinical-competencies">
            Annual Clinical Competencies
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Box mt="30" mb="50" width={['100']}>
        <Flex mt="10">
          <Box pt="2" fontSize="18" fontWeight="bold">
            Annual Clinical Competencies
          </Box>
          <Spacer />
          <Button
            leftIcon={<FaCloudDownloadAlt />}
            size="sm"
            onClick={() => {
              window.open('/doc/annual-clinical-competencies.pdf')
            }}
          >
            PDF
          </Button>
        </Flex>
      </Box>
      <Box w={{ base: '100%', sm: '100%', md: '100%', lg: '60rem' }}>
        <form onSubmit={handleSubmit(submitData)}>
          <HStack mb="5">
            <FormControl id="name" isInvalid={errors.name}>
              <FormLabel>Employee Name</FormLabel>
              <Input
                name="name"
                defaultValue={user.fullName}
                ref={register({ required: 'Please type name.' })}
                isReadOnly={true}
              />
              <FormErrorMessage>
                {errors.name && errors.name?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl id="date" isInvalid={errors.date}>
              <FormLabel>Date</FormLabel>
              <Controller
                render={props => (
                  <Input
                    value={props.value}
                    onChange={props.onChange}
                    isReadOnly
                    textAlign={'center'}
                  />
                )}
                name="date"
                control={control}
                defaultValue={moment().format('YYYY-MM-DD')}
                rules={{ required: 'Date required.' }}
              />
              <FormErrorMessage>
                {errors.date && errors.date.message}
              </FormErrorMessage>
            </FormControl>
          </HStack>
          <Text fontWeight={'bold'}>
            Blood Glucose Monitors Competency Checklist{' '}
          </Text>
          <FormControl mx={3} mt="2" isInvalid={errors.quizOne}>
            <Controller
              name="quizOne"
              control={control}
              defaultValue={null}
              rules={{ required: true }}
              render={props => (
                <RadioGroup
                  name={props.name}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                >
                  <HStack px={4}>
                    <FormLabel>
                      Demonstrates knowledgeable use of monitor
                    </FormLabel>
                    <Spacer />
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </HStack>
                </RadioGroup>
              )}
            />
          </FormControl>
          <FormControl mx={3} isInvalid={errors.quizTwo}>
            <Controller
              name="quizTwo"
              control={control}
              defaultValue={null}
              rules={{ required: true }}
              render={props => (
                <RadioGroup
                  name={props.name}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                >
                  <HStack px={4}>
                    <FormLabel>
                      Verifies monitor lot number and number on test strips vial
                      are the same
                    </FormLabel>
                    <Spacer />
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </HStack>
                </RadioGroup>
              )}
            />
          </FormControl>
          <FormControl mx={3} isInvalid={errors.quizThree}>
            <Controller
              name="quizThree"
              control={control}
              defaultValue={null}
              rules={{ required: true }}
              render={props => (
                <RadioGroup
                  name={props.name}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                >
                  <HStack px={4}>
                    <FormLabel>Checks system with check strip</FormLabel>
                    <Spacer />
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </HStack>
                </RadioGroup>
              )}
            />
          </FormControl>
          <FormControl mx={3} isInvalid={errors.quizFour}>
            <Controller
              name="quizFour"
              control={control}
              defaultValue={null}
              rules={{ required: true }}
              render={props => (
                <RadioGroup
                  name={props.name}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                >
                  <HStack px={4}>
                    <FormLabel>Checks system with control solution</FormLabel>
                    <Spacer />
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </HStack>
                </RadioGroup>
              )}
            />
          </FormControl>
          <FormControl mx={3} isInvalid={errors.quizFive}>
            <Controller
              name="quizFive"
              control={control}
              defaultValue={null}
              rules={{ required: true }}
              render={props => (
                <RadioGroup
                  name={props.name}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                >
                  <HStack px={4}>
                    <FormLabel>
                      Obtains blood sample using manual lancet
                    </FormLabel>
                    <Spacer />
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </HStack>
                </RadioGroup>
              )}
            />
          </FormControl>
          <FormControl mx={3} isInvalid={errors.quizSix}>
            <Controller
              name="quizSix"
              control={control}
              defaultValue={null}
              rules={{ required: true }}
              render={props => (
                <RadioGroup
                  name={props.name}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                >
                  <HStack px={4}>
                    <FormLabel>Test blood sample</FormLabel>
                    <Spacer />
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </HStack>
                </RadioGroup>
              )}
            />
          </FormControl>
          <FormControl mx={3} isInvalid={errors.quizSeven}>
            <Controller
              name="quizSeven"
              control={control}
              defaultValue={null}
              rules={{ required: true }}
              render={props => (
                <RadioGroup
                  name={props.name}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                >
                  <HStack px={4}>
                    <FormLabel>Care of the monitor</FormLabel>
                    <Spacer />
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </HStack>
                </RadioGroup>
              )}
            />
          </FormControl>
          <Box>
            <FormControl isInvalid={errors.firstVeryfied}>
              <HStack>
                <FormLabel>Veryfied By</FormLabel>
                <Input
                  name="firstVeryfied"
                  ref={register({ required: true })}
                  textAlign={'center'}
                  w={'250px'}
                />
              </HStack>
            </FormControl>
          </Box>
          <Text fontWeight={'bold'} mt={5}>
            Urine Pregnancy Test Competency
          </Text>
          <FormControl mx={3} mt="2" isInvalid={errors.quizEigth}>
            <Controller
              name="quizEigth"
              control={control}
              defaultValue={null}
              rules={{ required: true }}
              render={props => (
                <RadioGroup
                  name={props.name}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                >
                  <HStack px={4}>
                    <FormLabel>
                      Demonstrates knowledgeable use of monitor
                    </FormLabel>
                    <Spacer />
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </HStack>
                </RadioGroup>
              )}
            />
          </FormControl>
          <FormControl mx={3} isInvalid={errors.quizNine}>
            <Controller
              name="quizNine"
              control={control}
              defaultValue={null}
              rules={{ required: true }}
              render={props => (
                <RadioGroup
                  name={props.name}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                >
                  <HStack px={4}>
                    <FormLabel>Verifies expiration date</FormLabel>
                    <Spacer />
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </HStack>
                </RadioGroup>
              )}
            />
          </FormControl>
          <FormControl mx={3} isInvalid={errors.quizTen}>
            <Controller
              name="quizTen"
              control={control}
              defaultValue={null}
              rules={{ required: true }}
              render={props => (
                <RadioGroup
                  name={props.name}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                >
                  <HStack px={4}>
                    <FormLabel>
                      Obtains urine sample with four drops of urine
                    </FormLabel>
                    <Spacer />
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </HStack>
                </RadioGroup>
              )}
            />
          </FormControl>
          <FormControl mx={3} isInvalid={errors.quizEleven}>
            <Controller
              name="quizEleven"
              control={control}
              defaultValue={null}
              rules={{ required: true }}
              render={props => (
                <RadioGroup
                  name={props.name}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                >
                  <HStack px={4}>
                    <FormLabel>
                      Places cassette on flat surface and places urine in
                      correct well
                    </FormLabel>
                    <Spacer />
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </HStack>
                </RadioGroup>
              )}
            />
          </FormControl>
          <FormControl mx={3} isInvalid={errors.quizTwelve}>
            <Controller
              name="quizTwelve"
              control={control}
              defaultValue={null}
              rules={{ required: true }}
              render={props => (
                <RadioGroup
                  name={props.name}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                >
                  <HStack px={4}>
                    <FormLabel>
                      Waits 2-3 minutes to interpret results and interprets
                      results correctly
                    </FormLabel>
                    <Spacer />
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </HStack>
                </RadioGroup>
              )}
            />
          </FormControl>
          <FormControl mx={3} isInvalid={errors.quizThirteen}>
            <Controller
              name="quizThirteen"
              control={control}
              defaultValue={null}
              rules={{ required: true }}
              render={props => (
                <RadioGroup
                  name={props.name}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                >
                  <HStack px={4}>
                    <FormLabel>
                      Identifies invalid, negative, and positive results
                    </FormLabel>
                    <Spacer />
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </HStack>
                </RadioGroup>
              )}
            />
          </FormControl>
          <Box>
            <FormControl isInvalid={errors.secondVeryfied}>
              <HStack>
                <FormLabel>Veryfied By</FormLabel>
                <Input
                  name="secondVeryfied"
                  ref={register({ required: true })}
                  textAlign={'center'}
                  w={'250px'}
                />
              </HStack>
            </FormControl>
          </Box>
          <Text fontWeight={'bold'} mt={5}>
            Crash Cart
          </Text>
          <FormControl mx={3} mt="2" isInvalid={errors.quizFourteen}>
            <Controller
              name="quizFourteen"
              control={control}
              defaultValue={null}
              rules={{ required: true }}
              render={props => (
                <RadioGroup
                  name={props.name}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                >
                  <HStack px={4}>
                    <FormLabel>
                      Demonstrates proper use and knowledge of how to use crash
                      cart, EG: Defibrillator, easy go vac, 3 lead placement and
                      monitor reading
                    </FormLabel>
                    <Spacer />
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </HStack>
                </RadioGroup>
              )}
            />
          </FormControl>
          <Box>
            <FormControl isInvalid={errors.tirdVeryfied}>
              <HStack>
                <FormLabel>Veryfied By</FormLabel>
                <Input
                  name="tirdVeryfied"
                  ref={register({ required: true })}
                  textAlign={'center'}
                  w={'250px'}
                />
              </HStack>
            </FormControl>
          </Box>
          <Text fontWeight={'bold'} mt={5}>
            Cavi Wipes
          </Text>
          <FormControl mx={3} mt="2" isInvalid={errors.quizFifteen}>
            <Controller
              name="quizFifteen"
              control={control}
              defaultValue={null}
              rules={{ required: true }}
              render={props => (
                <RadioGroup
                  name={props.name}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                >
                  <HStack px={4}>
                    <FormLabel>
                      Demonstrate proper use of how to use Cavi Wipes
                    </FormLabel>
                    <Spacer />
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </HStack>
                </RadioGroup>
              )}
            />
          </FormControl>
          <Box>
            <FormControl isInvalid={errors.fithVeryfied}>
              <HStack>
                <FormLabel>Veryfied By</FormLabel>
                <Input
                  name="fithVeryfied"
                  ref={register({ required: true })}
                  textAlign={'center'}
                  w={'250px'}
                />
              </HStack>
            </FormControl>
          </Box>
          <Text fontWeight={'bold'} mt={5}>
            Urine Collecting Competency
          </Text>
          <FormControl mx={3} mt="2" isInvalid={errors.quizSixteen}>
            <Controller
              name="quizSixteen"
              control={control}
              defaultValue={null}
              rules={{ required: true }}
              render={props => (
                <RadioGroup
                  name={props.name}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                >
                  <HStack px={4}>
                    <FormLabel>Correct specimen collection</FormLabel>
                    <Spacer />
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </HStack>
                </RadioGroup>
              )}
            />
          </FormControl>
          <FormControl mx={3} mt="2" isInvalid={errors.quizSeventeen}>
            <Controller
              name="quizSeventeen"
              control={control}
              defaultValue={null}
              rules={{ required: true }}
              render={props => (
                <RadioGroup
                  name={props.name}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                >
                  <HStack px={4}>
                    <FormLabel>Correct specimen handling</FormLabel>
                    <Spacer />
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </HStack>
                </RadioGroup>
              )}
            />
          </FormControl>
          <Center mt="5">
            <Button
              colorScheme="blue"
              width={['100%', '100%', '100%', '10%']}
              type="submit"
              isDisabled={isDisabled}
              isLoading={isDisabled}
              mb={9}
            >
              Submit
            </Button>
          </Center>
        </form>
      </Box>
    </Container>
  )
}

export default MedecalPracticeManagement
