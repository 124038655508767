export const calPatientScore = value => {
  let score = { oneTotal: 0, twoTotal: 0, threeTotal: 0 }
  Object.keys(value).map(i => {
    if (i === 'date' || i === 'dateOfBirth' || i === 'name' || value[i] === '0')
      return null
    switch (value[i]) {
      case '1':
        return (score = {
          ...score,
          oneTotal: score.oneTotal + Number(value[i]),
        })
      case '2':
        return (score = {
          ...score,
          twoTotal: score.twoTotal + Number(value[i]),
        })
      case '3':
        return (score = {
          ...score,
          threeTotal: score.threeTotal + Number(value[i]),
        })
      default:
        return null
    }
  })
  score = {
    ...score,
    total: score.oneTotal + score.twoTotal + score.threeTotal,
  }
  return score
}

export const calScore = data => {
  const result = { resultOne: 0, resultTwo: 0, resultThree: 0 }
  data.map(item => {
    if (item === '1') return (result.resultOne += Number(item))
    if (item === '2') return (result.resultTwo += Number(item))
    if (item === '3') return (result.resultThree += Number(item))
    return null
  })
  return {
    ...result,
    total: result.resultOne + result.resultTwo + result.resultThree,
  }
}

export const calPatientPHQScore = score => {
  if (score >= 0 && score <= 5) return 'Mild'
  if (score >= 6 && score <= 10) return 'Moderate'
  if (score >= 11 && score <= 15) return 'Moderately Severe'
  if (score >= 16) return 'Severe Depression'
}

export const calPatientGADScore = score => {
  if (score >= 0 && score <= 5) return 'Mild'
  if (score >= 6 && score <= 10) return 'Moderate'
  if (score >= 11 && score <= 15) return 'Moderately Severe Anxiety'
  if (score >= 15) return 'Severe Anxiety'
}