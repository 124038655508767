import {
  Box,
  Center,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react'
import moment from 'moment'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { CardContent } from '../../../../../../Components/Card/card'
import { totalWebEnablePages } from '../../config'

const _ = require('underscore')

function PatientDemographics({ formView }) {
  const {
    // handleSubmit,
    // reset,
    // register,
    // errors,
    control,
    setValue,
    // watch,
    clearErrors,
  } = useForm()

  useEffect(() => {
    if (formView) {
      const data = formView
      _.keys(data).map(e => {
        return setValue(e, data[e])
      })
    }
  }, [formView, setValue])
  // const watchRace = watch('raceSuyvey')
  // const race = watchRace === 'otherRace' ? true : false
  return (
    <CardContent>
      <Box>
        <Flex
          bg="#B7DDE9"
          fontSize="14px"
          w="100%"
          pl="10px"
          mb="10px"
          fontWeight="bold"
        >
          Patient Demographics
        </Flex>
        <Box>
          <Controller
            name="namePatient"
            control={control}
            defaultValue=""
            rules={{
              required: {
                value: true,
                message: 'Please enter your name.',
              },
            }}
            render={props => (
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input
                  _readOnly={{
                    border: '1px solid #ccc',
                  }}
                  variant="no-effects"
                  readOnly
                  bg="#F9F9F9"
                  border="1px solid black"
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                />
              </FormControl>
            )}
          />
        </Box>
        <Box pt="10px">
          <Controller
            render={props => (
              <FormControl>
                <FormLabel>Address</FormLabel>
                <Textarea
                  _readOnly={{
                    border: '1px solid #ccc',
                  }}
                  variant="no-effects"
                  readOnly
                  bg="#F9F9F9"
                  border="1px solid black"
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                />
              </FormControl>
            )}
            name="addressPatient"
            control={control}
            defaultValue=""
            rules={{
              required: {
                value: true,
                message: 'Please enter your address.',
              },
            }}
          />
        </Box>
        <Flex
          pt="10px"
          pb="10px"
          direction={{ base: 'column', sm: 'column', md: 'row', lg: 'row' }}
        >
          <Box w="100%" pr={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}>
            <Controller
              name="homephonePatient"
              control={control}
              defaultValue=""
              rules={{
                required: {
                  value: true,
                  message: 'Please enter your home phone.',
                },
              }}
              render={props => (
                <FormControl>
                  <FormLabel>Home Phone</FormLabel>
                  <Input
                    _readOnly={{
                      border: '1px solid #ccc',
                    }}
                    variant="no-effects"
                    readOnly
                    bg="#F9F9F9"
                    border="1px solid black"
                    type="number"
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                  />
                </FormControl>
              )}
            />
          </Box>
          <Box
            w="100%"
            pt={{ base: '10px', md: '0px' }}
            pl={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}
          >
            <Controller
              name="cellphonePatient"
              control={control}
              defaultValue=""
              rules={{
                required: {
                  value: true,
                  message: 'Please enter your cell phone.',
                },
              }}
              render={props => (
                <FormControl>
                  <FormLabel>Cell Phone</FormLabel>
                  <Input
                    _readOnly={{
                      border: '1px solid #ccc',
                    }}
                    variant="no-effects"
                    readOnly
                    bg="#F9F9F9"
                    border="1px solid black"
                    type="number"
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                  />
                </FormControl>
              )}
            />
          </Box>
        </Flex>
        <Flex
          pt="10px"
          pb="10px"
          direction={{ base: 'column', sm: 'column', md: 'row', lg: 'row' }}
        >
          <Box w="100%" pr={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}>
            <Controller
              name="ssnPatient"
              control={control}
              defaultValue=""
              rules={{
                required: {
                  value: true,
                  message: 'Please enter your SSN.',
                },
              }}
              render={props => (
                <FormControl>
                  <FormLabel>SSN#</FormLabel>
                  <Input
                    _readOnly={{
                      border: '1px solid #ccc',
                    }}
                    variant="no-effects"
                    readOnly
                    bg="#F9F9F9"
                    border="1px solid black"
                    type="number"
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                  />
                </FormControl>
              )}
            />
          </Box>
          <Box
            w="100%"
            pt={{ base: '10px', md: '0px' }}
            pl={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}
          >
            <Controller
              name="dobPatient"
              control={control}
              defaultValue={moment(new Date()).format('yyyy-MM-DD')}
              rules={{
                required: {
                  value: true,
                  message: 'Please enter your DOB.',
                },
              }}
              render={props => (
                <FormControl>
                  <FormLabel>DOB (mm/dd/yy)</FormLabel>
                  <Input
                    _readOnly={{
                      border: '1px solid #ccc',
                    }}
                    variant="no-effects"
                    readOnly
                    bg="#F9F9F9"
                    border="1px solid black"
                    type="date"
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                  />
                </FormControl>
              )}
            />
          </Box>
        </Flex>
        <Controller
          name="primaryPatient"
          control={control}
          defaultValue=""
          rules={{
            required: {
              value: true,
              message: 'Please enter primary care physician.',
            },
          }}
          render={props => (
            <FormControl>
              <FormLabel>Primary Care Physician</FormLabel>
              <Input
                _readOnly={{
                  border: '1px solid #ccc',
                }}
                variant="no-effects"
                readOnly
                bg="#F9F9F9"
                border="1px solid black"
                value={props.value}
                onChange={props.onChange}
                onBlur={props.onBlur}
              />
            </FormControl>
          )}
        />
        <Flex
          bg="#B7DDE9"
          fontSize="14px"
          w="100%"
          mt="20px"
          mb="10px"
          pl="10px"
          fontWeight="bold"
        >
          Emergency Contact
        </Flex>
        <Box>
          <Controller
            name="nameEmergency"
            control={control}
            defaultValue=""
            rules={{
              required: {
                value: true,
                message: 'Please enter your name.',
              },
            }}
            render={props => (
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input
                  _readOnly={{
                    border: '1px solid #ccc',
                  }}
                  variant="no-effects"
                  readOnly
                  bg="#F9F9F9"
                  border="1px solid black"
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                />
              </FormControl>
            )}
          />
        </Box>
        <Flex
          pt="10px"
          pb="10px"
          direction={{ base: 'column', sm: 'column', md: 'row', lg: 'row' }}
        >
          <Box w="100%" pr={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}>
            <Controller
              name="relationshipEmergency"
              control={control}
              defaultValue=""
              render={props => (
                <FormControl>
                  <FormLabel>Relationship</FormLabel>
                  <Input
                    _readOnly={{
                      border: '1px solid #ccc',
                    }}
                    variant="no-effects"
                    readOnly
                    bg="#F9F9F9"
                    border="1px solid black"
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                  />
                </FormControl>
              )}
            />
          </Box>
          <Box
            w="100%"
            pt={{ base: '10px', md: '0px' }}
            pl={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}
          >
            <Controller
              name="phoneEmergency"
              control={control}
              defaultValue=""
              rules={{
                required: {
                  value: true,
                  message: 'Please enter your phone.',
                },
              }}
              render={props => (
                <FormControl>
                  <FormLabel>Phone</FormLabel>
                  <Input
                    _readOnly={{
                      border: '1px solid #ccc',
                    }}
                    variant="no-effects"
                    readOnly
                    bg="#F9F9F9"
                    border="1px solid black"
                    type="number"
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                  />
                </FormControl>
              )}
            />
          </Box>
        </Flex>
        <HStack mt="15px" display={{ base: 'none', xl: 'flex' }}>
          <Text fontWeight="bold">
            Grant Permission to access medical information
          </Text>
          <Box>
            <Controller
              name="radioGrantPermission"
              control={control}
              defaultValue=""
              render={props => (
                <FormControl>
                  <RadioGroup
                    onChange={props.onChange}
                    value={props.value}
                    onBlur={props.onBlur}
                  >
                    <HStack direction="row" pl="20px">
                      <Radio isDisabled value="yes">
                        Yes
                      </Radio>
                      <Radio isDisabled value="no">
                        No
                      </Radio>
                    </HStack>
                  </RadioGroup>
                </FormControl>
              )}
            />
          </Box>
        </HStack>
        <VStack
          mt="15px"
          display={{ base: 'flex', xl: 'none' }}
          alignItems="flex-start"
        >
          <Text fontWeight="bold">
            Grant Permission to access medical information
          </Text>
          <Box>
            <Controller
              name="radioGrantPermission"
              control={control}
              defaultValue=""
              render={props => (
                <FormControl>
                  <RadioGroup
                    onChange={props.onChange}
                    value={props.value}
                    onBlur={props.onBlur}
                  >
                    <HStack direction="row">
                      <Radio isDisabled value="yes">
                        Yes
                      </Radio>
                      <Radio isDisabled value="no" pl={'20px'}>
                        No
                      </Radio>
                    </HStack>
                  </RadioGroup>
                </FormControl>
              )}
            />
          </Box>
        </VStack>
        <Flex
          bg="#B7DDE9"
          fontSize="14px"
          w="100%"
          pl="10px"
          mt="10px"
          mb="10px"
          fontWeight="bold"
        >
          <Text fontWeight="bold">Race and Ethnicity Survey</Text>
        </Flex>
        <Flex
          textAlign="start"
          fontSize="14px"
          mb="10px"
          justifyContent="flex-start"
        >
          <Text>
            Please place a check indicating one choice under race and one choice
            under ethnicity. You are under no obligation to answer any of the
            questions below.
          </Text>
        </Flex>
        <Flex pb="5px">
          <Text>Race</Text>
        </Flex>
        <Flex>
          <Controller
            name="raceSurvey"
            control={control}
            defaultValue=""
            // rules={{
            //   required: {
            //     value: true,
            //   },
            // }}
            render={props => (
              <FormControl>
                <RadioGroup
                  onChange={props.onChange}
                  value={props.value}
                  onBlur={props.onBlur}
                  w="100%"
                >
                  <Grid
                    w="100%"
                    templateColumns={{
                      base: 'repeat(1,1fr)',
                      sm: 'repeat(1,1fr)',
                      md: 'repeat(1,1fr)',
                      lg: 'repeat(2, 1fr)',
                    }}
                    gap={[2, 2, 2, 4]}
                  >
                    <GridItem w="100%">
                      <VStack alignItems="flex-start">
                        <Radio isDisabled value="americanRace">
                          American Indian or Alaska Native
                        </Radio>
                        <Radio isDisabled value="blackRace">
                          Black or African American
                        </Radio>
                        <Radio isDisabled value="whiteRace">
                          White
                        </Radio>
                        <Radio isDisabled value="unknownRace">
                          Unknown
                        </Radio>
                      </VStack>
                    </GridItem>
                    <GridItem w="100%">
                      <VStack alignItems="flex-start">
                        <Radio isDisabled value="asianRace">
                          Asian
                        </Radio>
                        <Radio isDisabled value="nativeRace">
                          Native Hawaiian or Pacific Islander
                        </Radio>
                        <Radio isDisabled value="declineRace">
                          Decline
                        </Radio>
                        <HStack>
                          <Radio isDisabled value="otherRace" />
                          <Controller
                            name="raceOther"
                            control={control}
                            defaultValue=""
                            render={props => (
                              <FormControl>
                                <Input
                                  _readOnly={{
                                    border: '1px solid #ccc',
                                  }}
                                  variant="no-effects"
                                  readOnly
                                  bg="#F9F9F9"
                                  border="1px solid black"
                                  w={{
                                    base: '100%',
                                    sm: '100%',
                                    md: 'max-content',
                                  }}
                                  placeholder="Other"
                                  value={props.value}
                                  onChange={props.onChange}
                                  onBlur={props.onBlur}
                                />
                              </FormControl>
                            )}
                          />
                        </HStack>
                      </VStack>
                    </GridItem>
                  </Grid>
                </RadioGroup>
              </FormControl>
            )}
          />
        </Flex>
        <Flex pt="5px" pb="5px">
          <Text>Ethnicity</Text>
        </Flex>
        <Controller
          name="ethnicitySurvey"
          control={control}
          defaultValue=""
          render={props => (
            <FormControl>
              <RadioGroup
                onChange={props.onChange}
                value={props.value}
                onBlur={props.onBlur}
                w="100%"
              >
                <Grid
                  w="100%"
                  templateColumns={{
                    base: 'repeat(1,1fr)',
                    sm: 'repeat(1,1fr)',
                    md: 'repeat(1,1fr)',
                    lg: 'repeat(2, 1fr)',
                  }}
                  gap={1}
                >
                  <GridItem w="100%">
                    <VStack alignItems="flex-start">
                      <Radio isDisabled value="hispanicEthnicity">
                        Hispanic or Latino Ethnicity
                      </Radio>
                      <Radio isDisabled value="declineEthnicity">
                        Decline
                      </Radio>
                    </VStack>
                  </GridItem>
                  <GridItem w="100%">
                    <VStack alignItems="flex-start">
                      <Radio isDisabled value="nonhispanicEthnicity">
                        Non Hispanic or Latino Ethnicity
                      </Radio>
                      <Radio isDisabled value="unknownEthnicity">
                        Unknown
                      </Radio>
                    </VStack>
                  </GridItem>
                </Grid>
              </RadioGroup>
            </FormControl>
          )}
        />
        <Flex pt="5px" pb="5px">
          <Text>Language</Text>
        </Flex>
        <Controller
          name="languageSurvey"
          control={control}
          rules={{
            required: {
              value: true,
              message: '',
            },
          }}
          render={props => (
            <FormControl>
              <CheckboxGroup
                onChange={e => {
                  setValue('languageSurvey', e)
                  clearErrors('languageSurvey')
                }}
                value={props.value}
                onBlur={props.onBlur}
                w="100%"
              >
                <Grid
                  w="100%"
                  templateColumns={{
                    base: 'repeat(1,1fr)',
                    sm: 'repeat(1,1fr)',
                    md: 'repeat(1,1fr)',
                    lg: 'repeat(2, 1fr)',
                  }}
                  gap={1}
                >
                  <Checkbox isDisabled value="englishLanguage">
                    English
                  </Checkbox>
                  <Checkbox isDisabled value="spanishLanguage">
                    Spanish
                  </Checkbox>
                  <Checkbox isDisabled value="russianLanguage">
                    Russian
                  </Checkbox>

                  <Checkbox isDisabled value="indianLanguage">
                    {`Indian (includes Hindi & Tamil)`}
                  </Checkbox>
                  <HStack>
                    <Checkbox isDisabled value="otherLanguage"></Checkbox>
                    <Controller
                      name="otherLanguageSpecify"
                      control={control}
                      defaultValue=""
                      render={props => (
                        <FormControl>
                          <Input
                            _readOnly={{
                              border: '1px solid #ccc',
                            }}
                            variant="no-effects"
                            readOnly
                            bg="#F9F9F9"
                            w={{ base: '100%', sm: '100%', md: 'max-content' }}
                            border="1px solid black"
                            placeholder="Other"
                            value={props.value}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                          />
                        </FormControl>
                      )}
                    />
                  </HStack>
                </Grid>
              </CheckboxGroup>
            </FormControl>
          )}
        />
        <Flex
          bg="#B7DDE9"
          fontSize="14px"
          w="100%"
          mt="20px"
          mb="10px"
          pl="10px"
          fontWeight="bold"
        >
          Advance Directive
        </Flex>
        <Controller
          name="advanceDirective"
          control={control}
          defaultValue=""
          rules={{
            required: {
              value: true,
            },
          }}
          render={props => (
            <FormControl>
              <RadioGroup
                onChange={props.onChange}
                value={props.value}
                onBlur={props.onBlur}
                w="100%"
                isDisabled
              >
                <Grid
                  w="100%"
                  templateColumns={{
                    base: 'repeat(1,1fr)',
                    sm: 'repeat(1,1fr)',
                    md: 'repeat(1,1fr)',
                    lg: 'repeat(2, 1fr)',
                  }}
                  gap={4}
                >
                  <GridItem w="100%">
                    <VStack alignItems="flex-start">
                      <Radio isDisabled value="advanceAdvance">
                        Advance Directive
                      </Radio>
                      <Radio isDisabled value="noneAdvance">
                        None on File
                      </Radio>
                    </VStack>
                  </GridItem>
                  <GridItem w="100%">
                    <VStack alignItems="flex-start">
                      <Radio isDisabled value="notAdvance">
                        {`Do Not Resucitate Order (DNR)`}
                      </Radio>
                    </VStack>
                  </GridItem>
                </Grid>
              </RadioGroup>
            </FormControl>
          )}
        />
        <Flex
          bg="#B7DDE9"
          fontSize="14px"
          w="100%"
          mt="20px"
          mb="10px"
          pl="10px"
          fontWeight="bold"
        >
          Grant Permission to leave Message
        </Flex>
        <Controller
          name="grantMessage"
          control={control}
          render={props => (
            <FormControl>
              <CheckboxGroup
                onChange={e => {
                  setValue('grantMessage', e)
                  clearErrors('grantMessage')
                }}
                value={props.value}
                onBlur={props.onBlur}
                w="100%"
                isDisabled
              >
                <Grid
                  w="100%"
                  templateColumns={{
                    base: 'repeat(1,1fr)',
                    sm: 'repeat(1,1fr)',
                    md: 'repeat(1,1fr)',
                    lg: 'repeat(2, 1fr)',
                  }}
                >
                  <Checkbox
                    isDisabled
                    alignItems="baseline"
                    value="homePhoneGrantMessage"
                  >
                    Leave message on home phone
                    <HStack>
                      <Controller
                        name="radiohHomephone"
                        control={control}
                        render={props => (
                          <RadioGroup
                            onChange={props.onChange}
                            value={props.value}
                            w="100%"
                          >
                            <HStack alignItems="flex-start">
                              <Checkbox
                                isDisabled
                                value="voiceHomephone"
                                ml="30px"
                              >
                                Voice
                              </Checkbox>
                              <Checkbox isDisabled value="textHomephone">
                                Text
                              </Checkbox>
                            </HStack>
                          </RadioGroup>
                        )}
                      />
                    </HStack>
                  </Checkbox>

                  <Checkbox
                    isDisabled
                    alignItems="baseline"
                    value="cellphoneGrantMessage"
                  >
                    Leave message on cell phone*
                    <HStack>
                      <Controller
                        name="radioCellphone"
                        control={control}
                        render={props => (
                          <RadioGroup
                            onChange={props.onChange}
                            value={props.value}
                            w="100%"
                          >
                            <HStack alignItems="flex-start">
                              <Checkbox
                                isDisabled
                                value="voiceCellphone"
                                ml="30px"
                              >
                                Voice
                              </Checkbox>
                              <Checkbox isDisabled value="textCellphone">
                                Text
                              </Checkbox>
                            </HStack>
                          </RadioGroup>
                        )}
                      />
                    </HStack>
                    <VStack></VStack>
                  </Checkbox>
                </Grid>
                <Flex
                  w="100%"
                  direction={{ base: 'column', lg: 'row' }}
                  pt={{ lg: '15px' }}
                >
                  <Checkbox
                    isDisabled
                    value="Email"
                    w={{ base: '100%', lg: '500px' }}
                  >
                    Email message to my email address:
                  </Checkbox>
                  <Controller
                    name="emailGrantMessage"
                    control={control}
                    defaultValue=""
                    render={props => (
                      <FormControl pt="10px">
                        <Input
                          _readOnly={{
                            border: '1px solid #ccc',
                          }}
                          variant="no-effects"
                          readOnly
                          bg="#F9F9F9"
                          border="1px solid black"
                          placeholder="Email"
                          value={props.value}
                          onChange={props.onChange}
                          onBlur={props.onBlur}
                        />
                      </FormControl>
                    )}
                  />
                </Flex>
              </CheckboxGroup>
            </FormControl>
          )}
        />
        <Text pt="20px" fontSize="14px">
          * Charges may be added by your carrier per your plan policy.
        </Text>
      </Box>
      <Center pt="2rem">{`6/${totalWebEnablePages}`}</Center>
    </CardContent>
  )
}

export { PatientDemographics }
