import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { useDropzone } from 'react-dropzone'
import {
    Container,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Icon,
    Center,
    Button,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    FormErrorMessage,
    FormLabel,
    FormControl,
    Select,
    Textarea,
    Input,
    Box,
    useToast,
    Heading,
    Text,
    Tag,
    SimpleGrid,
    Stack,
    HStack,
    Spinner,
    Img
} from '@chakra-ui/react'

import { ChevronRightIcon } from '@chakra-ui/icons'
import { BiSupport, BiPaperclip } from 'react-icons/bi'
import { FaUser, FaUserShield } from 'react-icons/fa'
import { isEmpty } from 'underscore'
import { collection, getDoc, doc, onSnapshot, addDoc, updateDoc, query, orderBy, where } from 'firebase/firestore'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { db, storage } from '../../config/firebase'
import renderDate from '../../config/renderDate'


function HelpDesk({ user }) {
    const [tickets, setTickets] = useState([])
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ accept: 'image/jpeg, image/png' })
    const { handleSubmit, register, reset, errors, control } = useForm()
    const [isDisabled, setIsDisabled] = useState(false)
    const [selectedTicket, setSelectedTicket] = useState(null)
    const toast = useToast()
    const params = useParams()

    useEffect(() => {
        let unsubscribe
        if (params.id) {
            unsubscribe = onSnapshot(doc(db, "Tickets", params.id), async (snapShot) => {
                let ticket = snapShot.data()
                let owner = await getDoc(doc(db, "profiles", ticket.createdBy))
                setSelectedTicket({ ...ticket, id: ticket.id, ownedBy: owner })
            })
        } else {
            unsubscribe = onSnapshot(query(collection(db, "Tickets"), where("createdBy", "==", user.uid), orderBy("createdAt", "desc")), (snapShot) => {
                let data = []
                snapShot.forEach((doc) => {
                    let queryData = doc.data()
                    data.push({ ...queryData, id: doc.id })
                })
                setTickets(data)
            })
        }
        return () => {
            unsubscribe()
        }
    }, [user.uid, params.id])

    async function onSubmit(value) {
        setIsDisabled(true)
        addDoc(collection(db, 'Tickets'), { ...value, notification: true, createdAt: new Date(), updatedAt: new Date(), createdBy: user.uid, status: "Open" })
            .then(async (result) => {
                if (result.id) {
                    if (!isEmpty(acceptedFiles)) {
                        const storageRef = ref(storage, `Tickets/${result.id}/${Math.floor(Date.now() / 1000)}-${acceptedFiles[0].name}`)
                        uploadBytes(storageRef, acceptedFiles[0]).then(async () => {
                            const downloadUrl = await getDownloadURL(storageRef)
                            updateDoc(doc(db, "Tickets", result.id), { attechment: downloadUrl })
                        })
                    }
                    toast({
                        title: "Ticket Opened.",
                        description: "Support will response as soon as.",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    })
                }
                setIsDisabled(false)
                reset()
                onClose()
                acceptedFiles.splice(0, acceptedFiles.length)
            })
            .catch((e) => {
                console.log(e)
                toast({
                    title: "Open ticket fail.",
                    description: e.message,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                })
                setIsDisabled(false)
                reset()
                onClose()
                acceptedFiles.splice(0, acceptedFiles.length)
            })
    }

    function submitComment(value) {
        setIsDisabled(true)
        let ticket = selectedTicket
        let comments = ticket.comments || []
        updateDoc(doc(db, "Tickets", params.id), {
            comments: [...comments, {
                message: value.comment,
                createdAt: new Date(),
                createdBy: user.fullName
            }]
        }).then(() => {
            setIsDisabled(false)
            reset()
        }).catch((e) => {
            setIsDisabled(false)
            reset()
            console.log(e)
        })
    }

    function ticketStatus(status) {
        if (status === "Open") {
            return "green"
        } else if (status === "On progress") {
            return "blue"
        } else if (status === "Closed") {
            return "red"
        }
    }

    return (
        <>
            <Container maxW="100%" mt="5">
                <Breadcrumb
                    spacing="8px"
                    separator={<ChevronRightIcon color="gray.500" />}
                    bg="gray.100" p="1.5"
                    borderRadius="0.3em"
                >
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to="/dashboard">Dashbaord</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to="/help-desk">Help Desk</BreadcrumbLink>
                    </BreadcrumbItem>
                    {
                        params.id && selectedTicket ?
                            <BreadcrumbItem>
                                <BreadcrumbLink>{selectedTicket.subject}</BreadcrumbLink>
                            </BreadcrumbItem>
                            :
                            params.id &&
                            <BreadcrumbItem>
                                <Spinner
                                    thickness="4px"
                                    speed="0.65s"
                                    emptyColor="gray.200"
                                    color="blue.500"
                                    size="xs"
                                />
                            </BreadcrumbItem>
                    }
                </Breadcrumb>
                {
                    params.id && selectedTicket ?
                        <Box mt="30" mb="100">
                            <Heading as="h1" size="xl" textAlign="left">{selectedTicket.subject} {selectedTicket.attechment && <Icon as={BiPaperclip} fontSize="xx-large" />}</Heading>
                            <Stack mt="5" direction={["column", "row"]}>
                                <Tag size="md" colorScheme={ticketStatus(selectedTicket.status)}>{selectedTicket.status}</Tag> &nbsp; <Tag size="md">{selectedTicket.priority}</Tag>&nbsp;
                                <Center>
                                    <Text >{selectedTicket.ownedBy.fullName}</Text>&nbsp;&nbsp;<Text fontSize="xs">opened this issue at {renderDate(selectedTicket.createdAt)} </Text>
                                </Center>
                            </Stack>
                            <Box
                                mt="5"
                                borderWidth="1px"
                                borderRadius="lg"
                                minHeight="150"
                                p="15"
                            >
                                <Text>{selectedTicket.detail}</Text>
                                {selectedTicket.attechment && <Img mt="10" src={selectedTicket.attechment} alt={selectedTicket.subject} />}
                            </Box>
                            {selectedTicket.comments && selectedTicket.comments.map((comment, index) => (
                                <Box
                                    key={index}
                                    mt="5"
                                    borderWidth="1px"
                                    borderRadius="lg"
                                    minHeight="150"
                                    p="15"
                                    bg={comment.isAdmin ? "green.50" : "blue.50"}
                                >
                                    <Text mb="4" fontSize="xs" textAlign="right">{renderDate(comment.createdAt)} By {comment.createdBy} &nbsp;<Icon as={comment.isAdmin ? FaUserShield : FaUser} fontSize={comment.isAdmin ? "lg" : "sm"} /></Text>
                                    {comment.message}

                                </Box>
                            ))}
                            <Box
                                mt="5"
                                borderWidth="1px"
                                borderRadius="lg"
                                minHeight="150"
                                p="15"
                                bg="gray.100"
                            >
                                <form onSubmit={handleSubmit(submitComment)}>
                                    <FormControl mt="3" id="comment" isInvalid={errors.comment}>
                                        <Textarea backgroundColor="white" placeholder="Leave a comment" rows="6" name="comment" type="text" ref={register({ required: 'Comment is required.' })} />
                                        <FormErrorMessage>{errors.comment && errors.comment.message}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl textAlign="right">
                                        <Button colorScheme="blue" type="submit" mt="3" isLoading={isDisabled} disabled={isDisabled}>Comment</Button>
                                    </FormControl>
                                </form>
                            </Box>
                        </Box>
                        :
                        <>
                            <Center mt="10">
                                <Button leftIcon={<Icon as={BiSupport} />} onClick={onOpen} colorScheme="blue" size="md">Open Ticket</Button>
                            </Center>
                            <SimpleGrid mt="10" minChildWidth="250px" spacing="5">
                                {
                                    tickets.map((ticket, index) => (
                                        <Box
                                            key={index}
                                            borderWidth="1px"
                                            borderRadius="lg"
                                            minH="200"
                                            as={Link}
                                            to={`/help-desk/${ticket.id}`}
                                            shadow="md"
                                        >
                                            <Box>
                                                <Box p="1">
                                                    <HStack>
                                                        <Center>
                                                            <HStack>
                                                                <Center>
                                                                    <Tag size="sm" colorScheme={ticketStatus(ticket.status)}>{ticket.status}</Tag>&nbsp;{ticket.attechment && <Icon as={BiPaperclip} fontSize="lg" />}
                                                                </Center>
                                                            </HStack>

                                                        </Center>
                                                    </HStack>
                                                </Box>
                                                <Heading m="5" mb="0" as="h4" size="md">{ticket.subject}</Heading>
                                                {/* <Text m="5" mt="3">My cool blog post</Text> */}
                                                <div>

                                                </div>
                                            </Box>
                                        </Box>
                                    ))
                                }
                            </SimpleGrid>
                        </>
                }
            </Container>
            <Modal isOpen={isOpen} onClose={onClose} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Open Ticket</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <form onSubmit={handleSubmit(onSubmit)} id="open-ticket">
                            <FormControl mt="3" id="category">
                                <FormLabel>Category</FormLabel>
                                <Controller
                                    render={(props) => (
                                        <Select name={props.name} onChange={props.onChange}>
                                            <option value="General">General</option>
                                            <option value="Customer">Customer</option>
                                            <option value="IT Support">IT Support</option>
                                        </Select>
                                    )}
                                    name="category"
                                    control={control}
                                    defaultValue={'General'}
                                />
                            </FormControl>
                            <FormControl mt="3" id="priority">
                                <FormLabel>Priority</FormLabel>
                                <Controller
                                    render={(props) => (
                                        <Select name={props.name} onChange={props.onChange}>
                                            <option value="Medium">Medium</option>
                                            <option value="Low">Low</option>
                                            <option value="High">High</option>
                                            <option value="Urgent">Urgent</option>
                                        </Select>
                                    )}
                                    name="priority"
                                    control={control}
                                    defaultValue={'Medium'}
                                />
                            </FormControl>
                            <FormControl mt="3" id="subject" isInvalid={errors.subject}>
                                <FormLabel>Subject</FormLabel>
                                <Input name="subject" type="text" ref={register({ required: 'Subject is required.' })} />
                                <FormErrorMessage>{errors.subject && errors.subject.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl mt="3" id="detail" isInvalid={errors.detail}>
                                <FormLabel>Detail</FormLabel>
                                <Textarea rows="7" name="detail" type="text" ref={register({ required: 'Detail is required.' })} />
                                <FormErrorMessage>{errors.detail && errors.detail.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl mt="3" id="computerName" isInvalid={errors.computerName}>
                                <FormLabel>Computer name</FormLabel>
                                <Input name="computerName" type="text" ref={register({ required: 'Computer name is required.' })} />
                                <FormErrorMessage>{errors.computerName && errors.computerName.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl mt="5">
                                <Box border="dashed" borderRadius="md" borderColor="gray.200" backgroundColor="gray.50" p="5">
                                    <Center>
                                        <Box {...getRootProps({ className: 'dropzone' })}>
                                            <input {...getInputProps()} />
                                            <Box mb="3">Drag 'n' drop some files here, or click to select files</Box>
                                        </Box>
                                    </Center>
                                </Box>
                                <Box p="5">
                                    <aside>
                                        <ul>
                                            {
                                                acceptedFiles.map(file => (
                                                    <li key={file.path}>
                                                        {file.path} - {(file.size / 1024 / 1024).toFixed(2)} MB
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </aside>
                                </Box>
                            </FormControl>
                            <Button type="submit" colorScheme="blue" mt="5" w="100%" size="lg" isLoading={isDisabled} disabled={isDisabled}>Submit</Button>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default HelpDesk