import { useContext, useState, createContext } from 'react'
import React from 'react'
const AppContext = createContext({
  content: null,
})

function ContextProvider({ children }) {
  const [content, setContent] = useState(null)
  const { Provider } = AppContext

  const value = {
    content,
    setContent,
  }
  return <Provider value={value}>{children}</Provider>
}

const useAppContext = () => useContext(AppContext)

export { ContextProvider, AppContext, useAppContext }
