import { Circle, Image, Svg, Text, View } from '@react-pdf/renderer'
import React, { useMemo } from 'react'
import { oswestryQuestions } from '../../Components/FormWebEnableForm/config'

function OswestryWebEnabledPDF({ fillableData, styles, stylesOswestry }) {
  const checked = (key, value) => {
    if (key.toString() !== value) return <></>
    return <Image style={stylesOswestry.imgcheck} src="/check.png" />
  }

  const sumTotal = useMemo(() => {
    if (fillableData.answers && fillableData.answers.length > 0) {
      const score = fillableData.answers.reduce(
        (sum, answer) => sum + Number(answer),
        0
      )
      return `${score * 2} %`
    }
    return 0
  }, [fillableData])

  return (
    <>
      <View style={{ ...styles.gapBox }}>
        <View
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            fontSize: 8,
          }}
        >
          <Image
            style={{ width: '110px', objectFit: 'cover' }}
            src="/paincare-web-v2.png"
          />
          <View style={styles.boxtitle}>
            <Text>2480 Liberty Street NE, Suite 180, Salem, OR 97301</Text>
          </View>
          <View style={styles.boxtitle}>
            <Text>Office: (503) 371-1010 Fax: (503) 371-0805</Text>
          </View>
        </View>
      </View>
      <View style={{ width: '100%', textAlign: 'center', margin: '10px 0px' }}>
        <Text style={{ ...styles.title, fontSize: 12 }}>
          Oswestry Disability Questionnaire
        </Text>
      </View>
      <View style={stylesOswestry.sectionTable}>
        <View style={stylesOswestry.boxTopLeft}>
          <Text style={stylesOswestry.textQuestion}>
            {oswestryQuestions[0].question}
          </Text>
          {oswestryQuestions[0].answers.map((item, index) => (
            <View key={index} style={stylesOswestry.boxAnswer}>
              <Svg style={stylesOswestry.svg}>
                <Circle cx="6" cy="5" r="2" fill="white" stroke="gray" />
              </Svg>
              <Text style={stylesOswestry.textAnswer}>{item}</Text>
              <View style={stylesOswestry.boxCheck}>
                {checked(index, fillableData?.answers?.[0])}
              </View>
            </View>
          ))}
        </View>
        <View style={stylesOswestry.boxTopRight}>
          <Text style={stylesOswestry.textQuestion}>
            {oswestryQuestions[5].question}
          </Text>
          {oswestryQuestions[5].answers.map((item, index) => (
            <View key={index} style={stylesOswestry.boxAnswer}>
              <Svg style={stylesOswestry.svg}>
                <Circle cx="6" cy="5" r="2" fill="white" stroke="gray" />
              </Svg>
              <Text style={stylesOswestry.textAnswer}>{item}</Text>
              <View style={stylesOswestry.boxCheck}>
                {checked(index, fillableData?.answers?.[5])}
              </View>
            </View>
          ))}
        </View>
      </View>
      <View style={stylesOswestry.sectionTable}>
        <View style={stylesOswestry.boxTopLeft}>
          <Text style={stylesOswestry.textQuestion}>
            {oswestryQuestions[1].question}
          </Text>
          {oswestryQuestions[1].answers.map((item, index) => (
            <View key={index} style={stylesOswestry.boxAnswer}>
              <Svg style={stylesOswestry.svg}>
                <Circle cx="6" cy="5" r="2" fill="white" stroke="gray" />
              </Svg>
              <Text style={stylesOswestry.textAnswer}>{item}</Text>
              <View style={stylesOswestry.boxCheck}>
                {checked(index, fillableData?.answers?.[1])}
              </View>
            </View>
          ))}
        </View>
        <View style={stylesOswestry.boxTopRight}>
          <Text style={stylesOswestry.textQuestion}>
            {oswestryQuestions[6].question}
          </Text>
          {oswestryQuestions[6].answers.map((item, index) => (
            <View key={index} style={stylesOswestry.boxAnswer}>
              <Svg style={stylesOswestry.svg}>
                <Circle cx="6" cy="5" r="2" fill="white" stroke="gray" />
              </Svg>
              <Text style={stylesOswestry.textAnswer}>{item}</Text>
              <View style={stylesOswestry.boxCheck}>
                {checked(index, fillableData?.answers?.[6])}
              </View>
            </View>
          ))}
        </View>
      </View>
      <View style={stylesOswestry.sectionTable}>
        <View style={stylesOswestry.boxTopLeft}>
          <Text style={stylesOswestry.textQuestion}>
            {oswestryQuestions[2].question}
          </Text>
          {oswestryQuestions[2].answers.map((item, index) => (
            <View key={index} style={stylesOswestry.boxAnswer}>
              <Svg style={stylesOswestry.svg}>
                <Circle cx="6" cy="5" r="2" fill="white" stroke="gray" />
              </Svg>
              <Text style={stylesOswestry.textAnswer}>{item}</Text>
              <View style={stylesOswestry.boxCheck}>
                {checked(index, fillableData?.answers?.[2])}
              </View>
            </View>
          ))}
        </View>
        <View style={stylesOswestry.boxTopRight}>
          <Text style={stylesOswestry.textQuestion}>
            {oswestryQuestions[7].question}
          </Text>
          {oswestryQuestions[7].answers.map((item, index) => (
            <View key={index} style={stylesOswestry.boxAnswer}>
              <Svg style={stylesOswestry.svg}>
                <Circle cx="6" cy="5" r="2" fill="white" stroke="gray" />
              </Svg>
              <Text style={stylesOswestry.textAnswer}>{item}</Text>
              <View style={stylesOswestry.boxCheck}>
                {checked(index, fillableData?.answers?.[7])}
              </View>
            </View>
          ))}
        </View>
      </View>
      <View style={stylesOswestry.sectionTable}>
        <View style={stylesOswestry.boxTopLeft}>
          <Text style={stylesOswestry.textQuestion}>
            {oswestryQuestions[3].question}
          </Text>
          {oswestryQuestions[3].answers.map((item, index) => (
            <View key={index} style={stylesOswestry.boxAnswer}>
              <Svg style={stylesOswestry.svg}>
                <Circle cx="6" cy="5" r="2" fill="white" stroke="gray" />
              </Svg>
              <Text style={stylesOswestry.textAnswer}>{item}</Text>
              <View style={stylesOswestry.boxCheck}>
                {checked(index, fillableData?.answers?.[3])}
              </View>
            </View>
          ))}
        </View>
        <View style={stylesOswestry.boxTopRight}>
          <Text style={stylesOswestry.textQuestion}>
            {oswestryQuestions[8].question}
          </Text>
          {oswestryQuestions[8].answers.map((item, index) => (
            <View key={index} style={stylesOswestry.boxAnswer}>
              <Svg style={stylesOswestry.svg}>
                <Circle cx="6" cy="5" r="2" fill="white" stroke="gray" />
              </Svg>
              <Text style={stylesOswestry.textAnswer}>{item}</Text>
              <View style={stylesOswestry.boxCheck}>
                {checked(index, fillableData?.answers?.[8])}
              </View>
            </View>
          ))}
        </View>
      </View>
      <View style={stylesOswestry.sectionTable}>
        <View style={stylesOswestry.boxBottomLeft}>
          <Text style={stylesOswestry.textQuestion}>
            {oswestryQuestions[4].question}
          </Text>
          {oswestryQuestions[4].answers.map((item, index) => (
            <View key={index} style={stylesOswestry.boxAnswer}>
              <Svg style={stylesOswestry.svg}>
                <Circle cx="6" cy="5" r="2" fill="white" stroke="gray" />
              </Svg>
              <Text style={stylesOswestry.textAnswer}>{item}</Text>
              <View style={stylesOswestry.boxCheck}>
                {checked(index, fillableData?.answers?.[4])}
              </View>
            </View>
          ))}
        </View>
        <View style={stylesOswestry.boxBottomRight}>
          <Text style={stylesOswestry.textQuestion}>
            {oswestryQuestions[9].question}
          </Text>
          {oswestryQuestions[9].answers.map((item, index) => (
            <View key={index} style={stylesOswestry.boxAnswer}>
              <Svg style={stylesOswestry.svg}>
                <Circle cx="6" cy="5" r="2" fill="white" stroke="gray" />
              </Svg>
              <Text style={stylesOswestry.textAnswer}>{item}</Text>
              <View style={stylesOswestry.boxCheck}>
                {checked(index, fillableData?.answers?.[9])}
              </View>
            </View>
          ))}
        </View>
      </View>
      <View style={stylesOswestry.boxScore}>
        <Text style={stylesOswestry.textTitleScore}>Score</Text>
        <Text style={stylesOswestry.textScore}>{sumTotal}</Text>
      </View>
    </>
  )
}

export default OswestryWebEnabledPDF
