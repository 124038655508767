/* eslint-disable react-hooks/exhaustive-deps */
import {
  AddIcon,
  ChevronRightIcon,
  DownloadIcon,
  MinusIcon,
} from '@chakra-ui/icons'
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Center,
  Checkbox,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  HStack,
  Image,
  Input,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Select,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useBreakpoint,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { DatePicker } from 'antd'
import { addDoc, collection, doc, getDoc, updateDoc } from 'firebase/firestore'
import moment from 'moment'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FaRegEdit } from 'react-icons/fa'
import Resizer from 'react-image-file-resizer'
import { Link, useHistory, useParams } from 'react-router-dom'
import SignatureCanvas from 'react-signature-canvas'
import { db } from '../../config/firebase'
import { SwitchInputComponent } from '../../utils/switchInput'

export const resizeFile = file =>
  new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      'JPEG',
      100,
      0,
      uri => {
        resolve(uri)
      },
      'base64'
    )
  })

const _ = require('underscore')
function InfectionsAndEmergencyVisitForm() {
  const params = useParams()
  const history = useHistory()
  const { handleSubmit, register, errors, control, setValue, watch, trigger } =
    useForm()
  const toast = useToast()

  const sigCanvas = useRef({})

  const clear = () => sigCanvas.current.clear()
  const { isOpen, onToggle, onClose } = useDisclosure()
  // const [dataCheckBox, setDataCheckBox] = useState([])
  const breakPoint = useBreakpoint()
  const signatureCollector = watch('signatureCollector')
  const [loading, setLoading] = useState()
  const [isLoading, setIsLoading] = useState(true)

  const fileUploadInputRef = useRef(null)
  const [data, setData] = useState()
  const [rowNumber, setRowNumber] = useState(1)

  const facility = data?.facility || (watch('facility') ?? 'PCSO')

  const inputCount = useMemo(() => {
    return [...Array(rowNumber).keys()]
  }, [rowNumber])

  function onClickFileUpload() {
    fileUploadInputRef && fileUploadInputRef.current.click()
  }

  async function onUploadChange(event) {
    const file = event.target?.files[0]

    if (
      file?.type === 'image/jpg' ||
      file?.type === 'image/jpeg' ||
      file?.type === 'image/png'
    ) {
      const image = await resizeFile(file)
      setValue('signatureCollector', image)
      toast({
        duration: 3000,
        title: 'successfully uploaded.',
        status: 'success',
        position: 'top',
        isClosable: true,
      })
      onClose()
    } else {
      toast({
        duration: 3000,
        title: 'only file .jpg, jpeg and .png are supported.',
        status: 'info',
        position: 'top',
        isClosable: true,
      })
    }
  }

  const save = () =>
    setValue(
      'signatureCollector',
      sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')
    )

  const [infactionCheckedYes, setInfactionCheckedYes] = useState(
    inputCount.map(v => false)
  )
  const allYesInfectionChecked = infactionCheckedYes.every(Boolean)
  const isYesInfectionIndeterminate =
    infactionCheckedYes.some(Boolean) && !allYesInfectionChecked

  const [infactionCheckedNo, setInfactionCheckedNo] = useState(
    inputCount.map(v => false)
  )
  const allNoInfectionChecked = infactionCheckedNo.every(Boolean)
  const isNoInfectionIndeterminate =
    infactionCheckedNo.some(Boolean) && !allNoInfectionChecked

  const [erVisitCheckedYes, setErVisitCheckedYes] = useState(
    inputCount.map(v => false)
  )
  const allYesErVisitChecked = erVisitCheckedYes.every(Boolean)
  const isYesErVisitIndeterminate =
    erVisitCheckedYes.some(Boolean) && !allYesErVisitChecked

  const [erVisitCheckedNo, setErVisitCheckedNo] = useState(
    inputCount.map(v => false)
  )
  const allNoErVisitChecked = erVisitCheckedNo.every(Boolean)
  const isNoErVisitIndeterminate =
    erVisitCheckedNo.some(Boolean) && !allNoErVisitChecked

  function setDataCheckBox(data, i) {
    const inYes = infactionCheckedYes
    inYes[i] = data[`${i}_infection_yes`]
    setInfactionCheckedYes(inYes)

    const inNo = infactionCheckedNo
    inNo[i] = data[`${i}_infection_no`]
    setInfactionCheckedNo(inNo)

    const erYes = erVisitCheckedYes
    erYes[i] = data[`${i}_er_visit_yes`]
    setErVisitCheckedYes(erYes)

    const erNo = erVisitCheckedNo
    erNo[i] = data[`${i}_er_visit_no`]
    setErVisitCheckedNo(erNo)
  }

  useEffect(() => {
    if (params.id) {
      getDoc(doc(db, 'InfectionEmergencyForm', params.id))
        .then(doc => {
          const results = {
            ...doc.data(),
            id: doc.id,
            monthYear: moment(doc?.monthYear),
          }

          const obj = Object.keys(results)
          let arrNumber = []

          obj.map((e, i) => {
            const split = e.split('_')
            const number =
              _.isNaN(parseInt(split[0])) === false && parseInt(split[0])
            return arrNumber.push(number)
          })
          // eslint-disable-next-line array-callback-return
          const numberGroup = arrNumber?.filter(f => f !== false && f !== true)
          const groupKeys = _.keys(_.groupBy(numberGroup))?.map((e, i) => {
            const erYes = results[`${e}_er_visit_yes`] === 'Yes' ? 'Yes' : false
            const erNo = results[`${e}_er_visit_no`] === 'No' ? 'No' : false
            const infectionYes =
              results[`${e}_infection_yes`] === 'Yes' ? 'Yes' : false
            const infectionNo =
              results[`${e}_infection_no`] === 'No' ? 'No' : false

            return {
              [`${e}_er_visit_yes`]: erYes,
              [`${e}_er_visit_no`]: erNo,
              [`${e}_infection_yes`]: infectionYes,
              [`${e}_infection_no`]: infectionNo,
            }
          })

          const numKeys = groupKeys?.map((e, i) => i)
          setInfactionCheckedYes(numKeys.map(v => false))
          setInfactionCheckedNo(numKeys.map(v => false))
          setErVisitCheckedYes(numKeys.map(v => false))
          setErVisitCheckedNo(numKeys.map(v => false))
          setData({ ...doc.data(), groupKeys })

          setRowNumber(groupKeys?.length)
          // console.log(results.length)
          _.keys(groupKeys).map((e, i) => {
            const data = groupKeys[e]
            return setDataCheckBox(data, i)
          })
        })
        .then(() => {
          setIsLoading(false)
        })
    } else {
      setIsLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])
  function onDateChange(e, i) {
    setValue(`${i}_date`, e?.format('MM-DD-YYYY'))
  }
  function onSubmit(values) {
    console.log(values)
    setLoading(true)
    if (params.id) {
      console.log(values)
      const docRef = doc(db, 'InfectionEmergencyForm', params.id)
      return updateDoc(docRef, {
        ...values,
        monthYear: values?.monthYear?.toDate(),
        updatedAt: new Date(),
        notification: true,
        status: 'Success',
      }).then(() => {
        history.push('/infections-and-emergency-visit')
        toast({
          title: 'Submit Successfully',
          description: 'Form has been submmited.',
          status: 'success',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
      })
    } else {
      const colRef = collection(db, 'InfectionEmergencyForm')
      return addDoc(colRef, {
        ...values,
        signatureCollector: values.signatureCollector ?? null,
        monthYear: values?.monthYear?.toDate(),
        createdAt: new Date(),
        notification: true,
        status: 'Draft',
      }).then(() => {
        history.push('/infections-and-emergency-visit')
        toast({
          title: 'Submit Successfully',
          description: 'Form has been submmited.',
          status: 'success',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
      })
    }
  }

  return (
    <Container maxW="100%" mt="5">
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
        bg="gray.100"
        p="1.5"
        borderRadius="0.3em"
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/dashboard">
            Dashbaord
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/infections-and-emergency-visit">
            Infections and emergency visit
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="#">
            {data?.physician ? data?.physician : '-'}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      {isLoading === true ? (
        <Center w="100%" h="20rem">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="lg"
          />
        </Center>
      ) : (
        <>
          <Box>
            <Stack alignItems="center" spacing="1rem">
              <Image
                src={
                  (data && data.facility === 'PCSO') ||
                  (facility && facility === 'PCSO')
                    ? '/paincare-web.png'
                    : '/LogoOSSC.jpeg'
                }
                width="325px"
              />
              <Text align="center" fontSize="xs">
                {(data && data.facility === 'PCSO') ||
                (facility && facility === 'PCSO') ? (
                  <>
                    2480 Liberty Street NE, Suite 180, Salem, OR 97301 <br />
                    Phone: (503) 371-1010 Fax: (503) 371-0805
                  </>
                ) : (
                  <>
                    Oregon Specialists Surgery Center 2785 River Road S, Salem,
                    OR 97302
                  </>
                )}
              </Text>
              <Text fontSize="2xl" fontWeight="bold">
                Infections and Emergency Room Visit Form
              </Text>
            </Stack>
          </Box>
          <Box mt="4rem">
            <Flex w="100%" justifyContent="end">
              <Button
                leftIcon={<DownloadIcon />}
                colorScheme="blue"
                as={Link}
                to={`/infections-and-emergency-visit-form/pdf/${params?.id}`}
              >
                EXPORT PDF
              </Button>
            </Flex>
            <form onSubmit={handleSubmit(onSubmit)} id="time-away-request-form">
              <Box>
                <HStack>
                  <FormControl id="physician" isInvalid={errors?.physician}>
                    <FormLabel>Physician</FormLabel>
                    <Input
                      name="physician"
                      placeholder="Physician"
                      ref={register({
                        required: 'Physician required.',
                      })}
                      defaultValue={data?.physician ?? ''}
                    />
                  </FormControl>
                  <FormControl id="monthYear" isInvalid={errors?.monthYear}>
                    <FormLabel>Month/Year</FormLabel>

                    <Controller
                      render={props => (
                        <DatePicker
                          style={{
                            width: '100%',
                            height: '100%',
                          }}
                          value={props?.value}
                          picker="month"
                          onChange={props?.onChange}
                          format="MM/YYYY"
                        />
                      )}
                      name="monthYear"
                      control={control}
                      defaultValue={
                        data?.monthYear
                          ? moment(data?.monthYear?.toDate())
                          : undefined
                      }
                      rules={{ required: 'Date required.' }}
                    />
                    <FormErrorMessage>
                      {errors?.date && errors?.date?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl id="facility" isInvalid={errors?.facility}>
                    <FormLabel>Facility</FormLabel>

                    <Controller
                      render={props => (
                        <Select value={props?.value} onChange={props?.onChange}>
                          <option value="PCSO">PCSO</option>
                          <option value="OSSC">OSSC</option>
                        </Select>
                      )}
                      name="facility"
                      control={control}
                      defaultValue={data?.facility || 'PCSO'}
                      rules={{ required: 'Facility required.' }}
                    />
                  </FormControl>
                </HStack>
              </Box>
              <TableContainer>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th rowSpan={3} width="15%">
                        Date of Services
                      </Th>
                    </Tr>
                    <Tr>
                      <Th colSpan={2} textAlign="center">
                        Patient name
                      </Th>
                      <Th colSpan={2} width="10%" textAlign="center">
                        Infection
                      </Th>
                      <Th colSpan={2} width="10%" textAlign="center">
                        ER Visit
                      </Th>
                      <Th textAlign="center" width="20%" rowSpan={2}>
                        Note
                      </Th>
                    </Tr>
                    <Tr>
                      <Th textAlign="center">First</Th>
                      <Th textAlign="center">Last</Th>
                      <Th textAlign="center">
                        <Checkbox
                          isChecked={allYesInfectionChecked}
                          isIndeterminate={isYesInfectionIndeterminate}
                          onChange={e =>
                            setInfactionCheckedYes(
                              inputCount?.map(v => e.target.checked)
                            )
                          }
                        />
                        &nbsp; Yes
                      </Th>
                      <Th textAlign="center">
                        <Checkbox
                          isChecked={allNoInfectionChecked}
                          isIndeterminate={isNoInfectionIndeterminate}
                          onChange={e =>
                            setInfactionCheckedNo(
                              inputCount?.map(v => e.target.checked)
                            )
                          }
                        />
                        &nbsp; No
                      </Th>
                      <Th textAlign="center">
                        <Checkbox
                          isChecked={allYesErVisitChecked}
                          isIndeterminate={isYesErVisitIndeterminate}
                          onChange={e =>
                            setErVisitCheckedYes(
                              inputCount?.map(v => e.target.checked)
                            )
                          }
                        />
                        &nbsp;Yes
                      </Th>
                      <Th textAlign="center">
                        <Checkbox
                          isChecked={allNoErVisitChecked}
                          isIndeterminate={isNoErVisitIndeterminate}
                          onChange={e =>
                            setErVisitCheckedNo(
                              inputCount?.map(v => e.target.checked)
                            )
                          }
                        />
                        &nbsp;No
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {inputCount?.map((v, i) => {
                      return (
                        <Tr key={i}>
                          <Td>
                            <Controller
                              render={props => {
                                return (
                                  <DatePicker
                                    onChange={e => onDateChange(e, i)}
                                    value={
                                      props?.value
                                        ? moment(props.value)
                                        : undefined
                                    }
                                    size="large"
                                    format="MM-DD-YYYY"
                                    style={{
                                      width: '100%',
                                      borderRadius: '0.6em',
                                    }}
                                  />
                                )
                              }}
                              name={`${i}_date`}
                              control={control}
                              defaultValue={data && data[`${i}_date`]}
                              rules={{ required: 'Date required.' }}
                            />
                          </Td>
                          <Td>
                            <Input
                              name={`${i}_firstName`}
                              placeholder="First name"
                              ref={register({
                                required: 'First name required.',
                              })}
                              defaultValue={data && data[`${i}_firstName`]}
                            />
                          </Td>
                          <Td>
                            <Input
                              name={`${i}_lastName`}
                              placeholder="Last name"
                              ref={register({
                                required: 'Last name required.',
                              })}
                              defaultValue={data && data[`${i}_lastName`]}
                            />
                          </Td>

                          <Td textAlign="center">
                            <Checkbox
                              name={`${i}_infection_yes`}
                              isChecked={infactionCheckedYes[i]}
                              onChange={e => {
                                let arr = infactionCheckedYes.map(
                                  (v, index) => {
                                    if (index === i) {
                                      return e.target.checked
                                    } else {
                                      return v
                                    }
                                  }
                                )
                                setInfactionCheckedYes(arr)
                              }}
                              ref={register}
                              value="Yes"
                            />
                          </Td>
                          <Td textAlign="center">
                            <Checkbox
                              name={`${i}_infection_no`}
                              isChecked={infactionCheckedNo[i]}
                              onChange={e => {
                                let arr = infactionCheckedNo.map((v, index) => {
                                  if (index === i) {
                                    return e.target.checked
                                  } else {
                                    return v
                                  }
                                })
                                setInfactionCheckedNo(arr)
                              }}
                              ref={register}
                              value="No"
                            />
                          </Td>

                          <Td textAlign="center">
                            <Checkbox
                              name={`${i}_er_visit_yes`}
                              isChecked={erVisitCheckedYes[i]}
                              onChange={e => {
                                let arr = erVisitCheckedYes.map((v, index) => {
                                  if (index === i) {
                                    return e.target.checked
                                  } else {
                                    return v
                                  }
                                })
                                setErVisitCheckedYes(arr)
                              }}
                              ref={register}
                              value="Yes"
                            />
                          </Td>
                          <Td textAlign="center">
                            <Checkbox
                              name={`${i}_er_visit_no`}
                              isChecked={erVisitCheckedNo[i]}
                              onChange={e => {
                                let arr = erVisitCheckedNo.map((v, index) => {
                                  if (index === i) {
                                    return e.target.checked
                                  } else {
                                    return v
                                  }
                                })
                                setErVisitCheckedNo(arr)
                              }}
                              ref={register}
                              value="No"
                            />
                          </Td>

                          <Td>
                            <Input
                              name={`${i}_note`}
                              placeholder="Note"
                              ref={register}
                              defaultValue={data && data[`${i}_note`]}
                            />
                          </Td>
                        </Tr>
                      )
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
              <HStack py="1.75em" justifyContent="end">
                <Button
                  size="xs"
                  colorScheme={'blue'}
                  onClick={() => {
                    setRowNumber(rowNumber + 1)
                  }}
                >
                  <AddIcon />
                </Button>
                <Button
                  size="xs"
                  onClick={() => {
                    setRowNumber(rowNumber - 1)
                  }}
                >
                  <MinusIcon />
                </Button>
              </HStack>
              {/* <Center mt="3rem">
            <HStack w="30%" justifyContent="space-between">
              <Box borderBottom="1px">Signature</Box>
              <Box borderBottom="1px">Month/Date/Year</Box>
            </HStack>
          </Center> */}

              <Flex alignItems="end" w="100%" justifyContent="center">
                <Controller
                  name="signatureCollector"
                  control={control}
                  rules={{ required: false }}
                  defaultValue={data?.signatureCollector}
                  render={({ field }) => {
                    return (
                      <FormControl
                        w="30vw"
                        isInvalid={errors['signatureCollector']}
                      >
                        <GridItem {...field}>
                          <VStack spacing="0px" justifyContent={'flex-start'}>
                            <Center h={{ base: '80px' }}>
                              <Text
                                fontWeight="bold"
                                fontSize="15"
                                textAlign="center"
                              >
                                Signature of Collector
                              </Text>
                            </Center>
                            <HStack
                              h="40px"
                              w="100%"
                              flexDirection="row"
                              borderBottom={'1px solid #777'}
                            >
                              <Center w="100%">
                                <SwitchInputComponent
                                  data={
                                    signatureCollector ||
                                    data?.signatureCollector
                                  }
                                />
                              </Center>
                              <Flex
                                h="40px"
                                alignItems="flex-end"
                                justifyContent="flex-end"
                              >
                                <Popover
                                  placement="top"
                                  isOpen={isOpen}
                                  onClose={onClose}
                                >
                                  <PopoverTrigger p="0px">
                                    <Button
                                      bg="white"
                                      onClick={() => {
                                        setValue('signatureCollector', ' ')
                                        onToggle()
                                      }}
                                    >
                                      <FaRegEdit size="25" color="gray" />
                                    </Button>
                                  </PopoverTrigger>
                                  <Portal size="auto">
                                    <PopoverContent
                                      w={{
                                        base: '20rem',
                                        sm: '20rem',
                                        md: '30rem',
                                      }}
                                      h="auto"
                                      boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                                    >
                                      <PopoverArrow />
                                      <Box w="100%" h="100%">
                                        <Text
                                          p="0.75rem"
                                          fontSize="12px"
                                          color="grey"
                                        >
                                          <span
                                            style={{ color: 'red' }}
                                          >{`*`}</span>
                                          {`Any signature (including any electronic symbol
                                or process attached to, or associated with, a
                                contract or other record and adopted by a Person
                                with the intent to sign, authenticate or accept
                                such contract or record)`}
                                        </Text>

                                        <Box p="0.75rem" pt="1rem" h="auto">
                                          <Tabs variant="enclosed">
                                            <TabList>
                                              {/* <Tab
                                          onClick={() => {
                                            clear()
                                          }}
                                        >
                                          Text Signature
                                        </Tab> */}
                                              <Tab
                                                onClick={() => {
                                                  setValue(
                                                    'signatureCollector',
                                                    ' '
                                                  )
                                                }}
                                              >
                                                Draw Signature
                                              </Tab>
                                              <Tab
                                                onClick={() => {
                                                  setValue(
                                                    'signatureCollector',
                                                    ' '
                                                  )
                                                  clear()
                                                }}
                                              >
                                                Upload Signature
                                              </Tab>
                                            </TabList>
                                            <TabPanels h="auto">
                                              {/* <TabPanel
                                          h="22rem"
                                          display="flex"
                                          flexDirection="column"
                                        >
                                          <Box
                                            ml={{ base: '10px' }}
                                            mt={{ base: '10px' }}
                                            mr={{ base: '10px' }}
                                            h="100%"
                                          >
                                            <Box h="100%">
                                              <Controller
                                                name="signatureCollector"
                                                control={control}
                                                defaultValue={''}
                                                render={({ field }) => (
                                                  <FormControl>
                                                    <Input
                                                      {...field}
                                                      id="signatureTextInput"
                                                      placeholder="Name"
                                                      onChange={event => {
                                                        setValue(
                                                          'signatureCollector',
                                                          event.target.value
                                                        )
                                                      }}
                                                    />
                                                  </FormControl>
                                                )}
                                              />
                                              <Center w="100%">
                                                <Text
                                                  pt="2rem"
                                                  fontFamily={'Homemade Apple'}
                                                  fontSize={{
                                                    base: '18px',
                                                    sm: '18px',
                                                    md: '22px',
                                                  }}
                                                >
                                                  {watch('signatureCollector')}
                                                </Text>
                                              </Center>
                                            </Box>
                                          </Box>
                                          <Flex
                                            pl="10px"
                                            pb="5px"
                                            pr="10px"
                                            justifyContent="flex-end"
                                            h="max-content"
                                            alignItems="end"
                                          >
                                            <Button
                                              mt={4}
                                              h="1.8rem"
                                              fontSize="14px"
                                              w="4.5rem"
                                              bg="#17A2B8"
                                              color="white"
                                              colorScheme="cyan"
                                              onClick={async () => {
                                                let triggerValue =
                                                  await trigger(
                                                    'signatureCollector',
                                                    'Name'
                                                  )
                                                if (triggerValue) {
                                                  setValue(
                                                    'signatureCollector',
                                                    signatureCollector
                                                  )
                                                  onClose()
                                                }
                                              }}
                                            >
                                              Confirm
                                            </Button>
                                          </Flex>
                                        </TabPanel> */}
                                              <TabPanel
                                                h="22rem"
                                                display="flex"
                                                flexDirection="column"
                                              >
                                                <Box
                                                  h="100%"
                                                  border="1px solid #F0F0F0"
                                                >
                                                  <SignatureCanvas
                                                    style={{
                                                      backgroundColor: 'red',
                                                    }}
                                                    penColor="black"
                                                    canvasProps={{
                                                      width: switchCanvas(
                                                        breakPoint,
                                                        'width'
                                                      ),
                                                      height: switchCanvas(
                                                        breakPoint,
                                                        'height'
                                                      ),
                                                    }}
                                                    ref={sigCanvas}
                                                  />
                                                  <Flex
                                                    pl="10px"
                                                    pb="5px"
                                                    pr="10px"
                                                    justifyContent="space-between"
                                                    h="max-content"
                                                    alignItems="end"
                                                  >
                                                    <Center>
                                                      <Button
                                                        fontSize="14px"
                                                        px="1rem"
                                                        h="1.7rem"
                                                        colorScheme="blackAlpha"
                                                        onClick={() => clear()}
                                                      >
                                                        Clear
                                                      </Button>
                                                    </Center>
                                                    <Button
                                                      mt={4}
                                                      h="1.8rem"
                                                      fontSize="14px"
                                                      w="4.5rem"
                                                      bg="#17A2B8"
                                                      color="white"
                                                      colorScheme="cyan"
                                                      onClick={async () => {
                                                        let triggerValue =
                                                          await trigger(
                                                            'signatureCollector'
                                                          )
                                                        if (triggerValue) {
                                                          onClose()
                                                          save()
                                                        } else {
                                                        }
                                                      }}
                                                    >
                                                      Confirm
                                                    </Button>
                                                  </Flex>
                                                </Box>
                                              </TabPanel>
                                              <TabPanel
                                                h="22rem"
                                                display="flex"
                                                flexDirection="column"
                                              >
                                                <Center h="100%" w="100%">
                                                  <Input
                                                    type="file"
                                                    display="none"
                                                    ref={fileUploadInputRef}
                                                    onChange={onUploadChange}
                                                  />
                                                  <Button
                                                    color="#AEAEAE"
                                                    border="2px solid #AEAEAE"
                                                    bg="transparent"
                                                    _hover={{
                                                      bg: '#AEAEAE',
                                                      color: '#fff',
                                                      opacity: '0.5',
                                                    }}
                                                    _active={{
                                                      bg: '#AEAEAE',
                                                      color: '#fff',
                                                      opacity: '1',
                                                    }}
                                                    onClick={onClickFileUpload}
                                                  >
                                                    Upload
                                                  </Button>
                                                </Center>
                                                <Flex
                                                  pl="10px"
                                                  pb="5px"
                                                  pr="10px"
                                                  justifyContent="flex-end"
                                                  h="max-content"
                                                  alignItems="end"
                                                >
                                                  <Button
                                                    mt={4}
                                                    h="1.8rem"
                                                    fontSize="14px"
                                                    w="4.5rem"
                                                    bg="#17A2B8"
                                                    color="white"
                                                    colorScheme="cyan"
                                                    onClick={async () => {
                                                      let triggerValue =
                                                        await trigger(
                                                          'signaturePatient'
                                                        )
                                                      if (triggerValue) {
                                                        onClose()
                                                      } else {
                                                      }
                                                    }}
                                                  >
                                                    Confirm
                                                  </Button>
                                                </Flex>
                                              </TabPanel>
                                            </TabPanels>
                                          </Tabs>
                                        </Box>
                                      </Box>
                                    </PopoverContent>
                                  </Portal>
                                </Popover>
                              </Flex>
                            </HStack>
                          </VStack>
                        </GridItem>
                        <FormErrorMessage>
                          {errors['signatureCollector'] &&
                            errors['signatureCollector'].message}
                        </FormErrorMessage>
                      </FormControl>
                    )
                  }}
                />

                {/* <Spacer /> */}
                <Box mx={9}>
                  <FormControl isRequired>
                    <Controller
                      render={props => (
                        <Input
                          value={props.value}
                          onChange={props.onChange}
                          isReadOnly
                          textAlign={'center'}
                        />
                      )}
                      name="date"
                      control={control}
                      defaultValue={moment().format('YYYY-MM-DD')}
                      rules={{ required: 'Date is required.' }}
                    />
                    <FormLabel textAlign={'center'}>Date</FormLabel>
                  </FormControl>
                </Box>
              </Flex>

              <Center mt="3rem" pb="2rem">
                <Button type="submit" isLoading={loading}>
                  Submit
                </Button>
              </Center>
            </form>
          </Box>
        </>
      )}
    </Container>
  )
}

export default InfectionsAndEmergencyVisitForm

export function switchCanvas(bp, type) {
  if (type === 'width') {
    switch (bp) {
      case 'base':
        return 275
      case 'sm':
        return 275
      case 'md':
        return 425
      case 'lg':
        return 425
      case 'xl':
        return 425
      case '2xl':
        return 425
      default:
        return 150
    }
  }
  if (type === 'height') {
    switch (bp) {
      case 'base':
        return 150
      case 'sm':
        return 150
      case 'md':
        return 225
      case 'lg':
        return 225
      case 'xl':
        return 225
      case '2xl':
        return 225
      default:
        return 150
    }
  }
}
