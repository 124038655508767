import moment from 'moment'
import { doc, getDoc } from 'firebase/firestore'
import { Link, useParams } from 'react-router-dom'
import { ChevronRightIcon } from '@chakra-ui/icons'
import React, { useCallback, useEffect, useState } from 'react'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Spinner,
} from '@chakra-ui/react'
import {
  Image,
  PDFViewer,
  StyleSheet,
  Document,
  Page,
  View,
} from '@react-pdf/renderer'
import { db } from '../../../config/firebase'
import RenderCRNAANSPDF from './pdfCRNAANS'
import RenderMDPDF from './pdfMD'
import RenderNursesPDF from './pdfNurses'
import Cover from './cover'

const styles = StyleSheet.create({
  pdfView: {
    width: window.innerWidth,
    height: window.innerHeight,
    backgroundColor: '#fff',
  },
  page: {
    width: '100%',
    flexDirection: 'column',
    backgroundColor: '#fff',
    alignItems: 'center',
    paddingLeft: 40,
    paddingRight: 40,
  },
  sectionImg: {
    width: '50%',
    marginTop: 35,
    padding: 0,
    alignItems: 'center',
  },
  boxTitle: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '12px',
  },
  textBorder: {
    borderBottom: 1,
    textAlign: 'center',
  },
})

function ViewPeerReviewPDF() {
  const params = useParams()
  const [data, setData] = useState()

  const fetchFormData = useCallback(async id => {
    await getDoc(doc(db, 'PeerReviewForm', id)).then(doc => {
      const results = {
        ...doc.data(),
        id: doc.id,
        period:
          doc.data().type === 'Nurses'
            ? doc.data().period
            : moment(doc.data().period).format('Y-[Q]Q'),
      }
      setData(results)
    })
  }, [])

  useEffect(() => {
    if (params.id) fetchFormData(params.id)
  }, [fetchFormData, params])

  const renderPDF = value => {
    const crnaans = ['CRNAANS', 'MDANS']
    if (crnaans.includes(data?.type)) return <RenderCRNAANSPDF data={value} />
    if (data?.type === 'MD') return <RenderMDPDF data={value} />
  }

  return (
    <>
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
        bg="gray.100"
        p="1.5"
        borderRadius="0.3em"
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/dashboard">
            Dashbaord
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/peer-review">
            Peer Review
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="#">
            PDF
          </BreadcrumbLink>
        </BreadcrumbItem>
        {data && (
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="#">
              {data?.anesthesia
                ? data?.anesthesia
                : data?.list?.[0]?.anesthesia || '-'}
            </BreadcrumbLink>
          </BreadcrumbItem>
        )}
      </Breadcrumb>
      {data ? (
        <>
          {data.type === 'Nurses' ? (
            <RenderNursesPDF data={data} />
          ) : (
            <PDFViewer style={styles.pdfView}>
              <Document>
                <Cover data={data} />
                {data?.list?.map((item, index) => (
                  <Page key={index} size="A4" style={styles.page}>
                    <View style={styles.sectionImg}>
                      <Image
                        style={styles.img}
                        src={
                          data.facility === 'PCSO'
                            ? '/paincare-web.png'
                            : '/LogoOSSC.jpeg'
                        }
                      />
                    </View>
                    {renderPDF(item)}
                  </Page>
                ))}
              </Document>
            </PDFViewer>
          )}
        </>
      ) : (
        <Flex
          minW={'100%'}
          minH={'100vh'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <p>loading...</p>
          <Spinner ml={2} size={'md'} />
        </Flex>
      )}
    </>
  )
}

export default ViewPeerReviewPDF
