import React, { useState } from 'react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Container,
  Flex,
  Box,
  Spacer,
  Image,
  Button,
  FormErrorMessage,
  FormControl,
  Input,
  Center,
  FormLabel,
  RadioGroup,
  Stack,
  Radio,
  useToast,
} from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'

import { addDoc, collection } from 'firebase/firestore'
import Logo from '../../../Logo.png'
import { db } from '../../../config/firebase'

export const oswestryQuestions = [
  {
    question: 'Section 1: Pain Intensity',
    answers: [
      'I have no pain at the moment',
      'The pain is very mild at the moment',
      'The pain is moderate at the moment',
      'The pain is fairly severe at the moment',
      'The pain is very severe at the moment',
      'The pain is the worst imaginable at the moment',
    ],
  },
  {
    question: 'Section 2: Personal Care (eg. bathing, dressing)',
    answers: [
      'I can look after myself normally without causing extra pain',
      'I can look after myself normally, but it causes extra pain',
      'It is painful to look after myself and I am slow and careful',
      'I need some help, but manage most of my personal care',
      'I need help every day in most aspects of self-care',
      'I do not get dressed, wash with difficulty, and stay in bed',
    ],
  },
  {
    question: 'Section 3: Lifting',
    answers: [
      'I can lift heavy weights without extra pain',
      'I can lift heavy weights, but it gives me extra pain',
      'Pain prevents me from lifting heavy weights off the floor, but I can manage if they are conveniently placed (eg. on a table)',
      'Pain prevents me from lifting heavy weights, but I can manage light to medium weights if they are conveniently positioned',
      'I can only lift very light weights',
      'I cannot lift or carry anything',
    ],
  },
  {
    question: 'Section 4: Walking',
    answers: [
      'Pain does not prevent me from walking any distance',
      'Pain prevents me from walking more than 1 mile',
      'Pain prevents me from walking more than 0.6 miles',
      'Pain prevents me from walking more than 0.3 miles',
      'I can only walk using a stick or crutches',
      'I am in bed most of the time',
    ],
  },
  {
    question: 'Section 5: Sitting',
    answers: [
      'I can sit in any chair as long as I like',
      'I can only sit in my favorite chair as long as I like',
      'Pain prevents me from sitting more than one hour',
      'Pain prevents me from sitting more than 30 minutes',
      'Pain prevents me from sitting more than 10 minutes',
      'Pain prevents me from sitting at all',
    ],
  },
  {
    question: 'Section 6: Standing',
    answers: [
      'I can stand as long as I want without extra pain',
      'I can stand as long as I want, but it gives me extra pain',
      'Pain prevents me from standing for more than 1 hour',
      'Pain prevents me from standing for more than 30 minutes',
      'Pain prevents me from standing for more than 10 minutes',
      'Pain prevents me from standing at all',
    ],
  },
  {
    question: 'Section 7: Sleeping',
    answers: [
      'My sleep is never disturbed my pain',
      'My sleep is occasionally disturbed by pain',
      'Because of my pain I have less than 6 hours of sleep',
      'Because of my pain I have less than 4 hours of sleep',
      'Because of my pain I have less than 2 hours of sleep',
      'Pain prevents me from sleeping at all',
    ],
  },
  {
    question: 'Section 8: Changing Degrees of Pain',
    answers: [
      'My pain is rapidly improving',
      'My pain fluctuates, but overall is improving',
      'My pain seems to be getting better, but currently the improvement is slow',
      'My pain is neither getting better nor worse',
      'My pain is gradually worsening',
      'My pain is rapidly worsening',
    ],
  },
  {
    question: 'Section 9: Social Life',
    answers: [
      'My social life is normal and gives me no extra pain',
      'My social life is normal, but increases the degree of pain',
      'Pain has no significant effect on my social life apart from limiting more energetic interests (eg. sports)',
      'Pain has restricted my social life and I do not go out as often',
      'Pain has restricted my social life to home',
      'I have no social life because of pain',
    ],
  },
  {
    question: 'Section 10: Traveling',
    answers: [
      'I can travel anywhere without pain',
      'I can travel anywhere, but it gives me extra pain',
      'Pain is bad but I manage journeys over 2 hours',
      'Pain restricts me to journeys of less than 1 hour',
      'Pain restricts me to short necessary journeys under 30 minutes',
      'Pain prevents me from traveling except to receive treatment',
    ],
  },
]

export default function OswestryForm() {
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    reset,
  } = useForm()
  const toast = useToast()
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  const calTotal = value => {
    return value.reduce((aac, item) => aac + Number(item), 0)
  }

  const calScoreType = value => {
    if (value >= 0 && value <= 4) return 'No disability'
    if (value >= 5 && value <= 14) return 'Mild disability'
    if (value >= 15 && value <= 24) return 'Moderate disability'
    if (value >= 25 && value <= 34) return 'Severe disability'
    if (value >= 35) return 'Completely disability'
  }

  const onSubmit = ({ form }) => {
    try {
      setLoading(true)
      const total = calTotal(form.answers)
      const scoreType = calScoreType(total)
      addDoc(collection(db, 'PatientForm'), {
        ...form,
        total: total,
        scoreType: scoreType,
        createdAt: new Date(),
        notification: true,
        status: 'Pending',
        facility: 'PCSO',
        type: 'Oswestry Disability',
      })
      setLoading(false)
      reset()
      history.push('/patient/success')
    } catch (error) {
      toast({
        title: 'Submit Failed',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
      setLoading(false)
    }
  }
  return (
    <Container maxW="100%" mt="5" mb="10">
      <Image
        cursor="pointer"
        src={Logo}
        alt="paincare logo"
        htmlWidth="100px"
      />
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
        bg="gray.100"
        p="1.5"
        mt="5"
        borderRadius="0.3em"
      >
        <BreadcrumbItem>
          <BreadcrumbLink>
            Oswestry Disability Questionnaire Form
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex my="10">
        <Box pt="2" fontSize="18" fontWeight="bold">
          Oswestry Disability Questionnaire Form
        </Box>
        <Spacer />
      </Flex>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl
          id="date"
          mb="5"
          isRequired
          isInvalid={errors?.form?.date ?? false}
        >
          <FormLabel>Date</FormLabel>
          <Input
            name="form.date"
            type="date"
            placeholder="Select Date"
            ref={register({
              required: true,
            })}
            w="49%"
          />
        </FormControl>
        <Flex
          flexDirection={['column', 'column', 'row', 'row', 'row', 'row']}
          mt="2"
          w="100%"
          gap={6}
        >
          <FormControl isInvalid={errors?.form?.name ?? false} isRequired>
            <FormLabel>Name</FormLabel>
            <Input
              placeholder={'Name'}
              name="form.name"
              ref={register({
                required: true,
              })}
            />
          </FormControl>
          <FormControl
            id="dateOfBirth"
            mb="5"
            isRequired
            isInvalid={errors?.form?.dateOfBirth ?? false}
          >
            <FormLabel>Date of Birth</FormLabel>
            <Input
              name="form.dateOfBirth"
              type="date"
              placeholder="Select Date of Birth"
              ref={register({
                required: true,
              })}
            />
          </FormControl>
        </Flex>
        {oswestryQuestions.map((item, index) => (
          <FormControl
            key={index}
            mb="5"
            width={['100%', '100%', '100%', '80%']}
            isInvalid={errors?.form?.answers && errors?.form.answers[index]}
          >
            <FormLabel>{item.question}</FormLabel>
            <Controller
              name={`form.answers[${index}]`}
              control={control}
              rules={{ required: 'Please select answer' }}
              defaultValue={''}
              render={field => (
                <RadioGroup {...field}>
                  <Stack>
                    {item.answers.map((answer, i) => (
                      <Radio value={`${i}`} key={i}>
                        {answer}
                      </Radio>
                    ))}
                  </Stack>
                </RadioGroup>
              )}
            />
            <FormErrorMessage>
              {errors?.form?.answers && errors?.form?.answers[index]?.message}
            </FormErrorMessage>
          </FormControl>
        ))}
        <Center>
          <Button
            my={'10'}
            type="submit"
            colorScheme={'blue'}
            isLoading={loading}
          >
            Submit
          </Button>
        </Center>
      </form>
    </Container>
  )
}
