import { Image, Text, View } from '@react-pdf/renderer'
import moment from 'moment'
import React from 'react'

function SecondAcceptingPatients({ fillableData, styles }) {
  return (
    <View style={{ ...styles.gapBox }}>
      <View
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          fontSize: 8,
        }}
      >
        <Image
          style={{ width: '110px', objectFit: 'cover' }}
          src="/paincare-web-v2.png"
        />
        <View style={styles.boxtitle}>
          <Text>2480 Liberty Street NE, Suite 180, Salem, OR 97301</Text>
        </View>
        <View style={styles.boxtitle}>
          <Text>Office: (503) 371-1010 Fax: (503) 371-0805</Text>
        </View>
      </View>
      <View
        style={{
          width: '450px',
          paddingTop: '8px',
          gap: '4px',
        }}
      >
        <View style={{ width: '100%' }}>
          <View style={styles.boxRowData}>
            <View
              style={{
                ...styles.boxRowData,
                width: '100%',
                gap: '0px',
              }}
            >
              <Text style={{ width: '42px' }}>Name :</Text>
              <Text style={{ ...styles.paddingLeftFormData }}>
                {fillableData.namePatient ? fillableData.namePatient : ''}
              </Text>
            </View>
            <View
              style={{
                ...styles.boxRowData,
                width: '100%',
                gap: '0px',
              }}
            >
              <Text style={{ width: '35px' }}>DOB :</Text>
              <Text style={{ ...styles.paddingLeftFormData }}>
                {fillableData?.DOB
                  ? moment(fillableData?.DOB).format('MM/DD/YYYY')
                  : ''}
              </Text>
            </View>
          </View>
        </View>
        <Text style={{ fontWeight: 'bold' }}>
          New Patient Intake Form, Page 2 of 3
        </Text>
        <View
          style={{
            backgroundColor: '#b0acac',
            width: '100%',
            height: '4px',
          }}
        ></View>
        <View
          style={{ paddingTop: 4, display: 'flex', flexDirection: 'column' }}
        >
          <Text style={{ fontWeight: 'bold' }}>Current Medications:</Text>
        </View>
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            paddingTop: 2,
            paddingBottom: 1,
          }}
        >
          {fillableData?.currentmedications?.map((data, index) => {
            return (
              <View style={styles.boxRowData} key={index}>
                <View
                  style={{
                    ...styles.boxRowData,
                    width: '100%',
                    gap: '0px',
                  }}
                >
                  <Text style={{ width: '42px' }}>Name:</Text>
                  <Text
                    style={{ ...styles.paddingLeftFormData, paddingLeft: 0 }}
                  >
                    {data.name ? data.name : ''}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.boxRowData,
                    width: '100%',
                    gap: '0px',
                  }}
                >
                  <Text style={{ width: '35px' }}>Dose:</Text>
                  <Text
                    style={{ ...styles.paddingLeftFormData, paddingLeft: 0 }}
                  >
                    {data.dose ? data.dose : ''}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.boxRowData,
                    width: '100%',
                    gap: '0px',
                  }}
                >
                  <Text style={{ width: '80px' }}>Frequency:</Text>
                  <Text
                    style={{
                      ...styles.paddingLeftFormData,
                      paddingLeft: 0,
                    }}
                  >
                    {data.frequency ? data.frequency : ''}
                  </Text>
                </View>
              </View>
            )
          })}
        </View>
        <View
          style={{
            backgroundColor: '#000',
            width: '100%',
            height: '1px',
          }}
        ></View>
        <View>
          <Text style={{ fontWeight: 'bold' }}>Current Medical Problems :</Text>
          <Text style={{ borderBottom: '1px dotted #000', paddingTop: 4 }}>
            {fillableData.currentMedicalProblems
              ? fillableData.currentMedicalProblems
              : ''}
          </Text>
        </View>
        <View
          style={{
            backgroundColor: '#000',
            width: '100%',
            height: '1px',
          }}
        ></View>
        <View style={{ display: 'flex', flexDirection: 'column' }}>
          <Text>
            <Text style={{ fontWeight: 'bold' }}>Allergies:</Text>
            {` `}
            <Text>(circle all that apply)</Text>
          </Text>
          <View
            style={{
              paddingTop: 6,
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '6px',
            }}
          >
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTriedPage4.includes(
                  'allergiesNone'
                ) && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>None</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTriedPage4.includes(
                  'allergiesTape'
                ) && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Tape</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTriedPage4.includes(
                  'allergiesIodine'
                ) && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Iodine</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTriedPage4.includes(
                  'allergiesLatex'
                ) && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Latex</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTriedPage4.includes(
                  'allergiesContrast'
                ) && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Omnipaque</Text>
            </View>

            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTriedPage4.includes(
                  'allergiesOther'
                ) && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text style={{ marginTop: '2px' }}>Other</Text>
              {` `}
              <Text
                style={{
                  borderBottom: '1px dotted #000',
                  width: '120px',
                }}
              >
                {fillableData.inputAllergiesOther
                  ? fillableData.inputAllergiesOther
                  : ''}
              </Text>
            </View>
          </View>
          <View>
            <Text style={{ fontWeight: 'bold', paddingTop: 4 }}>
              Medication Allergies (list) :
            </Text>
            <Text style={{ borderBottom: '1px dotted #000', paddingTop: 4 }}>
              {fillableData.medicationAllergies
                ? fillableData.medicationAllergies
                : ''}
            </Text>
          </View>
        </View>
        <View
          style={{
            backgroundColor: '#000',
            width: '100%',
            height: '1px',
          }}
        ></View>
        <View style={{ display: 'flex', flexDirection: 'column' }}>
          <Text>
            <Text style={{ fontWeight: 'bold' }}>Surgical History</Text>
            {` `}
            <Text>(Check all that apply and circle for Left/Right)</Text>
          </Text>
          <View
            style={{
              paddingTop: 6,
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              gap: '6px',
            }}
          >
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.surgical.includes('surgicalCardiac') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Cardiac/Heart</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.surgical.includes('surgicalCervical') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Cervical/Neck</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.surgical.includes('surgicalLumbar') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Lumbar/Back</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.surgical.includes('surgicalHipLeft') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Hip Left/Right</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.surgical.includes('surgicalKnee') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Knee Left/Right</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.surgical.includes('surgicalShoulder') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Shoulder Left/Right</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.surgical.includes('noSurgicalHistory') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>No Surgical History</Text>
            </View>
          </View>
        </View>
        <View
          style={{
            backgroundColor: '#000',
            width: '100%',
            height: '1px',
          }}
        ></View>
        <View style={{ display: 'flex', flexDirection: 'column' }}>
          <Text style={{ fontWeight: 'bold' }}>Past Hospitalizations:</Text>
        </View>
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: '4px',
            paddingTop: 2,
            paddingBottom: 1,
          }}
        >
          {fillableData?.hospitalizations?.map((data, index) => {
            return (
              <View
                style={{
                  ...styles.boxRowData,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                }}
                key={index}
              >
                <View
                  style={{
                    ...styles.boxRowData,
                    width: '100%',
                    gap: '0px',
                  }}
                >
                  <Text style={{ width: '35px' }}>Date :</Text>
                  <Text style={{ ...styles.paddingLeftFormData }}>
                    {data.date ? data.date : ''}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.boxRowData,
                    width: '100%',
                    gap: '0px',
                  }}
                >
                  <Text style={{ width: '50px' }}>Reason :</Text>
                  <Text style={{ ...styles.paddingLeftFormData }}>
                    {data.reason ? data.reason : ''}
                  </Text>
                </View>
              </View>
            )
          })}
        </View>
        <View
          style={{
            backgroundColor: '#000',
            width: '100%',
            height: '1px',
          }}
        ></View>
        <View style={{ display: 'flex', flexDirection: 'column' }}>
          <Text>
            <Text style={{ fontWeight: 'bold' }}>Family History:</Text>
            {` `}
            <Text>(Please check where applicable)</Text>
          </Text>
        </View>
        <View style={{ gap: '6px' }}>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text style={{ width: '120px', textAlign: 'start' }}></Text>
            <Text style={{ width: '60px', textAlign: 'center' }}>Father</Text>
            <Text style={{ width: '60px', textAlign: 'center' }}>Mother</Text>
            <Text
              style={{
                width: '60px',
                textAlign: 'center',
              }}
            >
              Grandparent
            </Text>
            <Text style={{ width: '60px', textAlign: 'center' }}>Sibling</Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text style={{ width: '120px', textAlign: 'start' }}>
              Heart Disease
            </Text>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.heartDisease.includes('FATHER') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.heartDisease.includes('MOTHER') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.heartDisease.includes('GRANDPARENT') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.heartDisease.includes('SIBLING') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text style={{ width: '120px', textAlign: 'start' }}>
              Lung Disease
            </Text>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.lungDisease.includes('FATHER') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.lungDisease.includes('MOTHER') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.lungDisease.includes('GRANDPARENT') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.lungDisease.includes('SIBLING') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text style={{ width: '120px', textAlign: 'start' }}>
              Arthritis
            </Text>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.arthritis.includes('FATHER') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.arthritis.includes('MOTHER') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.arthritis.includes('GRANDPARENT') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.arthritis.includes('SIBLING') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text style={{ width: '120px', textAlign: 'start' }}>Cancer</Text>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.cancer.includes('FATHER') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.cancer.includes('MOTHER') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.cancer.includes('GRANDPARENT') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.cancer.includes('SIBLING') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text style={{ width: '120px', textAlign: 'start' }}>Stroke</Text>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.stroke.includes('FATHER') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.stroke.includes('MOTHER') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.stroke.includes('GRANDPARENT') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.stroke.includes('SIBLING') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            backgroundColor: '#000',
            width: '100%',
            height: '1px',
          }}
        ></View>

        <View style={{ display: 'flex', flexDirection: 'column' }}>
          <Text>
            <Text style={{ fontWeight: 'bold' }}>Surgical History:</Text>
            {` `}
            <Text>(Please check Yes or No for each question)</Text>
          </Text>
          <View
            style={{
              paddingTop: 6,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              // gap: '0px',
            }}
          >
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                alignItems: 'end',
                justifyContent: 'start',
                flexDirection: 'column',
                gap: '4px',
                borderBottom: 1,
                width: '100%',
                paddingBottom: 4,
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '2px',
                }}
              >
                <Text style={{ width: '100%' }}>Smoking:</Text>
                <View
                  style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                    gap: '6px',
                  }}
                >
                  <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
                    <View style={styles.stylesCheckImage}>
                      {fillableData?.smoking.includes('yesSmoking') && (
                        <Image
                          style={{
                            ...styles.imgcheck,
                          }}
                          src="/check.png"
                        />
                      )}
                    </View>
                    <Text>Yes</Text>
                  </View>
                  <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
                    <View style={styles.stylesCheckImage}>
                      {fillableData?.anticoagulant.includes('noSmoking') && (
                        <Image
                          style={{
                            ...styles.imgcheck,
                          }}
                          src="/check.png"
                        />
                      )}
                    </View>
                    <Text>No</Text>
                  </View>
                  <View>
                    <View style={{ flexDirection: 'row' }}>
                      <Text>(</Text>
                      <View
                        style={{
                          width: '40px',
                          textAlign: 'center',
                          borderBottom: '1px dotted #000',
                        }}
                      >
                        <Text>
                          {fillableData.otherSmoking
                            ? fillableData.otherSmoking
                            : ''}
                        </Text>
                      </View>
                      <Text>packs per day/week)</Text>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '2px',
                }}
              >
                <Text style={{ width: '100%' }}>Alcohol:</Text>
                <View
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '6px',
                  }}
                >
                  <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
                    <View style={styles.stylesCheckImage}>
                      {fillableData?.alcohol.includes('yesAlcohol') && (
                        <Image
                          style={{
                            ...styles.imgcheck,
                          }}
                          src="/check.png"
                        />
                      )}
                    </View>
                    <Text>Yes</Text>
                  </View>
                  <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
                    <View style={styles.stylesCheckImage}>
                      {fillableData?.alcohol.includes('noAlcohol') && (
                        <Image
                          style={{
                            ...styles.imgcheck,
                          }}
                          src="/check.png"
                        />
                      )}
                    </View>
                    <Text>No</Text>
                  </View>
                  <View>
                    <View style={{ flexDirection: 'row' }}>
                      <Text>(</Text>
                      <View
                        style={{
                          width: '40px',
                          textAlign: 'center',
                          borderBottom: '1px dotted #000',
                        }}
                      >
                        <Text>
                          {fillableData.otherAlcohol
                            ? fillableData.otherAlcohol
                            : ''}
                        </Text>
                      </View>
                      <Text>drinks per day/week)</Text>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '2px',
                  alignItems: 'end',
                }}
              >
                <Text style={{ width: '100%' }}>Illegal Drugs:</Text>
                <View
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '6px',
                  }}
                >
                  <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
                    <View style={styles.stylesCheckImage}>
                      {fillableData?.illegalDrugs.includes(
                        'yesIllegalDrugs'
                      ) && (
                        <Image
                          style={{
                            ...styles.imgcheck,
                          }}
                          src="/check.png"
                        />
                      )}
                    </View>
                    <Text>Yes</Text>
                  </View>
                  <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
                    <View style={styles.stylesCheckImage}>
                      {fillableData?.illegalDrugs.includes(
                        'noIllegalDrugs'
                      ) && (
                        <Image
                          style={{
                            ...styles.imgcheck,
                          }}
                          src="/check.png"
                        />
                      )}
                    </View>
                    <Text>No</Text>
                    <View style={{ flexDirection: 'row', alignItems: 'end' }}>
                      <Text>Date last used:</Text>
                      <View
                        style={{
                          width: '70px',
                          textAlign: 'center',
                          borderBottom: '1px dotted #000',
                        }}
                      >
                        <Text>
                          {fillableData.otherIllegalDrugs
                            ? fillableData.otherIllegalDrugs
                            : ''}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '6px',
                  alignItems: 'end',
                }}
              >
                <Text style={{ width: '105px' }}>Addiction/Dependency</Text>

                <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.addictionDependency.includes(
                      'yesAddictionDependency'
                    ) && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>Yes</Text>
                </View>
                <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.addictionDependency.includes(
                      'noAddictionDependency'
                    ) && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>No</Text>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '6px',
                  alignItems: 'end',
                }}
              >
                <Text style={{ width: '105px' }}>Marijuana</Text>

                <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.addictionMarijuana.includes(
                      'yesMarijuana'
                    ) && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>Yes</Text>
                </View>
                <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.addictionMarijuana.includes(
                      'noMarijuana'
                    ) && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>No</Text>
                </View>
              </View>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                alignItems: 'end',
                justifyContent: 'start',
                flexDirection: 'column',
                gap: '4px',
                width: '100%',
                paddingLeft: 8,
                borderBottom: 1,
                paddingBottom: 4,
                borderLeft: 1,
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '6px',
                  alignItems: 'end',
                }}
              >
                <Text style={{ width: '60px' }}>Married</Text>
                <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.married.includes('yesMarried') && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>Yes</Text>
                </View>
                <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.married.includes('noMarried') && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>No</Text>
                </View>
                <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.married.includes('divorcedMarried') && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>Divorced</Text>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '6px',
                  alignItems: 'end',
                }}
              >
                <Text style={{ width: '100%' }}>Children</Text>
                <View
                  style={{
                    flexDirection: 'row',
                    gap: '6px',
                  }}
                >
                  <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
                    <View style={styles.stylesCheckImage}>
                      {fillableData?.children.includes('yesChildren') && (
                        <Image
                          style={{
                            ...styles.imgcheck,
                          }}
                          src="/check.png"
                        />
                      )}
                    </View>
                    <Text>Yes</Text>
                  </View>
                  <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
                    <View style={styles.stylesCheckImage}>
                      {fillableData?.children.includes('noChildren') && (
                        <Image
                          style={{
                            ...styles.imgcheck,
                          }}
                          src="/check.png"
                        />
                      )}
                    </View>
                    <Text>No</Text>
                  </View>
                  <View style={{ flexDirection: 'row', alignItems: 'end' }}>
                    <Text>How many?</Text>
                    <View
                      style={{
                        width: '70px',
                        textAlign: 'center',
                        borderBottom: '1px dotted #000',
                      }}
                    >
                      <Text>
                        {fillableData.otherChildren
                          ? fillableData.otherChildren
                          : ''}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '6px',
                  alignItems: 'end',
                }}
              >
                <Text style={{ width: '60px' }}>On Disability</Text>
                <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.onDisability.includes('yesOnDisability') && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>Yes</Text>
                </View>
                <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.onDisability.includes('noOnDisability') && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>No</Text>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '6px',
                  alignItems: 'end',
                }}
              >
                <Text style={{ width: '100px' }}>Currently Working</Text>
                <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.currentLyWorking.includes(
                      'yesCurrentLyWorking'
                    ) && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>Yes</Text>
                </View>
                <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.currentLyWorking.includes(
                      'noCurrentLyWorking'
                    ) && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>No</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

export default SecondAcceptingPatients
