import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { ChevronRightIcon } from '@chakra-ui/icons'
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Center,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  Radio,
  RadioGroup,
  Spacer,
  Stack,
  useToast,
} from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import Logo from '../../../Logo.png'
import { addDoc, collection } from 'firebase/firestore'
import { db } from '../../../config/firebase'

export const soapprQuestion = [
  'How often do you have mood swings?',
  'How often have you felt a need for higher doses of medication to treat your pain?',
  'How often have you felt impatient with your doctors?',
  `How often have you felt that things are just too overwhelming that you can't handle them?`,
  'How often is there tension in the home?',
  'How often have you counted pain pills to see how many are remaining?',
  'How often have you been concerned that people will judge you for taking pain medication?',
  'How often do you feel bored?',
  'How often have you taken more pain medication than you were supposed to?',
  'How often have you worried about being left alone?',
  'How often have you felt a craving for medication?',
  'How often have others expressed concern over your use of medication?',
  'How often have any of your close friends had a problem with alcohol or drugs?',
  'How often have others told you that you had a bad temper?',
  'How often have you felt consumed by the need to get pain medication?',
  'How often have you run out of pain medication early?',
  'How often have others kept you from getting what you deserve?',
  'How often, in your lifetime, have you had legal problems or been arrested?',
  'How often have you attended an AA or NA meeting?',
  'How often have you been in an argument that was so out of control that someone got hurt?',
  'How often have you been sexually abused?',
  'How often have others suggested that you have a drug or alcohol problem?',
  'How often have you had to borrow pain medications from your family or friends?',
  'How often have you been treated for an alcohol or drug problem?',
]
export default function SOAPPRForm() {
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    reset,
  } = useForm()
  const toast = useToast()
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  const calScore = data => {
    let total = 0
    data.map(item => (total += Number(item)))
    return total
  }

  const calScoreType = value => {
    if (value >= 0 && value <= 9) return 'Low risk'
    if (value >= 10 && value <= 21) return 'Moderate risk'
    if (value >= 22) return 'High risk'
  }

  const onSubmit = ({ form }) => {
    const score = calScore(form.answers)
    const scoreType = calScoreType(score)
    try {
      setLoading(true)
      addDoc(collection(db, 'PatientForm'), {
        ...form,
        score: score,
        scoreType: scoreType,
        createdAt: new Date(),
        notification: true,
        status: 'Pending',
        facility: 'PCSO',
        type: 'SOAPP-R',
      })
      setLoading(false)
      reset()
      history.push('/patient/success')
    } catch (error) {
      toast({
        title: 'Submit Failed',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
      setLoading(false)
    }
  }
  return (
    <Container maxW="100%" mt="5" mb="10">
      <Image
        cursor="pointer"
        src={Logo}
        alt="paincare logo"
        htmlWidth="100px"
      />
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
        bg="gray.100"
        p="1.5"
        mt="5"
        borderRadius="0.3em"
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/patient-form/phd-9">
            SOAPP-R
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex my="10">
        <Box pt="2" fontSize="18" fontWeight="bold">
          SOAPP-R
        </Box>
        <Spacer />
      </Flex>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex
          flexDirection={['column', 'column', 'row', 'row', 'row', 'row']}
          mt="2"
          w="100%"
          gap={6}
        >
          <FormControl isInvalid={errors?.form?.name ?? false} isRequired>
            <FormLabel>Name</FormLabel>
            <Input
              placeholder={'Name'}
              name="form.name"
              ref={register({
                required: true,
              })}
            />
          </FormControl>
          <FormControl
            id="date"
            mb="5"
            isRequired
            isInvalid={errors?.form?.dateOfBirth ?? false}
          >
            <FormLabel>Date of Birth</FormLabel>
            <Input
              name="form.dateOfBirth"
              type="date"
              placeholder="Select Date"
              ref={register({
                required: true,
              })}
            />
          </FormControl>
        </Flex>
        {soapprQuestion.map((item, index) => {
          return (
            <FormControl
              mb="5"
              width={['100%', '100%', '100%', '80%']}
              key={index}
              isInvalid={errors?.form?.answers && errors?.form.answers[index]}
            >
              <FormLabel>
                {index + 1}. {item}
              </FormLabel>
              <Controller
                name={`form.answers[${index}]`}
                control={control}
                rules={{ required: 'Please select answer' }}
                defaultValue={''}
                render={field => (
                  <RadioGroup {...field}>
                    <Stack>
                      <Radio value="0">Never</Radio>
                      <Radio value="1">Seldom</Radio>
                      <Radio value="2">Sometimes</Radio>
                      <Radio value="3">Often</Radio>
                      <Radio value="4">Very Often</Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
              <FormErrorMessage>
                {errors?.form?.answers && errors?.form?.answers[index]?.message}
              </FormErrorMessage>
            </FormControl>
          )
        })}
        <Center>
          <Button
            my={'10'}
            type="submit"
            colorScheme={'blue'}
            isLoading={loading}
          >
            Submit
          </Button>
        </Center>
      </form>
    </Container>
  )
}
