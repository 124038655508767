import moment from 'moment'
function renderDate(timestamp, sec) {
    let datetime = new Date(timestamp.toDate())
    if (sec) {
        return moment(datetime).format('ll HH:mm:ss')
    } else {
        return moment(datetime).format('lll')
    }
}

export default renderDate