import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import {
    Container,
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Text,
    FormErrorMessage,
    FormLabel,
    FormControl,
    Stack,
    Input,
    Button,
    useToast,
    HStack,
    Center,
    Radio,
    RadioGroup,
    Flex,
    Spacer
} from "@chakra-ui/react"
import { DatePicker } from 'antd'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { FaCloudDownloadAlt } from 'react-icons/fa'
import { addDoc, collection } from 'firebase/firestore'
import { db } from '../../config/firebase'

function AbsenteeReportForm({ user }) {
    const { handleSubmit, reset, register, errors, control } = useForm()
    const toast = useToast()
    const [isDisabled, setIsDisabled] = useState(false)
    const [reasonForAbsence, setReasonForAbsence] = useState(null)
    function submitData(value) {
        setIsDisabled(true)
        value.dateFormCompleted = value.dateFormCompleted.format("YYYY-MM-DD")
        value.dateAbsent = value.dateAbsent.format("YYYY-MM-DD")
        addDoc(collection(db, "EmployeeForm"), { ...value, name: user.fullName, type: "Absentee Report", reasonForAbsence: reasonForAbsence, createdAt: new Date(), notification: true, status: "Pending" })
            .then(() => {
                toast({
                    title: "Submit Successfully",
                    description: "Form has been submmited.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                })
                setIsDisabled(false)
                reset()
            })
    }
    return (
        <Container maxW="100%" mt="5">
            <Breadcrumb
                spacing="8px"
                separator={<ChevronRightIcon color="gray.500" />}
                bg="gray.100" p="1.5"
                borderRadius="0.3em"
            >
                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to="/dashboard">Dashbaord</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to="/absentee-report-form">Absentee Report Form</BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>
            <Center mt="5">
                <Text decoration="underline">MUST BE COMPLETED AND TURNED INTO A SUPERVISOR PRIOR TO RETURNING TO WORK</Text>
            </Center>
            <Center mt="5">
                <Text fontSize="sm">This form is not used when absence is a result of a previously approved request for leave, given with at least 2 weeks’ notice.</Text>
            </Center>
            <Box mt="30" mb="100" width={["100"]}>
                <Flex mt="10" mb="10">
                    <Box pt="2" fontSize="18" fontWeight="bold">
                        Absentee Report Form
                    </Box>
                    <Spacer />
                    <Button onClick={() => { window.open('https://firebasestorage.googleapis.com/v0/b/paincare-a9e20.appspot.com/o/Downloads%2FForms%2FAbsentee-Report-Form.pdf?alt=media&token=c87f56eb-005b-482d-8ddf-171c7387f59d', '_blank') }} leftIcon={<FaCloudDownloadAlt />} size="sm">PDF</Button>
                </Flex>
                <form onSubmit={handleSubmit(submitData)}>
                    <HStack mb="5">
                        <FormControl id="name" isReadOnly>
                            <FormLabel>NAME</FormLabel>
                            <Input name="name" defaultValue={user.fullName} />
                        </FormControl>
                        <FormControl id="dateFormCompleted" isInvalid={errors.dateFormCompleted}>
                            <FormLabel>Date form Completed</FormLabel>
                            <Controller
                                render={props => (
                                    <DatePicker onChange={props.onChange} size="large" format="YYYY-MM-DD" style={{ width: "100%" }} />
                                )}
                                name="dateFormCompleted"
                                control={control}
                                defaultValue={null}
                                rules={{ required: "Date Form Completed required." }}
                            />
                            <FormErrorMessage>{errors.dateFormCompleted && errors.dateFormCompleted.message}</FormErrorMessage>
                        </FormControl>
                    </HStack>
                    <FormControl mb="5" width={["100%", "100%", "100%", "30%"]} id="dateAbsent" isInvalid={errors.dateAbsent}>
                        <FormLabel>Date(s) absent</FormLabel>
                        <Controller
                            render={props => (
                                <DatePicker onChange={props.onChange} size="large" format="YYYY-MM-DD"  style={{ width: "100%" }} />
                            )}
                            name="dateAbsent"
                            control={control}
                            defaultValue={null}
                            rules={{ required: "Date Absent required." }}
                        />
                        <FormErrorMessage>{errors.dateAbsent && errors.dateAbsent.message}</FormErrorMessage>
                    </FormControl>
                    <HStack mb="5" width={["100%", "100%", "100%", "30%"]}>
                        <FormControl id="timeFrom" isInvalid={errors.timeFrom}>
                            <FormLabel>TIME*:From</FormLabel>
                            <Input type="time" name="timeFrom" ref={register({ required: "Time From Required" })} />
                            <FormErrorMessage>{errors.timeFrom && errors.timeFrom.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl id="timeTo" isInvalid={errors.timeTo}>
                            <FormLabel>Time*:To</FormLabel>
                            <Input type="time" name="timeTo" ref={register({ required: "Time To Required" })} />
                            <FormErrorMessage>{errors.timeTo && errors.timeTo.message}</FormErrorMessage>
                        </FormControl>
                    </HStack>
                    <FormControl id="totalHoursAbsent" mb="5" width={["100%", "100%", "100%", "30%"]} isInvalid={errors.totalHoursAbsent}>
                        <FormLabel>Total Hours Absent</FormLabel>
                        <Input name="totalHoursAbsent" ref={register({ required: "Total Hours Absent Required." })} />
                        <FormErrorMessage>{errors.totalHoursAbsent && errors.totalHoursAbsent.message}</FormErrorMessage>
                    </FormControl>
                    <Text mb="5" mt="10" decoration="underline">REASON FOR ABSENCE:</Text>
                    <FormControl mb="5" id="reasonForAbsence">
                        <RadioGroup name="reasonForAbsence" onChange={(value) => setReasonForAbsence(value)}>
                            <Stack direction="column">
                                <Radio value="OFLA">OFLA (Proceed to OFLA statement below)</Radio>
                                <Radio value="Other">Other:(specify)</Radio>
                            </Stack>
                        </RadioGroup>
                    </FormControl>
                    {
                        reasonForAbsence === "Other" &&
                        <FormControl width={["100%", "100%", "100%", "30%"]} mb="5" isInvalid={errors.otherReasonForAbsence}>
                            <Input name="otherReasonForAbsence" placeholder="Other Reason" ref={register({ required: 'Other Reason For Absence' })} />
                            <FormErrorMessage>{errors.otherReasonForAbsence && errors.otherReasonForAbsence.message}</FormErrorMessage>
                        </FormControl>
                    }
                    <Text fontSize="sm" mb="5">
                        For absences of 2 consecutive days or more, employees may submit supporting documentation from a health care provider if they
                        choose. If documentation from a health care provider supports the absence, it will be documented as 1 occurrence. In the event an
                        employee chooses not to provide supporting documentation, each day of absence will count as one incident. For example, and
                        employee absent for 2 days in a row, will have two incidents recorded.
                    </Text>
                    <Text fontSize="sm" mb="5">
                        For absences without previous approval (not requested and approved 2 weeks prior), PTO will be automatically applied during the
                        next payroll period. If the time of the absence exceeds the PTO amount, the PTO will be fully applied, and additional time will receive
                        no compensation.
                    </Text>
                    <Text mb="5" mt="10" decoration="underline">OFLA:</Text>
                    <Text mb="5" fontSize="sm">
                        In the event the employee claims OFLA as their reasoning for absence, the employee is required to comply with
                        requirements for OFLA absence claims. This includes providing any required supporting documentation, as well as notifying a
                        supervisor in the appropriate time period. Please refer to the OFLA Rights Handout and the Pain Care Specialist policy in regards to
                        OFLA. In addition to this form, a <u>PCS OFLA REQUEST FORM MUST ALSO BE COMPLETED.</u>
                    </Text>
                    <Text mb="5" color="red.500">
                        ** Employee certifies that all information provided is true and complete to the best of his/her knowledge. Falsification of this form is grounds for disciplinary action, up to and including dismissal.
                    </Text>
                    <Center mt="10">
                        <Button colorScheme="blue" width={["100%", "100%", "100%", "10%"]} disabled={!reasonForAbsence || isDisabled} type="submit" isLoading={isDisabled}>Submit</Button>
                    </Center>
                </form>
            </Box>
        </Container>
    )
}

export default AbsenteeReportForm