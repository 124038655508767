import { ChevronRightIcon } from '@chakra-ui/icons'
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Center,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  HStack,
  Input,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  UnorderedList,
  useBreakpoint,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { FaRegEdit } from 'react-icons/fa'
import React, { useRef, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { addDoc, collection } from 'firebase/firestore'
import { useForm, Controller } from 'react-hook-form'
import { db } from '../../config/firebase'
import { SwitchInputComponent } from '../../utils'
import { resizeFile, switchCanvas } from './InfectionsAndEmergencyVisitForm'

export default function DeclinationOfInfluenzaVaccinationForm() {
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    reset,
    watch,
    trigger,
    setValue,
  } = useForm()
  const toast = useToast()
  const breakPoint = useBreakpoint()
  const [loading, setLoading] = useState(false)
  const { isOpen, onToggle, onClose } = useDisclosure()

  const sigCanvas = useRef({})
  const fileUploadInputRef = useRef(null)

  const signatureCollector = watch('form.signatureCollector') || ''

  const clear = () => sigCanvas.current.clear()
  const save = () =>
    setValue(
      'form.signatureCollector',
      sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')
    )

  async function onUploadChange(event) {
    const file = event.target?.files[0]

    if (
      file?.type === 'image/jpg' ||
      file?.type === 'image/jpeg' ||
      file?.type === 'image/png'
    ) {
      const image = await resizeFile(file)
      setValue('form.signatureCollector', image)
      toast({
        duration: 3000,
        title: 'successfully uploaded.',
        status: 'success',
        position: 'top',
        isClosable: true,
      })
      onClose()
    } else {
      toast({
        duration: 3000,
        title: 'only file .jpg, jpeg and .png are supported.',
        status: 'info',
        position: 'top',
        isClosable: true,
      })
    }
  }

  function onClickFileUpload() {
    fileUploadInputRef && fileUploadInputRef.current.click()
  }

  const onSubmit = ({ form }) => {
    try {
      setLoading(true)
      addDoc(collection(db, 'EmployeeForm'), {
        ...form,
        createdAt: new Date(),
        notification: true,
        status: 'Pending',
        type: 'Declination of Influenza Vaccination',
      })
      setLoading(false)
      setValue('form.signatureCollector', '')
      reset()
      toast({
        title: 'Submit Successfully',
        description: 'Form has been submmited.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch (error) {
      toast({
        title: 'Submit Failed',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
      setLoading(false)
    }
  }

  return (
    <Container maxW="100%" mt="5" mb="10">
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
        bg="gray.100"
        p="1.5"
        mt="5"
        borderRadius="0.3em"
      >
        <BreadcrumbItem>
          <BreadcrumbLink>
            Declination of Influenza Vaccination Form
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex my="10">
        <Box pt="2" fontSize="18" fontWeight="bold">
          Declination of Influenza Vaccination Form
        </Box>
        <Spacer />
      </Flex>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex alignItems="center">
          <Text fontSize="md" marginRight={1}>
            My employer,
          </Text>
          {/* <Text fontSize="md" as="u">
            Medical Practice Management
          </Text> */}
          <FormControl
            isInvalid={errors?.form?.name ?? false}
            isRequired
            width="20%"
          >
            <Input
              placeholder="Name"
              name="form.name"
              ref={register({
                required: true,
              })}
            />
          </FormControl>
          ,
          <Text fontSize="md" paddingLeft={1}>
            has recommended that I receive influenza vaccination to protect the
            patients I serve.
          </Text>
        </Flex>
        <Text fontSize="md">
          I acknowledge that I am aware of the following facts:
        </Text>
        <UnorderedList>
          <ListItem>
            <Text fontSize="md">
              Influenza is a serious respiratory disease that kills thousands of
              people in the United States each year.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontSize="md">
              Influenza vaccination is recommended for me and all other
              healthcare workers to protect this facility&#8217;s patients from
              influenza, its complications, and death.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontSize="md">
              If I contract influenza, I can shed the virus for 24 hours before
              influenza symptoms appear. My shedding the virus can spread
              influenza to patients in this facility.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontSize="md">
              If I become infected with influenza, I can spread severe illness
              to others even when my symptoms are mild or non-existent.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontSize="md">
              I understand that the strains of virus that cause influenza
              infection change almost every year and, even if they don&#8217;t
              change, my immunity declines over time. This is why vaccination
              against influenza is recommended each year.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontSize="md">
              I understand that I cannot get influenza from the influenza
              vaccine.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontSize="md">
              The consequences of my refusing to be vaccinated could have
              life-threatening consequences to my health and the health of those
              with whom I have contact, including
            </Text>
          </ListItem>
          <Box paddingLeft={10}>
            <ListItem>
              <Text fontSize="md">
                all patients in this healthcare facility
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize="md">my coworkers</Text>
            </ListItem>
            <ListItem>
              <Text fontSize="md">my family</Text>
            </ListItem>
            <ListItem>
              <Text fontSize="md"> my community</Text>
            </ListItem>
          </Box>
        </UnorderedList>
        <Text fontSize="md">
          Despite these facts, I am choosing to decline influenza vaccination
          right now for the
        </Text>
        <Text margin="20px 0px" fontSize="md">
          following reasons:
        </Text>
        <FormControl isInvalid={errors?.form?.detail ?? false} isRequired>
          <Textarea
            name="form.detail"
            ref={register({
              required: true,
            })}
          />
        </FormControl>
        <Text fontSize="md" paddingTop={10}>
          I understand that I can change my mind at any time and accept
          influenza vaccination, if vaccine is still available.
        </Text>
        <Text fontSize="md">
          I have read and fully understand the information on this declination
          form.
        </Text>
        <Flex alignItems="end">
          <FormControl
            w="30vw"
            isInvalid={errors?.form?.signatureCollector || false}
          >
            <Controller
              name="form.signatureCollector"
              control={control}
              rules={{ required: 'Please sign your signature' }}
              defaultValue={''}
              render={({ field }) => {
                return (
                  <GridItem {...field}>
                    <VStack spacing="0px" justifyContent={'flex-start'}>
                      <Center h={{ base: '80px' }}>
                        <Text
                          fontWeight="bold"
                          fontSize="15"
                          textAlign="center"
                        >
                          Signature
                        </Text>
                      </Center>
                      <HStack
                        h="40px"
                        w="100%"
                        flexDirection="row"
                        borderBottom={'1px solid #777'}
                      >
                        <Center w="100%">
                          <SwitchInputComponent data={signatureCollector} />
                        </Center>
                        <Flex
                          h="40px"
                          alignItems="flex-end"
                          justifyContent="flex-end"
                        >
                          <Popover
                            placement="top"
                            isOpen={isOpen}
                            onClose={onClose}
                          >
                            <PopoverTrigger p="0px">
                              <Button
                                bg="white"
                                onClick={() => {
                                  setValue('form.signatureCollector', ' ')
                                  onToggle()
                                }}
                              >
                                <FaRegEdit size="25" color="gray" />
                              </Button>
                            </PopoverTrigger>
                            <Portal size="auto">
                              <PopoverContent
                                w={{
                                  base: '20rem',
                                  sm: '20rem',
                                  md: '30rem',
                                }}
                                h="auto"
                                boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                              >
                                <PopoverArrow />
                                <Box w="100%" h="100%">
                                  <Text
                                    p="0.75rem"
                                    fontSize="12px"
                                    color="grey"
                                  >
                                    <span style={{ color: 'red' }}>{`*`}</span>
                                    {`Any signature (including any electronic symbol
                                or process attached to, or associated with, a
                                contract or other record and adopted by a Person
                                with the intent to sign, authenticate or accept
                                such contract or record)`}
                                  </Text>

                                  <Box p="0.75rem" pt="1rem" h="auto">
                                    <Tabs variant="enclosed">
                                      <TabList>
                                        <Tab
                                          onClick={() => {
                                            setValue(
                                              'form.signatureCollector',
                                              ' '
                                            )
                                          }}
                                        >
                                          Draw Signature
                                        </Tab>
                                        <Tab
                                          onClick={() => {
                                            setValue(
                                              'form.signatureCollector',
                                              ' '
                                            )
                                            clear()
                                          }}
                                        >
                                          Upload Signature
                                        </Tab>
                                      </TabList>
                                      <TabPanels h="auto">
                                        <TabPanel
                                          h="22rem"
                                          display="flex"
                                          flexDirection="column"
                                        >
                                          <Box
                                            h="100%"
                                            border="1px solid #F0F0F0"
                                          >
                                            <SignatureCanvas
                                              style={{
                                                backgroundColor: 'red',
                                              }}
                                              penColor="black"
                                              canvasProps={{
                                                width: switchCanvas(
                                                  breakPoint,
                                                  'width'
                                                ),
                                                height: switchCanvas(
                                                  breakPoint,
                                                  'height'
                                                ),
                                              }}
                                              ref={sigCanvas}
                                            />
                                            <Flex
                                              pl="10px"
                                              pb="5px"
                                              pr="10px"
                                              justifyContent="space-between"
                                              h="max-content"
                                              alignItems="end"
                                            >
                                              <Center>
                                                <Button
                                                  fontSize="14px"
                                                  px="1rem"
                                                  h="1.7rem"
                                                  colorScheme="blackAlpha"
                                                  onClick={() => clear()}
                                                >
                                                  Clear
                                                </Button>
                                              </Center>
                                              <Button
                                                mt={4}
                                                h="1.8rem"
                                                fontSize="14px"
                                                w="4.5rem"
                                                bg="#17A2B8"
                                                color="white"
                                                colorScheme="cyan"
                                                onClick={async () => {
                                                  let triggerValue =
                                                    await trigger(
                                                      'form.signatureCollector'
                                                    )
                                                  if (triggerValue) {
                                                    onClose()
                                                    save()
                                                  } else {
                                                  }
                                                }}
                                              >
                                                Confirm
                                              </Button>
                                            </Flex>
                                          </Box>
                                        </TabPanel>
                                        <TabPanel
                                          h="22rem"
                                          display="flex"
                                          flexDirection="column"
                                        >
                                          <Center h="100%" w="100%">
                                            <Input
                                              type="file"
                                              display="none"
                                              ref={fileUploadInputRef}
                                              onChange={onUploadChange}
                                            />
                                            <Button
                                              color="#AEAEAE"
                                              border="2px solid #AEAEAE"
                                              bg="transparent"
                                              _hover={{
                                                bg: '#AEAEAE',
                                                color: '#fff',
                                                opacity: '0.5',
                                              }}
                                              _active={{
                                                bg: '#AEAEAE',
                                                color: '#fff',
                                                opacity: '1',
                                              }}
                                              onClick={onClickFileUpload}
                                            >
                                              Upload
                                            </Button>
                                          </Center>
                                          <Flex
                                            pl="10px"
                                            pb="5px"
                                            pr="10px"
                                            justifyContent="flex-end"
                                            h="max-content"
                                            alignItems="end"
                                          >
                                            <Button
                                              mt={4}
                                              h="1.8rem"
                                              fontSize="14px"
                                              w="4.5rem"
                                              bg="#17A2B8"
                                              color="white"
                                              colorScheme="cyan"
                                              onClick={async () => {
                                                let triggerValue =
                                                  await trigger(
                                                    'form.signaturePatient'
                                                  )
                                                if (triggerValue) {
                                                  onClose()
                                                } else {
                                                }
                                              }}
                                            >
                                              Confirm
                                            </Button>
                                          </Flex>
                                        </TabPanel>
                                      </TabPanels>
                                    </Tabs>
                                  </Box>
                                </Box>
                              </PopoverContent>
                            </Portal>
                          </Popover>
                        </Flex>
                      </HStack>
                    </VStack>
                  </GridItem>
                )
              }}
            />
            <FormErrorMessage>
              {errors?.form?.signatureCollector &&
                errors?.form?.signatureCollector.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            id="date"
            isRequired
            isInvalid={errors?.form?.date ?? false}
            width="20%"
          >
            <FormLabel>Date</FormLabel>
            <Input
              name="form.date"
              type="date"
              placeholder="Select Date"
              ref={register({
                required: true,
              })}
            />
          </FormControl>
        </Flex>
        <FormControl
          id="name"
          isRequired
          isInvalid={errors?.form?.date ?? false}
          width="50%"
          mt={10}
        >
          <FormLabel>Name (print)</FormLabel>
          <Input
            name="form.namePrint"
            placeholder="Name (print)"
            ref={register({
              required: true,
            })}
          />
        </FormControl>
        <Center>
          <Button
            my={'10'}
            type="submit"
            colorScheme={'blue'}
            isLoading={loading}
          >
            Submit
          </Button>
        </Center>
      </form>
    </Container>
  )
}
