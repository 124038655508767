import React from 'react'

import {
  Document,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer'
import './style.module.css'

function PDFRadiationSafetyTest({ employeeData }) {
  const styles = StyleSheet.create({
    pdfView: {
      width: window.innerWidth,
      height: window.innerHeight,
      backgroundColor: '#fff',
    },
    page: {
      width: '100%',
      flexDirection: 'column',
      backgroundColor: '#fff',
      alignItems: 'center',
      paddingLeft: 40,
      paddingRight: 40,
    },
    section: {
      width: '100%',
      marginBottom: 20,
      padding: 10,
      alignItems: 'center',
    },
    sectionImg: {
      width: '100%',
      marginTop: 35,
      padding: 0,
      alignItems: 'center',
    },
    sectionTwo: {
      width: '100%',
      marginTop: 15,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    headerTitleContainer: {
      width: '100%',
      marginTop: 15,
      paddingBottom: 15,
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    headerTitleText: {
      fontSize: '22px',
    },
    headersubTitleText: {
      fontSize: '12px',
      fontStyle: 'italic',
    },
    subTitle: {
      fontSize: '12px',
      fontStyle: 'bold',
    },
    selectChoice: {
      paddingTop: 2,
      fontSize: '10px',
    },
    selectChoiceContainer: {
      width: '80%',
      paddingLeft: '20px',
    },
    contentContainer: {
      width: '100%',
      flexDirection: 'row',
      alignItems: 'flex-start',
      marginTop: 15,
    },
    boxview: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
    },
    img: {
      width: '130px',
      height: 'auto',
      objectFit: 'cover',
    },
    imgcheck: {
      width: '15px',
      height: '15px',
    },
    texttitle: {
      top: 20,
      fontSize: '16px',
      textDecoration: 'underline',
    },
    boxmonthyear: {
      top: 10,
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    textmonthyear: {
      fontSize: '12px',
    },
    textmonthyearborder: {
      fontSize: '12px',
      borderBottom: 1,
      textAlign: 'center',
      width: '260px',
    },
    nameTitle: {
      fontSize: '16px',
    },
    name: {
      fontSize: '16px',
      top: 20,
      textDecoration: 'underline',
    },
    nameDateStyle: {
      justifyContent: 'space-between',
      display: 'flex',
    },
    subTitleNumber: {
      width: '8%',
      fontSize: '12px',
      fontStyle: 'bold',
    },
  })

  return (
    <PDFViewer style={styles.pdfView}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.sectionImg}>
            <Image style={styles.img} src="/mpm.png" />
          </View>

          {/* Header Section */}
          <View style={styles.headerTitleContainer}>
            <Text style={styles.headerTitleText}>Radiation Safety Test</Text>
            <Text style={styles.headersubTitleText}>
              Instructor: Melissa Cedillo RT(R)
            </Text>
          </View>

          {/* Name, Date Section */}
          <View
            style={{
              width: '100%',
              flexDirection: 'row',
              justifyContent: 'space-between',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <View
              style={{
                width: '70%',
                flexDirection: 'row',
                alignItems: 'flex-start',
              }}
            >
              <Text
                style={{
                  fontSize: '12px',
                }}
              >
                Name:&nbsp;
              </Text>
              <Text
                style={{
                  width: '80%',
                  textAlign: 'center',
                  borderBottom: 1,
                  fontSize: 12,
                }}
              >
                {employeeData?.name || ''}
              </Text>
            </View>

            <View
              style={{
                width: '30%',
                flexDirection: 'row',
              }}
            >
              <Text
                style={{
                  fontSize: '12px',
                }}
              >
                Date:&nbsp;
              </Text>
              <Text
                style={{
                  textAlign: 'center',
                  borderBottom: 1,
                  fontSize: 12,
                  width: '100%',
                }}
              >
                {employeeData?.date || ''}
              </Text>
            </View>
          </View>

          {/* Occupation Section */}
          <View style={styles.contentContainer}>
            <Text
              style={{
                fontSize: '12px',
              }}
            >
              Occupation :
            </Text>
            <Text style={styles.textmonthyearborder}>
              {employeeData?.occupation || ''}
            </Text>
          </View>

          {/* Form Section */}
          <>
            <View style={styles.contentContainer}>
              <Text style={styles.subTitleNumber}>{`1)`}</Text>
              <Text style={styles.subTitle}>
                What causes an occupational workers primary dose in the room?
              </Text>
            </View>
            <View style={styles.selectChoiceContainer}>
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                {employeeData?.quizOne === 'answerA' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.selectChoice}>
                  {`a)`}
                  {` `}
                  {` `}
                  {` `}The left over radiation in the room
                </Text>
              </View>

              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                {employeeData?.quizOne === 'answerB' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.selectChoice}>
                  {`b)`}
                  {` `}
                  {` `}
                  {` `}Radiation leaking from the tube
                </Text>
              </View>

              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                {employeeData?.quizOne === 'answerC' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.selectChoice}>
                  {`c)`}
                  {` `}
                  {` `}
                  {` `}The scatter radiation produced from the patient
                </Text>
              </View>
            </View>
          </>

          <>
            <View style={styles.contentContainer}>
              <Text style={styles.subTitleNumber}>{`2)`}</Text>
              <Text style={styles.subTitle}>
                When is the possibility of radiation exposure the highest in the
                room?
              </Text>
            </View>
            <View style={styles.selectChoiceContainer}>
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                {employeeData?.quizTwo === 'answerA' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.selectChoice}>
                  {`a)`}
                  {` `}
                  {` `}
                  {` `}When the machine is on
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                {employeeData?.quizTwo === 'answerB' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.selectChoice}>
                  {`b)`}
                  {` `}
                  {` `}
                  {` `}During cross-table procedures
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                {employeeData?.quizTwo === 'answerC' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.selectChoice}>
                  {`c)`}
                  {` `}
                  {` `}
                  {` `}When performing right sided procedures
                </Text>
              </View>
            </View>
          </>

          <>
            <View style={styles.contentContainer}>
              <Text style={styles.subTitleNumber}>{`3)`}</Text>
              <Text style={styles.subTitle}>
                How can a worker limit their exposure to radiation while in the
                room?
              </Text>
            </View>
            <View style={styles.selectChoiceContainer}>
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                {employeeData?.quizThree === 'answerA' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.selectChoice}>
                  {`a)`}
                  {` `}
                  {` `}
                  {` `}Minimize the time spent around the radiation source
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                {employeeData?.quizThree === 'answerB' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.selectChoice}>
                  {`b)`}
                  {` `}
                  {` `}
                  {` `}Maximize the distance from the source of radiation
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                {employeeData?.quizThree === 'answerC' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.selectChoice}>
                  {`c)`}
                  {` `}
                  {` `}
                  {` `}Wear proper protective equipment such a lead aprons while
                  around radiation
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                {employeeData?.quizThree === 'allOfAbove' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.selectChoice}>
                  {`d)`}
                  {` `}
                  {` `}
                  {` `}All the above
                </Text>
              </View>
            </View>
          </>

          <>
            <View style={styles.contentContainer}>
              <Text style={styles.subTitleNumber}>{`4)`}</Text>
              <Text style={styles.subTitle}>
                Lead aprons will protect the employee from all radiation
                exposures.
              </Text>
            </View>
            <View style={styles.selectChoiceContainer}>
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                {employeeData?.quizFour === 'true' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.selectChoice}>
                  {`a)`}
                  {` `}
                  {` `}
                  {` `}True
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                {employeeData?.quizFour === 'false' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.selectChoice}>
                  {`b)`}
                  {` `}
                  {` `}
                  {` `}False
                </Text>
              </View>
            </View>
          </>

          <>
            <View style={styles.contentContainer}>
              <Text style={styles.subTitleNumber}>{`5)`}</Text>
              <Text style={styles.subTitle}>
                Never fold lead aprons for storage.
              </Text>
            </View>
            <View style={styles.selectChoiceContainer}>
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                {employeeData?.quizFive === 'true' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.selectChoice}>
                  {`b)`}
                  {` `}
                  {` `}
                  {` `}True
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                {employeeData?.quizFive === 'false' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.selectChoice}>
                  {`b)`}
                  {` `}
                  {` `}
                  {` `}False
                </Text>
              </View>
            </View>
          </>

          <>
            <View style={styles.contentContainer}>
              <Text style={styles.subTitleNumber}>{`6)`}</Text>
              <Text style={styles.subTitle}>
                Never fold lead aprons for storage.
              </Text>
            </View>

            <View style={styles.selectChoiceContainer}>
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                {employeeData?.quizSix === 'answerA' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.selectChoice}>
                  {`a)`}
                  {` `}
                  {` `}
                  {` `}Keep it on your lead for easy use
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                {employeeData?.quizSix === 'answerB' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.selectChoice}>
                  {`b)`}
                  {` `}
                  {` `}
                  {` `}Place it on the counter in the break room for storage
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                {employeeData?.quizSix === 'answerC' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.selectChoice}>
                  {`c)`}
                  {` `}
                  {` `}
                  {` `}Take it home with you at the end of the day
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                {employeeData?.quizSix === 'answerD' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.selectChoice}>
                  {`d)`}
                  {` `}
                  {` `}
                  {` `}Store it with the control badge outside the room
                </Text>
              </View>
            </View>
          </>
          <>
            <View style={styles.contentContainer}>
              <Text style={styles.subTitleNumber}>{`7)`}</Text>
              <Text style={styles.subTitle}>
                Always wear the radiation badge as directed every time for
                accurate readings.
              </Text>
            </View>
            <View style={styles.selectChoiceContainer}>
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                {employeeData?.quizSeven === 'true' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.selectChoice}>
                  {`a)`}
                  {` `}
                  {` `}
                  {` `}True
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                {employeeData?.quizSeven === 'false' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.selectChoice}>
                  {`b)`}
                  {` `}
                  {` `}
                  {` `}False
                </Text>
              </View>
            </View>
          </>
          <>
            <View style={styles.contentContainer}>
              <Text style={styles.subTitleNumber}>{`8)`}</Text>
              <Text style={styles.subTitle}>
                As an employee in a radiation environment, what is my annual
                exposure limit?
              </Text>
            </View>

            <View style={styles.selectChoiceContainer}>
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                {employeeData?.quizEigth === 'answerA' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.selectChoice}>
                  {`a)`}
                  {` `}
                  {` `}
                  {` `}5000mrem per year
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                {employeeData?.quizEigth === 'answerB' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.selectChoice}>
                  {`b)`}
                  {` `}
                  {` `}
                  {` `}500mrem per year
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                {employeeData?.quizEigth === 'answerC' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.selectChoice}>
                  {`c)`}
                  {` `}
                  {` `}
                  {` `}5 rem per year
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                {employeeData?.quizEigth === 'a&c' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.selectChoice}>
                  {`d)`}
                  {` `}
                  {` `}
                  {` `}Both A & C
                </Text>
              </View>
            </View>
          </>

          <>
            <View style={styles.contentContainer}>
              <Text style={styles.subTitleNumber}>{`9)`}</Text>
              <Text style={styles.subTitle}>
                What should every female patient between 10 and 60 be asked
                before a procedure?
              </Text>
            </View>

            <View style={styles.selectChoiceContainer}>
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                {employeeData?.quizNine === 'answerA' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.selectChoice}>
                  {`a)`}
                  {` `}
                  {` `}
                  {` `}Any chance of pregnancy?
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                {employeeData?.quizNine === 'answerB' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.selectChoice}>
                  {`b)`}
                  {` `}
                  {` `}
                  {` `}Any chance of pregnancy?
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                {employeeData?.quizNine === 'answerC' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.selectChoice}>
                  {`c)`}
                  {` `}
                  {` `}
                  {` `}Any chance of pregnancy?
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                {employeeData?.quizNine === 'allOfAbove' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.selectChoice}>
                  {`d)`}
                  {` `}
                  {` `}
                  {` `}All the above
                </Text>
              </View>
            </View>
          </>
        </Page>
      </Document>
    </PDFViewer>
  )
}

export default PDFRadiationSafetyTest
