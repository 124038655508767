import React, { useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import SignatureCanvas from 'react-signature-canvas'
import {
  Container,
  Image,
  Radio,
  FormControl,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  HStack,
  Text,
  Flex,
  RadioGroup,
  FormLabel,
  Divider,
  Input,
  FormErrorMessage,
  Stack,
  Center,
  Button,
} from '@chakra-ui/react'

import { ChevronRightIcon } from '@chakra-ui/icons'
import Logo from '../../Logo.png'

function PatientintakeT() {
  const { handleSubmit, register, formState, errors, control } =
    useForm()
  const history = useHistory()
  const Sig1 = useRef({})
  const Sig2 = useRef({})

  const formatIntoPng = () => {
    if (Sig1.current) {
      const DataUrl = Sig1.current.getTrimmedCanvas().toDataURL()
      return DataUrl
    }
    if (Sig2.current) {
      const DataUrl = Sig2.current.getTrimmedCanvas().toDataURL()
      return DataUrl
    }
  }

  const onSubmit = value => {
    console.log(value)
    history.push('/non-web-enable-form/finish')
  }

  return (
    <>
      <Container maxW="100%" mt="5">
        <Image
          cursor="pointer"
          src={Logo}
          alt="paincare logo"
          htmlWidth="100px"
        />
        <Breadcrumb
          spacing="8px"
          separator={<ChevronRightIcon color="gray.500" />}
          bg="gray.100"
          p="1.5"
          borderRadius="0.3em"
          mt="5"
        >
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/">
              Forms
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/non-web-enable-form">
              Non Web Enable New Patient Packet
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={Link}
              to="/non-web-enable-form/patient-intake-1"
            >
              New Patient Intake Form for Kiosk Check-in 1 of 3
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={Link}
              to="/non-web-enable-form/patient-intake-2"
            >
              New Patient Intake Form for Kiosk Check-in 2 of 3
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink>
              New Patient Intake Form for Kiosk Check-in 3 of 3
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex justifyContent="center" flexDirection="column" py="14">
            <Flex
              w="full"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
            >
              <Box border="1px" w={['100%', '100%', '100%', '900px']}></Box>
            </Flex>
            <Flex
              justifyContent="center"
              alignItems="center"
              flexDirection={['column', 'column', 'column', 'row']}
              py="2"
            >
              <Box>
                <FormControl py="2" isInvalid={errors.Bowel}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Bowel incontinence:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">Yes</Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Bowel' }}
                      control={control}
                      name="Bowel"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.Bladder}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Bladder incontinence:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">Yes</Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Bladder required' }}
                      control={control}
                      name="Bladder"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.Nausea}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Nausea:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">Yes</Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Nausea required' }}
                      control={control}
                      name="Nausea"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.Vomiting}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Vomiting:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">Yes</Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Vomiting required' }}
                      control={control}
                      name="Vomiting"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.Fevers}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Fevers:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">Yes</Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Fevers required' }}
                      control={control}
                      name="Fevers"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.Chills}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Chills:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">Yes</Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Chills required' }}
                      control={control}
                      name="Chills"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.Urinary}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Urinary incontinence:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">Yes</Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Urinary required' }}
                      control={control}
                      name="Urinary"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.Sinus}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Sinus congestion:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">Yes</Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Sinus required' }}
                      control={control}
                      name="Sinus"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.Chest}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Chest pain:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">Yes</Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Chest required' }}
                      control={control}
                      name="Chest"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.Weight}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Weight loss:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">Yes</Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Weight required' }}
                      control={control}
                      name="Weight"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.Fever}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Fever:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">Yes</Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Fever required' }}
                      control={control}
                      name="Fever"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.Rash}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Rash:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">Yes</Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Rash required' }}
                      control={control}
                      name="Rash"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.Diabetes}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Diabetes:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">Yes</Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Diabetes required' }}
                      control={control}
                      name="Diabetes"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.Hearing}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Hearing loss:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">Yes</Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Hearing required' }}
                      control={control}
                      name="Hearing"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.pregnant}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Chance of being pregnant:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">Yes</Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'pregnant required' }}
                      control={control}
                      name="pregnant"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.Constipation1}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Constipation:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">Yes</Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Constipation required' }}
                      control={control}
                      name="Constipation1"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.Musclepain}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Trouble swallowing:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">Yes</Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Trouble required' }}
                      control={control}
                      name="Trouble"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.Musclepain}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Abnormal bruising or bleeding:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">Yes</Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Abnormal required' }}
                      control={control}
                      name="Abnormal"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.Musclepain}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Hepatitis C positive:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">Yes</Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Hepatitis required' }}
                      control={control}
                      name="Hepatitis"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.Musclepain}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      HIV positive:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">Yes</Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'HIV required' }}
                      control={control}
                      name="HIV"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.Musclepain}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Change in sexual function:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">Yes</Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Sexual required' }}
                      control={control}
                      name="Sexual"
                    />
                  </Flex>
                </FormControl>
              </Box>

              <Box pl={['0', '0', '0', '24']}>
                <FormControl py="2" isInvalid={errors.Musclepain}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Muscle pain and spasm:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">
                                <Text>Yes</Text>
                              </Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Muscle Pain required' }}
                      control={control}
                      name="Musclepain"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.Weakness}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Weakness:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">
                                <Text>Yes</Text>
                              </Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Weakness required' }}
                      control={control}
                      name="Weakness"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.Headache}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Headache:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">
                                <Text>Yes</Text>
                              </Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Headache required' }}
                      control={control}
                      name="Headache"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.Tingling}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Tingling/numbness:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">
                                <Text>Yes</Text>
                              </Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Tingling required' }}
                      control={control}
                      name="Tingling"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.Constipation1}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Constipation:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">
                                <Text>Yes</Text>
                              </Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Constipation required' }}
                      control={control}
                      name="Constipation1"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.ChangeVersion}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Change in vision:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">
                                <Text>Yes</Text>
                              </Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Change required' }}
                      control={control}
                      name="ChangeVersion"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.Shortness}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Shortness of breath:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">
                                <Text>Yes</Text>
                              </Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Shortness required' }}
                      control={control}
                      name="Shortness"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.Depression}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Depression:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">
                                <Text>Yes</Text>
                              </Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Depression required' }}
                      control={control}
                      name="Depression"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.Suicidal}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Suicidal thoughts:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">
                                <Text>Yes</Text>
                              </Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Suicidal required' }}
                      control={control}
                      name="Suicidal"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.Suicide}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Suicide attempts:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">
                                <Text>Yes</Text>
                              </Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Suicide required' }}
                      control={control}
                      name="Suicide"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.Attention}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Attention deficit disorder:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">
                                <Text>Yes</Text>
                              </Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Attention required' }}
                      control={control}
                      name="Attention"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.Obsessive}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Obsessive compulsive disorder:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">
                                <Text>Yes</Text>
                              </Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Obsessive required' }}
                      control={control}
                      name="Obsessive"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.Bipolar}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Bipolar:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">
                                <Text>Yes</Text>
                              </Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Bipolar required' }}
                      control={control}
                      name="Bipolar"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.Schizophrenia}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Schizophrenia:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">
                                <Text>Yes</Text>
                              </Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Schizophrenia required' }}
                      control={control}
                      name="Schizophrenia"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.Mentalhealth}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Mental health changes:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">
                                <Text>Yes</Text>
                              </Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Mentalhealth required' }}
                      control={control}
                      name="Mentalhealth"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.Undergoing}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Undergoing counseling:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">
                                <Text>Yes</Text>
                              </Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Undergoing required' }}
                      control={control}
                      name="Undergoing"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.Highstress}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      High stress level:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">
                                <Text>Yes</Text>
                              </Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Highstress required' }}
                      control={control}
                      name="Highstress"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.Psychiatric}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Psychiatric hospitalization:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">
                                <Text>Yes</Text>
                              </Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Psychiatric required' }}
                      control={control}
                      name="Psychiatric"
                    />
                  </Flex>
                </FormControl>

                <FormControl py="2" isInvalid={errors.homicidal}>
                  <Flex
                    w="400px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize={['16px', '16px', '18px', '18px']}>
                      Homicidal ideation:
                    </Text>
                    <Controller
                      fontSize={['16px', '16px', '18px', '18px']}
                      defaultValue={''}
                      render={props => {
                        return (
                          <RadioGroup {...props}>
                            <HStack>
                              <Radio value="Yes">
                                <Text>Yes</Text>
                              </Radio>
                              <Text>/</Text>
                              <Text>No</Text>
                              <Radio value="No"></Radio>
                            </HStack>
                          </RadioGroup>
                        )
                      }}
                      rules={{ required: 'Homicidal required' }}
                      control={control}
                      name="homicidal"
                    />
                  </Flex>
                </FormControl>
              </Box>
            </Flex>
            <Flex
              w="full"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
            >
              <Box border="1px" w={['100%', '100%', '100%', '900px']}></Box>
            </Flex>
          </Flex>
          <Stack>
            <Stack
              direction={['column', 'row']}
              alignItems={'end'}
              justifyContent={[null, 'space-between']}
              spacing={'100px'}
            >
              <FormControl w={'500px'} isInvalid={errors.Sig1}>
                <Controller
                  render={props => (
                    <SignatureCanvas
                      ref={Sig1}
                      onEnd={() => {
                        props.onChange(formatIntoPng())
                      }}
                      penColor="black"
                      canvasProps={{
                        width: 500,
                        height: 100,
                        className: 'sigCanvas',
                      }}
                    />
                  )}
                  name="Sig1"
                  defaultValue={''}
                  control={control}
                  rules={{ required: 'Impotant information required.' }}
                />
                <Divider borderColor={'gray.600'} borderStart={'dotted'} />
                <FormLabel textAlign={'center'}>Signature</FormLabel>
                <FormErrorMessage>
                  {errors.Sig1 && errors.Sig1.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                maxW={['100%', '100%', '30%', '30%']}
                isInvalid={errors.month && errors.day && errors.year}
              >
                <HStack>
                  <Input
                    name="month"
                    type="text"
                    placeholder="mm"
                    ref={register({ required: 'Date required.' })}
                  />
                  <Input
                    name="day"
                    type="text"
                    placeholder="dd"
                    ref={register({ required: 'Date required.' })}
                  />
                  <Input
                    name="year"
                    type="text"
                    placeholder="yyyy"
                    ref={register({ required: 'Date required.' })}
                  />
                </HStack>
                <FormLabel textAlign={'center'}>Date</FormLabel>
                <FormErrorMessage>
                  {errors.month &&
                    errors.day &&
                    errors.year &&
                    errors.month.message}
                </FormErrorMessage>
              </FormControl>
            </Stack>
            <Stack
              direction={['column', 'row']}
              alignItems={'end'}
              justifyContent={[null, 'space-between']}
              spacing={'100px'}
            >
              <FormControl w={'500px'} isInvalid={errors.Sig2}>
                <Controller
                  render={props => (
                    <SignatureCanvas
                      ref={Sig2}
                      onEnd={() => {
                        props.onChange(formatIntoPng())
                      }}
                      penColor="black"
                      canvasProps={{
                        width: 500,
                        height: 100,
                        className: 'sigCanvas',
                      }}
                    />
                  )}
                  name="Sig2"
                  defaultValue={''}
                  control={control}
                  rules={{ required: 'Impotant information required.' }}
                />
                <Divider borderColor={'gray.600'} borderStart={'dotted'} />
                <FormLabel textAlign={'center'}>Signature</FormLabel>
                <FormErrorMessage>
                  {errors.Sig2 && errors.Sig2.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                maxW={['100%', '100%', '30%', '30%']}
                isInvalid={errors.month2 && errors.day2 && errors.year2}
              >
                <HStack>
                  <Input
                    name="month2"
                    type="text"
                    placeholder="mm"
                    ref={register({ required: 'Date required.' })}
                  />
                  <Input
                    name="day2"
                    type="text"
                    placeholder="dd"
                    ref={register({ required: 'Date required.' })}
                  />
                  <Input
                    name="year2"
                    type="text"
                    placeholder="yyyy"
                    ref={register({ required: 'Date required.' })}
                  />
                </HStack>
                <FormLabel textAlign={'center'}>Date</FormLabel>
                <FormErrorMessage>
                  {errors.month2 &&
                    errors.day2 &&
                    errors.year2 &&
                    errors.month2.message}
                </FormErrorMessage>
              </FormControl>
            </Stack>
            <HStack>
              <FormControl
                maxW={['100%', '100%', '100%', '100%']}
                isInvalid={errors.DOBmonth && errors.DOBmday && errors.DOBmyear}
              >
                <HStack>
                  <Input
                    name="DOBmonth"
                    type="text"
                    placeholder="mm"
                    ref={register({ required: 'DOB information required.' })}
                  />
                  <Input
                    name="DOBmday"
                    type="text"
                    placeholder="dd"
                    ref={register({ required: 'DOB information required.' })}
                  />
                  <Input
                    name="DOBmyear"
                    type="text"
                    placeholder="yyyy"
                    ref={register({ required: 'DOB information required.' })}
                  />
                </HStack>
                <FormLabel textAlign={'center'}>
                  Patient's Date of Birth
                </FormLabel>
                <FormErrorMessage>
                  {errors.month &&
                    errors.day &&
                    errors.year &&
                    errors.month.message}
                </FormErrorMessage>
              </FormControl>
            </HStack>
          </Stack>
          <Center my={'10'}>
            <Button
              type="submit"
              colorScheme={'blue'}
              isLoading={formState.isSubmitting}
            >
              Submit
            </Button>
          </Center>
        </form>
      </Container>
    </>
  )
}

export default PatientintakeT
