import { useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Center,
  Container,
  Spinner,
  useToast,
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { doc, onSnapshot, updateDoc } from 'firebase/firestore'

import { db } from '../../../config/firebase'

import RenderPHQGADPDF from '../pdf/pdfPHQGAD'
import RenderODQPDF from '../pdf/pdfODQ'
import RenderSOAPPRPDF from '../pdf/pdfSOAPPR'

export default function PatientFormView() {
  const toast = useToast()
  const params = useParams()
  const [formData, setFormData] = useState()

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(db, 'PatientForm', params.id),
      snapShot => {
        if (snapShot.exists) {
          let data = snapShot.data()
          setFormData({ ...data, id: snapShot.id })
        }
      }
    )
    return () => unsubscribe()
  }, [params])

  function changeStatus(formId, status) {
    updateDoc(doc(db, 'PatientForm', formId), {
      status: status,
      notification: false,
    })
      .then(() => {})
      .catch(e => {
        toast({
          title: 'Change status error',
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  }

  const renderPDF = data => {
    if (data.type === 'PHQ-9-GAD-7')
      return <RenderPHQGADPDF formData={formData} changeStatus={changeStatus} />
    if (formData.type === 'Oswestry Disability')
      return <RenderODQPDF formData={formData} changeStatus={changeStatus} />
    if (formData.type === 'SOAPP-R')
      return <RenderSOAPPRPDF formData={formData} changeStatus={changeStatus} />
  }

  return (
    <Container maxW="100%" mt="5" mb="10">
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
        bg="gray.100"
        p="1.5"
        borderRadius="0.3em"
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/dashboard">
            Dashbaord
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/patient">
            Patient Form
          </BreadcrumbLink>
        </BreadcrumbItem>
        {formData ? (
          <BreadcrumbItem>
            <BreadcrumbLink>
              {formData.name} ({formData.type})
            </BreadcrumbLink>
          </BreadcrumbItem>
        ) : (
          <BreadcrumbItem>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xs"
            />
          </BreadcrumbItem>
        )}
      </Breadcrumb>
      {formData ? (
        <>{renderPDF(formData)}</>
      ) : (
        <Center w="100%" h="20rem">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="lg"
          />
        </Center>
      )}
    </Container>
  )
}
