import { ChevronRightIcon } from '@chakra-ui/icons'
import { FaRegEdit } from 'react-icons/fa'
import {
  Box,
  Button,
  Center,
  Flex,
  GridItem,
  HStack,
  Input,
  Text,
  VStack,
  Popover,
  PopoverContent,
  PopoverArrow,
  PopoverTrigger,
  Portal,
  FormControl,
  useDisclosure,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useBreakpoint,
  useToast,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Container,
  FormLabel,
  FormErrorMessage,
  Spacer,
  RadioGroup,
  Radio,
  Stack,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FaCloudDownloadAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { db } from '../../config/firebase'
import { addDoc, collection } from 'firebase/firestore'
import { Divider } from 'antd'
import moment from 'moment'
import SignatureCanvas from 'react-signature-canvas'
import { useRef } from 'react'
import { SwitchInputComponent } from '../../utils/switchInput'
import Resizer from 'react-image-file-resizer'

const resizeFile = file =>
  new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      'JPEG',
      100,
      0,
      uri => {
        resolve(uri)
      },
      'base64'
    )
  })

function CollectorsCompetencyQuiz({ user }) {
  const {
    handleSubmit,
    // eslint-disable-next-line no-unused-vars
    formState: { errors },
    control,
    reset,
    trigger,
    watch,
    setValue,
  } = useForm()
  // eslint-disable-next-line no-unused-vars
  const [isDisabled, setIsDisabled] = useState(false)
  const { isOpen, onToggle, onClose } = useDisclosure()
  // console.log(errors)
  // console.log(watch())
  //function
  function submitData(value) {
    console.clear()

    console.log(value)
    setIsDisabled(true)
    // value.dateTesting = value.dateTesting.format('YYYY-MM-DD')
    addDoc(collection(db, 'EmployeeForm'), {
      ...value,
      name: user.fullName,
      type: 'Collectors Competency Quiz',
      createdAt: new Date(),
      notification: true,
      status: 'Pending',
    }).then(() => {
      toast({
        title: 'Submit Successfully',
        description: 'Form has been submmited.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      setIsDisabled(false)
      reset()
    })
  }

  const breakPoint = useBreakpoint()

  const sigCanvas = useRef({})

  const clear = () => sigCanvas.current.clear()
  const signatureCollector = watch('signatureCollector')
  // useEffect(() => {
  //   console.log(signatureCollector)
  // }, [signatureCollector])

  const fileUploadInputRef = useRef(null)
  const toast = useToast()

  function onClickFileUpload() {
    fileUploadInputRef && fileUploadInputRef.current.click()
  }

  async function onUploadChange(event) {
    const file = event.target?.files[0]

    if (
      file?.type === 'image/jpg' ||
      file?.type === 'image/jpeg' ||
      file?.type === 'image/png'
    ) {
      const image = await resizeFile(file)
      setValue('signatureCollector', image)
      toast({
        duration: 3000,
        title: 'successfully uploaded.',
        status: 'success',
        isClosable: true,
      })
      onClose()
    } else {
      toast({
        duration: 3000,
        title: 'only file .jpg, jpeg and .png are supported.',
        status: 'info',
        isClosable: true,
      })
    }
  }
  const save = () =>
    setValue(
      'signatureCollector',
      sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')
    )
  return (
    <Container maxW="100%" mt={5}>
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
        bg="gray.100"
        p="1.5"
        borderRadius="0.3em"
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/dashboard">
            Dashbaord
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/collectors-competency-quiz">
            Collectors Competency Quiz
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Box mt="30" mb="50" width={['100']}>
        <Flex mt="10" mb="10">
          <Box pt="2" fontSize="18" fontWeight="bold">
            Collectors Competency Quiz
          </Box>
          <Spacer />
          <Button
            leftIcon={<FaCloudDownloadAlt />}
            size="sm"
            onClick={() => {
              window.open('/doc/collectors-competency-quiz.pdf')
            }}
          >
            PDF
          </Button>
        </Flex>
      </Box>
      <form onSubmit={handleSubmit(submitData)}>
        <FormControl mb="5" isRequired>
          <FormLabel>
            {`1) Two patient identifiers must be used to identify a patient before
            collection process may begin?`}
          </FormLabel>
          <Controller
            name="quizOne"
            control={control}
            defaultValue={null}
            rules={{ required: true }}
            render={field => (
              <RadioGroup {...field}>
                <Stack>
                  <Radio value="true">True</Radio>
                  <Radio value="false">False</Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>
        <FormControl mb="5" isRequired>
          <FormLabel>
            {`2) An unlabeled specimen is acceptable to use for testing?`}
          </FormLabel>
          <Controller
            name="quizTwo"
            control={control}
            defaultValue={null}
            rules={{ required: true }}
            render={field => (
              <RadioGroup {...field}>
                <Stack>
                  <Radio value="true">True</Radio>
                  <Radio value="false">False</Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>
        <FormControl mb="5" isRequired>
          <FormLabel>
            {`3) The temperature of a sample is used to prove validity of sample
            being collected?`}
          </FormLabel>
          <Controller
            name="quizThree"
            control={control}
            defaultValue={null}
            rules={{ required: true }}
            render={field => (
              <RadioGroup {...field}>
                <Stack>
                  <Radio value="true">True</Radio>
                  <Radio value="false">False</Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>
        <FormControl mb="5" isRequired>
          <FormLabel>
            {`4) Two patient identifiers must be on all specimens submitted for
            testing?`}
          </FormLabel>
          <Controller
            name="quizFour"
            control={control}
            defaultValue={null}
            rules={{ required: true }}
            render={field => (
              <RadioGroup {...field}>
                <Stack>
                  <Radio value="true">True</Radio>
                  <Radio value="false">False</Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>
        <FormControl mb="5" isRequired>
          <FormLabel>
            {`5) Samples that cannot be tested within three days of collection
            should be kept in fridge?`}
          </FormLabel>
          <Controller
            name="quizFive"
            control={control}
            defaultValue={null}
            rules={{ required: true }}
            render={field => (
              <RadioGroup {...field}>
                <Stack>
                  <Radio value="true">True</Radio>
                  <Radio value="false">False</Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>
        <FormControl maxW={'200px'} mb="5" isRequired>
          <FormLabel>{`6) This is an acceptable specimen label:`}</FormLabel>
          <VStack alignItems={'start'}>
            <Box border={'1px'} m={4} p={4} borderRadius={'10px'}>
              <Text>Name : John</Text>
              <Text>DOB : 1962</Text>
              <Text>Date : 10/3</Text>
              <Text>Time : 10:15</Text>
            </Box>
          </VStack>
          <Controller
            name="quizSix"
            control={control}
            defaultValue={''}
            rules={{ required: true }}
            render={field => (
              <RadioGroup {...field}>
                <Stack>
                  <Radio value="true">True</Radio>
                  <Radio value="false">False</Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>
        <FormControl mb="5" isRequired>
          <FormLabel>
            {`7) Who must sign the patient requisition before testing may take
            place?`}
          </FormLabel>
          <Controller
            name="quizSeven"
            control={control}
            defaultValue={null}
            rules={{ required: true }}
            render={field => (
              <RadioGroup {...field}>
                <Stack>
                  <Radio value="answerA">{`a) Doctor only`}</Radio>
                  <Radio value="answerB">{`b) Doctor and patient`}</Radio>
                  <Radio value="answerC">{`c) Patient only`}</Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>
        <FormControl mb="5" isRequired>
          <FormLabel>
            {`8) Samples that can be tested within three days after collection may
            be kept in fridge?`}
          </FormLabel>
          <Controller
            name="quizEigth"
            control={control}
            defaultValue={''}
            rules={{ required: true }}
            render={field => (
              <RadioGroup {...field}>
                <Stack>
                  <Radio value="true">True</Radio>
                  <Radio value="false">False</Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>
        <FormControl mb="5" isRequired>
          <FormLabel>
            {`9) Temp of the fridge must fall between 2 and 8 degrees Celsius?`}
          </FormLabel>
          <Controller
            name="quizNine"
            control={control}
            defaultValue={''}
            rules={{ required: true }}
            render={field => (
              <RadioGroup {...field}>
                <Stack>
                  <Radio value="true">True</Radio>
                  <Radio value="false">False</Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>
        <FormControl mb="5" isRequired>
          <FormLabel>
            {`10) A patient has asked if their friend may accompany them into the
            restroom to help them. This is an acceptable course of action when
            collecting a sample for testing?`}
          </FormLabel>
          <Controller
            name="quizTen"
            control={control}
            defaultValue={''}
            rules={{ required: true }}
            render={field => (
              <RadioGroup {...field}>
                <Stack>
                  <Radio value="true">True</Radio>
                  <Radio value="false">False</Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>
        <Divider />

        <Flex>
          <Controller
            name="signatureCollector"
            control={control}
            rules={{ required: true }}
            defaultValue={''}
            render={({ field }) => (
              <FormControl w="30vw" isInvalid={errors["signatureCollector"]}>
                <GridItem {...field}>
                  <VStack spacing="0px" justifyContent={'flex-start'}>
                    <Center h={{ base: '80px' }}>
                      <Text fontWeight="bold" fontSize="15" textAlign="center">
                        Signature of Collector
                      </Text>
                    </Center>
                    <HStack
                      h="40px"
                      w="100%"
                      flexDirection="row"
                      borderBottom={'1px solid #777'}
                    >
                      <Center w="100%">
                        <Flex>
                          <SwitchInputComponent
                            data={signatureCollector || ''}
                          />
                        </Flex>
                      </Center>
                      <Flex
                        h="40px"
                        alignItems="flex-end"
                        justifyContent="flex-end"
                      >
                        <Popover
                          placement="top"
                          isOpen={isOpen}
                          onClose={onClose}
                        >
                          <PopoverTrigger p="0px">
                            <Button
                              bg="white"
                              onClick={() => {
                                setValue('signatureCollector', ' ')
                                onToggle()
                              }}
                            >
                              <FaRegEdit size="25" color="gray" />
                            </Button>
                          </PopoverTrigger>
                          <Portal size="auto">
                            <PopoverContent
                              w={{ base: '20rem', sm: '20rem', md: '30rem' }}
                              h="auto"
                              boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                            >
                              <PopoverArrow />
                              <Box w="100%" h="100%">
                                <Text p="0.75rem" fontSize="12px" color="grey">
                                  <span style={{ color: 'red' }}>{`*`}</span>
                                  {`Any signature (including any electronic symbol
                                or process attached to, or associated with, a
                                contract or other record and adopted by a Person
                                with the intent to sign, authenticate or accept
                                such contract or record)`}
                                </Text>

                                <Box p="0.75rem" pt="1rem" h="auto">
                                  <Tabs variant="enclosed">
                                    <TabList>
                                      <Tab
                                        onClick={() => {
                                          clear()
                                        }}
                                      >
                                        Text Signature
                                      </Tab>
                                      <Tab
                                        onClick={() => {
                                          setValue('signatureCollector', ' ')
                                        }}
                                      >
                                        Draw Signature
                                      </Tab>
                                      <Tab
                                        onClick={() => {
                                          setValue('signatureCollector', ' ')
                                          clear()
                                        }}
                                      >
                                        Upload Signature
                                      </Tab>
                                    </TabList>
                                    <TabPanels h="auto">
                                      <TabPanel
                                        h="22rem"
                                        display="flex"
                                        flexDirection="column"
                                      >
                                        <Box
                                          ml={{ base: '10px' }}
                                          mt={{ base: '10px' }}
                                          mr={{ base: '10px' }}
                                          h="100%"
                                        >
                                          <Box h="100%">
                                            <Controller
                                              name="signatureCollector"
                                              control={control}
                                              defaultValue={''}
                                              render={({ field }) => (
                                                <FormControl>
                                                  <Input
                                                    {...field}
                                                    id="signatureTextInput"
                                                    placeholder="Name"
                                                    onChange={event => {
                                                      setValue(
                                                        'signatureCollector',
                                                        event.target.value
                                                      )
                                                    }}
                                                  />
                                                </FormControl>
                                              )}
                                            />
                                            <Center w="100%">
                                              <Text
                                                pt="2rem"
                                                fontFamily={'Homemade Apple'}
                                                fontSize={{
                                                  base: '18px',
                                                  sm: '18px',
                                                  md: '22px',
                                                }}
                                              >
                                                {watch('signatureCollector')}
                                              </Text>
                                            </Center>
                                          </Box>
                                        </Box>
                                        <Flex
                                          pl="10px"
                                          pb="5px"
                                          pr="10px"
                                          justifyContent="flex-end"
                                          h="max-content"
                                          alignItems="end"
                                        >
                                          <Button
                                            mt={4}
                                            h="1.8rem"
                                            fontSize="14px"
                                            w="4.5rem"
                                            bg="#17A2B8"
                                            color="white"
                                            colorScheme="cyan"
                                            onClick={async () => {
                                              let triggerValue = await trigger(
                                                'signatureCollector',
                                                'Name'
                                              )
                                              if (triggerValue) {
                                                setValue(
                                                  'signatureCollector',
                                                  signatureCollector
                                                )
                                                onClose()
                                              }
                                            }}
                                          >
                                            Confirm
                                          </Button>
                                        </Flex>
                                      </TabPanel>
                                      <TabPanel
                                        h="22rem"
                                        display="flex"
                                        flexDirection="column"
                                      >
                                        <Box
                                          h="100%"
                                          border="1px solid #F0F0F0"
                                        >
                                          <SignatureCanvas
                                            style={{
                                              backgroundColor: 'red',
                                            }}
                                            penColor="black"
                                            canvasProps={{
                                              width: switchCanvas(
                                                breakPoint,
                                                'width'
                                              ),
                                              height: switchCanvas(
                                                breakPoint,
                                                'height'
                                              ),
                                            }}
                                            ref={sigCanvas}
                                          />
                                          <Flex
                                            pl="10px"
                                            pb="5px"
                                            pr="10px"
                                            justifyContent="space-between"
                                            h="max-content"
                                            alignItems="end"
                                          >
                                            <Center>
                                              <Button
                                                fontSize="14px"
                                                px="1rem"
                                                h="1.7rem"
                                                colorScheme="blackAlpha"
                                                onClick={() => clear()}
                                              >
                                                Clear
                                              </Button>
                                            </Center>
                                            <Button
                                              mt={4}
                                              h="1.8rem"
                                              fontSize="14px"
                                              w="4.5rem"
                                              bg="#17A2B8"
                                              color="white"
                                              colorScheme="cyan"
                                              onClick={async () => {
                                                let triggerValue =
                                                  await trigger(
                                                    'signatureCollector'
                                                  )
                                                if (triggerValue) {
                                                  onClose()
                                                  save()
                                                } else {
                                                }
                                              }}
                                            >
                                              Confirm
                                            </Button>
                                          </Flex>
                                        </Box>
                                      </TabPanel>
                                      <TabPanel
                                        h="22rem"
                                        display="flex"
                                        flexDirection="column"
                                      >
                                        <Center h="100%" w="100%">
                                          <Input
                                            type="file"
                                            display="none"
                                            ref={fileUploadInputRef}
                                            onChange={onUploadChange}
                                          />
                                          <Button
                                            color="#AEAEAE"
                                            border="2px solid #AEAEAE"
                                            bg="transparent"
                                            _hover={{
                                              bg: '#AEAEAE',
                                              color: '#fff',
                                              opacity: '0.5',
                                            }}
                                            _active={{
                                              bg: '#AEAEAE',
                                              color: '#fff',
                                              opacity: '1',
                                            }}
                                            onClick={onClickFileUpload}
                                          >
                                            Upload
                                          </Button>
                                        </Center>
                                        <Flex
                                          pl="10px"
                                          pb="5px"
                                          pr="10px"
                                          justifyContent="flex-end"
                                          h="max-content"
                                          alignItems="end"
                                        >
                                          <Button
                                            mt={4}
                                            h="1.8rem"
                                            fontSize="14px"
                                            w="4.5rem"
                                            bg="#17A2B8"
                                            color="white"
                                            colorScheme="cyan"
                                            onClick={async () => {
                                              let triggerValue = await trigger(
                                                'signaturePatient'
                                              )
                                              if (triggerValue) {
                                                onClose()
                                              } else {
                                              }
                                            }}
                                          >
                                            Confirm
                                          </Button>
                                        </Flex>
                                      </TabPanel>
                                    </TabPanels>
                                  </Tabs>
                                </Box>
                              </Box>
                            </PopoverContent>
                          </Portal>
                        </Popover>
                      </Flex>
                    </HStack>
                  </VStack>
                </GridItem>
                <FormErrorMessage>
                  {errors['signatureCollector'] &&
                    errors['signatureCollector'].message}
                </FormErrorMessage>
              </FormControl>
            )}
          />

          <Spacer />
          <Box mx={9}>
            <FormControl isRequired>
              <Controller
                render={props => (
                  <Input
                    value={props.value}
                    onChange={props.onChange}
                    isReadOnly
                    textAlign={'center'}
                  />
                )}
                name="dateTesting"
                control={control}
                defaultValue={moment().format('YYYY-MM-DD')}
                rules={{ required: 'Date is required.' }}
              />
              <FormLabel textAlign={'center'}>Date of testing</FormLabel>
            </FormControl>
          </Box>
        </Flex>
        <Text m={4} fontWeight={'bold'}>
          Score must be 70% or collector must be retrained within 90 days{' '}
        </Text>
        <Center mt="5">
          <Button
            colorScheme="blue"
            width={['100%', '100%', '100%', '10%']}
            type="submit"
            isDisabled={isDisabled}
            isLoading={isDisabled}
            mb={9}
            onClick={handleSubmit(submitData)}
          >
            Submit
          </Button>
        </Center>
      </form>
    </Container>
  )
}

export default CollectorsCompetencyQuiz

function switchCanvas(bp, type) {
  if (type === 'width') {
    switch (bp) {
      case 'base':
        return 275
      case 'sm':
        return 275
      case 'md':
        return 425
      case 'lg':
        return 425
      case 'xl':
        return 425
      case '2xl':
        return 425
      default:
        return 150
    }
  }
  if (type === 'height') {
    switch (bp) {
      case 'base':
        return 150
      case 'sm':
        return 150
      case 'md':
        return 225
      case 'lg':
        return 225
      case 'xl':
        return 225
      case '2xl':
        return 225
      default:
        return 150
    }
  }
}
