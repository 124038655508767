import React from 'react'
import moment from 'moment'

import {
  Text,
  Image,
  PDFViewer,
  StyleSheet,
  Document,
  Page,
  View,
} from '@react-pdf/renderer'
import { tbHighRiskQuestion } from '../../Forms/TBHighRiskForm'
export default function PDFTBHighRisk({ employeeData }) {
  const styles = StyleSheet.create({
    pdfView: {
      width: '100%',
      height: window.innerHeight,
      backgroundColor: '#fff',
      fontFamily: 'Open Sans',
    },
    page: {
      width: '100%',
      flexDirection: 'column',
      backgroundColor: '#fff',
      alignItems: 'center',
      padding: '20px 40px',
    },
    section: {
      width: '100%',
      marginBottom: 10,
      padding: 10,
      alignItems: 'center',
    },
    sectionImg: {
      width: '100%',
      marginTop: 25,
      padding: 0,
      alignItems: 'center',
    },
    img: {
      width: '150px',
      height: 'auto',
      objectFit: 'cover',
    },
    textTitle: {
      paddingHorizontal: 80,
      fontSize: '14px',
      textAlign: 'center',
    },
    boxInfo: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontSize: '12px',
      marginBottom: 20,
    },
    textName: {
      fontSize: '12px',
      borderBottom: 1,
      textAlign: 'center',
      width: '100%',
    },
    boxSubHeader: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      fontSize: '12px',
      marginBottom: 5,
    },
    boxAnswers: {
      width: '100%',
      margin: '20px 0px',
    },
    boxAnswersList: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      fontSize: '12px',
      marginBottom: 10,
    },
    boxSignatureAndDate: {
      marginTop: 20,
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    boxSignature: {
      width: '68%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      fontSize: '10px',
    },
    boxDate: {
      width: '30%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      fontSize: '10px',
    },
    signatureStyle: {
      paddingLeft: 12,
      paddingBottom: 2,
      width: '100px',
      height: '50px',
      objectFit: 'contain',
      borderBottom: 1,
    },
    boxAnswersCheckGroup: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0px 2px',
    },
    boxAnswersCheck: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0px 2px',
      position: 'relative',
    },
    boxCheck: {
      width: '20px',
      height: '20px',
      backgroundColor: '#fff',
      border: '1px solid #000',
      margin: '0px 4px',
    },
    imgCheckYes: {
      width: '25px',
      height: '25px',
      position: 'absolute',
      top: -10,
      left: 27,
    },
    imgCheckNo: {
      width: '25px',
      height: '25px',
      position: 'absolute',
      top: -10,
      left: 24,
    },
  })
  return (
    <PDFViewer style={styles.pdfView}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.sectionImg}>
            <Image style={styles.img} src="/paincare-web.png" />
          </View>
          <View style={styles.section}>
            <Text style={styles.textTitle}>TB High Risk Questionnaire</Text>
          </View>
          <View style={styles.boxInfo}>
            <Text>Employee Name :</Text>
            <Text style={styles.textName}>{employeeData?.name || '-'}</Text>
          </View>
          <View style={styles.boxSubHeader}>
            <Text>
              Targeted TB Testing will only be done if any of the following
              questions are answered “Yes”
            </Text>
          </View>
          <View style={styles.boxSubHeader}>
            <Text>
              Does the employee have any of the following risk
              factors/Conditions?
            </Text>
          </View>
          <View style={styles.boxAnswers}>
            {tbHighRiskQuestion.map((item, index) => (
              <View style={styles.boxAnswersList} key={index}>
                <Text>{item}</Text>
                <View style={styles.boxAnswersCheckGroup}>
                  <View style={styles.boxAnswersCheck}>
                    <Text>Yes</Text>
                    <View style={styles.boxCheck} />
                    {employeeData?.answers[index] === 'yes' && (
                      <Image style={styles.imgCheckYes} src="/check.png" />
                    )}
                  </View>
                  <View style={styles.boxAnswersCheck}>
                    <Text>No</Text>
                    <View style={styles.boxCheck} />
                    {employeeData?.answers[index] === 'no' && (
                      <Image style={styles.imgCheckNo} src="/check.png" />
                    )}
                  </View>
                </View>
              </View>
            ))}
          </View>
          <View style={styles.boxSubHeader}>
            <Text>
              The above recommendations for targeted TB testing were issued by
              the US Public Health Service Advisory Council on Elimination of
              Tuberculosis. If the employee falls into any of the above risk
              groups, TB testing is indicated and treatment for Latent TB
              infection if shown to be present will be offered.
            </Text>
          </View>
          <View style={styles.boxSignatureAndDate}>
            <View style={styles.boxSignature}>
              <Text>Employee Signature :</Text>
              <Image
                style={styles.signatureStyle}
                src={employeeData.signatureCollector}
              />
            </View>
            <View style={styles.boxDate}>
              <Text>Date :</Text>
              <Text style={styles.textName}>
                {moment(employeeData?.date).format('MM/DD/YYYY')}
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  )
}
