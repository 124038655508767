import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import {
    Container,
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Text,
    FormErrorMessage,
    FormLabel,
    FormControl,
    Input,
    Button,
    useToast,
    HStack,
    Center,
    Textarea,
    Radio,
    RadioGroup,
    Checkbox,
    Flex,
    Spacer
} from "@chakra-ui/react"
import { DatePicker } from 'antd'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { FaCloudDownloadAlt } from 'react-icons/fa'
import { addDoc, collection } from 'firebase/firestore'
import { db } from '../../config/firebase'

function CovidExprosureScreening({ user }) {
    const { handleSubmit, reset, register, errors, control } = useForm()
    const toast = useToast()
    const [isDisabled, setIsDisabled] = useState(false)
    function submitData(value) {
        setIsDisabled(true)
        value.date = value.date.format("YYYY-MM-DD")
        addDoc(collection(db, "EmployeeForm"), {
            ...value,
            name: user.fullName,
            type: "Covid-19 Questionnairs", createdAt: new Date(),
            notification: true,
            status: "Pending"
        })
            .then(() => {
                toast({
                    title: "Submit Successfully",
                    description: "Form has been submmited.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                })
                setIsDisabled(false)
                reset()
            })
    }
    return (
        <Container maxW="100%" mt="5">
            <Breadcrumb
                spacing="8px"
                separator={<ChevronRightIcon color="gray.500" />}
                bg="gray.100" p="1.5"
                borderRadius="0.3em"
            >
                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to="/dashboard">Dashbaord</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to="/covid-exprosure-screening">Covid Exprosure Screening</BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>
            <Box mt="30" mb="100" width={["100"]}>
                <Flex mt="10" mb="10">
                    <Box pt="2" fontSize="18" fontWeight="bold">
                        Covid Exprosure Screening
                    </Box>
                    <Spacer />
                    <Button onClick={() => { window.open('https://firebasestorage.googleapis.com/v0/b/paincare-a9e20.appspot.com/o/Downloads%2FForms%2FPCSO_COVID19-Questionnairs_Form-Staff.pdf?alt=media&token=4f91ad80-90d2-429c-b69b-4cc72795702c', '_blank') }} leftIcon={<FaCloudDownloadAlt />} size="sm">PDF</Button>
                </Flex>
                <form onSubmit={handleSubmit(submitData)}>
                    <HStack mb="5" width={["100%", "100%", "100%", "80%"]}>
                        <FormControl id="name" isReadOnly>
                            <FormLabel>NAME</FormLabel>
                            <Input name="name" defaultValue={user.fullName} />
                        </FormControl>
                        <FormControl isInvalid={errors.date}>
                            <FormLabel>Date</FormLabel>
                            <Controller
                                render={props => (
                                    <DatePicker onChange={props.onChange} size="large" format="YYYY-MM-DD" style={{ width: "100%" }} />
                                )}
                                name="date"
                                control={control}
                                defaultValue={null}
                                rules={{ required: "Date required." }}
                            />
                            <FormErrorMessage>{errors.date && errors.date.message}</FormErrorMessage>
                        </FormControl>
                    </HStack>
                    <FormControl mb="5" isInvalid={errors.temperatureOf100orMore}>
                        <FormLabel>Do you have a temperature of 100º F or more?</FormLabel>
                        <Controller
                            name="temperatureOf100orMore"
                            control={control}
                            defaultValue={null}
                            rules={{ required: "Answer is required." }}
                            render={(props) => (
                                <RadioGroup name={props.name} onChange={props.onChange} defaultValue={props.value}>
                                    <HStack>
                                        <Radio value="yes">Yes</Radio>
                                        <Radio value="no">No</Radio>
                                    </HStack>
                                </RadioGroup>
                            )}
                        />
                        <FormErrorMessage>{errors.temperatureOf100orMore && errors.temperatureOf100orMore.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl mb="5" isInvalid={errors.handCleanedOnArrival}>
                        <FormLabel>Hands cleaned with Soap and Water or Alcohol based waterless sanitizer on arrival</FormLabel>
                        <Controller
                            name="handCleanedOnArrival"
                            control={control}
                            defaultValue={null}
                            rules={{ required: "Answer is required." }}
                            render={(props) => (
                                <RadioGroup name={props.name} onChange={props.onChange} defaultValue={props.value}>
                                    <HStack>
                                        <Radio value="yes">Yes</Radio>
                                        <Radio value="no">No</Radio>
                                    </HStack>
                                </RadioGroup>
                            )}
                        />
                        <FormErrorMessage>{errors.temperatureOf100orMore && errors.temperatureOf100orMore.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl mb="5" isInvalid={errors.wearingAFacemaskOnArrival}>
                        <FormLabel>Wearing a facemask on arrival *If no mask provide mask immediately</FormLabel>
                        <Controller
                            name="wearingAFacemaskOnArrival"
                            control={control}
                            defaultValue={null}
                            rules={{ required: "Answer is required." }}
                            render={(props) => (
                                <RadioGroup name={props.name} onChange={props.onChange} defaultValue={props.value}>
                                    <HStack>
                                        <Radio value="yes">Yes</Radio>
                                        <Radio value="no">No</Radio>
                                    </HStack>
                                </RadioGroup>
                            )}
                        />
                        <FormErrorMessage>{errors.wearingAFacemaskOnArrival && errors.wearingAFacemaskOnArrival.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl mb="5" isInvalid={errors.doYouCurrentlyHaveACough}>
                        <FormLabel>Do you currently have a cough or a cold?</FormLabel>
                        <Controller
                            name="doYouCurrentlyHaveACough"
                            control={control}
                            defaultValue={null}
                            rules={{ required: "Answer is required." }}
                            render={(props) => (
                                <RadioGroup name={props.name} onChange={props.onChange} defaultValue={props.value}>
                                    <HStack>
                                        <Radio value="yes">Yes</Radio>
                                        <Radio value="no">No</Radio>
                                    </HStack>
                                </RadioGroup>
                            )}
                        />
                        <FormErrorMessage>{errors.doYouCurrentlyHaveACough && errors.doYouCurrentlyHaveACough.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl mb="5" isInvalid={errors.doYouHaveAnyShortnessOfBreath}>
                        <FormLabel>Do you have any shortness of breath?</FormLabel>
                        <Controller
                            name="doYouHaveAnyShortnessOfBreath"
                            control={control}
                            defaultValue={null}
                            rules={{ required: "Answer is required." }}
                            render={(props) => (
                                <RadioGroup name={props.name} onChange={props.onChange} defaultValue={props.value}>
                                    <HStack>
                                        <Radio value="yes">Yes</Radio>
                                        <Radio value="no">No</Radio>
                                    </HStack>
                                </RadioGroup>
                            )}
                        />
                        <FormErrorMessage>{errors.doYouHaveAnyShortnessOfBreath && errors.doYouHaveAnyShortnessOfBreath.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl mb="5" isInvalid={errors.doYouFeelSick}>
                        <FormLabel>Do you feel sick?</FormLabel>
                        <Controller
                            name="doYouFeelSick"
                            control={control}
                            defaultValue={null}
                            rules={{ required: "Answer is required." }}
                            render={(props) => (
                                <RadioGroup name={props.name} onChange={props.onChange} defaultValue={props.value}>
                                    <HStack>
                                        <Radio value="yes">Yes</Radio>
                                        <Radio value="no">No</Radio>
                                    </HStack>
                                </RadioGroup>
                            )}
                        />
                        <FormErrorMessage>{errors.doYouFeelSick && errors.doYouFeelSick.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl mb="5" isInvalid={errors.inTheLast14DaysHaveYouTraveledOutofTheState}>
                        <FormLabel>In the last 14 days have you traveled out of the state?</FormLabel>
                        <Controller
                            name="inTheLast14DaysHaveYouTraveledOutofTheState"
                            control={control}
                            defaultValue={null}
                            rules={{ required: "Answer is required." }}
                            render={(props) => (
                                <RadioGroup name={props.name} onChange={props.onChange} defaultValue={props.value}>
                                    <HStack>
                                        <Radio value="yes">Yes</Radio>
                                        <Radio value="no">No</Radio>
                                    </HStack>
                                </RadioGroup>
                            )}
                        />
                        <FormErrorMessage>{errors.inTheLast14DaysHaveYouTraveledOutofTheState && errors.inTheLast14DaysHaveYouTraveledOutofTheState.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl mb="5" isInvalid={errors.inTheLast14DaysHaveyouHadContactWithSomeoneWithSymptoms}>
                        <FormLabel>In the last 14 days have you had contact with someone with symptoms or under investigation for COVID-19, or are ill with respiratory illness?</FormLabel>
                        <Controller
                            name="inTheLast14DaysHaveyouHadContactWithSomeoneWithSymptoms"
                            control={control}
                            defaultValue={null}
                            rules={{ required: "Answer is required." }}
                            render={(props) => (
                                <RadioGroup name={props.name} onChange={props.onChange} defaultValue={props.value}>
                                    <HStack>
                                        <Radio value="yes">Yes</Radio>
                                        <Radio value="no">No</Radio>
                                    </HStack>
                                </RadioGroup>
                            )}
                        />
                        <FormErrorMessage>{errors.inTheLast14DaysHaveyouHadContactWithSomeoneWithSymptoms && errors.inTheLast14DaysHaveyouHadContactWithSomeoneWithSymptoms.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl mb="5" isInvalid={errors.areYouresidingInACommunityWhereCommunityBased}>
                        <FormLabel>Are you residing in a community where community-based spread of COVID-19 is occurring?</FormLabel>
                        <Controller
                            name="areYouresidingInACommunityWhereCommunityBased"
                            control={control}
                            defaultValue={null}
                            rules={{ required: "Answer is required." }}
                            render={(props) => (
                                <RadioGroup name={props.name} onChange={props.onChange} defaultValue={props.value}>
                                    <HStack>
                                        <Radio value="yes">Yes</Radio>
                                        <Radio value="no">No</Radio>
                                    </HStack>
                                </RadioGroup>
                            )}
                        />
                        <FormErrorMessage>{errors.areYouresidingInACommunityWhereCommunityBased && errors.areYouresidingInACommunityWhereCommunityBased.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl mb="5" isInvalid={errors.haveYouBeenTestedForCovid19}>
                        <FormLabel>Have you been tested for COVID-19 If yes, please write date of last test and result</FormLabel>
                        <Controller
                            name="haveYouBeenTestedForCovid19"
                            control={control}
                            defaultValue={null}
                            rules={{ required: "Answer is required." }}
                            render={(props) => (
                                <RadioGroup name={props.name} onChange={props.onChange} defaultValue={props.value}>
                                    <HStack>
                                        <Radio value="yes">Yes</Radio>
                                        <Radio value="no">No</Radio>
                                    </HStack>
                                </RadioGroup>
                            )}
                        />
                        <FormErrorMessage>{errors.haveYouBeenTestedForCovid19 && errors.haveYouBeenTestedForCovid19.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl mb="3">
                        <FormLabel>Action taken:</FormLabel>
                        <Checkbox name="actionTaken" value="N/A" ref={register({ required: "Action Taken is required." })}>N/A:</Checkbox>
                    </FormControl>
                    <Text>Or</Text>
                    <FormControl mb="5">
                        <Textarea name="otherReason" cols="5" width={["100%", "100%", "100%", "50%"]} ref={register()} />
                    </FormControl>
                    <Text mb="5" color="red.500">
                        ** Employee certifies that all information provided is true and complete to the best of his/her knowledge. Falsification of this form is grounds for disciplinary action, up to and including dismissal.
                    </Text>
                    <Center mt="10">
                        <Button colorScheme="blue" width={["100%", "100%", "100%", "10%"]} isLoading={isDisabled} disabled={isDisabled} type="submit">Submit</Button>
                    </Center>
                </form>
            </Box>
        </Container>
    )
}

export default CovidExprosureScreening