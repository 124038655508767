import React, { useEffect } from 'react'
import {
  Center,
  Box,
  Text,
  Table,
  Tbody,
  Th,
  Td,
  Tr,
  FormControl,
  RadioGroup,
  Radio,
  Image,
  Flex,
  HStack,
} from '@chakra-ui/react'
import { CardContent } from '../../../../../../Components/Card/card'
import { Controller, useForm } from 'react-hook-form'
import { totalNonWebEnabledPage } from '../..'

const _ = require('underscore')

function Soap({ formView }) {
  const {
    // handleSubmit,
    // reset,
    // register,
    // errors,
    control,
    setValue,
    // clearErrors,
  } = useForm()

  useEffect(() => {
    if (formView) {
      const data = formView
      _.keys(data).map(e => {
        return setValue(e, data[e])
      })
    }
  }, [formView, setValue])

  const question = [
    {
      title: '1. How often do you have mood swings?',
      name: 'howSwings',
    },
    {
      title:
        '2. How often have you felt a need for higher doses of medication to treat your pain?',
      name: 'howHigh',
    },
    {
      title: '3. How often have you felt impatient with your doctors?',
      name: 'howImpatient',
    },
    {
      title:
        "4. How often have you felt that things are just too overwhelming that you can't handle them?",
      name: 'howThings',
    },
    {
      title: '5. How often is there tension in the home?',
      name: 'howTension',
    },
    {
      title:
        '6. How often have you counted pain pills to see how many are remaining?',
      name: 'howPain',
    },
    {
      title:
        '7. How often have you been concerned that people will judge you for taking pain medication?',
      name: 'howConcerned',
    },
    {
      title: '8. How often do you feel bored?',
      name: 'howBored',
    },
    {
      title:
        '9. How often have you taken more pain medication than you were supposed to?',
      name: 'howMedication',
    },
    {
      title: '10. How often have you worried about being left alone?',
      name: 'howWorried',
    },
    {
      title: '11. How often have you felt a craving for medication?',
      name: 'howCraving',
    },
    {
      title:
        '12. How often have others expressed concern over your use of medication?',
      name: 'howConcern',
    },
    {
      title:
        '13. How often have any of your close friends had a problem with alcohol or drugs?',
      name: 'howClose',
    },
    {
      title: '14. How often have others told you that you had a bad temper?',
      name: 'howTold',
    },
    {
      title:
        '15. How often have you felt consumed by the need to get pain medication?',
      name: 'howConsumed',
    },
    {
      title: '16. How often have you run out of pain medication early?',
      name: 'howEarly',
    },
    {
      title:
        '17. How often have others kept you from getting what you deserve?',
      name: 'howKept',
    },
    {
      title:
        '18. How often, in your lifetime, have you had legal problems or been arrested?',
      name: 'howLifetime',
    },
    {
      title: '19. How often have you attended an AA or NA meeting?',
      name: 'howAttended',
    },
    {
      title:
        '20. How often have you been in an argument that was so out of control that someone got hurt?',
      name: 'howArgument',
    },
    {
      title: '21. How often have you been sexually abused?',
      name: 'howSexually',
    },
    {
      title:
        '22. How often have others suggested that you have a drug or alcohol problem?',
      name: 'howSuggested',
    },
    {
      title:
        '23. How often have you had to borrow pain medications from your family or friends?',
      name: 'howBorrow',
    },
    {
      title:
        '24. How often have you been treated for an alcohol or drug problem?',
      name: 'howAlcohol',
    },
  ]

  return (
    <CardContent>
      <Box>
        <Text pb="20px" fontWeight="bold" textAlign="center">
          SOAPP®-R
        </Text>
        <Text pb="40px">
          The following are some questions given to patients who are on or being
          considered for medication for their pain. Please answer each question
          as honestly as possible. There are no right or wrong answers.
        </Text>
        <Center pb="0.5rem">
          <Flex
            flexWrap="wrap"
            flexDirection={{
              base: 'column',
              sm: 'column',
              md: 'column',
              lg: 'row',
            }}
          >
            <HStack>
              <Text>0</Text>
              <Text>=</Text>
              <Text fontWeight="bold">Never,</Text>
            </HStack>
            <HStack>
              <Text>1</Text>
              <Text>=</Text>
              <Text fontWeight="bold">Seldom,</Text>
            </HStack>
            <HStack>
              <Text>2</Text>
              <Text>=</Text>
              <Text fontWeight="bold">Sometimes,</Text>
            </HStack>
            <HStack>
              <Text>3</Text>
              <Text>=</Text>
              <Text fontWeight="bold">Often,</Text>
            </HStack>
            <HStack>
              <Text>4</Text>
              <Text>=</Text>
              <Text fontWeight="bold">Very Often</Text>
            </HStack>
          </Flex>
        </Center>
        <Box overflow="auto" h="100%">
          <Table variant="unstyled">
            <Tbody>
              <Th
                p={{
                  base: '0px 0px 0px 0px',
                  sm: '0px 0px 0px 0px',
                  md: '0px 0px 0px 0px',
                }}
                w="100%"
              >
                <Td px={{ base: '1rem', sm: '1.5rem' }}>
                  <Text
                    w={{
                      base: '210px',
                      sm: '220px',
                      md: '230px',
                      lg: '280px',
                      xl: '330px',
                    }}
                  ></Text>
                </Td>
                <Td
                  fontSize="18px"
                  pl={{ base: '1.25rem', sm: '1rem' }}
                  pr="0px"
                >
                  <Text w={{ base: '1.5rem', sm: '1.25rem' }} textAlign="end">
                    0
                  </Text>
                </Td>
                <Td fontSize="18px" pl="2.5rem" pr="0px">
                  <Text w="1.5rem" textAlign="end">
                    1
                  </Text>
                </Td>
                <Td fontSize="18px" pl="2.5rem">
                  <Text w="1.75rem" textAlign="end">
                    2
                  </Text>
                </Td>
                <Td fontSize="18px" pl="1rem" px="0rem">
                  <Text w="1.25rem" textAlign="end">
                    3
                  </Text>
                </Td>
                <Td fontSize="18px" pl="2.25rem" pr="0px">
                  <Text w="1.75rem" textAlign="end">
                    4
                  </Text>
                </Td>
              </Th>
              {question.map((e, i) => {
                return (
                  <Tr key={i}>
                    <Tbody p="0px">
                      <Td
                        w={{
                          base: '400px',
                          sm: '400px',
                          md: '380px',
                          lg: '380px',
                          xl: '380px',
                        }}
                        p="0px"
                      >
                        <Text
                          w={{
                            base: '280px',
                            sm: '280px',
                            md: '300px',
                            lg: '300px',
                            xl: '280px',
                            '2xl': '100%',
                          }}
                          textAlign="start"
                        >
                          {e.title}
                        </Text>
                      </Td>
                      <Td p="0px">
                        <Controller
                          name={e.name}
                          control={control}
                          defaultValue=""
                          render={props => (
                            <FormControl>
                              <CheckBoxSOAPP
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                defaultValue={formView?.[e.name]}
                              />
                            </FormControl>
                          )}
                        />
                      </Td>
                    </Tbody>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </Box>
        <Text pt="30px" pb="20px" fontSize="14px">
          Please include any additional information you wish about the above
          answers.Thank you.
        </Text>
        <Text pb="20px" fontSize="14px">
          ©2014 Inflexxion, Inc. Permission granted solely for use in published
          format by individual practitioners in clinical practice. No other uses
          or alterations are authorized or permitted by copyright holder.
          Permissions questions: PainEDU@inflexxion.com. The SOAPP®-R was
          developed with a grant from the National Institutes of Health and an
          educational grant from Endo Pharmaceuticals.
        </Text>
        <Center>
          <Image src="/Form_page10.png" />
        </Center>
      </Box>
      <Center pt="2rem">{`6/${totalNonWebEnabledPage}`}</Center>
    </CardContent>
  )
}

export { Soap }

function CheckBoxSOAPP({ onChange, defaultValue }) {
  return (
    <RadioGroup
      isDisabled
      onChange={onChange}
      defaultValue={defaultValue}
      pl={{
        base: '0px',
        sm: '0px',
        md: '0px',
        lg: '0px',
        xl: '20px',
        '2xl': '20px',
      }}
    >
      <Td>
        <Radio value="Zero" />
      </Td>
      <Td pl="50px">
        <Radio value="One" />
      </Td>
      <Td pl="50px">
        <Radio value="Two" />
      </Td>
      <Td pl="45px">
        <Radio value="Three" />
      </Td>
      <Td pl="45px">
        <Radio value="Four" />
      </Td>
    </RadioGroup>
  )
}
