import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  Center,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'
import moment from 'moment'
import { Controller, useForm } from 'react-hook-form'
import { doc, updateDoc } from 'firebase/firestore'
import { CardContent } from '../../../../../../Components/Card/card'
import { db } from '../../../../../../config/firebase'
import { totalWebEnablePages } from '../../config'
const _ = require('underscore')

function RecruitingPatientsFirstForm({ formView }) {
  const {
    handleSubmit,
    // reset,
    // register,
    // errors,
    control,
    setValue,
    // watch,
    clearErrors,
  } = useForm()

  const [isLoading, setIsLoading] = useState(false)

  const myRef = useRef(null)
  const toast = useToast()

  function onSubmit(data) {
    setIsLoading(true)
    if (data) {
      return updateDoc(doc(db, 'Form', formView?.id), {
        officeHeart: data.officeHeart,
        officeBlood: data.officeBlood,
        officeTemp: data.officeTemp,
        officePulse: data.officePulse,
        officeOx: data.officeOx,
        officeResp: data.officeResp,
      })
        .then(() => {
          setIsLoading(false)
          toast({
            position: 'bottom',
            title: 'Updated successfully.',
            description: '',
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
        })
        .catch(() => {
          setIsLoading(false)
        })
    }
  }

  useEffect(() => {
    if (formView) {
      const data = formView
      _.keys(data).map(e => {
        return setValue(e, data[e])
      })
    }
  }, [formView, setValue])

  return (
    <CardContent>
      <Box position="fixed" top="70px" right="10px" zIndex="overlay">
        <Button
          onClick={() =>
            window.scrollTo({
              top: myRef.current.offsetTop,
              behavior: 'smooth',
            })
          }
          colorScheme="blue"
          px="0.5rem"
          _focus={{ border: '0px solid transparent' }}
        >
          For Office
        </Button>
      </Box>
      <Flex
        pb="10px"
        direction={{ base: 'column', sm: 'column', md: 'row', lg: 'row' }}
      >
        <Box w="100%" pr={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}>
          <Box w="100%" pr={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}>
            <Controller
              name="namePatient"
              control={control}
              defaultValue=""
              render={props => (
                <FormControl>
                  <FormLabel>Name</FormLabel>
                  <Input
                    _readOnly={{
                      border: '1px solid #ccc',
                    }}
                    variant="no-effects"
                    readOnly
                    bg="#F9F9F9"
                    border="1px solid black"
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                  />
                </FormControl>
              )}
            />
          </Box>
        </Box>
        <Box
          w="100%"
          pt={{ base: '10px', md: '0px' }}
          pl={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}
        >
          <Controller
            name="dobPatient"
            control={control}
            defaultValue={moment(new Date()).format('yyyy-MM-DD')}
            render={props => (
              <FormControl>
                <FormLabel>DOB (mm/dd/yy)</FormLabel>
                <Input
                  _readOnly={{
                    border: '1px solid #ccc',
                  }}
                  variant="no-effects"
                  readOnly
                  bg="#F9F9F9"
                  border="1px solid black"
                  type="date"
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                />
              </FormControl>
            )}
          />
        </Box>
      </Flex>
      <Box>
        <Text pt="30px" pb="10px" fontWeight="bold">
          New Patient Intake Form, Page 1 of 3
        </Text>
        <Text pl={{ base: '0px', sm: '10px' }} fontSize="14px">
          The pain is located mainly at the: (check one)
        </Text>
        <Controller
          name="painLocated"
          control={control}
          defaultValue=""
          render={props => (
            <FormControl>
              <RadioGroup
                onChange={props.onChange}
                value={props.value}
                onBlur={props.onBlur}
                w="100%"
              >
                <Flex
                  w="100%"
                  flexWrap="wrap"
                  justifyContent="start"
                  pt="10px"
                  display={{ base: 'none', md: 'flex' }}
                >
                  <Radio isDisabled pr="0.5rem" value="neckLocated">
                    Neck
                  </Radio>
                  <Radio isDisabled pr="0.5rem" value="midbackLocated">
                    Mid Back
                  </Radio>
                  <Radio isDisabled pr="0.5rem" value="lowbackLocated">
                    Low Back
                  </Radio>
                  <Radio isDisabled pr="0.5rem" value="shoulderLocated">
                    Shoulder
                  </Radio>
                  <Radio isDisabled pr="0.5rem" value="hipLocated">
                    Hip
                  </Radio>
                  <Radio isDisabled pr="0.5rem" value="kneeLocated">
                    Knee
                  </Radio>
                  <HStack w={{ base: 'auto', sm: 'auto', md: '13rem' }}>
                    <Radio isDisabled value="otherPainlocated" />
                    <Controller
                      name="otherLocated"
                      control={control}
                      defaultValue=""
                      render={props => (
                        <FormControl>
                          <Input
                            w="auto"
                            _readOnly={{
                              border: '1px solid #ccc',
                            }}
                            variant="no-effects"
                            readOnly
                            bg="#F9F9F9"
                            border="1px solid black"
                            placeholder="Other"
                            value={props.value}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                          />
                        </FormControl>
                      )}
                    />
                  </HStack>
                </Flex>

                <Flex
                  w="100%"
                  flexWrap="wrap"
                  pt="10px"
                  display={{ base: 'flex', md: 'none' }}
                >
                  <VStack alignItems="flex-start">
                    <Radio isDisabled value="neckLocated">
                      Neck
                    </Radio>
                    <Radio isDisabled value="midbackLocated">
                      Mid Back
                    </Radio>
                    <Radio isDisabled value="lowbackLocated">
                      Low Back
                    </Radio>
                    <Radio isDisabled value="shoulderLocated">
                      Shoulder
                    </Radio>
                    <Radio isDisabled value="hipLocated">
                      Hip
                    </Radio>
                    <Radio isDisabled value="kneeLocated">
                      Knee
                    </Radio>
                    <HStack mt={{ base: '10px', '2xl': '0px' }}>
                      <Radio value="otherPainlocated" />
                      <Controller
                        name="otherLocated"
                        control={control}
                        defaultValue=""
                        render={props => (
                          <FormControl>
                            <Input
                              _readOnly={{
                                border: '1px solid #ccc',
                              }}
                              variant="no-effects"
                              readOnly
                              bg="#F9F9F9"
                              border="1px solid black"
                              placeholder="Other"
                              value={props.value}
                              onChange={props.onChange}
                              onBlur={props.onBlur}
                            />
                          </FormControl>
                        )}
                      />
                    </HStack>
                  </VStack>
                </Flex>
              </RadioGroup>
            </FormControl>
          )}
        />
      </Box>
      <Box>
        <Text pl="10px" pt="30px" pb="10px" fontSize="14px" fontWeight="bold">
          The pain feels like:{' '}
          <span style={{ py: '1', fontWeight: 'normal' }}>
            {`(check all that apply)`}
          </span>
        </Text>
        <Controller
          name="painFeels"
          control={control}
          render={props => (
            <FormControl>
              <CheckboxGroup
                onChange={e => {
                  setValue('painFeels', e)
                  clearErrors('painFeels')
                }}
                onBlur={props.onBlur}
                value={props.value}
                w="100%"
                spacing="0px"
              >
                <Grid
                  w="100%"
                  templateColumns={{
                    base: 'repeat(1,1fr)',
                    sm: 'repeat(1,1fr)',
                    md: 'repeat(1,1fr)',
                    lg: 'repeat(2, 1fr)',
                    xl: 'repeat(3, 1fr)',
                  }}
                  gap={4}
                >
                  <Checkbox isDisabled value="sharpFeels">
                    Sharp/stabbing
                  </Checkbox>
                  <Checkbox isDisabled value="dullFeels">
                    Dull/achy
                  </Checkbox>
                  <Checkbox isDisabled value="spasmsFeels">
                    Like muscle spasms
                  </Checkbox>
                  <Checkbox isDisabled value="burningFeels">
                    Burning
                  </Checkbox>
                  <Checkbox isDisabled value="areasNumbnessFeels">
                    Like numbness/tingling
                  </Checkbox>
                  <Checkbox isDisabled value="otherFeels">
                    Other
                  </Checkbox>
                </Grid>
              </CheckboxGroup>
            </FormControl>
          )}
        />
      </Box>

      <Box>
        <Text pl="10px" pt="30px" pb="10px" fontSize="14px" fontWeight="bold">
          Does the pain radiate or travel?{' '}
          <span style={{ py: '1', fontWeight: 'normal' }}>{`(check one)`}</span>
        </Text>
        <Controller
          name="painRadiate"
          control={control}
          defaultValue=""
          render={props => (
            <FormControl>
              <RadioGroup
                onChange={props.onChange}
                value={props.value}
                onBlur={props.onBlur}
                w="100%"
              >
                <Flex
                  w="100%"
                  direction={{
                    base: 'column',
                    sm: 'column',
                    md: 'column',
                    lg: 'row',
                  }}
                >
                  <Radio isDisabled pr="0.5rem" value="travelyes" mr="50px">
                    Yes
                  </Radio>
                  <Flex
                    direction={{
                      base: 'column',
                      sm: 'row',
                      md: 'row',
                      lg: 'row',
                    }}
                  >
                    <Radio
                      w="100%"
                      isDisabled
                      value="travelno"
                      mr={{ base: '0px', lg: '-5px' }}
                      pb={{ base: '10px', sm: '0px' }}
                    >
                      {`No (if yes, where?)`}
                    </Radio>
                    <Controller
                      name="otherTravel"
                      control={control}
                      defaultValue=""
                      render={props => (
                        <FormControl>
                          <Input
                            _readOnly={{
                              border: '1px solid #ccc',
                            }}
                            variant="no-effects"
                            readOnly
                            bg="#F9F9F9"
                            border="1px solid black"
                            value={props.value}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                          />
                        </FormControl>
                      )}
                    />
                  </Flex>
                </Flex>
              </RadioGroup>
            </FormControl>
          )}
        />
      </Box>
      <Box>
        <Text pl="10px" pt="30px" pb="10px" fontSize="14px" fontWeight="bold">
          Do you have areas of numbness?{' '}
          <span style={{ py: '1', fontWeight: 'normal' }}>{`(check one)`}</span>
        </Text>
        <Controller
          name="areasNumbness"
          control={control}
          defaultValue=""
          render={props => (
            <FormControl>
              <RadioGroup
                onChange={props.onChange}
                onblur={props.onBlur}
                value={props.value}
                w="100%"
              >
                <Flex
                  w="100%"
                  direction={{
                    base: 'column',
                    sm: 'column',
                    md: 'column',
                    lg: 'row',
                  }}
                >
                  <Radio isDisabled pr="0.5rem" value="numbnessyes" mr="50px">
                    Yes
                  </Radio>
                  <Flex
                    direction={{
                      base: 'column',
                      sm: 'row',
                      md: 'row',
                      lg: 'row',
                    }}
                  >
                    <Radio
                      isDisabled
                      w="100%"
                      value="numbnessno"
                      mr={{ base: '0px', lg: '-5px' }}
                      pb={{ base: '10px', sm: '0px' }}
                    >
                      No (if yes, where?)
                    </Radio>
                    <Controller
                      name="otherNumbness"
                      control={control}
                      defaultValue=""
                      render={props => (
                        <FormControl>
                          <Input
                            _readOnly={{
                              border: '1px solid #ccc',
                            }}
                            variant="no-effects"
                            readOnly
                            bg="#F9F9F9"
                            border="1px solid black"
                            value={props.value}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                          />
                        </FormControl>
                      )}
                    />
                  </Flex>
                </Flex>
              </RadioGroup>
            </FormControl>
          )}
        />
      </Box>
      <Box>
        <Text pl="10px" pt="30px" pb="10px" fontSize="14px" fontWeight="bold">
          Do you have areas of weakness{' '}
          <span style={{ py: '1', fontWeight: 'normal' }}>{`(check one)`}</span>
        </Text>
        <Controller
          name="areasWeakness"
          control={control}
          defaultValue=""
          render={props => (
            <FormControl>
              <RadioGroup
                onChange={props.onChange}
                value={props.value}
                onBlur={props.onBlur}
                w="100%"
              >
                <Flex
                  w="100%"
                  direction={{
                    base: 'column',
                    sm: 'column',
                    md: 'column',
                    lg: 'row',
                  }}
                >
                  <Radio isDisabled pr="0.5rem" value="weaknessyes" mr="50px">
                    Yes
                  </Radio>
                  <Flex
                    direction={{
                      base: 'column',
                      sm: 'row',
                      md: 'row',
                      lg: 'row',
                    }}
                  >
                    <Radio
                      isDisabled
                      w="100%"
                      value="weaknesssno"
                      mr={{ base: '0px', lg: '-5px' }}
                      pb={{ base: '10px', sm: '0px' }}
                    >
                      {`No (if yes, where?)`}
                    </Radio>
                    <Controller
                      name="otherWeakness"
                      control={control}
                      defaultValue=""
                      render={props => (
                        <FormControl>
                          <Input
                            _readOnly={{
                              border: '1px solid #ccc',
                            }}
                            variant="no-effects"
                            readOnly
                            bg="#F9F9F9"
                            border="1px solid black"
                            value={props.value}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                          />
                        </FormControl>
                      )}
                    />
                  </Flex>
                </Flex>
              </RadioGroup>
            </FormControl>
          )}
        />
      </Box>

      <Box>
        <Text pl="10px" pt="30px" pb="10px" fontSize="14px" fontWeight="bold">
          The pain started:{' '}
          <span style={{ py: '1', fontWeight: 'normal' }}>{`(check one)`}</span>
        </Text>
        <Controller
          name="painStarted"
          control={control}
          render={props => (
            <FormControl>
              <RadioGroup
                onChange={e => {
                  setValue('painStarted', e)
                  clearErrors('painStarted')
                }}
                onBlur={props.onBlur}
                value={props.value}
                w="100%"
                spacing="0px"
              >
                <Grid
                  w="100%"
                  templateColumns={{
                    base: 'repeat(1,1fr)',
                    sm: 'repeat(1,1fr)',
                    md: 'repeat(1,1fr)',
                    lg: 'repeat(2, 1fr)',
                    '2xl': 'repeat(3, 1fr)',
                  }}
                  gap={4}
                >
                  <Radio isDisabled pr="0.5rem" value="less6">
                    Less than 6 months ago
                  </Radio>
                  <Radio isDisabled pr="0.5rem" value="more6m">
                    More than 6 months ago
                  </Radio>
                  <Radio isDisabled pr="0.5rem" value="more1y">
                    More than 1 year ago
                  </Radio>
                  <Radio isDisabled pr="0.5rem" value="more5y">
                    More than 5 years ago
                  </Radio>
                  <Radio isDisabled pr="0.5rem" value="more10y">
                    More than 10 years ago
                  </Radio>
                </Grid>
              </RadioGroup>
            </FormControl>
          )}
        />
      </Box>
      <Box>
        <Text pl="10px" pt="30px" pb="10px" fontSize="14px" fontWeight="bold">
          Is this pain related to an accident?{' '}
          <span style={{ py: '1', fontWeight: 'normal' }}>{`(check one)`}</span>
        </Text>
        <Controller
          name="relatedAccident"
          control={control}
          defaultValue=""
          render={props => (
            <FormControl>
              <RadioGroup
                onChange={props.onChange}
                onBlur={props.onBlur}
                value={props.value}
                w="100%"
              >
                <HStack>
                  <Radio isDisabled pr="0.5rem" value="accidentyes" mr="50px">
                    Yes
                  </Radio>
                  <Radio isDisabled pr="0.5rem" value="accidentno">
                    No
                  </Radio>
                  <Input
                    name="otherAccident"
                    type="text"
                    defaultValue={formView.otherAccident || ''}
                    _readOnly={{
                      border: '1px solid #ccc',
                    }}
                    variant="no-effects"
                    readOnly
                    bg="#F9F9F9"
                    border="1px solid black"
                    w={{
                      base: '100%',
                      sm: '100%',
                      md: 'max-content',
                    }}
                  />
                </HStack>
              </RadioGroup>
            </FormControl>
          )}
        />
      </Box>
      <Box>
        <Text pl="10px" pt="30px" pb="10px" fontSize="14px" fontWeight="bold">
          Is this pain related to a work injury?{' '}
          <span style={{ py: '1', fontWeight: 'normal' }}>{`(check one)`}</span>
        </Text>
        <Controller
          name="relatedWork"
          control={control}
          defaultValue=""
          render={props => (
            <FormControl>
              <RadioGroup
                onChange={props.onChange}
                value={props.value}
                onBlur={props.onBlur}
                w="100%"
              >
                <HStack>
                  <Radio isDisabled pr="0.5rem" value="workyes" mr="50px">
                    Yes
                  </Radio>
                  <Radio isDisabled pr="0.5rem" value="workno">
                    No
                  </Radio>
                  <Input
                    name="otherWork"
                    type="text"
                    defaultValue={formView.otherWork || ''}
                    _readOnly={{
                      border: '1px solid #ccc',
                    }}
                    variant="no-effects"
                    readOnly
                    bg="#F9F9F9"
                    border="1px solid black"
                    w={{
                      base: '100%',
                      sm: '100%',
                      md: 'max-content',
                    }}
                  />
                </HStack>
              </RadioGroup>
            </FormControl>
          )}
        />
      </Box>
      <Box>
        <Text pl="10px" pt="30px" pb="10px" fontSize="14px" fontWeight="bold">
          Is this pain related to a surgery?{' '}
          <span style={{ py: '1', fontWeight: 'normal' }}>{`(check one)`}</span>
        </Text>
        <Controller
          name="relatedSurgery"
          control={control}
          defaultValue=""
          render={props => (
            <FormControl>
              <RadioGroup
                onChange={props.onChange}
                onBlur={props.onBlur}
                value={props.value}
                w="100%"
              >
                <HStack>
                  <Radio isDisabled pr="0.5rem" value="surgeryyes" mr="50px">
                    Yes
                  </Radio>
                  <Radio isDisabled pr="0.5rem" value="surgeryno">
                    No
                  </Radio>
                  <Input
                    name="otherSurgery"
                    type="text"
                    defaultValue={formView.otherSurgery || ''}
                    _readOnly={{
                      border: '1px solid #ccc',
                    }}
                    variant="no-effects"
                    readOnly
                    bg="#F9F9F9"
                    border="1px solid black"
                    w={{
                      base: '100%',
                      sm: '100%',
                      md: 'max-content',
                    }}
                  />
                </HStack>
              </RadioGroup>
            </FormControl>
          )}
        />
      </Box>
      <Box>
        <Text pl="10px" pt="30px" pb="10px" fontSize="14px" fontWeight="bold">
          The pain increases with:{' '}
          <span style={{ py: '1', fontWeight: 'normal' }}>
            {`(check all that apply)`}
          </span>
        </Text>
        <Controller
          name="painIncreases"
          control={control}
          render={props => (
            <FormControl>
              <CheckboxGroup
                onChange={e => {
                  setValue('painIncreases', e)
                  clearErrors('painIncreases')
                }}
                onBlur={props.onBlur}
                value={props.value}
                w="100%"
                spacing="0px"
              >
                <Grid
                  w="100%"
                  templateColumns={{
                    base: 'repeat(1,1fr)',
                    sm: 'repeat(1,1fr)',
                    md: 'repeat(2,1fr)',
                    lg: 'repeat(2, 1fr)',
                    '2xl': 'repeat(4, 1fr)',
                  }}
                  gap={4}
                >
                  <Checkbox isDisabled pr="0.5rem" value="insit">
                    Sitting{' '}
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="inwalk">
                    Walking
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="instand">
                    Standing
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="infor">
                    Bending forward
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="inback">
                    Bending backward
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="inup">
                    Looking up
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="indown">
                    Looking down
                  </Checkbox>
                </Grid>
              </CheckboxGroup>
            </FormControl>
          )}
        />
      </Box>
      <Box>
        <Text pl="10px" pt="30px" pb="10px" fontSize="14px" fontWeight="bold">
          The pain decreases with:{' '}
          <span style={{ py: '1', fontWeight: 'normal' }}>
            {`(check all that apply)`}
          </span>
        </Text>
        <Controller
          name="painDecreases"
          control={control}
          render={props => (
            <FormControl>
              <CheckboxGroup
                onChange={e => {
                  setValue('painDecreases', e)
                  clearErrors('painDecreases')
                }}
                onBlur={props.onBlur}
                value={props.value}
                w="100%"
                spacing="0px"
              >
                <Grid
                  w="100%"
                  templateColumns={{
                    base: 'repeat(1,1fr)',
                    sm: 'repeat(1,1fr)',
                    md: 'repeat(1,1fr)',
                    lg: 'repeat(1, 1fr)',
                    xl: 'repeat(2, 1fr)',
                    '2xl': 'repeat(4, 1fr)',
                  }}
                  gap={4}
                >
                  <Checkbox isDisabled pr="0.5rem" value="decrest">
                    Rest
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="decmedic">
                    Medication
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="decexer">
                    <Text w="100%">Exercise/physical therapy</Text>
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="decpro">
                    Procedures
                  </Checkbox>
                </Grid>
              </CheckboxGroup>
            </FormControl>
          )}
        />
      </Box>
      <Box>
        <Text pl="10px" pt="30px" pb="10px" fontSize="14px" fontWeight="bold">
          Medications:{' '}
          <span style={{ py: '1', fontWeight: 'normal' }}>{`(check one)`}</span>
        </Text>
        <Controller
          name="medicaTions"
          control={control}
          render={props => (
            <FormControl>
              <RadioGroup
                onChange={e => {
                  setValue('medicaTions', e)
                  clearErrors('medicaTions')
                }}
                onBlur={props.onBlur}
                value={props.value}
                w="100%"
                spacing="0px"
              >
                <Grid
                  w="100%"
                  templateColumns={{
                    base: 'repeat(1,1fr)',
                    sm: 'repeat(1,1fr)',
                    md: 'repeat(1,1fr)',
                    lg: 'repeat(1, 1fr)',
                    xl: 'repeat(2, 1fr)',
                    '2xl': 'repeat(3, 1fr)',
                  }}
                  gap={4}
                  justifyContent="space-between"
                >
                  <Radio isDisabled pr="0.5rem" value="medhelp">
                    Do help relieve my pain
                  </Radio>
                  <Radio isDisabled pr="0.5rem" value="mednot">
                    Do not help relieve my pain
                  </Radio>
                  <Radio isDisabled pr="0.5rem" value="medeffect">
                    Cause side effects
                  </Radio>
                </Grid>
              </RadioGroup>
            </FormControl>
          )}
        />
      </Box>
      <Box>
        <Text pl="10px" pt="30px" pb="10px" fontSize="14px" fontWeight="bold">
          Medications tried:{' '}
          <span style={{ py: '1', fontWeight: 'normal' }}>
            {`(check all that apply)`}
          </span>
        </Text>
        <Controller
          name="medicationsTried"
          control={control}
          render={props => (
            <FormControl>
              <CheckboxGroup
                onChange={e => {
                  setValue('medicationsTried', e)
                  clearErrors('medicationsTried')
                }}
                onBlur={props.onBlur}
                value={props.value}
                w="100%"
                spacing="0px"
              >
                <Grid
                  w="100%"
                  templateColumns={{
                    base: 'repeat(1,1fr)',
                    sm: 'repeat(2,1fr)',
                    md: 'repeat(2,1fr)',
                    lg: 'repeat(3, 1fr)',
                    xl: 'repeat(4,1fr)',
                  }}
                  gap={4}
                >
                  <Checkbox isDisabled pr="0.5rem" value="tIbuprofen">
                    Ibuprofen
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tTylenol">
                    Tylenol
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tNortriptyline">
                    Nortriptyline
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tAmitriptyline">
                    Amitriptyline
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tGabapentin ">
                    Gabapentin
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tLyrica">
                    Lyrica
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tFlexeril">
                    Flexeril
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tRobaxin">
                    Robaxin
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tZanaflex ">
                    Zanaflex
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tSkelaxin">
                    Skelaxin
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tHydrocodone">
                    Hydrocodone
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tOxycodone">
                    Oxycodone
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tOxycontin">
                    Oxycontin
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tMorphine">
                    Morphine
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tMS">
                    MS Contin
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tMethadone">
                    Methadone
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tDilaudid">
                    Dilaudid
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tSuboxone">
                    Suboxone
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tNucynta">
                    Nucynta
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tFentanyl">
                    Fentanyl
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tOpana">
                    Opana
                  </Checkbox>
                  <HStack w="auto">
                    <Checkbox isDisabled value="Other"></Checkbox>
                    <Controller
                      name="otherMedications"
                      control={control}
                      defaultValue=""
                      render={props => (
                        <FormControl>
                          <Input
                            _readOnly={{
                              border: '1px solid #ccc',
                            }}
                            variant="no-effects"
                            readOnly
                            bg="#F9F9F9"
                            border="1px solid black"
                            placeholder="Other"
                            value={props.value}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                          />
                        </FormControl>
                      )}
                    />
                  </HStack>
                </Grid>
              </CheckboxGroup>
            </FormControl>
          )}
        />
      </Box>
      <Box>
        <Text pl="10px" pt="30px" pb="10px" fontSize="14px" fontWeight="bold">
          Are you currently taking an anticoagulant?{' '}
          <span style={{ py: '1', fontWeight: 'normal' }}>{`(check one)`}</span>
        </Text>
        <Controller
          name="currentlyAnticoagulant"
          control={control}
          defaultValue=""
          render={props => (
            <FormControl>
              <RadioGroup
                onChange={props.onChange}
                onBlur={props.onBlur}
                value={props.value}
                w="100%"
              >
                <Radio isDisabled pr="0.5rem" value="antiyes" mr="50px">
                  Yes
                </Radio>
                <Radio isDisabled pr="0.5rem" value="antino">
                  No
                </Radio>
              </RadioGroup>
            </FormControl>
          )}
        />
      </Box>
      <Box>
        <Text pl="10px" pt="30px" pb="10px" fontSize="14px" fontWeight="bold">
          Indicates options therapies tried:{' '}
          <span style={{ py: '1', fontWeight: 'normal' }}>
            {`(Check all that apply)`}
          </span>
        </Text>
        <Controller
          name="alternateFirst"
          control={control}
          render={props => (
            <FormControl>
              <CheckboxGroup
                onChange={e => {
                  setValue('alternateFirst', e)
                  clearErrors('alternateFirst')
                }}
                onBlur={props.onBlur}
                value={props.value}
                w="100%"
                spacing="0px"
              >
                <Grid
                  w="100%"
                  templateColumns={{
                    base: 'repeat(1,1fr)',
                    sm: 'repeat(1,1fr)',
                    md: 'repeat(1,1fr)',
                    lg: 'repeat(2, 1fr)',
                    '2xl': 'repeat(4, 1fr)',
                  }}
                  gap={4}
                >
                  <Checkbox isDisabled pr="0.5rem" value="altNone">
                    None
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="altPhysical">
                    Physical therapy
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="altMassage">
                    Massage
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="altAcupuncture">
                    Acupuncture
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="altChiropractic">
                    Chiropractic treatments
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="altTens">
                    TENS unit
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="altPain">
                    Pain psychology/therapy
                  </Checkbox>
                </Grid>
              </CheckboxGroup>
            </FormControl>
          )}
        />
      </Box>
      <Box>
        <Text pl="10px" pt="30px" pb="10px" fontSize="14px" fontWeight="bold">
          Procedures previously tried related to your current or past pain:{' '}
          <span style={{ py: '1', fontWeight: 'normal' }}>
            {`(Check all that apply)`}
          </span>
        </Text>
        <Controller
          name="alternateSecond"
          control={control}
          render={props => (
            <FormControl>
              <CheckboxGroup
                onChange={e => {
                  setValue('alternateSecond', e)
                  clearErrors('alternateSecond')
                }}
                value={props.value}
                onBlur={props.onBlur}
                w="100%"
                spacing="0px"
              >
                <Grid
                  w="100%"
                  templateColumns={{
                    base: 'repeat(1,1fr)',
                    sm: 'repeat(1,1fr)',
                    md: 'repeat(1,1fr)',
                    lg: 'repeat(2, 1fr)',
                    '2xl': 'repeat(4, 1fr)',
                  }}
                  gap={4}
                >
                  <Checkbox isDisabled pr="0.5rem" value="altNone2">
                    None
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="altEpidural">
                    Epidural steroid injection
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="altFacet">
                    Facet joint injection
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="altRadio">
                    Radiofrequency ablation
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="altTrigger">
                    Trigger point injections
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="altJoint">
                    Joint injection
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="altSpinal">
                    Spinal cord stimulator
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="altBotox">
                    Botox injection
                  </Checkbox>
                </Grid>
              </CheckboxGroup>
            </FormControl>
          )}
        />
      </Box>
      <Box ref={myRef}></Box>
      <Box
        bgImage={`url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23B7DDE8FF' stroke-width='3' stroke-dasharray='19%2c 16' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e")`}
        p="15px"
        borderRadius="16px"
        mt="30px"
      >
        <Text pl="10px" pb="20px" fontSize="14px" fontWeight="bold">
          Office Use Only:
        </Text>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex
            pt="10px"
            pb="10px"
            direction={{ base: 'column', sm: 'column', md: 'row', lg: 'row' }}
          >
            <Box
              w="100%"
              pr={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}
            >
              <Controller
                name="officeHeart"
                control={control}
                defaultValue=""
                render={props => (
                  <FormControl>
                    <FormLabel>Heart Rate</FormLabel>
                    <Input
                      // _readOnly={{
                      //   border: '1px solid #ccc',
                      // }}
                      // variant="no-effects"
                      // readOnly
                      // bg="#F9F9F9"
                      // border="1px solid black"
                      value={props.value}
                      onChange={props.onChange}
                      onBlur={props.onBlur}
                    />
                  </FormControl>
                )}
              />
            </Box>
            <Box
              w="100%"
              pt={{ base: '10px', md: '0px' }}
              pl={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}
            >
              <Controller
                name="officeBlood"
                control={control}
                defaultValue=""
                render={props => (
                  <FormControl>
                    <FormLabel>Blood Pressure</FormLabel>
                    <Input
                      // _readOnly={{
                      //   border: '1px solid #ccc',
                      // }}
                      // variant="no-effects"
                      // readOnly
                      // bg="#F9F9F9"
                      // border="1px solid black"
                      value={props.value}
                      onChange={props.onChange}
                      onBlur={props.onBlur}
                    />
                  </FormControl>
                )}
              />
            </Box>
          </Flex>

          <Flex
            pt="10px"
            pb="10px"
            direction={{ base: 'column', sm: 'column', md: 'row', lg: 'row' }}
          >
            <Box
              w="100%"
              pr={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}
            >
              <Controller
                name="officeTemp"
                control={control}
                defaultValue=""
                render={props => (
                  <FormControl>
                    <FormLabel>Temp (F)</FormLabel>
                    <Input
                      // _readOnly={{
                      //   border: '1px solid #ccc',
                      // }}
                      // variant="no-effects"
                      // readOnly
                      // bg="#F9F9F9"
                      // border="1px solid black"
                      value={props.value}
                      onChange={props.onChange}
                      onBlur={props.onBlur}
                    />
                  </FormControl>
                )}
              />
            </Box>
            <Box
              w="100%"
              pt={{ base: '10px', md: '0px' }}
              pl={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}
            >
              <Controller
                name="officePulse"
                control={control}
                defaultValue=""
                render={props => (
                  <FormControl>
                    <FormLabel>Pulse</FormLabel>
                    <Input
                      // _readOnly={{
                      //   border: '1px solid #ccc',
                      // }}
                      // variant="no-effects"
                      // readOnly
                      // bg="#F9F9F9"
                      // border="1px solid black"
                      value={props.value}
                      onChange={props.onChange}
                      onBlur={props.onBlur}
                    />
                  </FormControl>
                )}
              />
            </Box>
          </Flex>

          <Flex
            pt="10px"
            pb="10px"
            direction={{ base: 'column', sm: 'column', md: 'row', lg: 'row' }}
          >
            <Box
              w="100%"
              pr={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}
            >
              <Controller
                name="officeOx"
                control={control}
                defaultValue=""
                render={props => (
                  <FormControl>
                    <FormLabel>Ox (%)</FormLabel>
                    <Input
                      // _readOnly={{
                      //   border: '1px solid #ccc',
                      // }}
                      // variant="no-effects"
                      // readOnly
                      // bg="#F9F9F9"
                      // border="1px solid black"
                      value={props.value}
                      onChange={props.onChange}
                      onBlur={props.onBlur}
                    />
                  </FormControl>
                )}
              />
            </Box>
            <Box
              w="100%"
              pt={{ base: '10px', md: '0px' }}
              pl={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}
            >
              <Controller
                name="officeResp"
                control={control}
                defaultValue=""
                render={props => (
                  <FormControl>
                    <FormLabel>Resp</FormLabel>
                    <Input
                      // _readOnly={{
                      //   border: '1px solid #ccc',
                      // }}
                      // variant="no-effects"
                      // readOnly
                      // bg="#F9F9F9"
                      // border="1px solid black"
                      value={props.value}
                      onChange={props.onChange}
                      onBlur={props.onBlur}
                    />
                  </FormControl>
                )}
              />
            </Box>
          </Flex>
          <Flex w="100%" justifyContent="flex-end">
            <Button
              colorScheme="blue"
              type="submit"
              isLoading={isLoading}
              loadingText="loading..."
            >
              submit
            </Button>
          </Flex>
        </form>
      </Box>
      <Center pt="2rem">{`7/${totalWebEnablePages}`}</Center>
    </CardContent>
  )
}
export { RecruitingPatientsFirstForm }
