import { Text, View } from '@react-pdf/renderer'
import React from 'react'

function SecondNoticePrivacyPractices({ styles }) {
  return (
    <View
      style={{
        width: '100%',
        gap: '12px',
      }}
    >
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          <Text style={styles.title}>
            Release of Information to Family/ Friends.
          </Text>
          {` `}
          <Text>
            Our practice may release your PHI to a friend or family member who
            is involved in your care or who assists in taking care of you. For
            example, a guardian may ask that a neighbor take a parent or child
            to the physician's office for treatment. This neighbor may have
            access to this patient's medical information. We may also release
            information to friends or family members involved in your payment
            for health services we provide.
          </Text>
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          <Text style={styles.title}>Disclosures Required by Law.</Text>
          {` `}
          <Text>
            Our practice will use and disclose your PHI when we are required to
            do so by federal, state or local law.
          </Text>
        </Text>
      </View>
      <View style={{ paddingTop: 4, textAlign: 'center', fontSize: 10 }}>
        <Text style={styles.title}>
          USE AND DISCLOSURE OF YOUR PHI IN CERTAIN SPECIAL CIRCUMSTANCES
        </Text>
        <Text style={styles.title}>WITHOUT APPROVAL</Text>
      </View>
      <View style={{ marginTop: '-4px' }}>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          <Text style={styles.title}>Public Health Risks.</Text>
          {` `}
          <Text>
            Our practices may disclose your PHI to public health authorities
            that are authorized by law to collect information for the purpose
            of:
          </Text>
        </Text>
        <View style={{ gap: '2px', paddingLeft: 20, marginTop: 4 }}>
          <View style={styles.gapText}>
            <Text style={styles.fontDot}>•</Text>
            <Text>
              Maintaining vital records such as births and deaths Reporting
              child abuse or neglect
            </Text>
          </View>
          <View style={styles.gapText}>
            <Text style={styles.fontDot}>•</Text>
            <Text>Preventing or controlling disease, injury or disability</Text>
          </View>
          <View style={styles.gapText}>
            <Text style={styles.fontDot}>•</Text>
            <Text>
              Notifying a person regarding potential exposure to a communicable
              disease
            </Text>
          </View>
          <View style={styles.gapText}>
            <Text style={styles.fontDot}>•</Text>
            <Text>
              Reporting reactions to drugs or problems with products or devices
            </Text>
          </View>
          <View style={styles.gapText}>
            <Text style={styles.fontDot}>•</Text>
            <Text>
              Notifying individuals if a product or device has been recalled
            </Text>
          </View>
          <View style={styles.gapText}>
            <Text style={styles.fontDot}>•</Text>
            <Text style={{ lineHeight: 1.25 }}>
              Notifying appropriate government agencies and authorities
              regarding domestic abuse, potential abuse or neglect of an adult
              patient; however, we will only disclose this information if the
              patient agrees or we are required by law to disclose this
              information
            </Text>
          </View>
          <View style={styles.gapText}>
            <Text style={styles.fontDot}>•</Text>
            <Text style={{ lineHeight: 1.25 }}>
              Notifying your employer under limited circumstances related
              primarily to workplace injury or illness or medical surveillance
            </Text>
          </View>
        </View>
      </View>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          <Text style={styles.title}>Health Oversight Activities.</Text>
          {` `}
          <Text>
            Our practice may disclose your PHI to a health oversight agency for
            activities authorized by law. Oversight activities can include
            investigations, inspections, audits, surveys, licensure, and
            disciplinary actions; civil, administrative and criminal procedures
            or actions; or other activities necessary for the government to
            monitor government programs, compliance with civil rights laws and
            health care system in general. We may use your information to report
            diseases to the health department.
          </Text>
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          <Text style={styles.title}>Lawsuits and Similar Proceedings.</Text>
          {` `}
          <Text>
            Our practice may disclose your PHI in response to a court or
            administrative order if you are involved in a lawsuit or similar
            proceeding. We also may disclose your PHI in response to a discovery
            request, subpoena, or other lawful process by another party involved
            in the dispute after we inform you of the request to obtain an order
            protecting the information the party has requested.
          </Text>
        </Text>
      </View>
      <View style={{ marginTop: '4px' }}>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          <Text style={styles.title}>
            Law Enforcement. We may release PHI if asked to do so by a law
            enforcement official:
          </Text>
        </Text>
        <View style={{ gap: '2px', paddingLeft: 20, marginTop: 8 }}>
          <View style={styles.gapText}>
            <Text style={styles.fontDot}>•</Text>
            <Text>Regarding a crime victim in certain situations</Text>
          </View>
          <View style={styles.gapText}>
            <Text style={styles.fontDot}>•</Text>
            <Text>Concerning a death possibly due to criminal conduct</Text>
          </View>
          <View style={styles.gapText}>
            <Text style={styles.fontDot}>•</Text>
            <Text>Regarding criminal conduct at our offices</Text>
          </View>
          <View style={styles.gapText}>
            <Text style={styles.fontDot}>•</Text>
            <Text>
              In response to a warrant, summons, court, order, subpoena or
              similar legal process
            </Text>
          </View>
          <View style={styles.gapText}>
            <Text style={styles.fontDot}>•</Text>
            <Text>
              To identify/locate a suspect, material witness, fugitive or a
              missing person
            </Text>
          </View>
          <View style={styles.gapText}>
            <Text style={styles.fontDot}>•</Text>
            <Text style={{ lineHeight: 1.25 }}>
              In an emergency to report a crime (including the location or
              victim(s) of the crime or the description, identity or location of
              the perpetrator)
            </Text>
          </View>
        </View>
      </View>
    </View>
  )
}

export default SecondNoticePrivacyPractices
