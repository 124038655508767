import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../../config/firebase'
import {
    Box,
    Center,
    Container,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    Button,
    FormErrorMessage,
    Fade,
    useToast,
    Image,
    //Divider
} from '@chakra-ui/react'

import Logo from '../../Logo.png'

function Login() {
    const toast = useToast()
    const history = useHistory()
    const { handleSubmit, register, formState, errors } = useForm()
    const [showPass, setShowPass] = useState(false)
    const [disableBtn, setDisableBtn] = useState(false)

    const handleClick = () => setShowPass(!showPass)

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (authUser) => {
            if (authUser) {
                history.push('/dashboard')
            }
        })
        return () => {
            unsubscribe()
        }
    }, [history])

    function onSubmit(values) {
        setDisableBtn(true)
        signInWithEmailAndPassword(auth, values.email, values.password)
            .catch((error) => {
                if (error) {
                    let errorMessage = error.message
                    switch (error.code) {
                        case 'auth/user-not-found':
                            toast({
                                title: 'User not found.',
                                position: 'top',
                                status: 'info',
                                isClosable: true,
                                duration: 2000
                            })
                            break
                        case 'auth/wrong-password':
                            toast({
                                title: 'Password incorrect.',
                                position: 'top',
                                status: 'info',
                                isClosable: true,
                                duration: 2000
                            })
                            break
                        case 'auth/user-disabled':
                            toast({
                                title: 'User suspended.',
                                description: 'Please contact administrator',
                                position: 'top',
                                status: 'warning',
                                isClosable: true,
                                duration: 4000
                            })
                            break
                        default:
                            toast({
                                title: errorMessage,
                                position: 'top',
                                status: 'info',
                                isClosable: true,
                                duration: 2000
                            })
                    }
                    setDisableBtn(false)
                }
            })
    }

    return (
        <Container minH="100vh" maxW="xl" centerContent>
            <Fade in={true}>
                <Box mt="100" w={[370, 400, 500]} p="8" borderWidth={1} borderRadius={8} boxShadow="lg">
                    <Center><Image src={Logo} htmlWidth="150" /></Center>
                    <Center fontSize="14" paddingTop="5" paddingBottom="5">Please enter your email / password to sign-in</Center>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormControl id="email" isInvalid={errors.email}>
                            <FormLabel>EMAIL</FormLabel>
                            <Input name="email" type="email" ref={register({
                                required: "Please input your email"
                            })} />
                            <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl id="password" isInvalid={errors.password}>
                            <FormLabel>PASSWORD</FormLabel>
                            <InputGroup>
                                <Input name="password" type={showPass ? "text" : "password"} ref={register({
                                    required: "Please input your password"
                                })} />
                                <InputRightElement width="4.5rem">
                                    <Button h="1.75rem" size="sm" onClick={handleClick}>
                                        {showPass ? "Hide" : "Show"}
                                    </Button>
                                </InputRightElement>
                            </InputGroup>

                            <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
                        </FormControl>
                        <Button
                            mt={4}
                            w="100%"
                            type="submit"
                            isLoading={formState.isSubmitting}
                            isDisabled={disableBtn}
                        >
                            LOGIN
                        </Button>
                    </form>
                    <Center paddingTop="5">
                        {/* <Button variant="link" size="xs">
                            Forgot Password?
                        </Button> */}
                        {/* <Center height="20px" ml="10px" mr="10px">
                            <Divider orientation="vertical" />
                        </Center>
                        <Button variant="link" size="xs">
                            Don't Have Account
                        </Button> */}
                    </Center>
                </Box>
            </Fade>
        </Container>
    )
}

export default Login