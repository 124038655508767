import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import {
  Container,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  useToast,
  HStack,
  Center,
  Radio,
  RadioGroup,
  Flex,
  Spacer,
  Stack,
  VStack,
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { FaCloudDownloadAlt } from 'react-icons/fa'
import { addDoc, collection } from 'firebase/firestore'
import { db } from '../../config/firebase'
import moment from 'moment'

function EmployeeQuiz({ user }) {
  const { handleSubmit, reset, register, errors, control } = useForm()
  const toast = useToast()
  const [isDisabled, setIsDisabled] = useState(false)
  function submitData(value) {
    setIsDisabled(true)
    // value.date = value.date.format('YYYY-MM-DD')
    addDoc(collection(db, 'EmployeeForm'), {
      ...value,
      name: user.fullName,
      type: 'Employee Quiz',
      createdAt: new Date(),
      notification: true,
      status: 'Pending',
    }).then(() => {
      toast({
        title: 'Submit Successfully',
        description: 'Form has been submmited.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      setIsDisabled(false)
      reset()
    })
  }
  return (
    <Container maxW="100%" mt="5">
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
        bg="gray.100"
        p="1.5"
        borderRadius="0.3em"
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/dashboard">
            Dashbaord
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/employee-quiz">
            Employee Quiz
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Box mt="30" mb="100" width={['100']}>
        <Flex mt="10" mb="10">
          <Box pt="2" fontSize="18" fontWeight="bold">
            Employee Quiz
          </Box>
          <Spacer />
          <Button
            onClick={() => {
              window.open('/doc/osha-orientation-quiz.pdf', 'blank')
            }}
            leftIcon={<FaCloudDownloadAlt />}
            size="sm"
          >
            PDF
          </Button>
        </Flex>
        <form onSubmit={handleSubmit(submitData)}>
          <HStack
            mb="5"
            width={['100%', '100%', '100%', '80%']}
            alignItems="flex-start"
          >
            <FormControl id="name" isInvalid={errors.name}>
              <FormLabel>NAME</FormLabel>
              <Input
                name="name"
                defaultValue={user.fullName}
                ref={register({ required: 'Name is required' })}
                isReadOnly={true}
              />
              <FormErrorMessage>
                {errors.fname && errors.fname.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl id="date" isInvalid={errors.date}>
              <FormLabel>Date</FormLabel>
              <Controller
                render={props => (
                  <Input
                    value={props.value}
                    onChange={props.onChange}
                    isReadOnly
                    textAlign={'center'}
                  />
                )}
                name="date"
                control={control}
                defaultValue={moment().format('YYYY-MM-DD')}
                rules={{ required: 'Date is required.' }}
              />
              <FormErrorMessage>
                {errors.date && errors.date.message}
              </FormErrorMessage>
            </FormControl>
          </HStack>
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '80%']}
            isInvalid={errors.quizOne}
          >
            <FormLabel>
              1. How frequently are all Oregon businesses required to hold
              either Safety Committee (more than 10 employees) or all-staff
              safety meetings?
            </FormLabel>
            <Input
              name="quizOne"
              ref={register({ required: 'Answer is required, ' })}
            />
            <FormErrorMessage>
              {errors.quizOne && errors.quizOne.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            mb="5"
            isInvalid={errors.quizTwo}
            width={['100%', '100%', '100%', '80%']}
          >
            <FormLabel>
              2. If our practice has been free of accidents or bloodborne
              pathogen exposures for at least 24 months, we are not required to
              hold any safety meetings at all.
            </FormLabel>
            <Controller
              name="quizTwo"
              control={control}
              defaultValue={null}
              rules={{ required: 'Answer is required, ' }}
              render={props => (
                <RadioGroup
                  name={props.name}
                  onChange={props.onChange}
                  defaultValue={props.value}
                >
                  <VStack alignItems={'start'}>
                    <Radio value="true">True</Radio>
                    <Radio value="false">False</Radio>
                  </VStack>
                </RadioGroup>
              )}
            />
            <FormErrorMessage>
              {errors.quizTwo && errors.quizTwo.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            mb="5"
            isInvalid={errors.quizThree}
            width={['100%', '100%', '100%', '80%']}
          >
            <FormLabel>
              3. OR-OSHA requires that every business keep a written record of
              safety topics covered and staff attendance at safety meetings.
            </FormLabel>
            <Controller
              name="quizThree"
              control={control}
              defaultValue={null}
              rules={{ required: 'Answer is required, ' }}
              render={props => (
                <RadioGroup
                  name={props.name}
                  onChange={props.onChange}
                  defaultValue={props.value}
                >
                  <VStack alignItems={'start'}>
                    <Radio value="true">True</Radio>
                    <Radio value="false">False</Radio>
                  </VStack>
                </RadioGroup>
              )}
            />
            <FormErrorMessage>
              {errors.quizThree && errors.quizThree.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            mb="5"
            isInvalid={errors.quizFour}
            width={['100%', '100%', '100%', '80%']}
          >
            <FormLabel>4. What does OPIM stand for?</FormLabel>
            <Controller
              name="quizFour"
              control={control}
              defaultValue={null}
              rules={{ required: 'Answer is required, ' }}
              render={props => (
                <RadioGroup
                  name={props.name}
                  onChange={props.onChange}
                  defaultValue={props.value}
                >
                  <Stack>
                    <Radio value="answerA">{`a) Our Physician Is Masterful`}</Radio>
                    <Radio value="answerB">
                      {`b) Other Potentially Infectious Material`}
                    </Radio>
                    <Radio value="answerC">
                      {`c) Outside Parameters In Medica/Dental`}
                    </Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
            <FormErrorMessage>
              {errors.quizFour && errors.quizFour.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            mb="5"
            isInvalid={errors.quizFive}
            width={['100%', '100%', '100%', '80%']}
          >
            <FormLabel>5. What does OPIM stand for?</FormLabel>
            <Controller
              name="quizFive"
              control={control}
              defaultValue={null}
              rules={{ required: 'Answer is required, ' }}
              render={props => (
                <RadioGroup
                  name={props.name}
                  onChange={props.onChange}
                  defaultValue={props.value}
                >
                  <Stack>
                    <Radio value="answerA">
                      {`a) A droplet of a patient’s blood lands in your eye`}
                    </Radio>
                    <Radio value="answerB">
                      {`b) Your gloved hand touches saliva in a patient’s mouth`}
                    </Radio>
                    <Radio value="answerC">
                      {`c) You stick yourself with a sterile needle`}
                    </Radio>
                    <Radio value="allOfAbove"> All of the above</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
            <FormErrorMessage>
              {errors.quizFive && errors.quizFive.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            mb="5"
            isInvalid={errors.quizSix}
            width={['100%', '100%', '100%', '80%']}
          >
            <FormLabel>
              6. Your practice is required to ask the source individual to be
              tested for both Hepatitis B & HIV.
            </FormLabel>
            <Controller
              name="quizSix"
              control={control}
              defaultValue={null}
              rules={{ required: 'Answer is required, ' }}
              render={props => (
                <RadioGroup
                  name={props.name}
                  onChange={props.onChange}
                  defaultValue={props.value}
                >
                  <VStack alignItems={'start'}>
                    <Radio value="true">True</Radio>
                    <Radio value="false">False</Radio>
                  </VStack>
                </RadioGroup>
              )}
            />
            <FormErrorMessage>
              {errors.quizSix && errors.quizSix.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            mb="5"
            isInvalid={errors.quizSeven}
            width={['100%', '100%', '100%', '80%']}
          >
            <FormLabel>
              7. Hepatitis B virus (HBV) Immunity must be effectively maintained
              by the employee.
            </FormLabel>
            <Controller
              name="quizSeven"
              control={control}
              defaultValue={null}
              rules={{ required: 'Answer is required, ' }}
              render={props => (
                <RadioGroup
                  name={props.name}
                  onChange={props.onChange}
                  defaultValue={props.value}
                >
                  <VStack alignItems={'start'}>
                    <Radio value="true">True</Radio>
                    <Radio value="false">False</Radio>
                  </VStack>
                </RadioGroup>
              )}
            />
            <FormErrorMessage>
              {errors.quizSeven && errors.quizSeven.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '80%']}
            isInvalid={errors.quizEigth}
          >
            <FormLabel>
              8. Where would you find your practice’s Bloodborne Pathogen
              Exposure Control Plan?
            </FormLabel>
            <Input
              name="quizEigth"
              ref={register({ required: 'Answer is required, ' })}
              placeholder="Select your answer"
            />
            <FormErrorMessage>
              {errors.quizEigth && errors.quizEigth.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            mb="5"
            isInvalid={errors.quizNine}
            width={['100%', '100%', '100%', '80%']}
          >
            <FormLabel>
              9. OSHA requires every healthcare facility with employees using
              sharps to have an on-going program to identify and evaluate safer
              sharps devices, as well as annually document its findings.
            </FormLabel>
            <Controller
              name="quizNine"
              control={control}
              defaultValue={null}
              rules={{ required: 'Answer is required, ' }}
              render={props => (
                <RadioGroup
                  name={props.name}
                  onChange={props.onChange}
                  defaultValue={props.value}
                >
                  <VStack alignItems={'start'}>
                    <Radio value="true">True</Radio>
                    <Radio value="false">False</Radio>
                  </VStack>
                </RadioGroup>
              )}
            />
            <FormErrorMessage>
              {errors.quizNine && errors.quizNine.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '80%']}
            isInvalid={errors.quizTen}
          >
            <FormLabel>
              10. If your interaction with a patient involves a “reasonable
              expectation” of exposure to a bloodborne pathogen, what personal
              protective equipment (PPE) does your practice require you to wear?
            </FormLabel>
            <Input
              name="quizTen"
              ref={register({ required: 'Answer is required, ' })}
              placeholder="Select your answer"
            />
            <FormErrorMessage>
              {errors.quizTen && errors.quizTen.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '80%']}
            id="forPayPeriodOff"
            isInvalid={errors.quizEleven}
          >
            <FormLabel>
              11. Name an “engineering control” used in your practice to protect
              you.
            </FormLabel>
            <Input name="quizEleven" ref={register({ required: true })} />
            <FormErrorMessage>
              {errors.quizEleven && errors.quizEleven?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '80%']}
            id="forPayPeriodOff"
            isInvalid={errors.quizTwelve}
          >
            <FormLabel>
              12. Name a “work practice control” used in your practice to
              protect you.
            </FormLabel>
            <Input name="quizTwelve" ref={register({ required: true })} />
            <FormErrorMessage>
              {errors.quizTwelve && errors.quizTwelve?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '80%']}
            id="forPayPeriodOff"
            isInvalid={errors.quizThirteen}
          >
            <FormLabel>
              13. When working with hazardous chemicals in your practice, OSHA
              expects you to know what the SDS says regarding personal
              protective equipment for those chemicals.
            </FormLabel>
            <Controller
              name="quizThirteen"
              defaultValue={null}
              control={control}
              rules={{ required: true }}
              render={props => (
                <RadioGroup
                  onChange={props.onChange}
                  value={props.value}
                  onBlur={props.onBlur}
                >
                  <VStack alignItems={'start'}>
                    <Radio value="true">True</Radio>
                    <Radio value="false">False</Radio>
                  </VStack>
                </RadioGroup>
              )}
            />
            <FormErrorMessage>
              {errors.quizThirteen && errors.quizThirteen?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '80%']}
            id="forPayPeriodOff"
            isInvalid={errors.quizFourteen}
          >
            <FormLabel>
              14. Where is the chemical spill kit locate in you practice?
            </FormLabel>
            <Input name="quizFourteen" ref={register({ required: true })} />
            <FormErrorMessage>
              {errors.quizFourteen && errors.quizFourteen?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '80%']}
            id="forPayPeriodOff"
            isInvalid={errors.quizFifteen}
          >
            <FormLabel>
              15. A “secondary container” for hazardous materials is less
              important than a primary container and dose no need a label.
            </FormLabel>
            <Controller
              name="quizFifteen"
              defaultValue={null}
              control={control}
              rules={{ required: true }}
              render={props => (
                <RadioGroup
                  onChange={props.onChange}
                  value={props.value}
                  onBlur={props.onBlur}
                >
                  <Radio value="true">True</Radio>
                  <Spacer />
                  <Radio value="false">False</Radio>
                </RadioGroup>
              )}
            />
            <FormErrorMessage>
              {errors.quizFifteen && errors.quizFifteen?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '80%']}
            id="forPayPeriodOff"
            isInvalid={errors.quizSixteen}
          ></FormControl>
          <FormControl isInvalid={errors.quizSixteen}>
            <FormLabel>
              16. How often do you need to check and flush out your practice’s
              eyewash station?
            </FormLabel>
            <Controller
              name="quizSixteen"
              control={control}
              defaultValue={null}
              rules={{ required: true }}
              render={props => (
                <RadioGroup
                  name={props.name}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                >
                  <Stack>
                    <Radio value="whenever">Whenever we use it </Radio>
                    <Radio value="weekly">Weekly</Radio>
                    <Radio value="monthly">Monthly</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
          <FormControl isInvalid={errors.quizSeventeen}>
            <FormLabel>17. Tuberculosis Flu Viruses RequireThatWe</FormLabel>
            <Controller
              name="quizSeventeen"
              control={control}
              defaultValue={null}
              rules={{ required: true }}
              render={props => (
                <RadioGroup
                  name={props.name}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                >
                  <Stack>
                    <Radio value="answerA">
                      {`a) Follow out practice’s disinfection schedule for
                      surfaces`}
                    </Radio>
                    <Radio value="answerB">
                      {`b) Protect ourselves from airborne infection`}
                    </Radio>
                    <Radio value="answerC">
                      {`c) Take extra precautions if a patient tells you she is
                      infected`}
                    </Radio>
                    <Radio value="allOfAbove"> All of the above </Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '80%']}
            id="forPayPeriodOff"
            isInvalid={errors.quizEigthteen}
          >
            <FormLabel>
              18. What is your practice policy on verbal or physical harassment
              in the workplace
            </FormLabel>
            <Input name="quizEigthteen" ref={register({ required: true })} />
            <FormErrorMessage>
              {errors.quizEigthteen && errors.quizEigthteen?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.quuizNineteen}>
            <FormLabel>
              19. An OSHA compliance officer can interview any employee in the
              practice and ask about
            </FormLabel>
            <Controller
              name="quuizNineteen"
              control={control}
              defaultValue={null}
              rules={{ required: true }}
              render={props => (
                <RadioGroup
                  name={props.name}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                >
                  <Stack>
                    <Radio value="answerA">
                      {`a) Training they have received in practicing Universal
                      Precautions`}
                    </Radio>
                    <Radio value="answerB">
                      {`b) What duties they would perform in evacuating the
                      building`}
                    </Radio>
                    <Radio value="answerC">
                      {`c) When they attended their last safety meeting and what
                      was discussed`}
                    </Radio>
                    <Radio value="allOfAbove"> All of the above </Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '80%']}
            id="forPayPeriodOff"
            isInvalid={errors.quizTwenty}
          >
            <FormLabel>
              20. When using the fire extinguisher, where do you aim it and how
              far back should you stand?
            </FormLabel>
            <Input name="quizTwenty" ref={register({ required: true })} />
            <FormErrorMessage>
              {errors.quizTwenty && errors.quizTwenty?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '80%']}
            id="forPayPeriodOff"
            isInvalid={errors.quizTwentyone}
          >
            <FormLabel>
              21. Where is the fire extinguisher nearest your work area is
              located?
            </FormLabel>
            <Input name="quizTwentyone" ref={register({ required: true })} />
            <FormErrorMessage>
              {errors.quizTwentyone && errors.quizTwentyone?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '80%']}
            id="forPayPeriodOff"
            isInvalid={errors.quizTwentytwo}
          >
            <FormLabel>
              22. In the event of an emergency evacuation. Where do you meet
              outside the practice?
            </FormLabel>
            <Input name="quizTwentytwo" ref={register({ required: true })} />
            <FormErrorMessage>
              {errors.quizTwentytwo && errors.quizTwentytwo?.message}
            </FormErrorMessage>
          </FormControl>

          <Center mt="10">
            <Button
              colorScheme="blue"
              width={['100%', '100%', '100%', '10%']}
              isLoading={isDisabled}
              disabled={isDisabled}
              type="submit"
              mb={9}
            >
              Submit
            </Button>
          </Center>
        </form>
      </Box>
    </Container>
  )
}

export default EmployeeQuiz
