import React from 'react'
import {
  PDFViewer,
  StyleSheet,
  Document,
  Page,
  View,
  Text,
  Image,
} from '@react-pdf/renderer'
import moment from 'moment'

const styles = StyleSheet.create({
  pdfView: {
    width: '100%',
    height: window.innerHeight,
    backgroundColor: '#fff',
    fontFamily: 'Open Sans',
  },
  page: {
    width: '100%',
    flexDirection: 'column',
    backgroundColor: '#fff',
    alignItems: 'center',
    padding: '20px 40px',
  },
  sectionImg: {
    width: '100%',
    padding: 0,
    alignItems: 'center',
  },
  img: {
    width: '150px',
    height: 'auto',
    objectFit: 'cover',
  },
  boxHeader: {
    width: '100%',
    alignItems: 'center',
    margin: '20px 0px',
  },
  textHeader: {
    textAlign: 'center',
  },
  boxContentGroup: {
    width: '100%',
    height: '80%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    fontSize: '12px',
  },
  boxContent: {
    width: '100%',
    fontSize: '12px',
  },
  textUnderline: {
    textDecoration: 'underline',
  },
  textList: {
    marginVertical: '4px',
  },
  textSubList: {
    marginLeft: '50px',
  },
  textTitleDetail: {
    marginVertical: '15px',
  },
  textDetail: {
    textDecoration: 'underline',
    lineHeight: 1.5,
  },
  boxSignatureAndDate: {
    marginTop: 20,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  boxSignature: {
    width: '68%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    fontSize: '12px',
  },
  boxDate: {
    width: '30%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    fontSize: '12px',
  },
  signatureStyle: {
    paddingLeft: 12,
    paddingBottom: 2,
    width: '100px',
    height: '50px',
    objectFit: 'contain',
    borderBottom: 1,
  },
  textNamePrint: {
    fontSize: '12px',
    textDecoration: 'underline',
    marginTop: '8px',
  },
})
export default function PDFDeclinationOfInfluenzaVaccination({ employeeData }) {
  return (
    <PDFViewer style={styles.pdfView}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.sectionImg}>
            <Image style={styles.img} src="/paincare-web.png" />
          </View>
          <View style={styles.boxHeader}>
            <Text style={styles.textHeader}>
              Declination of Influenza Vaccination
            </Text>
          </View>
          <View style={styles.boxContentGroup}>
            <View style={styles.boxContent}>
              <Text>
                My employer,{' '}
                <Text style={styles.textUnderline}>{employeeData.name},</Text>{' '}
                has recommended that I receive influenza vaccination to protect
                the patients I serve.
              </Text>
              <Text>I acknowledge that I am aware of the following facts:</Text>
              <Text style={styles.textList}>
                <Text>•</Text> Influenza is a serious respiratory disease that
                kills thousands of people in the United States each year.
              </Text>
              <Text style={styles.textList}>
                <Text>•</Text> Influenza vaccination is recommended for me and
                all other healthcare workers to protect this facility&#8217;s
                patients from influenza, its complications, and death.
              </Text>
              <Text style={styles.textList}>
                <Text>•</Text> If I contract influenza, I can shed the virus for
                24 hours before influenza symptoms appear. My shedding the virus
                can spread influenza to patients in this facility.
              </Text>
              <Text style={styles.textList}>
                <Text>•</Text> If I become infected with influenza, I can spread
                severe illness to others even when my symptoms are mild or
                non-existent.
              </Text>
              <Text>
                <Text>•</Text> I understand that the strains of virus that cause
                influenza infection change almost every year and, even if they
                don&#8217;t change, my immunity declines over time. This is why
                vaccination against influenza is recommended each year.
              </Text>
              <Text style={styles.textList}>
                <Text>•</Text> I understand that I cannot get influenza from the
                influenza vaccine.
              </Text>
              <Text style={styles.textList}>
                <Text>•</Text> The consequences of my refusing to be vaccinated
                could have life-threatening consequences to my health and the
                health of those with whom I have contact, including
              </Text>
              <Text style={styles.textList}>
                <Text>•</Text> The consequences of my refusing to be vaccinated
                could have life-threatening consequences to my health and the
                health of those with whom I have contact, including
              </Text>
              <Text style={styles.textSubList}>
                <Text>•</Text> all patients in this healthcare facility
              </Text>
              <Text style={styles.textSubList}>
                <Text>•</Text> my coworkers
              </Text>
              <Text style={styles.textSubList}>
                <Text>•</Text> my family
              </Text>
              <Text style={styles.textSubList}>
                <Text>•</Text> my community
              </Text>
              <Text>
                Despite these facts, I am choosing to decline influenza
                vaccination right now for the
              </Text>
              <Text style={styles.textTitleDetail}>following reasons:</Text>
              <Text style={styles.textDetail}>{employeeData.detail}</Text>
              <Text style={{ marginTop: 10 }}>
                I understand that I can change my mind at any time and accept
                influenza vaccination, if vaccine is still available.
              </Text>
              <Text>
                I have read and fully understand the information on this
                declination form.
              </Text>
            </View>
            <View>
              <View style={styles.boxSignatureAndDate}>
                <View style={styles.boxSignature}>
                  <Text>Employee Signature :</Text>
                  <Image
                    style={styles.signatureStyle}
                    src={employeeData.signatureCollector}
                  />
                </View>
                <View style={styles.boxDate}>
                  <Text>Date : </Text>
                  <Text style={styles.textUnderline}>
                    {moment(employeeData?.date).format('MM/DD/YYYY')}
                  </Text>
                </View>
              </View>
              <Text style={{ marginTop: 10 }}>
                Name (print) :{' '}
                <Text style={styles.textNamePrint}>
                  {employeeData.namePrint}
                </Text>
              </Text>
              <Text style={{ fontSize: 8, marginTop: 1 }}>
                www.immunize.org/catg.d/p4068.pdf • Item #P4068 (10/11)
              </Text>
              <Text style={{ fontSize: 9, marginTop: 1 }}>
                Immunization Action Coalition • 1573 Selby Ave. • St. Paul, MN
                55104 • (651) 647-9009 • www.immunize.org • w
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  )
}
