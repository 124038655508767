import { Image, Text, View } from '@react-pdf/renderer'
import React from 'react'

function AssignmentBenefits({ styles }) {
  return (
    <View style={{ gap: '10px', width: '100%' }}>
      <View>
        <View
          style={{
            height: '80px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
          <Image
            style={{ width: '140px', objectFit: 'cover' }}
            src="/paincare-web-v2.png"
          />
        </View>
      </View>
      <View style={{ width: '100%', textAlign: 'center' }}>
        <Text style={styles.title}>
          ASSIGNMENT OF BENEFITS FOR LABORATORY TESTING
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          <Text>
            I, the above named patient, understand that services rendered to me
            by Pain Care Specialists or Oregon, LLC physicians and/or other
            healthcare providers are my financial responsibility and that the
            provider will bill my insurance company, listed above, as a
            courtesy. I authorize my insurance company to pay my benefits
            directly to Pain Care Specialists or Oregon, LLC and I understand
            that I will be fully responsible for any outstanding balance on my
            account.
          </Text>
          {` `}
          <Text style={{ fontWeight: 'bold' }}>
            THIS IS A DIRECT ASSIGNMENT OF MY RIGHTS AND BENEFITS UNDER THIS
            POLICY.
          </Text>
          {` `}
          <Text>
            This payment will not exceed my indebtedness to the above-mentioned
            assignee and I have agreed to pay, in a current manner, any balance
            of said professional service charges over and above this insurance
            payment.
          </Text>
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          I have been given the opportunity to pay my estimated deductible and
          co-insurance at the time of service. I have chosen to assign the
          benefits, knowing that the claim must be paid within all state or
          federal prompt payment guidelines. I will provide all relevant and
          accurate information to facilitate the prompt payment of the claim by
          my insurance company.
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          I authorize the provider to release any information necessary to
          adjudicate the claim and understand that there may be associated costs
          for providing information beyond what is necessary for the
          adjudication of a clean claim. In providing this consent, I am fully
          aware that the physicians of Pain Care Specialists or Oregon, LLC, the
          staff and employees cannot be responsible for the confidentiality of
          the information disclosed after medical records have been released.
          Therefore, the physicians of Pain Care Specialists or Oregon, LLC, the
          staff, and employees are released from liability arising from such
          disclosure.
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          I also understand that should my insurance company send payment to me,
          I will forward the payment to Pain Care Specialists or Oregon, LLC
          within forty-eight (48) hours. I agree that if I fail to send the
          payment to Pain Care Specialists or Oregon, LLC and they are forced to
          proceed with the collections process; I will be responsible for any
          cost incurred by the office to retrieve their monies. In the event
          patient receives any check, draft or other payment subject to this
          agreement, I will immediately deliver said check, draft or payment to
          provider. Any violations of this agreement will, at provider's
          election, terminate patient charge privileges with provider and bring
          any balance owed by patient to provider immediately due and payable.
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          To avoid this additional cost and inconvenience, should the insurance
          company forward payment to me, I authorize Pain Care Specialists or
          Oregon, LLC to facilitate payment utilizing the credit card number on
          file to resolve the balance. A photocopy of this Assignment shall be
          considered as effective and valid as the original.
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          I authorize Pain Care Specialists or Oregon, LLC to initiate a
          complaint or file appeals to the insurance commissioner or any payer
          authority for any reason on my behalf and I personally will be active
          in the resolution of claims delay or unjustified reductions or
          denials.
        </Text>
      </View>
      <View style={{ paddingTop: 10 }}>
        <Text style={styles.title}>DISCLOSURE OF PHYSICIAN OWNERSHIP</Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          The physicians at Pain Care Specialists or Oregon, LLC may have
          ownership in one or more of the following entities: Pain Care
          Specialists of Oregon and Oregon Specialists Surgery Center. A list of
          physician ownership is available upon request.
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          You have a right to choose the provider of your health care services.
          Therefore, you have the option to use a health care facility other
          than the one to which you were referred.
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          You will not be treated differently by your physician if you choose to
          use a different facility. If desired, your physician can provide
          information about alternative providers.
        </Text>
      </View>
    </View>
  )
}

export default AssignmentBenefits
