import React from 'react'
import { Image, Text } from '@chakra-ui/react'

export function SwitchInputComponent({ data }) {
  if (data?.includes('data:image/')) {
    return <Image src={data} h="60px" mt="-20px" />
  } else if (data?.includes('https://')) {
    return <Image src={data} h="60px" mt="-20px" />
  } else {
    return <Text>{data || ''}</Text>
  }
}
