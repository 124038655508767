import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import {
  Container,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Box,
  Stack,
  Avatar,
  Divider,
  Text,
  Icon,
  Center,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalBody,
  FormControl,
  FormLabel,
  Select,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  Input,
  HStack,
  useToast,
} from '@chakra-ui/react'
import _ from 'underscore'
import { DatePicker } from 'antd'
import {
  ChevronRightIcon,
  EditIcon,
  CheckIcon,
  CloseIcon,
} from '@chakra-ui/icons'
import { GrCertificate } from 'react-icons/gr'
import {
  doc,
  onSnapshot,
  collection,
  query,
  updateDoc,
} from 'firebase/firestore'
import { db } from '../../config/firebase'
import Mandatory from '../../Components/Mandatory'

function CredentialProfile({ user }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const params = useParams()
  const initialRef = useRef()
  const finalRef = useRef()
  const toast = useToast()
  const [profile, setProfile] = useState(null)
  const [jobTitle, setJobTitle] = useState(null)
  const [editJobTitle, setEditJobTitle] = useState(false)
  const [specialty, setSpecialty] = useState(null)
  const [editSpecialty, setEditSpecialty] = useState(false)
  const [hiredate, setHireDate] = useState(null)
  const [editHiredate, setEditHiredate] = useState(false)
  const [birthdate, setBirthdate] = useState(null)
  const [editBirthdate, setEditBirthdate] = useState(false)
  const [credentialTypes, setCredentialTypes] = useState([])
  const [addCredential, setAddCredential] = useState([])
  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, 'profiles', params.id), snapShot => {
      if (snapShot.exists) {
        setProfile({ ...snapShot.data(), id: snapShot.id })
      }
    })

    const qCredentials = query(collection(db, 'CredentialTypes'))
    const unsubCredentialTypes = onSnapshot(qCredentials, snapShot => {
      let data = []
      snapShot.forEach(doc => {
        data.push({ ...doc.data(), id: doc.id })
      })
      let credentialGroup = _.groupBy(data, item => item.groupName)
      setCredentialTypes(credentialGroup)
    })
    return () => {
      unsubscribe()
      unsubCredentialTypes()
      setProfile(null)
      setCredentialTypes([])
      setAddCredential([])
    }
  }, [params.id])

  function addCredentialToProfile() {
    if (profile.credentials) {
      let credentialIds = _.pluck(profile.credentials, 'id')
      let updateCredentials = []
      _.map(addCredential, newCredential => {
        if (!_.contains(credentialIds, newCredential.id)) {
          updateCredentials.push(newCredential)
        }
      })
      updateDoc(doc(db, 'profiles', params.id), {
        credentials: [...profile.credentials, ...updateCredentials],
      }).then(() => {
        onClose()
      })
    } else {
      updateDoc(doc(db, 'profiles', params.id), {
        credentials: [...addCredential],
      }).then(() => {
        onClose()
      })
    }
  }

  return (
    <Container maxW="100%" mt="5">
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
        bg="gray.100"
        p="1.5"
        borderRadius="0.3em"
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/dashboard">
            Dashbaord
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/credentialing">
            Credentialing
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to={`/credentialing/${profile?.id}`}>
            {profile?.fullName}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Box mt="5" mb="5" minH="300px" borderWidth="1px" borderRadius="lg">
        <Stack
          direction={['column', 'column', 'column', 'row']}
          spacing="20px"
          p="15px"
        >
          <Box
            bgColor="gray.100"
            p="15"
            borderTopLeftRadius="lg"
            align="center"
          >
            <Avatar src={profile?.avatarUrl} size="2xl" />
          </Box>
          <Divider
            h="300px"
            orientation="vertical"
            display={['none', 'none', 'none', 'inherit']}
          />
          <Box p="15px">
            <Box mb="10px">
              <Text fontSize="3xl" fontWeight="bold" color="gray.700">
                {profile?.fullName}
              </Text>
            </Box>
            <Stack mb="3px" direction="row">
              <Center>
                <Text fontWeight="bold" mr="10px">
                  Job Title :{' '}
                </Text>
                <Text fontSize="md" color="gray.500" mr="10px">
                  {editJobTitle ? (
                    <Input
                      size="xs"
                      type="text"
                      onChange={e => setJobTitle(e.currentTarget.value)}
                      placeholder="Job title"
                      defaultValue={profile?.jobTitle}
                    />
                  ) : profile?.jobTitle ? (
                    profile.jobTitle
                  ) : (
                    'n/a'
                  )}
                </Text>
                {editJobTitle ? (
                  <HStack>
                    <Button
                      onClick={() => {
                        updateDoc(doc(db, 'profiles', profile.id), {
                          jobTitle: jobTitle,
                        })
                          .then(() => {
                            toast({
                              title: 'Profile Updated.',
                              description: `Job title has been updated.`,
                              status: 'success',
                              duration: 3000,
                              isClosable: true,
                            })
                            setEditJobTitle(false)
                          })
                          .catch(e => {
                            console.log(e)
                          })
                      }}
                      leftIcon={<CheckIcon />}
                      size="xs"
                    >
                      Save
                    </Button>{' '}
                    <Button
                      onClick={() => setEditJobTitle(false)}
                      leftIcon={<CloseIcon />}
                      size="xs"
                    >
                      Close
                    </Button>
                  </HStack>
                ) : (
                  <Icon
                    as={EditIcon}
                    onClick={() => setEditJobTitle(true)}
                    cursor="pointer"
                  />
                )}
              </Center>
            </Stack>
            <Stack mb="3px" direction="row">
              <Center>
                <Text fontWeight="bold" mr="10px">
                  Specialty :{' '}
                </Text>
                <Text fontSize="md" color="gray.500" mr="10px">
                  {editSpecialty ? (
                    <Input
                      size="xs"
                      type="text"
                      onChange={e => setSpecialty(e.currentTarget.value)}
                      placeholder="Specialty"
                      defaultValue={profile?.specialty}
                    />
                  ) : profile?.specialty ? (
                    profile.specialty
                  ) : (
                    'n/a'
                  )}
                </Text>
                {editSpecialty ? (
                  <HStack>
                    <Button
                      onClick={() => {
                        updateDoc(doc(db, 'profiles', profile.id), {
                          specialty: specialty,
                        })
                          .then(() => {
                            toast({
                              title: 'Profile Updated.',
                              description: `Specialty has been updated.`,
                              status: 'success',
                              duration: 3000,
                              isClosable: true,
                            })
                            setEditSpecialty(false)
                          })
                          .catch(e => {
                            console.log(e)
                          })
                      }}
                      leftIcon={<CheckIcon />}
                      size="xs"
                    >
                      Save
                    </Button>{' '}
                    <Button
                      onClick={() => setEditSpecialty(false)}
                      leftIcon={<CloseIcon />}
                      size="xs"
                    >
                      Close
                    </Button>
                  </HStack>
                ) : (
                  <Icon
                    as={EditIcon}
                    onClick={() => setEditSpecialty(true)}
                    cursor="pointer"
                  />
                )}
              </Center>
            </Stack>
            <Stack mb="3px" direction="row">
              <Center>
                <Text fontWeight="bold" mr="10px">
                  Hire Date :{' '}
                </Text>
                <Box fontSize="md" color="gray.500" mr="10px">
                  {editHiredate ? (
                    <DatePicker
                      size="small"
                      format="YYYY-MM-DD"
                      onChange={value => setHireDate(value)}
                      placeholder="Hire Date"
                    />
                  ) : profile?.hiredate ? (
                    profile.hiredate
                  ) : (
                    'n/a'
                  )}
                </Box>
                {editHiredate ? (
                  <HStack>
                    <Button
                      onClick={() => {
                        let dateFormat = hiredate.format('YYYY-MM-DD')
                        updateDoc(doc(db, 'profiles', profile.id), {
                          hiredate: dateFormat,
                        })
                          .then(() => {
                            toast({
                              title: 'Profile Updated.',
                              description: `Hire date has been updated.`,
                              status: 'success',
                              duration: 3000,
                              isClosable: true,
                            })
                            setEditHiredate(false)
                          })
                          .catch(e => {
                            console.log(e)
                          })
                      }}
                      leftIcon={<CheckIcon />}
                      size="xs"
                      disabled={!hiredate}
                    >
                      Save
                    </Button>{' '}
                    <Button
                      onClick={() => setEditHiredate(false)}
                      leftIcon={<CloseIcon />}
                      size="xs"
                    >
                      Close
                    </Button>
                  </HStack>
                ) : (
                  <Icon
                    as={EditIcon}
                    onClick={() => setEditHiredate(true)}
                    cursor="pointer"
                  />
                )}
              </Center>
            </Stack>
            <Stack mb="3px" direction="row">
              <Center>
                <Text fontWeight="bold" mr="10px">
                  Date of Birth:
                </Text>
                <Box fontSize="md" color="gray.500" mr="10px">
                  {editBirthdate ? (
                    <DatePicker
                      size="small"
                      format="YYYY-MM-DD"
                      onChange={value => setBirthdate(value)}
                      placeholder="Birth Date"
                    />
                  ) : profile?.birthdate ? (
                    profile.birthdate
                  ) : (
                    'n/a'
                  )}
                </Box>
                {editBirthdate ? (
                  <HStack>
                    <Button
                      onClick={() => {
                        let dateFormat = birthdate.format('YYYY-MM-DD')
                        updateDoc(doc(db, 'profiles', profile.id), {
                          birthdate: dateFormat,
                        })
                          .then(() => {
                            toast({
                              title: 'Profile Updated.',
                              description: `Birth date has been updated.`,
                              status: 'success',
                              duration: 3000,
                              isClosable: true,
                            })
                            setEditBirthdate(false)
                          })
                          .catch(e => {
                            console.log(e)
                          })
                      }}
                      leftIcon={<CheckIcon />}
                      size="xs"
                      disabled={!birthdate}
                    >
                      Save
                    </Button>{' '}
                    <Button
                      onClick={() => setEditBirthdate(false)}
                      leftIcon={<CloseIcon />}
                      size="xs"
                    >
                      Close
                    </Button>
                  </HStack>
                ) : (
                  <Icon
                    as={EditIcon}
                    onClick={() => setEditBirthdate(true)}
                    cursor="pointer"
                  />
                )}
              </Center>
            </Stack>
          </Box>
        </Stack>
      </Box>
      <Box mt="5" minH="200px" borderWidth="1px" borderRadius="lg" p="15px">
        <Box mb="5" display="flex" justifyContent="space-between">
          <Text fontSize="lg" fontWeight="bold" color="gray.700">
            Credentials
          </Text>
          <Button size="sm" leftIcon={<GrCertificate />} onClick={onOpen}>
            Setup
          </Button>
        </Box>
        <Box>
          <Accordion allowMultiple>
            {!_.isEmpty(profile?.credentials) ? (
              _.map(profile.credentials, (credential, credentialIndex) => (
                <AccordionItem key={credentialIndex}>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        <Icon as={GrCertificate} /> {credential.typeName}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Table>
                      <Thead>
                        <Tr>
                          <Th w="10%">Mandatory</Th>
                          <Th>Data</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {credential?.mandatory.map(
                          (mandatory, mandatoryIndex) => (
                            <Tr key={mandatoryIndex}>
                              <Td textAlign="right">{mandatory.title}</Td>
                              <Td>
                                <Mandatory
                                  credential={credential.typeName}
                                  credentialId={credential.id}
                                  credentialIndex={credentialIndex}
                                  mandatoryIndex={mandatoryIndex}
                                  profile={profile}
                                  {...mandatory}
                                  createdBy={
                                    user.fullName ? user.fullName : 'System'
                                  }
                                />
                              </Td>
                            </Tr>
                          )
                        )}
                      </Tbody>
                    </Table>
                  </AccordionPanel>
                </AccordionItem>
              ))
            ) : (
              <Center>No Credentials</Center>
            )}
          </Accordion>
        </Box>
      </Box>
      <Box mt="5" h="100px"></Box>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Setup Credentials</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Credential Group</FormLabel>
              <Select
                placeholder="Select"
                onChange={e => {
                  let group = e.currentTarget.value
                  if (group) {
                    setAddCredential(credentialTypes[group])
                  } else {
                    setAddCredential([])
                  }
                }}
              >
                {_.map(credentialTypes, (credential, index) => (
                  <option key={index} value={index}>
                    {index}
                  </option>
                ))}
              </Select>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              disabled={_.isEmpty(addCredential) ? true : false}
              onClick={addCredentialToProfile}
            >
              Setup
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  )
}

export default CredentialProfile
