import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import {
    Container,
    Image,
    Box,
    FormControl,
    FormLabel,
    Input,
    Button,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    HStack,
    Center,
    Textarea,
    CheckboxGroup,
    Checkbox,
    Text,
    Stack,
    Table,
    Thead,
    Tbody,
    Tr,
    Td,
    Divider,
    RadioGroup,
    Radio,
    FormErrorMessage
} from '@chakra-ui/react'

import { ChevronRightIcon } from '@chakra-ui/icons'
import Logo from '../../Logo.png'

function NonWebPatientIntakeS() {

    const { handleSubmit, register, formState, errors, control } = useForm()
    const history = useHistory()
    function onSubmit(values) {
        console.log(values)
        history.push("/non-web-enable-form/patient-intake-3")
    }

    return (
        <Container maxW="100%" mt="5">
            <Image cursor="pointer" src={Logo} alt="paincare logo" htmlWidth="100px" />
            <Breadcrumb
                spacing="8px"
                separator={<ChevronRightIcon color="gray.500" />}
                bg="gray.100" p="1.5"
                borderRadius="0.3em"
                mt="5"
            >
                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to="/">Forms</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to="/non-web-enable-form">Non Web Enable New Patient Packet</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to="/non-web-enable-form/patient-intake-1">New Patient Intake Form for  Kiosk Check-in 1 of 3</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink>New Patient Intake Form for  Kiosk Check-in 2 of 3</BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>
            <Box mt="10" mb="10" textAlign="center" fontSize="lg" fontWeight="bold">
                New Patient Intake Form for  Kiosk Check-in
            </Box>
            <Container maxW={'container.xl'} mb="20">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box mt="10" mb="10" textAlign="">
                        Current Medications:
                    </Box>
                    <Stack direction={['column','row']} spacing="5">
                        <FormControl isInvalid={errors.currentMedication1}>
                            <FormLabel>Name</FormLabel>
                            <Input name="currentMedication1" ref={register({ required: 'Field required.' })} />
                            <FormErrorMessage>{errors.currentMedication1 && errors.currentMedication1.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={errors.dose1}>
                            <FormLabel>Dose</FormLabel>
                            <Input name="dose1" ref={register({ required: 'Field required.' })} />
                            <FormErrorMessage>{errors.dose1 && errors.dose1.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={errors.frequency1}>
                            <FormLabel>Frequency</FormLabel>
                            <Input name="frequency1" ref={register({ required: 'Field required.' })} />
                            <FormErrorMessage>{errors.frequency1 && errors.frequency1.message}</FormErrorMessage>
                        </FormControl>
                    </Stack>
                    <Stack direction={['column','row']} spacing="5">
                        <FormControl isInvalid={errors.currentMedication2}>
                            <FormLabel>Name</FormLabel>
                            <Input name="currentMedication2" ref={register({ required: 'Field required.' })} />
                            <FormErrorMessage>{errors.currentMedication2 && errors.currentMedication2.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={errors.dose2}>
                            <FormLabel>Dose</FormLabel>
                            <Input name="dose2" ref={register({ required: 'Field required.' })} />
                            <FormErrorMessage>{errors.dose2 && errors.dose2.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={errors.frequency2}>
                            <FormLabel>Frequency</FormLabel>
                            <Input name="frequency2" ref={register({ required: 'Field required.' })} />
                            <FormErrorMessage>{errors.frequency2 && errors.frequency2.message}</FormErrorMessage>
                        </FormControl>
                    </Stack>
                    <Stack direction={['column','row']} spacing="5">
                        <FormControl isInvalid={errors.currentMedication2}>
                            <FormLabel>Name</FormLabel>
                            <Input name="currentMedication3" ref={register({ required: 'Field required.' })} />
                            <FormErrorMessage>{errors.currentMedication3 && errors.currentMedication3.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={errors.currentMedication2}>
                            <FormLabel>Dose</FormLabel>
                            <Input name="dose3" ref={register({ required: 'Field required.' })} />
                            <FormErrorMessage>{errors.dose3 && errors.dose3.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={errors.currentMedication2}>
                            <FormLabel>Frequency</FormLabel>
                            <Input name="frequency3" ref={register({ required: 'Field required.' })} />
                            <FormErrorMessage>{errors.frequency3 && errors.frequency3.message}</FormErrorMessage>
                        </FormControl>
                    </Stack>
                    <Stack direction={['column','row']} spacing="5">
                        <FormControl isInvalid={errors.currentMedication4}>
                            <FormLabel>Name</FormLabel>
                            <Input name="currentMedication4" ref={register({ required: 'Field required.' })} />
                            <FormErrorMessage>{errors.currentMedication4 && errors.currentMedication4.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={errors.dose4}>
                            <FormLabel>Dose</FormLabel>
                            <Input name="dose4" ref={register({ required: 'Field required.' })} />
                            <FormErrorMessage>{errors.dose4 && errors.dose4.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={errors.frequency4}>
                            <FormLabel>Frequency</FormLabel>
                            <Input name="frequency4" ref={register({ required: 'Field required.' })} />
                            <FormErrorMessage>{errors.frequency4 && errors.frequency4.message}</FormErrorMessage>
                        </FormControl>
                    </Stack>
                    <Stack direction={['column','row']} spacing="5">
                        <FormControl isInvalid={errors.currentMedication5}>
                            <FormLabel>Name</FormLabel>
                            <Input name="currentMedication5" ref={register({ required: 'Field required.' })} />
                            <FormErrorMessage>{errors.currentMedication5 && errors.currentMedication5.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={errors.dose5}>
                            <FormLabel>Dose</FormLabel>
                            <Input name="dose5" ref={register({ required: 'Field required.' })} />
                            <FormErrorMessage>{errors.dose5 && errors.dose5.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={errors.frequency5}>
                            <FormLabel>Frequency</FormLabel>
                            <Input name="frequency5" ref={register({ required: 'Field required.' })} />
                            <FormErrorMessage>{errors.frequency5 && errors.frequency5.message}</FormErrorMessage>
                        </FormControl>
                    </Stack>
                    <Box mt="5">
                        Current Medical Problems:
                    </Box>
                    <Textarea mt="5" isInvalid={errors.currentMedicalProblems} name="currentMedicalProblems" ref={register({ required: 'Field required.' })} />
                    <FormControl mt="5">
                        <FormLabel>Allergies (Circle all that apply):</FormLabel>
                        <Controller
                            render={(props) => (
                                <CheckboxGroup onChange={props.onChange} onBlur={props.onBlur}>
                                    <Stack direction={['column','row']} spacing="5">
                                            <Checkbox value="None">None</Checkbox>
                                            <Checkbox value="Tape">Tape</Checkbox>
                                            <Checkbox value="Iodine">Iodine</Checkbox>
                                            <Checkbox value="Latex">Latex</Checkbox>
                                            <Checkbox value="Other">Other</Checkbox>
                                    </Stack>
                                </CheckboxGroup>
                            )}
                            defaultValue={''}
                            name="allergies"
                            control={control}
                            rules={{ required: 'Allergies required.' }}
                        />
                        <FormErrorMessage>{errors.allergies && errors.allergies.message}</FormErrorMessage>
                    </FormControl>
                    <Box mt="10">
                        Medication Allergies (list):
                    </Box>
                    <Textarea isInvalid={errors.medicationAllergies} mt="5" name="medicationAllergies" ref={register({ required: 'Field required.' })} />
                    <FormControl mt="5" isInvalid={errors.surgicalHistory}>
                        <FormLabel>Surgical History (Circle all that apply):</FormLabel>
                        <Controller
                            render={(props) => (
                                <CheckboxGroup onChange={props.onChange} onBlur={props.onBlur}>
                                    <Stack direction={['column','row']} spacing="5">
                                        <Stack>
                                            <Checkbox value="Cardiac/Heart">Cardiac/Heart</Checkbox>
                                            <Checkbox value="Cervical/Neck">Cervical/Neck</Checkbox>
                                            <Checkbox value="Lumbar/Back">Lumbar/Back</Checkbox>
                                        </Stack>
                                        <Stack>
                                            <Checkbox value="Hip Left/Right">Hip Left/Right</Checkbox>
                                            <Checkbox value="Knee Left/Right">Knee Left/Right</Checkbox>
                                            <Checkbox value="Shoulder Left/Right">Shoulder Left/Right</Checkbox>
                                        </Stack>
                                    </Stack>
                                </CheckboxGroup>
                            )}
                            defaultValue={''}
                            name="surgicalHistory"
                            control={control}
                            rules={{ required: 'Sugical History required.' }}
                        />
                        <FormErrorMessage>{errors.surgicalHistory && errors.surgicalHistory.message}</FormErrorMessage>
                    </FormControl>
                    <Box mt="5">
                        Current Medical Problems:
                    </Box>
                    <Stack direction={['column','row']} spacing="5">
                        <FormControl isInvalid={errors.pastHospitalDate1}>
                            <FormLabel>Date:</FormLabel>
                            <Input name="pastHospitalDate1" ref={register({ required: 'Field required.' })} />
                        <FormErrorMessage>{errors.pastHospitalDate1 && errors.pastHospitalDate1.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={errors.pastHospitalReason1}>
                            <FormLabel>Reason:</FormLabel>
                            <Input name="pastHospitalReason1" ref={register({ required: 'Field required.' })} />
                        <FormErrorMessage>{errors.pastHospitalReason1 && errors.pastHospitalReason1.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={errors.pastHospitalDate2}>
                            <FormLabel>Date:</FormLabel>
                            <Input name="pastHospitalDate2" ref={register({ required: 'Field required.' })} />
                        <FormErrorMessage>{errors.pastHospitalDate2 && errors.pastHospitalDate2.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={errors.pastHospitalReason2}>
                            <FormLabel>Reason:</FormLabel>
                            <Input name="pastHospitalReason2" ref={register({ required: 'Field required.' })} />
                        <FormErrorMessage>{errors.pastHospitalReason2 && errors.pastHospitalReason2.message}</FormErrorMessage>
                        </FormControl>
                    </Stack>
                    <Stack direction={['column','row']} spacing="5">
                        <FormControl isInvalid={errors.pastHospitalDate3}>
                            <FormLabel>Date:</FormLabel>
                            <Input name="pastHospitalDate3" ref={register({ required: 'Field required.' })} />
                        <FormErrorMessage>{errors.pastHospitalDate3 && errors.pastHospitalDate3.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={errors.pastHospitalReason3}>
                            <FormLabel>Reason:</FormLabel>
                            <Input name="pastHospitalReason3" ref={register({ required: 'Field required.' })} />
                        <FormErrorMessage>{errors.pastHospitalReason3 && errors.pastHospitalReason3.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={errors.pastHospitalDate3}>
                            <FormLabel>Date:</FormLabel>
                            <Input name="pastHospitalDate3" ref={register({ required: 'Field required.' })} />
                        <FormErrorMessage>{errors.pastHospitalDate3 && errors.pastHospitalDate3.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={errors.pastHospitalReason3}>
                            <FormLabel>Reason:</FormLabel>
                            <Input name="pastHospitalReason3" ref={register({ required: 'Field required.' })} />
                        <FormErrorMessage>{errors.pastHospitalReason3 && errors.pastHospitalReason3.message}</FormErrorMessage>
                        </FormControl>
                    </Stack>
                    <Stack direction={['column','row']} spacing="5">
                        <FormControl isInvalid={errors.pastHospitalDate4}>
                            <FormLabel>Date:</FormLabel>
                            <Input name="pastHospitalDate4" ref={register({ required: 'Field required.' })} />
                        <FormErrorMessage>{errors.pastHospitalDate4 && errors.pastHospitalDate4.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={errors.pastHospitalReason4}>
                            <FormLabel>Reason:</FormLabel>
                            <Input name="pastHospitalReason4" ref={register({ required: 'Field required.' })} />
                        <FormErrorMessage>{errors.pastHospitalReason4 && errors.pastHospitalReason4.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={errors.pastHospitalDate4}>
                            <FormLabel>Date:</FormLabel>
                            <Input name="pastHospitalDate4" ref={register({ required: 'Field required.' })} />
                        <FormErrorMessage>{errors.pastHospitalDate4 && errors.pastHospitalDate4.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={errors.pastHospitalReason4}>
                            <FormLabel>Reason:</FormLabel>
                            <Input name="pastHospitalReason4" ref={register({ required: 'Field required.' })} />
                        <FormErrorMessage>{errors.pastHospitalReason4 && errors.pastHospitalReason4.message}</FormErrorMessage>
                        </FormControl>
                    </Stack>
                    <Stack overflowX={'hidden'} maxW={'1250px'}>
                    <Box mt="10">Family History (Please place an X where applicable):</Box>
                    <FormControl mt="5" isInvalid={errors.heartDisease}>
                        <Controller
                            render={(props) => (
                                <Table>
                                    <CheckboxGroup onChange={props.onChange} onBlur={props.onBlur}>
                                        <Thead>
                                            <Tr>
                                                <Td w={'220px'}></Td>
                                                <Td w={'220px'}>Father</Td>
                                                <Td w={'220px'}>Mother</Td>
                                                <Td w={'220px'}>Grandparent</Td>
                                                <Td w={'220px'}>Sibling</Td>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            <Td w={'220px'}>Heart Disease</Td>
                                            <Td w={'220px'}><Checkbox value="Father"/></Td>
                                            <Td w={'220px'}><Checkbox value="Mother"/></Td>
                                            <Td w={'220px'}><Checkbox value="Grandparent"/></Td>
                                            <Td w={'220px'}><Checkbox value="Sibling"/></Td>
                                        </Tbody>
                                    </CheckboxGroup>
                                </Table>
                            )}
                            defaultValue={''}
                            name="heartDisease"
                            rules={{ required: 'Impotant information required.' }}
                            control={control}
                        />
                        <FormErrorMessage>{errors.heartDisease && errors.heartDisease.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl mt="5" isInvalid={errors.lungDisease}>
                        <Controller
                            render={(props) => (
                                <Table>
                                    <CheckboxGroup onChange={props.onChange} onBlur={props.onBlur}>
                                        <Tbody>
                                            <Td w={'220px'}>Lung Disease</Td>
                                            <Td w={'220px'}><Checkbox value="Father"/></Td>
                                            <Td w={'220px'}><Checkbox value="Mother"/></Td>
                                            <Td w={'220px'}><Checkbox value="Grandparent"/></Td>
                                            <Td w={'220px'}><Checkbox value="Sibling"/></Td>
                                        </Tbody>
                                    </CheckboxGroup>
                                </Table>
                            )}
                            defaultValue={''}
                            name="lungDisease"
                            rules={{ required: 'Impotant information required.' }}
                            control={control}
                        />
                        <FormErrorMessage>{errors.lungDisease && errors.lungDisease.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl mt="5" isInvalid={errors.arthrits}>
                        <Controller
                            render={(props) => (
                                <Table>
                                    <CheckboxGroup onChange={props.onChange} onBlur={props.onBlur}>
                                        <Tbody>
                                            <Td w={'220px'}>Arthrits</Td>
                                            <Td w={'220px'}><Checkbox value="Father"/></Td>
                                            <Td w={'220px'}><Checkbox value="Mother"/></Td>
                                            <Td w={'220px'}><Checkbox value="Grandparent"/></Td>
                                            <Td w={'220px'}><Checkbox value="Sibling"/></Td>
                                        </Tbody>
                                    </CheckboxGroup>
                                </Table>
                            )}
                            defaultValue={''}
                            name="arthrits"
                            rules={{ required: 'Impotant information required.' }}
                            control={control}
                        />
                        <FormErrorMessage>{errors.arthrits && errors.arthrits.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl mt="5" isInvalid={errors.Cancer}>
                        <Controller
                            render={(props) => (
                                <Table>
                                    <CheckboxGroup onChange={props.onChange} onBlur={props.onBlur}>
                                        <Tbody>
                                            <Td w={'220px'}>Cancer</Td>
                                            <Td w={'220px'}><Checkbox value="Father"/></Td>
                                            <Td w={'220px'}><Checkbox value="Mother"/></Td>
                                            <Td w={'220px'}><Checkbox value="Grandparent"/></Td>
                                            <Td w={'220px'}><Checkbox value="Sibling"/></Td>
                                        </Tbody>
                                    </CheckboxGroup>
                                </Table>
                            )}
                            defaultValue={''}
                            name="Cancer"
                            rules={{ required: 'Impotant information required.' }}
                            control={control}
                        />
                        <FormErrorMessage>{errors.Cancer && errors.Cancer.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl mt="5" isInvalid={errors.Stroke}>
                        <Controller
                            render={(props) => (
                                <Table>
                                    <CheckboxGroup onChange={props.onChange} onBlur={props.onBlur}>
                                        <Tbody>
                                            <Td w={'220px'}>Stroke</Td>
                                            <Td w={'220px'}><Checkbox value="Father"/></Td>
                                            <Td w={'220px'}><Checkbox value="Mother"/></Td>
                                            <Td w={'220px'}><Checkbox value="Grandparent"/></Td>
                                            <Td w={'220px'}><Checkbox value="Sibling"/></Td>
                                        </Tbody>
                                    </CheckboxGroup>
                                </Table>
                            )}
                            defaultValue={''}
                            name="Stroke"
                            rules={{ required: 'Impotant information required.' }}
                            control={control}
                        />
                        <FormErrorMessage>{errors.Stroke && errors.Stroke.message}</FormErrorMessage>
                    </FormControl>
                    </Stack>
                    <Stack>
                        <Divider borderColor={'gray.500'}/>
                        <Stack direction={['column','row']} justifyContent={'space-around'}>
                            <Stack borderRight={[null,'1px']} borderBottom={['1px',null]} borderColor={'gray.500'}>
                                <Text>
                                    Social History: (Please check Yes or No for each question)
                                </Text>
                                <FormControl isInvalid={errors.Smoking}>
                                    <FormLabel>
                                        Smoking
                                    </FormLabel>
                                    <Controller 
                                        render={(props)=>(
                                            <RadioGroup onChange={props.onChange} onBlur={props.onBlur}>
                                                <HStack>
                                                    <Radio value={'Yes'}>Yes</Radio>
                                                    <Radio value={'No'}>No</Radio>
                                                    <Input name={"SmokingPacks"} ref={register()}  />
                                                    <Text w={"13rem"}>packs per day/week</Text>
                                                </HStack>
                                            </RadioGroup>
                                        )}
                                        defaultValue={''}
                                        control={control}
                                        name={"Smoking"}
                                        rules={{ required: 'Impotant information required.' }}
                                        />
                                        <FormErrorMessage>{errors.Smoking && errors.Smoking.message}</FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={errors.Alcohol}>
                                    <FormLabel>
                                        Alcohol
                                    </FormLabel>
                                    <Controller 
                                        render={(props)=>(
                                            <RadioGroup onChange={props.onChange} onBlur={props.onBlur}>
                                                <HStack>
                                                    <Radio value={'Yes'}>Yes</Radio>
                                                    <Radio value={'No'}>No</Radio>
                                                    <Input name={"AlcoholDrinks"} ref={register()}  />
                                                    <Text w={"13rem"}>drinks per day/week</Text>
                                                </HStack>
                                            </RadioGroup>
                                        )}
                                        defaultValue={''}
                                        control={control}
                                        name={"Alcohol"}
                                        rules={{ required: 'Impotant information required.' }}
                                        />
                                        <FormErrorMessage>{errors.Alcohol && errors.Alcohol.message}</FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={errors.Illegal}>
                                    <FormLabel>
                                        Illegal Drugs
                                    </FormLabel>
                                    <Controller 
                                        render={(props)=>(
                                            <RadioGroup onChange={props.onChange} onBlur={props.onBlur}>
                                                <HStack>
                                                    <Radio value={'Yes'}>Yes</Radio>
                                                    <Radio value={'No'}>No</Radio>
                                                    <Text w={"13rem"}>Date last used:</Text>
                                                    <Input name={"IllegalDrugs"} ref={register()}  />
                                                </HStack>
                                            </RadioGroup>
                                        )}
                                        defaultValue={''}
                                        control={control}
                                        name={"Illegal"}
                                        rules={{ required: 'Impotant information required.' }}
                                        />
                                        <FormErrorMessage>{errors.Illegal && errors.Illegal.message}</FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={errors.AddictionDependency}>
                                    <FormLabel>
                                        Addiction/Dependency
                                    </FormLabel>
                                    <Controller 
                                        render={(props)=>(
                                            <RadioGroup onChange={props.onChange} onBlur={props.onBlur}>
                                                <HStack>
                                                    <Radio value={'Yes'}>Yes</Radio>
                                                    <Radio value={'No'}>No</Radio>
                                                </HStack>
                                            </RadioGroup>
                                        )}
                                        defaultValue={''}
                                        control={control}
                                        name={"AddictionDependency"}
                                        rules={{ required: 'Impotant information required.' }}
                                        />
                                        <FormErrorMessage>{errors.AddictionDependency && errors.AddictionDependency.message}</FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={errors.Marijuana}>
                                    <FormLabel>
                                        Marijuana
                                    </FormLabel>
                                    <Controller 
                                        render={(props)=>(
                                            <RadioGroup onChange={props.onChange} onBlur={props.onBlur}>
                                                <HStack>
                                                    <Radio value={'Yes'}>Yes</Radio>
                                                    <Radio value={'No'}>No</Radio>
                                                </HStack>
                                            </RadioGroup>
                                        )}
                                        defaultValue={''}
                                        control={control}
                                        name={"Marijuana"}
                                        rules={{ required: 'Impotant information required.' }}
                                        />
                                        <FormErrorMessage>{errors.Marijuana && errors.Marijuana.message}</FormErrorMessage>
                                </FormControl>
                            </Stack>
                            <Stack h={'full'} pl={5}>
                            <FormControl isInvalid={errors.Married}>
                                    <FormLabel>
                                        Married
                                    </FormLabel>
                                    <Controller 
                                        render={(props)=>(
                                            <RadioGroup onChange={props.onChange} onBlur={props.onBlur}>
                                                <HStack>
                                                    <Radio value={'Yes'}>Yes</Radio>
                                                    <Radio value={'No'}>No</Radio>
                                                    <Radio value={'Divorced'}>Divorced</Radio>
                                                </HStack>
                                            </RadioGroup>
                                        )}
                                        defaultValue={''}
                                        control={control}
                                        name={"Married"}
                                        rules={{ required: 'Impotant information required.' }}
                                        />
                                        <FormErrorMessage>{errors.Married && errors.Married.message}</FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={errors.Children}>
                                    <FormLabel>
                                        Children
                                    </FormLabel>
                                    <Controller 
                                        render={(props)=>(
                                            <RadioGroup onChange={props.onChange} onBlur={props.onBlur}>
                                                <HStack>
                                                    <Radio value={'Yes'}>Yes</Radio>
                                                    <Radio value={'No'}>No</Radio>
                                                    <Text w={"13rem"}>How many?</Text>
                                                    <Input name={"ChildrenHowMany"} ref={register()}  />
                                                </HStack>
                                            </RadioGroup>
                                        )}
                                        defaultValue={''}
                                        control={control}
                                        name={"Children"}
                                        rules={{ required: 'Impotant information required.' }}
                                        />
                                        <FormErrorMessage>{errors.Children && errors.Children.message}</FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={errors.Disability}>
                                    <FormLabel>
                                        On Disability
                                    </FormLabel>
                                    <Controller 
                                        render={(props)=>(
                                            <RadioGroup onChange={props.onChange} onBlur={props.onBlur}>
                                                <HStack>
                                                    <Radio value={'Yes'}>Yes</Radio>
                                                    <Radio value={'No'}>No</Radio>
                                                </HStack>
                                            </RadioGroup>
                                        )}
                                        defaultValue={''}
                                        control={control}
                                        name={"Disability"}
                                        rules={{ required: 'Impotant information required.' }}
                                        />
                                        <FormErrorMessage>{errors.Disability && errors.Disability.message}</FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={errors.CurruntlyWorking}>
                                    <FormLabel>
                                        Curruntly Working
                                    </FormLabel>
                                    <Controller 
                                        render={(props)=>(
                                            <RadioGroup onChange={props.onChange} onBlur={props.onBlur}>
                                                <HStack>
                                                    <Radio value={'Yes'}>Yes</Radio>
                                                    <Radio value={'No'}>No</Radio>
                                                </HStack>
                                            </RadioGroup>
                                        )}
                                        defaultValue={''}
                                        control={control}
                                        name={"CurruntlyWorking"}
                                        rules={{ required: 'Impotant information required.' }}
                                        />
                                        <FormErrorMessage>{errors.CurruntlyWorking && errors.CurruntlyWorking.message}</FormErrorMessage>
                                </FormControl>
                            </Stack>
                        </Stack>
                        <Divider borderColor={'gray.500'}/>
                    </Stack>
                    
                    <Center>
                        <Button mt="5" colorScheme="blue" type="submit" isLoading={formState.isSubmitting}>Submit</Button>
                    </Center>
                </form>
            </Container>
        </Container>
    )
}

export default NonWebPatientIntakeS