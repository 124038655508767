import {
  Box,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  VStack,
  HStack,
  InputGroup,
  InputLeftAddon,
  Grid,
  GridItem,
} from '@chakra-ui/react'
import moment from 'moment'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { CardContent } from '../../../../../../Components/Card/card'
import { SwitchInputComponent } from '../../../switchInput'
import { totalNonWebEnabledPage } from '../..'
const _ = require('underscore')
function SignaturePermission({ formView }) {
  const {
    // handleSubmit,
    // reset,
    // register,
    // errors,
    control,
    setValue,
    // watch,
    // clearErrors,
  } = useForm()
  useEffect(() => {
    if (formView) {
      const data = formView
      _.keys(data).map(e => {
        return setValue(e, data[e])
      })
    }
  }, [formView, setValue])
  return (
    <CardContent>
      <Box>
        <Text
          align="center"
          fontWeight="bold"
          pl={{ base: '0px', md: '10px', lg: '50px' }}
          pr={{ base: '0px', md: '10px', lg: '50px' }}
        >
          PERMISSION TO RELEASE CONFIDENTIAL MEDICAL INFORMAION TO A FAMILY
          MEMBER, FRIEND OR LEGAL REPRESENTAITVE
        </Text>
        <Text mt="40px" pl="10px">
          IMPORTANT NOTE: The law prohibits release of confidential Medical
          information to any entity without the written, voluntary consent of
          the undersigned patient.
        </Text>
        <Flex
          pt="30px"
          pb="10px"
          direction={{ base: 'column', sm: 'column', md: 'row', lg: 'row' }}
        >
          <Box w="100%" pr={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}>
            <Controller
              name="namePatient"
              control={control}
              defaultValue={formView.namePatient}
              render={props => (
                <FormControl>
                  <FormLabel>Name of Patient</FormLabel>
                  <Input
                    _readOnly={{
                      border: '1px solid #ccc',
                    }}
                    variant="no-effects"
                    bg="#F9F9F9"
                    isReadOnly
                    border="1px solid black"
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                  />
                </FormControl>
              )}
            />
          </Box>
          <Box
            w="100%"
            pt={{ base: '10px', md: '0px' }}
            pl={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}
          >
            <Controller
              name="DOB"
              control={control}
              defaultValue={moment(new Date(formView.DOB)).format('MM/DD/YYYY')}
              render={props => (
                <FormControl>
                  <FormLabel>DOB (mm/dd/yy)</FormLabel>
                  <Input
                    _readOnly={{
                      border: '1px solid #ccc',
                    }}
                    variant="no-effects"
                    bg="#F9F9F9"
                    border="1px solid black"
                    isReadOnly
                    defaultValue={moment(new Date(formView.DOB)).format(
                      'MM/DD/YYYY'
                    )}
                  />
                </FormControl>
              )}
            />
          </Box>
        </Flex>
        <Text pt="20px">
          I give permission for Pain Care Specialists of Oregon, LLC, or their
          designated representative to communicate informat ion about my medical
          condition and treatment to:
        </Text>
        <VStack spacing="4" pt="20px">
          <Box w="100%">
            {formView?.permission.map((item, index) => (
              <HStack w="100%">
                <Box
                  w="100%"
                  key={item?.id}
                  bgImage={`url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23B7DDE8FF' stroke-width='3' stroke-dasharray='19%2c 16' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e")`}
                  p="1em"
                  borderRadius="16px"
                  mt="15px"
                >
                  <VStack>
                    <Controller
                      name={`permission.${index}.name`}
                      control={control}
                      defaultValue=""
                      render={props => (
                        <InputGroup>
                          <InputLeftAddon
                            children="Name"
                            bg="#B7DDE9"
                            color="#FFFFFF"
                          />
                          <Input
                            _readOnly={{
                              border: '1px solid #ccc',
                            }}
                            variant="no-effects"
                            bg="#F9F9F9"
                            border="1px solid black"
                            isReadOnly
                            defaultValue={formView?.permission[index]?.name}
                          />
                        </InputGroup>
                      )}
                    />
                    <Controller
                      name={`permission.${index}.phone`}
                      control={control}
                      defaultValue=""
                      render={props => (
                        <InputGroup>
                          <InputLeftAddon
                            children="Phone"
                            bg="#B7DDE9"
                            color="#FFFFFF"
                          />
                          <Input
                            _readOnly={{
                              border: '1px solid #ccc',
                            }}
                            variant="no-effects"
                            bg="#F9F9F9"
                            border="1px solid black"
                            isReadOnly
                            defaultValue={formView?.permission[index]?.phone}
                          />
                        </InputGroup>
                      )}
                    />
                    <Controller
                      name={`permission.${index}.relationship`}
                      control={control}
                      defaultValue=""
                      render={props => (
                        <InputGroup>
                          <InputLeftAddon
                            children="Relationship"
                            bg="#B7DDE9"
                            color="#FFFFFF"
                          />
                          <Input
                            _readOnly={{
                              border: '1px solid #ccc',
                            }}
                            variant="no-effects"
                            bg="#F9F9F9"
                            border="1px solid black"
                            isReadOnly
                            defaultValue={
                              formView?.permission[index]?.relationship
                            }
                          />
                        </InputGroup>
                      )}
                    />
                  </VStack>
                </Box>
              </HStack>
            ))}
          </Box>
        </VStack>
        <Grid
          templateColumns={{ base: 'repeat(1, 1fr)', xl: 'repeat(2, 1fr)' }}
          alignItems="end"
          gap={0}
          h="max-content"
        >
          <Controller
            name="namePatient"
            control={control}
            defaultValue=""
            render={props => (
              <FormControl>
                <GridItem
                  onChange={props.onChange}
                  value={props.value}
                  onBlur={props.onBlur}
                  w="100%"
                >
                  <VStack spacing="0px">
                    <Center h={{ base: '80px' }}>
                      <Text fontWeight="bold" fontSize="15">
                        Printed Name of Patient
                      </Text>
                    </Center>
                    <HStack h="40px" w="100%" flexDirection="row">
                      <Center w="100%">
                        <Flex ml="0px">
                          <Text>{formView.namePatient}</Text>
                        </Flex>
                      </Center>
                    </HStack>
                  </VStack>
                  <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
                  <VStack spacing="0px">
                    <Center h={{ base: '80px' }}>
                      <Text fontWeight="bold" fontSize="15">
                        Signature
                      </Text>
                    </Center>
                    <HStack h="40px" w="100%" flexDirection="row">
                      <Center w="100%">
                        <Flex ml="0px">
                          <SwitchInputComponent
                            formView={formView.signaturePatient || ''}
                          />
                        </Flex>
                      </Center>
                    </HStack>
                  </VStack>
                  <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
                </GridItem>
                <Center></Center>
              </FormControl>
            )}
          />
          <Controller
            name="namePatientPermission"
            control={control}
            defaultValue=""
            render={props => (
              <FormControl>
                <GridItem
                  onChange={props.onChange}
                  value={props.value}
                  onBlur={props.onBlur}
                  w="100%"
                >
                  <VStack spacing="0px">
                    <Center h={{ base: '80px' }}>
                      <Text fontWeight="bold" fontSize="15">
                        Printed Name of Representative
                      </Text>
                    </Center>
                    <HStack h="40px" w="100%" flexDirection="row">
                      <Center w="100%">
                        <Flex ml="0px">
                          <Text>{formView.namePatientPermission}</Text>
                        </Flex>
                      </Center>
                    </HStack>
                  </VStack>
                  <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
                  <VStack spacing="0px">
                    <Center h={{ base: '80px' }}>
                      <Text fontWeight="bold" fontSize="15">
                        Signature
                      </Text>
                    </Center>
                    <HStack h="40px" w="100%" flexDirection="row">
                      <Center w="100%">
                        <Flex ml="0px">
                          <SwitchInputComponent
                            formView={
                              formView.signaturePatientRepresentative || ''
                            }
                          />
                        </Flex>
                      </Center>
                    </HStack>
                  </VStack>
                  <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
                </GridItem>
                <Center></Center>
              </FormControl>
            )}
          />

          <GridItem w="100%">
            <VStack spacing="0px">
              <Center h={{ base: '80px' }}>
                <Text fontWeight="bold" fontSize="15">
                  Date
                </Text>
              </Center>

              <Center h="40px">
                <Text>
                  {new Date().toLocaleDateString('en-US', { hour12: false })}
                </Text>
              </Center>
            </VStack>
            <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
          </GridItem>
          <GridItem w="100%">
            <VStack spacing="0px">
              <Center h={{ base: '80px' }}>
                <Text fontWeight="bold" fontSize="15">
                  Date
                </Text>
              </Center>

              <Center h="40px">
                <Text>
                  {new Date().toLocaleDateString('en-US', { hour12: false })}
                </Text>
              </Center>
            </VStack>
            <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
          </GridItem>
        </Grid>
      </Box>
      <Center pt="2rem">{`18/${totalNonWebEnabledPage}`}</Center>
    </CardContent>
  )
}

export { SignaturePermission }
