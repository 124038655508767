/* eslint-disable react-hooks/exhaustive-deps */
import { ChevronRightIcon, SearchIcon } from '@chakra-ui/icons'
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Center,
  Container,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Spacer,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore'
import { search } from 'ss-search'
import { BsViewStacked } from 'react-icons/bs'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import { db } from '../../config/firebase'
import renderDate from '../../config/renderDate'

function PatienList() {
  const params = useParams()
  const [loading, setLoading] = useState(false)
  const [patientForm, setPatientForm] = useState([])
  const [searchText, setSearchText] = useState('')
  const [patientFilter, setPatientFilter] = useState([])
  const [filter, setFilter] = useState({
    type: 'All',
    facility: 'All',
    status: 'Pending',
  })

  useEffect(() => {
    let unsubscribePatientForm
    setLoading(true)
    let querys = []
    if (filter.type !== 'All') {
      querys.push(where('type', '==', filter.type))
    }
    if (filter.status !== 'All') {
      querys.push(where('status', '==', filter.status))
    }
    if (filter.facility !== 'All') {
      querys.push(where('facility', '==', filter.facility))
    }

    const queryPatientForm = query(
      collection(db, 'PatientForm'),
      ...querys,
      orderBy('createdAt', 'desc')
    )

    unsubscribePatientForm = onSnapshot(queryPatientForm, snapShot => {
      let data = []

      snapShot.forEach(value => {
        let formData = value.data()
        data.push({ ...formData, id: value.id })
      })
      setPatientForm(data)
      setPatientFilter(data)
      setLoading(false)
    })

    return () => {
      unsubscribePatientForm()
    }
  }, [params, filter])

  function onSearch() {
    const searchField = ['name']
    const key = searchText

    const result = search(patientForm, searchField, key)
    setPatientFilter(result)
  }

  useEffect(() => {
    if (searchText !== '') {
      onSearch()
    }
  }, [searchText])

  return (
    <Container maxW="100%" mt="5" mb="10">
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
        bg="gray.100"
        p="1.5"
        borderRadius="0.3em"
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/dashboard">
            Dashbaord
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/patient">
            Questionnaire
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <>
        <Flex mt="2">
          <Box pt="2" fontSize="18" fontWeight="bold">
            Questionnaire
          </Box>
          <Spacer />
        </Flex>
        <Flex
          flexDirection={['column', 'column', 'row', 'row', 'row', 'row']}
          mt="2"
          w="100%"
          justifyContent="space-between"
          alignItems="end"
        >
          <InputGroup w={['100%', '100%', '225px', '225px', '225px', '225px']}>
            <InputLeftElement>
              <SearchIcon />
            </InputLeftElement>
            <Input
              w={['100%', '100%', '225px', '225px', '225px', '225px']}
              placeholder={'Name'}
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
            />
          </InputGroup>
          <Flex
            flexDirection={['column', 'column', 'row', 'row', 'row', 'row']}
            w={['100%', '100%', '450px', '450px', '500px', '500px']}
          >
            <Box mt={[2, 2, 0, 0, 0, 0]} w={'100%'}>
              <Text mb="4px">Form</Text>
              <Select
                w={'100%'}
                onChange={e => setFilter({ ...filter, type: e.target?.value })}
                defaultValue={filter.type}
              >
                <option value={'All'}>All</option>
                <option value={'PHQ-9-GAD-7'}>PHQ-9 GAD-7</option>
                <option value={'Oswestry Disability'}>
                  Oswestry Disability
                </option>
                <option value={'SOAPP-R'}>SOAPP-R</option>
              </Select>
            </Box>

            <Box mt={[2, 2, 0, 0, 0, 0]} mx={[0, 0, 1, 1, 1, 1]} w={'100%'}>
              <Text mb="4px">Facility</Text>
              <Select
                w={'100%'}
                onChange={e =>
                  setFilter({ ...filter, facility: e.target?.value })
                }
                defaultValue={filter.facility}
              >
                <option value={'All'}>All</option>
                <option value={'PCSO'}>PCSO</option>
                <option value={'OSSC'}>OSSC</option>
              </Select>
            </Box>

            <Box mt={[2, 2, 0, 0, 0, 0]} w={'100%'}>
              <Text mb="4px">Status</Text>
              <Select
                w={'100%'}
                onChange={e =>
                  setFilter({ ...filter, status: e.target?.value })
                }
                defaultValue={filter.status}
              >
                <option value={'All'}>All</option>
                <option value={'Pending'}>Pending</option>
                <option value={'Archived'}>Archived</option>
              </Select>
            </Box>
          </Flex>
        </Flex>
        {loading ? (
          <Center w="100%" h="20rem">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="lg"
            />
          </Center>
        ) : (
          <Table size="sm" mt="10" overflowX="auto">
            <Thead>
              <Tr>
                <Th>Date</Th>
                <Th>Name</Th>
                <Th>Form</Th>
                <Th>Facility</Th>
                <Th>Status</Th>
                <Th textAlign="center">Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {patientFilter.map((form, index) => {
                return (
                  <Tr key={index}>
                    <Td>{renderDate(form.createdAt)}</Td>
                    <Td>{form.name}</Td>
                    <Td>{form.type}</Td>
                    <Td>{form.facility}</Td>
                    <Td>{form.status}</Td>
                    <Td textAlign="center">
                      <Button
                        as={Link}
                        to={`/patient/${form.id}`}
                        size="xs"
                        leftIcon={<BsViewStacked />}
                      >
                        View
                      </Button>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        )}
      </>
    </Container>
  )
}

export default PatienList
