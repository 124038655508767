import { Box, Button, ButtonGroup, Heading, HStack } from '@chakra-ui/react'
import moment from 'moment'
import React from 'react'

import {
  Text,
  Image,
  PDFViewer,
  StyleSheet,
  Document,
  Page,
  View,
  Svg,
  Ellipse,
} from '@react-pdf/renderer'
import { gadQuizData, phqQuizData } from '../form/formPHQGAD'

export default function RenderPHQGADPDF({ formData, changeStatus }) {
  const checked = (key, value) => {
    if (key !== value) return ''
    return <Image style={styles.imgcheck} src="/check.png" />
  }

  const calPHQStyle = () => {
    if (formData?.phqScoreType === 'Mild') return -50
    if (formData?.phqScoreType === 'Moderate') return 100
    if (formData?.phqScoreType === 'Moderately Severe') return 245
    if (formData?.phqScoreType === 'Severe Depression') return 385
  }

  const calGADStyle = () => {
    if (formData?.gadScoreType === 'Mild') return -50
    if (formData?.gadScoreType === 'Moderate') return 100
    if (formData?.gadScoreType === 'Moderately Severe Anxiety') return 245
    if (formData?.gadScoreType === 'Severe Anxiety') return 385
  }

  const styles = StyleSheet.create({
    pdfView: {
      width: '100%',
      height: window.innerHeight,
      backgroundColor: '#fff',
      fontFamily: 'Open Sans',
    },
    page: {
      width: '100%',
      flexDirection: 'column',
      backgroundColor: '#fff',
      alignItems: 'center',
      paddingLeft: 40,
      paddingRight: 40,
    },
    section: {
      width: '100%',
      marginBottom: 10,
      padding: 10,
      alignItems: 'center',
    },
    sectionImg: {
      width: '100%',
      marginTop: 25,
      padding: 0,
      alignItems: 'center',
    },
    img: {
      width: '150px',
      height: 'auto',
      objectFit: 'cover',
    },
    textTitle: {
      paddingHorizontal: 80,
      fontSize: '12px',
      textAlign: 'center',
    },
    sectionTwo: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    boxmonthyearIn: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
    textmonthyear: {
      fontSize: '12px',
    },
    textDate: {
      fontSize: '12px',
      borderBottom: 1,
      textAlign: 'center',
      width: '80px',
    },
    textName: {
      fontSize: '12px',
      borderBottom: 1,
      textAlign: 'center',
      width: '160px',
    },
    boxHelperTable: {
      width: '100%',
      display: 'flex',
      marginTop: '5px',
      flexDirection: 'row',
    },
    boxHelperTableSecond: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
    },
    textHelperTable: {
      fontSize: '10px',
    },
    textHelperTableUnderline: {
      fontSize: '10px',
      textDecoration: 'underline',
    },
    sectionthree: {
      width: '100%',
      marginBottom: 10,
      flexDirection: 'column',
      justifyContent: 'start',
      alignItems: 'start',
    },
    table: {
      display: 'table',
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
    },
    tableRow: {
      margin: 'auto',
      flexDirection: 'row',
    },
    tableColTitleHeader: {
      width: '55%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      display: 'flex',
      justifyContent: 'center',
    },
    tableColTitle: {
      width: '55%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableColTitleTotal: {
      width: '55%',
      borderStyle: 'solid',
      borderWidth: 2,
      borderLeftWidth: 0,
      borderTopWidth: 2,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    tableColOne: {
      width: '10%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableColFirstPHQ: {
      paddingTop: 5,
      width: '10%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableColOneTotal: {
      width: '10%',
      borderStyle: 'solid',
      borderWidth: 2,
      borderLeftWidth: 0,
      borderTopWidth: 2,
    },
    tableColTwo: {
      width: '15%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableColTwoTotal: {
      width: '15%',
      borderStyle: 'solid',
      borderWidth: 2,
      borderLeftWidth: 0,
      borderTopWidth: 2,
    },
    tableColThree: {
      width: '10%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableColThreeTotal: {
      width: '10%',
      borderStyle: 'solid',
      borderWidth: 2,
      borderLeftWidth: 0,
      borderTopWidth: 2,
    },
    tableCellHeader: {
      marginTop: 2,
      fontSize: 10,
      textAlign: 'center',
    },
    tableCell: {
      margin: 'auto',
      marginY: 1,
      fontSize: 10,
    },
    tableCellQuestion: {
      margin: '1px 1px',
      fontSize: 10,
    },
    imgcheck: {
      width: '7px',
      height: '7px',
    },
    sectionTotalScore: {
      width: '100%',
      marginBottom: 5,
    },
    boxTotalScore: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'row',
    },
    sectionBox: {
      width: '100%',
      position: 'relative',
    },
    sectionResult: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      marginBottom: 10,
      marginTop: 10,
    },
    textResult: {
      fontSize: 10,
      textAlign: 'start',
    },
    circlePHQ: {
      position: 'absolute',
      left: calPHQStyle(),
    },
    circleGAD: {
      position: 'absolute',
      left: calGADStyle(),
    },
    boxTextRev: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'row',
      marginTop: '20px',
      marginBottom: '10px',
      fontSize: '9px',
    },
    boxTextFooter: {
      paddingHorizontal: '30px',
    },
    textFooter: {
      fontSize: '8px',
      textAlign: 'center',
    },
  })

  return (
    <Box mt="5">
      <Box textAlign="right" mt="5">
        <HStack justifyContent="space-between" mb={8}>
          <Box>
            <Heading as="h4" size="md" textAlign="start">
              Patient Name : {formData?.name || ''}
            </Heading>
            <Heading as="h4" size="md" textAlign="start">
              Date of Birth :{' '}
              {moment(formData?.dateOfBirth).format('MM/DD/YYYY')}
            </Heading>
          </Box>
          <HStack spacing={'2rem'}>
            <ButtonGroup size="sm" isAttached>
              <Button
                colorScheme="green"
                variant={formData?.status === 'Archived' ? 'solid' : 'outline'}
                mr="-px"
                onClick={() => changeStatus(formData?.id, 'Archived')}
              >
                Archived
              </Button>
            </ButtonGroup>
          </HStack>
        </HStack>
      </Box>
      <PDFViewer style={styles.pdfView}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.sectionImg}>
              <Image style={styles.img} src="/paincare-web.png" />
            </View>
            <View style={styles.section}>
              <Text style={styles.textTitle}>
                Patient Health Questionnaire and General Anxiety Disorder (PHQ-9
                and GAD-7)
              </Text>
            </View>
            <View style={styles.sectionTwo}>
              <View style={styles.boxmonthyearIn}>
                <Text style={styles.textmonthyear}>Date :</Text>
                <Text style={styles.textDate}>
                  {formData?.date
                    ? `${moment(formData?.date).format('MM/DD/YYYY')}`
                    : '-'}
                </Text>
              </View>
              <View style={styles.boxmonthyearIn}>
                <Text style={styles.textmonthyear}>Patient name :</Text>
                <Text style={styles.textName}>{formData?.name || '-'}</Text>
              </View>
              <View style={styles.boxmonthyearIn}>
                <Text style={styles.textmonthyear}>Date of birth :</Text>
                <Text style={styles.textDate}>
                  {formData?.dateOfBirth
                    ? `${moment(formData?.dateOfBirth).format('MM/DD/YYYY')}`
                    : '-'}
                </Text>
              </View>
            </View>
            <View style={styles.boxHelperTable}>
              <View>
                <Text style={styles.textHelperTable}>Over the </Text>
              </View>
              <View>
                <Text style={styles.textHelperTableUnderline}>
                  last 2 weeks
                </Text>
              </View>
              <View>
                <Text style={styles.textHelperTable}>
                  , how often have you been bothered by any of the following
                  problems?
                </Text>
              </View>
            </View>
            <View style={styles.boxHelperTableSecond}>
              <Text style={styles.textHelperTable}>
                Please check your answers.
              </Text>
            </View>
            <View style={styles.sectionthree}>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableColTitleHeader}>
                    <Text style={styles.tableCellQuestion}>PHQ-9</Text>
                  </View>
                  <View style={styles.tableColFirstPHQ}>
                    <Text style={styles.tableCellHeader}>Not at all</Text>
                  </View>
                  <View style={styles.tableColOne}>
                    <Text style={styles.tableCellHeader}>Several days</Text>
                  </View>
                  <View style={styles.tableColTwo}>
                    <Text style={styles.tableCellHeader}>
                      More than half the days
                    </Text>
                  </View>
                  <View style={styles.tableColThree}>
                    <Text style={styles.tableCellHeader}>Nearly every day</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableColTitle}>
                    <Text style={styles.tableCellQuestion}>Score</Text>
                  </View>
                  <View style={styles.tableColOne}>
                    <Text style={styles.tableCell}>0</Text>
                  </View>
                  <View style={styles.tableColOne}>
                    <Text style={styles.tableCell}>1</Text>
                  </View>
                  <View style={styles.tableColTwo}>
                    <Text style={styles.tableCell}>2</Text>
                  </View>
                  <View style={styles.tableColThree}>
                    <Text style={styles.tableCell}>3</Text>
                  </View>
                </View>
                {phqQuizData.map((item, index) => (
                  <View key={index} style={styles.tableRow}>
                    <View style={styles.tableColTitle}>
                      <Text style={styles.tableCellQuestion}>{item}</Text>
                    </View>
                    <View style={styles.tableColOne}>
                      <Text style={styles.tableCell}>
                        {checked(formData.phqAnswers[index], '0')}
                      </Text>
                    </View>
                    <View style={styles.tableColOne}>
                      <Text style={styles.tableCell}>
                        {checked(formData.phqAnswers[index], '1')}
                      </Text>
                    </View>
                    <View style={styles.tableColTwo}>
                      <Text style={styles.tableCell}>
                        {checked(formData.phqAnswers[index], '2')}
                      </Text>
                    </View>
                    <View style={styles.tableColThree}>
                      <Text style={styles.tableCell}>
                        {checked(formData.phqAnswers[index], '3')}
                      </Text>
                    </View>
                  </View>
                ))}
                <View style={styles.tableRow}>
                  <View style={styles.tableColTitleTotal}>
                    <Text style={styles.tableCellQuestion}>
                      The score for each column
                    </Text>
                  </View>
                  <View style={styles.tableColOneTotal}>
                    <Text style={styles.tableCell}>0</Text>
                  </View>
                  <View style={styles.tableColOneTotal}>
                    <Text style={styles.tableCell}>
                      {formData.phqScore.resultOne || 0}
                    </Text>
                  </View>
                  <View style={styles.tableColTwoTotal}>
                    <Text style={styles.tableCell}>
                      {formData.phqScore.resultTwo || 0}
                    </Text>
                  </View>
                  <View style={styles.tableColThreeTotal}>
                    <Text style={styles.tableCell}>
                      {formData.phqScore.resultThree || 0}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.sectionTotalScore}>
              <View style={styles.boxTotalScore}>
                <Text style={styles.textmonthyear}>
                  Total Score (add your column scores) :
                </Text>
                <Text style={styles.textDate}>
                  {formData.phqScore.total || 0}
                </Text>
              </View>
            </View>
            <View style={styles.sectionBox}>
              <View style={styles.sectionResult}>
                <Text style={styles.textResult}>Not difficult at all</Text>
                <Text style={styles.textResult}>Somewhat difficult</Text>
                <Text style={styles.textResult}>Very Difficult</Text>
                <Text style={styles.textResult}>Extremely Difficult</Text>
              </View>
              <View style={styles.circlePHQ}>
                <Svg viewBox="0 0 600 300">
                  <Ellipse cx="100" cy="20" rx="80" ry="15" stroke="gray" />
                </Svg>
              </View>
            </View>
            <View>
              <Text>
                ----------------------------------------------------------------------------------------
              </Text>
            </View>
            <View style={styles.boxHelperTable}>
              <View>
                <Text style={styles.textHelperTable}>Over the </Text>
              </View>
              <View>
                <Text style={styles.textHelperTableUnderline}>
                  last 2 weeks
                </Text>
              </View>
              <View>
                <Text style={styles.textHelperTable}>
                  , how often have you been bothered by any of the following
                  problems?
                </Text>
              </View>
            </View>
            <View style={styles.boxHelperTableSecond}>
              <Text style={styles.textHelperTable}>
                Please check your answers.
              </Text>
            </View>
            <View style={styles.sectionthree}>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableColTitleHeader}>
                    <Text style={styles.tableCellQuestion}>GAD-7</Text>
                  </View>
                  <View style={styles.tableColOne}>
                    <Text style={styles.tableCellHeader}>Not at all sure</Text>
                  </View>
                  <View style={styles.tableColOne}>
                    <Text style={styles.tableCellHeader}>Several days</Text>
                  </View>
                  <View style={styles.tableColTwo}>
                    <Text style={styles.tableCellHeader}>
                      Over half the days
                    </Text>
                  </View>
                  <View style={styles.tableColThree}>
                    <Text style={styles.tableCellHeader}>Nearly every day</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableColTitle}>
                    <Text style={styles.tableCellQuestion}>Score</Text>
                  </View>
                  <View style={styles.tableColOne}>
                    <Text style={styles.tableCell}>0</Text>
                  </View>
                  <View style={styles.tableColOne}>
                    <Text style={styles.tableCell}>1</Text>
                  </View>
                  <View style={styles.tableColTwo}>
                    <Text style={styles.tableCell}>2</Text>
                  </View>
                  <View style={styles.tableColThree}>
                    <Text style={styles.tableCell}>3</Text>
                  </View>
                </View>
                {gadQuizData.map((item, index) => (
                  <View key={index} style={styles.tableRow}>
                    <View style={styles.tableColTitle}>
                      <Text style={styles.tableCellQuestion}>{item}</Text>
                    </View>
                    <View style={styles.tableColOne}>
                      <Text style={styles.tableCell}>
                        {checked(formData.gadAnswers[index], '0')}
                      </Text>
                    </View>
                    <View style={styles.tableColOne}>
                      <Text style={styles.tableCell}>
                        {checked(formData.gadAnswers[index], '1')}
                      </Text>
                    </View>
                    <View style={styles.tableColTwo}>
                      <Text style={styles.tableCell}>
                        {checked(formData.gadAnswers[index], '2')}
                      </Text>
                    </View>
                    <View style={styles.tableColThree}>
                      <Text style={styles.tableCell}>
                        {checked(formData.gadAnswers[index], '3')}
                      </Text>
                    </View>
                  </View>
                ))}
                <View style={styles.tableRow}>
                  <View style={styles.tableColTitleTotal}>
                    <Text style={styles.tableCellQuestion}>
                      The score for each column
                    </Text>
                  </View>
                  <View style={styles.tableColOneTotal}>
                    <Text style={styles.tableCell}>0</Text>
                  </View>
                  <View style={styles.tableColOneTotal}>
                    <Text style={styles.tableCell}>
                      {formData.gadScore.resultOne || 0}
                    </Text>
                  </View>
                  <View style={styles.tableColTwoTotal}>
                    <Text style={styles.tableCell}>
                      {formData.gadScore.resultTwo || 0}
                    </Text>
                  </View>
                  <View style={styles.tableColThreeTotal}>
                    <Text style={styles.tableCell}>
                      {formData.gadScore.resultThree || 0}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.sectionTotalScore}>
              <View style={styles.boxTotalScore}>
                <Text style={styles.textmonthyear}>
                  Total Score (add your column scores) :
                </Text>
                <Text style={styles.textDate}>
                  {formData.gadScore.total || 0}
                </Text>
              </View>
            </View>
            <View style={styles.sectionBox}>
              <View style={styles.sectionResult}>
                <Text style={styles.textResult}>Not difficult at all</Text>
                <Text style={styles.textResult}>Somewhat difficult</Text>
                <Text style={styles.textResult}>Very Difficult</Text>
                <Text style={styles.textResult}>Extremely Difficult</Text>
              </View>
              <View style={styles.circleGAD}>
                <Svg viewBox="0 0 600 300">
                  <Ellipse cx="100" cy="20" rx="80" ry="15" stroke="gray" />
                </Svg>
              </View>
            </View>
            <View style={styles.boxTextRev}>
              <Text>UHS Rev 4/2020</Text>
            </View>
            <View style={styles.boxTextFooter}>
              <Text style={styles.textFooter}>
                Developed by Drs. Robert L. Spitzer, Janet B.W. Williams, Kurt
                Kroenke and colleagues, with an educational grant from Pfizer
                Inc. No permission required to reproduce, translate, display or
                distribute, 1999.
              </Text>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </Box>
  )
}
