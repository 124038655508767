import { Text, View } from '@react-pdf/renderer'
import React from 'react'

function SeriousThreatsHealthSafety({ styles }) {
  return (
    <View
      style={{
        width: '100%',
        gap: '12px',
      }}
    >
      <View>
        <Text style={{ lineHeight: 1.5, textAlign: 'justify' }}>
          <Text style={styles.title}>Serious Threats to Health or Safety.</Text>
          {` `}
          <Text>
            Our practice may use and disclose your PHI when necessary to reduce
            or prevent a serious threat to your health and safety or health and
            safety of another individual or the public. Under these
            circumstances, we will only make disclosures to a person or
            organization able to help prevent the threat.
          </Text>
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.5, textAlign: 'justify' }}>
          <Text style={styles.title}>National Security.</Text>
          {` `}
          <Text>
            Our practice may disclose your PHI to federal officials for
            intelligence and national security activities authorized by law. We
            also may disclose your PHI to federal officials in order to protect
            the President, other officials or foreign heads of state, or to
            conduct investigations.
          </Text>
        </Text>
      </View>
      <View style={{ paddingTop: 4, textAlign: 'center', fontSize: 10 }}>
        <Text style={styles.title}>YOUR RIGHTS REGARDING YOUR PHI</Text>
      </View>
      <View style={{ marginTop: '-4px' }}>
        <Text style={{ lineHeight: 1.5, textAlign: 'justify' }}>
          <Text style={styles.title}>Confidential Communications.</Text>
          {` `}
          <Text>
            You have the right to request that our practice communicate with you
            about your health and related issues in a particular manner or at a
            certain location. For example, you may ask that we contact you at
            home, rather than work, or to send communications in a sealed
            envelope instead of a postcard. You may be asked to pay for
            additional costs incurred to comply with your request. In order to
            request a type of confidential communication, you must make a
            written request to our Privacy Officer specifying the requested
            method of contact, or the location where you wish to be contacted.
            Our practice will accommodate reasonable requests. You do not need
            to give a reason for your request.
          </Text>
        </Text>
        <Text style={{ paddingTop: 8, lineHeight: 1.5, textAlign: 'justify' }}>
          <Text style={styles.title}>Requesting Restrictions.</Text>
          {` `}
          <Text>
            You have the right to request a restriction in our use or disclosure
            of your PHI for treatment, payment, or health care operations.
            Additionally, you have the right to request that we restrict our
            disclosure of your PHI only to certain individuals such as family
            members and friends involved in your care or the payment of your
            care. You may request to not have trainees or others involved in
            your care. We are not required to agree to your request; however, if
            we do agree, we are bound by our agreement except when otherwise
            required by law in emergencies, or when the information is necessary
            to treat you. In order to request a restriction in our use or
            disclosure of your PHI, you must make your request in writing to our
            Privacy Officer. Your request must describe in a clear concise
            fashion:
          </Text>
        </Text>
        <View style={{ gap: '2px', paddingLeft: 20, marginTop: 4 }}>
          <View style={styles.gapText}>
            <Text style={styles.fontDot}>•</Text>
            <Text>The information you wish restricted</Text>
          </View>
          <View style={styles.gapText}>
            <Text style={styles.fontDot}>•</Text>
            <Text>
              Whether you are requesting to limit our practice's use,
              disclosure, or both; and
            </Text>
          </View>
          <View style={styles.gapText}>
            <Text style={styles.fontDot}>•</Text>
            <Text>To whom you want the limits to apply</Text>
          </View>
        </View>
        <View>
          <Text
            style={{ lineHeight: 1.5, textAlign: 'justify', paddingTop: 8 }}
          >
            <Text style={styles.title}>Inspection Copies.</Text>
            {` `}
            <Text>
              You have the right to inspect and obtain a copy of the PHI that
              may be used to make decisions about you. You must submit your
              request in writing to Privacy Officer 2480 Liberty St NE, Ste 180,
              Salem, OR 97301. Our practice may charge a fee for the costs of
              copying associated with your request.
            </Text>
          </Text>
        </View>
        <View>
          <Text
            style={{ lineHeight: 1.5, textAlign: 'justify', paddingTop: 8 }}
          >
            <Text style={styles.title}>Amendment.</Text>
            {` `}
            <Text>
              You may ask to amend your health information if you believe it is
              incorrect or incomplete, and you may request an amendment for as
              long as the information is kept by or for our practice. To request
              an amendment, your request must be made in writing and submitted
              to our Privacy Officer. You must provide it with a reason that
              supports your request for amendment. Our practice will deny your
              request if you fail to submit in writing your request and the
              reason supporting your request. Also, we may deny your request if
              you ask us to amend information that is in our opinion:
            </Text>
          </Text>
          <Text
            style={{ lineHeight: 1.5, textAlign: 'justify', paddingTop: 8 }}
          >
            (a) accurate and complete; (b) not part of the PHI which you would
            be permitted to inspect and copy; or (d) not created by our
            practice.
          </Text>
        </View>
        <View>
          <Text
            style={{ lineHeight: 1.5, textAlign: 'justify', paddingTop: 8 }}
          >
            <Text style={styles.title}>Accounting of Disclosures.</Text>
            {` `}
            <Text>
              All of our patients have the right to request an "accounting of
              disclosures: An "accounting of disclosures" is a list of certain
              disclosures required to list your PHI as part of the routine
              patient care, payment, or health operations in our practice for
              paper records. Example of routine patient care, payment or health
              operations excluded from an accounting from paper charts include
              the doctor sharing information with the nurse, billing department
              using your information to file your insurance claim, and
              discussion of your PHI for purposes of improving our health care
              delivery system
            </Text>
          </Text>
        </View>
      </View>
    </View>
  )
}

export default SeriousThreatsHealthSafety
