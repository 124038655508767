import React, { useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import {
  Container,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  useToast,
  HStack,
  Center,
  Textarea,
  Flex,
  Spacer,
  Icon,
} from '@chakra-ui/react'
import { DatePicker } from 'antd'

import { ChevronRightIcon } from '@chakra-ui/icons'
import { FaCloudDownloadAlt } from 'react-icons/fa'
import { BiCloudUpload } from 'react-icons/bi'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { addDoc, collection, updateDoc, doc } from 'firebase/firestore'
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage'
import { db, storage } from '../../config/firebase'

function CMERequestForm({ user }) {
  const { handleSubmit, reset, register, errors, control } = useForm()
  const toast = useToast()
  const [isDisabled, setIsDisabled] = useState(false)
  const [fileUpload, setFileUpload] = useState(null)
  const hiddenFileInput = useRef()

  function handleUploadClick() {
    hiddenFileInput.current.click()
  }

  async function handleUploadChange(e) {
    const file = e.target.files[0]
    if (file) {
      setFileUpload(file)
    }
  }

  function submitData(value) {
    setIsDisabled(true)
    value.date = value.date.format('YYYY-MM-DD')
    if (fileUpload) {
      addDoc(collection(db, 'EmployeeForm'), {
        ...value,
        name: user.fullName,
        jobTitle: user.jobTitle,
        type: 'CME Request',
        createdAt: new Date(),
        notification: true,
        status: 'Pending',
      }).then(async ({ path, id }) => {
        const fileRef = ref(
          storage,
          `EmployeeForm/${user.uid}/CMERequestForm/${id}/${fileUpload.name}`
        )

        uploadBytes(fileRef, fileUpload).then(async () => {
          const downloadURL = await getDownloadURL(fileRef)
          let employeeFormRef = doc(db, path)
          updateDoc(employeeFormRef, { attachFile: downloadURL }).then(() => {
            toast({
              title: 'Submit Successfully',
              description: 'Form has been submmited.',
              status: 'success',
              duration: 3000,
              isClosable: true,
            })
            setIsDisabled(false)
            setFileUpload(null)
            hiddenFileInput.current.value = ''
            reset()
          })
        })
      })
    } else {
      addDoc(collection(db, 'EmployeeForm'), {
        ...value,
        name: user.fullName,
        jobTitle: user.jobTitle,
        type: 'CME Request',
        createdAt: new Date(),
        notification: true,
        status: 'Pending',
      }).then(() => {
        toast({
          title: 'Submit Successfully',
          description: 'Form has been submmited.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setIsDisabled(false)
        reset()
      })
    }
  }

  return (
    <Container maxW="100%" mt="5">
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
        bg="gray.100"
        p="1.5"
        borderRadius="0.3em"
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/dashboard">
            Dashbaord
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/cme-request-form">
            CME Request Form
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Box mt="30" mb="100" width={['100']}>
        <Flex mt="10" mb="10">
          <Box pt="2" fontSize="18" fontWeight="bold">
            CME Request Form
          </Box>
          <Spacer />
          <Button
            onClick={() => {
              window.open(
                'https://firebasestorage.googleapis.com/v0/b/paincare-a9e20.appspot.com/o/Downloads%2FForms%2FCME-Request-Form.pdf?alt=media&token=e52b3275-4d3e-4291-a1cb-6957e22b7aff',
                '_blank'
              )
            }}
            leftIcon={<FaCloudDownloadAlt />}
            size="sm"
          >
            PDF
          </Button>
        </Flex>
        <form onSubmit={handleSubmit(submitData)}>
          <HStack>
            <Input
              type="file"
              display="none"
              ref={hiddenFileInput}
              onChange={handleUploadChange}
            />
            <FormControl id="name" isReadOnly>
              <FormLabel>NAME</FormLabel>
              <Input name="name" defaultValue={user.fullName} />
            </FormControl>
            <FormControl id="jobTitle" isReadOnly>
              <FormLabel>JOB TITLE</FormLabel>
              <Input name="jobTitle" defaultValue={user.jobTitle} />
            </FormControl>
          </HStack>
          <Center bg="black" mt="30" mb="30" h="35">
            <Text fontWeight="bold" color="white">
              DATE AND AMOUNT REQUESTED
            </Text>
          </Center>
          <FormControl
            id="date"
            mb="5"
            width={['100%', '100%', '100%', '30%']}
            isInvalid={errors.date}
          >
            <FormLabel>DATE</FormLabel>
            <Controller
              render={props => (
                <DatePicker
                  onChange={props.onChange}
                  size="large"
                  format="YYYY-MM-DD"
                />
              )}
              name="date"
              control={control}
              defaultValue={null}
              rules={{ required: 'Date required.' }}
            />
            <FormErrorMessage>
              {errors.date && errors.date.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            id="eventAmount"
            isInvalid={errors.eventAmount}
            mb="5"
            width={['100%', '100%', '100%', '50%']}
          >
            <FormLabel>EVENT/AMOUNT</FormLabel>
            <Textarea
              name="eventAmount"
              rows="6"
              ref={register({ required: 'Event Amount required' })}
            />
            <FormErrorMessage>
              {errors.eventAmount && errors.eventAmount.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '50%']}
            isInvalid={errors.hoursOfPtoRequested}
          >
            <FormLabel>HOURS OF PTO REQUESTED</FormLabel>
            <Input
              name="hoursOfPtoRequested"
              ref={register({ required: 'Hours OF PTO Requested required.' })}
            />
            <FormErrorMessage>
              {errors.hoursOfPtoRequested && errors.hoursOfPtoRequested.message}
            </FormErrorMessage>
          </FormControl>
          {fileUpload ? (
            <Box mb="5">
              {fileUpload?.name}&nbsp;
              <Icon
                fontSize="lg"
                as={AiOutlineCloseCircle}
                cursor="pointer"
                onClick={() => {
                  setFileUpload(null)
                  hiddenFileInput.current.value = ''
                }}
              />
            </Box>
          ) : (
            <Button
              variant="outline"
              leftIcon={<BiCloudUpload />}
              mb="5"
              onClick={() => handleUploadClick()}
            >
              Upload File
            </Button>
          )}

          <Text mb="5" color="red.500">
            ** Employee certifies that all information provided is true and
            complete to the best of his/her knowledge. Falsification of this
            form is grounds for disciplinary action, up to and including
            dismissal.
          </Text>
          <Center mt="10">
            <Button
              colorScheme="blue"
              width={['100%', '100%', '100%', '10%']}
              isLoading={isDisabled}
              disabled={isDisabled}
              type="submit"
            >
              Submit
            </Button>
          </Center>
        </form>
      </Box>
    </Container>
  )
}

export default CMERequestForm
