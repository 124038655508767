export const oswestryQuestions = [
  {
    question: 'Section 1: Pain Intensity',
    answers: [
      'I have no pain at the moment',
      'The pain is very mild at the moment',
      'The pain is moderate at the moment',
      'The pain is fairly severe at the moment',
      'The pain is very severe at the moment',
      'The pain is the worst imaginable at the moment',
    ],
  },
  {
    question: 'Section 2: Personal Care (eg. bathing, dressing)',
    answers: [
      'I can look after myself normally without causing extra pain',
      'I can look after myself normally, but it causes extra pain',
      'It is painful to look after myself and I am slow and careful',
      'I need some help, but manage most of my personal care',
      'I need help every day in most aspects of self-care',
      'I do not get dressed, wash with difficulty, and stay in bed',
    ],
  },
  {
    question: 'Section 3: Lifting',
    answers: [
      'I can lift heavy weights without extra pain',
      'I can lift heavy weights, but it gives me extra pain',
      'Pain prevents me from lifting heavy weights off the floor, but I can manage if they are conveniently placed (eg. on a table)',
      'Pain prevents me from lifting heavy weights, but I can manage light to medium weights if they are conveniently positioned',
      'I can only lift very light weights',
      'I cannot lift or carry anything',
    ],
  },
  {
    question: 'Section 4: Walking',
    answers: [
      'Pain does not prevent me from walking any distance',
      'Pain prevents me from walking more than 1 mile',
      'Pain prevents me from walking more than 0.6 miles',
      'Pain prevents me from walking more than 0.3 miles',
      'I can only walk using a stick or crutches',
      'I am in bed most of the time',
    ],
  },
  {
    question: 'Section 5: Sitting',
    answers: [
      'I can sit in any chair as long as I like',
      'I can only sit in my favorite chair as long as I like',
      'Pain prevents me from sitting more than one hour',
      'Pain prevents me from sitting more than 30 minutes',
      'Pain prevents me from sitting more than 10 minutes',
      'Pain prevents me from sitting at all',
    ],
  },
  {
    question: 'Section 6: Standing',
    answers: [
      'I can stand as long as I want without extra pain',
      'I can stand as long as I want, but it gives me extra pain',
      'Pain prevents me from standing for more than 1 hour',
      'Pain prevents me from standing for more than 30 minutes',
      'Pain prevents me from standing for more than 10 minutes',
      'Pain prevents me from standing at all',
    ],
  },
  {
    question: 'Section 7: Sleeping',
    answers: [
      'My sleep is never disturbed my pain',
      'My sleep is occasionally disturbed by pain',
      'Because of my pain I have less than 6 hours of sleep',
      'Because of my pain I have less than 4 hours of sleep',
      'Because of my pain I have less than 2 hours of sleep',
      'Pain prevents me from sleeping at all',
    ],
  },
  {
    question: 'Section 8: Changing Degrees of Pain',
    answers: [
      'My pain is rapidly improving',
      'My pain fluctuates, but overall is improving',
      'My pain seems to be getting better, but currently the improvement is slow',
      'My pain is neither getting better nor worse',
      'My pain is gradually worsening',
      'My pain is rapidly worsening',
    ],
  },
  {
    question: 'Section 9: Social Life',
    answers: [
      'My social life is normal and gives me no extra pain',
      'My social life is normal, but increases the degree of pain',
      'Pain has no significant effect on my social life apart from limiting more energetic interests (eg. sports)',
      'Pain has restricted my social life and I do not go out as often',
      'Pain has restricted my social life to home',
      'I have no social life because of pain',
    ],
  },
  {
    question: 'Section 10: Traveling',
    answers: [
      'I can travel anywhere without pain',
      'I can travel anywhere, but it gives me extra pain',
      'Pain is bad but I manage journeys over 2 hours',
      'Pain restricts me to journeys of less than 1 hour',
      'Pain restricts me to short necessary journeys under 30 minutes',
      'Pain prevents me from traveling except to receive treatment',
    ],
  },
]
