/* eslint-disable react-hooks/exhaustive-deps */
import { AddIcon, DownloadIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react'

import { useFieldArray, useForm } from 'react-hook-form'
import { useParams, useHistory } from 'react-router-dom'
import React, { useCallback, useEffect } from 'react'

import CRNAANSList from '../Components/CRNAANSList'

export const crnaansQuestion = [
  'Anesthesia record IS (a) present, (b) complete, (c) reflects appropriate monitoring, and (d) includes assessment pre- and post-operatively',
  'Required signatures are present and complete',
  'Procedure completed without significant intra or post-op anesthesia related complications noted',
  'If present, complications were managed appropriately',
  'Patient evaluated by an anesthesia provider prior to discharge',
  'Duration of PACU stay was appropriate and not prolonged',
]
function FormCRNAANSAngela5({ data, facility, onSubmit, loading }) {
  const params = useParams()
  const history = useHistory()
  const { handleSubmit, register, errors, control, setValue, watch, trigger } =
    useForm()

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'form.list',
  })

  const initData = useCallback(() => {
    if (!data) return append({})
    data.list.map(item => {
      return append(item)
    })
  }, [data])

  useEffect(() => {
    initData()
  }, [initData])

  return (
    <>
      <Box>
        <Stack alignItems="center" spacing="1rem">
          <Image
            src={facility === 'PCSO' ? '/paincare-web.png' : '/LogoOSSC.jpeg'}
            width="325px"
          />
          <Text align="center" fontSize="xs">
            {facility === 'PCSO' ? (
              <>
                2480 Liberty Street NE, Suite 180, Salem, OR 97301 <br />
                Phone: (503) 371-1010 Fax: (503) 371-0805
              </>
            ) : (
              <>
                Oregon Specialists Surgery Center 2785 River Road S, Salem, OR
                97302
              </>
            )}
          </Text>
          <Text fontSize="2xl" fontWeight="bold">
            MEDICAL RECORD/PEER REVIEW WORKSHEET
          </Text>
        </Stack>
      </Box>
      <Box mt="4rem">
        <Flex w="100%" justifyContent="end">
          <Button
            leftIcon={<DownloadIcon />}
            colorScheme="blue"
            isDisabled={params?.id ? false : true}
            onClick={() => history.push(`/peer-review/pdf/${params?.id}`)}
          >
            EXPORT PDF
          </Button>
        </Flex>

        <form onSubmit={handleSubmit(onSubmit)} id="time-away-request-form">
          {fields.map((_, index) => {
            return (
              <CRNAANSList
                key={index}
                index={index}
                errors={errors}
                register={register}
                control={control}
                data={data}
                crnaansQuestion={crnaansQuestion}
                setValue={setValue}
                watch={watch}
                trigger={trigger}
                remove={remove}
              />
            )
          })}

          {fields.length > 0 && (
            <hr style={{ backgroundColor: 'black', height: 1 }} />
          )}

          <Flex justifyContent="end" my={4} pb={8}>
            <ButtonGroup>
              <Button
                variant="outline"
                leftIcon={<AddIcon />}
                onClick={() => append({})}
                isDisabled={fields.length === 15 ? true : false}
              >
                Add
              </Button>
              <Button type="submit" isLoading={loading}>
                Submit
              </Button>
            </ButtonGroup>
          </Flex>
        </form>
      </Box>
    </>
  )
}

export default FormCRNAANSAngela5
