import React from 'react'
import {
  Box,
  Button,
  Text,
  Flex,
  FormLabel,
  FormControl,
  Input,
  HStack,
  VStack,
  Center,
  Textarea,
  RadioGroup,
  Radio,
  Stack,
  Checkbox,
  Spacer,
  Heading,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react'
import { doc, updateDoc } from 'firebase/firestore'
import { db } from '../config/firebase'
import { SwitchInputComponent } from '../utils/switchInput'
import { tbHighRiskQuestion } from '../Pages/Forms/TBHighRiskForm'
// import { useForm } from 'react-hook-form'

function RenderFormData({ data }, toast) {
  const submitScore = async event => {
    event.preventDefault()
    const score = document.getElementById('scoreInput').value
    await updateDoc(doc(db, 'EmployeeForm', data.id), {
      score: score,
    })
      .then(() => {
        toast({
          title: 'Submit Successfully',
          description: 'Score Form has been submitted.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        // console.log('test')
        // alert('Score Submitted!')
      })
      .catch(error => {
        toast({
          title: 'Error',
          description: 'Fail to submit score',
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  }

  switch (data.type) {
    case 'Time Away Request':
      return (
        <Box mt="30" mb="100" width={['100']}>
          <form id="time-away-request-form">
            <HStack mb="5">
              <FormControl id="name" isReadOnly>
                <FormLabel>NAME</FormLabel>
                <Input name="name" defaultValue={data.name} />
              </FormControl>
              <FormControl id="jobTitle" isReadOnly>
                <FormLabel>JOB TITLE</FormLabel>
                <Input
                  name="jobTitle"
                  placeholder="Your Job title"
                  defaultValue={data.jobTitle}
                />
              </FormControl>
            </HStack>
            <FormControl
              mb="5"
              width={['100%', '100%', '100%', '50%']}
              isReadOnly
            >
              <FormLabel>FACILITY</FormLabel>
              <Input
                name="amountOfHoursThatNeedToBeUsed"
                defaultValue={data.facility}
              />
            </FormControl>
            <Center bg="black" mt="30" mb="30" h="35">
              <Text fontWeight="bold" color="white">
                DATE AND TIME AWAY REQUESTED
              </Text>
            </Center>
            <FormControl
              id="date"
              mb="5"
              width={['100%', '100%', '100%', '30%']}
              isReadOnly
            >
              <FormLabel>DATE</FormLabel>
              <Input
                type="text"
                name="date"
                placeholder="Select Date"
                defaultValue={data.date}
              />
            </FormControl>
            <FormControl
              id="reason"
              mb="5"
              width={['100%', '100%', '100%', '50%']}
              isReadOnly
            >
              <FormLabel>REASON</FormLabel>
              <Textarea name="reason" rows="6" defaultValue={data.reason} />
            </FormControl>
            <FormControl
              id="hoursOfPtoRequested"
              mb="5"
              width={['100%', '100%', '100%', '50%']}
              isReadOnly
            >
              <FormLabel>HOURS OF PTO REQUESTED</FormLabel>
              <Input
                name="hoursOfPtoRequested"
                defaultValue={data.hoursOfPtoRequested}
              />
            </FormControl>
            <Text mb="5" fontSize="xs">
              Employee certifies that all information provided on the Time Away
              Request Form is true and complete to the best of his/her
              knowledge. Falsification of this form is grounds for disciplinary
              action, up to and including dismissal.
            </Text>
          </form>
        </Box>
      )

    case 'Time Clock Adjustment':
      return (
        <Box mt="30" mb="100" width={['100']}>
          <form>
            <HStack mb="5">
              <FormControl id="name" isReadOnly>
                <FormLabel>NAME</FormLabel>
                <Input name="name" defaultValue={data.name} />
              </FormControl>
              <FormControl id="jobTitle" isReadOnly>
                <FormLabel>JOB TITLE</FormLabel>
                <Input
                  name="jobTitle"
                  placeholder="Your Job title"
                  defaultValue={data.jobTitle}
                />
              </FormControl>
            </HStack>
            <FormControl
              mb="5"
              width={['100%', '100%', '100%', '50%']}
              isReadOnly
            >
              <FormLabel>FACILITY</FormLabel>
              <Input
                name="amountOfHoursThatNeedToBeUsed"
                defaultValue={data.facility}
              />
            </FormControl>
            <Center bg="black" mt="30" mb="30" h="35">
              <Text fontWeight="bold" color="white">
                MISSING CLOCK IN / OUT
              </Text>
            </Center>
            <FormControl
              id="date"
              mb="5"
              width={['100%', '100%', '100%', '50%']}
              isReadOnly
            >
              <FormLabel>DATE</FormLabel>
              <Input
                type="text"
                name="date"
                placeholder="Select Date"
                defaultValue={data.date}
              />
            </FormControl>
            <HStack mb="5" width={['100%', '100%', '100%', '50%']}>
              <FormControl id="clockIn" isReadOnly>
                <FormLabel>CLOCK IN TIME</FormLabel>
                <Input type="time" name="clockIn" defaultValue={data.clockIn} />
              </FormControl>
              <FormControl id="clockOut" isReadOnly>
                <FormLabel>CLOCK OUT TIME</FormLabel>
                <Input
                  type="time"
                  name="clockOut"
                  defaultValue={data.clockOut}
                />
              </FormControl>
            </HStack>
            <HStack mb="5" width={['100%', '100%', '100%', '50%']}>
              <FormControl id="adjustClockIn" isReadOnly>
                <FormLabel>ADJUST CLOCK IN TIME</FormLabel>
                <Input
                  type="time"
                  name="adjustClockIn"
                  defaultValue={data.adjustClockIn}
                />
              </FormControl>
              <FormControl id="adjustClockOut" isReadOnly>
                <FormLabel>ADJUST CLOCK OUT TIME</FormLabel>
                <Input
                  type="time"
                  name="adjustClockOut"
                  defaultValue={data.adjustClockOut}
                />
              </FormControl>
            </HStack>
            <FormControl id="explaination" isReadOnly>
              <FormLabel>EXPLAINATION</FormLabel>
              <Textarea
                rows="6"
                name="explaination"
                defaultValue={data.explaination}
              />
            </FormControl>
            <Text mb="5" fontSize="xs">
              Employee certifies that all information provided on the Time Clock
              Adjustment Form is true and complete to the best of his/her
              knowledge. Falsification of this form is grounds for disciplinary
              action, up to and including dismissal.
            </Text>
          </form>
        </Box>
      )

    case 'Absentee Report':
      return (
        <Box mt="30" mb="100" width={['100']}>
          <HStack mb="5">
            <FormControl isReadOnly>
              <FormLabel>Name</FormLabel>
              <Input name="name" defaultValue={data.name} />
            </FormControl>
            <FormControl isReadOnly>
              <FormLabel>Date form Completed</FormLabel>
              <Input
                name="dateFormCompleted"
                type="text"
                defaultValue={data.dateFormCompleted}
              />
            </FormControl>
          </HStack>
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '30%']}
            isReadOnly
          >
            <FormLabel>Date(s) absent</FormLabel>
            <Input
              name="dateAbsent"
              type="text"
              defaultValue={data.dateAbsent}
            />
          </FormControl>
          <HStack mb="5" width={['100%', '100%', '100%', '30%']}>
            <FormControl isReadOnly>
              <FormLabel>TIME*:From</FormLabel>
              <Input type="time" name="timeFrom" defaultValue={data.timeFrom} />
            </FormControl>
            <FormControl id="timeTo" isReadOnly>
              <FormLabel>Time*:To</FormLabel>
              <Input type="time" name="timeTo" defaultValue={data.timeTo} />
            </FormControl>
          </HStack>
          <FormControl
            id="totalHoursAbsent"
            mb="5"
            width={['100%', '100%', '100%', '30%']}
            isReadOnly
          >
            <FormLabel>Total Hours Absent</FormLabel>
            <Input
              name="totalHoursAbsent"
              defaultValue={data.totalHoursAbsent}
            />
          </FormControl>
          <Text mb="5" mt="10" decoration="underline">
            REASON FOR ABSENCE:
          </Text>
          <FormControl mb="5" isReadOnly>
            <RadioGroup
              name="reasonForAbsence"
              defaultValue={data.reasonForAbsence}
            >
              <Stack direction="column">
                <Radio value="OFLA" disabled>
                  OFLA (Proceed to OFLA statement below)
                </Radio>
                <Radio value="Other" disabled>
                  Other:(specify)
                </Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          {data.otherReasonForAbsence && (
            <FormControl
              width={['100%', '100%', '100%', '30%']}
              mb="5"
              isReadOnly
            >
              <Input
                name="otherReasonForAbsence"
                placeholder="Other Reason"
                defaultValue={data.otherReasonForAbsence}
              />
            </FormControl>
          )}
          <Box>
            <Text fontSize="sm" mb="5">
              For absences of 2 consecutive days or more, employees may submit
              supporting documentation from a health care provider if they
              choose. If documentation from a health care provider supports the
              absence, it will be documented as 1 occurrence. In the event an
              employee chooses not to provide supporting documentation, each day
              of absence will count as one incident. For example, and employee
              absent for 2 days in a row, will have two incidents recorded.
            </Text>
            <Text fontSize="sm" mb="5">
              For absences without previous approval (not requested and approved
              2 weeks prior), PTO will be automatically applied during the next
              payroll period. If the time of the absence exceeds the PTO amount,
              the PTO will be fully applied, and additional time will receive no
              compensation.
            </Text>
            <Text mb="5" mt="10" decoration="underline">
              OFLA:
            </Text>
            <Text mb="5" fontSize="sm">
              In the event the employee claims OFLA as their reasoning for
              absence, the employee is required to comply with requirements for
              OFLA absence claims. This includes providing any required
              supporting documentation, as well as notifying a supervisor in the
              appropriate time period. Please refer to the OFLA Rights Handout
              and the Pain Care Specialist policy in regards to OFLA. In
              addition to this form, a{' '}
              <Text fontSize="sm" decoration="underline">
                PCS OFLA REQUEST FORM MUST ALSO BE COMPLETED.
              </Text>
            </Text>
          </Box>
        </Box>
      )
    case 'CME Request':
      return (
        <Box mt="30" mb="100" width={['100']}>
          <HStack>
            <FormControl id="name" isReadOnly>
              <FormLabel>Name</FormLabel>
              <Input name="name" defaultValue={data.name} />
            </FormControl>
            <FormControl id="jobTitle" isReadOnly>
              <FormLabel>JOB TITLE</FormLabel>
              <Input
                name="jobTitle"
                placeholder="Your Job title"
                defaultValue={data.jobTitle}
              />
            </FormControl>
          </HStack>
          <Center bg="black" mt="30" mb="30" h="35">
            <Text fontWeight="bold" color="white">
              DATE AND AMOUNT REQUESTED
            </Text>
          </Center>
          <FormControl
            id="date"
            mb="5"
            width={['100%', '100%', '100%', '30%']}
            isReadOnly
          >
            <FormLabel>DATE</FormLabel>
            <Input
              type="text"
              name="date"
              placeholder="Select Date"
              defaultValue={data.date}
            />
          </FormControl>
          <FormControl
            id="eventAmount"
            mb="5"
            width={['100%', '100%', '100%', '50%']}
            isReadOnly
          >
            <FormLabel>EVENT/AMOUNT</FormLabel>
            <Textarea
              name="eventAmount"
              rows="6"
              defaultValue={data.eventAmount}
            />
          </FormControl>
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '50%']}
            isReadOnly
          >
            <FormLabel>HOURS OF PTO REQUESTED</FormLabel>
            <Input
              name="hoursOfPtoRequested"
              defaultValue={data.hoursOfPtoRequested}
            />
          </FormControl>
          <Text mb="5" fontSize="xs">
            Employee certifies that all information provided on the Time Away
            Request Form is true and complete to the best of his/her knowledge.
            Falsification of this form is grounds for disciplinary action, up to
            and including dismissal.
          </Text>
        </Box>
      )
    case 'Paid Time Off Request':
      return (
        <Box mt="30" mb="100" width={['100']}>
          <HStack mb="5">
            <FormControl id="name" isReadOnly>
              <FormLabel>Name</FormLabel>
              <Input name="name" defaultValue={data.name} />
            </FormControl>
            <FormControl id="jobTitle" isReadOnly>
              <FormLabel>JOB TITLE</FormLabel>
              <Input
                name="jobTitle"
                placeholder="Your Job title"
                defaultValue={data.jobTitle}
              />
            </FormControl>
          </HStack>
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '50%']}
            isReadOnly
          >
            <FormLabel>FACILITY</FormLabel>
            <Input
              name="amountOfHoursThatNeedToBeUsed"
              defaultValue={data.facility}
            />
          </FormControl>
          <Center bg="black" mt="30" mb="30" h="35">
            <Text fontWeight="bold" color="white"></Text>
          </Center>
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '50%']}
            id="forPayPeriodOff"
            isReadOnly
          >
            <FormLabel>FOR PAY PERIOD OF:</FormLabel>
            <Input name="forPayPeriodOf" defaultValue={data.forPayPeriodOf} />
          </FormControl>
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '50%']}
            isReadOnly
          >
            <FormLabel>AMOUNT OF HOURS THAT NEED TO BE USED:</FormLabel>
            <Input
              name="amountOfHoursThatNeedToBeUsed"
              defaultValue={data.amountOfHoursThatNeedToBeUsed}
            />
          </FormControl>

          <Text mb="5" fontSize="xs">
            Employee certifies that all information provided on the Time Away
            Request Form is true and complete to the best of his/her knowledge.
            Falsification of this form is grounds for disciplinary action, up to
            and including dismissal.
          </Text>
        </Box>
      )
    case 'Request For OFLA Leave':
      return (
        <Box mt="30" mb="100" width={['100']}>
          <HStack mb="5">
            <FormControl id="name" isReadOnly>
              <FormLabel>Name</FormLabel>
              <Input name="name" defaultValue={data.name} />
            </FormControl>
            <FormControl id="jobTitle" isReadOnly>
              <FormLabel>JOB TITLE</FormLabel>
              <Input
                name="jobTitle"
                placeholder="Your Job title"
                defaultValue={data.jobTitle}
              />
            </FormControl>
          </HStack>
          <HStack mb="5">
            <FormControl id="manager" isReadOnly>
              <FormLabel>Manager</FormLabel>
              <Input
                name="manager"
                placeholder="Enter Manager name"
                defaultValue={data.manager}
              />
            </FormControl>
            <FormControl isReadOnly>
              <FormLabel>Office Side</FormLabel>
              <RadioGroup
                name="officeSide"
                defaultValue={data.officeSide}
                disabled
              >
                <HStack>
                  <Radio value="Front" disabled>
                    Front
                  </Radio>
                  <Radio value="Back" disabled>
                    Back
                  </Radio>
                </HStack>
              </RadioGroup>
            </FormControl>
          </HStack>
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '50%']}
            isReadOnly
          >
            <FormLabel>Emp. Home/Cell Number</FormLabel>
            <Input
              name="homeCellNumber"
              type="telephone"
              defaultValue={data.homeCellNumber}
            />
          </FormControl>
          <Text mb="3" mt="10" decoration="underline">
            PLEASE CHECK ONE OF THE FOLLOWING REASONS FOR YOUR LEAVE REQUEST:
          </Text>
          <FormControl mb="5" isReadOnly>
            <RadioGroup defaultValue={data.reason} disabled>
              <Stack>
                <Radio value="Own Serious Health" disabled>
                  Your own serious health condition
                </Radio>
                <HStack>
                  <Radio value="Birth, placement or adoption" disabled>
                    Birth, placement or adoption
                  </Radio>
                  <RadioGroup>
                    <HStack>
                      <Radio value="Parental (Bonding leave)" disabled>
                        Parental (Bonding leave)
                      </Radio>
                      <Radio value="Pregnancy Disability Leave" disabled>
                        Pregnancy Disability Leave
                      </Radio>
                    </HStack>
                  </RadioGroup>
                </HStack>
                <Radio
                  value="Eligible Family Member with a serious health condition"
                  disabled
                >
                  Eligible Family Member with a serious health condition
                </Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <HStack mb="5">
            <FormControl id="name" isReadOnly>
              <FormLabel>Name</FormLabel>
              <Input name="reasonName" defaultValue={data.reasonName} />
            </FormControl>
            <FormControl id="reasonDOB" isReadOnly>
              <FormLabel>Date of Birth</FormLabel>
              <Input
                name="reasonDOB"
                type="text"
                defaultValue={data.reasonDOB}
              />
            </FormControl>
          </HStack>
          <Text mb="3" fontWeight="bold">
            Relation:
          </Text>
          <FormControl mb="5" isReadOnly>
            <RadioGroup defaultValue={data.relation} disabled>
              <Stack>
                <Radio value="Sopuse" disabled>
                  Sopuse
                </Radio>
                <HStack>
                  <Radio value="Domestic Partner (please sepcify)" disabled>
                    Domestic Partner (please sepcify)
                  </Radio>
                  <RadioGroup defaultValue={data.relation}>
                    <HStack>
                      <Radio value="State registered" disabled>
                        State registered
                      </Radio>
                      <Radio value="Country registered" disabled>
                        Country registered
                      </Radio>
                      <Radio value="Affidavit of domestic partnership" disabled>
                        Affidavit of domestic partnership
                      </Radio>
                    </HStack>
                  </RadioGroup>
                </HStack>
                <Radio value="Child/Stepchild" disabled>
                  Child/Stepchild
                </Radio>
                <Radio value="Parent" disabled>
                  Parent
                </Radio>
                <Radio value="Parent-in-law" disabled>
                  Parent-in-law
                </Radio>
                <Radio value="Domestic Partner Child" disabled>
                  Domestic Partner Child
                </Radio>
                <Radio value="Domestic Partner's Parent" disabled>
                  Domestic Partner's Parent
                </Radio>
                <Radio value="Grandparent" disabled>
                  Grandparent
                </Radio>
                <Radio value="Grandchild" disabled>
                  Grandchild
                </Radio>
                <Radio value="Other" disabled>
                  Other:
                </Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <FormControl mb="5" isReadOnly>
            <Checkbox
              name="beravment"
              defaultIsChecked={data.beravment}
              isDisabled
            >
              Beravment:
            </Checkbox>
          </FormControl>
          {data.beravment && (
            <Stack>
              <FormControl
                mb="5"
                width={['100%', '100%', '100%', '50%']}
                isReadOnly
              >
                <FormLabel>Name of Family member</FormLabel>
                <Input
                  name="nameOfFamilyMember"
                  defaultValue={data.nameOfFamilyMember}
                />
              </FormControl>
              <FormControl
                mb="5"
                width={['100%', '100%', '100%', '50%']}
                isReadOnly
              >
                <FormLabel>Date you learned of the death</FormLabel>
                <Input
                  name="dateYouLearnedOfTheDeath"
                  type="text"
                  defaultValue={data.dateYouLearnedOfTheDeath}
                />
              </FormControl>
              <FormControl
                mb="5"
                width={['100%', '100%', '100%', '50%']}
                isReadOnly
              >
                <FormLabel>
                  Relationship: (please specify from list above):
                </FormLabel>
                <Input name="relationship" defaultValue={data.relationship} />
              </FormControl>
            </Stack>
          )}
          <FormControl mb="5" isReadOnly>
            <Checkbox
              name="militaryleaveRelated"
              value="Military Leave Related"
              isDisabled
              defaultIsChecked={data.militaryleaveRelated !== ''}
            >
              Military leave related to a Qualifying Exigency or Injured Service
              Member Caregiver Leave
            </Checkbox>
          </FormControl>
          <Text mb="5" decoration="underline" fontWeight="bold">
            DURATION AND TYPE OF ABSENCE:
          </Text>
          <FormControl mb="5" isReadOnly>
            <FormLabel>Type of Absence</FormLabel>
            <Stack mb="5">
              <Checkbox
                name="continuousLeave"
                defaultIsChecked={data.continuousLeave}
                isDisabled
              >
                Continuous Leave (provide dates)
              </Checkbox>
              <HStack>
                <FormControl id="continuousLeaveFrom" isReadOnly>
                  <Input
                    name="continuousLeaveFrom"
                    type="text"
                    defaultValue={data.continuousLeaveFrom}
                  />
                </FormControl>
                <Text>to</Text>
                <FormControl id="continuousTo" isReadOnly>
                  <Input
                    name="continuousTo"
                    type="text"
                    defaultValue={data.continuousTo}
                  />
                </FormControl>
              </HStack>
            </Stack>
            <Stack>
              <Checkbox
                name="intermittentLeave"
                defaultIsChecked={data.intermittent}
                isDisabled
              >
                Intermittent Leave (provide dates)
              </Checkbox>
              <HStack>
                <FormControl id="intermittentLeaveFrom" isReadOnly>
                  <Input
                    name="intermittentLeaveFrom"
                    type="text"
                    defaultValue={data.intermittentLeaveFrom}
                  />
                </FormControl>
                <Text>to</Text>
                <FormControl id="intermittentLeaveTo" isReadOnly>
                  <Input
                    name="intermittentLeaveTo"
                    type="text"
                    defaultValue={data.intermittentLeaveTo}
                  />
                </FormControl>
              </HStack>
            </Stack>
          </FormControl>

          <Text mb="3">
            If you expect to be off work on an intermittent basis or work less
            than your normal schedule, please coordinate with your manager to
            ensure coverage during your absence.
          </Text>
          <Text mb="3">
            ***Your accrued PTO will be used during your leave of absence. ***
          </Text>
          <Text mb="3">
            If your leave is approved for appointments or for prescheduled
            leave, you must notify your supervisor in advance and designate your
            absence as OFLA. If you are approved for flare ups, you must
            designate your leave as OFLA at the time you call in, otherwise your
            absence may count against the Pain Care Specialists
            absence/attendance policy.
          </Text>
        </Box>
      )
    case 'Covid-19 Questionnairs':
      return (
        <Box mt="30" mb="100" width={['100']} pb="1rem">
          <HStack mb="5" width={['100%', '100%', '100%', '80%']}>
            <FormControl isReadOnly>
              <FormLabel>Employee Name</FormLabel>
              <Input name="employeeName" defaultValue={data.name} />
            </FormControl>
            <FormControl isReadOnly>
              <FormLabel>Date</FormLabel>
              <Input name="date" type="text" defaultValue={data.date} />
            </FormControl>
          </HStack>
          <FormControl mb="5">
            <FormLabel>Do you have a temperature of 100º F or more?</FormLabel>
            <RadioGroup isDisabled defaultValue={data.temperatureOf100orMore}>
              <HStack>
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          <FormControl mb="5">
            <RadioGroup isDisabled defaultValue={data.handCleanedOnArrival}>
              <HStack>
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          <FormControl mb="5">
            <FormLabel>
              Wearing a facemask on arrival *If no mask provide mask immediately
            </FormLabel>
            <RadioGroup
              isDisabled
              defaultValue={data.wearingAFacemaskOnArrival}
            >
              <HStack>
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          <FormControl mb="5">
            <FormLabel>Do you currently have a cough or a cold?</FormLabel>
            <RadioGroup isDisabled defaultValue={data.doYouCurrentlyHaveACough}>
              <HStack>
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          <FormControl mb="5">
            <FormLabel>Do you have any shortness of breath?</FormLabel>
            <RadioGroup
              isDisabled
              defaultValue={data.doYouHaveAnyShortnessOfBreath}
            >
              <HStack>
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          <FormControl mb="5">
            <FormLabel>Do you feel sick?</FormLabel>
            <RadioGroup isDisabled defaultValue={data.doYouFeelSick}>
              <HStack>
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          <FormControl mb="5">
            <FormLabel>
              In the last 14 days have you traveled out of the state?
            </FormLabel>
            <RadioGroup
              isDisabled
              defaultValue={data.inTheLast14DaysHaveYouTraveledOutofTheState}
            >
              <HStack>
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          <FormControl mb="5">
            <FormLabel>
              In the last 14 days have you had contact with someone with
              symptoms or under investigation for COVID-19, or are ill with
              respiratory illness?
            </FormLabel>
            <RadioGroup
              defaultValue={
                data.inTheLast14DaysHaveyouHadContactWithSomeoneWithSymptoms
              }
              isDisabled
            >
              <HStack>
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          <FormControl mb="5">
            <FormLabel>
              Are you residing in a community where community-based spread of
              COVID-19 is occurring?
            </FormLabel>
            <RadioGroup
              defaultValue={data.areYouresidingInACommunityWhereCommunityBased}
              isDisabled
            >
              <HStack>
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          <FormControl mb="5">
            <FormLabel>
              Have you been tested for COVID-19 If yes, please write date of
              last test and result
            </FormLabel>
            <RadioGroup
              isDisabled
              defaultValue={data.haveYouBeenTestedForCovid19}
            >
              <HStack>
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          <FormControl mb="3">
            <FormLabel>Action taken:</FormLabel>
            <Checkbox
              name="actionTaken"
              value="N/A"
              isDisabled
              defaultIsChecked={data.actionTaken}
            >
              N/A:
            </Checkbox>
          </FormControl>
          <Text>Or</Text>
          <FormControl mb="5" isReadOnly>
            <Textarea
              name="otherReason"
              cols="5"
              width={['100%', '100%', '100%', '50%']}
              defaultValue={data.otherReason}
            />
          </FormControl>
        </Box>
      )
    case 'Employee Quiz':
      return (
        <Box mt="30" mb="100" width={['100']}>
          <HStack
            mb="5"
            width={['100%', '100%', '100%', '80%']}
            alignItems="flex-start"
          >
            <FormControl id="name" isReadOnly>
              <FormLabel>NAME</FormLabel>
              <Input name="name" defaultValue={data.name} />
            </FormControl>
            <FormControl isReadOnly>
              <FormLabel>Date</FormLabel>
              <Input name="date" type="text" defaultValue={data.date} />
            </FormControl>
          </HStack>
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '80%']}
            isReadOnly
          >
            <FormLabel>
              1. How frequently are all Oregon businesses required to hold
              either Safety Committee (more than 10 employees) or all-staff
              safety meetings?
            </FormLabel>
            <Input name="quizOne" defaultValue={data.quizOne} />
          </FormControl>
          <FormControl
            mb="5"
            isReadOnly
            width={['100%', '100%', '100%', '80%']}
          >
            <FormLabel>
              2. If our practice has been free of accidents or bloodborne
              pathogen exposures for at least 24 months, we are not required to
              hold any safety meetings at all.
            </FormLabel>

            <RadioGroup isDisabled defaultValue={data.quizTwo}>
              <VStack alignItems={'start'}>
                <Radio value="true">True</Radio>
                <Radio value="false">False</Radio>
              </VStack>
            </RadioGroup>
          </FormControl>
          <FormControl
            mb="5"
            isReadOnly
            width={['100%', '100%', '100%', '80%']}
          >
            <FormLabel>
              3. OR-OSHA requires that every business keep a written record of
              safety topics covered and staff attendance at safety meetings.
            </FormLabel>
            <RadioGroup isDisabled defaultValue={data.quizThree}>
              <VStack alignItems={'start'}>
                <Radio value="true">True</Radio>
                <Radio value="false">False</Radio>
              </VStack>
            </RadioGroup>
          </FormControl>
          <FormControl
            mb="5"
            isReadOnly
            width={['100%', '100%', '100%', '80%']}
          >
            <FormLabel>4. What does OPIM stand for?</FormLabel>

            <RadioGroup isDisabled defaultValue={data.quizFour}>
              <Stack>
                <Radio value="answerA">{`a) Our Physician Is Masterful`}</Radio>
                <Radio value="answerB">
                  {`b) Other Potentially Infectious Material`}
                </Radio>
                <Radio value="answerC">
                  {`c) Outside Parameters In Medica/Dental`}
                </Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <FormControl
            mb="5"
            isReadOnly
            width={['100%', '100%', '100%', '80%']}
          >
            <FormLabel>5. What does OPIM stand for?</FormLabel>
            <RadioGroup isDisabled defaultValue={data.quizFive}>
              <Stack>
                <Radio value="answerA">
                  {`a) A droplet of a patient’s blood lands in your eye`}
                </Radio>
                <Radio value="answerB">
                  {`b) Your gloved hand touches saliva in a patient’s mouth`}
                </Radio>
                <Radio value="answerC">
                  {`c) You stick yourself with a sterile needle`}
                </Radio>
                <Radio value="allOfAbove"> All of the above</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <FormControl
            mb="5"
            isReadOnly
            width={['100%', '100%', '100%', '80%']}
          >
            <FormLabel>
              6. Your practice is required to ask the source individual to be
              tested for both Hepatitis B & HIV.
            </FormLabel>
            <RadioGroup isDisabled defaultValue={data.quizSix}>
              <VStack alignItems={'start'}>
                <Radio value="true">True</Radio>
                <Radio value="false">False</Radio>
              </VStack>
            </RadioGroup>
          </FormControl>
          <FormControl
            mb="5"
            isReadOnly
            width={['100%', '100%', '100%', '80%']}
          >
            <FormLabel>
              7. Hepatitis B virus (HBV) Immunity must be effectively maintained
              by the employee.
            </FormLabel>
            <RadioGroup isDisabled defaultValue={data.quizSeven}>
              <VStack alignItems={'start'}>
                <Radio value="true">True</Radio>
                <Radio value="false">False</Radio>
              </VStack>
            </RadioGroup>
          </FormControl>
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '80%']}
            isReadOnly
          >
            <FormLabel>
              8. Where would you find your practice’s Bloodborne Pathogen
              Exposure Control Plan?
            </FormLabel>
            <Input name="quizEigth" defaultValue={data.quizEigth} />
          </FormControl>
          <FormControl
            mb="5"
            isReadOnly
            width={['100%', '100%', '100%', '80%']}
          >
            <FormLabel>
              9. OSHA requires every healthcare facility with employees using
              sharps to have an on-going program to identify and evaluate safer
              sharps devices, as well as annually document its findings.
            </FormLabel>
            <RadioGroup isDisabled defaultValue={data.quizNine}>
              <VStack alignItems={'start'}>
                <Radio value="true">True</Radio>
                <Radio value="false">False</Radio>
              </VStack>
            </RadioGroup>
          </FormControl>
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '80%']}
            isReadOnly
          >
            <FormLabel>
              10. If your interaction with a patient involves a “reasonable
              expectation” of exposure to a bloodborne pathogen, what personal
              protective equipment (PPE) does your practice require you to wear?
            </FormLabel>
            <Input name="quizTen" defaultValue={data.quizTen} />
          </FormControl>
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '80%']}
            isReadOnly
          >
            <FormLabel>
              11. Name an “engineering control” used in your practice to protect
              you.
            </FormLabel>
            <Input name="quizEleven" defaultValue={data.quizEleven} />
          </FormControl>
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '80%']}
            isReadOnly
          >
            <FormLabel>
              12. Name a “work practice control” used in your practice to
              protect you.
            </FormLabel>
            <Input name="quizTwelve" defaultValue={data.quizTwelve} />
          </FormControl>
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '80%']}
            isReadOnly
          >
            <FormLabel>
              13. When working with hazardous chemicals in your practice, OSHA
              expects you to know what the SDS says regarding personal
              protective equipment for those chemicals.
            </FormLabel>
            <RadioGroup isDisabled defaultValue={data.quizThirteen}>
              <VStack alignItems={'start'}>
                <Radio value="true">True</Radio>
                <Radio value="false">False</Radio>
              </VStack>
            </RadioGroup>
          </FormControl>
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '80%']}
            isReadOnly
          >
            <FormLabel>
              14. Where is the chemical spill kit locate in you practice?
            </FormLabel>
            <Input name="quizFourteen" defaultValue={data.quizFourteen} />
          </FormControl>
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '80%']}
            isReadOnly
          >
            <FormLabel>
              15. A “secondary container” for hazardous materials is less
              important than a primary container and dose no need a label.
            </FormLabel>
            <RadioGroup isDisabled defaultValue={data.quizFifteen}>
              <Radio value="true">True</Radio>
              <Spacer />
              <Radio value="false">False</Radio>
            </RadioGroup>
          </FormControl>
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '80%']}
            isReadOnly
          >
            <FormLabel>
              16. How often do you need to check and flush out your practice’s
              eyewash station?
            </FormLabel>
            <RadioGroup isDisabled defaultValue={data.quizSixteen}>
              <Stack>
                <Radio value="whenever">Whenever we use it </Radio>
                <Radio value="weekly">Weekly</Radio>
                <Radio value="monthly">Monthly</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '80%']}
            isReadOnly
          >
            <FormLabel>17. Tuberculosis Flu Viruses RequireThatWe</FormLabel>
            <RadioGroup isDisabled defaultValue={data.quizSeventeen}>
              <Stack>
                <Radio value="answerA">
                  {`a) Follow out practice’s disinfection schedule for
                      surfaces`}
                </Radio>
                <Radio value="answerB">
                  {`b) Protect ourselves from airborne infection`}
                </Radio>
                <Radio value="answerC">
                  {`c) Take extra precautions if a patient tells you she is
                      infected`}
                </Radio>
                <Radio value="allOfAbove"> All of the above </Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '80%']}
            isReadOnly
          >
            <FormLabel>
              18. What is your practice policy on verbal or physical harassment
              in the workplace
            </FormLabel>
            <Input name="quizEigthteen" defaultValue={data.quizEigthteen} />
          </FormControl>
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '80%']}
            isReadOnly
          >
            <FormLabel>
              19. An OSHA compliance officer can interview any employee in the
              practice and ask about
            </FormLabel>
            <RadioGroup isDisabled defaultValue={data.quuizNineteen}>
              <Stack>
                <Radio value="answerA">
                  {`a) Training they have received in practicing Universal
                      Precautions`}
                </Radio>
                <Radio value="answerB">
                  {`b) What duties they would perform in evacuating the
                      building`}
                </Radio>
                <Radio value="answerC">
                  {`c) When they attended their last safety meeting and what
                      was discussed`}
                </Radio>
                <Radio value="allOfAbove"> All of the above </Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '80%']}
            isReadOnly
          >
            <FormLabel>
              20. When using the fire extinguisher, where do you aim it and how
              far back should you stand?
            </FormLabel>
            <Input name="quizTwenty" defaultValue={data.quizTwenty} />
          </FormControl>
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '80%']}
            isReadOnly
          >
            <FormLabel>
              21. Where is the fire extinguisher nearest your work area is
              located?
            </FormLabel>
            <Input name="quizTwentyone" defaultValue={data.quizTwentyone} />
          </FormControl>
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '80%']}
            isReadOnly
            pb="1rem"
          >
            <FormLabel>
              22. In the event of an emergency evacuation. Where do you meet
              outside the practice?
            </FormLabel>
            <Input name="quizTwentytwo" defaultValue={data.quizTwentytwo} />
          </FormControl>
        </Box>
      )
    case 'Annual Clinical Competencies':
      return (
        <Box
          pb="1rem"
          w={{ base: '100%', sm: '100%', md: '100%', lg: '60rem' }}
        >
          <HStack mb="5">
            <FormControl isReadOnly>
              <FormLabel>Employee Name</FormLabel>
              <Input name="name" defaultValue={data.name} />
            </FormControl>
            <FormControl isReadOnly>
              <FormLabel>Date</FormLabel>
              <Input name="date" type="text" defaultValue={data.name} />
            </FormControl>
          </HStack>
          <Text fontWeight={'bold'}>
            Blood Glucose Monitors Competency Checklist{' '}
          </Text>
          <FormControl mx={3} mt="2">
            <RadioGroup isDisabled defaultValue={data.quizOne}>
              <HStack px={4}>
                <FormLabel>Demonstrates knowledgeable use of monitor</FormLabel>
                <Spacer />
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          <FormControl mx={3}>
            <RadioGroup isDisabled defaultValue={data.quizTwo}>
              <HStack px={4}>
                <FormLabel>
                  Verifies monitor lot number and number on test strips vial are
                  the same
                </FormLabel>
                <Spacer />
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          <FormControl mx={3}>
            <RadioGroup isDisabled defaultValue={data.quizThree}>
              <HStack px={4}>
                <FormLabel>Checks system with check strip</FormLabel>
                <Spacer />
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          <FormControl mx={3}>
            <RadioGroup isDisabled defaultValue={data.quizFour}>
              <HStack px={4}>
                <FormLabel>Checks system with control solution</FormLabel>
                <Spacer />
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          <FormControl mx={3}>
            <RadioGroup isDisabled defaultValue={data.quizFive}>
              <HStack px={4}>
                <FormLabel>Obtains blood sample using manual lancet</FormLabel>
                <Spacer />
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          <FormControl mx={3}>
            <RadioGroup isDisabled defaultValue={data.quizSix}>
              <HStack px={4}>
                <FormLabel>Test blood sample</FormLabel>
                <Spacer />
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          <FormControl mx={3}>
            <RadioGroup isDisabled defaultValue={data.quizSeven}>
              <HStack px={4}>
                <FormLabel>Care of the monitor</FormLabel>
                <Spacer />
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          <Box>
            <FormControl isReadOnly>
              <HStack alignItems="center">
                <FormLabel>Veryfied By</FormLabel>
                <Input
                  w="max-content"
                  name="firstVeryfied"
                  defaultValue={data.firstVeryfied}
                />
              </HStack>
            </FormControl>
          </Box>
          <Text fontWeight={'bold'} mt={5}>
            Urine Pregnancy Test Competency
          </Text>
          <FormControl mx={3} mt="2">
            <RadioGroup isDisabled defaultValue={data.quizEigth}>
              <HStack px={4}>
                <FormLabel>Demonstrates knowledgeable use of monitor</FormLabel>
                <Spacer />
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          <FormControl mx={3} isReadOnly>
            <RadioGroup isDisabled defaultValue={data.quizNine}>
              <HStack px={4}>
                <FormLabel>Verifies expiration date</FormLabel>
                <Spacer />
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          <FormControl mx={3}>
            <RadioGroup isDisabled defaultValue={data.quizTen}>
              <HStack px={4}>
                <FormLabel>
                  Obtains urine sample with four drops of urine
                </FormLabel>
                <Spacer />
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          <FormControl mx={3}>
            <RadioGroup isDisabled defaultValue={data.quizEleven}>
              <HStack px={4}>
                <FormLabel>
                  Places cassette on flat surface and places urine in correct
                  well
                </FormLabel>
                <Spacer />
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          <FormControl mx={3}>
            <RadioGroup isDisabled defaultValue={data.quizTwelve}>
              <HStack px={4}>
                <FormLabel>
                  Waits 2-3 minutes to interpret results and interprets results
                  correctly
                </FormLabel>
                <Spacer />
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          <FormControl mx={3}>
            <RadioGroup isDisabled defaultValue={data.quizThirteen}>
              <HStack px={4}>
                <FormLabel>
                  Identifies invalid, negative, and positive results
                </FormLabel>
                <Spacer />
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          <Box>
            <FormControl isReadOnly>
              <HStack alignItems="center">
                <FormLabel>Veryfied By</FormLabel>
                <Input
                  w="max-content"
                  name="secondVeryfied"
                  defaultValue={data.secondVeryfied}
                />
              </HStack>
            </FormControl>
          </Box>
          <Text fontWeight={'bold'} mt={5}>
            Crash Cart
          </Text>
          <FormControl mx={3} mt="2">
            <RadioGroup isDisabled defaultValue={data.quizFourteen}>
              <HStack px={4}>
                <FormLabel>
                  Demonstrates proper use and knowledge of how to use crash
                  cart, EG: Defibrillator, easy go vac, 3 lead placement and
                  monitor reading
                </FormLabel>
                <Spacer />
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          <Box>
            <FormControl isReadOnly>
              <HStack alignItems="center">
                <FormLabel>Veryfied By</FormLabel>
                <Input
                  w="max-content"
                  name="tirdVeryfied"
                  defaultValue={data.tirdVeryfied}
                />
              </HStack>
            </FormControl>
          </Box>
          <Text fontWeight={'bold'} mt={5}>
            Cavi Wipes
          </Text>
          <FormControl mx={3} mt="2">
            <RadioGroup isDisabled defaultValue={data.quizFifteen}>
              <HStack px={4}>
                <FormLabel>
                  Demonstrate proper use of how to use Cavi Wipes
                </FormLabel>
                <Spacer />
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          <Box>
            <FormControl isReadOnly>
              <HStack alignItems="center">
                <FormLabel>Veryfied By</FormLabel>
                <Input
                  w="max-content"
                  name="fithVeryfied"
                  defaultValue={data.fithVeryfied}
                />
              </HStack>
            </FormControl>
          </Box>
          <Text fontWeight={'bold'} mt={5}>
            Urine Collecting Competency
          </Text>
          <FormControl mx={3} mt="2">
            <RadioGroup isDisabled defaultValue={data.quizSixteen}>
              <HStack px={4}>
                <FormLabel>Correct specimen collection</FormLabel>
                <Spacer />
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          <FormControl mx={3} mt="2">
            <RadioGroup isDisabled defaultValue={data.quizSeventeen}>
              <HStack px={4}>
                <FormLabel>Correct specimen handling</FormLabel>
                <Spacer />
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
        </Box>
      )
    case 'Radiation Safety Test':
      return (
        // <Box>{console.log(data)}</Box>
        <Box mt="30" mb="100" width={['100']}>
          <HStack mb="5">
            <FormControl id="name" isReadOnly={true}>
              <FormLabel>Name</FormLabel>
              <Input name="name" defaultValue={data.name} />
            </FormControl>
            <FormControl id="date" isReadOnly={true}>
              <FormLabel>Date</FormLabel>
              <Input
                type={'date'}
                size="large"
                defaultValue={data.date}
                format="YYYY-MM-DD"
                style={{ width: '100%' }}
                isReadOnly
              />
            </FormControl>
          </HStack>

          <FormControl mb="5" isReadOnly={true}>
            <FormLabel>Occupation</FormLabel>
            <Input
              name="occupation"
              defaultValue={data.occupation}
              width="300px"
              isReadOnly={true}
            />
          </FormControl>

          <FormControl mb="5" isReadOnly={true}>
            <FormLabel>
              {`1) What causes an occupational workers primary dose in the room?`}
            </FormLabel>
            <RadioGroup defaultValue={data.quizOne} isReadOnly>
              <Stack>
                <Radio value="answerA">
                  {`a) The left over radiation in the room`}
                </Radio>
                <HStack>
                  <Radio value="answerB">
                    {`b) Radiation Leaking from the tube`}
                  </Radio>
                </HStack>
                <Radio value="answerC">
                  {`c) The scatter radiation produced from the patient`}
                </Radio>
              </Stack>
            </RadioGroup>
          </FormControl>

          <FormControl mb="5" isReadOnly={true}>
            <FormLabel>
              {`2) When is the possibility of radiation exposure the highest in the
            room?`}
            </FormLabel>
            <RadioGroup defaultValue={data.quizTwo}>
              <Stack>
                <Radio value="answerA">{`a) When the machine is on`}</Radio>
                <HStack>
                  <Radio value="answerB">
                    {`b) During cross-table procedures`}
                  </Radio>
                </HStack>
                <Radio value="answerC">
                  {`c) When performing right sided procedures`}
                </Radio>
              </Stack>
            </RadioGroup>
          </FormControl>

          <FormControl mb="5" isReadOnly={true}>
            <FormLabel>
              {`3) How can a worker limit their exposure to radiation while in the
            room?`}
            </FormLabel>
            <RadioGroup defaultValue={data.quizThree}>
              <Stack>
                <Radio value="answerA">
                  {`a) Minimize the time spent around the radiation source`}
                </Radio>
                <HStack>
                  <Radio value="answerB">
                    {`b) Maximize the distance from the source of radiation`}
                  </Radio>
                </HStack>
                <Radio value="answerC">
                  {`c) Wear proper protective equipment such a lead aprons while
                    around radiation`}
                </Radio>
                <Radio value="allOfAbove">All the above</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>

          <FormControl mb="5" isReadOnly={true}>
            <FormLabel>
              {`4) Lead aprons will protect the employee from all radiation
            exposures.`}
            </FormLabel>

            <RadioGroup defaultValue={data.quizFour}>
              <Stack>
                <Radio value="true">True</Radio>
                <Radio value="false">False</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>

          <FormControl mb="5" isReadOnly={true}>
            <FormLabel>{`5) Never fold lead aprons for storage.`}</FormLabel>
            <RadioGroup defaultValue={data.quizFive}>
              <Stack>
                <Radio value="true">True</Radio>
                <Radio value="false">False</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>

          <FormControl mb="5" isReadOnly={true}>
            <FormLabel>
              {`6) Where should the radiation badge be placed while not in the room?`}
            </FormLabel>
            <RadioGroup defaultValue={data.quizSix}>
              <Stack>
                <Radio value="answerA">
                  {`a) Keep it on your lead for easy use`}
                </Radio>
                <HStack>
                  <Radio value="answerB">
                    {`b) Place it on the counter in the break room for storage`}
                  </Radio>
                </HStack>
                <Radio value="answerC">
                  {`c) Take it home with you at the end of the day`}
                </Radio>
                <Radio value="answerD">
                  {`d) Store it with the control badge outside the room`}
                </Radio>
              </Stack>
            </RadioGroup>
          </FormControl>

          <FormControl mb="5" isReadOnly={true}>
            <FormLabel>
              {`7) Always wear the radiation badge as directed every time for
            accurate readings.`}
            </FormLabel>
            <RadioGroup defaultValue={data.quizSeven}>
              <Stack>
                <Radio value="true">True</Radio>
                <Radio value="false">False</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>

          <FormControl mb="5" isReadOnly={true}>
            <FormLabel>
              {`8) As an employee in a radiation environment, what is my annual
            exposure limit?`}
            </FormLabel>
            <RadioGroup defaultValue={data.quizEigth}>
              <Stack>
                <Radio value="answerA">{`a) 5000 mrem per year`}</Radio>
                <HStack>
                  <Radio value="answerB">{`b) 500 mrem per year`}</Radio>
                </HStack>
                <Radio value="answerC">{`c) 5 mrem per year`}</Radio>
                <Radio value="a&c">Both A&C</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>

          <FormControl mb="5" isReadOnly={true}>
            <FormLabel>
              {`9) What should every female patient between 10 and 60 be asked
            before a procedure?`}
            </FormLabel>
            <RadioGroup defaultValue={data.quizNine}>
              <Stack>
                <Radio value="answerA">{`a) Any chance of pregnancy?`}</Radio>
                <HStack>
                  <Radio value="answerB">{`b) Any chance of pregnancy?`}</Radio>
                </HStack>
                <Radio value="answerC">{`c) Any chance of pregnancy?`}</Radio>
                <Radio value="allOfAbove">All the above</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
        </Box>
      )

    case 'Collectors Competency Quiz':
      return (
        // <Box>{console.log(data)}</Box>
        <Box mt="30" pb="1rem" mb="100" width={['100']}>
          <FormControl mb="5" isReadOnly>
            <FormLabel>
              {`1) Two patient identifiers must be used to identify a patient before
            collection process may begin?`}
            </FormLabel>
            <RadioGroup isDisabled defaultValue={data.quizOne}>
              <Stack>
                <Radio value="true">True</Radio>
                <Radio value="false">False</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <FormControl mb="5">
            <FormLabel>
              {`2) An unlabeled specimen is acceptable to use for testing?`}
            </FormLabel>
            <RadioGroup isDisabled defaultValue={data.quizTwo}>
              <Stack>
                <Radio value="true">True</Radio>
                <Radio value="false">False</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <FormControl mb="5">
            <FormLabel>
              {`3) The temperature of a sample is used to prove validity of sample
            being collected?`}
            </FormLabel>
            <RadioGroup isDisabled defaultValue={data.quizThree}>
              <Stack>
                <Radio value="true">True</Radio>
                <Radio value="false">False</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <FormControl mb="5">
            <FormLabel>
              {`4) Two patient identifiers must be on all specimens submitted for
            testing?`}
            </FormLabel>
            <RadioGroup isDisabled defaultValue={data.quizFour}>
              <Stack>
                <Radio value="true">True</Radio>
                <Radio value="false">False</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <FormControl mb="5">
            <FormLabel>
              {`5) Samples that cannot be tested within three days of collection
            should be kept in fridge?`}
            </FormLabel>
            <RadioGroup isDisabled defaultValue={data.quizFive}>
              <Stack>
                <Radio value="true">True</Radio>
                <Radio value="false">False</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <FormControl mb="5">
            <FormLabel>{`6) This is an acceptable specimen label:`}</FormLabel>
            <Box pl="2rem">
              <VStack
                alignItems={'start'}
                w="max-content"
                border="1px solid #000"
                p="1rem"
                borderRadius="15px"
              >
                <Text>Name : John</Text>
                <Text>DOB : 1962</Text>
                <Text>Date : 10/3</Text>
                <Text>Time : 10:15</Text>
              </VStack>
            </Box>
            <RadioGroup pt="1rem" isDisabled defaultValue={data.quizSix}>
              <Stack>
                <Radio value="true">True</Radio>
                <Radio value="false">False</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <FormControl mb="5">
            <FormLabel>
              {`7) Who must sign the patient requisition before testing may take
            place?`}
            </FormLabel>
            <RadioGroup isDisabled defaultValue={data.quizSeven}>
              <Stack>
                <Radio value="answerA">{`a) Doctor only`}</Radio>
                <Radio value="answerB">{`b) Doctor and patient`}</Radio>
                <Radio value="answerC">{`c) Patient only`}</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <FormControl mb="5">
            <FormLabel>
              {`8) Samples that can be tested within three days after collection may
            be kept in fridge?`}
            </FormLabel>
            <RadioGroup isDisabled defaultValue={data.quizEigth}>
              <Stack>
                <Radio value="true">True</Radio>
                <Radio value="false">False</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <FormControl mb="5">
            <FormLabel>
              {`9) Temp of the fridge must fall between 2 and 8 degrees Celsius?`}
            </FormLabel>
            <RadioGroup isDisabled defaultValue={data.quizNine}>
              <Stack>
                <Radio value="true">True</Radio>
                <Radio value="false">False</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <FormControl mb="5">
            <FormLabel>
              {`10) A patient has asked if their friend may accompany them into the
            restroom to help them. This is an acceptable course of action when
            collecting a sample for testing?`}
            </FormLabel>
            <RadioGroup isDisabled defaultValue={data.quizTen}>
              <Stack>
                <Radio value="true">True</Radio>
                <Radio value="false">False</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>

          <Flex
            justifyContent={'space-around'}
            alignItems={'flex-start'}
            flexWrap="wrap"
          >
            <Box>
              {' '}
              <Center h={{ base: 'auto' }}>
                <Text fontWeight="bold" fontSize="15" textAlign="center">
                  Signature of Collector
                </Text>
              </Center>
              <Center w="100%" pt="2rem">
                <Flex>
                  <SwitchInputComponent data={data.signatureCollector || ''} />
                </Flex>
              </Center>
              {/* <FormControl>
                <Input
                  name="signature"
                  defaultValue={data.signature}
                  isReadOnly
                />
                <FormLabel textAlign={'center'}>Collector Signature</FormLabel>
              </FormControl> */}
            </Box>
            <Box>
              <FormControl>
                <Input
                  type={'date'}
                  defaultValue={data.dateTesting}
                  format="YYYY-MM-DD"
                  style={{ width: '100%' }}
                  isReadOnly
                />
                <FormLabel textAlign={'center'} pt="2rem">
                  Date of testing
                </FormLabel>
              </FormControl>
            </Box>
            <Box>
              <FormControl isRequired>
                <Input
                  id="scoreInput"
                  defaultValue={data.score || ''}
                  textAlign={'center'}
                  min={0}
                  type="number"
                  // isReadOnly={data.score}
                />
                <Text textAlign={'center'} py="1rem">
                  Score
                </Text>
              </FormControl>
              <Center>
                <Button
                  type="submit"
                  colorScheme={'green'}
                  onClick={submitScore}
                >
                  Submit Score
                </Button>
              </Center>
            </Box>
          </Flex>
          <Text m={4} fontWeight={'bold'}>
            Score must be 70% or collector must be retrained within 90 days{' '}
          </Text>
        </Box>
      )
    case 'TB High Risk':
      return (
        <>
          <Flex
            flexDirection={['column', 'column', 'row', 'row', 'row', 'row']}
            mt={5}
            w="100%"
            gap={6}
          >
            <FormControl>
              <FormLabel>Employee Name</FormLabel>
              <Input placeholder="Name" value={data?.name || ''} isDisabled />
            </FormControl>
            <FormControl id="date" mb="5">
              <FormLabel>Date</FormLabel>
              <Input
                type="date"
                placeholder="Select Date"
                value={data?.date || ''}
                isDisabled
              />
            </FormControl>
          </Flex>
          <VStack alignItems="start" mb={5}>
            <Heading as="h5" size="sm">
              Targeted TB Testing will only be done if any of the following
              questions are answered “Yes”
            </Heading>
            <Heading as="h5" size="sm">
              Does the employee have any of the following risk
              factors/Conditions?
            </Heading>
          </VStack>
          {tbHighRiskQuestion.map((item, index) => {
            return (
              <FormControl
                mb="5"
                width={['100%', '100%', '100%', '80%']}
                key={index}
              >
                <FormLabel>{item}</FormLabel>
                <RadioGroup isDisabled value={data?.answers[index] || ''}>
                  <Stack>
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
            )
          })}
          <Heading as="h5" size="sm">
            The above recommendations for targeted TB testing were issued by the
            US Public Health Service Advisory Council on Elimination of
            Tuberculosis. If the employee falls into any of the above risk
            groups, TB testing is indicated and treatment for Latent TB
            infection if shown to be present will be offered.
          </Heading>
          <Box mt={8}>
            <Text fontWeight="bold" fontSize="15">
              Employee Signature
            </Text>
            <Box my={8} ml={10}>
              <SwitchInputComponent data={data.signatureCollector || ''} />
            </Box>
          </Box>
        </>
      )
    case 'Declination of Influenza Vaccination':
      return (
        <Box mt={8}>
          <Flex alignItems="center">
            <Text fontSize="md" marginRight={1}>
              My employer,
            </Text>
            <Input
              value={data.name || ''}
              width="20%"
              placeholder="Name"
              isDisabled
            />
            ,
            <Text fontSize="md" paddingLeft={1}>
              has recommended that I receive influenza vaccination to protect
              the patients I serve.
            </Text>
          </Flex>
          <Text fontSize="md">
            I acknowledge that I am aware of the following facts:
          </Text>
          <UnorderedList>
            <ListItem>
              <Text fontSize="md">
                Influenza is a serious respiratory disease that kills thousands
                of people in the United States each year.
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize="md">
                Influenza vaccination is recommended for me and all other
                healthcare workers to protect this facility&#8217;s patients
                from influenza, its complications, and death.
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize="md">
                If I contract influenza, I can shed the virus for 24 hours
                before influenza symptoms appear. My shedding the virus can
                spread influenza to patients in this facility.
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize="md">
                If I become infected with influenza, I can spread severe illness
                to others even when my symptoms are mild or non-existent.
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize="md">
                I understand that the strains of virus that cause influenza
                infection change almost every year and, even if they don&#8217;t
                change, my immunity declines over time. This is why vaccination
                against influenza is recommended each year.
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize="md">
                I understand that I cannot get influenza from the influenza
                vaccine.
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize="md">
                The consequences of my refusing to be vaccinated could have
                life-threatening consequences to my health and the health of
                those with whom I have contact, including
              </Text>
            </ListItem>
            <Box paddingLeft={10}>
              <ListItem>
                <Text fontSize="md">
                  all patients in this healthcare facility
                </Text>
              </ListItem>
              <ListItem>
                <Text fontSize="md">my coworkers</Text>
              </ListItem>
              <ListItem>
                <Text fontSize="md">my family</Text>
              </ListItem>
              <ListItem>
                <Text fontSize="md"> my community</Text>
              </ListItem>
            </Box>
          </UnorderedList>
          <Text fontSize="md">
            Despite these facts, I am choosing to decline influenza vaccination
            right now for the
          </Text>
          <Text margin="20px 0px" fontSize="md">
            following reasons:
          </Text>
          <Textarea isDisabled value={data.detail || ''} />
          <Text fontSize="md" paddingTop={10}>
            I understand that I can change my mind at any time and accept
            influenza vaccination, if vaccine is still available.
          </Text>
          <Text fontSize="md">
            I have read and fully understand the information on this declination
            form.
          </Text>
          <Flex align="center" gap="8" w={'50%'} justify="space-between">
            <Box mt={8}>
              <Text fontWeight="bold" fontSize="15" textAlign="center">
                Signature
              </Text>
              <Box my={8} ml={10}>
                <SwitchInputComponent data={data.signatureCollector || ''} />
              </Box>
            </Box>
            <Box>
              <Text>Date</Text>
              <Input
                type="date"
                placeholder="Select Date"
                value={data.date || ''}
                isDisabled
              />
            </Box>
          </Flex>
          <Box w={'50%'}>
            <Text>Name (print)</Text>
            <Input
              value={data.namePrint || ''}
              placeholder="Name (print)"
              isDisabled
            />
          </Box>
        </Box>
      )
    default:
      return <>No Data</>
  }
}

export default RenderFormData
