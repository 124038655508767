import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  Container,
  Button,
  Flex,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Box,
  Spacer,
  Text,
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  Avatar,
  Center,
  Input,
} from '@chakra-ui/react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import _ from 'underscore'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { GrCertificate } from 'react-icons/gr'
import { MdSettings } from 'react-icons/md'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import {
  collection,
  query,
  onSnapshot,
  orderBy,
  limit,
} from 'firebase/firestore'
import { db } from '../../config/firebase'
import renderDate from '../../config/renderDate'

const localizer = momentLocalizer(moment)

function Credentialing() {
  const history = useHistory()
  const [staffList, setStaffList] = useState([])
  const [filteredStaff, setFilteredStaff] = useState([])
  const [logs, setLogs] = useState([])
  const [calendarEvents, setCalendarEvents] = useState([])

  useEffect(() => {
    const qStaff = query(collection(db, 'profiles'), orderBy('fullName', 'asc'))
    const subStaff = onSnapshot(qStaff, snapShot => {
      let data = []
      snapShot.forEach(doc => {
        data.push({ ...doc.data(), id: doc.id })
      })
      setStaffList(data)
      setFilteredStaff(data)
    })
    const qLogs = query(
      collection(db, 'CredentialLogs'),
      limit(200),
      orderBy('createdAt', 'desc')
    )
    const subLogs = onSnapshot(qLogs, snapShot => {
      let data = []
      snapShot.forEach(doc => {
        data.push({ ...doc.data(), id: doc.id })
      })
      setLogs(data)
    })
    const qEvents = query(collection(db, 'CalendarEvents'))
    const subEvents = onSnapshot(qEvents, snapShot => {
      let data = []
      snapShot.forEach(doc => {
        data.push({
          ...doc.data(),
          start: doc.data().start.toDate(),
          end: doc.data().end.toDate(),
          id: doc.id,
        })
      })
      setCalendarEvents(
        data.filter(
          v => v.type === 'Expiry Date' || v.type === 'Next Check Date'
        )
      )
    })
    return () => {
      subStaff()
      subLogs()
      subEvents()
    }
  }, [])

  return (
    <Container maxW="100%" mt="5">
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
        bg="gray.100"
        p="1.5"
        borderRadius="0.3em"
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/dashboard">
            Dashbaord
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/credentialing">
            Credentialing
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex mt="5">
        <Box pt="2" fontSize="18" fontWeight="bold">
          Credentialing Management
        </Box>
        <Spacer />
        <Button
          leftIcon={<MdSettings />}
          size="sm"
          as={Link}
          to="/credentialing/settings"
        >
          Settings
        </Button>
      </Flex>
      <Box mt="10">
        <Box mb="5" display="flex" justifyContent="space-between">
          <Text fontWeight="bold" fontSize="16">
            Staff / Provider
          </Text>
          <Box>
            <Input
              type="text"
              onChange={e => {
                let regEx = new RegExp(`${e.target.value.toLowerCase()}`, 'g')
                let filteredList = staffList.filter(staff => {
                  let fullName = staff.fullName.toLowerCase()
                  return fullName.match(regEx)
                })
                setFilteredStaff(filteredList)
              }}
              placeholder="Search"
            />
          </Box>
        </Box>
        <Box
          mb="5"
          h="300px"
          borderWidth="1px"
          borderRadius="lg"
          overflow="scroll"
        >
          <Table>
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>Email</Th>
                <Th>Full Name</Th>
                <Th>Job Title</Th>
                <Th>Manage</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredStaff.map((staff, index) => (
                <Tr key={index}>
                  <Td>
                    <Avatar src={staff.avatarUrl} size="sm" />
                  </Td>
                  <Td>{staff.email}</Td>
                  <Td>{staff.fullName}</Td>
                  <Td>{staff.jobTitle}</Td>
                  <Td>
                    <Button
                      size="xs"
                      leftIcon={<GrCertificate />}
                      as={Link}
                      to={`/credentialing/${staff.id}`}
                    >
                      Credentials
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
        <Stack direction={['column', 'column', 'column', 'row']} spacing="20px">
          <Box
            h="600px"
            p="15px"
            width={['100%', '100%', '100%', '60%']}
            borderWidth="1px"
            borderRadius="lg"
            overflow="scroll"
          >
            <Calendar
              localizer={localizer}
              events={calendarEvents}
              startAccessor="start"
              endAccessor="end"
              onSelectEvent={event =>
                history.push(`/credentialing/${event.profileId}`)
              }
            />
          </Box>
          <Box
            h="600px"
            p="15px"
            width={['100%', '100%', '100%', '40%']}
            borderWidth="1px"
            borderRadius="lg"
          >
            <Text mb="5" fontWeight="bold" fontSize="16">
              Credential Logs
            </Text>
            <Box height="100%" width="100%" overflow="scroll" fontSize="xs">
              {_.isEmpty(logs) ? (
                <Center>No Credential Logs</Center>
              ) : (
                logs.map((log, index) => (
                  <Box key={index}>
                    [{renderDate(log.createdAt)}] - {log.staffName}'s{' '}
                    {log.credential} - {log.title} - {log.action} by{' '}
                    {log.createdBy}
                  </Box>
                ))
              )}
            </Box>
          </Box>
        </Stack>
      </Box>
      <Box height="100px"></Box>
    </Container>
  )
}

export default Credentialing
