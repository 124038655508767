import React from 'react'
import moment from 'moment'
import { Text, Image, StyleSheet, View } from '@react-pdf/renderer'
import { questionMD } from '../form/FormMD'

const styles = StyleSheet.create({
  section: {
    width: '100%',
    marginBottom: 10,
    padding: 10,
    textAlign: 'center',
  },
  textHeaderTop: {
    fontSize: '14px',
  },
  sectionTwo: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  boxTitle: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '11px',
  },
  textBorder: {
    borderBottom: 1,
    textAlign: 'center',
  },
  imgcheck: {
    width: '7px',
    height: '7px',
  },
  textHeader: {
    width: '100%',
    margin: '20px 0px',
    fontSize: '12px',
  },
  boxAnswersList: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '11px',
    marginBottom: 12,
    alignItems: 'flex-start',
  },
  boxAnswersCheckGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0px 2px',
  },
  boxAnswersCheck: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0px 2px',
    position: 'relative',
  },
  boxAnswersCheckNo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0px 2px',
    position: 'relative',
    marginRight: '40px',
  },
  textQuestion: {
    width: '70%',
    display: 'flex',
    flexDirection: 'row',
  },
  textQuestionPadding: {
    paddingLeft: '20px',
  },
  boxCheck: {
    width: '12px',
    height: '12px',
    backgroundColor: '#fff',
    border: '1px solid #000',
    margin: '0px 4px',
  },
  imgCheckYes: {
    width: '18px',
    height: '18px',
    position: 'absolute',
    top: -8,
    left: 6,
  },
  imgCheckNo: {
    width: '18px',
    height: '18px',
    position: 'absolute',
    top: -8,
    left: 6,
  },
  boxComment: {
    width: '100%',
    margin: '32px 0px',
  },
  textCommentTitle: {
    fontSize: '14px',
  },
  textComment: {
    margin: '12px 0px',
    fontSize: '12px',
  },
  sectionSignature: {
    width: '100%',
    marginTop: 60,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  signatureBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '150px',
  },
  signatureImg: {
    height: '40px',
  },
  signature: {
    top: 4,
    paddingTop: 4,
    width: '150px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: 1,
  },
  textsignature: {
    fontSize: 10,
  },
})
function RenderMDPDF({ data }) {
  return (
    <>
      <View style={styles.section} fixed>
        <Text style={styles.textHeaderTop}>
          MEDICAL RECORD/PEER REVIEW WORKSHEET
        </Text>
      </View>
      <View style={styles.sectionTwo}>
        <View style={styles.boxTitle}>
          <Text style={styles.textTitle}>Anesthesia Provider : </Text>
          <Text style={styles.textBorder}>{data?.anesthesia || '-'}</Text>
        </View>
        <View style={styles.boxTitle}>
          <Text style={styles.textTitle}>Period : </Text>
          <Text style={styles.textBorder}>{data?.period || '-'}</Text>
        </View>
        <View style={styles.boxTitle}>
          <Text style={styles.textTitle}>MR# : </Text>
          <Text style={styles.textBorder}>{data?.mr || ''}</Text>
        </View>
        <View style={styles.boxTitle}>
          <Text style={styles.textTitle}>DOS : </Text>
          <Text style={styles.textBorder}>{data?.dos || '-'}</Text>
        </View>
      </View>
      <Text style={styles.textHeader}>
        SURGEON PEER/UTILIZATION REVIEW CRITERIA
      </Text>
      {questionMD.map((item, index) => (
        <View style={styles.boxAnswersList} key={index}>
          <View style={styles.textQuestion}>
            <Text>{index + 1}. </Text>
            <Text>{item}</Text>
          </View>
          <View style={styles.boxAnswersCheckGroup}>
            <View style={styles.boxAnswersCheck}>
              <View style={styles.boxCheck} />
              {data?.answers[index] === 'yes' && (
                <Image style={styles.imgCheckYes} src="/check.png" />
              )}
              <Text>Yes</Text>
            </View>
            <View
              style={
                index === 8 || index === 7
                  ? styles.boxAnswersCheck
                  : styles.boxAnswersCheckNo
              }
            >
              <View style={styles.boxCheck} />
              {data?.answers[index] === 'no' && (
                <Image style={styles.imgCheckNo} src="/check.png" />
              )}
              <Text>No</Text>
            </View>
            <View style={styles.boxAnswersCheck}>
              {(index === 8 || index === 7) && (
                <>
                  <View style={styles.boxCheck} />
                  {data?.answers[index] === 'n/a' && (
                    <Image style={styles.imgCheckYes} src="/check.png" />
                  )}
                  <Text>N/A</Text>
                </>
              )}
            </View>
          </View>
        </View>
      ))}
      <View style={styles.boxComment}>
        <Text style={styles.textCommentTitle}>Comment : </Text>
        <Text style={styles.textComment}>{data?.comment}</Text>
      </View>
      <View style={styles.sectionSignature}>
        <View style={styles.signatureBox}>
          {data?.signatureCollector && (
            <Image style={styles.signatureImg} src={data?.signatureCollector} />
          )}
          <Text style={styles.signature}>
            <Text style={styles.textsignature}>Peer Reviewer Signature</Text>
          </Text>
        </View>
        <View style={styles.signatureBox}>
          <Text style={styles.textsignature}>
            {data?.date ? moment(data?.date).format('MM/DD/YYYY') : ' '}
          </Text>
          <Text style={styles.signature}>
            <Text style={styles.textsignature}>Date</Text>
          </Text>
        </View>
      </View>
      <View style={styles.sectionSignature}>
        <View style={styles.signatureBox}>
          <Text style={styles.textsignature}>{data?.reviewer}</Text>
          <Text style={styles.signature}>
            <Text style={styles.textsignature}>Peer Reviewer Printed Name</Text>
          </Text>
        </View>
      </View>
    </>
  )
}

export default RenderMDPDF
