import {
  Box,
  Button,
  Center,
  Flex,
  GridItem,
  HStack,
  Input,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBreakpoint,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'
import React, { useRef } from 'react'
import { FaRegEdit } from 'react-icons/fa'
import Resizer from 'react-image-file-resizer'
import SignatureCanvas from 'react-signature-canvas'
import { SwitchInputComponent } from '../../utils'

const resizeFile = file =>
  new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      'JPEG',
      100,
      0,
      uri => {
        resolve(uri)
      },
      'base64'
    )
  })

function Signature({
  label,
  field,
  watch,
  data,
  setValue,
  trigger,
  formKey = 'form.signatureCollector',
}) {
  const sigCanvas = useRef({})
  const fileUploadInputRef = useRef(null)
  const breakPoint = useBreakpoint()
  const toast = useToast()
  const { isOpen, onToggle, onClose } = useDisclosure()

  const clear = () => sigCanvas.current.clear()
  const signatureCollector = watch ? watch(formKey) : ''
  const save = () =>
    setValue(
      formKey,
      sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')
    )

  async function onUploadChange(event) {
    const file = event.target?.files[0]

    if (
      file?.type === 'image/jpg' ||
      file?.type === 'image/jpeg' ||
      file?.type === 'image/png'
    ) {
      const image = await resizeFile(file)
      setValue(formKey, image)
      toast({
        duration: 3000,
        title: 'successfully uploaded.',
        status: 'success',
        position: 'top',
        isClosable: true,
      })
      onClose()
    } else {
      toast({
        duration: 3000,
        title: 'only file .jpg, jpeg and .png are supported.',
        status: 'info',
        position: 'top',
        isClosable: true,
      })
    }
  }

  function onClickFileUpload() {
    fileUploadInputRef && fileUploadInputRef.current.click()
  }

  return (
    <GridItem {...field}>
      <VStack spacing="0px" justifyContent={'flex-start'}>
        <Center h={{ base: '80px' }}>
          <Text fontWeight="bold" fontSize="15" textAlign="center">
            {label}
          </Text>
        </Center>
        <HStack
          h="40px"
          w="100%"
          flexDirection="row"
          borderBottom={'1px solid #777'}
        >
          <Center w="100%">
            <SwitchInputComponent data={signatureCollector || data} />
          </Center>
          <Flex h="40px" alignItems="flex-end" justifyContent="flex-end">
            <Popover placement="top" isOpen={isOpen} onClose={onClose}>
              <PopoverTrigger p="0px">
                <Button
                  bg="white"
                  onClick={() => {
                    setValue(formKey, ' ')
                    onToggle()
                  }}
                >
                  <FaRegEdit size="25" color="gray" />
                </Button>
              </PopoverTrigger>
              <Portal size="auto">
                <PopoverContent
                  w={{
                    base: '20rem',
                    sm: '20rem',
                    md: '30rem',
                  }}
                  h="auto"
                  boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                >
                  <PopoverArrow />
                  <Box w="100%" h="100%">
                    <Text p="0.75rem" fontSize="12px" color="grey">
                      <span style={{ color: 'red' }}>{`*`}</span>
                      {`Any signature (including any electronic symbol
        or process attached to, or associated with, a
        contract or other record and adopted by a Person
        with the intent to sign, authenticate or accept
        such contract or record)`}
                    </Text>

                    <Box p="0.75rem" pt="1rem" h="auto">
                      <Tabs variant="enclosed">
                        <TabList>
                          <Tab
                            onClick={() => {
                              setValue(formKey, ' ')
                            }}
                          >
                            Draw Signature
                          </Tab>
                          <Tab
                            onClick={() => {
                              setValue(formKey, ' ')
                              clear()
                            }}
                          >
                            Upload Signature
                          </Tab>
                        </TabList>
                        <TabPanels h="auto">
                          <TabPanel
                            h="22rem"
                            display="flex"
                            flexDirection="column"
                          >
                            <Box h="100%" border="1px solid #F0F0F0">
                              <SignatureCanvas
                                style={{
                                  backgroundColor: 'red',
                                }}
                                penColor="black"
                                canvasProps={{
                                  width: switchCanvas(breakPoint, 'width'),
                                  height: switchCanvas(breakPoint, 'height'),
                                }}
                                ref={sigCanvas}
                              />
                              <Flex
                                pl="10px"
                                pb="5px"
                                pr="10px"
                                justifyContent="space-between"
                                h="max-content"
                                alignItems="end"
                              >
                                <Center>
                                  <Button
                                    fontSize="14px"
                                    px="1rem"
                                    h="1.7rem"
                                    colorScheme="blackAlpha"
                                    onClick={() => clear()}
                                  >
                                    Clear
                                  </Button>
                                </Center>
                                <Button
                                  mt={4}
                                  h="1.8rem"
                                  fontSize="14px"
                                  w="4.5rem"
                                  bg="#17A2B8"
                                  color="white"
                                  colorScheme="cyan"
                                  onClick={async () => {
                                    let triggerValue = await trigger(formKey)
                                    if (triggerValue) {
                                      onClose()
                                      save()
                                    } else {
                                    }
                                  }}
                                >
                                  Confirm
                                </Button>
                              </Flex>
                            </Box>
                          </TabPanel>
                          <TabPanel
                            h="22rem"
                            display="flex"
                            flexDirection="column"
                          >
                            <Center h="100%" w="100%">
                              <Input
                                type="file"
                                display="none"
                                ref={fileUploadInputRef}
                                onChange={onUploadChange}
                              />
                              <Button
                                color="#AEAEAE"
                                border="2px solid #AEAEAE"
                                bg="transparent"
                                _hover={{
                                  bg: '#AEAEAE',
                                  color: '#fff',
                                  opacity: '0.5',
                                }}
                                _active={{
                                  bg: '#AEAEAE',
                                  color: '#fff',
                                  opacity: '1',
                                }}
                                onClick={onClickFileUpload}
                              >
                                Upload
                              </Button>
                            </Center>
                            <Flex
                              pl="10px"
                              pb="5px"
                              pr="10px"
                              justifyContent="flex-end"
                              h="max-content"
                              alignItems="end"
                            >
                              <Button
                                mt={4}
                                h="1.8rem"
                                fontSize="14px"
                                w="4.5rem"
                                bg="#17A2B8"
                                color="white"
                                colorScheme="cyan"
                                onClick={async () => {
                                  let triggerValue = await trigger(
                                    'form.signaturePatient'
                                  )
                                  if (triggerValue) {
                                    onClose()
                                  } else {
                                  }
                                }}
                              >
                                Confirm
                              </Button>
                            </Flex>
                          </TabPanel>
                        </TabPanels>
                      </Tabs>
                    </Box>
                  </Box>
                </PopoverContent>
              </Portal>
            </Popover>
          </Flex>
        </HStack>
      </VStack>
    </GridItem>
  )
}

export default Signature

function switchCanvas(bp, type) {
  if (type === 'width') {
    switch (bp) {
      case 'base':
        return 275
      case 'sm':
        return 275
      case 'md':
        return 425
      case 'lg':
        return 425
      case 'xl':
        return 425
      case '2xl':
        return 425
      default:
        return 150
    }
  }
  if (type === 'height') {
    switch (bp) {
      case 'base':
        return 150
      case 'sm':
        return 150
      case 'md':
        return 225
      case 'lg':
        return 225
      case 'xl':
        return 225
      case '2xl':
        return 225
      default:
        return 150
    }
  }
}
