import { Image, Text } from '@chakra-ui/react'
import React from 'react'

export function SwitchInputComponent({ formView }) {
  if (formView?.includes('data:image/')) {
    return <Image alt={formView} src={formView} h="60px" mt="-20px" />
  } else if (formView?.includes('https://')) {
    return <Image alt={formView} src={formView} h="60px" mt="-20px" />
  } else {
    return <Text fontFamily={'Homemade Apple'}>{formView || ''}</Text>
  }
}
