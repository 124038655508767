import { Text, View } from '@react-pdf/renderer'
import React from 'react'

function CancellationNOShowPolicy({ styles }) {
  return (
    <View style={{ gap: '10px', width: '100%' }}>
      <View style={{ width: '100%', textAlign: 'start' }}>
        <Text style={styles.title}>PATIENT FINANCIAL CONSENT</Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          Copayment is due on the day of service, unless payment arrangements
          have been previously arranged. Pain Care Specialists of Oregon, LLC
          will submit insurance claims for you. However, if the service is not
          covered under your policy, you are responsible for the balance due.
          This may include additional patient responsibility charges for
          procedures performed same day as office visit. It is the policy of
          Pain Care Specialists of Oregon, LLC to work diligently to assist
          patients with financial arrangements. Extended payment plans can be
          arranged through our billing office. These plans are based upon
          financial circumstances of each patient. We invite you to discuss any
          financial difficulties by calling our office at (503) 371-1010. If
          insurance requires that a referral is necessary it is the
          responsibility of the patient to obtain one from their primary care
          physician before an appointment with a Pain Care Specialists of
          Oregon, LLC physician. Pain Care Specialists of Oregon, LLC reserves
          the right to refuse service to any patient who does not have a valid
          referral in our office at the time of their appointment. Many of the
          services that our office provides require pre authorization as we ask
          that you be patient with our office in obtaining this authorization.
          Many insurance companies require documentation prior to authorizing
          services and we will do our best to comply in a timely fashion with
          their requests.
        </Text>
      </View>
      <View>
        <Text>Consent to Release Information:</Text>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          I (the patient/ guarantor) hereby give my consent to Pain Care
          Specialists of Oregon, LLC to release any information regarding my
          care and treatment as may be required in connection with payment. I
          authorize the release of any medical documentation to insurance
          companies and medical providers as necessary.
        </Text>
      </View>
      <View>
        <Text>Assignment of Benefits:</Text>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          I (the patient/ guarantor) hereby authorize payment to be rendered
          directly to Pain Care Specialists of Oregon, LLC for the benefits
          otherwise payable to me by any third party. The above authorizations
          are in effect permanently or until canceled by myself in writing.
        </Text>
      </View>
      <View>
        <Text>Medicare Signature on File:</Text>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          I (patient/ guarantor) request that payment under the medical
          insurance program Medicare to be made to Pain Care Specialists of
          Oregon, LLC on any bills for services furnished to me by the
          physicians of Pain Care Specialists of Oregon, LLC permanently or
          until this authorization in canceled by me (the patient/guarantor) in
          writing. I also give Pain Care Specialists of Oregon, LLC
          authorization to file claims to Medicare on my behalf.
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          Pain Care Specialists of Oregon, LLC reserves the right to charge a
          $50 fee if the patient fails to give at least 24-hour cancellation
          notice or "no show" to their appointment. This fee will be paid by the
          patient/ guarantor regardless of insurance. We reserve the right to
          charge a $200 fee if the patient fails to check in for a procedure or
          cancel the procedure without providing at least 24 hours' advance
          notice. This fee is not billable to insurance.
        </Text>
      </View>
      <View style={{ paddingTop: 10 }}>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          I have read this policy and understand that delinquent accounts may be
          assigned to a credit reporting and collection service.
        </Text>
      </View>
      <View style={{ paddingTop: 10 }}>
        <Text style={{ textAlign: 'center', ...styles.title }}>
          CANCELLATION AND NO SHOW POLICY FOR CLINIC APPOINTMENTS
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          <Text>
            Your appointment is reserved especially for you, and when you do not
            call to cancel an appointment, you may be preventing another patient
            from getting much needed treatment. We understand that there are
            times when you must miss an appointment due to emergencies or
            obligations for work or family. We also understand that delays can
            happen. However, in fairness to other patients, if you do not arrive
            30 minutes prior to your scheduled new patient visit, or 5 minutes
            prior to your schedule follow-up visit, we may need to reschedule
            your appointment to another day. If you arrive more than 5 minutes
            past your actual appointment time you will be rescheduled. No
            controlled substances will be prescribed without an appointment. We
            kindly request that you notify us at least 24 hours in advance
            should you need to cancel or reschedule your appointment. Please
            contact our office between 8:00AM-4:00PM Monday through Friday at
            (503) 371-1010.
          </Text>
          {` `}
          <Text style={{ fontWeight: 'bold' }}>
            Each time you cancel or "no show" your appointment with less than 24
            hours' notice, you will be subjected to a $50.00 fee, and this fee
            is not billable to insurance. You may be dismissed from the clinic
            if you cancel or "no show" your appointment less than 24 hours of
            your scheduled time more than three times per year.
          </Text>
        </Text>
      </View>
    </View>
  )
}

export default CancellationNOShowPolicy
