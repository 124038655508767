import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import {
    Container,
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Text,
    FormErrorMessage,
    FormLabel,
    FormControl,
    Stack,
    Input,
    Button,
    useToast,
    HStack,
    Center,
    Checkbox,
    RadioGroup,
    Radio,
    Flex,
    Spacer
} from "@chakra-ui/react"
import { DatePicker } from 'antd'

import { ChevronRightIcon } from '@chakra-ui/icons'
import { FaCloudDownloadAlt } from 'react-icons/fa'
import { addDoc, collection } from 'firebase/firestore'
import { db } from '../../config/firebase'

function RequestForOFLALeaveForm({ user }) {

    const { handleSubmit, reset, register, errors, control } = useForm()
    const toast = useToast()
    const [isDisabled, setIsDisabled] = useState(false)
    const [reason, setReason] = useState(null)
    const [relation, setRelation] = useState(null)
    const [beravment, setBeravment] = useState(false)
    const [continuousLeave, setContinuousLeave] = useState(false)
    const [intermittent, setIntermittent] = useState(false)
    function submitData(value) {
        setIsDisabled(true)
        value.reasonDOB = value.reasonDOB.format("YYYY-MM-DD")
        value.dateYouLearnedOfTheDeath = value.dateYouLearnedOfTheDeath.format("YYYY-MM-DD")
        value.continuousLeaveFrom = value.continuousLeaveFrom.format("YYYY-MM-DD")
        value.continuousTo = value.continuousTo.format("YYYY-MM-DD")
        value.intermittentLeaveFrom = value.intermittentLeaveFrom.format("YYYY-MM-DD")
        value.intermittentLeaveTo = value.intermittentLeaveTo.format("YYYY-MM-DD")
        addDoc(collection(db, "EmployeeForm"), {
            ...value,
            reason: reason,
            name: user.fullName,
            jobTitle: user.jobTitle,
            relation: relation,
            beravment: beravment,
            continuousLeave: continuousLeave,
            intermittent: intermittent,
            type: "Request For OFLA Leave", createdAt: new Date(),
            notification: true,
            status: "Pending"
        })
            .then(() => {
                toast({
                    title: "Submit Successfully",
                    description: "Form has been submmited.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                })
                setIsDisabled(false)
                reset()
            })
    }

    return (
        <Container maxW="100%" mt="5">
            <Breadcrumb
                spacing="8px"
                separator={<ChevronRightIcon color="gray.500" />}
                bg="gray.100" p="1.5"
                borderRadius="0.3em"
            >
                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to="/dashboard">Dashbaord</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to="/request-for-ofla-leave-form">Request For OFLA Leave Form</BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>
            <Box mt="30" mb="100" width={["100"]}>
                <Flex mt="10" mb="10">
                    <Box pt="2" fontSize="18" fontWeight="bold">
                        Request For OFLA Leave Form
                    </Box>
                    <Spacer />
                    <Button onClick={() => { window.open('https://firebasestorage.googleapis.com/v0/b/paincare-a9e20.appspot.com/o/Downloads%2FForms%2FPTO-Request-Form-1.pdf?alt=media&token=d3be8f8c-f2b3-49db-a0e5-ed6456814655', '_blank') }} leftIcon={<FaCloudDownloadAlt />} size="sm">PDF</Button>
                </Flex>
                <form onSubmit={handleSubmit(submitData)}>
                    <HStack mb="5">
                        <FormControl id="name" isReadOnly>
                            <FormLabel>NAME</FormLabel>
                            <Input name="name" defaultValue={user.fullName} />
                        </FormControl>
                        <FormControl id="jobTitle" isReadOnly>
                            <FormLabel>JOB TITLE</FormLabel>
                            <Input name="jobTitle" defaultValue={user.jobTitle} />
                        </FormControl>
                    </HStack>
                    <HStack mb="5">
                        <FormControl id="manager" isInvalid={errors.manager}>
                            <FormLabel>Manager</FormLabel>
                            <Input name="manager" placeholder="Enter Manager name" ref={register({ required: "Manager is required." })} />
                            <FormErrorMessage>{errors.manager && errors.manager.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Office Side</FormLabel>
                            <Controller
                                control={control}
                                name="officeSide"
                                defaultValue="Front"
                                render={(props) => (
                                    <RadioGroup name={props.name} defaultValue="Front" onChange={props.onChange}>
                                        <HStack>
                                            <Radio value="Front">Front</Radio>
                                            <Radio value="Back">Back</Radio>
                                        </HStack>
                                    </RadioGroup>
                                )}
                            />
                        </FormControl>
                    </HStack>
                    <FormControl mb="5" width={["100%", "100%", "100%", "50%"]} isInvalid={errors.homeCellNumber}>
                        <FormLabel>Emp. Home/Cell Number</FormLabel>
                        <Input name="homeCellNumber" type="telephone" ref={register({ required: "Home / Cell Number is required." })} />
                    </FormControl>
                    <Text mb="3" mt="10" decoration="underline">PLEASE CHECK ONE OF THE FOLLOWING REASONS FOR YOUR LEAVE REQUEST:</Text>
                    <FormControl mb="5">
                        <RadioGroup onChange={(value) => { setReason(value) }}>
                            <Stack>
                                <Radio value="Own Serious Health">Your own serious health condition</Radio>
                                <HStack>
                                    <Radio value="Birth, placement or adoption">Birth, placement or adoption</Radio>
                                    {(reason === "Birth, placement or adoption" || reason === "Parental (Bonding leave)" || reason === "Pregnancy Disability Leave") &&
                                        <RadioGroup onChange={(value) => { setReason(value) }}>
                                            <HStack>
                                                <Radio value="Parental (Bonding leave)">Parental (Bonding leave)</Radio>
                                                <Radio value="Pregnancy Disability Leave">Pregnancy Disability Leave</Radio>
                                            </HStack>
                                        </RadioGroup>
                                    }
                                </HStack>
                                <Radio value="Eligible Family Member with a serious health condition">Eligible Family Member with a serious health condition</Radio>
                            </Stack>
                        </RadioGroup>
                    </FormControl>
                    <HStack mb="5">
                        <FormControl id="name" isInvalid={errors.reasonName}>
                            <FormLabel>Name</FormLabel>
                            <Input name="reasonName" ref={register({ required: "Name is Required." })} />
                            <FormErrorMessage>{errors.reasonName && errors.reasonName.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl id="reasonDOB" isInvalid={errors.reasonDOB}>
                            <FormLabel>Date of Birth</FormLabel>
                            <Controller
                                render={props => (
                                    <DatePicker onChange={props.onChange} size="large" format="YYYY-MM-DD" style={{ width: "100%" }} />
                                )}
                                name="reasonDOB"
                                control={control}
                                defaultValue={null}
                                rules={{ required: "Date required." }}
                            />
                            <FormErrorMessage>{errors.reasonDOB && errors.reasonDOB.message}</FormErrorMessage>
                        </FormControl>
                    </HStack>
                    <Text mb="3" fontWeight="bold">Relation:</Text>
                    <FormControl mb="5">
                        <RadioGroup onChange={(value) => setRelation(value)}>
                            <Stack>
                                <Radio value="Sopuse">Sopuse</Radio>
                                <HStack>
                                    <Radio value="Domestic Partner (please sepcify)">Domestic Partner (please sepcify)</Radio>
                                    {
                                        (relation === "Domestic Partner (please sepcify)" || relation === "State registered" || relation === "Country registered" || relation === "Affidavit of domestic partnership") &&
                                        <RadioGroup>
                                            <HStack>
                                                <Radio value="State registered">State registered</Radio>
                                                <Radio value="Country registered">Country registered</Radio>
                                                <Radio value="Affidavit of domestic partnership">Affidavit of domestic partnership</Radio>
                                            </HStack>
                                        </RadioGroup>
                                    }
                                </HStack>
                                <Radio value="Child/Stepchild">Child/Stepchild</Radio>
                                <Radio value="Parent">Parent</Radio>
                                <Radio value="Parent-in-law">Parent-in-law</Radio>
                                <Radio value="Domestic Partner Child">Domestic Partner Child</Radio>
                                <Radio value="Domestic Partner's Parent">Domestic Partner's Parent</Radio>
                                <Radio value="Grandparent">Grandparent</Radio>
                                <Radio value="Grandchild">Grandchild</Radio>
                                <Radio value="Other">Other:</Radio>
                            </Stack>
                        </RadioGroup>
                    </FormControl>
                    <FormControl mb="5">
                        <Checkbox name="beravment" onChange={(e) => setBeravment(e.currentTarget.checked)}>Beravment:</Checkbox>
                    </FormControl>
                    {
                        beravment &&
                        <Stack>
                            <FormControl mb="5" width={["100%", "100%", "100%", "50%"]} id="nameOfFamilyMember" isInvalid={errors.nameOfFamilyMember}>
                                <FormLabel>Name of Family member</FormLabel>
                                <Input name="nameOfFamilyMember" ref={register({ required: "Name of Family Required." })} />
                                <FormErrorMessage>{errors.nameOfFamilyMember && errors.nameOfFamilyMember.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl mb="5" width={["100%", "100%", "100%", "50%"]} id="dateYouLearnedOfTheDeath" isInvalid={errors.dateYouLearnedOfTheDeath}>
                                <FormLabel>Date you learned of the death</FormLabel>
                                <Controller
                                    render={props => (
                                        <DatePicker onChange={props.onChange} size="large" format="YYYY-MM-DD" style={{ width: "100%" }} />
                                    )}
                                    name="dateYouLearnedOfTheDeath"
                                    control={control}
                                    defaultValue={null}
                                    rules={{ required: "Date required." }}
                                />
                                <FormErrorMessage>{errors.dateYouLearnedOfTheDeath && errors.dateYouLearnedOfTheDeath.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl mb="5" width={["100%", "100%", "100%", "50%"]} id="relationship" isInvalid={errors.relationship}>
                                <FormLabel>Relationship: (please specify from list above):</FormLabel>
                                <Input name="relationship" ref={register({ required: "Relationship is Required." })} />
                                <FormErrorMessage>{errors.relationship && errors.relationship.message}</FormErrorMessage>
                            </FormControl>
                        </Stack>
                    }
                    <FormControl mb="5">
                        <Controller
                            name="militaryLeaveRelated"
                            control={control}
                            defaultValue={true}
                            render={(props) => (
                                <Checkbox
                                    onChange={props.onChange}
                                    name="militaryleaveRelated"
                                    value="Military Leave Related"
                                >
                                    Military leave related to a Qualifying Exigency or Injured Service Member Caregiver Leave</Checkbox>
                            )}
                        />
                    </FormControl>
                    <Text mb="5" decoration="underline" fontWeight="bold">DURATION AND TYPE OF ABSENCE:</Text>
                    <FormControl mb="5">
                        <FormLabel>Type of Absence</FormLabel>
                        <Stack mb="5">
                            <Checkbox name="continuousLeave" onChange={(e) => setContinuousLeave(e.currentTarget.checked)}>Continuous Leave (provide dates)</Checkbox>
                            {
                                continuousLeave &&
                                <HStack>
                                    <FormControl id="continuousLeaveFrom" isInvalid={errors.continuousLeaveFrom}>
                                        <Controller
                                            render={props => (
                                                <DatePicker onChange={props.onChange} size="large" format="YYYY-MM-DD" style={{ width: "100%" }} />
                                            )}
                                            name="continuousLeaveFrom"
                                            control={control}
                                            defaultValue={null}
                                            rules={{ required: "Date required." }}
                                        />
                                        <FormErrorMessage>{errors.continuousLeaveFrom && errors.continuousLeaveFrom.message}</FormErrorMessage>
                                    </FormControl>
                                    <Text>to</Text>
                                    <FormControl id="continuousTo" isInvalid={errors.continuousTo}>
                                        <Controller
                                            render={props => (
                                                <DatePicker onChange={props.onChange} size="large" format="YYYY-MM-DD" style={{ width: "100%" }} />
                                            )}
                                            name="continuousTo"
                                            control={control}
                                            defaultValue={null}
                                            rules={{ required: "Date required." }}
                                        />
                                        <FormErrorMessage>{errors.continuousTo && errors.continuousTo.message}</FormErrorMessage>
                                    </FormControl>
                                </HStack>
                            }
                        </Stack>
                        <Stack>
                            <Checkbox name="intermittentLeave" onChange={(e) => setIntermittent(e.currentTarget.checked)}>Intermittent Leave (provide dates)</Checkbox>
                            {
                                intermittent &&
                                <HStack>
                                    <FormControl id="intermittentLeaveFrom" isInvalid={errors.intermittentLeaveFrom}>
                                        <Controller
                                            render={props => (
                                                <DatePicker onChange={props.onChange} size="large" format="YYYY-MM-DD" style={{ width: "100%" }} />
                                            )}
                                            name="intermittentLeaveFrom"
                                            control={control}
                                            defaultValue={null}
                                            rules={{ required: "Date required." }}
                                        />
                                        <FormErrorMessage>{errors.intermittentLeaveFrom && errors.intermittentLeaveFrom.message}</FormErrorMessage>
                                    </FormControl>
                                    <Text>to</Text>
                                    <FormControl id="intermittentLeaveTo" isInvalid={errors.intermittentLeaveTo}>
                                        <Controller
                                            render={props => (
                                                <DatePicker onChange={props.onChange} size="large" format="YYYY-MM-DD" style={{ width: "100%" }} />
                                            )}
                                            name="intermittentLeaveTo"
                                            control={control}
                                            defaultValue={null}
                                            rules={{ required: "Date required." }}
                                        />
                                        <FormErrorMessage>{errors.intermittentLeaveTo && errors.intermittentLeaveTo.message}</FormErrorMessage>
                                    </FormControl>
                                </HStack>
                            }
                        </Stack>
                    </FormControl>
                    <Text mb="3">If you expect to be off work on an intermittent basis or work less than your normal schedule, please coordinate with your manager to ensure coverage during your absence.</Text>
                    <Text mb="3">***Your accrued PTO will be used during your leave of absence. ***</Text>
                    <Text mb="3">If your leave is approved for appointments or for prescheduled leave, you must notify your supervisor in advance and designate your absence as OFLA. If you are approved for flare ups, you must designate your leave as OFLA at the time you call in, otherwise your absence may count against the Pain Care Specialistsabsence/attendance policy.</Text>
                    <Text mb="5" color="red.500">
                        ** Employee certifies that all information provided is true and complete to the best of his/her knowledge. Falsification of this form is grounds for disciplinary action, up to and including dismissal.
                    </Text>
                    <Center mt="10">
                        <Button colorScheme="blue" width={["100%", "100%", "100%", "10%"]} isLoading={isDisabled} disabled={isDisabled} type="submit" >Submit</Button>
                    </Center>
                </form>
            </Box>
        </Container>
    )
}

export default RequestForOFLALeaveForm