import React, { useEffect } from 'react'
import {
  Center,
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  RadioGroup,
  Td,
  Radio,
  Text,
  Tr,
  Table,
  Tbody,
} from '@chakra-ui/react'
import { CardContent } from '../../../../../../Components/Card/card'
import { Controller, useForm } from 'react-hook-form'
import moment from 'moment'
import { totalNonWebEnabledPage } from '../..'

const _ = require('underscore')

function ThirAcceptingPatients({ formView }) {
  const {
    // handleSubmit,
    // reset,
    // register,
    // errors,
    control,
    setValue,
    // clearErrors,
  } = useForm()

  useEffect(() => {
    if (formView) {
      const data = formView
      _.keys(data).map(e => {
        return setValue(e, data[e])
      })
    }
  }, [formView, setValue])

  const questionName = [
    { title: 'Bowel incontinence:', name: 'bowelIncontinence' },
    { title: 'Bladder incontinence:', name: 'bladderIncontinence' },
    { title: 'Nausea:', name: 'nausea' },
    { title: 'Vomiting:', name: 'vomiting' },
    { title: 'Fevers:', name: 'fevers' },
    { title: 'Chills:', name: 'chills' },
    { title: 'Sinus congestion:', name: 'sinusCongestion' },
    { title: 'Chest pain:', name: 'chestPain' },
    { title: 'Weight loss:', name: 'weightLoss' },
    { title: 'Rash:', name: 'rash' },
    { title: 'Diabetes:', name: 'diabetes' },
    { title: 'Hearing loss:', name: 'hearingLoss' },
    { title: 'Chance of being pregnant:', name: 'chancePregnant:' },
    { title: 'Trouble swallowing:', name: 'troubleSwallowing' },
    { title: 'Abnormal bruising or bleeding', name: 'abnormalBruising' },
    { title: 'Hepatitis C positive:', name: 'hepatitis' },
    { title: 'HIV positive:', name: 'hiv' },
    { title: 'Change in sexual function:', name: 'changeSexual' },
    { title: 'Muscle pain and spasm:', name: 'painSpasm' },
    { title: 'Weakness:', name: 'weakness' },
    { title: 'Headache:', name: 'headache' },
    { title: 'Tingling/numbness:', name: 'tingling' },
    { title: 'Constipation:', name: 'constipation' },
    { title: 'Change in vision:', name: 'changeVision:' },
    { title: 'Shortness of breath:', name: 'shortnessBreath:' },
    { title: 'Depression:', name: 'depression' },
    { title: 'Suicide attempts:', name: 'suicideAttempts' },
    { title: 'Suicidal thoughts:', name: 'suicidalThoughts' },
    { title: 'Attention deficit disorder:', name: 'attentionDeficit' },
    { title: 'Obsessive compulsive disorder:', name: 'obsessiveCompulsive' },
    { title: 'Bipolar:', name: 'bipolar' },
    { title: 'Schizophrenia:', name: 'schizophrenia' },
    { title: 'Mental health changes:', name: 'mentalHealthChanges' },
    { title: 'Undergoing counseling:', name: 'undergoingCounseling:' },
    { title: 'High stress level:', name: 'highStress' },
    {
      title: 'Psychiatric hospitalization:',
      name: 'psychiatricHospitalization',
    },
    { title: 'Homicidal ideation:', name: 'homicidalIdeation' },
  ]

  return (
    <CardContent>
      <Box w="100%">
        <Flex
          w="100%"
          pb="10px"
          direction={{ base: 'column', sm: 'column', md: 'row', lg: 'row' }}
        >
          <Box w="100%" pr={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}>
            <Controller
              name="namePatient"
              control={control}
              defaultValue=""
              render={props => (
                <FormControl>
                  <FormLabel>Name</FormLabel>
                  <Input
                    _readOnly={{
                      border: '1px solid #ccc',
                    }}
                    variant="no-effects"
                    bg="#F9F9F9"
                    border="1px solid black"
                    isReadOnly
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                  />
                </FormControl>
              )}
            />
          </Box>
          <Box
            w="100%"
            pt={{ base: '10px', md: '0px' }}
            pl={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}
          >
            <Controller
              name="DOB"
              control={control}
              defaultValue={moment(new Date()).format('yyyy-MM-DD')}
              rules={{
                required: {
                  value: true,
                  message: 'Please enter your date.',
                },
              }}
              render={props => (
                <FormControl>
                  <FormLabel>DOB (mm/dd/yy)</FormLabel>
                  <Input
                    _readOnly={{
                      border: '1px solid #ccc',
                    }}
                    variant="no-effects"
                    bg="#F9F9F9"
                    border="1px solid black"
                    isReadOnly
                    type="date"
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                  />
                </FormControl>
              )}
            />
          </Box>
        </Flex>
        <Text pt="20px" pb="20px" fontWeight="bold">
          New Patient Intake Form, Page 3 of 3
        </Text>
        <Text pb="10px" fontSize="14px" fontWeight="bold" textAlign="center">
          Please check Yes or No for each question
        </Text>
        <Box overflow="auto" h="100%">
          <Table variant="unstyled">
            <Tbody p="0px">
              {questionName.map((e, i) => {
                return (
                  <Tr key={i}>
                    <Td p="0px" py="1rem">
                      <Text w="max-content" textAlign="start">
                        {e.title}
                      </Text>
                    </Td>
                    <Controller
                      name={e.name}
                      control={control}
                      defaultValue=""
                      render={props => (
                        <FormControl>
                          <CheckBoxNewPatient
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            defaultValue={formView?.[e.name]}
                          />
                        </FormControl>
                      )}
                    />
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </Box>
      </Box>
      <Center pt="2rem">{`5/${totalNonWebEnabledPage}`}</Center>
    </CardContent>
  )
}

export { ThirAcceptingPatients }

function CheckBoxNewPatient({ onChange, defaultValue }) {
  return (
    <RadioGroup isDisabled onChange={onChange} defaultValue={defaultValue}>
      <Td px="1rem" py="0.25rem">
        <Radio value="Yes">Yes</Radio>
      </Td>
      <Td px="1rem" py="0.25rem">
        <Radio value="No">No</Radio>
      </Td>
    </RadioGroup>
  )
}
