import React, { useState } from 'react'
import {
    Box,
    FormErrorMessage,
    FormLabel,
    FormControl,
    FormHelperText,
    Stack,
    Input,
    Button,
    useToast
} from "@chakra-ui/react"
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { signInWithEmailAndPassword, updatePassword, signOut } from 'firebase/auth'
import { auth } from '../config/firebase'


function AccountSetting({ user }) {

    const { handleSubmit, register, errors } = useForm()
    const history = useHistory()
    const toast = useToast()
    const [isDisabled, setIsDisabled] = useState(false)
    const [password, setPassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState()

    function updateAccount(value) {
        setIsDisabled(true)
        signInWithEmailAndPassword(auth, user.email, value.oldPassword)
            .then((res) => {
                if (res.user) {
                    if (confirmPassword) {
                        updatePassword(res.user, value.confirmPassword)
                            .then(() => {
                                signOut(auth)
                                history.replace('/login')
                                setIsDisabled(false)
                            })
                            .catch((err) => {
                                setIsDisabled(false)
                                toast({
                                    title: err.message,
                                    status: 'error',
                                    isClosable: true,
                                })
                            })
                    }
                }

            })
            .catch((err) => {
                setIsDisabled(false)
                toast({
                    title: err.message,
                    status: 'error',
                    isClosable: true,
                })
            })
    }

    return (
        <Box>
            <form onSubmit={handleSubmit(updateAccount)} id="change-password">
                <Stack>
                    <FormControl mt="3" id="password" isInvalid={errors.password}>
                        <FormLabel>New Password</FormLabel>
                        <Input name="password" placeholder="Input new password here" type="password" value={password} onChange={e => setPassword(e.target.value)} ref={register({ required: 'Password required.' })} />
                        <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl mt="3" isInvalid={errors.confirmPassword}>
                        <FormLabel>Confirm New Password</FormLabel>
                        <Input
                            type="password"
                            id="confirmPassword"
                            name="confirmPassword"
                            placeholder="Confirm new password again"
                            value={confirmPassword}
                            onChange={e => setConfirmPassword(e.target.value)}
                            ref={register({ required: 'Please confirm password.' })}
                        />
                        <FormErrorMessage>{errors.confirmPassword && errors.confirmPassword.message}</FormErrorMessage>
                        {!!confirmPassword && confirmPassword !== password && (
                            <FormHelperText id="confirm-password">
                                Passwords do not match
                            </FormHelperText>
                        )}
                    </FormControl>
                    <FormControl mt="3" id="oldPassword" isInvalid={errors.oldPassword}>
                        <FormLabel>Old Password</FormLabel>
                        <Input name="oldPassword" placeholder="Enter old password for change" type="password" ref={register({ required: 'Must input old password before change' })} />
                        <FormErrorMessage>{errors.oldPassword && errors.oldPassword.message}</FormErrorMessage>
                    </FormControl>
                </Stack>
                <Button type="submit" colorScheme="blue" w="100%" size="sm" mt="5" isLoading={isDisabled} disabled={(isDisabled) || (confirmPassword !== password ? true : false)}>Change Password</Button>
            </form>
        </Box>
    )
}

export default AccountSetting