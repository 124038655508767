import { Image, Text, View } from '@react-pdf/renderer'
import moment from 'moment'
import React from 'react'

function SignaturePermission({ fillableData, styles }) {
  return (
    <View style={{ ...styles.gapBox }}>
      <View
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          fontSize: 8,
        }}
      >
        <Image
          style={{ width: '110px', objectFit: 'cover' }}
          src="/paincare-web-v2.png"
        />
        <View style={styles.boxtitle}>
          <Text>2480 Liberty Street NE, Suite 180, Salem, OR 97301</Text>
        </View>
        <View style={styles.boxtitle}>
          <Text>Office: (503) 371-1010 Fax: (503) 371-0805</Text>
        </View>
      </View>
      <Text
        style={{
          textAlign: 'center',
          paddingLeft: 8,
          paddingRight: 8,
          paddingTop: 4,
        }}
      >
        PERMISSION TO RELEASE CONFIDENTIAL MEDICAL INFORMATION TO A FAMILY
        MEMBER, FRIEND OR LEGAL REPRESENTAITVE
      </Text>
      <Text style={{ textAlign: 'center', paddingLeft: 20, paddingRight: 20 }}>
        IMPORTANT NOTE: The law prohibits release of confidential Medical
        information to any entity without the written, voluntary consent of the
        undersigned patient.
      </Text>
      {/* <View style={{ width: '' }}></View> */}
      <View
        style={{
          width: '460px',
          paddingTop: '8px',
          gap: '4px',
        }}
      >
        <View style={{ width: '100%' }}>
          <View style={styles.boxRowData}>
            <View
              style={{
                ...styles.boxRowData,
                width: '100%',
                gap: '0px',
              }}
            >
              <Text style={{ width: '120px' }}>Name of Patient:</Text>
              <Text style={{ ...styles.paddingLeftFormData }}>
                {fillableData.namePatient ? fillableData.namePatient : ''}
              </Text>
            </View>
            <View
              style={{
                ...styles.boxRowData,
                width: '100%',
                gap: '0px',
              }}
            >
              <Text style={{ width: '90px' }}>Date of Birth:</Text>
              <Text style={{ ...styles.paddingLeftFormData }}>
                {fillableData?.dobPatient
                  ? moment(fillableData?.dobPatient).format('MM/DD/YYYY')
                  : ''}
              </Text>
            </View>
          </View>
        </View>
        <Text
          style={{ paddingTop: 20, lineHeight: 1.25, textAlign: 'justify' }}
        >
          I give permission for Pain Care Specialists of Oregon, LLC, or their
          designated representative to communicate information about my medical
          condition and treatment to:
        </Text>
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            paddingTop: 20,
            paddingBottom: 1,
          }}
        >
          {fillableData?.permission?.map((data, index) => {
            return (
              <View style={{ ...styles.boxRowData, gap: 20 }} key={index}>
                <View
                  style={{
                    ...styles.boxRowData,
                    width: '100%',
                    gap: '0px',
                    flexDirection: 'column',
                  }}
                >
                  <Text
                    style={{
                      borderBottom: '1px dotted #000',
                      paddingBottom: 2,
                      width: '100%',
                    }}
                  >
                    {data.name ? data.name : ' '}
                  </Text>
                  <Text style={{ width: '100%', paddingTop: 6 }}>Name</Text>
                </View>
                <View
                  style={{
                    ...styles.boxRowData,
                    width: '100%',
                    gap: '0px',
                    flexDirection: 'column',
                  }}
                >
                  <Text
                    style={{
                      borderBottom: '1px dotted #000',
                      paddingBottom: 2,
                      width: '100%',
                    }}
                  >
                    {data.phone ? data.phone : ' '}
                  </Text>
                  <Text style={{ width: '100%', paddingTop: 6 }}>Phone</Text>
                </View>
                <View
                  style={{
                    ...styles.boxRowData,
                    width: '100%',
                    gap: '0px',
                    flexDirection: 'column',
                  }}
                >
                  <Text
                    style={{
                      borderBottom: '1px dotted #000',
                      paddingBottom: 2,
                      width: '100%',
                    }}
                  >
                    {data.relationship ? data.relationship : ' '}
                  </Text>
                  <Text style={{ width: '100%', paddingTop: 6 }}>
                    Relationship
                  </Text>
                </View>
              </View>
            )
          })}
        </View>
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            paddingTop: 30,
            paddingBottom: 1,
          }}
        >
          <View
            style={{
              width: '100%',
              flexDirection: 'column',
              gap: '5px',
              paddingTop: 10,
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                height: '55px',
                alignItems: 'flex-end',
                gap: 20,
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                  paddingBottom: '2px',
                }}
              >
                <Text
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    paddingBottom: 2,
                  }}
                >
                  {fillableData.namePatient ? fillableData.namePatient : ''}
                </Text>
                <Text
                  style={{
                    paddingTop: 4,
                    width: '100%',
                    textAlign: 'start',
                    borderTop: '1px dotted #000',
                  }}
                >
                  Printed Name of Patient
                </Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                  paddingBottom: '2px',
                }}
              >
                <View
                  style={{
                    width: '175px',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    paddingBottom: 2,
                  }}
                >
                  {fillableData.signaturePatient.includes('data:image/') ? (
                    <Image
                      style={{
                        width: '80px',
                      }}
                      src={fillableData.signaturePatient}
                    />
                  ) : (
                    <Text style={{ textAlign: 'center' }}>
                      {fillableData.signaturePatient}
                    </Text>
                  )}
                </View>
                <Text
                  style={{
                    paddingTop: 4,
                    width: '100%',
                    textAlign: 'start',
                    borderTop: '1px dotted #000',
                  }}
                >
                  Signature
                </Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                  paddingBottom: '2px',
                }}
              >
                <Text
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    paddingBottom: 2,
                  }}
                >
                  {fillableData?.createdAt
                    ? moment(fillableData?.createdAt).format('MM/DD/YYYY')
                    : ''}
                </Text>
                <Text
                  style={{
                    paddingTop: 4,
                    width: '100%',
                    textAlign: 'start',
                    borderTop: '1px dotted #000',
                  }}
                >
                  Date
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              width: '100%',
              flexDirection: 'column',
              gap: '5px',
              paddingTop: 10,
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                height: '55px',
                alignItems: 'flex-end',
                gap: 20,
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                  paddingBottom: '2px',
                }}
              >
                <Text
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    paddingBottom: 2,
                  }}
                >
                  {fillableData.namePatientPermission
                    ? fillableData.namePatientPermission
                    : ''}
                </Text>
                <Text
                  style={{
                    paddingTop: 4,
                    width: '100%',
                    textAlign: 'start',
                    borderTop: '1px dotted #000',
                  }}
                >
                  Printed Name of Representative
                </Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                  paddingBottom: '2px',
                }}
              >
                <View
                  style={{
                    width: '175px',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    paddingBottom: 2,
                  }}
                >
                  {fillableData.signaturePatientRepresentative.includes(
                    'data:image/'
                  ) ? (
                    <Image
                      style={{
                        width: '80px',
                      }}
                      src={fillableData.signaturePatientRepresentative}
                    />
                  ) : (
                    <Text style={{ textAlign: 'center' }}>
                      {fillableData.signaturePatientRepresentative}
                    </Text>
                  )}
                </View>
                <Text
                  style={{
                    paddingTop: 4,
                    width: '100%',
                    textAlign: 'start',
                    borderTop: '1px dotted #000',
                  }}
                >
                  Signature
                </Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                  paddingBottom: '2px',
                }}
              >
                <Text
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    paddingBottom: 2,
                  }}
                >
                  {fillableData?.createdAt
                    ? moment(fillableData?.createdAt).format('MM/DD/YYYY')
                    : ''}
                </Text>
                <Text
                  style={{
                    paddingTop: 4,
                    width: '100%',
                    textAlign: 'start',
                    borderTop: '1px dotted #000',
                  }}
                >
                  Date
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

export default SignaturePermission
