import React, { useState, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Logo from '../../Logo.png'
import {useForm, Controller} from 'react-hook-form'
import SignatureCanvas from 'react-signature-canvas'
import {
    Box,
    Container,
    Image,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Input,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Checkbox,
    CheckboxGroup,
    Stack,
    HStack,
    Text,
    Divider,
    SimpleGrid,
    Button
} from '@chakra-ui/react'
import {
    ChevronRightIcon
} from '@chakra-ui/icons'

function NonAuthorizationLetter() {

    const { register, handleSubmit, formState, errors, control, watch } = useForm()
    const [ Other, setOther] = useState("")
    const [ Drug, setDrug] = useState("")
    const [ Treatment, setTreatment ] = useState("")
    const [ Period, setPeriod ] = useState("")
    const [ Injuries, setInjuries ] = useState("")
    const history = useHistory()
    const SigPatient = useRef({})
    const SigbyLaw = useRef({})


    const formatIntoPng = () => {
        if (SigPatient.current) {
            const DataUrl = SigPatient.current.getTrimmedCanvas().toDataURL()
            return DataUrl
        }
        if (SigbyLaw.current){
            const DataUrl = SigPatient.current.getTrimmedCanvas().toDataURL()
            return DataUrl
        }
    }
console.log(watch());
    const onSubmit = (values) => {
        console.log(values);
        history.push("#")
    }
  return (
    <Container maxW="100%" mt="5">
        <Image cursor="pointer" src={Logo} alt="paincare logo" htmlWidth="100px" />
            <Breadcrumb
                spacing="8px"
                separator={<ChevronRightIcon color="gray.500" />}
                bg="gray.100" p="1.5"
                borderRadius="0.3em"
                mt="5"
            >
                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to="/">Forms</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to="/non-web-enable-form">Web Enable New Patient Packet</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to="/non-web-enable-form/kios-checkin">New Patient Intake Form for  Kiosk Check-in</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink>Authorization Letter</BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>
            <Container maxW={'container.xl'}>
                <Box mt="10" mb="10" textAlign="center" fontSize="lg" fontWeight="bold">
                    {/* <Box>
                        New Patient Intake Form for  Kiosk Check-in
                    </Box> */}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormControl isInvalid={errors.authorizeBy}>
                            <Stack direction={['column','row']} mt={"5"}>
                                <FormLabel>I authorize</FormLabel>
                                <Input w={"60%"} name={"authorizeBy"} ref={register({required:"Impotant information required."})} />
                                <FormLabel>to release a copy of the medical information for</FormLabel>
                            </Stack>
                                <FormErrorMessage>{errors.authorizeBy && errors.authorizeBy.message}</FormErrorMessage>
                        </FormControl>
                        <Stack direction={['column','row']}>
                        <FormControl isInvalid={errors.NameOfRecipient}>
                            <HStack mt={"5"}>
                                <FormLabel>to</FormLabel>
                                <Input name="NameOfRecipient" placeholder="(Name of Recipient)" ref={register({required:"Impotant information required."})} />
                            </HStack>
                            <FormErrorMessage>{errors.NameOfRecipient && errors.NameOfRecipient.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl>
                            <HStack  mt={"5"}>
                                <FormLabel w={"13rem"}>Mailling Address</FormLabel>
                                <Input name="MaillingAddress" ref={register()} />
                            </HStack>
                        </FormControl>
                        <FormControl>
                            <HStack  mt={"5"}>
                                <FormLabel w={"7rem"}>or Fax No:</FormLabel>
                                <Input name="Fax" ref={register()} />
                            </HStack>
                        </FormControl>
                        </Stack>
                        <Box mt={"5"}>
                            <Text textAlign={'left'}>The information will be used on my behalf for following purpos(s):</Text>
                            <Divider mt={"5"} borderStyle={'dotted'} borderColor={'gray.500'} />
                        </Box>
                        <Box>
                            <FormControl mt={'5'} isInvalid={errors.record1}>
                                <Box>
                                    <FormLabel textAlign={'left'}>
                                        By initialing the spaces below, I specifically authorize the release of the following records, if such records exist:
                                    </FormLabel>
                                </Box>
                                <Stack mt={"5"}>
                                    <Controller 
                                        render={(props) => (
                                            <CheckboxGroup onChange={props.onChange} onBlur={props.onBlur}>
                                                <SimpleGrid columns={[1,2]}>
                                                    <Checkbox fontSize={'14px'} textAlign={'left'} value={"All hospital records (including nursing records and progress notes)"}>All hospital records (including nursing records and progress notes)</Checkbox>
                                                    <Checkbox fontSize={'14px'} textAlign={'left'} value={"Transcribed hospital reports"}>Transcribed hospital reports </Checkbox>
                                                    <Checkbox fontSize={'14px'} textAlign={'left'} value={"Medical records needed for continuity of care"}>Medical records needed for continuity of care </Checkbox>
                                                    <Checkbox fontSize={'14px'} textAlign={'left'} value={"Most recent five year history"}>Most recent five year history</Checkbox>
                                                    <Checkbox fontSize={'14px'} textAlign={'left'} value={"Diagnostic imaging reports"}>Diagnostic imaging reports</Checkbox>
                                                    <Checkbox fontSize={'14px'} textAlign={'left'} value={"Laboratory reports"}>Laboratory reports</Checkbox>
                                                    <Checkbox fontSize={'14px'} textAlign={'left'} value={"Pathology reports"}>Pathology reports</Checkbox>
                                                    <Checkbox fontSize={'14px'} textAlign={'left'} value={"Billing Statements"}>Billing Statements</Checkbox>
                                                    <Checkbox fontSize={'14px'} textAlign={'left'} value={"Emergency and urgency care records"}>Emergency and urgency care records</Checkbox>
                                                    <Checkbox fontSize={'14px'} textAlign={'left'} value={"Physical Therapy records"}>Physical Therapy records</Checkbox>
                                                    <Checkbox fontSize={'14px'} textAlign={'left'} value={"Dental records"}>Dental records</Checkbox>
                                                    <Checkbox fontSize={'14px'} textAlign={'left'} value={"Clinician office chart notes"}>Clinician office chart notes</Checkbox>
                                                    <HStack>
                                                        <Checkbox value={Other}>Other</Checkbox>
                                                        <Input onChange={(e) => {setOther(e.target.value)}} />
                                                    </HStack>
                                                </SimpleGrid>
                                            </CheckboxGroup>
                                        )}
                                        defaultValue={null}
                                        name="record1"
                                        control={control}
                                        rules={{ required: 'Impotant information required.' }}
                                    />
                                </Stack>
                                <FormErrorMessage>{errors.record1 && errors.record1.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl mt={'5'} isInvalid={errors.record2}>
                                <Box>
                                    <FormLabel textAlign={'left'}>
                                        Please send the entire medical record (all information) to the above named recipient. The recipient understands this record may be voluminous and agrees to pay all reasonable charges associated with providing this record.
                                    </FormLabel>
                                </Box>
                                <Stack mt={'5'}>
                                <Controller 
                                        render={(props) => (
                                            <CheckboxGroup onChange={props.onChange} onBlur={props.onBlur}>
                                                <Checkbox  value={"*HIV/AIDS-related records"}>*HIV/AIDS-related records</Checkbox>
                                                <Checkbox  value={"*Genetic testing information"}>*Genetic testing information </Checkbox>
                                                <Text textAlign={'left'}>*Must be specifically selected to be included in other documents.</Text>
                                                <HStack>
                                                    <Checkbox textAlign={'left'}  value={`**Drug/Alcohol diagnosis, treatment or referral information ${Drug}`}>**Drug/Alcohol diagnosis, treatment or referral information </Checkbox>
                                                    <Input onChange={(e)=>{setDrug(e.target.value)}} />
                                                </HStack>
                                                <Text textAlign={'left'}>**Federal Regulation 42 CFR Part 2, requires a description of how much and what kind of information is to be disclosed.</Text>
                                                <HStack>
                                                    <Checkbox textAlign={'left'}  value={`This authorization is limited to the following treatment: ${Treatment}`}>This authorization is limited to the following treatment:</Checkbox>
                                                    <Input onChange={(e)=>{setTreatment(e.target.value)}} />
                                                </HStack>
                                                <HStack>
                                                    <Checkbox textAlign={'left'}  value={`This authorization is limited to the time period: ${Period}`}>This authorization is limited to the time period:</Checkbox>
                                                    <Input onChange={(e)=>{setPeriod(e.target.value)}} />
                                                </HStack>
                                                <HStack>
                                                    <Checkbox textAlign={'left'} value={`This authorization is limited to a worker's compensation claim for injuries of: ${Injuries}`}>This authorization is limited to a worker's compensation claim for injuries of: </Checkbox>
                                                    <Input onChange={(e)=>{setInjuries(e.target.value)}} />
                                                </HStack>
                                            </CheckboxGroup>
                                        )}
                                        defaultValue={null}
                                        name="record2"
                                        control={control}
                                        rules={{ required: 'Impotant information required.' }}
                                    />
                                </Stack>
                                <FormErrorMessage>{errors.record2 && errors.record2.message}</FormErrorMessage>
                            </FormControl>
                            <Box>
                                <Text textAlign={'left'}>
                                    This authorization may be revoked at any time. The only exception is when action has been taken in reliance on the authorization, unless revoked earlier, this consent will expire 180 days from the date of signing or shall emain in effect for the period reasonably needed to complete the request.
                                </Text>
                            </Box>
                            <Box mt={10}>
                                <Stack direction={['column','row']} justifyContent={'space-between'} alignItems={'end'}>
                                    <FormControl
                                        w={"500px"}
                                        isInvalid={errors.SigPatient}
                                    >

                                        <Controller
                                            render={(props) => (
                                                <SignatureCanvas ref={SigPatient} onEnd={()=>{props.onChange(formatIntoPng())}} penColor='black' canvasProps={{width: 500, height: 100, className: 'sigCanvas'}} />
                                                )}
                                                name="SigPatient"
                                                control={control}
                                                rules={{ required: 'Impotant information required.' }}
                                                />
                                                <Divider borderColor={'gray.600'} borderStart={'dotted'} />
                                        <FormLabel textAlign={'center'}>Signature of patient</FormLabel>
                                        <FormErrorMessage>{errors.SigPatient && errors.SigPatient.message}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl maxW={["100%", "100%", "30%", "30%"]} isInvalid={errors.monthPatient && errors.dayPatient && errors.yearPatient}>
                                        <HStack>
                                            <Input name="monthPatient" type="text" placeholder="mm" ref={register({ required: 'Date required.' })} />
                                            <Input name="dayPatient" type="text" placeholder="dd" ref={register({ required: 'Date required.' })} />
                                            <Input name="yearPatient" type="text" placeholder="yyyy" ref={register({ required: 'Date required.' })} />
                                        </HStack>
                                        <FormLabel textAlign={'center'}>Date</FormLabel>
                                        <FormErrorMessage>{errors.dayByLaw && errors.day && errors.yearByLaw && errors.monthByLaw.message}</FormErrorMessage>
                                    </FormControl>
                                </Stack>
                                <Stack direction={['column','row']} justifyContent={'space-between'} alignItems={'end'}>
                                    <FormControl
                                        w={"500px"}
                                        isInvalid={errors.SigbyLaw}
                                    >

                                        <Controller
                                            render={(props) => (
                                                <SignatureCanvas ref={SigbyLaw} onEnd={()=>{props.onChange(formatIntoPng())}} penColor='black' canvasProps={{width: 500, height: 100, className: 'sigCanvas'}} />
                                                )}
                                            name="SigbyLaw"
                                            control={control}
                                            rules={{ required: 'Impotant information required.' }}
                                        />
                                                <Divider borderColor={'gray.600'} borderStart={'dotted'} />
                                        <FormLabel textAlign={'center'}>Signature of person authorized by law</FormLabel>
                                        <FormErrorMessage>{errors.SigbyLaw && errors.SigbyLaw.message}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl maxW={["100%", "100%", "30%", "30%"]} isInvalid={errors.monthByLaw && errors.dayByLaw && errors.yearByLaw}>
                                        <HStack>
                                            <Input name="monthByLaw" type="text" placeholder="mm" ref={register({ required: 'Date required.' })} />
                                            <Input name="dayByLaw" type="text" placeholder="dd" ref={register({ required: 'Date required.' })} />
                                            <Input name="yearByLaw" type="text" placeholder="yyyy" ref={register({ required: 'Date required.' })} />
                                        </HStack>
                                        <FormLabel textAlign={'center'}>Date</FormLabel>
                                        <FormErrorMessage>{errors.dayByLaw && errors.day && errors.yearByLaw && errors.monthByLaw.message}</FormErrorMessage>
                                    </FormControl>
                                </Stack>
                                
                            </Box>
                        </Box>
                        <Button my={"10"} colorScheme={'blue'} type='submit' isLoading={formState.isSubmitting}>Submit</Button>
                    </form>
                </Box>
            </Container>
    </Container>
  )
}

export default NonAuthorizationLetter