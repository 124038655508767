import React from 'react'

import {
  Document,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer'
import './style.module.css'
import moment from 'moment'

function PDFCollectorsCompetencyQuiz({ employeeData }) {
  const styles = StyleSheet.create({
    pdfView: {
      width: window.innerWidth,
      height: window.innerHeight,
      backgroundColor: '#fff',
    },
    page: {
      width: '100%',
      flexDirection: 'column',
      backgroundColor: '#fff',
      alignItems: 'center',
      paddingLeft: 40,
      paddingRight: 40,
    },
    section: {
      width: '100%',
      marginBottom: 20,
      padding: 10,
      alignItems: 'center',
    },
    sectionImg: {
      width: '100%',
      marginTop: 35,
      padding: 0,
      alignItems: 'center',
    },
    sectionTwo: {
      width: '100%',
      marginTop: 15,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    headerTitleContainer: {
      width: '100%',
      marginTop: 15,
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    headerTitleText: {
      fontSize: '22px',
    },
    headersubTitleText: {
      fontSize: '13px',
      fontStyle: 'italic',
    },
    subTitle: {
      fontSize: '12px',
      fontStyle: 'bold',
    },
    choiceContent: {
      paddingBottom: 2,
      fontSize: '10px',
    },
    choiceSelect: {
      flexDirection: 'row',
      width: '50px',
      justifyContent: 'space-between',
      alignSelf: 'flex-start',
      marginLeft: '20px',
      marginTop: '10px',
    },
    contentContainer: {
      width: '100%',
      flexDirection: 'row',
      alignItems: 'flex-start',
      marginTop: 13,
    },
    boxview: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
    },
    img: {
      width: '130px',
      height: 'auto',
      objectFit: 'cover',
    },
    imgcheck: {
      width: '15px',
      height: '15px',
    },
    texttitle: {
      top: 20,
      fontSize: '16px',
      textDecoration: 'underline',
    },
    boxmonthyear: {
      top: 10,
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    textmonthyear: {
      fontSize: '12px',
    },
    textmonthyearborder: {
      fontSize: '12px',
      borderBottom: 1,
      textAlign: 'center',
      width: '150px',
    },
    nameTitle: {
      fontSize: '16px',
    },
    name: {
      fontSize: '16px',
      top: 20,
      textDecoration: 'underline',
    },
    nameDateStyle: {
      justifyContent: 'space-between',
      display: 'flex',
    },
    subTitleNumber: {
      width: '8%',
      fontSize: '12px',
      fontStyle: 'bold',
    },
    stylesCheckImage: {
      flexDirection: 'row',
      marginTop: 4,
    },
    signatureImg: {
      height: '40px',
      width: 'auto',
    },
    textsignature: {
      fontSize: 12,
      textAlign: 'center',
      borderBottom: 1,
      width: '100px',
    },
    textscore: {
      fontSize: 12,
      textAlign: 'center',
      borderBottom: 1,
      width: '50px',
    },
  })

  return (
    <PDFViewer style={styles.pdfView} width={'100%'}>
      <Document
        title={`${employeeData?.name || ''} (${employeeData?.type || ''})`}
      >
        <Page size="A4" style={styles.page}>
          <View style={{ marginTop: '30px' }}>
            <Text>Collectors Competency Quiz</Text>
          </View>

          <>
            <View style={styles.contentContainer}>
              <Text style={styles.subTitle}>
                1.{` `}
                {` `}
                {` `}Two patient identifiers must be used to identify a patient
                before collection process may begin?
              </Text>
            </View>
            <View style={styles.choiceSelect}>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quizOne === 'true' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: -2,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>T</Text>
              </View>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quizOne === 'false' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: -2,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>F</Text>
              </View>
            </View>
          </>

          <>
            <View style={styles.contentContainer}>
              <Text style={styles.subTitle}>
                2.{` `}
                {` `}
                {` `}An unlabeled specimen is acceptable to use for testing?
              </Text>
            </View>
            <View style={styles.choiceSelect}>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quizTwo === 'true' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: -2,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>T</Text>
              </View>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quizTwo === 'false' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: -2,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>F</Text>
              </View>
            </View>
          </>

          <>
            <View style={styles.contentContainer}>
              <Text style={styles.subTitle}>
                3.{` `}
                {` `}
                {` `}The temperature of a sample is used to prove validity of
                sample being collected?
              </Text>
            </View>
            <View style={styles.choiceSelect}>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quizThree === 'true' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: -2,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>T</Text>
              </View>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quizThree === 'false' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: -2,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>F</Text>
              </View>
            </View>
          </>
          <>
            <View style={styles.contentContainer}>
              <Text style={styles.subTitle}>
                4.{` `}
                {` `}
                {` `}Two patient identifiers must be on all specimens submitted
                for testing?
              </Text>
            </View>
            <View style={styles.choiceSelect}>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quizFour === 'true' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: -2,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>T</Text>
              </View>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quizFour === 'false' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: -2,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>F</Text>
              </View>
            </View>
          </>
          <>
            <View style={styles.contentContainer}>
              <Text style={styles.subTitle}>
                5.{` `}
                {` `}
                {` `}Samples that cannot be tested within three days of
                collection should be kept in fridge?
              </Text>
            </View>
            <View style={styles.choiceSelect}>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quizFive === 'true' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: -2,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>T</Text>
              </View>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quizFive === 'false' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: -2,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>F</Text>
              </View>
            </View>
          </>
          <>
            <View style={styles.contentContainer}>
              <Text style={styles.subTitle}>
                6.{` `}
                {` `}
                {` `}This is an acceptable specimen label:
              </Text>
            </View>
            <View
              style={{
                alignSelf: 'flex-start',
                marginLeft: '20px',
                border: '1px',
                padding: '5px',
                borderRadius: '5px',
                marginTop: 6,
                marginBottom: 2,
              }}
            >
              <Text style={styles.choiceContent}>Name: John</Text>
              <Text style={styles.choiceContent}>DOB: 1962</Text>
              <Text style={styles.choiceContent}>Date: 10/3</Text>
              <Text style={styles.choiceContent}>Time: 10:15</Text>
            </View>
            <View style={{ ...styles.choiceSelect, marginTop: '5px' }}>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quizSix === 'true' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: -2,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>T</Text>
              </View>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quizSix === 'false' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: -2,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>F</Text>
              </View>
            </View>
          </>
          <>
            <View style={styles.contentContainer}>
              <Text style={styles.subTitle}>
                7.{` `}
                {` `}
                {` `}Who must sign the patient requisition before testing may
                take place?
              </Text>
            </View>
            <View style={{ alignSelf: 'flex-start', marginLeft: '20px' }}>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quizSeven === 'answerA' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: -2,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>a. Doctor only</Text>
              </View>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quizSeven === 'answerB' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: -2,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>b. Doctor and patient</Text>
              </View>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quizSeven === 'answerC' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: -2,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>c. Patient only</Text>
              </View>
            </View>
          </>
          <>
            <View style={styles.contentContainer}>
              <Text style={styles.subTitle}>
                8.{` `}
                {` `}
                {` `}Samples that can be tested within three days after
                collection may be kept in fridge?
              </Text>
            </View>
            <View style={styles.choiceSelect}>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quizEigth === 'true' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: -2,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>T</Text>
              </View>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quizEigth === 'false' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: -2,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>F</Text>
              </View>
            </View>
          </>
          <>
            <View style={styles.contentContainer}>
              <Text style={styles.subTitle}>
                9.{` `}
                {` `}
                {` `}Temp of the fridge must fall between 2 and 8 degrees
                Celsius?
              </Text>
            </View>
            <View style={styles.choiceSelect}>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quizNine === 'true' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: -2,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>T</Text>
              </View>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quizNine === 'false' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: -2,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>F</Text>
              </View>
            </View>
          </>
          <>
            <View style={styles.contentContainer}>
              <Text style={styles.subTitle}>
                10.{` `}
                {` `}
                {` `}A patient has asked if their friend may accompany them into
                the restroom to help them. This is an acceptable course of
                action when collecting a sample for testing?
              </Text>
            </View>
            <View style={styles.choiceSelect}>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quizTen === 'true' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: -2,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>T</Text>
              </View>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quizTen === 'false' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -5,
                      top: -2,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>F</Text>
              </View>
            </View>
          </>

          <View
            style={{
              ...styles.contentContainer,
              flexDirection: 'row',
              justifyContent: 'start',
              gap: '40px',
              marginTop: '25px',
              alignItems: 'flex-end',
            }}
          >
            <View>
              <View
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                <Image
                  style={styles.signatureImg}
                  src={employeeData?.signatureCollector}
                />
              </View>
              <View>
                <Text
                  style={{
                    ...styles.subTitle,
                    paddingTop: '4px',
                    borderTop: '1px',
                  }}
                >
                  Collectors signature
                </Text>
              </View>
            </View>
            <View>
              <Text style={styles.textsignature}>
                {employeeData?.dateTesting
                  ? moment(employeeData?.dateTesting).format('MM/DD/YYYY')
                  : ' '}
              </Text>
              <Text
                style={{
                  ...styles.subTitle,

                  paddingTop: '4px',
                }}
              >
                Date of testing
              </Text>
            </View>
            <View>
              <Text style={styles.textscore}>
                {employeeData?.score ? employeeData?.score : ' '}
              </Text>

              <Text
                style={{
                  ...styles.subTitle,

                  paddingTop: '4px',
                }}
              >
                Score
              </Text>
            </View>
          </View>

          <View
            style={{
              marginTop: '15px',
              alignSelf: 'flex-start',
            }}
          >
            <Text
              style={{
                ...styles.subTitle,
                fontWeight: 'bold',
                fontSize: '12px',
              }}
            >
              Score must be &gt;70% or collector must be retrained within 90
              days
            </Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  )
}

export default PDFCollectorsCompetencyQuiz
