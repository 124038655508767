import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import {
    Box,
    HStack,
    Stack,
    Container,
    FormControl,
    FormLabel,
    Input,
    Button,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Image,
    Radio,
    RadioGroup,
    CheckboxGroup,
    Checkbox,
    Center,
    // FormErrorMessage,
    InputRightAddon,
    Text,
    Divider,
    SimpleGrid,
    FormErrorMessage
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import Logo from '../../Logo.png'

function NonWebPatientIntakeF() {

    const { handleSubmit, register, formState, control, errors } = useForm()
    const history = useHistory()
    function onSubmit(values) {
        console.log(values)
        let submitData = JSON.stringify({ patientInfo: { ...values } })
        localStorage.setItem("submitData", submitData)
        history.push("/non-web-enable-form/patient-intake-2")
    }

    return (
        <Container maxW="100%" mt="5">
            <Image cursor="pointer" src={Logo} alt="paincare logo" htmlWidth="100px" />
            <Breadcrumb
                spacing="8px"
                separator={<ChevronRightIcon color="gray.500" />}
                bg="gray.100" p="1.5"
                borderRadius="0.3em"
                mt="5"
            >
                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to="/">Forms</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to="/non-web-enable-form">Non Web Enable New Patient Packet</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink>New Patient Intake Form for  Kiosk Check-in 1 of 3</BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>
            <Box mt="10" mb="10" textAlign="center" fontSize="lg" fontWeight="bold">
                Web Enable New Patient Packet
            </Box>
            <Box mb="20">
                <Container maxW={'container.xl'}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormControl isInvalid={errors.PainLocated}>
                            <FormLabel>The pain located mainly at the:(check one)</FormLabel>
                            <Stack direction={['column','row']}>
                            <Controller 
                                render={(props)=>(
                                    <CheckboxGroup onChange={props.onChange} onBlur={props.onBlur}>
                                        <Checkbox value={'Neck'}>Neck</Checkbox>
                                        <Checkbox value={'Mid'}>Mid</Checkbox>
                                        <Checkbox value={'Back'}>Back</Checkbox>
                                        <Checkbox value={'Low Back'}>Low Back</Checkbox>
                                        <Checkbox value={'Shoulder'}>Shoulder</Checkbox>
                                        <Checkbox value={'Hip'}>Hip</Checkbox>
                                        <HStack>
                                            <Checkbox value={'Other'}>other</Checkbox>
                                            <Input name={"OtherPain"} ref={register()}/>
                                        </HStack>
                                    </CheckboxGroup>
                                )}
                                control={control}
                                defaultValue={''}
                                name={"PainLocated"}
                                rules={{ required: 'Impotant information required.' }}
                                />
                            </Stack>
                            <Divider borderColor={'gray.500'} />
                        </FormControl>
                        <Stack mt={'5'}>
                            <FormControl  isInvalid={errors.FeelsLike}>
                                <FormLabel>The pain feels like: (check all that apply)</FormLabel>
                                <Stack direction={['column','row']}>
                                <Controller 
                                    render={(props)=>(
                                        <CheckboxGroup onChange={props.onChange} onBlur={props.onBlur}>
                                            <Checkbox value={'Sharp/stabbing'}>Sharp/stabbing</Checkbox>
                                            <Checkbox value={'bull/achy'}>bull/achy</Checkbox>
                                            <Checkbox value={'Like muscle spasms'}>Like muscle spasms</Checkbox>
                                            <Checkbox value={'Burning'}>Burning</Checkbox>
                                            <Checkbox value={'Like numbness/tingling'}>Like numbness/tingling</Checkbox>
                                            <Checkbox value={"Other"}>other</Checkbox> 
                                        </CheckboxGroup>
                                    )}
                                    control={control}
                                    defaultValue={''}
                                    name={"FeelsLike"}
                                    rules={{ required: 'Impotant information required.' }}
                                    />
                                </Stack>
                                <FormErrorMessage>{errors.FeelsLike && errors.FeelsLike.message}</FormErrorMessage>
                                <Divider borderColor={'gray.500'} />
                            </FormControl>
                        </Stack>
                        <Stack mt={'5'}>
                            <FormControl isInvalid={errors.Travel}>
                                <Stack direction={['column','row']} justifyContent={'space-between'}>
                                <FormLabel >Does the pain radiate or travel? (check one) </FormLabel>
                                <Controller 
                                    render={(props)=>(
                                        <RadioGroup onChange={props.onChange} onBlur={props.onBlur}>
                                            <HStack>
                                                <Radio value={'Yes'}>Yes</Radio>
                                                <Radio value={'No'}>No</Radio>
                                                <Text w={"13rem"}>(if yes, where?)</Text>
                                                <Input name={"YesWhere1"} ref={register()}  />
                                            </HStack>
                                        </RadioGroup>
                                    )}
                                    control={control}
                                    defaultValue={''}
                                    name={"Travel"}
                                    rules={{ required: 'Impotant information required.' }}
                                    />
                                </Stack>
                                <Divider borderColor={'gray.500'} />
                            </FormControl>
                            <FormControl isInvalid={errors.numbness}>
                                <Stack direction={['column','row']} justifyContent={'space-between'}>
                                <FormLabel >Do you have areas of numbness?: (check one) </FormLabel>
                                <Controller 
                                    render={(props)=>(
                                        <RadioGroup onChange={props.onChange} onBlur={props.onBlur}>
                                            <HStack>
                                                <Radio value={'Yes'}>Yes</Radio>
                                                <Radio value={'No'}>No</Radio>
                                                <Text w={"13rem"}>(if yes, where?)</Text>
                                                <Input name={"YesWhere2"} ref={register()}  />
                                            </HStack>
                                        </RadioGroup>
                                    )}
                                    control={control}
                                    defaultValue={''}
                                    name={"numbness"}
                                    rules={{ required: 'Impotant information required.' }}
                                    />
                                </Stack>
                                <Divider borderColor={'gray.500'} />
                            </FormControl>
                            <FormControl isInvalid={errors.weakness}>
                                <Stack direction={['column','row']} justifyContent={'space-between'}>
                                <FormLabel >Do you have areas of weakness: (check one) </FormLabel>
                                <Controller 
                                    render={(props)=>(
                                        <RadioGroup onChange={props.onChange} onBlur={props.onBlur}>
                                            <HStack>
                                                <Radio value={'Yes'}>Yes</Radio>
                                                <Radio value={'No'}>No</Radio>
                                                <Text w={"13rem"}>(if yes, where?)</Text>
                                                <Input name={"YesWhere3"} ref={register()}  />
                                            </HStack>
                                        </RadioGroup>
                                    )}
                                    control={control}
                                    defaultValue={''}
                                    name={"weakness"}
                                    rules={{ required: 'Impotant information required.' }}
                                    />
                                </Stack>
                                <Divider borderColor={'gray.500'} />
                            </FormControl>
                        </Stack>
                        <Stack mt={'5'}>
                            <FormControl isInvalid={errors.PainStart}>
                                <FormLabel>The pain started:(check one)</FormLabel>
                                <Stack direction={['column','row']} >
                                <Controller 
                                    render={(props)=>(
                                        <RadioGroup onChange={props.onChange} onBlur={props.onBlur}>
                                            <Stack direction={['column','row']} spacing={'20px'}>
                                                <Radio value={'Less than 6 months ago'}>Less than 6 months ago</Radio>
                                                <Radio value={'More than 6 months ago'}>More than 6 months ago</Radio>
                                                <Radio value={'More than 1 year ago'}>More than 1 year ago</Radio>
                                                <Radio value={'More than 5 years ago'}>More than 5 years ago</Radio>
                                                <Radio value={'More than 10 years ago'}>More than 10 years ago</Radio>
                                            </Stack>
                                        </RadioGroup>
                                    )}
                                    control={control}
                                    defaultValue={''}
                                    name={"PainStart"}
                                    rules={{ required: 'Impotant information required.' }}
                                    />
                                </Stack>
                                <Divider borderColor={'gray.500'} />
                            </FormControl>
                        </Stack>
                        <Stack>
                            <FormControl mt={'5'} isInvalid={errors.accident}>
                                <Stack direction={['column','row']}>
                                <FormLabel >Is this pain related to an accident? (check one) </FormLabel>
                                <Controller 
                                    render={(props)=>(
                                        <RadioGroup onChange={props.onChange} onBlur={props.onBlur}>
                                            <HStack>
                                                <Radio value={'Yes'}>Yes</Radio>
                                                <Radio value={'No'}>No</Radio>
                                            </HStack>
                                        </RadioGroup>
                                    )}
                                    control={control}
                                    defaultValue={''}
                                    name={"accident"}
                                    rules={{ required: 'Impotant information required.' }}
                                    />
                                </Stack>
                                <Divider borderColor={'gray.500'} />
                            </FormControl>
                            <FormControl mt={'5'} isInvalid={errors.injury}>
                                <Stack direction={['column','row']}>
                                <FormLabel >Is this pain related to a work injury? (check one) </FormLabel>
                                <Controller 
                                    render={(props)=>(
                                        <RadioGroup onChange={props.onChange} onBlur={props.onBlur}>
                                            <HStack>
                                                <Radio value={'Yes'}>Yes</Radio>
                                                <Radio value={'No'}>No</Radio>
                                            </HStack>
                                        </RadioGroup>
                                    )}
                                    control={control}
                                    defaultValue={''}
                                    name={"injury"}
                                    rules={{ required: 'Impotant information required.' }}
                                    />
                                </Stack>
                                <Divider borderColor={'gray.500'} />
                            </FormControl>
                            <FormControl mt={'5'} isInvalid={errors.surgery}>
                                <Stack direction={['column','row']}>
                                <FormLabel >Is this pain related to a surgery? (check one) </FormLabel>
                                <Controller 
                                    render={(props)=>(
                                        <RadioGroup onChange={props.onChange} onBlur={props.onBlur}>
                                            <HStack>
                                                <Radio value={'Yes'}>Yes</Radio>
                                                <Radio value={'No'}>No</Radio>
                                            </HStack>
                                        </RadioGroup>
                                    )}
                                    control={control}
                                    defaultValue={''}
                                    name={"surgery"}
                                    rules={{ required: 'Impotant information required.' }}
                                    />
                                </Stack>
                                <Divider borderColor={'gray.500'} />
                            </FormControl>
                        </Stack>
                        <Stack>
                            <FormControl mt={'5'} isInvalid={errors.Increases}>
                                <FormLabel>
                                    The pain increases with: (check all that apply)
                                </FormLabel>
                                <Stack direction={['column','row']}>
                                <Controller 
                                    render={(props)=>(
                                        <CheckboxGroup onChange={props.onChange} onBlur={props.onBlur}>
                                            <Checkbox value={'Sitting'}>Sitting</Checkbox>
                                            <Checkbox value={'Walking'}>Walking</Checkbox>
                                            <Checkbox value={'Standing'}>Standing</Checkbox>
                                            <Checkbox value={'Bending forward'}>Bending forward</Checkbox>
                                            <Checkbox value={'Bending backward'}>Bending backward</Checkbox>
                                            <Checkbox value={"Looking up"}>Looking up</Checkbox>
                                            <Checkbox value={"Looking down"}>Looking down</Checkbox>
                                        </CheckboxGroup>
                                    )}
                                    control={control}
                                    defaultValue={''}
                                    name={"Increases"}
                                    rules={{ required: 'Impotant information required.' }}
                                    />
                                </Stack>
                                <FormErrorMessage>{errors.Increases && errors.Increases.message}</FormErrorMessage>
                                <Divider borderColor={'gray.500'} />
                            </FormControl>
                            <FormControl mt={'5'} isInvalid={errors.Decreases}>
                                <HStack>
                                    <FormLabel>
                                        The pain decreases with: (check all that apply)
                                    </FormLabel>
                                    <Stack direction={['column','row']}>
                                    <Controller 
                                        render={(props)=>(
                                            <CheckboxGroup onChange={props.onChange} onBlur={props.onBlur}>
                                                <Checkbox value={'Rest'}>Rest</Checkbox>
                                                <Checkbox value={'Medication'}>Medication</Checkbox>
                                                <Checkbox value={'Exercise/physical therapy'}>Exercise/physical therapy</Checkbox>
                                                <Checkbox value={'Procedures'}>Procedures</Checkbox>
                                            </CheckboxGroup>
                                        )}
                                        control={control}
                                        defaultValue={''}
                                        name={"Decreases"}
                                        rules={{ required: 'Impotant information required.' }}
                                        />
                                    </Stack>
                                </HStack>
                                <FormErrorMessage>{errors.Decreases && errors.Decreases.message}</FormErrorMessage>
                                <Divider borderColor={'gray.500'} />
                            </FormControl>
                            <FormControl mt={'5'} isInvalid={errors.Medications}>
                                <HStack>
                                    <FormLabel>
                                        Medications: (check one)
                                    </FormLabel>
                                    <Stack direction={['column','row']}>
                                    <Controller 
                                        render={(props)=>(
                                            <CheckboxGroup onChange={props.onChange} onBlur={props.onBlur}>
                                                <Checkbox value={'Do help relieve my pain'}>Do help relieve my pain</Checkbox>
                                                <Checkbox value={'Do not help relieve my pain'}>Do not help relieve my pain</Checkbox>
                                                <Checkbox value={'Cause side effects'}>Cause side effects</Checkbox>
                                            </CheckboxGroup>
                                        )}
                                        control={control}
                                        defaultValue={''}
                                        name={"Medications"}
                                        rules={{ required: 'Impotant information required.' }}
                                        />
                                    </Stack>
                                </HStack>
                                <FormErrorMessage>{errors.Medications && errors.Medications.message}</FormErrorMessage>
                                <Divider borderColor={'gray.500'} />
                            </FormControl>
                            <FormControl mt={'5'} isInvalid={errors.MedicationsTried}>
                                <FormLabel>
                                    Medications tried: (Check all that apply)
                                </FormLabel>
                                <Controller 
                                    render={(props)=>(
                                        <CheckboxGroup onChange={props.onChange} onBlur={props.onBlur}>
                                            <SimpleGrid justifyContent={'space-between'} columns={[2,3,5,7]}>
                                                <Checkbox value={'Ibuprofen'}>Ibuprofen</Checkbox>
                                                <Checkbox value={'Tylenol'}>Tylenol</Checkbox>
                                                <Checkbox value={'Nortriptyline'}>Nortriptyline</Checkbox>
                                                <Checkbox value={'Amitriptyline'}>Amitriptyline</Checkbox>
                                                <Checkbox value={'Gabapentin'}>Gabapentin</Checkbox>
                                                <Checkbox value={'Lyrica'}>Lyrica</Checkbox>
                                                <Checkbox value={'Flexeril'}>Flexeril</Checkbox>
                                                <Checkbox value={'Robaxin'}>Robaxin</Checkbox>
                                                <Checkbox value={'Zanaflex'}>Zanaflex</Checkbox>
                                                <Checkbox value={'Skelaxin'}>Skelaxin</Checkbox>
                                                <Checkbox value={'Hydrocodone'}>Hydrocodone</Checkbox>
                                                <Checkbox value={'Oxycodone'}>Oxycodone</Checkbox>
                                                <Checkbox value={'Oxycontin'}>Oxycontin</Checkbox>
                                                <Checkbox value={'Morphine'}>Morphine</Checkbox>
                                                <Checkbox value={'MS Contin'}>MS Contin</Checkbox>
                                                <Checkbox value={'Methadone'}>Methadone</Checkbox>
                                                <Checkbox value={'Dilaudid'}>Dilaudid</Checkbox>
                                                <Checkbox value={'Suboxone'}>Suboxone</Checkbox>
                                                <Checkbox value={'Nucynta'}>Nucynta</Checkbox>
                                                <Checkbox value={'Fentanyl'}>Fentanyl</Checkbox>
                                                <Checkbox value={'Opana'}>Opana</Checkbox>
                                            </SimpleGrid>
                                        </CheckboxGroup>
                                    )}
                                    control={control}
                                    defaultValue={''}
                                    name={"MedicationsTried"}
                                    rules={{ required: 'Impotant information required.' }}
                                    />
                                    <FormControl mt={"5"}>
                                        <HStack>
                                            <FormLabel>
                                                Other
                                            </FormLabel>
                                            <Input name={"OtherMedicationsTried"} ref={register()} />
                                        </HStack>
                                    </FormControl>
                                <FormErrorMessage>{errors.MedicationsTried && errors.MedicationsTried.message}</FormErrorMessage>
                                <Divider borderColor={'gray.500'} />
                            </FormControl>
                            
                            <FormControl mt={'5'} isInvalid={errors.Anticoagulant}>
                                <Stack direction={['column','row']}>
                                <FormLabel >Are you currently taking an anticoagulant? (check one) </FormLabel>
                                <Controller 
                                    render={(props)=>(
                                        <RadioGroup onChange={props.onChange} onBlur={props.onBlur}>
                                            <HStack>
                                                <Radio value={'Yes'}>Yes</Radio>
                                                <Radio value={'No'}>No</Radio>
                                            </HStack>
                                        </RadioGroup>
                                    )}
                                    control={control}
                                    defaultValue={''}
                                    name={"Anticoagulant"}
                                    rules={{ required: 'Impotant information required.' }}
                                    />
                                </Stack>
                                <Divider borderColor={'gray.500'} />
                            </FormControl>
                            <FormControl mt={'5'} isInvalid={errors.Alternate}>
                                <Stack direction={['column','row']}>
                                    <FormLabel>
                                        Alternate therapies tried: (Check all that apply)
                                    </FormLabel>
                                    <Controller 
                                        render={(props)=>(
                                            <CheckboxGroup onChange={props.onChange} onBlur={props.onBlur}>
                                                <SimpleGrid columns={[1,2,3]}>
                                                    <Checkbox value={'None'}>None</Checkbox>
                                                    <Checkbox value={'Physical therapy'}>Physical therapy</Checkbox>
                                                    <Checkbox value={'Massage'}>Massage</Checkbox>
                                                    <Checkbox value={'Acupuncture'}>Acupuncture</Checkbox>
                                                    <Checkbox value={'Chiropractic treatments'}>Chiropractic treatments</Checkbox>
                                                    <Checkbox value={'TENS unit'}>TENS unit</Checkbox>
                                                    <Checkbox value={'Pain psychology/therapy'}>Pain psychology/therapy</Checkbox>
                                                </SimpleGrid>
                                            </CheckboxGroup>
                                        )}
                                        control={control}
                                        defaultValue={''}
                                        name={"Alternate"}
                                        rules={{ required: 'Impotant information required.' }}
                                        />
                                </Stack>
                                <FormErrorMessage>{errors.Alternate && errors.Alternate.message}</FormErrorMessage>
                                <Divider borderColor={'gray.500'} />
                            </FormControl>
                            <FormControl mt={'5'} isInvalid={errors.Procedures}>
                                <Stack direction={['column','row']}>
                                    <FormLabel>
                                        Procedures previously tried related to your current or past pain: (Check all that apply)
                                    </FormLabel>
                                    <HStack>
                                    <Controller 
                                        render={(props)=>(
                                            <CheckboxGroup onChange={props.onChange} onBlur={props.onBlur}>
                                                <Stack direction={['column','row']}>
                                                <Checkbox value={'None'}>None</Checkbox>
                                                <Checkbox value={'Epidural steroid injection'}>Epidural steroid injection</Checkbox>
                                                <Checkbox value={'Facet joint injection'}>Facet joint injection</Checkbox>
                                                <Checkbox value={'Radiofrequency ablation'}>Radiofrequency ablation</Checkbox>
                                                <Checkbox value={'Trigger point injections'}>Trigger point injections</Checkbox>
                                                <Checkbox value={'Joint injection'}>Joint injection</Checkbox>
                                                <Checkbox value={'Spinal cord stimulator'}>Spinal cord stimulator</Checkbox>
                                                <Checkbox value={'Botox injection'}>Botox injection</Checkbox>
                                                </Stack>
                                            </CheckboxGroup>
                                        )}
                                        control={control}
                                        defaultValue={''}
                                        name={"Procedures"}
                                        rules={{ required: 'Impotant information required.' }}
                                        />
                                    </HStack>
                                </Stack>
                                <FormErrorMessage>{errors.Procedures && errors.Procedures.message}</FormErrorMessage>
                                <Divider borderColor={'gray.500'} />
                            </FormControl>
                        </Stack>
                        <Stack mt={"10"} p={5} border={"2px"} borderColor={'black'}>
                            <Box>
                                <FormLabel>
                                    Office Use Only:
                                </FormLabel>
                                <Stack direction={['column','row']}>
                                    <FormControl isInvalid={errors.HeartRate}>
                                        <HStack justifyContent={['space-between',null]}>
                                            <FormLabel>
                                                Heart Rate
                                            </FormLabel>
                                            <Input w={["10rem",'60px']} name={"HeartRate"} ref={register({ required: 'Impotant information required.' })} />
                                        </HStack>
                                    </FormControl>
                                    <FormControl isInvalid={errors.BloodPressure}>
                                        <HStack justifyContent={['space-between',null]}>
                                            <FormLabel>
                                                Blood Pressure
                                            </FormLabel>
                                            <Input w={["10rem",'60px']} name={"BloodPressure"} ref={register({ required: 'Impotant information required.' })} />
                                        </HStack>
                                    </FormControl>
                                    <FormControl isInvalid={errors.Temp}>
                                        <HStack justifyContent={['space-between',null]}>
                                            <FormLabel>
                                                Temp
                                            </FormLabel>
                                            <Input w={["10rem",'60px']} name={"Temp"} ref={register({ required: 'Impotant information required.' })} />
                                        </HStack>
                                    </FormControl>
                                    <FormControl isInvalid={errors.F_Pulse}>
                                        <HStack justifyContent={['space-between',null]}>
                                            <FormLabel>
                                                F. Pulse
                                            </FormLabel>
                                            <Input w={["10rem",'60px']} name={"F_Pulse"} ref={register({ required: 'Impotant information required.' })} />
                                        </HStack>
                                    </FormControl>
                                    <FormControl isInvalid={errors.Ox}>
                                        <HStack justifyContent={['space-between',null]}>
                                            <FormLabel>
                                                Ox
                                            </FormLabel>
                                            <HStack>
                                                <Input w={["10rem",'60px']} name={"Ox"} ref={register({ required: 'Impotant information required.' })} />
                                                <InputRightAddon children='%' />
                                            </HStack>
                                        </HStack>
                                    </FormControl>
                                    <FormControl isInvalid={errors.Resp}>
                                        <HStack justifyContent={['space-between',null]}>
                                            <FormLabel>
                                                Resp:
                                            </FormLabel>
                                            <Input name={"Resp"} ref={register({ required: 'Impotant information required.' })} />
                                        </HStack>
                                    </FormControl>
                                </Stack>
                            </Box>
                        </Stack>
                        <Center>
                            <Button mt="10" type="submit" colorScheme="blue" isLoading={formState.isSubmitting} >Next Step</Button>
                        </Center>
                    </form>
                </Container>
            </Box>
        </Container>
    )
}

export default NonWebPatientIntakeF