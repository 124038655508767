import React, { useState } from 'react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Container,
  Flex,
  Box,
  Spacer,
  Image,
  Button,
  FormErrorMessage,
  FormControl,
  Input,
  Center,
  FormLabel,
  RadioGroup,
  Stack,
  Radio,
  useToast,
  Divider,
} from '@chakra-ui/react'
import { Link, useHistory } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { addDoc, collection } from 'firebase/firestore'

import Logo from '../../../Logo.png'
import { db } from '../../../config/firebase'
import {
  calPatientGADScore,
  calPatientPHQScore,
  calScore,
} from '../../../utils'

export const phqQuizData = [
  '1. Little interest or pleasure in doing things.',
  '2. Feeling down, depressed, or hopeless.',
  '3. Trouble falling or staying asleep, or sleeping too much.',
  '4. Feeling tired or having little energy.',
  '5. Poor appetite or overeating.',
  '6. Feeling bad about yourself - or that you are a failure or have let yourself or your family down.',
  '7. Trouble concentrating on things, such as reading the newspaper or watching television.',
  '8. Moving or speaking so slowly that other people could have noticed. Or the opposite - being so fidgety or restless that you have been moving around a lot more than usual.',
  '9. Thoughts that you would be better off dead, or of hurting yourself in some way.',
]

export const gadQuizData = [
  '1. Feeling nervous, anxious, or on edge.',
  '2. Not being able to stop or control worrying.',
  '3. Worrying too much about different things.',
  '4. Trouble relaxing.',
  '5. Being so restless that it’s hard to sit still.',
  '6. Becoming easily annoyed or irritable.',
  '7. Feeling afraid as if something awful might happen.',
]

export default function PatientForm() {
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    reset,
  } = useForm()
  const toast = useToast()
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  const onSubmit = ({ form }) => {
    const phqScore = calScore(form.phqAnswers)
    const gadScore = calScore(form.gadAnswers)
    const phqScoreType = calPatientPHQScore(phqScore.total)
    const gadScoreType = calPatientGADScore(phqScore.total)
    try {
      setLoading(true)
      addDoc(collection(db, 'PatientForm'), {
        ...form,
        phqScore: phqScore,
        gadScore: gadScore,
        phqScoreType: phqScoreType,
        gadScoreType: gadScoreType,
        createdAt: new Date(),
        notification: true,
        status: 'Pending',
        facility: 'PCSO',
        type: 'PHQ-9-GAD-7',
      })
      setLoading(false)
      reset()
      history.push('/patient/success')
    } catch (error) {
      toast({
        title: 'Submit Failed',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
      setLoading(false)
    }
  }
  return (
    <Container maxW="100%" mt="5" mb="10">
      <Image
        cursor="pointer"
        src={Logo}
        alt="paincare logo"
        htmlWidth="100px"
      />
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
        bg="gray.100"
        p="1.5"
        mt="5"
        borderRadius="0.3em"
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/patient-form/phd-9">
            Patient Health Questionnaire (PHQ-9)
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex my="10">
        <Box pt="2" fontSize="18" fontWeight="bold">
          Patient Health Questionnaire (PHQ-9)
        </Box>
        <Spacer />
      </Flex>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl
          id="date"
          mb="5"
          isRequired
          w={['100%', '100%', '300px']}
          isInvalid={errors?.date ?? false}
        >
          <FormLabel>Date</FormLabel>
          <Input
            name="form.date"
            type="date"
            format="YYYY-MM-DD"
            placeholder="Select Date"
            ref={register({
              required: true,
            })}
          />
        </FormControl>
        <Flex
          flexDirection={['column', 'column', 'row', 'row', 'row', 'row']}
          mt="2"
          w="100%"
          gap={6}
        >
          <FormControl isInvalid={errors?.form?.name ?? false} isRequired>
            <FormLabel>Patient Name</FormLabel>
            <Input
              placeholder={'Name'}
              name="form.name"
              ref={register({
                required: true,
              })}
            />
          </FormControl>
          <FormControl
            id="date"
            mb="5"
            isRequired
            isInvalid={errors?.form?.dateOfBirth ?? false}
          >
            <FormLabel>Date of Birth</FormLabel>
            <Input
              name="form.dateOfBirth"
              type="date"
              placeholder="Select Date"
              ref={register({
                required: true,
              })}
            />
          </FormControl>
        </Flex>
        {phqQuizData.map((item, index) => {
          return (
            <FormControl
              mb="5"
              width={['100%', '100%', '100%', '80%']}
              key={index}
              isInvalid={
                errors?.form?.phqAnswers && errors?.form.phqAnswers[index]
              }
            >
              <FormLabel>{item}</FormLabel>
              <Controller
                name={`form.phqAnswers[${index}]`}
                control={control}
                rules={{ required: 'Please select answer' }}
                defaultValue={''}
                render={field => (
                  <RadioGroup {...field}>
                    <Stack>
                      <Radio value="0">Not at all</Radio>
                      <Radio value="1">Several days</Radio>
                      <Radio value="2">More than half the days</Radio>
                      <Radio value="3">Nearly every day</Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
              <FormErrorMessage>
                {errors?.form?.phqAnswers &&
                  errors?.form?.phqAnswers[index]?.message}
              </FormErrorMessage>
            </FormControl>
          )
        })}
        <Divider />

        <Flex my="10">
          <Box pt="2" fontSize="18" fontWeight="bold">
            General Anxiety Disorder (GAD-7)
          </Box>
          <Spacer />
        </Flex>

        {gadQuizData.map((item, index) => {
          return (
            <FormControl
              mb="5"
              width={['100%', '100%', '100%', '80%']}
              key={index}
              isInvalid={
                errors?.form?.gadAnswers && errors?.form.gadAnswers[index]
              }
            >
              <FormLabel>{item}</FormLabel>
              <Controller
                name={`form.gadAnswers[${index}]`}
                control={control}
                rules={{ required: 'Please select answer' }}
                defaultValue={''}
                render={field => (
                  <RadioGroup {...field}>
                    <Stack>
                      <Radio value="0">Not at all sure</Radio>
                      <Radio value="1">Several days</Radio>
                      <Radio value="2">Over half the days</Radio>
                      <Radio value="3">Nearly every day</Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
              <FormErrorMessage>
                {errors?.form?.gadAnswers &&
                  errors?.form?.gadAnswers[index]?.message}
              </FormErrorMessage>
            </FormControl>
          )
        })}

        <Center>
          <Button
            my={'10'}
            type="submit"
            colorScheme={'blue'}
            isLoading={loading}
          >
            Submit
          </Button>
        </Center>
      </form>
    </Container>
  )
}
