import { Image, Text, View } from '@react-pdf/renderer'
import moment from 'moment'
import React from 'react'

function Soap({ fillableData, styles, stylesSoap }) {
  return (
    <View style={{ ...styles.gapBox }}>
      <View
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          fontSize: 8,
        }}
      >
        <Image
          style={{ width: '110px', objectFit: 'cover' }}
          src="/paincare-web-v2.png"
        />
        <View style={styles.boxtitle}>
          <Text>2480 Liberty Street NE, Suite 180, Salem, OR 97301</Text>
        </View>
        <View style={styles.boxtitle}>
          <Text>Office: (503) 371-1010 Fax: (503) 371-0805</Text>
        </View>
        <View style={{ paddingTop: 20 }}>
          <Image
            style={{ width: '60px', objectFit: 'cover' }}
            src="/soapp.png"
          />
        </View>
      </View>
      <View
        style={{
          width: '450px',
          gap: '4px',
          marginTop: '-8px',
        }}
      >
        <Text style={{ lineHeight: 1.5, textAlign: 'justify' }}>
          The following are some questions given to patients who are on or being
          considered for medication for their pain. Please answer each question
          as honestly as possible. There are no right or wrong answers.
        </Text>
        <View
          style={{
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'start',
            width: '450px',
            paddingTop: '4px',
          }}
        >
          <View style={{ ...styles.table, width: '450px' }}>
            <View
              style={{
                margin: 'auto',
                flexDirection: 'row',
                width: '450px',
              }}
            >
              <View
                style={{
                  ...stylesSoap.boxBorderTitle,
                  padding: 30,
                  paddingTop: 8,
                  paddingBottom: 8,
                  width: '100%',
                  gap: 6,
                }}
              >
                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ fontWeight: 'bold', width: '40px' }}>
                    Name:
                  </Text>
                  {` `}
                  <Text style={{ ...styles.paddingLeftFormData }}>
                    {fillableData.namePatient ? fillableData.namePatient : ''}
                  </Text>
                </View>
                <View style={{ flexDirection: 'row', paddingTop: 6 }}>
                  <Text style={{ fontWeight: 'bold', width: '80px' }}>
                    Date of Birth:
                  </Text>
                  {` `}
                  <Text style={{ ...styles.paddingLeftFormData }}>
                    {fillableData?.dobPatient
                      ? moment(fillableData?.dobPatient).format('MM/DD/YYYY')
                      : ''}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  ...stylesSoap.boxBorder,
                  height: '62px',
                  backgroundColor: '#dddddd',
                }}
              >
                <Text style={stylesSoap.subBoxTitle}>Never</Text>
              </View>
              <View
                style={{
                  ...stylesSoap.boxBorder,
                  backgroundColor: '#dddddd',
                }}
              >
                <Text style={stylesSoap.subBoxTitle}>Seldom</Text>
              </View>
              <View
                style={{
                  ...stylesSoap.boxBorder,
                  backgroundColor: '#dddddd',
                }}
              >
                <Text style={stylesSoap.subBoxTitle}>Sometimes</Text>
              </View>
              <View
                style={{
                  ...stylesSoap.boxBorder,
                  backgroundColor: '#dddddd',
                }}
              >
                <Text style={stylesSoap.subBoxTitle}>Often</Text>
              </View>
              <View
                style={{
                  ...stylesSoap.boxBorder,
                  backgroundColor: '#dddddd',
                }}
              >
                <Text style={stylesSoap.subBoxTitle}>Very Often</Text>
              </View>
            </View>
            <View
              style={{
                margin: 'auto',
                flexDirection: 'row',
                width: '450px',
              }}
            >
              <View
                style={{
                  ...stylesSoap.boxBorderTitle,
                  width: '100%',
                  gap: 6,
                }}
              >
                <Text></Text>
              </View>
              <View
                style={{
                  ...stylesSoap.boxBorder,
                  backgroundColor: '#dddddd',
                }}
              >
                <Text>0</Text>
              </View>
              <View
                style={{
                  ...stylesSoap.boxBorder,
                  backgroundColor: '#dddddd',
                }}
              >
                <Text>1</Text>
              </View>
              <View
                style={{
                  ...stylesSoap.boxBorder,
                  backgroundColor: '#dddddd',
                }}
              >
                <Text>2</Text>
              </View>
              <View
                style={{
                  ...stylesSoap.boxBorder,
                  backgroundColor: '#dddddd',
                }}
              >
                <Text>3</Text>
              </View>
              <View
                style={{
                  ...stylesSoap.boxBorder,
                  backgroundColor: '#dddddd',
                }}
              >
                <Text>4</Text>
              </View>
            </View>
            <View
              style={{
                margin: 'auto',
                flexDirection: 'row',
                width: '450px',
              }}
            >
              <View
                style={{
                  ...stylesSoap.boxBorderTitle,
                  width: '100%',
                  flexDirection: 'row',
                }}
              >
                <Text style={{ width: '20px' }}>1.</Text>
                <Text>How often do you have mood swings?</Text>
              </View>
              <View style={stylesSoap.boxBorder}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howswings === 'zero' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={stylesSoap.boxBorder}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howswings === 'one' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={stylesSoap.boxBorder}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howswings === 'two' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={stylesSoap.boxBorder}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howswings === 'three' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={stylesSoap.boxBorder}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howswings === 'four' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
            </View>
            <View
              style={{
                margin: 'auto',
                flexDirection: 'row',
                width: '450px',
              }}
            >
              <View
                style={{
                  ...stylesSoap.boxBorderTitle,
                  width: '100%',
                  flexDirection: 'row',
                }}
              >
                <Text style={{ width: '20px' }}>2.</Text>
                <Text style={{ paddingRight: 18 }}>
                  How often have you felt a need for higher doses of medication
                  to treat your pain?
                </Text>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howhigh === 'zero' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howhigh === 'one' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howhigh === 'two' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howhigh === 'three' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howhigh === 'four' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
            </View>
            <View
              style={{
                margin: 'auto',
                flexDirection: 'row',
                width: '450px',
              }}
            >
              <View
                style={{
                  ...stylesSoap.boxBorderTitle,
                  width: '100%',
                  flexDirection: 'row',
                }}
              >
                <Text style={{ width: '20px' }}>3.</Text>
                <Text style={{ paddingRight: 18 }}>
                  How often have you felt impatient with your doctors?
                </Text>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howimpatient === 'zero' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howimpatient === 'one' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howimpatient === 'two' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howimpatient === 'three' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howimpatient === 'four' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
            </View>
            <View
              style={{
                margin: 'auto',
                flexDirection: 'row',
                width: '450px',
              }}
            >
              <View
                style={{
                  ...stylesSoap.boxBorderTitle,
                  width: '100%',
                  flexDirection: 'row',
                }}
              >
                <Text style={{ width: '20px' }}>4.</Text>
                <Text style={{ paddingRight: 18 }}>
                  How often have you felt that things are just too overwhelming
                  that you can't handle them?
                </Text>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howthings === 'zero' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howthings === 'one' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howthings === 'two' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howthings === 'three' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howthings === 'four' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
            </View>
            <View
              style={{
                margin: 'auto',
                flexDirection: 'row',
                width: '450px',
              }}
            >
              <View
                style={{
                  ...stylesSoap.boxBorderTitle,
                  width: '100%',
                  flexDirection: 'row',
                }}
              >
                <Text style={{ width: '20px' }}>5.</Text>
                <Text style={{ paddingRight: 18 }}>
                  How often is there tension in the home?
                </Text>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howtension === 'zero' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howtension === 'one' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howtension === 'two' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howtension === 'three' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howtension === 'four' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
            </View>
            <View
              style={{
                margin: 'auto',
                flexDirection: 'row',
                width: '450px',
              }}
            >
              <View
                style={{
                  ...stylesSoap.boxBorderTitle,
                  width: '100%',
                  flexDirection: 'row',
                }}
              >
                <Text style={{ width: '20px' }}>6.</Text>
                <Text style={{ paddingRight: 18 }}>
                  How often have you counted pain pills to see how many are
                  remaining?
                </Text>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howpain === 'zero' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howpain === 'one' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howpain === 'two' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howpain === 'three' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howpain === 'four' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
            </View>
            <View
              style={{
                margin: 'auto',
                flexDirection: 'row',
                width: '450px',
              }}
            >
              <View
                style={{
                  ...stylesSoap.boxBorderTitle,
                  width: '100%',
                  flexDirection: 'row',
                }}
              >
                <Text style={{ width: '20px' }}>7.</Text>
                <Text style={{ paddingRight: 18 }}>
                  How often have you been concerned that people will judge you
                  for taking pain medication?
                </Text>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howconcerned === 'zero' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howconcerned === 'one' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howconcerned === 'two' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howconcerned === 'three' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howconcerned === 'four' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
            </View>
            <View
              style={{
                margin: 'auto',
                flexDirection: 'row',
                width: '450px',
              }}
            >
              <View
                style={{
                  ...stylesSoap.boxBorderTitle,
                  width: '100%',
                  flexDirection: 'row',
                }}
              >
                <Text style={{ width: '20px' }}>8.</Text>
                <Text style={{ paddingRight: 18 }}>
                  How often do you feel bored?
                </Text>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howbored === 'zero' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howbored === 'one' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howbored === 'two' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howbored === 'three' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howbored === 'four' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
            </View>
            <View
              style={{
                margin: 'auto',
                flexDirection: 'row',
                width: '450px',
              }}
            >
              <View
                style={{
                  ...stylesSoap.boxBorderTitle,
                  width: '100%',
                  flexDirection: 'row',
                }}
              >
                <Text style={{ width: '20px' }}>9.</Text>
                <Text style={{ paddingRight: 18 }}>
                  How often have you taken more pain medication than you were
                  supposed to?
                </Text>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howmedication === 'zero' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howmedication === 'one' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howmedication === 'two' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howmedication === 'three' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howmedication === 'four' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
            </View>
            <View
              style={{
                margin: 'auto',
                flexDirection: 'row',
                width: '450px',
              }}
            >
              <View
                style={{
                  ...stylesSoap.boxBorderTitle,
                  width: '100%',
                  flexDirection: 'row',
                }}
              >
                <Text style={{ width: '20px' }}>10.</Text>
                <Text style={{ paddingRight: 18 }}>
                  How often have you worried about being left alone?
                </Text>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howworried === 'zero' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howworried === 'one' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howworried === 'two' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howworried === 'three' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howworried === 'four' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
            </View>
            <View
              style={{
                margin: 'auto',
                flexDirection: 'row',
                width: '450px',
              }}
            >
              <View
                style={{
                  ...stylesSoap.boxBorderTitle,
                  width: '100%',
                  flexDirection: 'row',
                }}
              >
                <Text style={{ width: '20px' }}>11.</Text>
                <Text style={{ paddingRight: 18 }}>
                  How often have you felt a craving for medication?
                </Text>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howcraving === 'zero' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howcraving === 'one' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howcraving === 'two' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howcraving === 'three' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howcraving === 'four' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
            </View>
            <View
              style={{
                margin: 'auto',
                flexDirection: 'row',
                width: '450px',
              }}
            >
              <View
                style={{
                  ...stylesSoap.boxBorderTitle,
                  width: '100%',
                  flexDirection: 'row',
                  borderBottom: 1,
                }}
              >
                <Text style={{ width: '20px' }}>12.</Text>
                <Text style={{ paddingRight: 18 }}>
                  How often have others expressed concern over your use of
                  medication?
                </Text>
              </View>
              <View
                style={{
                  ...stylesSoap.boxBorder,
                  paddingTop: 8,
                  borderBottom: 1,
                }}
              >
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howconcern === 'zero' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View
                style={{
                  ...stylesSoap.boxBorder,
                  paddingTop: 8,
                  borderBottom: 1,
                }}
              >
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howconcern === 'one' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View
                style={{
                  ...stylesSoap.boxBorder,
                  paddingTop: 8,
                  borderBottom: 1,
                }}
              >
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howconcern === 'two' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View
                style={{
                  ...stylesSoap.boxBorder,
                  paddingTop: 8,
                  borderBottom: 1,
                }}
              >
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howconcern === 'three' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View
                style={{
                  ...stylesSoap.boxBorder,
                  paddingTop: 8,
                  borderBottom: 1,
                }}
              >
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howconcern === 'four' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
            </View>
          </View>
        </View>
        <Text style={{ paddingTop: 6, lineHeight: 1.5, textAlign: 'justify' }}>
          ©2014 Inflexxion, Inc. Permission granted solely for use in published
          format by individual practitioners in clinical practice. No other uses
          or alterations are authorized or permitted by copyright holder.
          Permissions questions:{' '}
          <Text style={{ borderBottom: 1 }}>PainEDU@inflexxion.com.</Text> The
          SOAPP®-R was developed with a grant from the National Institutes of
          Health and an educational grant from Endo Pharmaceuticals.
        </Text>
        <View
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '450px',
            paddingTop: 25,
          }}
        >
          <Image
            style={{ width: '170px', objectFit: 'cover' }}
            src="/edu-logo.png"
          />
        </View>
      </View>
    </View>
  )
}

export default Soap
