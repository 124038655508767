/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import {
  Text,
  Image,
  PDFViewer,
  StyleSheet,
  Document,
  Page,
  View,
} from '@react-pdf/renderer'

import { useParams, Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { doc, getDoc } from 'firebase/firestore'

import './style.module.css'

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Spinner,
} from '@chakra-ui/react'
import { db } from '../../config/firebase'
import { ChevronRightIcon } from '@chakra-ui/icons'
import moment from 'moment'

const _ = require('underscore')

function Pdf() {
  const styles = StyleSheet.create({
    pdfView: {
      width: window.innerWidth,
      height: window.innerHeight,
      backgroundColor: '#fff',
    },
    page: {
      width: '100%',
      flexDirection: 'column',
      backgroundColor: '#fff',
      alignItems: 'center',
      paddingLeft: 40,
      paddingRight: 40,
    },
    section: {
      width: '100%',
      marginBottom: 20,
      padding: 10,
      alignItems: 'center',
    },
    sectionImg: {
      width: '100%',
      marginTop: 35,
      padding: 0,
      alignItems: 'center',
    },
    sectionTwo: {
      width: '100%',
      marginTop: 15,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    sectionthree: {
      width: '100%',
      marginTop: 20,
      marginBottom: 20,
      flexDirection: 'column',
      justifyContent: 'start',
      alignItems: 'start',
    },
    sectionfour: {
      width: '100%',
      marginTop: 60,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    boxview: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
    },
    img: {
      width: '150px',
      height: 'auto',
      objectFit: 'cover',
    },
    imgcheck: {
      width: '7px',
      height: '7px',
    },
    texttitle: {
      top: 20,
      fontSize: '16px',
      textDecoration: 'underline',
    },
    boxmonthyear: {
      top: 10,
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    boxmonthyearIn: {
      display: 'flex',
      flexDirection: 'row',
    },
    textmonthyear: {
      fontSize: '12px',
    },
    textmonthyearborder: {
      fontSize: '12px',
      borderBottom: 1,
      textAlign: 'center',
      width: '150px',
    },
    table: {
      display: 'table',
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
    },
    tableRow: {
      margin: 'auto',

      flexDirection: 'row',
    },
    tableRowName: {
      borderStyle: 'solid',
      width: '100%',
      borderTopWidth: 1,
      marginTop: 2.5,
      flexDirection: 'row',
    },
    tableCol: {
      width: '35%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      backgroundColor: '#aeaaaa',
    },
    tableColDate: {
      width: '15%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      backgroundColor: '#aeaaaa',
    },
    tableColDateBody: {
      width: '15%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      backgroundColor: '#fff',
    },
    tableColyesno: {
      width: '12.5%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      backgroundColor: '#aeaaaa',
    },
    tableCell: {
      backgroundColor: '#aeaaaa',
      margin: 'auto',
      marginTop: 5,
      fontSize: 10,
    },
    tableCellName: {
      backgroundColor: '#aeaaaa',
      margin: 'auto',
      marginTop: 2.5,
      fontSize: 10,
    },
    tableCellNameLeft: {
      backgroundColor: '#aeaaaa',
      margin: 'auto',
      marginLeft: 2.5,
      paddingTop: 2.5,
      paddingBottom: 2.5,
      fontSize: 10,
      width: '100%',
    },
    tableCellNameRight: {
      backgroundColor: '#aeaaaa',
      margin: 'auto',
      borderLeftWidth: 1,
      paddingTop: 2.5,
      paddingBottom: 2.5,
      paddingLeft: 2.5,
      fontSize: 10,
      width: '100%',
    },
    tableCellNameLeftYes: {
      backgroundColor: '#aeaaaa',
      margin: 'auto',
      marginLeft: 2.5,
      paddingTop: 2.5,
      textAlign: 'center',
      paddingBottom: 2.5,
      fontSize: 10,
      width: '100%',
    },
    tableCellNameRightNo: {
      backgroundColor: '#aeaaaa',
      margin: 'auto',
      borderLeftWidth: 1,
      paddingTop: 2.5,
      paddingBottom: 2.5,
      textAlign: 'center',
      paddingLeft: 2.5,
      fontSize: 10,
      width: '100%',
    },
    tableCellcolumn: {
      backgroundColor: '#aeaaaa',
      width: '25%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      display: 'flex',
      flexDirection: 'column',
    },
    tableColBody: {
      width: '35%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      backgroundColor: '#fff',
    },
    tableCellBody: {
      backgroundColor: '#fff',
      margin: 'auto',
      marginTop: 2.5,
      fontSize: 10,
    },
    tableCellcolumnBody: {
      backgroundColor: '#fff',
      width: '25%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      display: 'flex',
      flexDirection: 'column',
    },
    tableRowNameBody: {
      borderStyle: 'solid',
      backgroundColor: '#fff',
      width: '100%',
      borderBottomWidth: 0,
      marginTop: 0,
      flexDirection: 'row',
    },
    tableCellNameLeftBody: {
      backgroundColor: '#fff',
      margin: 'auto',
      marginLeft: 2.5,
      paddingTop: 2.5,
      paddingBottom: 2.5,
      fontSize: 10,
      width: '100%',
    },
    tableCellNameRightBody: {
      backgroundColor: '#fff',
      margin: 'auto',
      borderLeftWidth: 1,
      paddingTop: 2.5,
      paddingBottom: 2.5,
      paddingLeft: 2.5,
      fontSize: 10,
      width: '100%',
    },
    tableCellNameLeftBodyYes: {
      backgroundColor: '#fff',
      margin: 'auto',
      marginLeft: 2.5,
      paddingTop: 2.5,
      textAlign: 'center',
      paddingBottom: 2.5,
      fontSize: 10,
      width: '100%',
    },
    tableCellNameRightBodyNo: {
      backgroundColor: '#fff',
      margin: 'auto',
      borderLeftWidth: 1,
      paddingTop: 2.5,
      paddingBottom: 2.5,
      textAlign: 'center',
      paddingLeft: 2.5,
      fontSize: 10,
      width: '100%',
    },
    signatureBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '150px',
    },
    signatureImg: {
      height: '40px',
    },
    signature: {
      top: 4,
      paddingTop: 4,
      width: '150px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderTop: 1,
    },
    textsignature: {
      fontSize: 10,
    },
  })

  const params = useParams()
  const [data, setData] = useState()

  const getData = () => {
    if (params?.docId) {
      getDoc(doc(db, 'InfectionEmergencyForm', params?.docId))
        .then(res => {
          const results = { ...res.data() }
          const obj = Object.keys(results)
          let arrNumber = []

          obj.map((e, i) => {
            const split = e.split('_')
            const number =
              _.isNaN(parseInt(split[0])) === false && parseInt(split[0])
            return arrNumber.push(number)
          })
          // eslint-disable-next-line array-callback-return
          const numberGroup = arrNumber.filter(f => f !== false && f !== true)
          const groupKeys = _.keys(_.groupBy(numberGroup)).map((e, i) => {
            const erYes = results[`${e}_er_visit_yes`] === 'Yes' ? 'Yes' : false
            const erNo = results[`${e}_er_visit_no`] === 'No' ? 'No' : false
            const infectionYes =
              results[`${e}_infection_yes`] === 'Yes' ? 'Yes' : false
            const infectionNo =
              results[`${e}_infection_no`] === 'No' ? 'No' : false

            return {
              [`er_visit_yes`]: erYes,
              [`er_visit_no`]: erNo,
              [`infection_yes`]: infectionYes,
              [`infection_no`]: infectionNo,
              [`date`]: results[`${e}_date`],
              [`firstName`]: results[`${e}_firstName`],
              [`lastName`]: results[`${e}_lastName`],
              [`note`]: results[`${e}_note`],
            }
          })
          const perChunk = 30

          const split = groupKeys.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / perChunk)

            if (!resultArray[chunkIndex]) {
              resultArray[chunkIndex] = []
            }

            resultArray[chunkIndex].push(item)

            return resultArray
          }, [])

          const monthYear = res.data()?.monthYear.toDate()
          setData({ ...res.data(), groupKeys: split, monthYear })
        })
        .catch(e => {
          console.log(e)
        })
    }
  }

  useEffect(() => {
    getData()
  }, [params?.docId])

  return (
    <>
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
        bg="gray.100"
        p="1.5"
        borderRadius="0.3em"
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/dashboard">
            Dashbaord
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/infections-and-emergency-visit">
            Infections and emergency visit
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="#">
            PDF
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="#">
            {data?.physician ? data?.physician : '-'}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      {data ? (
        <PDFViewer style={styles.pdfView}>
          <Document>
            <Page size="A4" style={styles.page}>
              <View style={styles.sectionImg}>
                {data && data.facility === 'PCSO' && (
                  <Image style={styles.img} src="/paincare-web.png" />
                )}
                {data && data.facility === 'OSSC' && (
                  <Image style={styles.img} src="/LogoOSSC.jpeg" />
                )}
              </View>
              <View style={styles.section} fixed>
                <Text style={styles.texttitle}>
                  Intections and Emergency Room Visit Form
                </Text>
              </View>
              <View style={styles.sectionTwo}>
                <View style={styles.boxmonthyearIn}>
                  <Text style={styles.textmonthyear}>Physician :</Text>
                  <Text style={styles.textmonthyearborder}>
                    {data?.physician ? data?.physician : '-'}
                  </Text>
                </View>
                <View style={styles.boxmonthyearIn}>
                  <Text style={styles.textmonthyear}>Month/Year :</Text>
                  <Text style={styles.textmonthyearborder}>
                    {data?.monthYear
                      ? moment(data?.monthYear).format('MMM YYYY')
                      : '-'}
                  </Text>
                </View>
              </View>
              {data?.groupKeys?.map((group, index) => {
                return (
                  <View
                    style={styles.sectionthree}
                    key={index}
                    break={index !== 0}
                  >
                    <View style={styles.table}>
                      <View style={styles.tableRow}>
                        <View style={styles.tableColDate}>
                          <Text style={styles.tableCell}>Date of Service</Text>
                        </View>
                        <View style={styles.tableCellcolumn}>
                          <Text style={styles.tableCell}>Type</Text>
                          <View style={styles.tableRowName}>
                            <Text style={styles.tableCellNameLeft}>First</Text>
                            <Text style={styles.tableCellNameRight}>Last</Text>
                          </View>
                        </View>
                        <View style={styles.tableColyesno}>
                          <Text style={styles.tableCell}>Infection</Text>
                          <View style={styles.tableRowName}>
                            <Text style={styles.tableCellNameLeftYes}>Yes</Text>
                            <Text style={styles.tableCellNameRightNo}>No</Text>
                          </View>
                        </View>
                        <View style={styles.tableColyesno}>
                          <Text style={styles.tableCell}>ER visit</Text>
                          <View style={styles.tableRowName}>
                            <Text style={styles.tableCellNameLeftYes}>Yes</Text>
                            <Text style={styles.tableCellNameRightNo}>No</Text>
                          </View>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>Note</Text>
                        </View>
                      </View>
                      {group?.map((data, index) => {
                        return (
                          <View style={styles.tableRow} key={index}>
                            <View style={styles.tableColDateBody}>
                              <Text style={styles.tableCellBody}>
                                {data?.date
                                  ? moment(data?.date).format('MM/DD/YYYY')
                                  : ' '}
                              </Text>
                            </View>
                            <View style={styles.tableCellcolumnBody}>
                              <View style={styles.tableRowNameBody}>
                                <Text style={styles.tableCellNameLeftBody}>
                                  {data?.firstName ? data?.firstName : ' '}
                                </Text>
                                <Text style={styles.tableCellNameRightBody}>
                                  {data?.lastName ? data?.lastName : ' '}
                                </Text>
                              </View>
                            </View>
                            <View style={styles.tableColyesno}>
                              <View style={styles.tableRowNameBody}>
                                <Text style={styles.tableCellNameLeftBodyYes}>
                                  {data?.infection_yes === false ? (
                                    ' '
                                  ) : (
                                    <Image
                                      style={styles.imgcheck}
                                      src="/check.png"
                                    />
                                  )}
                                </Text>
                                <Text style={styles.tableCellNameRightBodyNo}>
                                  {data?.infection_no === false ? (
                                    ' '
                                  ) : (
                                    <Image
                                      style={styles.imgcheck}
                                      src="/check.png"
                                    />
                                  )}
                                </Text>
                              </View>
                            </View>
                            <View style={styles.tableColyesno}>
                              <View style={styles.tableRowNameBody}>
                                <Text style={styles.tableCellNameLeftBodyYes}>
                                  {data?.er_visit_yes === false ? (
                                    ' '
                                  ) : (
                                    <Image
                                      style={styles.imgcheck}
                                      src="/check.png"
                                    />
                                  )}
                                </Text>
                                <Text style={styles.tableCellNameRightBodyNo}>
                                  {data?.er_visit_no === false ? (
                                    ' '
                                  ) : (
                                    <Image
                                      style={styles.imgcheck}
                                      src="/check.png"
                                    />
                                  )}
                                </Text>
                              </View>
                            </View>
                            <View style={styles.tableColBody}>
                              <Text style={styles.tableCellBody}>
                                {data?.note ? data?.note : ' '}
                              </Text>
                            </View>
                          </View>
                        )
                      })}
                    </View>
                  </View>
                )
              })}

              <View style={styles.sectionfour}>
                <View style={styles.signatureBox}>
                  {data?.signatureCollector && (
                    <Image
                      style={styles.signatureImg}
                      src={data?.signatureCollector}
                    />
                  )}
                  <Text style={styles.signature}>
                    <Text style={styles.textsignature}>
                      Physician Signature
                    </Text>
                  </Text>
                </View>
                <View style={styles.signatureBox}>
                  <Text style={styles.textsignature}>
                    {data?.date ? moment(data?.date).format('MM/DD/YYYY') : ' '}
                  </Text>
                  <Text style={styles.signature}>
                    <Text style={styles.textsignature}>Month/Date/Year</Text>
                  </Text>
                </View>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      ) : (
        <Flex
          minW={'100%'}
          minH={'100vh'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <p>loading...</p>
          <Spinner ml={2} size={'md'} />
        </Flex>
      )}
    </>
  )
}

export default Pdf
