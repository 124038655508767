import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import {
  Container,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  useToast,
  HStack,
  Center,
  Textarea,
  Flex,
  Spacer,
  Select,
} from '@chakra-ui/react'
import { DatePicker } from 'antd'

import { ChevronRightIcon } from '@chakra-ui/icons'
import { FaCloudDownloadAlt } from 'react-icons/fa'
import { addDoc, collection } from 'firebase/firestore'
import { db } from '../../config/firebase'

function TimeClockAdjustmentForm({ user }) {
  const { handleSubmit, register, reset, errors, control } = useForm()
  const [isDisabled, setIsDisabled] = useState(false)
  const toast = useToast()

  function submitData(value) {
    setIsDisabled(true)
    value.date = value.date.format('YYYY-MM-DD')
    addDoc(collection(db, 'EmployeeForm'), {
      ...value,
      name: user.fullName,
      jobTitle: user.jobTitle,
      type: 'Time Clock Adjustment',
      createdAt: new Date(),
      notification: true,
      status: 'Pending',
    }).then(() => {
      toast({
        title: 'Submit Successfully',
        description: 'Form has been submmited.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      setIsDisabled(false)
    })
    reset()
  }
  return (
    <Container maxW="100%" mt="5">
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
        bg="gray.100"
        p="1.5"
        borderRadius="0.3em"
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/dashboard">
            Dashbaord
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/time-clock-adjustment-form">
            Time Clock Adjustment Form
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Box mt="30" mb="100" width={['100']}>
        <Flex mt="10" mb="10">
          <Box pt="2" fontSize="18" fontWeight="bold">
            Time Clock Adjustment Form
          </Box>
          <Spacer />
          <Button
            onClick={() => {
              window.open(
                'https://firebasestorage.googleapis.com/v0/b/paincare-a9e20.appspot.com/o/Downloads%2FForms%2FTime-Clock-Adjustment-Form-2.pdf?alt=media&token=fa0dab09-883a-490a-b8fd-d588167373d3',
                '_blank'
              )
            }}
            leftIcon={<FaCloudDownloadAlt />}
            size="sm"
          >
            PDF
          </Button>
        </Flex>
        <form onSubmit={handleSubmit(submitData)}>
          <HStack mb="5">
            <FormControl id="name" isReadOnly>
              <FormLabel>NAME</FormLabel>
              <Input name="name" defaultValue={user.fullName} />
            </FormControl>
            <FormControl id="jobTitle" isReadOnly>
              <FormLabel>JOB TITLE</FormLabel>
              <Input name="jobTitle" defaultValue={user.jobTitle} />
            </FormControl>
          </HStack>
          <FormControl
            mb="5"
            id="facility"
            isInvalid={errors.facility}
            width={['100%', '100%', '100%', '50%']}
          >
            <FormLabel>FACILITY</FormLabel>
            <Controller
              render={props => (
                <Select onChange={props.onChange} placeholder="Select Facility">
                  <option value="PCSO">PCSO</option>
                  <option value="OSSC">OSSC</option>
                  <option value="OPM">OPM</option>
                </Select>
              )}
              name="facility"
              control={control}
              defaultValue={null}
              rules={{ required: 'Fcility required.' }}
            />
            <FormErrorMessage>
              {errors.facility && errors.facility.message}
            </FormErrorMessage>
          </FormControl>
          <Center bg="black" mt="30" mb="30" h="35">
            <Text fontWeight="bold" color="white">
              MISSING CLOCK IN / OUT
            </Text>
          </Center>
          <FormControl
            id="date"
            mb="5"
            width={['100%', '100%', '100%', '30%']}
            isInvalid={errors.date}
          >
            <FormLabel>DATE</FormLabel>
            <Controller
              render={props => (
                <DatePicker
                  onChange={props.onChange}
                  size="large"
                  format="YYYY-MM-DD"
                />
              )}
              name="date"
              control={control}
              defaultValue={null}
              rules={{ required: 'Date required.' }}
            />
            <FormErrorMessage>
              {errors.date && errors.date.message}
            </FormErrorMessage>
          </FormControl>
          <HStack mb="5" width={['100%', '100%', '100%', '50%']}>
            <FormControl id="clockIn" isInvalid={errors.clockIn}>
              <FormLabel>CLOCK IN TIME</FormLabel>
              <Input
                type="time"
                name="clockIn"
                ref={register({ required: 'Clock In Required.' })}
              />
              <FormErrorMessage>
                {errors.clockIn && errors.clockIn.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl id="clockOut" isInvalid={errors.clockOut}>
              <FormLabel>CLOCK OUT TIME</FormLabel>
              <Input
                type="time"
                name="clockOut"
                ref={register({ required: 'Clock Out Required.' })}
              />
              <FormErrorMessage>
                {errors.clockOut && errors.clockOut.message}
              </FormErrorMessage>
            </FormControl>
          </HStack>
          <HStack mb="5" width={['100%', '100%', '100%', '50%']}>
            <FormControl id="adjustClockIn" isInvalid={errors.adjustClockIn}>
              <FormLabel>ADJUST CLOCK IN TIME</FormLabel>
              <Input
                type="time"
                name="adjustClockIn"
                ref={register({ required: 'Adjust Clock In Required.' })}
              />
              <FormErrorMessage>
                {errors.adjustClockIn && errors.adjustClockIn.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl id="adjustClockOut" isInvalid={errors.adjustClockOut}>
              <FormLabel>ADJUST CLOCK OUT TIME</FormLabel>
              <Input
                type="time"
                name="adjustClockOut"
                ref={register({ required: 'Adjust Clock Out Required.' })}
              />
              <FormErrorMessage>
                {errors.adjustClockOut && errors.adjustClockOut.message}
              </FormErrorMessage>
            </FormControl>
          </HStack>
          <FormControl
            id="explaination"
            isInvalid={errors.explaination}
            mb="5"
            width={['100%', '100%', '100%', '50%']}
          >
            <FormLabel>EXPLAINATION</FormLabel>
            <Textarea
              rows="6"
              name="explaination"
              ref={register({ required: 'Explaination Required' })}
            />
            <FormErrorMessage>
              {errors.explaination && errors.explaination.message}
            </FormErrorMessage>
          </FormControl>
          <Text mb="5" color="red.500">
            ** Employee certifies that all information provided is true and
            complete to the best of his/her knowledge. Falsification of this
            form is grounds for disciplinary action, up to and including
            dismissal.
          </Text>
          <Center mt="10">
            <Button
              colorScheme="blue"
              isLoading={isDisabled}
              disabled={isDisabled}
              type="submit"
              width={['100%', '100%', '100%', '10%']}
            >
              Submit
            </Button>
          </Center>
        </form>
      </Box>
    </Container>
  )
}

export default TimeClockAdjustmentForm
