import { ChevronRightIcon } from '@chakra-ui/icons'
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Center,
  Container,
  Flex,
  Select,
  Spinner,
  Text,
  useToast,
} from '@chakra-ui/react'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { addDoc, collection, doc, getDoc, updateDoc } from 'firebase/firestore'
import { Link, useHistory, useParams } from 'react-router-dom'

import { db } from '../../../config/firebase'

import FormMD from './FormMD'
import FormNurse from './FormNurse'
import FormCRNAANSAngela5 from './FormCRNAANSAngela5'

const optionsData = {
  OSSC: [
    { label: 'CRNA-ANS', value: 'CRNAANS' },
    { label: 'MD-ANS', value: 'MDANS' },
    { label: 'MD', value: 'MD' },
    { label: 'Nurses', value: 'Nurses' },
  ],
  PCSO: [
    { label: 'CRNA-ANS', value: 'CRNAANS' },
    { label: 'MD-ANS', value: 'MDANS' },
    { label: 'MD', value: 'MD' },
    { label: 'Nurses', value: 'Nurses' },
  ],
}

function PeerReviewForm() {
  const toast = useToast()
  const history = useHistory()
  const params = useParams()

  const [facility, setFacility] = useState('PCSO')
  const [form, setForm] = useState('CRNAANS')
  const [loading, setLoading] = useState(false)
  const [isFetching, setIsfetching] = useState(false)
  const [data, setData] = useState()

  const fetchFormData = useCallback(async id => {
    setIsfetching(true)
    await getDoc(doc(db, 'PeerReviewForm', id)).then(doc => {
      const results = {
        ...doc.data(),
        id: doc.id,
      }
      setFacility(results.facility)
      setForm(results.type)
      setData(results)
    })
    setIsfetching(false)
  }, [])

  useEffect(() => {
    if (params.id) fetchFormData(params.id)
  }, [fetchFormData, params])

  const onSubmit = async values => {
    const model = {
      ...values.form,
      list: values.form.list.map(item => {
        return {
          ...item,
          period: moment(item.period).format('MM/DD/YYYY'),
          dos: moment(item.dos).format('MM/DD/YYYY'),
        }
      }),
    }
    try {
      if (params.id) {
        setLoading(true)
        const docRef = doc(db, 'PeerReviewForm', params.id)
        await updateDoc(
          docRef,
          {
            ...model,
            facility: facility,
            type: form,
            updatedAt: new Date(),
            notification: true,
            status: 'Success',
          },
          { merge: true }
        )
        toast({
          title: 'Submit Successfully',
          description: 'Form has been submmited.',
          status: 'success',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
        history.push('/peer-review')
      } else {
        setLoading(true)
        const colRef = collection(db, 'PeerReviewForm')
        await addDoc(colRef, {
          ...model,
          facility: facility,
          type: form,
          createdAt: new Date(),
          notification: true,
          status: 'Draft',
        })
        toast({
          title: 'Submit Successfully',
          description: 'Form has been submmited.',
          status: 'success',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
        history.push('/peer-review')
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
      toast({
        title: 'Submit failed',
        description: '',
        status: 'error',
        position: 'top',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  const formData = {
    OSSC: {
      CRNAANS: (
        <FormCRNAANSAngela5
          data={data}
          facility={'OSSC'}
          onSubmit={onSubmit}
          loading={loading}
        />
      ),
      MDANS: (
        <FormCRNAANSAngela5
          data={data}
          facility={'OSSC'}
          onSubmit={onSubmit}
          loading={loading}
        />
      ),
      MD: (
        <FormMD
          facility={'OSSC'}
          data={data}
          onSubmit={onSubmit}
          loading={loading}
        />
      ),
      Nurses: (
        <FormNurse
          facility={'OSSC'}
          data={data}
          onSubmit={onSubmit}
          loading={loading}
        />
      ),
    },
    PCSO: {
      CRNAANS: (
        <FormCRNAANSAngela5
          data={data}
          facility={'PCSO'}
          onSubmit={onSubmit}
          loading={loading}
        />
      ),
      MDANS: (
        <FormCRNAANSAngela5
          data={data}
          facility={'PCSO'}
          onSubmit={onSubmit}
          loading={loading}
        />
      ),
      MD: (
        <FormMD
          facility={'PCSO'}
          data={data}
          onSubmit={onSubmit}
          loading={loading}
        />
      ),
      Nurses: (
        <FormNurse
          facility={'PCSO'}
          data={data}
          onSubmit={onSubmit}
          loading={loading}
        />
      ),
    },
  }

  const formCondition = useMemo(() => {
    if (!form) return <Center>Select Form</Center>
    return formData[facility][form]
  }, [form, facility, formData])

  if (isFetching)
    return (
      <Center w="100%" h="20rem">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="lg"
        />
      </Center>
    )

  return (
    <Container maxW="100%" mt="5">
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
        bg="gray.100"
        p="1.5"
        borderRadius="0.3em"
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/dashboard">
            Dashbaord
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/peer-review">
            Peer Review
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/#">
            {data
              ? data.anesthesia || data.list?.[0]?.anesthesia
              : 'Create Form'}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex gap={4} w={'100%'} justifyContent={'end'} mt={2}>
        <Box>
          <Text mb="4px">Facility</Text>
          <Select
            name="facility"
            onChange={e => {
              setFacility(e.target.value)
              setForm('CRNAANS')
            }}
            defaultValue={facility}
            isDisabled={!!params.id}
          >
            <option value={'PCSO'}>PCSO</option>
            <option value={'OSSC'}>OSSC</option>
          </Select>
        </Box>
        <Box>
          <Text mb="4px">Form</Text>
          <Select
            name="status"
            onChange={e => setForm(e.target.value)}
            defaultValue={form}
            isDisabled={!!params.id}
          >
            {optionsData[facility].map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
          </Select>
        </Box>
      </Flex>
      {formCondition}
    </Container>
  )
}

export default PeerReviewForm
