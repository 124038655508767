import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import {
  Container,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  useToast,
  HStack,
  Center,
  Flex,
  Spacer,
  Select,
} from '@chakra-ui/react'

import { ChevronRightIcon } from '@chakra-ui/icons'
import { FaCloudDownloadAlt } from 'react-icons/fa'
import { addDoc, collection } from 'firebase/firestore'
import { db } from '../../config/firebase'

function PaidTimeOffRequestForm({ user }) {
  const { handleSubmit, reset, register, errors, control } = useForm()
  const toast = useToast()
  const [isDisabled, setIsDisabled] = useState(false)
  function submitData(value) {
    console.log(value)
    setIsDisabled(true)
    addDoc(collection(db, 'EmployeeForm'), {
      ...value,
      name: user.fullName,
      jobTitle: user.jobTitle,
      type: 'Paid Time Off Request',
      createdAt: new Date(),
      notification: true,
      status: 'Pending',
    }).then(() => {
      toast({
        title: 'Submit Successfully',
        description: 'Form has been submmited.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      setIsDisabled(false)
      reset()
    })
  }

  return (
    <Container maxW="100%" mt="5">
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
        bg="gray.100"
        p="1.5"
        borderRadius="0.3em"
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/dashboard">
            Dashbaord
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/paid-timeoff-request-form">
            Paid Time Off Request Form
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Box mt="30" mb="100" width={['100']}>
        <Flex mt="10" mb="10">
          <Box pt="2" fontSize="18" fontWeight="bold">
            Paid Time Off Request Form
          </Box>
          <Spacer />
          <Button
            onClick={() => {
              window.open(
                'https://firebasestorage.googleapis.com/v0/b/paincare-a9e20.appspot.com/o/Downloads%2FForms%2FPTO-Request-Form-1.pdf?alt=media&token=d3be8f8c-f2b3-49db-a0e5-ed6456814655',
                '_blank'
              )
            }}
            leftIcon={<FaCloudDownloadAlt />}
            size="sm"
          >
            PDF
          </Button>
        </Flex>
        <form onSubmit={handleSubmit(submitData)}>
          <HStack mb="5">
            <FormControl id="name" isReadOnly>
              <FormLabel>NAME</FormLabel>
              <Input name="name" defaultValue={user.fullName} />
            </FormControl>
            <FormControl id="jobTitle" isReadOnly>
              <FormLabel>JOB TITLE</FormLabel>
              <Input name="jobTitle" defaultValue={user.jobTitle} />
            </FormControl>
          </HStack>
          <FormControl
            mb="5"
            id="facility"
            isInvalid={errors.facility}
            width={['100%', '100%', '100%', '50%']}
          >
            <FormLabel>FACILITY</FormLabel>
            <Controller
              render={props => (
                <Select onChange={props.onChange} placeholder="Select Facility">
                  <option value="PCSO">PCSO</option>
                  <option value="OSSC">OSSC</option>
                  <option value="OPM">OPM</option>
                </Select>
              )}
              name="facility"
              control={control}
              defaultValue={null}
              rules={{ required: 'Fcility required.' }}
            />
            <FormErrorMessage>
              {errors.facility && errors.facility.message}
            </FormErrorMessage>
          </FormControl>
          <Center bg="black" mt="30" mb="30" h="35">
            <Text fontWeight="bold" color="white"></Text>
          </Center>
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '50%']}
            id="forPayPeriodOff"
            isInvalid={errors.forPayPeriodOf}
          >
            <FormLabel>FOR PAY PERIOD OF:</FormLabel>
            <Input
              name="forPayPeriodOf"
              ref={register({ required: 'For Pay Perioid Off required' })}
            />
            <FormErrorMessage>
              {errors.forPayPeriodOf && errors.forPayPeriodOf.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '50%']}
            isInvalid={errors.amountOfHoursThatNeedToBeUsed}
          >
            <FormLabel>AMOUNT OF HOURS THAT NEED TO BE USED:</FormLabel>
            <Input
              name="amountOfHoursThatNeedToBeUsed"
              ref={register({
                required: 'Amount Of Hours That Need to Be Used',
              })}
            />
            <FormErrorMessage>
              {errors.amountOfHoursThatNeedToBeUsed &&
                errors.amountOfHoursThatNeedToBeUsed.message}
            </FormErrorMessage>
          </FormControl>

          <Text mb="5" color="red.500">
            ** Employee certifies that all information provided is true and
            complete to the best of his/her knowledge. Falsification of this
            form is grounds for disciplinary action, up to and including
            dismissal.
          </Text>
          <Center mt="10">
            <Button
              colorScheme="blue"
              width={['100%', '100%', '100%', '10%']}
              isLoading={isDisabled}
              disabled={isDisabled}
              type="submit"
            >
              Submit
            </Button>
          </Center>
        </form>
      </Box>
    </Container>
  )
}

export default PaidTimeOffRequestForm
