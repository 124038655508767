import React, { useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import SignatureCanvas from 'react-signature-canvas'
import {
    Container,
    Image,
    Box,
    FormControl,
    FormLabel,
    Input,
    Button,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    HStack,
    Center,
    Textarea,
    CheckboxGroup,
    Checkbox,
    Text,
    Stack,
    Table,
    Thead,
    Tbody,
    Tr,
    Td,
    Divider,
    FormErrorMessage
} from '@chakra-ui/react'

import { ChevronRightIcon } from '@chakra-ui/icons'
import Logo from '../../Logo.png'

function KioskCheckIn() {

    const { handleSubmit, register, formState, errors, control } = useForm()
    const history = useHistory()
    const Sig1 = useRef({})
    const Sig2 = useRef({})

    const formatIntoPng = () => {
        if (Sig1.current) {
            const DataUrl = Sig1.current.getTrimmedCanvas().toDataURL()
            return DataUrl
        }
        if (Sig2.current) {
            const DataUrl = Sig2.current.getTrimmedCanvas().toDataURL()
            return DataUrl
        }
    }

    function onSubmit(values) {
        console.log(values)
        history.push("/web-enable-form/poa")
    }

    return (
        <Container maxW="100%" mt="5">
            <Image cursor="pointer" src={Logo} alt="paincare logo" htmlWidth="100px" />
            <Breadcrumb
                spacing="8px"
                separator={<ChevronRightIcon color="gray.500" />}
                bg="gray.100" p="1.5"
                borderRadius="0.3em"
                mt="5"
            >
                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to="/">Forms</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to="/web-enable-form">Web Enable New Patient Packet</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink>New Patient Intake Form for  Kiosk Check-in</BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>
            <Box mt="10" mb="10" textAlign="center" fontSize="lg" fontWeight="bold">
                New Patient Intake Form for  Kiosk Check-in
            </Box>
            <Container maxW={"container.xl"} mb="20">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box mt="10" mb="10" textAlign="">
                        Current Medications:
                    </Box>
                    <Stack direction={['column','row']} spacing="5">
                        <FormControl isInvalid={errors.currentMedication1}>
                            <FormLabel>Name</FormLabel>
                            <Input name="currentMedication1" ref={register({ required: 'Field required.' })} />
                        </FormControl>
                        <FormControl isInvalid={errors.dose1}>
                            <FormLabel>Dose</FormLabel>
                            <Input name="dose1" ref={register({ required: 'Field required.' })} />
                        </FormControl>
                        <FormControl isInvalid={errors.frequency1}>
                            <FormLabel>Frequency</FormLabel>
                            <Input name="frequency1" ref={register({ required: 'Field required.' })} />
                        </FormControl>
                    </Stack>
                    <Stack direction={['column','row']} spacing="5">
                        <FormControl isInvalid={errors.currentMedication2}>
                            <FormLabel>Name</FormLabel>
                            <Input name="currentMedication2" ref={register({ required: 'Field required.' })} />
                        </FormControl>
                        <FormControl isInvalid={errors.dose2}>
                            <FormLabel>Dose</FormLabel>
                            <Input name="dose2" ref={register({ required: 'Field required.' })} />
                        </FormControl>
                        <FormControl isInvalid={errors.frequency2}>
                            <FormLabel>Frequency</FormLabel>
                            <Input name="frequency2" ref={register({ required: 'Field required.' })} />
                        </FormControl>
                    </Stack>
                    <Stack direction={['column','row']} spacing="5">
                        <FormControl isInvalid={errors.currentMedication2}>
                            <FormLabel>Name</FormLabel>
                            <Input name="currentMedication3" ref={register({ required: 'Field required.' })} />
                        </FormControl>
                        <FormControl isInvalid={errors.currentMedication2}>
                            <FormLabel>Dose</FormLabel>
                            <Input name="dose3" ref={register({ required: 'Field required.' })} />
                        </FormControl>
                        <FormControl isInvalid={errors.currentMedication2}>
                            <FormLabel>Frequency</FormLabel>
                            <Input name="frequency3" ref={register({ required: 'Field required.' })} />
                        </FormControl>
                    </Stack>
                    <Stack direction={['column','row']} spacing="5">
                        <FormControl isInvalid={errors.currentMedication4}>
                            <FormLabel>Name</FormLabel>
                            <Input name="currentMedication4" ref={register({ required: 'Field required.' })} />
                        </FormControl>
                        <FormControl isInvalid={errors.dose4}>
                            <FormLabel>Dose</FormLabel>
                            <Input name="dose4" ref={register({ required: 'Field required.' })} />
                        </FormControl>
                        <FormControl isInvalid={errors.frequency4}>
                            <FormLabel>Frequency</FormLabel>
                            <Input name="frequency4" ref={register({ required: 'Field required.' })} />
                        </FormControl>
                    </Stack>
                    <Stack direction={['column','row']} spacing="5">
                        <FormControl isInvalid={errors.currentMedication5}>
                            <FormLabel>Name</FormLabel>
                            <Input name="currentMedication5" ref={register({ required: 'Field required.' })} />
                        </FormControl>
                        <FormControl isInvalid={errors.dose5}>
                            <FormLabel>Dose</FormLabel>
                            <Input name="dose5" ref={register({ required: 'Field required.' })} />
                        </FormControl>
                        <FormControl isInvalid={errors.frequency5}>
                            <FormLabel>Frequency</FormLabel>
                            <Input name="frequency5" ref={register({ required: 'Field required.' })} />
                        </FormControl>
                    </Stack>
                    <Box mt="5">
                        Current Medical Problems:
                    </Box>
                    <Textarea mt="5" isInvalid={errors.currentMedicalProblems} name="currentMedicalProblems" ref={register({ required: 'Field required.' })} />
                    <FormControl mt="5">
                        <FormLabel>Allergies (Circle all that apply):</FormLabel>
                        <Controller
                            render={(props) => (
                                <CheckboxGroup onChange={props.onChange} onBlur={props.onBlur}>
                                    <Stack direction={['column','row']} spacing="5">
                                        <Stack>
                                            <Checkbox value="None">None</Checkbox>
                                            <Checkbox value="Tape">Tape</Checkbox>
                                            <Checkbox value="Iodine">Iodine</Checkbox>
                                        </Stack>
                                        <Stack>
                                            <Checkbox value="Latex">Latex</Checkbox>
                                            <Checkbox value="Contrast Dye">Contrast Dye</Checkbox>
                                            <Checkbox value="Other">Other</Checkbox>
                                        </Stack>
                                    </Stack>
                                </CheckboxGroup>
                            )}
                            defaultValue={''}
                            name="allergies"
                            control={control}
                            rules={{ required: 'Allergies required.' }}
                        />
                        {errors.allergies && <Text color="red">{errors.allergies.message}</Text>}
                    </FormControl>
                    <Box mt="10">
                        Medication Allergies (list):
                    </Box>
                    <Textarea isInvalid={errors.medicationAllergies} mt="5" name="medicationAllergies" ref={register({ required: 'Field required.' })} />
                    <FormControl mt="5">
                        <FormLabel>Surgical History (Circle all that apply):</FormLabel>
                        <Controller
                            render={(props) => (
                                <CheckboxGroup onChange={props.onChange} onBlur={props.onBlur}>
                                    <Stack direction={['column','row']} spacing="5">
                                        <Stack>
                                            <Checkbox value="Cardiac/Heart">Cardiac/Heart</Checkbox>
                                            <Checkbox value="Cervical/Neck">Cervical/Neck</Checkbox>
                                            <Checkbox value="Lumbar/Back">Lumbar/Back</Checkbox>
                                        </Stack>
                                        <Stack>
                                            <Checkbox value="Hip Left/Right">Hip Left/Right</Checkbox>
                                            <Checkbox value="Knee Left/Right">Knee Left/Right</Checkbox>
                                            <Checkbox value="Shoulder Left/Right">Shoulder Left/Right</Checkbox>
                                        </Stack>
                                    </Stack>
                                </CheckboxGroup>
                            )}
                            defaultValue={''}
                            name="surgicalHistory"
                            control={control}
                            rules={{ required: 'Sugical History required.' }}
                        />
                        {errors.surgicalHistory && <Text color="red">{errors.surgicalHistory.message}</Text>}
                    </FormControl>
                    <Box mt="5">
                        Current Medical Problems:
                    </Box>
                    <Stack direction={['column','row']} spacing="5">
                        <FormControl isInvalid={errors.pastHospitalDate1}>
                            <FormLabel>Date:</FormLabel>
                            <Input name="pastHospitalDate1" ref={register({ required: 'Field required.' })} />
                        </FormControl>
                        <FormControl isInvalid={errors.pastHospitalReason1}>
                            <FormLabel>Reason:</FormLabel>
                            <Input name="pastHospitalReason1" ref={register({ required: 'Field required.' })} />
                        </FormControl>
                        <FormControl isInvalid={errors.pastHospitalDate2}>
                            <FormLabel>Date:</FormLabel>
                            <Input name="pastHospitalDate2" ref={register({ required: 'Field required.' })} />
                        </FormControl>
                        <FormControl isInvalid={errors.pastHospitalReason2}>
                            <FormLabel>Reason:</FormLabel>
                            <Input name="pastHospitalReason2" ref={register({ required: 'Field required.' })} />
                        </FormControl>
                    </Stack>
                    <Stack direction={['column','row']} spacing="5">
                        <FormControl isInvalid={errors.pastHospitalDate3}>
                            <FormLabel>Date:</FormLabel>
                            <Input name="pastHospitalDate3" ref={register({ required: 'Field required.' })} />
                        </FormControl>
                        <FormControl isInvalid={errors.pastHospitalReason3}>
                            <FormLabel>Reason:</FormLabel>
                            <Input name="pastHospitalReason3" ref={register({ required: 'Field required.' })} />
                        </FormControl>
                        <FormControl isInvalid={errors.pastHospitalDate4}>
                            <FormLabel>Date:</FormLabel>
                            <Input name="pastHospitalDate4" ref={register({ required: 'Field required.' })} />
                        </FormControl>
                        <FormControl isInvalid={errors.pastHospitalReason4}>
                            <FormLabel>Reason:</FormLabel>
                            <Input name="pastHospitalReason4" ref={register({ required: 'Field required.' })} />
                        </FormControl>
                    </Stack>
                    <Stack overflowX={'hidden'} maxW={'1250px'}>
                    <Box mt="10">Family History (Please place an X where applicable):</Box>
                    <FormControl mt="5">
                        <Controller
                            render={(props) => (
                                <Table>
                                    <CheckboxGroup onChange={props.onChange} onBlur={props.onBlur}>
                                        <Thead>
                                            <Tr>
                                                <Td w={'220px'}></Td>
                                                <Td w={'220px'}>Father</Td>
                                                <Td w={'220px'}>Mother</Td>
                                                <Td w={'220px'}>Grandparent</Td>
                                                <Td w={'220px'}>Sibling</Td>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            <Td w={'220px'}>Heart Disease</Td>
                                            <Td w={'220px'}><Checkbox value="Father"/></Td>
                                            <Td w={'220px'}><Checkbox value="Mother"/></Td>
                                            <Td w={'220px'}><Checkbox value="Grandparent"/></Td>
                                            <Td w={'220px'}><Checkbox value="Sibling"/></Td>
                                        </Tbody>
                                    </CheckboxGroup>
                                </Table>
                            )}
                            defaultValue={''}
                            name="heartDisease"
                            control={control}
                        />
                        {errors.heartDisease && <Text color="red">{errors.heartDisease.message}</Text>}
                    </FormControl>
                    <FormControl mt="5">
                        <Controller
                            render={(props) => (
                                <Table>
                                    <CheckboxGroup onChange={props.onChange} onBlur={props.onBlur}>
                                        <Tbody>
                                            <Td w={'220px'}>Lung Disease</Td>
                                            <Td w={'220px'}><Checkbox value="Father"/></Td>
                                            <Td w={'220px'}><Checkbox value="Mother"/></Td>
                                            <Td w={'220px'}><Checkbox value="Grandparent"/></Td>
                                            <Td w={'220px'}><Checkbox value="Sibling"/></Td>
                                        </Tbody>
                                    </CheckboxGroup>
                                </Table>
                            )}
                            defaultValue={''}
                            name="lungDisease"
                            control={control}
                        />
                        {errors.lungDisease && <Text color="red">{errors.lungDisease.message}</Text>}
                    </FormControl>
                    <FormControl mt="5">
                        <Controller
                            render={(props) => (
                                <Table>
                                    <CheckboxGroup onChange={props.onChange} onBlur={props.onBlur}>
                                        <Tbody>
                                            <Td w={'220px'}>Arthrits</Td>
                                            <Td w={'220px'}><Checkbox value="Father"/></Td>
                                            <Td w={'220px'}><Checkbox value="Mother"/></Td>
                                            <Td w={'220px'}><Checkbox value="Grandparent"/></Td>
                                            <Td w={'220px'}><Checkbox value="Sibling"/></Td>
                                        </Tbody>
                                    </CheckboxGroup>
                                </Table>
                            )}
                            defaultValue={''}
                            name="arthrits"
                            control={control}
                        />
                        {errors.arthrits && <Text color="red">{errors.arthrits.message}</Text>}
                    </FormControl>
                    <FormControl mt="5">
                        <Controller
                            render={(props) => (
                                <Table>
                                    <CheckboxGroup onChange={props.onChange} onBlur={props.onBlur}>
                                        <Tbody>
                                            <Td w={'220px'}>Cancer</Td>
                                            <Td w={'220px'}><Checkbox value="Father"/></Td>
                                            <Td w={'220px'}><Checkbox value="Mother"/></Td>
                                            <Td w={'220px'}><Checkbox value="Grandparent"/></Td>
                                            <Td w={'220px'}><Checkbox value="Sibling"/></Td>
                                        </Tbody>
                                    </CheckboxGroup>
                                </Table>
                            )}
                            defaultValue={''}
                            name="Cancer"
                            control={control}
                        />
                        {errors.Cancer && <Text color="red">{errors.Cancer.message}</Text>}
                    </FormControl>
                    <FormControl mt="5">
                        <Controller
                            render={(props) => (
                                <Table>
                                    <CheckboxGroup onChange={props.onChange} onBlur={props.onBlur}>
                                        <Tbody>
                                            <Td w={'220px'}>Stroke</Td>
                                            <Td w={'220px'}><Checkbox value="Father"/></Td>
                                            <Td w={'220px'}><Checkbox value="Mother"/></Td>
                                            <Td w={'220px'}><Checkbox value="Grandparent"/></Td>
                                            <Td w={'220px'}><Checkbox value="Sibling"/></Td>
                                        </Tbody>
                                    </CheckboxGroup>
                                </Table>
                            )}
                            defaultValue={''}
                            name="Stroke"
                            control={control}
                        />
                        {errors.stroke && <Text color="red">{errors.stroke.message}</Text>}
                    </FormControl>
                    </Stack>
                    <Stack>
                        <Stack direction={['column','row']} alignItems={'end'} justifyContent={'space-between'} spacing={'100px'}>
                            <FormControl
                                w={'500px'}
                                isInvalid={errors.Sig1}
                            >
                                <Controller
                                    render={(props) => (
                                        <SignatureCanvas ref={Sig1} onEnd={()=>{props.onChange(formatIntoPng())}} penColor='black' canvasProps={{width: 500, height: 100, className: 'sigCanvas'}} />
                                        )}
                                        defaultValue={''}
                                        name="Sig1"
                                        control={control}
                                        rules={{ required: 'Impotant information required.' }}
                                        />
                                        <Divider borderColor={'gray.600'} borderStart={'dotted'} />
                                <FormLabel textAlign={'center'}>Signature</FormLabel>
                            <FormErrorMessage>{errors.Sig1 && errors.Sig1.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl maxW={["100%", "100%", "30%", "30%"]} isInvalid={errors.month && errors.day && errors.year}>
                                <HStack>
                                    <Input name="month" type="text" placeholder="mm" ref={register({ required: 'Date required.' })} />
                                    <Input name="day" type="text" placeholder="dd" ref={register({ required: 'Date required.' })} />
                                    <Input name="year" type="text" placeholder="yyyy" ref={register({ required: 'Date required.' })} />
                                </HStack>
                                {/* <Divider borderColor={'gray.600'} borderStart={'dotted'} /> */}
                                <FormLabel textAlign={'center'}>Date</FormLabel>
                                <FormErrorMessage>{errors.month && errors.day && errors.year && errors.month.message}</FormErrorMessage>
                            </FormControl>
                        </Stack>
                        <Stack direction={['column','row']} alignItems={'end'} justifyContent={'space-between'} spacing={'100px'}>
                            <FormControl
                                w={"500px"}
                                isInvalid={errors.Sig2}
                            >

                                <Controller
                                    render={(props) => (
                                        <SignatureCanvas ref={Sig2} onEnd={()=>{props.onChange(formatIntoPng())}} penColor='black' canvasProps={{width: 500, height: 100, className: 'sigCanvas'}} />
                                        )}
                                        defaultValue={''}
                                        name="Sig2"
                                        control={control}
                                        rules={{ required: 'Impotant information required.' }}
                                        />
                                        <Divider borderColor={'gray.600'} borderStart={'dotted'} />
                                <FormLabel textAlign={'center'}>Signature</FormLabel>
                            <FormErrorMessage>{errors.Sig2 && errors.Sig2.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl maxW={["100%", "100%", "30%", "30%"]} isInvalid={errors.month && errors.day && errors.year}>
                                <HStack>
                                    <Input name="month2" type="text" placeholder="mm" ref={register({ required: 'Date required.' })} />
                                    <Input name="day2" type="text" placeholder="dd" ref={register({ required: 'Date required.' })} />
                                    <Input name="year2" type="text" placeholder="yyyy" ref={register({ required: 'Date required.' })} />
                                </HStack>
                                <FormLabel textAlign={'center'}>Date</FormLabel>
                                <FormErrorMessage>{errors.month && errors.day && errors.year && errors.month.message}</FormErrorMessage>
                            </FormControl>
                        </Stack>
                        <HStack>
                            <FormControl maxW={["100%", "100%", "100%", "100%"]} isInvalid={errors.DOBmonth && errors.DOBmday && errors.DOBmyear}>
                                <HStack>
                                    <Input name="DOBmonth" type="text" placeholder="mm" ref={register({ required: 'DOB information required.' })} />
                                    <Input name="DOBmday" type="text" placeholder="dd" ref={register({ required: 'DOB information required.' })} />
                                    <Input name="DOBmyear" type="text" placeholder="yyyy" ref={register({ required: 'DOB information required.' })} />
                                </HStack>
                                <FormLabel textAlign={'center'}>Patient's Date of Birth</FormLabel>
                                <FormErrorMessage>{errors.DOBmonth && errors.DOBmday && errors.DOBmyear && errors.DOBmonth.message}</FormErrorMessage>
                            </FormControl>
                        </HStack>
                    </Stack>
                    <Center>
                        <Button mt="5" colorScheme="blue" type="submit" isLoading={formState.isSubmitting}>Submit</Button>
                    </Center>
                </form>
            </Container>
        </Container>
    )
}

export default KioskCheckIn