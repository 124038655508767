import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react'
import React from 'react'

export const modals = {}

modals.confirm = props => {
  const {
    // cancelRef,
    onClose,
    isOpen,
    cancelText = 'No',
    submitText = 'Yes',
    title = 'Remove?',
    description = 'Are you sure you want to remove?',
    onSubmit,
  } = props
  return (
    <AlertDialog
      motionPreset="slideInBottom"
      //   leastDestructiveRef={cancelRef || null}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>{title}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>{description}</AlertDialogBody>
        <AlertDialogFooter>
          <Button
            //   ref={cancelRef}
            onClick={onClose}
          >
            {cancelText}
          </Button>
          <Button colorScheme="red" ml={3} onClick={onSubmit}>
            {submitText}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
