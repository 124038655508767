import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import {
  Container,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Box,
  Table,
  Tbody,
  Tr,
  Td,
  Spinner,
  Center,
  Stack,
  Text,
  HStack,
  Button,
  Badge,
  Divider,
  Image,
} from '@chakra-ui/react'
import { doc, onSnapshot, getDoc } from 'firebase/firestore'
import { db } from '../../config/firebase'
import { ChevronRightIcon } from '@chakra-ui/icons'
import renderDate from '../../config/renderDate'
function ViewRegister() {
  const params = useParams()
  const [register, setRegister] = useState(null)

  useEffect(() => {
    const id = params.id
    if (id) {
      const registerRef = doc(db, 'RegisterList', id)
      onSnapshot(registerRef, async snap => {
        if (snap.exists()) {
          const invoice = await (await getDoc(snap.data().invoiceRef)).data()
          setRegister({ ...snap.data(), invoice: invoice, id: snap.id })
        }
      })
    }
  }, [params.id])

  return (
    <Container maxW="100%" mt="5">
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
        bg="gray.100"
        p="1.5"
        borderRadius="0.3em"
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/dashboard">
            Dashbaord
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/event">
            Event
          </BreadcrumbLink>
        </BreadcrumbItem>
        {register ? (
          <BreadcrumbItem>
            <BreadcrumbLink>
              {register.firstName} {register.middleName} {register.lastName},{' '}
              {register.jobTitle}
            </BreadcrumbLink>
          </BreadcrumbItem>
        ) : (
          <BreadcrumbItem>
            <Spinner size="xs" />
          </BreadcrumbItem>
        )}
      </Breadcrumb>
      <Box>
        {register ? (
          <Box mt="5" mb="20">
            <Box textAlign="right" mt="5" mb="5">
              {/* Action Section */}
            </Box>
            <Stack
              direction={['column', 'column', 'column', 'row']}
              spacing="10"
            >
              <Box width="50%">
                <Center mb="5">
                  <Text fontSize="2xl">{register.eventName} Register</Text>
                </Center>
                <Table>
                  <Tbody>
                    <Tr>
                      <Td textAlign="right">Register date :</Td>
                      <Td>{renderDate(register.createdAt)}</Td>
                    </Tr>
                    <Tr>
                      <Td textAlign="right">Full name :</Td>
                      <Td>
                        {register.firstName} {register.middleName}{' '}
                        {register.lastName}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td textAlign="right">Telephone :</Td>
                      <Td>{register.mobileNo}</Td>
                    </Tr>
                    <Tr>
                      <Td textAlign="right">Email :</Td>
                      <Td>{register.email}</Td>
                    </Tr>
                    <Tr>
                      <Td textAlign="right">Job Title :</Td>
                      <Td>{register.jobTitle}</Td>
                    </Tr>
                    <Tr>
                      <Td textAlign="right">Organization :</Td>
                      <Td>{register.organization}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Box>
              <Box w={['100%', '100%', '100%', '50%']} mb="30">
                <Center mb="5">
                  <Text fontSize="2xl">Payment & E-Ticket</Text>
                </Center>
                <Box
                  w="100%"
                  borderRadius="0.4em"
                  mb="5"
                  height="300"
                  p="5"
                  mt="5"
                  backgroundColor="gray.50"
                >
                  <Stack>
                    <HStack>
                      <Text>Payment Status:</Text>
                      <Badge
                        variant="subtle"
                        colorScheme={
                          register?.invoice?.stripeInvoiceStatus === 'paid'
                            ? 'green'
                            : 'orange'
                        }
                      >
                        {register?.invoice?.stripeInvoiceStatus}
                      </Badge>
                    </HStack>
                    <HStack>
                      <Button
                        onClick={() => {
                          window.open(
                            register?.invoice?.stripeInvoiceUrl,
                            '_blank'
                          )
                        }}
                        colorScheme="blue"
                        size="sm"
                        variant="outline"
                      >
                        Invoice Link
                      </Button>
                      <Button
                        onClick={() => {
                          window.open(
                            register?.invoice?.stripeInvoiceRecord,
                            '_blank'
                          )
                        }}
                        colorScheme="blue"
                        size="sm"
                        variant="outline"
                      >
                        Invoice Record
                      </Button>
                    </HStack>
                  </Stack>
                  <Divider mt="1rem" mb="1rem" />
                  <Stack>
                    <HStack>
                      <Text>Ticket Reference:</Text>
                      <Text fontWeight="bold">{register?.ref}</Text>
                    </HStack>
                    <Box>
                      <Image
                        src={`https://chart.googleapis.com/chart?cht=qr&chs=150x150&chl=${process.env.REACT_APP_APP_URL}/verify-event/${register?.ref}`}
                        alt="qrcode"
                      />
                    </Box>
                  </Stack>
                </Box>
              </Box>
            </Stack>
          </Box>
        ) : (
          <Center>
            <Spinner
              mt="10"
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Center>
        )}
      </Box>
    </Container>
  )
}

export default ViewRegister
