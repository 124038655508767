import React, { useRef }  from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../Logo.png'
import { useForm, Controller } from 'react-hook-form'
import { ChevronRightIcon } from '@chakra-ui/icons'
import SignatureCanvas from 'react-signature-canvas'
import {
    Container,
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Image,
    FormControl,
    FormLabel,
    Input,
    Text,
    Center,
    HStack,
    Stack,
    FormErrorMessage,
    Button,
    Divider
} from '@chakra-ui/react'
function WebEnabledFinish() {

    const { register, handleSubmit, formState, errors, control } = useForm()
    
    const Sig1 = useRef({})
    const Sig2 = useRef({})

    const formatIntoPng = () => {
        if (Sig1.current) {
            const DataUrl = Sig1.current.getTrimmedCanvas().toDataURL()
            return DataUrl
        }
        if (Sig2.current) {
            const DataUrl = Sig2.current.getTrimmedCanvas().toDataURL()
            return DataUrl
        }
    }
    const onSubmit = (values) => {
        console.log(values);
    }
  return (
    <Container maxW={'100%'} mt={"5"}>
        <Image cursor="pointer" src={Logo} alt="paincare logo" htmlWidth="100px" />
            <Breadcrumb
                spacing="8px"
                separator={<ChevronRightIcon color="gray.500" />}
                bg="gray.100" p="1.5"
                borderRadius="0.3em"
                mt="5"
            >
                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to="/">Forms</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to="/web-enable-form">Web Enable New Patient Packet</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to="/web-enable-form/kios-checkin">New Patient Intake Form for  Kiosk Check-in</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to="/web-enable-form/poa">Authorization Letter</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink>Finish</BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>
            <Container maxW={'container.xl'} mt={"10"}>
                <form onSubmit={handleSubmit(onSubmit)}>
                <Center>
                    <Image src={Logo} w={'15%'} />
                </Center>
                <Stack alignItems={'center'}>
                    <Box pt={'5'} w={"60%"}>
                        <Text textAlign={'center'} fontSize={'20'} fontWeight={'medium'}>
                            PERMISSION TO RELEASE CONFIDENTIAL MEDICAL INFORMATION TO A FAMILY MEMBER, FRIEND OR LEGAL REPRESENTAITVE
                        </Text>
                    </Box>
                    <Box pt={'10'} w={"55%"}>
                        <Text textAlign={'center'} fontSize={'16'} fontWeight={'medium'}>
                            IMPORTANT NOTE: The law prohibits release of confidential Medical information to any entity without the written, voluntary consent of the undersigned patient.
                        </Text>
                    </Box>
                </Stack>
                <Stack direction={['column','row']}>
                    <FormControl isInvalid={errors.NameOfPatient}>
                        <HStack>
                            <FormLabel w={'10rem'}>
                                Name of Patient
                            </FormLabel>
                            <Input name={'NameOfPatient'} ref={register({required:"Important information required"})} />
                        </HStack>
                        <FormErrorMessage>{errors.NameOfPatient && errors.NameOfPatient.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl maxW={["100%", "100%", "30%", "30%"]} isInvalid={errors.month && errors.day && errors.year}>
                        <HStack>
                        <FormLabel w={'50rem'}>Date of Birth:</FormLabel>
                            <Input name="month1" type="text" placeholder="mm" ref={register({ required: 'DOB information required.' })} />
                            <Input name="day1" type="text" placeholder="dd" ref={register({ required: 'DOB information required.' })} />
                            <Input name="year1" type="text" placeholder="yyyy" ref={register({ required: 'DOB information required.' })} />
                        </HStack>
                        <FormErrorMessage>{errors.month && errors.day && errors.year && errors.month.message}</FormErrorMessage>
                    </FormControl>
                </Stack>
                <Stack >
                    <Box pt={'10'}>
                        <Text textAlign={'left'} fontSize={'18px'}>
                            I give permission for Pain Care Specialists of Oregon, LLC, or their designated representative to communicate information about my medical condition and treatment to:
                        </Text>
                    </Box>
                </Stack>
                <Stack>
                    <Stack direction={['column','row']} alignItems={'end'} justifyContent={'space-between'} spacing={'100px'}>
                        <FormControl isInvalid={errors.Name1}>
                            <Input name={'Name1'} ref={register({required:"Important information required"})} />
                            <FormLabel>Name</FormLabel>
                            <FormErrorMessage>{errors.Name1 && errors.Name1.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={errors.Phone1}>
                            <Input name={'Phone1'} ref={register({required:"Important information required"})} />
                            <FormLabel>Phone</FormLabel>
                            <FormErrorMessage>{errors.Phone1 && errors.Phone1.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={errors.Relationship1}>
                            <Input name={'Relationship1'} ref={register({required:"Important information required"})} />
                            <FormLabel>Relationship</FormLabel>
                            <FormErrorMessage>{errors.Relationship1 && errors.Relationship1.message}</FormErrorMessage>
                        </FormControl>
                    </Stack>

                    <Stack direction={['column','row']} alignItems={'end'} justifyContent={'space-between'} spacing={'100px'}>
                        <FormControl isInvalid={errors.Name2}>
                            <Input name={'Name2'} ref={register({required:"Important information required"})} />
                            <FormLabel>Name</FormLabel>
                            <FormErrorMessage>{errors.Name2 && errors.Name2.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={errors.Phone2}>
                            <Input name={'Phone2'} ref={register({required:"Important information required"})} />
                            <FormLabel>Phone</FormLabel>
                            <FormErrorMessage>{errors.Phone2 && errors.Phone2.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={errors.Relationship2}>
                            <Input name={'Relationship2'} ref={register({required:"Important information required"})} />
                            <FormLabel>Relationship</FormLabel>
                            <FormErrorMessage>{errors.Relationship2 && errors.Relationship2.message}</FormErrorMessage>
                        </FormControl>
                    </Stack>

                    <Stack direction={['column','row']} alignItems={'end'} justifyContent={'space-between'} spacing={'100px'}>
                        <FormControl isInvalid={errors.Name3}>
                            <Input name={'Name3'} ref={register({required:"Important information required"})} />
                            <FormLabel>Name</FormLabel>
                            <FormErrorMessage>{errors.Name3 && errors.Name3.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={errors.Phone3}>
                            <Input name={'Phone3'} ref={register({required:"Important information required"})} />
                            <FormLabel>Phone</FormLabel>
                            <FormErrorMessage>{errors.Phone3 && errors.Phone3.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={errors.Relationship3}>
                            <Input name={'Relationship3'} ref={register({required:"Important information required"})} />
                            <FormLabel>Relationship</FormLabel>
                            <FormErrorMessage>{errors.Relationship3 && errors.Relationship3.message}</FormErrorMessage>
                        </FormControl>
                    </Stack>

                    <Stack direction={['column','row']} alignItems={'end'} justifyContent={'space-between'} spacing={'100px'}>
                        <FormControl isInvalid={errors.Name4}>
                            <Input name={'Name4'} ref={register({required:"Important information required"})} />
                            <FormLabel>Name</FormLabel>
                            <FormErrorMessage>{errors.Name4 && errors.Name4.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={errors.Phone4}>
                            <Input name={'Phone4'} ref={register({required:"Important information required"})} />
                            <FormLabel>Phone</FormLabel>
                            <FormErrorMessage>{errors.Phone4 && errors.Phone4.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={errors.Relationship4}>
                            <Input name={'Relationship4'} ref={register({required:"Important information required"})} />
                            <FormLabel>Relationship</FormLabel>
                            <FormErrorMessage>{errors.Relationship4 && errors.Relationship4.message}</FormErrorMessage>
                        </FormControl>
                    </Stack>
                    
                </Stack>
                <Stack>
                    <Stack direction={['column','row']} alignItems={'end'} justifyContent={'space-between'} spacing={'100px'}>
                        <FormControl isInvalid={errors.PrintedNameOfPatient}>
                            <Input name="PrintedNameOfPatient" ref={register({required:"Important information required"})} />
                            <FormLabel textAlign={'center'}>Printed Name of Patient</FormLabel>
                            <FormErrorMessage>{errors.PrintedNameOfPatient && errors.PrintedNameOfPatient.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl
                            isInvalid={errors.Sig1}
                        >
                            <Controller
                                render={(props) => (
                                    <SignatureCanvas ref={Sig1} onEnd={()=>{props.onChange(formatIntoPng())}} penColor='black' canvasProps={{width: 350, height: 100, className: 'sigCanvas'}} />
                                    )}
                                    defaultValue={''}
                                    name="Sig1"
                                    control={control}
                                    rules={{ required: 'Impotant information required.' }}
                                    />
                                    <Divider borderColor={'gray.600'} borderStart={'dotted'} />
                            <FormLabel textAlign={'center'}>Signature</FormLabel>
                            <FormErrorMessage>{errors.Sig1 && errors.Sig1.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl maxW={["100%", "100%", "30%", "30%"]} isInvalid={errors.month && errors.day && errors.year}>
                            <HStack>
                                <Input name="month" type="text" placeholder="mm" ref={register({ required: 'DOB information required.' })} />
                                <Input name="day" type="text" placeholder="dd" ref={register({ required: 'DOB information required.' })} />
                                <Input name="year" type="text" placeholder="yyyy" ref={register({ required: 'DOB information required.' })} />
                            </HStack>
                            <FormLabel textAlign={'center'}>Date</FormLabel>
                            <FormErrorMessage>{errors.month && errors.day && errors.year && errors.month.message}</FormErrorMessage>
                        </FormControl>
                    </Stack>
                    <Stack direction={['column','row']} alignItems={'end'} justifyContent={'space-between'} spacing={'100px'}>
                        <FormControl isInvalid={errors.PrintedNameOfRepresentative}>
                            <Input name="PrintedNameOfRepresentative" ref={register({required:"Important information required"})} />
                            <FormLabel textAlign={'center'}>Printed Name of Representative</FormLabel>
                            <FormErrorMessage>{errors.PrintedNameOfRepresentative && errors.PrintedNameOfRepresentative.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl
                            isInvalid={errors.Sig2}
                        >

                            <Controller
                                render={(props) => (
                                    <SignatureCanvas ref={Sig2} onEnd={()=>{props.onChange(formatIntoPng())}} penColor='black' canvasProps={{width: 350, height: 100, className: 'sigCanvas'}} />
                                    )}
                                    defaultValue={''}
                                    name="Sig2"
                                    control={control}
                                    rules={{ required: 'Impotant information required.' }}
                                    />
                                    <Divider borderColor={'gray.600'} borderStart={'dotted'} />
                            <FormLabel textAlign={'center'}>Signature</FormLabel>
                            <FormErrorMessage>{errors.Sig2 && errors.Sig2.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl maxW={["100%", "100%", "30%", "30%"]} isInvalid={errors.month && errors.day && errors.year}>
                            <HStack>
                                <Input name="month2" type="text" placeholder="mm" ref={register({ required: 'DOB information required.' })} />
                                <Input name="day2" type="text" placeholder="dd" ref={register({ required: 'DOB information required.' })} />
                                <Input name="year2" type="text" placeholder="yyyy" ref={register({ required: 'DOB information required.' })} />
                            </HStack>
                            <FormLabel textAlign={'center'}>Date</FormLabel>
                            <FormErrorMessage>{errors.month && errors.day && errors.year && errors.month.message}</FormErrorMessage>
                        </FormControl>
                    </Stack>
                </Stack>
                <Center>
                    <Button my={'10'} type='submit' colorScheme={'blue'} isLoading={formState.isSubmitting}>
                        Submit
                    </Button>
                </Center>
                </form>
            </Container>
    </Container>
  )
}

export default WebEnabledFinish