import { ChevronRightIcon } from '@chakra-ui/icons'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Spinner,
} from '@chakra-ui/react'
import { doc, getDoc } from 'firebase/firestore'
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { db } from '../../config/firebase'
import PDFFormMedicalRecord from './PDFForm/FormMedicalRecord'
import FormWebEnableForm from './PDFForm/FormWebEnableForm'
import FormNonWebEnableForm from './PDFForm/FormNonWebEnableForm'

function FillablePDFForm() {
  const [fillableData, setfillableData] = useState(null)
  const [fillableLoading, setfillableLoading] = useState(true)
  const { id, formType } = useParams()

  const fetchfillableData = useCallback(async () => {
    const fillableRef = doc(db, 'Form', id)
    const getfillableData = (await getDoc(fillableRef)).data()
    setfillableData({
      ...getfillableData,
      createdAt: getfillableData?.createdAt?.toDate() || null,
    })
    setfillableLoading(false)
  }, [id])

  useEffect(() => {
    fetchfillableData()
  }, [id, fetchfillableData])

  if (fillableLoading) {
    return (
      <Flex
        minW={'100%'}
        minH={'100vh'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <p>loading...</p>
        <Spinner ml={2} size={'md'} />
      </Flex>
    )
  }

  return (
    <>
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
        bg="gray.100"
        p="1.5"
        borderRadius="0.3em"
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/dashboard">
            Dashbaord
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/fillable">
            Fillable
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem as={Link} to={`/fillable/view/${id}`}>
          <BreadcrumbLink>
            {formType === 'WebEnableForm' && (
              <>{fillableData?.namePatient ? fillableData?.namePatient : ''}</>
            )}
            {formType === 'NonWebEnableForm' && (
              <>{fillableData?.namePatient ? fillableData?.namePatient : ''}</>
            )}
            {formType === 'MedicalRecord' && (
              <>
                {fillableData?.authorizeForm3
                  ? fillableData?.authorizeForm3
                  : ''}
              </>
            )}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink>{formType}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      {formType === 'WebEnableForm' && (
        <>
          <FormWebEnableForm fillableData={fillableData} />
        </>
      )}
      {formType === 'NonWebEnableForm' && (
        <>
          <FormNonWebEnableForm fillableData={fillableData} />
        </>
      )}
      {formType === 'MedicalRecord' && (
        <>
          <PDFFormMedicalRecord fillableData={fillableData} />
        </>
      )}
    </>
  )
}

export default FillablePDFForm
