import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import {
  Box,
  HStack,
  Stack,
  Container,
  FormControl,
  FormLabel,
  Input,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Image,
  Radio,
  RadioGroup,
  CheckboxGroup,
  Checkbox,
  Center,
  FormErrorMessage,
  Text,
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import Logo from '../../Logo.png'

function WebEnableForm() {
  const { handleSubmit, register, formState, errors, control } = useForm()
  const history = useHistory()
  function onSubmit(values) {
    console.log(values)
    let submitData = JSON.stringify({ patientInfo: { ...values } })
    localStorage.setItem('submitData', submitData)
    history.push('/web-enable-form/kios-checkin')
  }

  return (
    <Container maxW="100%" mt="5">
      <Image
        cursor="pointer"
        src={Logo}
        alt="paincare logo"
        htmlWidth="100px"
      />
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
        bg="gray.100"
        p="1.5"
        borderRadius="0.3em"
        mt="5"
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/">
            Forms
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink>Web Enable New Patient Packet</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Box mt="10" mb="10" textAlign="center" fontSize="lg" fontWeight="bold">
        Web Enable New Patient Packet
      </Box>
      <Container maxW={'container.xl'} mb="20">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            backgroundColor="blue.100"
            h="30"
            p="1"
            fontWeight="bold"
            mt="10"
            mb="10"
          >
            Patient Demographics
          </Box>
          <Box mt="5" maxW={['100%', '100%', '100%', '100%']}>
            <FormControl maxW="100%" isInvalid={errors.fullName}>
              <FormLabel>Name:</FormLabel>
              <Input
                name="fullName"
                type="text"
                ref={register({ required: 'Important information required.' })}
              />
              <FormErrorMessage>
                {errors.fullName && errors.fullName.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl maxW="100%" isInvalid={errors.address}>
              <FormLabel>Address:</FormLabel>
              <Input
                name="address"
                type="text"
                ref={register({ required: 'Important information required' })}
              />
              <FormErrorMessage>
                {errors.fullName && errors.fullName.message}
              </FormErrorMessage>
            </FormControl>
            <HStack spacing="20px">
              <FormControl maxW="100%" isInvalid={errors.cellTel}>
                <FormLabel>Cell Phone:</FormLabel>
                <Input
                  name="cellTel"
                  type="text"
                  ref={register({
                    required: 'Cell Phone information required',
                  })}
                />
                <FormErrorMessage>
                  {errors.cellTel && errors.cellTel.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl maxW="100%">
                <FormLabel>Home Phone:</FormLabel>
                <Input name="homeTel" type="text" />
              </FormControl>
            </HStack>
            <HStack spacing="20px" mt="5">
              <FormControl maxW="100%" isInvalid={errors.ssn}>
                <FormLabel>SSN#:</FormLabel>
                <Input
                  name="ssn"
                  type="text"
                  ref={register({ required: 'SSN information required' })}
                />
                <FormErrorMessage>
                  {errors.ssn && errors.ssn.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                maxW={['100%', '100%', '30%', '30%']}
                isInvalid={errors.month && errors.day && errors.year}
              >
                <FormLabel>DOB (mm/dd/yyyy):</FormLabel>
                <HStack>
                  <Input
                    name="month"
                    type="text"
                    placeholder="mm"
                    ref={register({ required: 'DOB information required.' })}
                  />
                  <Input
                    name="day"
                    type="text"
                    placeholder="dd"
                    ref={register({ required: 'DOB information required.' })}
                  />
                  <Input
                    name="year"
                    type="text"
                    placeholder="yyyy"
                    ref={register({ required: 'DOB information required.' })}
                  />
                </HStack>
                <FormErrorMessage>
                  {errors.month &&
                    errors.day &&
                    errors.year &&
                    errors.month.message}
                </FormErrorMessage>
              </FormControl>
            </HStack>
            <FormControl maxW="100%" isInvalid={errors.primaryCarePhysician}>
              <FormLabel>Primary Care Physician:</FormLabel>
              <Input
                name="primaryCarePhysician"
                type="text"
                ref={register({ required: 'Primary Care Physician required.' })}
              />
              <FormErrorMessage>
                {errors.primaryCarePhysician &&
                  errors.primaryCarePhysician.message}
              </FormErrorMessage>
            </FormControl>
          </Box>
          <Box
            backgroundColor="blue.100"
            h="30"
            p="1"
            fontWeight="bold"
            mt="10"
            mb="10"
          >
            Emergency Contact
          </Box>
          <FormControl isInvalid={errors.emergencyContactFullName}>
            <FormLabel>Name:</FormLabel>
            <Input
              name="emergencyContactFullName"
              type="text"
              ref={register({
                required: 'Emergency Contact Fullname required.',
              })}
            />
            <FormErrorMessage>
              {errors.emergencyContactFullName &&
                errors.emergencyContactFullName.message}
            </FormErrorMessage>
          </FormControl>
          <HStack>
            <FormControl>
              <FormLabel>Relationship:</FormLabel>
              <Input name="emergencyContactRelationship" type="text" />
            </FormControl>
            <FormControl isInvalid={errors.emergencyContactPhone}>
              <FormLabel>Phone:</FormLabel>
              <Input
                name="emergencyContactPhone"
                type="text"
                ref={register({
                  required: 'Emergency Contact Phone required.',
                })}
              />
              <FormErrorMessage>
                {errors.emergencyContactPhone &&
                  errors.emergencyContactPhone.message}
              </FormErrorMessage>
            </FormControl>
          </HStack>
          <FormControl>
            <FormLabel>Emergency Contact Grant Permission.</FormLabel>
            <Controller
              defaultValue={null}
              render={props => {
                return (
                  <RadioGroup {...props}>
                    <HStack>
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                    </HStack>
                  </RadioGroup>
                )
              }}
              rules={{ required: 'Grant Permission required.' }}
              control={control}
              name="emergencyContactGrantPermission"
            />
            {errors.emergencyContactGrantPermission && (
              <Text color="red">
                {errors.emergencyContactGrantPermission.message}
              </Text>
            )}
          </FormControl>
          <Box
            backgroundColor="blue.100"
            h="30"
            p="1"
            fontWeight="bold"
            mt="10"
            mb="5"
          >
            Race and Ethnicity Survey
          </Box>
          <Box mb="10">
            Please place a check indicating one choice under race and one choice
            under ethnicity. You are under no obligation to answer any of the
            questions below.
          </Box>
          <FormControl isInvalid={errors.race}>
            <FormLabel>Race</FormLabel>
            <Controller
              defaultValue={null}
              render={props => (
                <CheckboxGroup onChange={props.onChange} onBlur={props.onBlur}>
                  <Stack direction="row" spacing="20px">
                    <Stack>
                      <Checkbox value="American Indian or Alaska Native">
                        American Indian or Alaska Native
                      </Checkbox>
                      <Checkbox value="Black or African American">
                        Black or African American
                      </Checkbox>
                      <Checkbox value="White">White</Checkbox>
                      <Checkbox value="Unknown">Unknown</Checkbox>
                    </Stack>
                    <Stack>
                      <Checkbox value="Asian">Asian</Checkbox>
                      <Checkbox value="Native Hawaiian or Pacific Islander">
                        Native Hawaiian or Pacific Islander
                      </Checkbox>
                      <Checkbox value="Decline">Decline</Checkbox>
                      <Checkbox value="Other">Other</Checkbox>
                    </Stack>
                  </Stack>
                </CheckboxGroup>
              )}
              name="race"
              rules={{ required: 'Race required.' }}
              control={control}
            />
            {errors.race && <Text color="red">{errors.race.message}</Text>}
          </FormControl>
          <FormControl mt="5" isInvalid={errors.ethincity}>
            <FormLabel>Ethnicity</FormLabel>
            <Controller
              render={props => (
                <CheckboxGroup onChange={props.onChange} onBlur={props.onBlur}>
                  <HStack spacing="20px">
                    <Stack>
                      <Checkbox value="Hispanic or Latino Ethnicity">
                        Hispanic or Latino Ethnicity
                      </Checkbox>
                      <Checkbox value="Decline">Decline</Checkbox>
                    </Stack>
                    <Stack>
                      <Checkbox value="Non Hicpanic or Latino Ethnicity">
                        Non Hicpanic or Latino Ethnicity
                      </Checkbox>
                      <Checkbox value="Unknown">Unknown</Checkbox>
                    </Stack>
                  </HStack>
                </CheckboxGroup>
              )}
              control={control}
              rules={{ required: 'Ethnicity required.' }}
              defaultValue={null}
              name="ethincity"
            />
            {errors.ethincity && (
              <Text color="red">{errors.ethincity.message}</Text>
            )}
          </FormControl>
          <FormControl mt="5">
            <FormLabel>Language</FormLabel>
            <Controller
              render={props => (
                <CheckboxGroup onChange={props.onChange} onBlur={props.onBlur}>
                  <Stack direction="row" spacing="20px">
                    <Stack>
                      <Checkbox value="English">English</Checkbox>
                      <Checkbox value="Russian">Russian</Checkbox>
                      <Checkbox value="Indian">
                        Indian (Include Hindi &amp; Tamil)
                      </Checkbox>
                    </Stack>
                    <Stack>
                      <Checkbox value="Spanish">Spanish</Checkbox>
                      <Checkbox value="Other">Other</Checkbox>
                    </Stack>
                  </Stack>
                </CheckboxGroup>
              )}
              control={control}
              defaultValue={null}
              name="language"
              rules={{ required: 'Language required.' }}
            />
            {errors.language && (
              <Text color="red">{errors.language.message}</Text>
            )}
          </FormControl>
          <Box
            backgroundColor="blue.100"
            h="30"
            p="1"
            fontWeight="bold"
            mt="10"
            mb="10"
          >
            Advance Directive
          </Box>
          <FormControl>
            <Controller
              render={props => (
                <CheckboxGroup onChange={props.onChange} onBlur={props.onBlur}>
                  <Stack direction="row" spacing="20px">
                    <Stack>
                      <Checkbox value="Advance Directive">
                        Advance Directive
                      </Checkbox>
                      <Checkbox value="None on File">None on File</Checkbox>
                    </Stack>
                    <Stack>
                      <Checkbox value="Do Not Resucitate Order">
                        Do Not Resucitate Order (DNR)
                      </Checkbox>
                    </Stack>
                  </Stack>
                </CheckboxGroup>
              )}
              name="advanceDirective"
              defaultValue={null}
              control={control}
              rules={{ required: 'Advance Directive required.' }}
            />
            {errors.advanceDirective && (
              <Text color="red">{errors.advanceDirective.message}</Text>
            )}
          </FormControl>
          <Box
            backgroundColor="blue.100"
            h="30"
            p="1"
            fontWeight="bold"
            mt="10"
            mb="10"
          >
            Grant Permission to leave Message
          </Box>
          <FormControl>
            <Controller
              render={props => (
                <CheckboxGroup onChange={props.onChange} onBlur={props.onBlur}>
                  <Stack direction="row" spacing="20px">
                    <Stack>
                      <Checkbox value="Leave message on home phone">
                        Leave message on home phone
                      </Checkbox>
                      <Checkbox value="Email message to my email address">
                        Email message to my email address
                      </Checkbox>
                    </Stack>
                    <Stack>
                      <Checkbox value="Do Not Resucitate Order">
                        Do Not Resucitate Order *
                      </Checkbox>
                    </Stack>
                  </Stack>
                </CheckboxGroup>
              )}
              defaultValue={null}
              control={control}
              rules={{ required: 'Grant Permission required.' }}
              name="grantPermissionToLeaveMessage"
            />
            {errors.grantPermissionToLeaveMessage && (
              <Text color="red">
                {errors.grantPermissionToLeaveMessage.message}
              </Text>
            )}
          </FormControl>
          <Box mt="10">
            * Charges may be added by your carrier per your plan policy.
          </Box>
          <Center>
            <Button
              mt="10"
              type="submit"
              colorScheme="blue"
              isLoading={formState.isSubmitting}
            >
              Next Step
            </Button>
          </Center>
        </form>
      </Container>
    </Container>
  )
}

export default WebEnableForm
