import { ChevronRightIcon } from '@chakra-ui/icons'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Spinner,
} from '@chakra-ui/react'
import { doc, getDoc } from 'firebase/firestore'
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { db } from '../../config/firebase'
import PDFCollectorsCompetencyQuiz from './PDFForm/PDFCollectorsCompetencyQuiz'
import PDFEmployeeQuiz from './PDFForm/PDFEmployeeQuiz'
import PDFRadiationSafetyTest from './PDFForm/PDFRadiation-Safety-Test'
import PDFTBHighRisk from './PDFForm/PDFTB-High-Risk'
import PDFDeclinationOfInfluenzaVaccination from './PDFForm/PDFDeclinationOfInfluenzaVaccination'

function EmployeePDFForm() {
  const [employeeData, setEmployeeData] = useState(null)
  const [employeeLoading, setEmployeeLoading] = useState(true)
  const { id, formType } = useParams()

  const fetchEmployeeData = useCallback(async () => {
    const employeeRef = doc(db, 'EmployeeForm', id)
    const getEmployeeData = (await getDoc(employeeRef)).data()
    setEmployeeData(getEmployeeData)
    setEmployeeLoading(false)
  }, [id])

  useEffect(() => {
    fetchEmployeeData()
  }, [id, fetchEmployeeData])

  if (employeeLoading) {
    return (
      <Flex
        minW={'100%'}
        minH={'100vh'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <p>loading...</p>
        <Spinner ml={2} size={'md'} />
      </Flex>
    )
  }

  return (
    <>
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
        bg="gray.100"
        p="1.5"
        borderRadius="0.3em"
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/dashboard">
            Dashbaord
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/infections-and-emergency-visit">
            Employee Form
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to={`/employee-form/${id}`}>
            {employeeData?.name || '-'} ({employeeData?.type || ''})
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="#">
            PDF
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      {formType === 'radiation-safety-test' ? (
        <PDFRadiationSafetyTest employeeData={employeeData} />
      ) : null}
      {formType === 'employee-quiz' ? (
        <PDFEmployeeQuiz employeeData={employeeData} />
      ) : null}
      {formType === 'collectors-competency-quiz' ? (
        <PDFCollectorsCompetencyQuiz employeeData={employeeData} />
      ) : null}
      {formType === 'tb-high-risk' ? (
        <PDFTBHighRisk employeeData={employeeData} />
      ) : null}
      {formType === 'declination-of-influenza-vaccination' ? (
        <PDFDeclinationOfInfluenzaVaccination employeeData={employeeData} />
      ) : null}
    </>
  )
}

export default EmployeePDFForm
