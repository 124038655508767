import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  Image,
  Spacer,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import React, { useRef } from 'react'
import { BsBoxArrowRight, BsGrid3X3GapFill } from 'react-icons/bs'
import { Link, useHistory } from 'react-router-dom'
import { signOut } from 'firebase/auth'
import Logo from '../Logo.png'
import { auth } from '../config/firebase'
import AccountSetting from './AccountSetting'

function AppMenu({ user }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef()
  const history = useHistory()

  return (
    <>
      <Flex p="5">
        <Box p="2">
          <Image
            cursor="pointer"
            onClick={() => {
              history.push('/dashboard')
            }}
            src={Logo}
            alt="paincare logo"
            htmlWidth="100px"
          />
        </Box>
        <Spacer />
        <Box>
          <IconButton
            colorScheme="blue"
            onClick={onOpen}
            aria-label="Menu"
            icon={<BsGrid3X3GapFill />}
          />
        </Box>
      </Flex>
      <Drawer
        isOpen={isOpen}
        size="xs"
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay>
          <DrawerContent>
            <DrawerHeader bg="blue.900">
              <Flex>
                <Box>
                  <Avatar src={user.avatarUrl} />
                </Box>
                <Spacer />
                <Box textAlign="right" color="gray.300">
                  {user.fullName ? user.fullName : 'Account'}
                  <Text color="gray.500" fontSize="xs">
                    {user.email}
                  </Text>
                  <Text color="gray.500" fontSize="xs">
                    {user.role}
                  </Text>
                </Box>
              </Flex>
            </DrawerHeader>
            <DrawerBody p="2">
              <Box mt="5" borderRadius="0.2em" p="1">
                <Button as={Link} to="/dashboard" width="100%">
                  Dashboard
                </Button>
              </Box>
              <Box mt="5" borderRadius="0.2em" p="1">
                <Accordion allowToggle>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box flex="1" textAlign="left">
                          Company Policies
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Button
                        size="sm"
                        mt="3"
                        width="100%"
                        onClick={() => {
                          window.open(
                            `https://firebasestorage.googleapis.com/v0/b/paincareoregon-prod.appspot.com/o/Documents%2FPCSO-Covid-19-Vaccine-Policy-updated.pdf?alt=media&token=ccb8a737-0d78-4157-9870-6e61dd4fafd1`,
                            '_blank'
                          )
                        }}
                      >
                        PCSO Covid 19 Vaccine Policy
                      </Button>
                      <Button
                        size="sm"
                        mt="3"
                        width="100%"
                        onClick={() => {
                          window.open(
                            `https://firebasestorage.googleapis.com/v0/b/paincareoregon-prod.appspot.com/o/Documents%2FCovid-Vaccine-Policy-FAQs-updated.pdf?alt=media&token=42260bf7-e196-41b6-a292-67f84e66e0d7`,
                            '_blank'
                          )
                        }}
                      >
                        Covid Vaccine Policy FAQs
                      </Button>
                      <Button
                        size="sm"
                        mt="3"
                        width="100%"
                        onClick={() => {
                          window.open(
                            `https://firebasestorage.googleapis.com/v0/b/paincareoregon-prod.appspot.com/o/Documents%2FHCP-with-SARS-CoV-2.jpg?alt=media&token=134eaa2a-5593-4337-8406-0bbafea4f2db`,
                            '_blank'
                          )
                        }}
                      >
                        HCP With SARS CoV2
                      </Button>
                      <Button
                        size="sm"
                        mt="3"
                        width="100%"
                        onClick={() => {
                          window.open(
                            `/doc/dsha-orientation-packet.pdf`,
                            '_blank'
                          )
                        }}
                      >
                        DSHA Orientation Packet
                      </Button>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box flex="1" textAlign="left">
                          Staff Form
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Button
                        size="sm"
                        mt="3"
                        width="100%"
                        onClick={() => {
                          window.open(
                            `/doc/HOW_TO_SETUP_THE_NEW_ECW_VERSION_12_AT_OSSC_AND_PCSO.pdf`,
                            '_blank'
                          )
                        }}
                      >
                        How to setup the new ECW Version 12
                      </Button>
                      <Button
                        size="sm"
                        mt="3"
                        width="100%"
                        as={Link}
                        to="/time-away-request-form"
                      >
                        Time Away Request
                      </Button>
                      <Button
                        size="sm"
                        mt="3"
                        width="100%"
                        as={Link}
                        to="/time-clock-adjustment-form"
                      >
                        Time Clock Adjustment
                      </Button>
                      <Button
                        size="sm"
                        mt="3"
                        width="100%"
                        as={Link}
                        to="/absentee-report-form"
                      >
                        Absentee Report
                      </Button>
                      <Button
                        size="sm"
                        mt="3"
                        width="100%"
                        as={Link}
                        to="/cme-request-form"
                      >
                        CME Request
                      </Button>
                      <Button
                        size="sm"
                        mt="3"
                        width="100%"
                        as={Link}
                        to="/paid-timeoff-request-form"
                      >
                        PTO Request Form
                      </Button>
                      <Button
                        size="sm"
                        mt="3"
                        width="100%"
                        as={Link}
                        to="/request-for-ofla-leave-form"
                      >
                        Request for OFLA Leave
                      </Button>
                      <Button
                        size="sm"
                        mt="3"
                        width="100%"
                        as={Link}
                        to="/covid-exprosure-screening"
                      >
                        COVID-19 Questionnairs
                      </Button>
                      <Button
                        size="sm"
                        mt="3"
                        width="100%"
                        onClick={() => {
                          window.open(
                            `https://firebasestorage.googleapis.com/v0/b/paincare-a9e20.appspot.com/o/Downloads%2FForms%2F2020-12-OHSA-Infection-Control-Plan.pdf?alt=media&token=a3bc7dd5-e4cf-47c1-88a9-96a670f54836`,
                            '_blank'
                          )
                        }}
                      >
                        OHSA Infection Control Plan
                      </Button>
                      {/* <Button
                        size="sm"
                        mt="3"
                        width="100%"
                        onClick={() => {
                          window.open(
                            `https://firebasestorage.googleapis.com/v0/b/paincareoregon-prod.appspot.com/o/Documents%2FOHA-Religious-Exemption-Form-PDF.pdf?alt=media&token=34ad2267-6968-41f8-8a64-178beed6eb54`,
                            '_blank'
                          )
                        }}
                      >
                        OHA Religious Exemption Form
                      </Button>
                      {/* <Button
                        size="sm"
                        mt="3"
                        width="100%"
                        as={Link}
                        to="/radiation-safety-test"
                      >
                        Radiation Safety Test
                      </Button>
                      <Button
                        size="sm"
                        mt="3"
                        width="100%"
                        as={Link}
                        to="/employee-quiz"
                      >
                        OSHA Quiz
                      </Button>
                      <Button
                        size="sm"
                        mt="3"
                        width="100%"
                        as={Link}
                        to="/collectors-competency-quiz"
                      >
                        Collectors Competency Quiz
                      </Button>
                      <Button
                        size="sm"
                        mt="3"
                        width="100%"
                        as={Link}
                        to="/annual-clinical-competencies"
                      >
                        Annual Clinical Competencies
                      </Button> */}
                    </AccordionPanel>
                  </AccordionItem>
                  {/* <AccordionItem>
                                        <h2>
                                            <AccordionButton>
                                                <Box flex="1" textAlign="left">
                                                    Profile
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4}>
                                            <Profile user={user} />
                                        </AccordionPanel>
                                    </AccordionItem> */}
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box flex="1" textAlign="left">
                          Account settings
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <AccountSetting user={user} />
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Box>
            </DrawerBody>
            <DrawerFooter>
              <Spacer />
              <Button
                onClick={() => {
                  signOut(auth).then(history.push('/login'))
                }}
                size="sm"
                variant="outline"
                rightIcon={<BsBoxArrowRight />}
              >
                Logout
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  )
}

export default AppMenu
