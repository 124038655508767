import { ChevronRightIcon } from '@chakra-ui/icons'
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Container,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Spacer,
  Button,
  FormErrorMessage,
  RadioGroup,
  Radio,
  Stack,
  Center,
  useToast,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FaCloudDownloadAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { db } from '../../config/firebase'
import { addDoc, collection } from 'firebase/firestore'
import moment from 'moment'

function RadiationSafetyTest({ user }) {
  //State hook
  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    reset,
  } = useForm()
  const toast = useToast()
  const [isDisabled, setIsDisabled] = useState(false)

  //function
  function submitData(value) {
    setIsDisabled(true)
    // value.date = value.date.format('YYYY-MM-DD')
    addDoc(collection(db, 'EmployeeForm'), {
      ...value,
      name: user.fullName,
      type: 'Radiation Safety Test',
      createdAt: new Date(),
      notification: true,
      status: 'Pending',
    }).then(() => {
      toast({
        title: 'Submit Successfully',
        description: 'Form has been submmited.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      setIsDisabled(false)
      reset()
    })
  }
  return (
    <Container maxW="100%" mt={5}>
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
        bg="gray.100"
        p="1.5"
        borderRadius="0.3em"
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/dashboard">
            Dashbaord
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/medical-practice-management">
            Medical Practice Management
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Box mt="30" mb="50" width={['100']}>
        <Flex mt="10" mb="10">
          <Box pt="2" fontSize="18" fontWeight="bold">
            Radiation Safety Test
          </Box>
          <Spacer />
          <Button
            leftIcon={<FaCloudDownloadAlt />}
            onClick={() => {
              window.open('/doc/radiation-safety-test.pdf')
            }}
            size="sm"
          >
            PDF
          </Button>
        </Flex>
      </Box>
      <form onSubmit={handleSubmit(submitData)}>
        <HStack mb="5">
          <FormControl id="name" isInvalid={errors.name}>
            <FormLabel>Name</FormLabel>
            <Input
              name="name"
              defaultValue={user.fullName}
              ref={register({ required: 'Please type name.' })}
              isReadOnly={true}
            />
            <FormErrorMessage>
              {errors.name && errors.name?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl id="date" isInvalid={errors.date}>
            <FormLabel>Date</FormLabel>
            <Controller
              render={props => (
                <Input
                  value={props.value}
                  onChange={props.onChange}
                  isReadOnly
                  textAlign={'center'}
                  type='date'
                />
              )}
              name="date"
              control={control}
              defaultValue={moment().format('YYYY-MM-DD')}
              rules={{ required: 'Date required.' }}
            />
            <FormErrorMessage>
              {errors.date && errors.date.message}
            </FormErrorMessage>
          </FormControl>
        </HStack>
        <FormControl mb="5" isInvalid={errors.occupation}>
          <FormLabel>Occupation</FormLabel>
          <Input
            name="occupation"
            defaultValue=""
            width="300px"
            ref={register({ required: true })}
          />
        </FormControl>

        <FormControl mb="5" isInvalid={errors.quizOne}>
          <FormLabel>
            {`1) What causes an occupational workers primary dose in the room?`}
          </FormLabel>
          <Controller
            name="quizOne"
            control={control}
            defaultValue={null}
            rules={{ required: true }}
            render={props => (
              <RadioGroup
                name={props.name}
                onChange={props.onChange}
                onBlur={props.onBlur}
              >
                <Stack>
                  <Radio value="answerA">
                    {`a) The left over radiation in the room`}
                  </Radio>
                  <HStack>
                    <Radio value="answerB">
                      {`b) Radiation Leaking from the tube`}
                    </Radio>
                  </HStack>
                  <Radio value="answerC">
                    {`c) The scatter radiation produced from the patient`}
                  </Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>
        <FormControl mb="5" isInvalid={errors.quizTwo}>
          <FormLabel>
            {`2) When is the possibility of radiation exposure the highest in the
            room?`}
          </FormLabel>
          <Controller
            name="quizTwo"
            control={control}
            defaultValue={''}
            rules={{ required: true }}
            render={props => (
              <RadioGroup
                name={props.name}
                onChange={props.onChange}
                onBlur={props.onBlur}
              >
                <Stack>
                  <Radio value="answerA">{`a) When the machine is on`}</Radio>
                  <HStack>
                    <Radio value="answerB">
                      {`b) During cross-table procedures`}
                    </Radio>
                  </HStack>
                  <Radio value="answerC">
                    {`c) When performing right sided procedures`}
                  </Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>
        <FormControl mb="5" isInvalid={errors.quizThree}>
          <FormLabel>
            {`3) How can a worker limit their exposure to radiation while in the
            room?`}
          </FormLabel>

          <Controller
            name="quizThree"
            control={control}
            defaultValue={''}
            rules={{ required: true }}
            render={props => (
              <RadioGroup
                name={props.name}
                onChange={props.onChange}
                onBlur={props.onBlur}
              >
                <Stack>
                  <Radio value="answerA">
                    {`a) Minimize the time spent around the radiation source`}
                  </Radio>
                  <HStack>
                    <Radio value="answerB">
                      {`b) Maximize the distance from the source of radiation`}
                    </Radio>
                  </HStack>
                  <Radio value="answerC">
                    {`c) Wear proper protective equipment such a lead aprons while
                    around radiation`}
                  </Radio>
                  <Radio value="allOfAbove">All the above</Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>
        <FormControl mb="5" isInvalid={errors.quizFour}>
          <FormLabel>
            {`4) Lead aprons will protect the employee from all radiation
            exposures.`}
          </FormLabel>
          <Controller
            name="quizFour"
            control={control}
            defaultValue={''}
            rules={{ required: true }}
            render={props => (
              <RadioGroup
                name={props.name}
                onChange={props.onChange}
                onBlur={props.onBlur}
              >
                <Stack>
                  <Radio value="true">True</Radio>
                  <Radio value="false">False</Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>
        <FormControl mb="5" isInvalid={errors.quizFive}>
          <FormLabel>{`5) Never fold lead aprons for storage.`}</FormLabel>
          <Controller
            name="quizFive"
            control={control}
            defaultValue={''}
            rules={{ required: true }}
            render={props => (
              <RadioGroup
                name={props.name}
                onChange={props.onChange}
                onBlur={props.onBlur}
              >
                <Stack>
                  <Radio value="true">True</Radio>
                  <Radio value="false">False</Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>
        <FormControl mb="5" isInvalid={errors.quizSix}>
          <FormLabel>
            {`6) Where should the radiation badge be placed while not in the room?`}
          </FormLabel>
          <Controller
            name="quizSix"
            control={control}
            defaultValue={''}
            rules={{ required: true }}
            render={props => (
              <RadioGroup
                name={props.name}
                onChange={props.onChange}
                onBlur={props.onBlur}
              >
                <Stack>
                  <Radio value="answerA">
                    {`a) Keep it on your lead for easy use`}
                  </Radio>
                  <HStack>
                    <Radio value="answerB">
                      {`b) Place it on the counter in the break room for storage`}
                    </Radio>
                  </HStack>
                  <Radio value="answerC">
                    {`c) Take it home with you at the end of the day`}
                  </Radio>
                  <Radio value="answerD">
                    {`d) Store it with the control badge outside the room`}
                  </Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>
        <FormControl mb="5" isInvalid={errors.quizSeven}>
          <FormLabel>
            {`7) Always wear the radiation badge as directed every time for
            accurate readings.`}
          </FormLabel>
          <Controller
            name="quizSeven"
            control={control}
            defaultValue={''}
            rules={{ required: true }}
            render={props => (
              <RadioGroup
                name={props.name}
                onChange={props.onChange}
                onBlur={props.onBlur}
              >
                <Stack>
                  <Radio value="true">True</Radio>
                  <Radio value="false">False</Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>
        <FormControl mb="5" isInvalid={errors.quizEigth}>
          <FormLabel>
            {`8) As an employee in a radiation environment, what is my annual
            exposure limit?`}
          </FormLabel>
          <Controller
            name="quizEigth"
            control={control}
            defaultValue={''}
            rules={{ required: true }}
            render={props => (
              <RadioGroup
                name={props.name}
                onChange={props.onChange}
                onBlur={props.onBlur}
              >
                <Stack>
                  <Radio value="answerA">{`a) 5000 mrem per year`}</Radio>
                  <HStack>
                    <Radio value="answerB">{`b) 500 mrem per year`}</Radio>
                  </HStack>
                  <Radio value="answerC">{`c) 5 mrem per year`}</Radio>
                  <Radio value="a&c">Both A&C</Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>
        <FormControl mb="5" isInvalid={errors.quizNine}>
          <FormLabel>
            {`9) What should every female patient between 10 and 60 be asked
            before a procedure?`}
          </FormLabel>
          <Controller
            name="quizNine"
            control={control}
            defaultValue={''}
            rules={{ required: true }}
            render={props => (
              <RadioGroup
                name={props.name}
                onChange={props.onChange}
                onBlur={props.onBlur}
              >
                <Stack>
                  <Radio value="answerA">{`a) Any chance of pregnancy?`}</Radio>
                  <HStack>
                    <Radio value="answerB">{`b) Any chance of pregnancy?`}</Radio>
                  </HStack>
                  <Radio value="answerC">{`c) Any chance of pregnancy?`}</Radio>
                  <Radio value="allOfAbove">All the above</Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>
        <Center mt="5">
          <Button
            colorScheme="blue"
            width={['100%', '100%', '100%', '10%']}
            type="submit"
            isDisabled={isDisabled}
            isLoading={isDisabled}
            mb={9}
          >
            Submit
          </Button>
        </Center>
      </form>
    </Container>
  )
}

export default RadiationSafetyTest
