import { Image, Text, View } from '@react-pdf/renderer'
import moment from 'moment'
import React from 'react'

function RecruitPatients({ fillableData, styles }) {
  return (
    <View style={{ ...styles.gapBox }}>
      <View
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          fontSize: 8,
        }}
      >
        <Image
          style={{ width: '110px', objectFit: 'cover' }}
          src="/paincare-web-v2.png"
        />
        <View style={styles.boxtitle}>
          <Text>2480 Liberty Street NE, Suite 180, Salem, OR 97301</Text>
        </View>
        <View style={styles.boxtitle}>
          <Text>Office: (503) 371-1010 Fax: (503) 371-0805</Text>
        </View>
      </View>
      <View
        style={{
          width: '450px',
          paddingTop: '6px',
          gap: '3px',
        }}
      >
        <View style={{ width: '100%' }}>
          <View style={styles.boxRowData}>
            <View
              style={{
                ...styles.boxRowData,
                width: '100%',
                gap: '0px',
              }}
            >
              <Text style={{ width: '42px' }}>Name :</Text>
              <Text style={{ ...styles.paddingLeftFormData }}>
                {fillableData.namePatient ? fillableData.namePatient : ''}
              </Text>
            </View>
            <View
              style={{
                ...styles.boxRowData,
                width: '100%',
                gap: '0px',
              }}
            >
              <Text style={{ width: '35px' }}>DOB :</Text>
              <Text style={{ ...styles.paddingLeftFormData }}>
                {fillableData?.dobPatient
                  ? moment(fillableData?.dobPatient).format('MM/DD/YYYY')
                  : ''}
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            backgroundColor: '#b0acac',
            width: '100%',
            height: '4px',
          }}
        ></View>
        <View style={{ display: 'flex', flexDirection: 'column' }}>
          <Text>
            <Text style={{ fontWeight: 'bold' }}>Medications tried:</Text>
            {` `}
            <Text>(check all that apply)</Text>
          </Text>
          <View
            style={{
              paddingTop: 8,
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              justifyContent: 'start',
              gap: '6px',
            }}
          >
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tIbuprofen') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Ibuprofen</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tTylenol') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Tylenol</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tNortriptyline') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Nortriptyline</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tAmitriptyline') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Amitriptyline</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tGabapentin ') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Gabapentin</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tLyrica') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Lyrica</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tFlexeril') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Flexeril</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tRobaxin') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Robaxin</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tZanaflex ') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Zanaflex</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tSkelaxin') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Skelaxin</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tHydrocodone') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Hydrocodone</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tOxycodone') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Oxycodone</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tOxycontin') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Oxycontin</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tMorphine') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Morphine</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tMS') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>MS Contin</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tMethadone') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Methadone</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tDilaudid') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Dilaudid</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tSuboxone') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Suboxone</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tNucynta') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Nucynta</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tFentanyl') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Fentanyl</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tOpana') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Opana</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '100%',
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 4,
                }}
              >
                <Text>Other</Text>
                {` `}
                <Text
                  style={{
                    borderBottom: '1px dotted #000',
                    width: '100%',
                  }}
                >
                  {fillableData.otherMedications
                    ? fillableData.otherMedications
                    : ''}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            backgroundColor: '#000',
            width: '100%',
            height: '1px',
          }}
        ></View>
        <View
          style={{ paddingTop: 4, display: 'flex', flexDirection: 'column' }}
        >
          <Text style={{ fontWeight: 'bold' }}>Current Medications:</Text>
        </View>
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            paddingTop: 2,
            paddingBottom: 1,
          }}
        >
          {fillableData?.medications?.map((data, index) => {
            return (
              <View style={styles.boxRowData} key={index}>
                <View
                  style={{
                    ...styles.boxRowData,
                    width: '100%',
                    gap: '0px',
                  }}
                >
                  <Text style={{ width: '42px' }}>Name:</Text>
                  <Text
                    style={{ ...styles.paddingLeftFormData, paddingLeft: 0 }}
                  >
                    {data.name ? data.name : ''}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.boxRowData,
                    width: '100%',
                    gap: '0px',
                  }}
                >
                  <Text style={{ width: '35px' }}>Dose:</Text>
                  <Text
                    style={{ ...styles.paddingLeftFormData, paddingLeft: 0 }}
                  >
                    {data.dose ? data.dose : ''}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.boxRowData,
                    width: '100%',
                    gap: '0px',
                  }}
                >
                  <Text style={{ width: '80px' }}>Frequency:</Text>
                  <Text
                    style={{
                      ...styles.paddingLeftFormData,
                      paddingLeft: 0,
                    }}
                  >
                    {data.frequency ? data.frequency : ''}
                  </Text>
                </View>
              </View>
            )
          })}
        </View>
        <View
          style={{
            backgroundColor: '#000',
            width: '100%',
            height: '1px',
          }}
        ></View>
        <View>
          <Text style={{ fontWeight: 'bold' }}>Current Medical Problems :</Text>
          <Text style={{ borderBottom: '1px dotted #000', paddingTop: 4 }}>
            {fillableData.currentMedicalProblems
              ? fillableData.currentMedicalProblems
              : ''}
          </Text>
        </View>
        <View
          style={{
            backgroundColor: '#000',
            width: '100%',
            height: '1px',
          }}
        ></View>
      </View>
    </View>
  )
}

export default RecruitPatients
