import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import {
    Container,
    Button,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Box,
    HStack,
    Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    DrawerHeader,
    DrawerFooter,
    DrawerCloseButton,
    Stack,
    FormLabel,
    Input,
    Select,
    CheckboxGroup,
    Checkbox,
    useDisclosure,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    List,
    ListItem,
    ListIcon,
    Tooltip,
    Center,
    IconButton
} from '@chakra-ui/react'
import _ from 'underscore'
import { ChevronRightIcon, EditIcon } from '@chakra-ui/icons'
import { GrCertificate } from 'react-icons/gr'
import { collection, doc, addDoc, updateDoc, query, onSnapshot, orderBy } from 'firebase/firestore'
import { db } from '../../config/firebase'

function CredentialSetting() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const firstField = useRef()
    const [newGroup, setNewGroup] = useState(false)
    const [groupName, setGroupName] = useState(null)
    const [typeName, setTypeName] = useState(null)
    const [mandatory, setMandatory] = useState([])
    const [groupList, setGroupList] = useState([])
    const [credentialTypes, setCredentialTypes] = useState([])
    const [editType, setEditType] = useState(null)

    useEffect(() => {
        const qCredentials = query(collection(db, "CredentialTypes"), orderBy("typeName", "asc"))
        let unsubscribe = onSnapshot(qCredentials, (snapShot) => {
            let data = []
            snapShot.forEach((doc) => {
                data.push({ ...doc.data(), id: doc.id })
            })
            let group = _.uniq(data, "groupName").map((data) => data.groupName)
            let credentialGroup = _.groupBy(data, (item) => item.groupName)
            setGroupList(group)
            setCredentialTypes(credentialGroup)
        })
        return () => {
            unsubscribe()
        }
    }, [])

    function createType() {
        if (typeName && groupName) {
            addDoc(collection(db, "CredentialTypes"), { typeName, groupName, mandatory: _.map(mandatory, (data) => JSON.parse(data)) }).then((res) => {
                if (res.id) {
                    setGroupName(null)
                    setTypeName(null)
                    setMandatory(null)
                    setNewGroup(null)
                    onClose()
                }
            }).catch((e) => {
                console.error(e)
            })

        }
    }

    function updateType() {
        updateDoc(doc(db, "CredentialTypes", editType.id), { typeName, groupName, mandatory: _.map(mandatory, (data) => JSON.parse(data)) }).then(() => {
            setGroupName(null)
            setTypeName(null)
            setMandatory(null)
            setNewGroup(null)
            onClose()
        }).catch((e) => {
            console.error(e)
        })
    }

    return (
        <>
            <Container maxW="100%" mt="5">
                <Breadcrumb
                    spacing="8px"
                    separator={<ChevronRightIcon color="gray.500" />}
                    bg="gray.100" p="1.5"
                    borderRadius="0.3em"
                >
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to="/dashboard">Dashbaord</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to="/credentialing">Credentialing</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to="/credentialing/settings">Settings</BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
                <Box mt="5" mb="5" display="flex" justifyContent="space-between">
                    <Box pt="2" fontSize="18" fontWeight="bold">
                        Credential Settings
                    </Box>
                    <HStack spacing="10px">
                        <Button size="sm" leftIcon={<GrCertificate />} onClick={() => {
                            onOpen()
                            setEditType(null)
                        }}>Create Types</Button>
                    </HStack>
                </Box>
                <Box mh="300px" mb="5" borderRadius="lg" borderWidth='1px' p="15px">
                    {
                        !_.isEmpty(credentialTypes) ?
                            <Accordion defaultIndex={[0]} allowMultiple>
                                {
                                    _.map(credentialTypes, (credential, index) => (
                                        <AccordionItem key={index}>
                                            <h2>
                                                <AccordionButton>
                                                    <Box flex='1' textAlign='left'>
                                                        {index}
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                                <List spacing={3}>
                                                    {
                                                        _.map(credential, (value, index) => (
                                                            <ListItem key={index}>
                                                                <ListIcon as={GrCertificate} />
                                                                <Tooltip hasArrow label={`${_.map(value.mandatory, (mandatory) => {
                                                                    return (`${mandatory.title}`)
                                                                })}`}>
                                                                    {value.typeName}
                                                                </Tooltip>
                                                                <IconButton disabled size="xs" icon={<EditIcon />} cursor="pointer" marginLeft="5px" onClick={() => {
                                                                    let jsonMandatory = value.mandatory.map(({ type, name, title }) => JSON.stringify({ type, name, title }))
                                                                    setEditType({ ...value })
                                                                    setTypeName(value.typeName)
                                                                    setGroupName(value.groupName)
                                                                    setMandatory(jsonMandatory)
                                                                    onOpen()
                                                                }} />
                                                            </ListItem>
                                                        ))
                                                    }
                                                </List>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    ))
                                }

                            </Accordion>
                            :
                            <Center>No Credential Types</Center>
                    }

                </Box>
            </Container>
            <Drawer
                isOpen={isOpen}
                placement='right'
                initialFocusRef={firstField}
                onClose={onClose}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader borderBottomWidth='1px'>
                        {editType ? 'Update' : 'Create'} Type
                    </DrawerHeader>

                    <DrawerBody>
                        <Stack spacing='20px'>
                            <Box>
                                <FormLabel htmlFor='typeName'>Name</FormLabel>
                                <Input
                                    ref={firstField}
                                    id='typeName'
                                    defaultValue={editType?.typeName}
                                    onChange={(e) => {
                                        let typeName = e.currentTarget.value
                                        setTypeName(typeName)
                                    }}
                                    placeholder='Credential type name'
                                />
                            </Box>
                            <Box>
                                <FormLabel htmlFor='owner'>Group</FormLabel>
                                <Select defaultValue={editType?.groupName} id='group' placeholder='Select Group' onChange={(e) => {
                                    let groupName = e.currentTarget.value
                                    if (groupName === "newGroup") {
                                        setNewGroup(true)
                                    } else {
                                        setNewGroup(false)
                                        setGroupName(groupName)
                                    }
                                }}>
                                    {
                                        groupList.map((list, index) => (
                                            <option value={list} key={index}>{list}</option>
                                        ))
                                    }
                                    <option value='newGroup'>New Group</option>
                                </Select>
                            </Box>
                            {
                                newGroup &&
                                <Box>
                                    <FormLabel htmlFor='groupName'>New Group Name</FormLabel>
                                    <Input
                                        id='groupName'
                                        onChange={(e) => {
                                            let groupName = e.currentTarget.value
                                            setGroupName(groupName)
                                        }}
                                        placeholder='Enter Group name'
                                    />
                                </Box>
                            }
                            <Box>
                                <FormLabel htmlFor='mandatory'>Mandatory Field</FormLabel>
                                <CheckboxGroup defaultValue={mandatory} onChange={(value) => setMandatory(value)}>
                                    <Stack>
                                        <Checkbox value={JSON.stringify({ type: 'file', name: 'fileUpload', title: 'File Upload' })}>File Upload</Checkbox>
                                        <Checkbox value={JSON.stringify({ type: 'date', name: 'expiryDate', title: 'Expiry Date' })}>Expiry Date</Checkbox>
                                        <Checkbox value={JSON.stringify({ type: 'date', name: 'issueDate', title: 'Issue Date' })}>Issue Date</Checkbox>
                                        <Checkbox value={JSON.stringify({ type: 'date', name: 'nextCheckDate', title: 'Next Check Date' })}>Next Check Date</Checkbox>
                                        <Checkbox value={JSON.stringify({ type: 'text', name: 'hospitalName', title: 'Hospital Name' })}>Hospital Name</Checkbox>
                                        <Checkbox value={JSON.stringify({ type: 'textarea', name: 'remark', title: 'Remark' })}>Remark</Checkbox>
                                    </Stack>
                                </CheckboxGroup>
                            </Box>
                        </Stack>
                    </DrawerBody>

                    <DrawerFooter borderTopWidth='1px'>
                        <Button variant='outline' mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button colorScheme='blue' onClick={editType ? updateType : createType} disabled={_.isEmpty(typeName || groupName) ? true : false}>{editType ? 'Update' : 'Create'}</Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default CredentialSetting