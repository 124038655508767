import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Box,
  Image,
  Center,
  Container,
  Icon,
  Text,
  Button,
  useToast,
} from '@chakra-ui/react'
import {
  doc,
  onSnapshot,
  collection,
  updateDoc,
  query,
  where,
} from 'firebase/firestore'
import { db } from '../../config/firebase'
import { BsCheckCircle } from 'react-icons/bs'
import Logo from '../../full_logo.webp'
import { Divider } from 'antd'
function VerifyEvent({ user }) {
  console.log(user)
  const toast = useToast()
  const params = useParams()
  const [ticket, setTicket] = useState(null)

  useEffect(() => {
    if (!params.id) return
    const regisRef = collection(db, 'RegisterList')
    const regisQuery = query(regisRef, where('ref', '==', params.id))
    onSnapshot(regisQuery, async snap => {
      const tickets = snap.docs.map(doc => ({ ...doc.data(), id: doc.id }))
      setTicket(tickets[0])
    })
  }, [params.id])

  const checkIn = id => {
    const updateRef = doc(db, 'RegisterList', id)
    updateDoc(updateRef, { status: 'Check In' }).then(() => {
      toast({
        description: 'Check In updated.',
        isClosable: true,
        position: 'top',
      })
    })
  }

  return (
    <Container>
      <Box pt="1rem" h="100vh">
        <Center h="full">
          <Box textAlign="center">
            <Center>
              <Image src={Logo} mb="2rem" />
            </Center>
            {ticket ? (
              <>
                <Box textAlign="center">
                  <Icon as={BsCheckCircle} fontSize="8xl" color="green.500" />
                </Box>
                <Box textAlign="center" mt="2rem">
                  <Text fontSize="3xl" fontWeight="bold" color="green.500">
                    Ticket Valid!
                  </Text>
                </Box>
                <Box>
                  Reference No.
                  <Text fontWeight="bold">{ticket.ref}</Text>
                </Box>
                <Box textAlign="center" mt="2rem">
                  <Text fontSize="xl" fontWeight="bold">
                    {ticket.firstName} {ticket.middleName} {ticket.lastName}
                    ,&nbsp;
                    {ticket.jobTitle}
                  </Text>
                </Box>
                {ticket.organization && (
                  <Box textAlign="center" mt="1rem">
                    <Text fontSize="lg">{ticket.organization}</Text>
                  </Box>
                )}
                <Box fontSize="xs" mt='1rem'>
                  <Text fontWeight='bold'>Agenda</Text>
                  <Text>Saturday, September 30th, 2023, from 5 PM - 9 PM</Text>
                  <Text>
                    Andina Restaurant - Tupai Conference Room – 1314 NW Glisan
                    St, Portland OR
                  </Text>
                </Box>
                <Divider />
                <Box mt="1rem">
                  <Text mb="1rem" fontWeight="bold">
                    For Staff
                  </Text>
                  <Button
                    variant="outline"
                    colorScheme="blue"
                    onClick={() => checkIn(ticket.id)}
                    size="sm"
                  >
                    Check In
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <Box textAlign="center" fontSize="xl">
                  Checking...
                </Box>
              </>
            )}
          </Box>
        </Center>
      </Box>
    </Container>
  )
}

export default VerifyEvent
