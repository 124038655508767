import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
    Container,
    Fade,
    Box,
    Image,
    Center,
    Input,
    Button,
    FormErrorMessage,
    FormLabel,
    FormControl,
    Select,
    Stack,
    HStack,
    Icon,
    Textarea
} from '@chakra-ui/react'
import Logo from '../../Logo.png'
import { BsCheckCircle } from 'react-icons/bs'
import { addDoc, collection } from 'firebase/firestore'
import { db } from '../../config/firebase'

function RequestAppointment() {

    const [patientType, setPatientType] = useState("New Patient")
    const [isSuccess, setIsSuccess] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)
    const { handleSubmit, register, errors, control } = useForm()

    function onSubmit(values) {
        setIsDisabled(true)
        addDoc(collection(db, "Appointment"), { createdAt: new Date(), ...values, notification: true, status: 'Pending' }).then((docRef) => {
            if (docRef.id) {
                setIsSuccess(true)
                setIsDisabled(false)
            }
        })
    }

    return (
        <Container minH="100vh" maxW="xl" centerContent>
            <Fade in={true}>
                <Box mt="50" mb="50" w={[400, 450, 900, 1000]} p="8" borderWidth={1} borderRadius={8} boxShadow="lg">
                    <Center><Image src={Logo} htmlWidth="150" /></Center>
                    <Center fontSize="20" paddingTop="5" paddingBottom="2">
                        Request an Appointment
                    </Center>
                    <Center fontSize="14" paddingBottom="5">
                        Contact Pain Care Specialists today!
                    </Center>
                    {
                        isSuccess ?
                            <Center fontSize="30" mt="5">
                                <Icon as={BsCheckCircle} /> &nbsp;Submitted Schedule &nbsp; <a href="https://www.paincareoregon.com" ><Button size="sm">Back to Home</Button></a>
                            </Center>
                            :
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <FormControl id="patientType">
                                    <FormLabel>New or Current Patient ?</FormLabel>
                                    <Controller
                                        render={(props) => (
                                            <Select defaultValue="New Patient" onChange={(e) => {
                                                props.onChange(e)
                                                setPatientType(e.target.value)
                                            }}>
                                                <option value="New Patient">New Patient</option>
                                                <option value="Current Patient">Current Patient</option>
                                            </Select>
                                        )}
                                        name="patientType"
                                        control={control}
                                        defaultValue="New Patient"
                                    />
                                </FormControl>
                                <HStack mt="5">
                                    <FormControl id="firstName" isInvalid={errors.firstName}>
                                        <FormLabel>First Name</FormLabel>
                                        <Input name="firstName" type="text" ref={register({ required: 'Important information required.' })} />
                                        <FormErrorMessage>{errors.firstName && errors.firstName.message}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl id="lastName" isInvalid={errors.lastName}>
                                        <FormLabel>Last Name</FormLabel>
                                        <Input name="lastName" type="text" ref={register({ required: 'Important information required.' })} />
                                        <FormErrorMessage>{errors.lastName && errors.lastName.message}</FormErrorMessage>
                                    </FormControl>
                                </HStack>
                                <Stack mt="5" direction={["column", "column", "row", "row"]}>
                                    <FormControl id="email" w="100%" isInvalid={errors.email}>
                                        <FormLabel>Email</FormLabel>
                                        <Input name="email" type="email" ref={register({ required: 'Email information required.' })} />
                                        <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl id="telephone" isInvalid={errors.telephone}>
                                        <FormLabel>Telephone (111)111-1111</FormLabel>
                                        <Input name="telephone" type="telephone" ref={register({ required: 'Telephone information required.' })} />
                                        <FormErrorMessage>{errors.telephone && errors.telephone.message}</FormErrorMessage>
                                    </FormControl>
                                    {
                                        patientType === "New Patient" &&
                                        <>
                                            <FormControl id="dob" isInvalid={errors.dob}>
                                                <FormLabel>Date of Birth</FormLabel>
                                                <Input
                                                    name="dob"
                                                    type="date"
                                                    ref={register({ required: 'Date of Birth information required.' })}
                                                />
                                                <FormErrorMessage>{errors.dob && errors.dob.message}</FormErrorMessage>
                                            </FormControl>
                                            <FormControl id="insurance" isInvalid={errors.insurance}>
                                                <FormLabel>Insurance</FormLabel>
                                                <Controller
                                                    render={(props) => (
                                                        <Select name={props.name} onChange={props.onChange}>
                                                            <option value="N/A">Choose an Option</option>
                                                            <option value="Aetna - HMO">Aetna - HMO</option>
                                                            <option value="Aetna - Other">Aetna - Other</option>
                                                            <option value="APIPA">APIPA</option>
                                                            <option value="Banner OON Only">Banner OON Only</option>
                                                            <option value="Blue Cross Blue Shield">Blue Cross Blue Shield</option>
                                                            <option value="Cigna - HMO">Cigna - HMO</option>
                                                            <option value="Cigna - Other">Cigna - Other</option>
                                                            <option value="Greatwest">Greatwest</option>
                                                            <option value="Healthnet - HMO">Healthnet - HMO</option>
                                                            <option value="Healthnet - Other">Healthnet - Other</option>
                                                            <option value="Humana">Humana</option>
                                                            <option value="Medicare">Medicare</option>
                                                            <option value="Mercy Care Plan">Mercy Care Plan</option>
                                                            <option value="Abrazzo">Abrazzo</option>
                                                            <option value="Liens">Liens</option>
                                                            <option value="Lifeprint">Lifeprint</option>
                                                            <option value="UHC Secure Horizons">UHC Secure Horizons</option>
                                                            <option value="TRICARE">TRICARE</option>
                                                            <option value="UHC">UHC</option>
                                                            <option value="VA">VA</option>
                                                            <option value="Workers Comp">Workers Comp</option>
                                                            <option value="Self Pay">Self Pay</option>
                                                        </Select>
                                                    )}
                                                    name="insurance"
                                                    control={control}
                                                    rules={{ required: 'Information required.' }}
                                                    defaultValue={null}
                                                />
                                                <FormErrorMessage>{errors.insurance && errors.insurance.message}</FormErrorMessage>
                                            </FormControl>
                                        </>
                                    }
                                </Stack>
                                <Stack mt="5" direction={["column", "column", "row", "row"]}>
                                    <FormControl id="primaryLocation" isInvalid={errors.primaryLocation}>
                                        <FormLabel>Primary Location</FormLabel>
                                        <Controller
                                            render={(props) => (
                                                <Select name={props.name} onChange={props.onChange}>
                                                    <option value="N/A">Choose a Primary Location</option>
                                                    <option value="SALEM">SALEM</option>
                                                    <option value="CLACKAMAS">CLACKAMAS</option>
                                                    <option value="MCMINNVILLE">MCMINNVILLE</option>
                                                    <option value="WEST LINN">WEST LINN</option>
                                                    <option value="CORVALLIS">CORVALLIS</option>
                                                </Select>
                                            )}
                                            name="primaryLocation"
                                            control={control}
                                            rules={{ required: 'Location required.' }}
                                            defaultValue={null}
                                        />
                                        <FormErrorMessage>{errors.primaryLocation && errors.primaryLocation.message}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl id="secondaryLocation" isInvalid={errors.secondaryLocation}>
                                        <FormLabel>Secondary Location</FormLabel>
                                        <Controller
                                            render={(props) => (
                                                <Select name={props.name} onChange={props.onChange}>
                                                    <option value="N/A">Choose a Secondary Location</option>
                                                    <option value="SALEM">SALEM</option>
                                                    <option value="CLACKAMAS">CLACKAMAS</option>
                                                    <option value="MCMINNVILLE">MCMINNVILLE</option>
                                                    <option value="WEST LINN">WEST LINN</option>
                                                    <option value="CORVALLIS">CORVALLIS</option>
                                                </Select>
                                            )}
                                            name="secondaryLocation"
                                            control={control}
                                            rules={{ required: 'Location required.' }}
                                            defaultValue={null}
                                        />
                                        <FormErrorMessage>{errors.secondaryLocation && errors.secondaryLocation.message}</FormErrorMessage>
                                    </FormControl>F
                                </Stack>
                                <Stack mt="5" direction={["column", "column", "row", "row"]}>
                                    {
                                        patientType === "New Patient" ?
                                            <>
                                                <FormControl id="howDidYouHereAboutUs" isInvalid={errors.howDidYouHereAboutUs}>
                                                    <FormLabel>How did you hear about us ?</FormLabel>
                                                    <Controller
                                                        render={(props) => (
                                                            <Select name={props.name} onChange={props.onChange}>
                                                                <option value="N/A">Choose an Option</option>
                                                                <option value="TV Commercial">TV Commercial</option>
                                                                <option value="Google">Google</option>
                                                                <option value="Google My Business">Google Maps</option>
                                                                <option value="Facebook">Facebook</option>
                                                                <option value="Other">Other</option>
                                                            </Select>
                                                        )}
                                                        name="howDidYouHereAboutUs"
                                                        control={control}
                                                        rules={{ required: 'Information required.' }}
                                                        defaultValue={null}
                                                    />
                                                    <FormErrorMessage>{errors.howDidYouHereAboutUs && errors.howDidYouHereAboutUs.message}</FormErrorMessage>
                                                </FormControl>
                                                <FormControl id="chiefPainComplaint" isInvalid={errors.chiefPainComplaint}>
                                                    <FormLabel>Chief Pain Complaint</FormLabel>
                                                    <Input name="chiefPainComplaint" ref={register({ required: 'Information required.' })} />
                                                    <FormErrorMessage>{errors.dob && errors.dob.message}</FormErrorMessage>
                                                </FormControl>
                                            </>
                                            :
                                            <>
                                                <FormControl id="doctorName" isInvalid={errors.doctorName}>
                                                    <FormLabel>Doctor Name</FormLabel>
                                                    <Input name="doctorName" ref={register({ required: 'Information required.' })} />
                                                    <FormErrorMessage>{errors.doctorName && errors.doctorName.message}</FormErrorMessage>
                                                </FormControl>
                                                <FormControl id="reasonForContact" isInvalid={errors.reasonForContact}>
                                                    <FormLabel>Reason for Contacting Us</FormLabel>
                                                    <Input name="reasonForContact" />
                                                    <FormErrorMessage>{errors.reasonForContact && errors.reasonForContact.message}</FormErrorMessage>
                                                </FormControl>
                                            </>
                                    }
                                </Stack>
                                <FormControl id="message" mt="5" >
                                    <FormLabel>Message (Optional)</FormLabel>
                                    <Textarea name="message" rows="6" ref={register()} placeholder="Leave message to us" />
                                </FormControl>
                                <Button type="submit" colorScheme="blue" mt="5" w="100%" size="lg" isLoading={isDisabled} disabled={isDisabled}>Get Relief Now</Button>
                            </form>
                    }
                </Box>
            </Fade>
        </Container>
    )
}

export default RequestAppointment