import React, { useState, useRef } from 'react'
import {
  Input,
  Textarea,
  useToast,
  Button,
  HStack,
  VStack,
  Text,
} from '@chakra-ui/react'
import _ from 'underscore'
import { DatePicker } from 'antd'
import { EditIcon, CheckIcon, CloseIcon, DeleteIcon } from '@chakra-ui/icons'
import { BsCloudUpload } from 'react-icons/bs'
import {
  ref,
  getDownloadURL,
  uploadBytes,
  deleteObject,
  updateMetadata,
} from 'firebase/storage'
import { doc, updateDoc, collection, addDoc } from 'firebase/firestore'
import { storage, db } from '../config/firebase'

function Mandatory({
  url,
  value,
  name,
  title,
  type,
  profile,
  credentialIndex,
  credential,
  credentialId,
  mandatoryIndex,
  createdBy,
}) {
  const toast = useToast()
  const [editable, setEditable] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(false)
  const [date, setDate] = useState(null)
  const [textValue, setTextValue] = useState(null)

  const hiddenFileInput = useRef()
  function handleUploadClick() {
    hiddenFileInput.current.click()
  }

  async function handleUploadChange(e) {
    setUploadProgress(true)
    const fileUploaded = e.target.files[0]

    if (fileUploaded) {
      const profileRef = ref(
        storage,
        `Profile/${profile.id}/Credentials/${credentialId}/${fileUploaded.name}`
      )
      uploadBytes(profileRef, fileUploaded).then(async () => {
        const downloadURL = await getDownloadURL(profileRef)
        const newMetadata = {
          customMetadata: { credential, credentialId, createdBy },
        }
        updateMetadata(profileRef, newMetadata)
        const updateMandatory = _.map(
          profile.credentials[credentialIndex].mandatory,
          (data, index) => {
            if (index === mandatoryIndex) {
              console.log(
                profile.credentials[credentialIndex].mandatory[mandatoryIndex]
              )
              if (
                profile.credentials[credentialIndex]?.mandatory[mandatoryIndex]
                  .url
              ) {
                return {
                  ...data,
                  url: [
                    ...profile.credentials[credentialIndex].mandatory[
                      mandatoryIndex
                    ].url,
                    { url: downloadURL, fileName: fileUploaded.name },
                  ],
                }
              } else {
                return {
                  ...data,
                  url: [{ url: downloadURL, fileName: fileUploaded.name }],
                }
              }
            } else {
              return { ...data }
            }
          }
        )
        const updateCredential = _.map(profile.credentials, (data, index) => {
          if (index === credentialIndex) {
            return { ...data, mandatory: updateMandatory }
          } else {
            return data
          }
        })
        updateDoc(doc(db, 'profiles', profile.id), {
          credentials: updateCredential,
        }).then(() => {
          toast({
            title: 'Credential Updated.',
            description: `${title} has been updated.`,
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
          addDoc(collection(db, 'CredentialLogs'), {
            createdAt: new Date(),
            credential: credential,
            action: 'Update',
            title,
            createdBy,
            staffName: profile.fullName,
            staffId: profile.id,
          })
          setUploadProgress(false)
        })
      })
    } else {
      setUploadProgress(false)
    }
  }

  function handleDeleteFile(fileName, deleteIndex) {
    const updateMandatory = _.map(
      profile.credentials[credentialIndex].mandatory,
      (data, index) => {
        if (index === mandatoryIndex) {
          const fileUrl =
            profile.credentials[credentialIndex].mandatory[mandatoryIndex].url
          return {
            ...data,
            url: _.filter(fileUrl, (url, index) => index !== deleteIndex),
          }
        } else {
          return { ...data }
        }
      }
    )
    const updateCredential = _.map(profile.credentials, (data, index) => {
      if (index === credentialIndex) {
        return { ...data, mandatory: updateMandatory }
      } else {
        return data
      }
    })

    updateDoc(doc(db, 'profiles', profile.id), {
      credentials: updateCredential,
    }).then(() => {
      toast({
        title: 'Credential Updated.',
        description: `${title} has been deleted.`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      let credentialFileRef = ref(
        storage,
        `Profile/${profile.id}/Credentials/${fileName}`
      )
      deleteObject(credentialFileRef)
      addDoc(collection(db, 'CredentialLogs'), {
        createdAt: new Date(),
        credential: credential,
        action: 'Delete',
        title,
        createdBy,
        staffName: profile.fullName,
        staffId: profile.id,
      })
      setUploadProgress(false)
    })
  }

  function handleDateChange() {
    let dateFormat = date.format('YYYY-MM-DD')
    const updateMandatory = _.map(
      profile.credentials[credentialIndex].mandatory,
      (data, index) => {
        if (index === mandatoryIndex) {
          return { ...data, value: dateFormat }
        } else {
          return { ...data }
        }
      }
    )
    const updateCredential = _.map(profile.credentials, (data, index) => {
      if (index === credentialIndex) {
        return { ...data, mandatory: updateMandatory }
      } else {
        return data
      }
    })
    updateDoc(doc(db, 'profiles', profile.id), {
      credentials: updateCredential,
    }).then(() => {
      toast({
        title: 'Credential Updated.',
        description: `${title} has been updated.`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      addDoc(collection(db, 'CalendarEvents'), {
        createdAt: new Date(),
        profileId: profile.id,
        type: title,
        start: new Date(dateFormat),
        end: new Date(dateFormat),
        title: `${title} - ${credential} of ${profile.fullName}`,
        allDay: true,
      })
      addDoc(collection(db, 'CredentialLogs'), {
        createdAt: new Date(),
        credential: credential,
        action: 'Update',
        title,
        createdBy,
        staffName: profile.fullName,
        staffId: profile.id,
      })
      setEditable(false)
    })
  }

  function handleUpdateText() {
    const updateMandatory = _.map(
      profile.credentials[credentialIndex].mandatory,
      (data, index) => {
        if (index === mandatoryIndex) {
          return { ...data, value: textValue }
        } else {
          return { ...data }
        }
      }
    )
    const updateCredential = _.map(profile.credentials, (data, index) => {
      if (index === credentialIndex) {
        return { ...data, mandatory: updateMandatory }
      } else {
        return data
      }
    })
    updateDoc(doc(db, 'profiles', profile.id), {
      credentials: updateCredential,
    }).then(() => {
      toast({
        title: 'Credential Updated.',
        description: `${title} has been updated.`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      console.log(createdBy)
      addDoc(collection(db, 'CredentialLogs'), {
        createdAt: new Date(),
        credential: credential,
        action: 'Update',
        title,
        createdBy,
        staffName: profile.fullName,
        staffId: profile.id,
      })
      setEditable(false)
    })
  }

  switch (type) {
    case 'text':
      return (
        <>
          {editable ? (
            <HStack>
              <Input
                defaultValue={value}
                onChange={e => {
                  setTextValue(e.currentTarget.value)
                }}
                width={['100%', '100%', '100%', '40%']}
                type={type}
                name={name}
              />
              <Button
                onClick={handleUpdateText}
                isDisabled={!textValue ? true : false}
                leftIcon={<CheckIcon />}
                size="sm"
              >
                Save
              </Button>{' '}
              <Button
                onClick={() => setEditable(false)}
                leftIcon={<CloseIcon />}
                size="sm"
              >
                Close
              </Button>
            </HStack>
          ) : (
            <>
              <HStack>
                <Text>{value}</Text>{' '}
                <EditIcon cursor="pointer" onClick={() => setEditable(true)} />
              </HStack>
            </>
          )}
        </>
      )
    case 'file':
      return (
        <>
          <Input
            type="file"
            display="none"
            ref={hiddenFileInput}
            onChange={handleUploadChange}
          />
          {url && url.length > 0 ? (
            <>
              <VStack alignItems="flex-start">
                {url.map((data, index) => (
                  <HStack key={index}>
                    <Button
                      size="xs"
                      onClick={() => {
                        window.open(`${data.url}`, '_blank')
                      }}
                    >
                      {data.fileName}
                    </Button>
                    <DeleteIcon
                      onClick={() => handleDeleteFile(data.fileName, index)}
                      cursor="pointer"
                    />
                  </HStack>
                ))}
              </VStack>
              <Button
                mt="20px"
                size="xs"
                leftIcon={<BsCloudUpload />}
                onClick={handleUploadClick}
                disabled={uploadProgress}
                isLoading={uploadProgress}
                loadingText="Uploading..."
              >
                Upload More
              </Button>
            </>
          ) : (
            <>
              <Button
                leftIcon={<BsCloudUpload />}
                onClick={handleUploadClick}
                size="xs"
                disabled={uploadProgress}
                isLoading={uploadProgress}
                loadingText="Uploading..."
              >
                Upload
              </Button>
            </>
          )}
        </>
      )
    case 'date':
      return (
        <>
          {editable ? (
            <HStack>
              <DatePicker
                onChange={value => setDate(value)}
                size="large"
                format="YYYY-MM-DD"
              />{' '}
              <Button
                onClick={handleDateChange}
                isDisabled={!date ? true : false}
                leftIcon={<CheckIcon />}
                size="sm"
              >
                Save
              </Button>{' '}
              <Button
                onClick={() => setEditable(false)}
                leftIcon={<CloseIcon />}
                size="sm"
              >
                Close
              </Button>
            </HStack>
          ) : (
            <>
              <HStack>
                <Text>{value}</Text>{' '}
                <EditIcon cursor="pointer" onClick={() => setEditable(true)} />
              </HStack>
            </>
          )}
        </>
      )
    case 'textarea':
      return (
        <>
          {editable ? (
            <HStack>
              <Textarea
                defaultValue={value}
                onChange={e => setTextValue(e.currentTarget.value)}
                width={['100%', '100%', '100%', '40%']}
                name={name}
              />{' '}
              <Button
                onClick={handleUpdateText}
                isDisabled={!textValue ? true : false}
                leftIcon={<CheckIcon />}
                size="sm"
              >
                Save
              </Button>{' '}
              <Button
                onClick={() => setEditable(false)}
                leftIcon={<CloseIcon />}
                size="sm"
              >
                Close
              </Button>
            </HStack>
          ) : (
            <>
              <HStack>
                <Text>{value}</Text>{' '}
                <EditIcon cursor="pointer" onClick={() => setEditable(true)} />
              </HStack>
            </>
          )}
        </>
      )
    default:
      return null
  }
}

export default Mandatory
