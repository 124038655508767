import React from 'react'

import {
  Document,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer'
import './style.module.css'

function PDFEmployeeQuiz({ employeeData }) {
  const styles = StyleSheet.create({
    pdfView: {
      width: window.innerWidth,
      height: window.innerHeight,
      backgroundColor: '#fff',
    },
    page: {
      width: '100%',
      flexDirection: 'column',
      backgroundColor: '#fff',
      alignItems: 'center',
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 60,
      paddingBottom: 40,
    },
    section: {
      width: '100%',
      marginBottom: 20,
      padding: 10,

      alignItems: 'center',
    },
    sectionImg: {
      width: '100%',
      marginTop: 35,
      padding: 0,
      alignItems: 'center',
    },
    sectionTwo: {
      width: '100%',
      marginTop: 15,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    headerTitleContainer: {
      width: '100%',
      marginTop: 15,
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    selectChoiceContainer: {
      paddingBottom: 4,
      width: '80%',
      paddingLeft: '20px',
    },
    contentContainer: {
      paddingBottom: 4,
      paddingTop: 6,
      flexDirection: 'row',
      width: '100%',
      alignSelf: 'flex-start',
    },
    choiceContent: {
      paddingTop: 4,
      fontSize: '12px',
    },
    choiceContainer: {
      paddingBottom: 4,
      paddingTop: 6,
      width: '80%',
      paddingLeft: '20px',
      flexDirection: 'row',
      justifyContent: 'start',
      alignContent: 'start',
      gap: '30px',
    },
    trueFalseChoiceContainer: {
      paddingBottom: 4,
      paddingTop: 6,

      flexDirection: 'row',
      gap: '6px',
    },
    trueFalseChoiceFill: {
      borderBottom: '1px',
      width: '50px',
      fontSize: '14px',
    },
    fillUnderLineWithBorder: {
      borderBottom: 1,
      width: '95%',
      marginRight: 70,
      marginLeft: 100,
      fontSize: '14px',
      marginTop: '5px',
    },
    boxview: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
    },
    img: {
      width: '130px',
      height: 'auto',
      objectFit: 'cover',
    },
    imgcheck: {
      width: '20px',
      height: '20px',
    },
    texttitle: {
      top: 15,
      fontSize: '14px',
    },
    boxmonthyear: {
      top: 10,
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    textmonthyear: {
      fontSize: '12px',
    },
    textmonthyearborder: {
      fontSize: '12px',
      borderBottom: 1,
      textAlign: 'center',
      width: '150px',
    },
    nameTitle: {
      fontSize: '12px',
      // marginLeft: '10px',
    },
    title: {
      fontSize: '12px',
      paddingLeft: '15px',
    },
    contentText: {
      fontSize: '12px',
    },
    nameDateStyle: {
      justifyContent: 'space-between',
      display: 'flex',
    },
    subTitleNumber: {
      width: '8%',
      fontSize: '12px',
      fontStyle: 'bold',
    },
    fillAnswers: {
      fontSize: '10px',
    },
    stylesCheckImage: {
      flexDirection: 'row',
    },
    headerTitle: {
      marginTop: 5,
      fontSize: '12px',
      fontWeight: 'bold',
    },
  })
  return (
    <PDFViewer style={styles.pdfView}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View>
            <Text style={styles.texttitle}>EMPLOYEE QUIZ</Text>
          </View>

          <View
            style={{
              flexDirection: 'row',
              gap: '40px',
              justifyContent: 'space-between',
              marginTop: '30px',
              marginBottom: '20px',
            }}
          >
            <View style={{ alignItems: 'center' }}>
              <Text
                style={{
                  ...styles.headerTitle,
                  borderBottom: 1,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingBottom: 4,
                  minWidth: '40px',
                }}
              >
                {employeeData?.name || '-'}
              </Text>
              <Text style={styles.headerTitle}>Employee Name</Text>
            </View>
            <View style={{ alignItems: 'center' }}>
              <Text
                style={{
                  ...styles.headerTitle,
                  borderBottom: 1,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingBottom: 4,
                  minWidth: '40px',
                  fontWeight: 'bold',
                }}
              >
                {employeeData?.date || '-'}
              </Text>
              <Text style={styles.headerTitle}>date</Text>
            </View>
          </View>

          <View style={styles.contentContainer}>
            <Text style={styles.nameTitle}>1.</Text>
            <Text style={styles.title}>
              How frequently are all Oregon businesses required to hold either
              Safety Committee (more than 10 employees) or all-staff safety
              meetings?
            </Text>
          </View>

          <View style={styles.fillUnderLineWithBorder}>
            {employeeData?.quizOne && (
              <Text styles={styles.fillAnswers}>{employeeData?.quizOne}</Text>
            )}
          </View>

          <View style={{ paddingTop: 6 }}></View>
          <>
            <View style={styles.contentContainer}>
              <Text style={styles.nameTitle}>2.</Text>
              <Text style={styles.title}>
                If our practice has been free of accidents or bloodborne
                pathogen exposures for at least 24 months, we are not required
                to hold any safety meetings at all.
              </Text>
            </View>

            <View style={styles.choiceContainer}>
              <View style={styles.trueFalseChoiceContainer}>
                <Text style={styles.contentText}>True</Text>
                <View style={styles.stylesCheckImage}>
                  {employeeData?.quizTwo === 'true' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                        position: 'absolute',
                        left: 13,
                        top: -6,
                      }}
                      src="/icons/close-100.png"
                    />
                  )}
                  <Text style={styles.trueFalseChoiceFill}></Text>
                </View>
              </View>
              <View style={styles.trueFalseChoiceContainer}>
                <Text style={styles.contentText}>False</Text>
                <View style={styles.stylesCheckImage}>
                  {employeeData?.quizTwo === 'false' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                        position: 'absolute',
                        left: 13,
                        top: -6,
                      }}
                      src="/icons/close-100.png"
                    />
                  )}
                  <Text style={styles.trueFalseChoiceFill}></Text>
                </View>
              </View>
            </View>
          </>

          <>
            <View style={styles.contentContainer}>
              <Text style={styles.nameTitle}>3.</Text>
              <Text style={styles.title}>
                OR-OSHA requires that every business keep a written record of
                safety topics covered and staff attendance at safety meetings.
              </Text>
            </View>

            <View style={styles.choiceContainer}>
              <View style={styles.trueFalseChoiceContainer}>
                <Text style={styles.contentText}>True</Text>
                <View style={styles.stylesCheckImage}>
                  {employeeData?.quizThree === 'true' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                        position: 'absolute',
                        left: 13,
                        top: -6,
                      }}
                      src="/icons/close-100.png"
                    />
                  )}
                  <Text style={styles.trueFalseChoiceFill}></Text>
                </View>
              </View>
              <View style={styles.trueFalseChoiceContainer}>
                <Text style={styles.contentText}>False</Text>
                <View style={styles.stylesCheckImage}>
                  {employeeData?.quizThree === 'false' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                        position: 'absolute',
                        left: 13,
                        top: -6,
                      }}
                      src="/icons/close-100.png"
                    />
                  )}
                  <Text style={styles.trueFalseChoiceFill}></Text>
                </View>
              </View>
            </View>
          </>

          <>
            <View style={styles.contentContainer}>
              <Text style={styles.nameTitle}>4.</Text>
              <Text style={styles.title}>
                What does OPIM stand for? a. Our Physician Is Masterful b. Other
                Potentially Infectious Material c. Outside Parameters In
                Medica/Dental
              </Text>
            </View>

            <View style={styles.selectChoiceContainer}>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quizFour === 'answerA' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -6,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>
                  a.{` `}
                  {` `}
                  {` `}Our Physician Is Masterful
                </Text>
              </View>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quizFour === 'answerB' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -6,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>
                  b.{` `}
                  {` `}
                  {` `}Other Potentially Infectious Material
                </Text>
              </View>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quizFour === 'answerC' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -6,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>
                  c.{` `}
                  {` `}
                  {` `}Outside Parameters In Medica/Dental
                </Text>
              </View>
            </View>
          </>

          <>
            <View style={styles.contentContainer}>
              <Text style={styles.nameTitle}>5.</Text>
              <Text style={styles.title}>
                Exposure to a Bloodborne pathogen or OPIM can happen when:
              </Text>
            </View>

            <View style={styles.selectChoiceContainer}>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quizFive === 'answerA' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -6,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>
                  a.{` `}
                  {` `}
                  {` `}A droplet of a patient’s blood lands in your eye
                </Text>
              </View>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quizFive === 'answerB' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -6,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>
                  b.{` `}
                  {` `}
                  {` `}Your gloved hand touches saliva in a patient’s mouth
                </Text>
              </View>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quizFive === 'answerC' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -6,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>
                  c.{` `}
                  {` `}
                  {` `}You stick yourself with a sterile needle
                </Text>
              </View>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quizFive === 'allOfAbove' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -6,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>
                  d.{` `}
                  {` `}
                  {` `}All of the above
                </Text>
              </View>
            </View>
          </>

          <>
            <View style={styles.contentContainer}>
              <Text style={styles.nameTitle}>6.</Text>
              <Text style={styles.title}>
                Your practice is required to ask the source individual to be
                tested for both Hepatitis B & HIV.
              </Text>
            </View>
            <View style={styles.choiceContainer}>
              <View style={styles.trueFalseChoiceContainer}>
                <Text style={styles.contentText}>True</Text>
                <View style={styles.stylesCheckImage}>
                  {employeeData?.quizSix === 'true' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                        position: 'absolute',
                        left: 13,
                        top: -6,
                      }}
                      src="/icons/close-100.png"
                    />
                  )}
                  <Text style={styles.trueFalseChoiceFill}></Text>
                </View>
              </View>
              <View style={styles.trueFalseChoiceContainer}>
                <Text style={styles.contentText}>False</Text>
                <View style={styles.stylesCheckImage}>
                  {employeeData?.quizSix === 'false' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                        position: 'absolute',
                        left: 13,
                        top: -6,
                      }}
                      src="/icons/close-100.png"
                    />
                  )}
                  <Text style={styles.trueFalseChoiceFill}></Text>
                </View>
              </View>
            </View>
          </>

          <>
            <View style={styles.contentContainer}>
              <Text style={styles.nameTitle}>7.</Text>
              <Text style={styles.title}>
                Hepatitis B virus (HbV) Immunity must be effectively maintained
                by the employee.
              </Text>
            </View>
            <View style={styles.choiceContainer}>
              <View style={styles.trueFalseChoiceContainer}>
                <Text style={styles.contentText}>True</Text>
                <View style={styles.stylesCheckImage}>
                  {employeeData?.quizSeven === 'true' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                        position: 'absolute',
                        left: 13,
                        top: -6,
                      }}
                      src="/icons/close-100.png"
                    />
                  )}
                  <Text style={styles.trueFalseChoiceFill}></Text>
                </View>
              </View>
              <View style={styles.trueFalseChoiceContainer}>
                <Text style={styles.contentText}>False</Text>
                <View style={styles.stylesCheckImage}>
                  {employeeData?.quizSeven === 'false' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                        position: 'absolute',
                        left: 13,
                        top: -6,
                      }}
                      src="/icons/close-100.png"
                    />
                  )}
                  <Text style={styles.trueFalseChoiceFill}></Text>
                </View>
              </View>
            </View>
          </>

          <>
            <View style={styles.contentContainer}>
              <Text style={styles.nameTitle}>8.</Text>
              <Text style={styles.title}>
                Where would you find your practice’s Bloodborne Pathogen
                Exposure Control Plan?
              </Text>
            </View>
            <View style={styles.fillUnderLineWithBorder}>
              {employeeData?.quizEigth && (
                <Text styles={styles.fillAnswers}>
                  {employeeData?.quizEigth}
                </Text>
              )}
            </View>
          </>
          <View style={{ paddingTop: 6 }}></View>
          <>
            <View style={styles.contentContainer}>
              <Text style={styles.nameTitle}>9.</Text>
              <Text style={styles.title}>
                OSHA requires every healthcare facility with employees using
                sharps to have an on-going program to identify and evaluate
                safer sharps devices, as well as annually document its findings.
              </Text>
            </View>
            <View style={styles.choiceContainer}>
              <View style={styles.trueFalseChoiceContainer}>
                <Text style={styles.contentText}>True</Text>
                <View style={styles.stylesCheckImage}>
                  {employeeData?.quizNine === 'true' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                        position: 'absolute',
                        left: 13,
                        top: -6,
                      }}
                      src="/icons/close-100.png"
                    />
                  )}
                  <Text style={styles.trueFalseChoiceFill}></Text>
                </View>
              </View>
              <View style={styles.trueFalseChoiceContainer}>
                <Text style={styles.contentText}>False</Text>
                <View style={styles.stylesCheckImage}>
                  {employeeData?.quizNine === 'false' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                        position: 'absolute',
                        left: 13,
                        top: -6,
                      }}
                      src="/icons/close-100.png"
                    />
                  )}
                  <Text style={styles.trueFalseChoiceFill}></Text>
                </View>
              </View>
            </View>
          </>
        </Page>
        <Page size="A4" style={styles.page}>
          <>
            <View style={styles.contentContainer}>
              <Text style={styles.nameTitle}>10.</Text>
              <Text style={styles.title}>
                If your interaction with a patient involves a “reasonable
                expectation” of exposure to a bloodborne pathogen, what personal
                protective equipment (PPE) does your practice require you to
                wear?
              </Text>
            </View>
            <View style={styles.fillUnderLineWithBorder}>
              {employeeData?.quizTen && (
                <Text styles={styles.fillAnswers}>{employeeData?.quizTen}</Text>
              )}
            </View>
          </>
          <View style={{ paddingTop: 6 }}></View>
          <>
            <View style={styles.contentContainer}>
              <Text style={styles.nameTitle}>11.</Text>
              <Text style={styles.title}>
                Name an “engineering control” used in your practice to protect
                you.
              </Text>
            </View>
            <View style={styles.fillUnderLineWithBorder}>
              {employeeData?.quizEleven && (
                <Text styles={styles.fillAnswers}>
                  {employeeData?.quizEleven}
                </Text>
              )}
            </View>
          </>
          <View style={{ paddingTop: 6 }}></View>
          <>
            <View style={styles.contentContainer}>
              <Text style={styles.nameTitle}>12.</Text>
              <Text style={styles.title}>
                Name a “work practice control” used in your practice to protect
                you.
              </Text>
            </View>
            <View style={styles.fillUnderLineWithBorder}>
              {employeeData?.quizTwelve && (
                <Text styles={styles.fillAnswers}>
                  {employeeData?.quizTwelve}
                </Text>
              )}
            </View>
          </>
          <View style={{ paddingTop: 6 }}></View>
          <>
            <View style={styles.contentContainer}>
              <Text style={styles.nameTitle}>13.</Text>
              <Text style={styles.title}>
                When working with hazardous chemicals in your practice, OSHA
                expects you to know what the SDS says regarding personal
                protective equipment for those chemicals.
              </Text>
            </View>
            <View style={styles.choiceContainer}>
              <View style={styles.trueFalseChoiceContainer}>
                <Text style={styles.contentText}>True</Text>
                <View style={styles.stylesCheckImage}>
                  {employeeData?.quizThirteen === 'true' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                        position: 'absolute',
                        left: 13,
                        top: -6,
                      }}
                      src="/icons/close-100.png"
                    />
                  )}
                  <Text style={styles.trueFalseChoiceFill}></Text>
                </View>
              </View>
              <View style={styles.trueFalseChoiceContainer}>
                <Text style={styles.contentText}>False</Text>
                <View style={styles.stylesCheckImage}>
                  {employeeData?.quizThirteen === 'false' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                        position: 'absolute',
                        left: 13,
                        top: -6,
                      }}
                      src="/icons/close-100.png"
                    />
                  )}
                  <Text style={styles.trueFalseChoiceFill}></Text>
                </View>
              </View>
            </View>
          </>

          <>
            <View style={styles.contentContainer}>
              <Text style={styles.nameTitle}>14.</Text>
              <Text style={styles.title}>
                Where is the chemical spill kit locate in you practice?
              </Text>
            </View>
            <View style={styles.fillUnderLineWithBorder}>
              {employeeData?.quizFourteen && (
                <Text styles={styles.fillAnswers}>
                  {employeeData?.quizFourteen}
                </Text>
              )}
            </View>
          </>
          <View style={{ paddingTop: 6 }}></View>
          <>
            <View style={styles.contentContainer}>
              <Text style={styles.nameTitle}>15.</Text>
              <Text style={styles.title}>
                A “secondary container” for hazardous materials is less
                important than a primary container and dose no need a label.
              </Text>
            </View>
            <View style={styles.choiceContainer}>
              <View style={styles.trueFalseChoiceContainer}>
                <Text style={styles.contentText}>True</Text>
                <View style={styles.stylesCheckImage}>
                  {employeeData?.quizFifteen === 'true' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                        position: 'absolute',
                        left: 13,
                        top: -6,
                      }}
                      src="/icons/close-100.png"
                    />
                  )}
                  <Text style={styles.trueFalseChoiceFill}></Text>
                </View>
              </View>
              <View style={styles.trueFalseChoiceContainer}>
                <Text style={styles.contentText}>False</Text>
                <View style={styles.stylesCheckImage}>
                  {employeeData?.quizFifteen === 'false' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                        position: 'absolute',
                        left: 13,
                        top: -6,
                      }}
                      src="/icons/close-100.png"
                    />
                  )}
                  <Text style={styles.trueFalseChoiceFill}></Text>
                </View>
              </View>
            </View>
          </>

          <>
            <View style={styles.contentContainer}>
              <Text style={styles.nameTitle}>16.</Text>
              <Text style={styles.title}>
                How often do you need to check and flush out your practice’s
                eyewash station(s)?
              </Text>
            </View>
            <View style={styles.selectChoiceContainer}>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quizSixteen === 'whenever' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -6,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>
                  A.{` `}
                  {` `}
                  {` `}Whenever we use it
                </Text>
              </View>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quizSixteen === 'weekly' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -6,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>
                  B.{` `}
                  {` `}
                  {` `}Weekly
                </Text>
              </View>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quizSixteen === 'monthly' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -6,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>
                  C.{` `}
                  {` `}
                  {` `}Monthly
                </Text>
              </View>
            </View>
          </>

          <>
            <View style={{ ...styles.contentContainer }}>
              <Text style={styles.nameTitle}>17.</Text>
              <Text style={styles.title}>
                Tuberculosis, flu viruses and MRAS all require that we:
              </Text>
            </View>
            <View style={styles.selectChoiceContainer}>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quizSeventeen === 'answerA' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -6,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>
                  A.{` `}
                  {` `}
                  {` `}Follow out practice’s disinfection schedule for surfaces
                </Text>
              </View>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quizSeventeen === 'answerB' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -6,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>
                  B.{` `}
                  {` `}
                  {` `}Protect ourselves from airborne infection
                </Text>
              </View>

              <View style={styles.stylesCheckImage}>
                {employeeData?.quizSeventeen === 'answerC' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -6,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>
                  C.{` `}
                  {` `}
                  {` `}Take extra precautions if a patient tells you she is
                  infected
                </Text>
              </View>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quizSeventeen === 'allOfAbove' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -6,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>
                  D.{` `}
                  {` `}
                  {` `}All of the above
                </Text>
              </View>
            </View>
          </>

          <>
            <View style={styles.contentContainer}>
              <Text style={styles.nameTitle}>18.</Text>
              <Text style={styles.title}>
                What is your practice’s policy on verbal or physical harassment
                in the workplace
              </Text>
            </View>
            <View style={styles.fillUnderLineWithBorder}>
              {employeeData?.quizEigthteen && (
                <Text styles={styles.fillAnswers}>
                  {employeeData?.quizEigthteen}
                </Text>
              )}
            </View>
          </>
          <View style={{ paddingTop: 6 }}></View>
          <>
            <View style={styles.contentContainer}>
              <Text style={styles.nameTitle}>19.</Text>
              <Text style={styles.title}>
                An OSHA compliance officer can interview any employee in the
                practice and ask about:
              </Text>
            </View>

            <View style={styles.selectChoiceContainer}>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quuizNineteen === 'answerA' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -6,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>
                  A.{` `}
                  {` `}
                  {` `}Training they have received in practicing Universal
                  Precautions
                </Text>
              </View>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quuizNineteen === 'answerB' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -6,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>
                  B.{` `}
                  {` `}
                  {` `}What duties they would perform in evacuating the building
                </Text>
              </View>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quuizNineteen === 'answerC' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -6,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>
                  C.{` `}
                  {` `}
                  {` `}When they attended their last safety meeting and what was
                  discussed
                </Text>
              </View>
              <View style={styles.stylesCheckImage}>
                {employeeData?.quuizNineteen === 'allOfAbove' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                      position: 'absolute',
                      left: -6,
                      top: 1,
                    }}
                    src="/icons/close-100.png"
                  />
                )}
                <Text style={styles.choiceContent}>
                  D.{` `}
                  {` `}
                  {` `}All of the above
                </Text>
              </View>
            </View>
          </>

          <>
            <View style={styles.contentContainer}>
              <Text style={styles.nameTitle}>20.</Text>
              <Text style={styles.title}>
                When using the fire extinguisher, where do you aim it and how
                far back should you stand?
              </Text>
            </View>
            <View style={styles.fillUnderLineWithBorder}>
              {employeeData?.quizTwenty && (
                <Text styles={styles.fillAnswers}>
                  {employeeData?.quizTwenty}
                </Text>
              )}
            </View>
          </>
        </Page>
        <Page size="A4" style={styles.page}>
          <>
            <View style={styles.contentContainer}>
              <Text style={styles.nameTitle}>21.</Text>
              <Text style={styles.title}>
                Where is the fire extinguisher nearest your work area is
                located?
              </Text>
            </View>
            <View style={styles.fillUnderLineWithBorder}>
              {employeeData?.quizTwentyone && (
                <Text styles={styles.fillAnswers}>
                  {employeeData?.quizTwentyone}
                </Text>
              )}
            </View>
          </>
          <View style={{ paddingTop: 6 }}></View>
          <>
            <View style={styles.contentContainer}>
              <Text style={styles.nameTitle}>22.</Text>
              <Text style={styles.title}>
                In the event of an emergency evacuation. Where do you meet
                outside the practice?
              </Text>
            </View>
            <View style={styles.fillUnderLineWithBorder}>
              {employeeData?.quizTwentytwo && (
                <Text styles={styles.fillAnswers}>
                  {employeeData?.quizTwentytwo}
                </Text>
              )}
            </View>
          </>

          <>
            <View style={{ marginTop: '20px' }}>
              <Text style={styles.nameTitle}>
                HEALTHARE COMPLIANCE ASSOCIATES
              </Text>
            </View>
          </>
          <>
            <View>
              <Text style={styles.nameTitle}>
                90722 Hill Rd. / Springfield / 541-345-3875 / fax: 541-345-3939
              </Text>
            </View>
          </>
        </Page>
      </Document>
    </PDFViewer>
  )
}

export default PDFEmployeeQuiz
