import { Image, Text, View } from '@react-pdf/renderer'
import moment from 'moment'
import React from 'react'

function SecondSignatureCancellationNonWeb({ fillableData, styles }) {
  return (
    <View style={{ ...styles.gapBox }}>
      <View
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          fontSize: 8,
        }}
      >
        <Image
          style={{ width: '110px', objectFit: 'cover' }}
          src="/paincare-web-v2.png"
        />
        <View style={styles.boxtitle}>
          <Text>2480 Liberty Street NE, Suite 180, Salem, OR 97301</Text>
        </View>
        <View style={styles.boxtitle}>
          <Text>Office: (503) 371-1010 Fax: (503) 371-0805</Text>
        </View>
      </View>
      <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
        This authorization must be filled-out, dated and signed by the patient
        or by a person authorized by law to give authorization.
      </Text>
      <View
        style={{
          width: '450px',
          gap: '4px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            width: '100%',
            flexDirection: 'row',
          }}
        >
          <View style={{ flexDirection: 'row', width: '100%' }}>
            <View style={{ width: '52px' }}>
              <Text>I authorize</Text>
            </View>
            <View
              style={{
                width: '220px',
                alignItems: 'center',
                borderBottom: '1px solid #000',
                textAlign: 'center',
              }}
            >
              <Text>
                {fillableData.authorize ? fillableData.authorize : ''}
              </Text>
            </View>
            <View>
              <Text>to release a copy of the medical information for</Text>
            </View>
          </View>
        </View>
        <View
          style={{
            width: '100%',
            flexDirection: 'row',
          }}
        >
          <View style={{ flexDirection: 'row', width: '100%' }}>
            <View
              style={{
                width: '100%',
                borderBottom: '1px solid #000',
                textAlign: 'center',
              }}
            >
              <Text>
                {fillableData?.namePatient}
                {'   '}
                {fillableData?.DOB
                  ? moment(fillableData?.DOB).format('MM/DD/YYYY')
                  : ''}
              </Text>
            </View>
            <View style={{ width: '140px' }}>
              <Text>(Name of Patient/DOB)</Text>
            </View>
          </View>
        </View>
        <View
          style={{
            width: '100%',
            flexDirection: 'row',
          }}
        >
          <View style={{ flexDirection: 'row', width: '100%' }}>
            <View style={{ width: '13px' }}>
              <Text>to</Text>
            </View>
            <View
              style={{
                width: '100%',
                borderBottom: '1px solid #000',
                textAlign: 'center',
              }}
            >
              <Text>
                {fillableData?.nameRecipient ? fillableData?.nameRecipient : ''}
              </Text>
            </View>
            <View style={{ width: '130px' }}>
              <Text>(Name of Recipient)</Text>
            </View>
          </View>
        </View>
        <View
          style={{
            width: '100%',
            flexDirection: 'row',
          }}
        >
          <View style={{ flexDirection: 'row', width: '100%' }}>
            <View style={{ width: '80px' }}>
              <Text>Mailling Address</Text>
            </View>
            <View
              style={{
                width: '180px',
                alignItems: 'center',
                borderBottom: '1px solid #000',
                textAlign: 'center',
              }}
            >
              <Text>
                {fillableData.maillingAddress
                  ? fillableData.maillingAddress
                  : ''}
              </Text>
            </View>
            <View style={{ width: '70px' }}>
              <Text>or Fax Number</Text>
            </View>
            <View
              style={{
                width: '120px',
                alignItems: 'center',
                borderBottom: '1px solid #000',
                textAlign: 'center',
              }}
            >
              <Text>{fillableData.fax ? fillableData.fax : ''}</Text>
            </View>
          </View>
        </View>
        <View style={{ width: '100%', paddingTop: 4 }}>
          <Text>
            The information will be used on my behalf for the following
            purpose(s):
          </Text>
          <Text style={{ borderBottom: 1, paddingTop: 4 }}>
            {fillableData.information ? fillableData.information : ''}
          </Text>
        </View>
        <View style={{ width: '100%', paddingTop: 2 }}>
          <View>
            <Text>
              By initialing the spaces below, I specifically authorize the
              release of the following records, if such records exist:
            </Text>
          </View>
          <View
            style={{
              width: '100%',
              flexDirection: 'column',
              gap: '5px',
            }}
          >
            <View style={{ gap: '4px', paddingTop: 6 }}>
              <View
                style={{
                  ...styles.trueFalseChoiceRow,
                  justifyContent: 'space-between',
                }}
              >
                <View style={{ ...styles.trueFalseChoicewidth, width: '100%' }}>
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.racords.includes('hospitalrecord') && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>
                    All hospital records (including nursing records and progress
                    notes)
                  </Text>
                </View>
              </View>
            </View>
            <View style={{ gap: '4px' }}>
              <View
                style={{
                  ...styles.trueFalseChoiceRow,
                  justifyContent: 'space-between',
                }}
              >
                <View style={{ ...styles.trueFalseChoicewidth, width: '50%' }}>
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.racords.includes('transcribreports') && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>Transcribed hospital reports</Text>
                </View>
                <View style={{ ...styles.trueFalseChoicewidth, width: '50%' }}>
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.racords.includes('clinician') && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>Clinician office chart notes</Text>
                </View>
              </View>
            </View>
            <View style={{ gap: '4px' }}>
              <View
                style={{
                  ...styles.trueFalseChoiceRow,
                  justifyContent: 'space-between',
                }}
              >
                <View
                  style={{ ...styles.trueFalseChoiceContainer, width: '50%' }}
                >
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.racords.includes('medicalrecord') && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>Medical records needed for continuity of care</Text>
                </View>
                <View
                  style={{ ...styles.trueFalseChoiceContainer, width: '50%' }}
                >
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.racords.includes('dental') && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>Dental records</Text>
                </View>
              </View>
            </View>
            <View style={{ gap: '4px' }}>
              <View
                style={{
                  ...styles.trueFalseChoiceRow,
                  justifyContent: 'space-between',
                }}
              >
                <View style={{ ...styles.trueFalseChoicewidth, width: '50%' }}>
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.racords.includes('yearhistory') && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>Most recent five year history</Text>
                </View>
                <View style={{ ...styles.trueFalseChoicewidth, width: '50%' }}>
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.racords.includes('therapy') && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>Physical Therapy records</Text>
                </View>
              </View>
            </View>
            <View style={{ gap: '4px' }}>
              <View
                style={{
                  ...styles.trueFalseChoiceRow,
                  justifyContent: 'space-between',
                }}
              >
                <View
                  style={{ ...styles.trueFalseChoiceContainer, width: '50%' }}
                >
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.racords.includes('laboratory') && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>Laboratory reports</Text>
                </View>
                <View
                  style={{ ...styles.trueFalseChoiceContainer, width: '50%' }}
                >
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.racords.includes('care') && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>Emergency and urgency care records</Text>
                </View>
              </View>
            </View>
            <View style={{ gap: '4px' }}>
              <View
                style={{
                  ...styles.trueFalseChoiceRow,
                  justifyContent: 'space-between',
                }}
              >
                <View style={{ ...styles.trueFalseChoicewidth, width: '50%' }}>
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.racords.includes('pathology') && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>Pathology reports</Text>
                </View>
                <View style={{ ...styles.trueFalseChoicewidth, width: '50%' }}>
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.racords.includes('statement') && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>Billing Statements</Text>
                </View>
              </View>
            </View>
            <View style={{ gap: '4px' }}>
              <View
                style={{
                  ...styles.trueFalseChoiceRow,
                  justifyContent: 'space-between',
                }}
              >
                <View
                  style={{ ...styles.trueFalseChoiceContainer, width: '50%' }}
                >
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.racords.includes('diagnostic') && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>Diagnostic imaging reports</Text>
                </View>
                <View
                  style={{ ...styles.trueFalseChoiceContainer, width: '50%' }}
                >
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.racords.includes('Other') && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>Other</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={{ width: '100%', paddingTop: 4 }}>
          <View>
            <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
              Please send the entire medical record (all information) to the
              above named recipient. The recipient understands this record may
              be voluminous and agrees to pay all reasonable charges associated
              with providing this record.
            </Text>
          </View>
          <View
            style={{
              width: '100%',
              flexDirection: 'column',
              gap: '5px',
            }}
          >
            <View style={{ gap: '4px', paddingTop: 6 }}>
              <View
                style={{
                  ...styles.trueFalseChoiceRow,
                  justifyContent: 'space-between',
                }}
              >
                <View style={{ ...styles.trueFalseChoicewidth, width: '100%' }}>
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.racords.includes('hiv') && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>*HIV/AIDS-related records</Text>
                </View>
              </View>
            </View>
            <View style={{ gap: '4px' }}>
              <View
                style={{
                  ...styles.trueFalseChoiceRow,
                  justifyContent: 'space-between',
                }}
              >
                <View style={{ ...styles.trueFalseChoicewidth, width: '100%' }}>
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.racords.includes('genetic') && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <Text>*Genetic testing information</Text>
                </View>
              </View>
              <Text>
                *Must be specifically selected to be included in other
                documents.
              </Text>
            </View>
          </View>

          <View
            style={{
              width: '100%',
              flexDirection: 'column',
              gap: '5px',
              paddingTop: 8,
            }}
          >
            <View style={{ gap: '4px' }}>
              <View
                style={{
                  ...styles.trueFalseChoiceRow,
                  justifyContent: 'space-between',
                }}
              >
                <View style={{ ...styles.trueFalseChoicewidth, width: '100%' }}>
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.racords.includes('drug') && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <View
                    style={{ flexDirection: 'row', alignItems: 'flex-end' }}
                  >
                    <View>
                      <Text>
                        **Drug/Alcohol diagnosis, treatment or referral
                        information
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={{
                          width: '170px',
                          borderBottom: '1px solid #000',
                          textAlign: 'center',
                        }}
                      >
                        {fillableData.otherDrug ? fillableData.otherDrug : ''}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              <Text>
                **Federal Regulation 42 CFR Part 2, requires a description of
                how much and what kind of information is to be disclosed.
              </Text>
            </View>
          </View>

          <View
            style={{
              width: '100%',
              flexDirection: 'column',
              gap: '5px',
              paddingTop: 8,
            }}
          >
            <View style={{ gap: '4px' }}>
              <View
                style={{
                  ...styles.trueFalseChoiceRow,
                  justifyContent: 'space-between',
                }}
              >
                <View style={{ ...styles.trueFalseChoicewidth, width: '100%' }}>
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.federal?.includes('treatment') && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <View
                    style={{ flexDirection: 'row', alignItems: 'flex-end' }}
                  >
                    <View>
                      <Text>
                        This authorization is limited to the following treatment
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={{
                          width: '190px',
                          borderBottom: '1px solid #000',
                          textAlign: 'center',
                        }}
                      >
                        {fillableData.otherFederal
                          ? fillableData.otherFederal
                          : ''}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  ...styles.trueFalseChoiceRow,
                  justifyContent: 'space-between',
                }}
              >
                <View style={{ ...styles.trueFalseChoicewidth, width: '100%' }}>
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.period?.includes('limited') && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <View
                    style={{ flexDirection: 'row', alignItems: 'flex-end' }}
                  >
                    <View>
                      <Text>
                        This authorization is limited to the time period
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={{
                          width: '224px',
                          borderBottom: '1px solid #000',
                          textAlign: 'center',
                        }}
                      >
                        {fillableData.otherperiod
                          ? fillableData.otherperiod
                          : ''}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  ...styles.trueFalseChoiceRow,
                  justifyContent: 'space-between',
                }}
              >
                <View style={{ ...styles.trueFalseChoicewidth, width: '100%' }}>
                  <View style={styles.stylesCheckImage}>
                    {fillableData?.injuries?.includes('worker') && (
                      <Image
                        style={{
                          ...styles.imgcheck,
                        }}
                        src="/check.png"
                      />
                    )}
                  </View>
                  <View
                    style={{ flexDirection: 'row', alignItems: 'flex-end' }}
                  >
                    <View>
                      <Text>
                        This authorization is limited to a worker's compensation
                        claim for injuries of
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={{
                          width: '95px',
                          borderBottom: '1px solid #000',
                          textAlign: 'center',
                        }}
                      >
                        {fillableData.otherinjuries
                          ? fillableData.otherinjuries
                          : ''}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              <Text
                style={{
                  lineHeight: 1.25,
                  textAlign: 'justify',
                  paddingTop: 6,
                }}
              >
                This authorization may be revoked at any time. The only
                exception is when action has been taken in reliance on the
                authorization, unless revoked earlier, this consent will expire
                180 days from the date of signing or shall remain in effect for
                the period reasonably needed to complete the request.
              </Text>
            </View>
          </View>
          <View
            style={{
              width: '100%',
              flexDirection: 'column',
              gap: '5px',
              paddingTop: 8,
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '55px',
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                  paddingBottom: '2px',
                }}
              >
                <Text style={{ width: '175px', textAlign: 'center' }}>
                  {fillableData?.createdAt
                    ? moment(fillableData?.createdAt).format('MM/DD/YYYY')
                    : ''}
                </Text>
                <View
                  style={{
                    width: '175px',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {fillableData.signaturePatient.includes('data:image/') ? (
                    <Image
                      style={{
                        width: '115px',
                      }}
                      src={fillableData.signaturePatient}
                    />
                  ) : (
                    <Text style={{ textAlign: 'center', paddingTop: 12 }}>
                      {fillableData.signaturePatient}
                    </Text>
                  )}
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'space-between',
                  paddingTop: '2px',
                }}
              >
                <Text
                  style={{
                    width: '175px',
                    textAlign: 'center',
                    borderTop: '1px solid #000',
                    paddingTop: 3,
                  }}
                >
                  (Date)
                </Text>
                <Text
                  style={{
                    width: '175px',
                    textAlign: 'center',
                    borderTop: '1px solid #000',
                    paddingTop: 3,
                  }}
                >
                  (Signature of patient)
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              width: '100%',
              flexDirection: 'column',
              gap: '5px',
              paddingTop: 8,
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '55px',
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                  paddingBottom: '2px',
                }}
              >
                <Text style={{ width: '175px', textAlign: 'center' }}>
                  {fillableData?.createdAt
                    ? moment(fillableData?.createdAt).format('MM/DD/YYYY')
                    : ''}
                </Text>
                <View
                  style={{
                    width: '175px',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {fillableData.signaturePatientLaw.includes('data:image/') ? (
                    <Image
                      style={{
                        width: '115px',
                      }}
                      src={fillableData.signaturePatientLaw}
                    />
                  ) : (
                    <Text style={{ textAlign: 'center' }}>
                      {fillableData.signaturePatientLaw}
                    </Text>
                  )}
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'space-between',
                  paddingTop: '2px',
                }}
              >
                <Text
                  style={{
                    width: '175px',
                    textAlign: 'center',
                    borderTop: '1px solid #000',
                    paddingTop: 3,
                  }}
                >
                  (Date)
                </Text>
                <Text
                  style={{
                    width: '175px',
                    textAlign: 'center',
                    borderTop: '1px solid #000',
                    paddingTop: 3,
                  }}
                >
                  (Signature of person authorized by law)
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

export default SecondSignatureCancellationNonWeb
