import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import {
    Flex,
    Box,
    Container,
    Spacer,
    Button,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Spinner,
    HStack,
    Stack,
    Input,
    useToast,
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { BsFillTrashFill, BsCloudUpload, BsSearch } from 'react-icons/bs'
import { doc, onSnapshot, collection, orderBy, query, addDoc, deleteDoc } from 'firebase/firestore'
import { ref, getDownloadURL, uploadBytes, deleteObject } from 'firebase/storage'
import _ from 'underscore'
import renderDate from '../../config/renderDate'
import { storage, db } from '../../config/firebase'

function Schedule({ user }) {
    const toast = useToast()

    const [schedule, setSchedule] = useState([])
    const [isLoading, setLoading] = useState(true)

    const hiddenFileInput = useRef()
    const [uploadProgress, setUploadProgress] = useState(false)

    useEffect(() => {
        const queryStaffSchedule = query(collection(db, "StaffSchedule"), orderBy("createdAt", "desc"))
        let unsubscribe = onSnapshot(queryStaffSchedule, (querySnapshot) => {
            let schedule = []
            querySnapshot.forEach((doc) => {
                schedule.push({ id: doc.id, ...doc.data() })
            })
            setSchedule(schedule)
            setLoading(false)
        })
        return () => {
            unsubscribe()
        }
    }, [user])

    function handleUploadClick() {
        hiddenFileInput.current.click()
    }

    async function handleUploadChange(e) {
        if (_.contains(user.roles, "Scheduler")) {
            setUploadProgress(true)
            setLoading(true)
            const fileUploaded = e.target.files[0]
            if (fileUploaded) {
                const scheduleRef = ref(storage, `Schedule/${fileUploaded.name}`)
                uploadBytes(scheduleRef, fileUploaded).then(async () => {
                    const downloadURL = await getDownloadURL(scheduleRef)
                    addDoc(collection(db, "StaffSchedule"), { createdAt: new Date(), createdBy: user.uid, name: fileUploaded.name, url: downloadURL })
                        .then(() => {
                            setUploadProgress(false)
                            setLoading(false)
                            toast({
                                title: "Upload Successfully.",
                                description: "Schedule has been uploaded.",
                                status: "success",
                                duration: 3000,
                                isClosable: true,
                            })
                        })
                })
            } else {
                setUploadProgress(false)
                setLoading(false)
            }

        }
    }

    return (
        <Container maxW="100%" mt="5">
            <Breadcrumb
                spacing="8px"
                separator={<ChevronRightIcon color="gray.500" />}
                bg="gray.100" p="1.5"
                borderRadius="0.3em"
            >
                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to="/dashboard">Dashbaord</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to="/shedule">Schedule</BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>
            <Flex mt="5">
                <Box pt="2" fontSize="18" fontWeight="bold">
                    Schedule
                </Box>
                <Spacer />
                {
                    _.contains(user.roles, "Scheduler") &&
                    <HStack>
                        <Input type="file" display="none" ref={hiddenFileInput} onChange={handleUploadChange} />
                        <Button leftIcon={<BsCloudUpload />} onClick={handleUploadClick} size="md" disabled={uploadProgress} isLoading={uploadProgress} loadingText="Uploading...">Upload</Button>
                    </HStack>
                }

            </Flex>
            <Box overflow="scroll">
                <Table mt="10" mb="20">
                    <Thead>
                        <Tr>
                            <Th width={["30%", "20%"]}>Date</Th>
                            <Th width={["30%", "60%"]}>Schedule</Th>
                            <Th width={["30%", "10%"]} textAlign="center">Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            isLoading ?
                                <Tr>
                                    <Td colSpan="4" textAlign="center">
                                        <Spinner
                                            thickness="4px"
                                            speed="0.65s"
                                            emptyColor="gray.200"
                                            color="blue.500"
                                            size="xl"
                                        />
                                    </Td>
                                </Tr>
                                : schedule.map((value, index) => (
                                    <Tr key={index}>
                                        <Td>{renderDate(value.createdAt)}</Td>
                                        <Td>{value.name}</Td>
                                        <Td>
                                            <Stack>
                                                <Button onClick={() => { window.open(`${value.url}`, '_blank') }} w={["100%"]} size="sm" leftIcon={<BsSearch />}>View</Button>
                                                {
                                                    _.contains(user.roles, "Scheduler") &&
                                                    <Button onClick={() => {
                                                        setLoading(true)
                                                        deleteDoc(doc(db, "StaffSchedule", value.id)).then(() => {
                                                            setLoading(false)
                                                            let scheduleRef = ref(storage, `Schedule/${value.name}`)
                                                            deleteObject(scheduleRef)
                                                        })
                                                    }} w={["100%"]} size="sm" leftIcon={<BsFillTrashFill />}>Remove</Button>
                                                }
                                            </Stack>
                                        </Td>
                                    </Tr>
                                ))
                        }
                    </Tbody>
                </Table>
            </Box>
        </Container>
    )
}

export default Schedule