import React, { useEffect, useRef, useState } from 'react'
import {
  Center,
  Box,
  Input,
  FormLabel,
  FormControl,
  Flex,
  Radio,
  HStack,
  Grid,
  RadioGroup,
  Text,
  Checkbox,
  CheckboxGroup,
  Button,
  useToast,
} from '@chakra-ui/react'
import { CardContent } from '../../../../../../Components/Card/card'
import { Controller, useForm } from 'react-hook-form'
import moment from 'moment'
import { doc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../../../config/firebase'
import { totalNonWebEnabledPage } from '../..'
const _ = require('underscore')

function NewPatientAdmission({ formView }) {
  const {
    handleSubmit,
    // reset,
    // register,
    // errors,
    control,
    setValue,
    // clearErrors,
  } = useForm()
  const [isLoading, setIsLoading] = useState(false)
  // console.clear()
  // console.log(formView)

  const myRef = useRef(null)
  const toast = useToast()

  function onSubmit(data) {
    setIsLoading(true)
    if (data) {
      return updateDoc(doc(db, 'Form', formView?.id), {
        heartRate: data.heartRate,
        bloodPressure: data.bloodPressure,
        temp: data.temp,
        pulse: data.pulse,
        oxFrom2: data.oxFrom2,
        respFrom2: data.respFrom2,
      })
        .then(() => {
          setIsLoading(false)
          toast({
            position: 'bottom',
            title: 'Updated successfully.',
            description: '',
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
        })
        .catch(() => {
          setIsLoading(false)
        })
    }
  }

  useEffect(() => {
    if (formView) {
      const data = formView
      _.keys(data).map(e => {
        return setValue(e, data[e])
      })
    }
  }, [formView, setValue])
  return (
    <CardContent>
      <Box position="fixed" top="70px" right="10px" zIndex="overlay">
        <Button
          onClick={() =>
            window.scrollTo({
              top: myRef.current.offsetTop,
              behavior: 'smooth',
            })
          }
          colorScheme="blue"
          px="0.5rem"
          _focus={{ border: '0px solid transparent' }}
        >
          For Office
        </Button>
      </Box>
      <Box w="100%">
        <Flex
          w="100%"
          pb="10px"
          direction={{ base: 'column', sm: 'column', md: 'row', lg: 'row' }}
        >
          <Box w="100%" pr={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}>
            <Controller
              name="namePatient"
              control={control}
              defaultValue=""
              render={props => (
                <FormControl>
                  <FormLabel>Name</FormLabel>
                  <Input
                    _readOnly={{
                      border: '1px solid #ccc',
                    }}
                    variant="no-effects"
                    readOnly
                    bg="#F9F9F9"
                    border="1px solid black"
                    w={{
                      base: '100%',
                      sm: '100%',
                      md: '100%',
                    }}
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                  />
                </FormControl>
              )}
            />
          </Box>
          <Box
            w="100%"
            pt={{ base: '10px', md: '0px' }}
            pl={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}
          >
            <Controller
              name="DOB"
              control={control}
              defaultValue={moment(new Date()).format('yyyy-MM-DD')}
              render={props => (
                <FormControl>
                  <FormLabel>DOB (mm/dd/yy)</FormLabel>
                  <Input
                    _readOnly={{
                      border: '1px solid #ccc',
                    }}
                    variant="no-effects"
                    readOnly
                    bg="#F9F9F9"
                    border="1px solid black"
                    w={{
                      base: '100%',
                      sm: '100%',
                      md: '100%',
                    }}
                    type="date"
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                  />
                </FormControl>
              )}
            />
          </Box>
        </Flex>

        <Box>
          <Text pt="30px" pb="10px" fontWeight="bold">
            New Patient Intake Form, Page 1 of 3
          </Text>
          <Text pl="10px" fontSize="14px">
            The pain is located mainly at the: (check one)
          </Text>

          <Controller
            name="raceFrom2"
            control={control}
            defaultValue={formView.raceFrom2}
            render={props => (
              <FormControl>
                <RadioGroup
                  onChange={props.onChange}
                  value={props.value}
                  onBlur={props.onBlur}
                  w="100%"
                  isDisabled
                >
                  <Grid
                    w="100%"
                    templateColumns={{
                      base: 'repeat(1,1fr)',
                      sm: 'repeat(2,1fr)',
                      md: 'repeat(2,1fr)',
                      lg: 'repeat(3, 1fr)',
                      '2xl': 'repeat(5, 1fr)',
                    }}
                    pt="10px"
                    gap={3}
                  >
                    <Radio value="neck">Neck</Radio>
                    <Radio value="midback">Mid Back</Radio>
                    <Radio value="lowback">Low Back</Radio>
                    <Radio value="shoulder">Shoulder</Radio>
                    <Radio value="hip">Hip</Radio>
                    <Radio value="knee">Knee</Radio>{' '}
                    <HStack w="100%" pt="10px">
                      <Radio value="other1" />
                      <Controller
                        name="otherLocat"
                        control={control}
                        defaultValue=""
                        render={props => (
                          <FormControl>
                            <Input
                              _readOnly={{
                                border: '1px solid #ccc',
                              }}
                              variant="no-effects"
                              readOnly
                              bg="#F9F9F9"
                              border="1px solid black"
                              w={{
                                base: '100%',
                                sm: '100%',
                                md: '100%',
                              }}
                              placeholder="other"
                              value={props.value}
                              onChange={props.onChange}
                              onBlur={props.onBlur}
                            />
                          </FormControl>
                        )}
                      />
                    </HStack>
                  </Grid>
                </RadioGroup>
              </FormControl>
            )}
          />
        </Box>
        <Box>
          <Text pl="10px" pt="30px" pb="10px" fontSize="14px" fontWeight="bold">
            The pain feels like:{` `}
            <span style={{ py: '1', fontWeight: 'normal' }}>
              {`(check all that apply)`}
            </span>
          </Text>
          <Controller
            name="feels"
            control={control}
            defaultValue={formView.feels}
            render={props => (
              <FormControl>
                <CheckboxGroup
                  // onChange={e => {
                  //   setValue('feels', e)
                  // }}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                  value={props.value}
                  w="100%"
                  spacing="0px"
                  isDisabled
                >
                  <Grid
                    w="100%"
                    templateColumns={{
                      base: 'repeat(1,1fr)',
                      sm: 'repeat(1,1fr)',
                      md: 'repeat(2,1fr)',
                      lg: 'repeat(2, 1fr)',
                      xl: 'repeat(3, 1fr)',
                    }}
                    gap={4}
                  >
                    <Checkbox pr="0.5rem" value="sharp">
                      Sharp/stabbing
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="dull">
                      Dull/achy
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="spasms">
                      Like muscle spasms
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="burning">
                      Burning
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="numbness">
                      Like numbness/tingling
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="feelsOther">
                      Other
                    </Checkbox>
                  </Grid>
                </CheckboxGroup>
              </FormControl>
            )}
          />
        </Box>
        <Box>
          <Text pl="10px" pt="30px" pb="10px" fontSize="14px" fontWeight="bold">
            Does the pain radiate or travel?{` `}
            <span style={{ py: '1', fontWeight: 'normal' }}>
              {`(check one)`}
              {` `}
            </span>
          </Text>
          <Controller
            name="travel"
            control={control}
            defaultValue={formView?.travel || ''}
            render={props => (
              <FormControl>
                <RadioGroup
                  defaultValue={props.value}
                  onChange={props.onChange}
                  value={props.value}
                  onBlur={props.onBlur}
                  w="100%"
                  isDisabled
                >
                  <Flex
                    w="100%"
                    direction={{
                      base: 'column',
                      sm: 'column',
                      md: 'column',
                      lg: 'row',
                    }}
                  >
                    <Radio pr="0.5rem" value="travelYes" mr="50px">
                      Yes
                    </Radio>
                    <Flex
                      pt="10px"
                      direction={{
                        base: 'column',
                        sm: 'row',
                        md: 'row',
                        lg: 'row',
                      }}
                    >
                      <Radio
                        w="100%"
                        value="travelNo"
                        pr={{ base: '0px', lg: '5px' }}
                        pb={{ base: '10px', sm: '0px' }}
                      >
                        {`No (if yes, where?)`}
                      </Radio>
                      <Controller
                        name="otherTravel"
                        control={control}
                        defaultValue=""
                        render={props => (
                          <FormControl>
                            <Input
                              _readOnly={{
                                border: '1px solid #ccc',
                              }}
                              variant="no-effects"
                              readOnly
                              bg="#F9F9F9"
                              border="1px solid black"
                              w={{
                                base: '100%',
                                sm: '100%',
                                md: 'max-content',
                              }}
                              value={props.value}
                              onChange={props.onChange}
                              onBlur={props.onBlur}
                            />
                          </FormControl>
                        )}
                      />
                    </Flex>
                  </Flex>
                </RadioGroup>
              </FormControl>
            )}
          />
        </Box>
        <Box>
          <Text pl="10px" pt="30px" pb="10px" fontSize="14px" fontWeight="bold">
            Do you have areas of numbness?{` `}
            <span style={{ py: '1', fontWeight: 'normal' }}>
              {`(check one)`}
              {` `}
            </span>
          </Text>
          <Controller
            name="numbness"
            control={control}
            defaultValue={formView?.numbness || ''}
            render={props => (
              <FormControl>
                <RadioGroup
                  defaultValue={props.value}
                  onChange={props.onChange}
                  onblur={props.onBlur}
                  value={props.value}
                  w="100%"
                  isDisabled
                >
                  <Flex
                    w="100%"
                    direction={{
                      base: 'column',
                      sm: 'column',
                      md: 'column',
                      lg: 'row',
                    }}
                  >
                    <Radio pr="0.5rem" value="numbnessYes" mr="50px">
                      Yes
                    </Radio>
                    <Flex
                      direction={{
                        base: 'column',
                        sm: 'row',
                        md: 'row',
                        lg: 'row',
                      }}
                      pt="10px"
                    >
                      <Radio
                        w="100%"
                        value="numbnessNo"
                        pr={{ base: '0px', lg: '5px' }}
                        pb={{ base: '10px', sm: '0px' }}
                      >
                        No (if yes, where?)
                      </Radio>
                      <Controller
                        name="otherNumbness"
                        control={control}
                        defaultValue=""
                        render={props => (
                          <FormControl>
                            <Input
                              _readOnly={{
                                border: '1px solid #ccc',
                              }}
                              variant="no-effects"
                              readOnly
                              bg="#F9F9F9"
                              border="1px solid black"
                              w={{
                                base: '100%',
                                sm: '100%',
                                md: 'max-content',
                              }}
                              value={props.value}
                              onChange={props.onChange}
                              onBlur={props.onBlur}
                            />
                          </FormControl>
                        )}
                      />
                    </Flex>
                  </Flex>
                </RadioGroup>
              </FormControl>
            )}
          />
        </Box>
        <Box>
          <Text pl="10px" pt="30px" pb="10px" fontSize="14px" fontWeight="bold">
            Do you have areas of weakness{` `}{' '}
            <span style={{ py: '1', fontWeight: 'normal' }}>
              {`(check one)`}
              {` `}
            </span>
          </Text>
          <Controller
            name="weakness"
            control={control}
            defaultValue={formView.weakness || ''}
            render={props => (
              <FormControl>
                <RadioGroup
                  defaultValue={props.value}
                  onChange={props.onChange}
                  value={props.value}
                  onBlur={props.onBlur}
                  w="100%"
                  isDisabled
                >
                  <Flex
                    w="100%"
                    direction={{
                      base: 'column',
                      sm: 'column',
                      md: 'column',
                      lg: 'row',
                    }}
                  >
                    <Radio pr="0.5rem" value="Yes" mr="50px">
                      Yes
                    </Radio>
                    <Flex
                      direction={{
                        base: 'column',
                        sm: 'row',
                        md: 'row',
                        lg: 'row',
                      }}
                      py="10px"
                    >
                      <Radio
                        w="100%"
                        value="No"
                        pr={{ base: '0px', lg: '5px' }}
                        pb={{ base: '10px', sm: '0px' }}
                      >
                        {`No (if yes, where?)`}
                      </Radio>
                      <Controller
                        name="otherWeakness"
                        control={control}
                        defaultValue={formView.otherWeakness}
                        render={props => (
                          <FormControl>
                            <Input
                              _readOnly={{
                                border: '1px solid #ccc',
                              }}
                              variant="no-effects"
                              readOnly
                              bg="#F9F9F9"
                              border="1px solid black"
                              w={{
                                base: '100%',
                                sm: '100%',
                                md: 'max-content',
                              }}
                              value={props.value}
                              onChange={props.onChange}
                              onBlur={props.onBlur}
                            />
                          </FormControl>
                        )}
                      />
                    </Flex>
                  </Flex>
                </RadioGroup>
              </FormControl>
            )}
          />
        </Box>
        <Box>
          <Text pl="10px" pt="30px" pb="10px" fontSize="14px" fontWeight="bold">
            The pain started:{` `}
            <span style={{ py: '1', fontWeight: 'normal' }}>
              {`(check one)`}
            </span>
          </Text>
          <Controller
            name="started"
            control={control}
            render={props => (
              <FormControl>
                <CheckboxGroup
                  onChange={e => {
                    setValue('started', e)
                    // clearErrors("started")
                  }}
                  onBlur={props.onBlur}
                  value={props.value}
                  w="100%"
                  spacing="0px"
                  isDisabled
                >
                  <Grid
                    w="100%"
                    templateColumns={{
                      base: 'repeat(1,1fr)',
                      sm: 'repeat(1,1fr)',
                      md: 'repeat(1,1fr)',
                      lg: 'repeat(2, 1fr)',
                      xl: 'repeat(3, 1fr)',
                    }}
                    gap={4}
                  >
                    <Checkbox pr="0.5rem" value="less6">
                      Less than 6 months ago{' '}
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="more6m">
                      More than 6 months ago
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="more1y">
                      More than 1 year ago
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="more5y">
                      More than 5 years ago{' '}
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="more10y">
                      More than 10 years ago{' '}
                    </Checkbox>
                  </Grid>
                </CheckboxGroup>
              </FormControl>
            )}
          />
        </Box>
        <Box>
          <Text pl="10px" pt="30px" pb="10px" fontSize="14px" fontWeight="bold">
            Is this pain related to an accident?{` `}
            <span style={{ py: '1', fontWeight: 'normal' }}>
              {`(check one)`}
            </span>
          </Text>
          <Controller
            name="accident"
            control={control}
            defaultValue=""
            render={props => (
              <FormControl>
                <RadioGroup
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                  value={props.value}
                  w="100%"
                  isDisabled
                >
                  <HStack>
                    <Radio pr="0.5rem" value="accidentYes" mr="50px">
                      Yes
                    </Radio>
                    <Radio pr="0.5rem" value="accidentNo">
                      No
                    </Radio>
                    <Input
                      name="otherAccident"
                      type="text"
                      defaultValue={formView.otherAccident || ''}
                      _readOnly={{
                        border: '1px solid #ccc',
                      }}
                      variant="no-effects"
                      readOnly
                      bg="#F9F9F9"
                      border="1px solid black"
                      w={{
                        base: '100%',
                        sm: '100%',
                        md: 'max-content',
                      }}
                    />
                  </HStack>
                </RadioGroup>
              </FormControl>
            )}
          />
        </Box>
        <Box>
          <Text pl="10px" pt="30px" pb="10px" fontSize="14px" fontWeight="bold">
            Is this pain related to a work injury?{` `}
            <span style={{ py: '1', fontWeight: 'normal' }}>
              {`(check one)`}
            </span>
          </Text>
          <Controller
            name="work"
            control={control}
            defaultValue=""
            render={props => (
              <FormControl>
                <RadioGroup
                  onChange={props.onChange}
                  value={props.value}
                  onBlur={props.onBlur}
                  w="100%"
                  isDisabled
                >
                  <HStack>
                    <Radio pr="0.5rem" value="workYes" mr="50px">
                      Yes
                    </Radio>
                    <Radio pr="0.5rem" value="workNo">
                      No
                    </Radio>
                    <Input
                      name="otherWork"
                      type="text"
                      defaultValue={formView.otherWork || ''}
                      _readOnly={{
                        border: '1px solid #ccc',
                      }}
                      variant="no-effects"
                      readOnly
                      bg="#F9F9F9"
                      border="1px solid black"
                      w={{
                        base: '100%',
                        sm: '100%',
                        md: 'max-content',
                      }}
                    />
                  </HStack>
                </RadioGroup>
              </FormControl>
            )}
          />
        </Box>
        <Box>
          <Text pl="10px" pt="30px" pb="10px" fontSize="14px" fontWeight="bold">
            Is this pain related to a surgery?{` `}
            <span style={{ py: '1', fontWeight: 'normal' }}>
              {`(check one)`}
            </span>
          </Text>
          <Controller
            name="surgery"
            control={control}
            defaultValue=""
            render={props => (
              <FormControl>
                <RadioGroup
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                  value={props.value}
                  w="100%"
                  isDisabled
                >
                  <HStack>
                    <Radio pr="0.5rem" value="surgeryYes" mr="50px">
                      Yes
                    </Radio>
                    <Radio pr="0.5rem" value="surgeryNo">
                      No
                    </Radio>
                    <Input
                      name="otherSurgery"
                      type="text"
                      defaultValue={formView.otherSurgery||''}
                      _readOnly={{
                        border: '1px solid #ccc',
                      }}
                      variant="no-effects"
                      readOnly
                      bg="#F9F9F9"
                      border="1px solid black"
                      w={{
                        base: '100%',
                        sm: '100%',
                        md: 'max-content',
                      }}
                    />
                  </HStack>
                </RadioGroup>
              </FormControl>
            )}
          />
        </Box>
        <Box>
          <Text pl="10px" pt="30px" pb="10px" fontSize="14px" fontWeight="bold">
            The pain increases with:{` `}
            <span style={{ py: '1', fontWeight: 'normal' }}>
              {`(check all that apply)`}
            </span>
          </Text>
          <Controller
            name="increases"
            control={control}
            render={props => (
              <FormControl>
                <CheckboxGroup
                  onChange={e => {
                    setValue('increases', e)
                    // clearErrors("increases")
                  }}
                  onBlur={props.onBlur}
                  value={props.value}
                  w="100%"
                  spacing="0px"
                  isDisabled
                >
                  <Grid
                    w="100%"
                    templateColumns={{
                      base: 'repeat(1,1fr)',
                      sm: 'repeat(2,1fr)',
                      md: 'repeat(2,1fr)',
                      lg: 'repeat(2, 1fr)',
                      xl: 'repeat(3, 1fr)',
                    }}
                    gap={4}
                  >
                    <Checkbox pr="0.5rem" value="insit">
                      Sitting{' '}
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="inwalk">
                      Walking
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="instand">
                      Standing
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="infor">
                      Bending forward
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="inback">
                      Bending backward
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="inup">
                      Looking up
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="indown">
                      Looking down
                    </Checkbox>
                  </Grid>
                </CheckboxGroup>
              </FormControl>
            )}
          />
        </Box>
        <Box>
          <Text pl="10px" pt="30px" pb="10px" fontSize="14px" fontWeight="bold">
            The pain decreases with:{` `}
            <span style={{ py: '1', fontWeight: 'normal' }}>
              {`(check all that apply)`}
            </span>
          </Text>
          <Controller
            name="decreases"
            control={control}
            render={props => (
              <FormControl>
                <CheckboxGroup
                  onChange={e => {
                    setValue('decreases', e)
                    // clearErrors("decreases")
                  }}
                  onBlur={props.onBlur}
                  value={props.value}
                  w="100%"
                  spacing="0px"
                  isDisabled
                >
                  <Grid
                    w="100%"
                    templateColumns={{
                      base: 'repeat(1,1fr)',
                      sm: 'repeat(1,1fr)',
                      md: 'repeat(2,1fr)',
                      lg: 'repeat(2, 1fr)',
                      '2xl': 'repeat(4, 1fr)',
                    }}
                    gap={4}
                  >
                    <Checkbox value="decrest">Rest</Checkbox>
                    <Checkbox value="decmedic">Medication</Checkbox>
                    <Checkbox value="decexer">
                      <Text w="100%">Exercise/physical therapy</Text>
                    </Checkbox>
                    <Checkbox value="decpro">Procedures</Checkbox>
                  </Grid>
                </CheckboxGroup>
              </FormControl>
            )}
          />
        </Box>
        <Box>
          <Text pl="10px" pt="30px" pb="10px" fontSize="14px" fontWeight="bold">
            Medications:{` `}
            <span style={{ py: '1', fontWeight: 'normal' }}>
              {`(check one)`}
            </span>
          </Text>
          <Controller
            name="medications"
            control={control}
            render={props => (
              <FormControl>
                <CheckboxGroup
                  onChange={e => {
                    setValue('medications', e)
                    // clearErrors("medications")
                  }}
                  onBlur={props.onBlur}
                  value={props.value}
                  w="100%"
                  spacing="0px"
                  isDisabled
                >
                  <Grid
                    w="100%"
                    templateColumns={{
                      base: 'repeat(1,1fr)',
                      sm: 'repeat(1,1fr)',
                      md: 'repeat(1,1fr)',
                      lg: 'repeat(2, 1fr)',
                      '2xl': 'repeat(3, 1fr)',
                    }}
                    gap={{ base: 3, lg: 4 }}
                  >
                    <Checkbox value="medhelp">
                      Do help relieve my pain{' '}
                    </Checkbox>
                    <Checkbox value="mednot">
                      Do not help relieve my pain{' '}
                    </Checkbox>
                    <Checkbox value="medeffect">Cause side effects</Checkbox>
                  </Grid>
                </CheckboxGroup>
              </FormControl>
            )}
          />
        </Box>
        <Box>
          <Text pl="10px" pt="30px" pb="10px" fontSize="14px" fontWeight="bold">
            Medications tried:{` `}
            <span style={{ py: '1', fontWeight: 'normal' }}>
              {`(check all that apply)`}
            </span>
          </Text>
          <Controller
            name="medicationsTried"
            control={control}
            render={props => (
              <FormControl>
                <CheckboxGroup
                  onChange={e => {
                    setValue('medicationsTried', e)
                    // clearErrors("medicationsTried")
                  }}
                  onBlur={props.onBlur}
                  value={props.value}
                  w="100%"
                  spacing="0px"
                  isDisabled
                >
                  <Grid
                    w="100%"
                    templateColumns={{
                      base: 'repeat(2,1fr)',
                      sm: 'repeat(2,1fr)',
                      md: 'repeat(2,1fr)',
                      lg: 'repeat(3, 1fr)',
                      xl: 'repeat(4,1fr)',
                    }}
                    gap={4}
                  >
                    <Checkbox pr="0.5rem" value="tIbuprofen">
                      Ibuprofen
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="tTylenol">
                      Tylenol
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="tNortriptyline">
                      Nortriptyline
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="tAmitriptyline">
                      Amitriptyline
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="tGabapentin ">
                      Gabapentin
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="tLyrica">
                      Lyrica
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="tFlexeril">
                      Flexeril
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="tRobaxin">
                      Robaxin
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="tZanaflex ">
                      Zanaflex
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="tSkelaxin">
                      Skelaxin
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="tHydrocodone">
                      Hydrocodone
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="tOxycodone">
                      Oxycodone
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="tOxycontin">
                      Oxycontin
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="tMorphine">
                      Morphine
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="tMS">
                      MS Contin
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="tMethadone">
                      Methadone
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="tDilaudid">
                      Dilaudid
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="tSuboxone">
                      Suboxone
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="tNucynta">
                      Nucynta
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="tFentanyl">
                      Fentanyl
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="tOpana">
                      Opana
                    </Checkbox>
                    <HStack>
                      <Checkbox value="Other"></Checkbox>
                      <Controller
                        name="otherLocat2"
                        control={control}
                        defaultValue=""
                        render={props => (
                          <FormControl>
                            <Input
                              _readOnly={{
                                border: '1px solid #ccc',
                              }}
                              variant="no-effects"
                              readOnly
                              bg="#F9F9F9"
                              border="1px solid black"
                              w={{
                                base: '100%',
                                sm: '100%',
                                md: 'auto',
                              }}
                              placeholder="Other"
                              value={props.value}
                              onChange={props.onChange}
                              onBlur={props.onBlur}
                            />
                          </FormControl>
                        )}
                      />
                    </HStack>
                  </Grid>
                </CheckboxGroup>
              </FormControl>
            )}
          />
        </Box>
        <Box>
          <Text pl="10px" pt="30px" pb="10px" fontSize="14px" fontWeight="bold">
            Are you currently taking an anticoagulant?{` `}{' '}
            <span style={{ py: '1', fontWeight: 'normal' }}>
              {`(check one)`}
            </span>
          </Text>
          <Controller
            name="anticoagulant"
            control={control}
            defaultValue=""
            render={props => (
              <FormControl>
                <RadioGroup
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                  value={props.value}
                  w="100%"
                  isDisabled
                >
                  <Radio pr="0.5rem" value="antiYes" mr="50px">
                    Yes
                  </Radio>
                  <Radio pr="0.5rem" value="antiNo">
                    No
                  </Radio>
                </RadioGroup>
              </FormControl>
            )}
          />
        </Box>
        <Box>
          <Text pl="10px" pt="30px" pb="10px" fontSize="14px" fontWeight="bold">
            Indicates options therapies tried:{` `}
            <span style={{ py: '1', fontWeight: 'normal' }}>
              {`(Check all that apply)`}
            </span>
          </Text>
          <Controller
            name="alternate"
            control={control}
            render={props => (
              <FormControl>
                <CheckboxGroup
                  onChange={e => {
                    setValue('alternate', e)
                    // clearErrors("alternate")
                  }}
                  onBlur={props.onBlur}
                  value={props.value}
                  w="100%"
                  spacing="0px"
                  isDisabled
                >
                  <Grid
                    w="100%"
                    templateColumns={{
                      base: 'repeat(1,1fr)',
                      sm: 'repeat(1,1fr)',
                      md: 'repeat(2,1fr)',
                      lg: 'repeat(2, 1fr)',
                      xl: 'repeat(3, 1fr)',
                    }}
                    gap={3}
                  >
                    <Checkbox pr="0.5rem" value="altNone">
                      None
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="altPhysical">
                      Physical therapy
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="altMassage">
                      Massage
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="altAcupuncture">
                      Acupuncture
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="altChiropractic">
                      Chiropractic treatments
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="altTens">
                      TENS unit
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="altPain">
                      Pain psychology/therapy
                    </Checkbox>
                  </Grid>
                </CheckboxGroup>
              </FormControl>
            )}
          />
        </Box>
        <Box>
          <Text pl="10px" pt="30px" pb="10px" fontSize="14px" fontWeight="bold">
            Procedures previously tried related to your current or past pain:
            {` `}
            <span style={{ py: '1', fontWeight: 'normal' }}>
              {`(Check all that apply)`}
            </span>
          </Text>
          <Controller
            name="alternate2"
            control={control}
            render={props => (
              <FormControl>
                <CheckboxGroup
                  onChange={e => {
                    setValue('alternate2', e)
                    // clearErrors("alternate2")
                  }}
                  value={props.value}
                  onBlur={props.onBlur}
                  w="100%"
                  spacing="0px"
                  isDisabled
                >
                  <Grid
                    w="100%"
                    templateColumns={{
                      base: 'repeat(1,1fr)',
                      sm: 'repeat(1,1fr)',
                      md: 'repeat(1,1fr)',
                      lg: 'repeat(2, 1fr)',
                      xl: 'repeat(3, 1fr)',
                    }}
                    gap={3}
                  >
                    <Checkbox pr="0.5rem" value="altNone2">
                      None
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="altEpidural">
                      Epidural steroid injection
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="altFacet">
                      Facet joint injection
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="altRadio">
                      Radiofrequency ablation
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="altTrigger">
                      Trigger point injections
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="altJoint">
                      Joint injection
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="altSpinal">
                      Spinal cord stimulator
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="altBotox">
                      Botox injection
                    </Checkbox>
                  </Grid>
                </CheckboxGroup>
              </FormControl>
            )}
          />
        </Box>
        <Box ref={myRef}></Box>
        <Box
          bgImage={`url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23B7DDE8FF' stroke-width='3' stroke-dasharray='19%2c 16' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e")`}
          p="15px"
          borderRadius="16px"
          mt="30px"
          w="100%"
        >
          <Text pl="10px" pb="20px" fontSize="14px" fontWeight="bold">
            Office Use Only:
          </Text>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex
              pt="10px"
              pb="10px"
              w="100%"
              direction={{ base: 'column', sm: 'column', md: 'row', lg: 'row' }}
            >
              <Box
                w="100%"
                pr={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}
              >
                <Controller
                  name="heartRate"
                  control={control}
                  defaultValue=""
                  render={props => (
                    <FormControl>
                      <FormLabel>Heart Rate</FormLabel>
                      <Input
                        // _readOnly={{
                        //   border: '1px solid #ccc',
                        // }}
                        // variant="no-effects"
                        // readOnly
                        // bg="#F9F9F9"
                        // border="1px solid black"
                        w={{
                          base: '100%',
                          sm: '100%',
                          md: '100%',
                        }}
                        value={props.value}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                      />
                    </FormControl>
                  )}
                />
              </Box>
              <Box
                w="100%"
                pt={{ base: '10px', md: '0px' }}
                pl={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}
              >
                <Controller
                  name="bloodPressure"
                  control={control}
                  defaultValue=""
                  render={props => (
                    <FormControl>
                      <FormLabel>Blood Pressure</FormLabel>
                      <Input
                        // _readOnly={{
                        //   border: '1px solid #ccc',
                        // }}
                        // variant="no-effects"
                        // readOnly
                        // bg="#F9F9F9"
                        // border="1px solid black"
                        w={{
                          base: '100%',
                          sm: '100%',
                          md: '100%',
                        }}
                        value={props.value}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                      />
                    </FormControl>
                  )}
                />
              </Box>
            </Flex>

            <Flex
              pt="10px"
              w="100%"
              pb="10px"
              direction={{ base: 'column', sm: 'column', md: 'row', lg: 'row' }}
            >
              <Box
                w="100%"
                pr={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}
              >
                <Controller
                  name="temp"
                  control={control}
                  defaultValue=""
                  render={props => (
                    <FormControl>
                      <FormLabel>temp (F)</FormLabel>
                      <Input
                        // _readOnly={{
                        //   border: '1px solid #ccc',
                        // }}
                        // variant="no-effects"
                        // readOnly
                        // bg="#F9F9F9"
                        // border="1px solid black"
                        w={{
                          base: '100%',
                          sm: '100%',
                          md: '100%',
                        }}
                        value={props.value}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                      />
                    </FormControl>
                  )}
                />
              </Box>

              <Box
                w="100%"
                pt={{ base: '10px', md: '0px' }}
                pl={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}
              >
                <Controller
                  name="pulse"
                  control={control}
                  defaultValue=""
                  render={props => (
                    <FormControl>
                      <FormLabel>pulse</FormLabel>
                      <Input
                        // _readOnly={{
                        //   border: '1px solid #ccc',
                        // }}
                        // variant="no-effects"
                        // readOnly
                        // bg="#F9F9F9"
                        // border="1px solid black"
                        w={{
                          base: '100%',
                          sm: '100%',
                          md: '100%',
                        }}
                        value={props.value}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                      />
                    </FormControl>
                  )}
                />
              </Box>
            </Flex>

            <Flex
              pt="10px"
              pb="10px"
              w="100%"
              direction={{ base: 'column', sm: 'column', md: 'row', lg: 'row' }}
            >
              <Box
                w="100%"
                pr={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}
              >
                <Controller
                  name="oxFrom2"
                  control={control}
                  defaultValue=""
                  render={props => (
                    <FormControl>
                      <FormLabel>Ox (%)</FormLabel>
                      <Input
                        // _readOnly={{
                        //   border: '1px solid #ccc',
                        // }}
                        // variant="no-effects"
                        // readOnly
                        // bg="#F9F9F9"
                        // border="1px solid black"
                        w={{
                          base: '100%',
                          sm: '100%',
                          md: '100%',
                        }}
                        value={props.value}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                      />
                    </FormControl>
                  )}
                />
              </Box>
              <Box
                w="100%"
                pt={{ base: '10px', md: '0px' }}
                pl={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}
              >
                <Controller
                  name="respFrom2"
                  control={control}
                  defaultValue=""
                  render={props => (
                    <FormControl>
                      <FormLabel>Resp</FormLabel>
                      <Input
                        // _readOnly={{
                        //   border: '1px solid #ccc',
                        // }}
                        // variant="no-effects"
                        // readOnly
                        // bg="#F9F9F9"
                        // border="1px solid black"
                        w={{
                          base: '100%',
                          sm: '100%',
                          md: '100%',
                        }}
                        value={props.value}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                      />
                    </FormControl>
                  )}
                />
              </Box>
            </Flex>
            <Flex w="100%" justifyContent="flex-end">
              <Button
                colorScheme="blue"
                type="submit"
                isLoading={isLoading}
                loadingText="loading..."
              >
                submit
              </Button>
            </Flex>
          </form>
        </Box>
      </Box>
      <Center pt="2rem">{`3/${totalNonWebEnabledPage}`}</Center>
    </CardContent>
  )
}

export { NewPatientAdmission }
