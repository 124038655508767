import { ChevronRightIcon, ChevronUpIcon } from '@chakra-ui/icons'
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Center,
  Checkbox,
  CheckboxGroup,
  Container,
  Fade,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Input,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react'
import moment from 'moment'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link, useParams } from 'react-router-dom'
import { CardContent } from '../../../../Components/Card/card'
import { SwitchInputComponent } from '../switchInput/index'
import { FaCloudDownloadAlt } from 'react-icons/fa'

const _ = require('underscore')

function InputComponents({ props, type }) {
  return (
    <Input
      type={type ? type : 'text'}
      _readOnly={{
        border: '1px solid #ccc',
      }}
      variant="no-effects"
      readOnly
      bg="#F9F9F9"
      border="1px solid black"
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
    />
  )
}

function FormMedicalRecord({ formView }) {
  const { control, setValue, clearErrors } = useForm()
  const [scrollY, setScrollY] = useState(0)
  const params = useParams()
  useEffect(() => {
    if (formView) {
      const data = formView
      _.keys(data).map(e => {
        return setValue(e, data[e])
      })
    }
  }, [formView, setValue])

  useLayoutEffect(() => {
    function updatePosition() {
      setScrollY(window.pageYOffset)
    }
    window.addEventListener('scroll', updatePosition)
    updatePosition()
    return () => window.removeEventListener('scroll', updatePosition)
  }, [])

  return (
    <Container maxW="100%" mt="5">
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
        bg="gray.100"
        p="1.5"
        borderRadius="0.3em"
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/dashboard">
            Dashbaord
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/fillable">
            Fillable
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink>
            {formView.namePatient || formView.namepatientForm3}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex w="100%" justifyContent="flex-end" pt="1rem">
        <Button
          onClick={() => {
            window.open(`/fillable/view/${params.id}/pdf/MedicalRecord`)
          }}
          leftIcon={<FaCloudDownloadAlt />}
          size="sm"
        >
          PDF
        </Button>
      </Flex>
      <Center bg="" w="100%" minH="100vh" pt="3rem" pb="4rem">
        <CardContent fontFamily="Inter">
          <Text>
            This authorization must be filled-out, dated and signed by the
            patient or by a person authorized by law to give authorization.
          </Text>
          <Box pt="20px">
            <Controller
              render={props => (
                <FormControl>
                  <FormLabel>I authorize</FormLabel>
                  <InputComponents props={props} type={'text'} />
                </FormControl>
              )}
              name="authorizeForm3"
              control={control}
              defaultValue=""
            />
          </Box>
          <Text pt="10px" pl="5px">
            to release a copy of the medical information for
          </Text>

          <HStack
            pt="10px"
            alignItems="flex-start"
            flexDirection={{
              base: 'column',
              sm: 'column',
              md: 'row',
              lg: 'row',
            }}
            spacing={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}
          >
            <Controller
              render={props => (
                <FormControl>
                  <FormLabel>Name of Patient</FormLabel>
                  <InputComponents props={props} type={'text'} />
                </FormControl>
              )}
              name="namepatientForm3"
              control={control}
              defaultValue=""
              rules={{
                required: {
                  value: true,
                  message: 'Please enter your name of patient.',
                },
              }}
            />

            <Controller
              render={props => (
                <FormControl>
                  <FormLabel>DOB (mm/dd/yy)</FormLabel>
                  <InputComponents props={props} type={'date'} />
                </FormControl>
              )}
              name="authorizationDOBForm3"
              control={control}
              defaultValue={moment(new Date()).format('yyyy-MM-DD')}
            />
          </HStack>

          <HStack
            pt="10px"
            flexDirection={{
              base: 'column',
              sm: 'column',
              md: 'row',
              lg: 'row',
            }}
            spacing={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}
          >
            <Controller
              name="namerecipientForm3"
              control={control}
              defaultValue=""
              render={props => (
                <FormControl>
                  <FormLabel>to (Name of Recipient)</FormLabel>
                  <InputComponents props={props} type={'text'} />
                </FormControl>
              )}
            />

            <Controller
              render={props => (
                <FormControl>
                  <FormLabel>Mailling Address</FormLabel>
                  <InputComponents props={props} type={'text'} />
                </FormControl>
              )}
              name="maillingaddressForm3"
              control={control}
              defaultValue=""
            />
          </HStack>

          <HStack
            pt="10px"
            flexDirection={{
              base: 'column',
              sm: 'column',
              md: 'row',
              lg: 'row',
            }}
            spacing={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}
          >
            <Controller
              render={props => (
                <FormControl>
                  <FormLabel>or Fax No.</FormLabel>
                  <InputComponents props={props} type={'text'} />
                </FormControl>
              )}
              name="faxnoForm3"
              control={control}
              defaultValue=""
            />
            <Box w="100%"></Box>
          </HStack>

          <Box pt="10px">
            <Controller
              name="informationForm3"
              control={control}
              defaultValue=""
              render={props => (
                <FormControl>
                  <FormLabel>
                    The information will be used on my behalf for the following
                    purpose(s)
                  </FormLabel>
                  <Textarea
                    _readOnly={{
                      border: '1px solid #ccc',
                    }}
                    variant="no-effects"
                    readOnly
                    bg="#F9F9F9"
                    border="1px solid black"
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                  />
                </FormControl>
              )}
            />
          </Box>
          <Text pt="20px">
            By initialing the spaces below, I specifically authorize the release
            of the following records, if such records exist:
          </Text>
          <Flex pt="20px">
            <Controller
              name="racordsForm3"
              control={control}
              render={props => (
                <FormControl>
                  <CheckboxGroup
                    onChange={e => {
                      setValue('racordsForm3', e)
                      clearErrors('racordsForm3')
                    }}
                    value={props.value}
                    onBlur={props.onBlur}
                    w="100%"
                  >
                    <Grid
                      w="100%"
                      templateColumns={{
                        base: 'repeat(1,1fr)',
                        sm: 'repeat(1,1fr)',
                        md: 'repeat(1,1fr)',
                        lg: 'repeat(1, 1fr)',
                      }}
                      gap={4}
                    >
                      <GridItem w="100%">
                        <VStack alignItems="flex-start">
                          <Checkbox value="hospitalrecord" isDisabled>
                            All hospital records (including nursing records and
                            progress notes)
                          </Checkbox>

                          <Checkbox value="transcribreports" isDisabled>
                            Transcribed hospital reports
                          </Checkbox>

                          <Checkbox value="medicalrecord" isDisabled>
                            Medical records needed for continuity of care
                          </Checkbox>

                          <Checkbox value="yearhistory" isDisabled>
                            Most recent five year history
                          </Checkbox>

                          <Checkbox value="laboratory" isDisabled>
                            Laboratory reports
                          </Checkbox>

                          <Checkbox value="pathology" isDisabled>
                            Pathology reports
                          </Checkbox>

                          <Checkbox value="diagnostic" isDisabled>
                            Diagnostic imaging reports
                          </Checkbox>

                          <Checkbox value="clinician" isDisabled>
                            Clinician office chart notes
                          </Checkbox>

                          <Checkbox value="dental" isDisabled>
                            Dental records
                          </Checkbox>

                          <Checkbox value="therapy" isDisabled>
                            Physical Therapy records
                          </Checkbox>

                          <Checkbox value="care" isDisabled>
                            Emergency and urgency care records
                          </Checkbox>

                          <Checkbox value="statement" isDisabled>
                            Billing Statements
                          </Checkbox>

                          <HStack>
                            <Checkbox value="Other" isDisabled />
                            <Controller
                              render={props => (
                                <FormControl>
                                  <Input
                                    w={{ base: '50vw', md: '20vw' }}
                                    placeholder="Other"
                                    _readOnly={{
                                      border: '1px solid #ccc',
                                    }}
                                    variant="no-effects"
                                    readOnly
                                    bg="#F9F9F9"
                                    border="1px solid black"
                                    value={props.value}
                                    onChange={props.onChange}
                                    onBlur={props.onBlur}
                                  />
                                </FormControl>
                              )}
                              name="other"
                              control={control}
                              defaultValue=""
                            />
                          </HStack>
                        </VStack>
                      </GridItem>
                    </Grid>
                  </CheckboxGroup>
                </FormControl>
              )}
            />
          </Flex>
          <Text pt="20px">
            Please send the entire medical record (all information) to the above
            named recipient. The recipient understands this record may be
            voluminous and agrees to pay all reasonable charges associated with
            providing this record.
          </Text>
          <Flex pt="20px">
            <Controller
              render={props => (
                <CheckboxGroup
                  onChange={props.onChange}
                  value={props.value}
                  w="100%"
                >
                  <Grid
                    w="100%"
                    templateColumns={{
                      base: 'repeat(1,1fr)',
                      sm: 'repeat(1,1fr)',
                      md: 'repeat(1,1fr)',
                      lg: 'repeat(1, 1fr)',
                    }}
                    gap={4}
                  >
                    <GridItem w="100%">
                      <VStack alignItems="flex-start">
                        <Checkbox value="hiv" isDisabled>
                          *HIV/AIDS-related records
                        </Checkbox>

                        <Checkbox value="genetic" isDisabled>
                          *Genetic testing information
                        </Checkbox>
                      </VStack>
                    </GridItem>
                  </Grid>
                </CheckboxGroup>
              )}
              name="racords"
              control={control}
            />
          </Flex>
          <Text pt="10px">
            *Must be specifically selected to be included in other documents.
          </Text>

          <Flex pt="10px">
            <Controller
              render={props => (
                <CheckboxGroup
                  onChange={props.onChange}
                  value={props.value}
                  w="100%"
                >
                  <Grid
                    w="100%"
                    templateColumns={{
                      base: 'repeat(1,1fr)',
                      sm: 'repeat(1,1fr)',
                      md: 'repeat(1,1fr)',
                      lg: 'repeat(1, 1fr)',
                    }}
                    gap={4}
                  >
                    <GridItem w="100%">
                      <VStack alignItems="flex-start">
                        <Checkbox value="drug" isDisabled>
                          **Drug/Alcohol diagnosis, treatment or referral
                          information
                        </Checkbox>

                        <HStack pl="1.5rem">
                          <Controller
                            name="otherDrug"
                            control={control}
                            defaultValue=""
                            render={props => (
                              <FormControl>
                                <Input
                                  _readOnly={{
                                    border: '1px solid #ccc',
                                  }}
                                  variant="no-effects"
                                  readOnly
                                  bg="#F9F9F9"
                                  border="1px solid black"
                                  w={{ base: '50vw', md: '20vw' }}
                                  value={props.value}
                                  onChange={props.onChange}
                                  onBlur={props.onBlur}
                                />
                              </FormControl>
                            )}
                          />
                        </HStack>
                      </VStack>
                    </GridItem>
                  </Grid>
                </CheckboxGroup>
              )}
              name="racords"
              control={control}
            />
          </Flex>
          <Text pt="10px">
            **Federal Regulation 42 CFR Part 2, requires a description of how
            much and what kind of information is to be disclosed.
          </Text>
          <Flex pt="10px">
            <Controller
              name="federal"
              control={control}
              render={props => (
                <CheckboxGroup
                  onChange={props.onChange}
                  value={props.value}
                  w="100%"
                >
                  <Grid
                    w="100%"
                    templateColumns={{
                      base: 'repeat(1,1fr)',
                      sm: 'repeat(1,1fr)',
                      md: 'repeat(1,1fr)',
                      lg: 'repeat(1, 1fr)',
                    }}
                    gap={4}
                  >
                    <GridItem w="100%">
                      <VStack alignItems="flex-start">
                        <Checkbox value="treatment" isDisabled>
                          This authorization is limited to the following
                          treatment
                        </Checkbox>

                        <HStack pl="1.5rem">
                          <Controller
                            name="otherFederal"
                            control={control}
                            defaultValue=""
                            render={props => (
                              <FormControl>
                                <Input
                                  _readOnly={{
                                    border: '1px solid #ccc',
                                  }}
                                  variant="no-effects"
                                  readOnly
                                  bg="#F9F9F9"
                                  border="1px solid black"
                                  w={{ base: '50vw', md: '20vw' }}
                                  value={props.value}
                                  onChange={props.onChange}
                                  onBlur={props.onBlur}
                                />
                              </FormControl>
                            )}
                          />
                        </HStack>
                      </VStack>
                    </GridItem>
                  </Grid>
                </CheckboxGroup>
              )}
            />
          </Flex>
          <Flex pt="10px">
            <Controller
              name="period"
              control={control}
              render={props => (
                <CheckboxGroup
                  onChange={props.onChange}
                  value={props.value}
                  w="100%"
                >
                  <Grid
                    w="100%"
                    templateColumns={{
                      base: 'repeat(1,1fr)',
                      sm: 'repeat(1,1fr)',
                      md: 'repeat(1,1fr)',
                      lg: 'repeat(1, 1fr)',
                    }}
                    gap={4}
                  >
                    <GridItem w="100%">
                      <VStack alignItems="flex-start">
                        <Checkbox value="limited" isDisabled>
                          This authorization is limited to the time period
                        </Checkbox>

                        <HStack pl="1.5rem">
                          <Controller
                            name="otherperiod"
                            control={control}
                            defaultValue=""
                            render={props => (
                              <FormControl>
                                <Input
                                  _readOnly={{
                                    border: '1px solid #ccc',
                                  }}
                                  variant="no-effects"
                                  readOnly
                                  bg="#F9F9F9"
                                  border="1px solid black"
                                  w={{ base: '50vw', md: '20vw' }}
                                  value={props.value}
                                  onChange={props.onChange}
                                  onBlur={props.onBlur}
                                />
                              </FormControl>
                            )}
                          />
                        </HStack>
                      </VStack>
                    </GridItem>
                  </Grid>
                </CheckboxGroup>
              )}
            />
          </Flex>

          <Flex pt="10px">
            <Controller
              name="injuries"
              control={control}
              render={props => (
                <CheckboxGroup
                  onChange={props.onChange}
                  value={props.value}
                  w="100%"
                >
                  <Grid
                    w="100%"
                    templateColumns={{
                      base: 'repeat(1,1fr)',
                      sm: 'repeat(1,1fr)',
                      md: 'repeat(1,1fr)',
                      lg: 'repeat(1, 1fr)',
                    }}
                    gap={4}
                  >
                    <GridItem w="100%">
                      <VStack alignItems="flex-start">
                        <Checkbox value="worker" isDisabled>
                          This authorization is limited to a worker's
                          compensation claim for injuries of
                        </Checkbox>

                        <HStack pl="1.5rem">
                          <Controller
                            name="otherinjuries"
                            control={control}
                            defaultValue=""
                            render={props => (
                              <FormControl>
                                <Input
                                  _readOnly={{
                                    border: '1px solid #ccc',
                                  }}
                                  variant="no-effects"
                                  readOnly
                                  bg="#F9F9F9"
                                  border="1px solid black"
                                  w={{ base: '50vw', md: '20vw' }}
                                  value={props.value}
                                  onChange={props.onChange}
                                  onBlur={props.onBlur}
                                />
                              </FormControl>
                            )}
                          />
                        </HStack>
                      </VStack>
                    </GridItem>
                  </Grid>
                </CheckboxGroup>
              )}
            />
          </Flex>
          <Text pt="20px">
            This authorization may be revoked at any time. The only exception is
            when action has been taken in reliance on the authorization, unless
            revoked earlier, this consent will expire 180 days from the date of
            signing or shall remain in effect for the period reasonably needed
            to complete the request.
          </Text>

          <Grid
            templateColumns={{ base: 'repeat(1, 1fr)', xl: 'repeat(2, 1fr)' }}
            alignItems="start"
            gap={0}
            h="max-content"
          >
            <Controller
              name="patient "
              control={control}
              defaultValue=""
              render={props => (
                <FormControl>
                  <GridItem
                    onChange={props.onChange}
                    value={props.value}
                    onBlur={props.onBlur}
                    w="100%"
                  >
                    <VStack spacing="0px">
                      <Center h={{ base: '80px' }}>
                        <Text fontWeight="bold" fontSize="15">
                          Signature of patient
                        </Text>
                      </Center>
                      <HStack h="40px" w="100%" flexDirection="row">
                        <Center w="100%">
                          <Flex ml="0px">
                            <SwitchInputComponent
                              formView={formView.signaturePatient || ' '}
                            />
                          </Flex>
                        </Center>
                      </HStack>
                    </VStack>

                    <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
                  </GridItem>
                </FormControl>
              )}
            />

            <GridItem w="100%">
              <VStack spacing="0px">
                <Center h={{ base: '80px' }}>
                  <Text fontWeight="bold" fontSize="15">
                    Date
                  </Text>
                </Center>

                <Center h="40px">
                  <Text>
                    {new Date().toLocaleDateString('en-US', { hour12: false })}
                  </Text>
                </Center>
              </VStack>
              <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
            </GridItem>
          </Grid>

          <Grid
            templateColumns={{ base: 'repeat(1, 1fr)', xl: 'repeat(2, 1fr)' }}
            alignItems="start"
            gap={0}
            h="max-content"
          >
            <Controller
              name="person"
              control={control}
              defaultValue=""
              render={props => (
                <FormControl>
                  <GridItem
                    onChange={props.onChange}
                    value={props.value}
                    onBlur={props.onBlur}
                    w="100%"
                  >
                    <VStack spacing="0px">
                      <Center h={{ base: '80px' }}>
                        <Text fontWeight="bold" fontSize="15">
                          Signature of person authorized by law
                        </Text>
                      </Center>
                      <HStack h="40px" w="100%" flexDirection="row">
                        <Center w="100%">
                          <Flex ml="0px">
                            <SwitchInputComponent
                              formView={formView.signaturePatientLaw || ' '}
                            />
                          </Flex>
                        </Center>
                        <Flex
                          h="40px"
                          alignItems="flex-end"
                          justifyContent="flex-end"
                        ></Flex>
                      </HStack>
                    </VStack>

                    <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
                  </GridItem>
                  <Center></Center>
                </FormControl>
              )}
            />

            <GridItem w="100%">
              <VStack spacing="0px">
                <Center h={{ base: '80px' }}>
                  <Text fontWeight="bold" fontSize="15">
                    Date
                  </Text>
                </Center>

                <Center h="40px">
                  <Text>
                    {new Date().toLocaleDateString('en-US', { hour12: false })}
                  </Text>
                </Center>
              </VStack>
              <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
            </GridItem>
          </Grid>
        </CardContent>
      </Center>
      {scrollY > 1000 && (
        <Box
          as={Fade}
          in={scrollY > 1100 ? true : false}
          position="fixed"
          bottom="10px"
          right="10px"
        >
          <Button
            w={{ base: '3rem', sm: '3rem', md: '3.5rem' }}
            colorScheme="gray"
            color="#5fc2ff"
            _focus={{ border: '0px solid transparent' }}
            fontSize={{ base: '24px', sm: '24px', md: '30px' }}
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          >
            <ChevronUpIcon />
          </Button>
        </Box>
      )}
    </Container>
  )
}

export { FormMedicalRecord }
