import { Text, View } from '@react-pdf/renderer'
import React from 'react'

function System({ styles }) {
  return (
    <View
      style={{
        width: '100%',
        gap: '10px',
      }}
    >
      <View>
        <Text style={{ lineHeight: 1.5, textAlign: 'justify' }}>
          <Text>
            in order to obtain an accounting you must submit your request in
            writing to the Privacy Officer 2480 Liberty St NE, Ste 180, Salem,
            OR 97301. All requests for "accounting of disclosures" must state a
            time period. Our practice will notify you of the costs involved with
            additional requests, and you may withdraw your request before you
            incur any costs.
          </Text>
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.5, textAlign: 'justify' }}>
          <Text style={styles.title}>Right to Paper Copy of This Notice.</Text>
          {` `}
          <Text>
            You are entitled to receive a paper copy of our Notice of Privacy
            Practices. You will be offered a copy on your first visit to the
            practice. You may ask us to give you a copy of this notice at any
            time. To obtain a paper copy of this notice, contact our Privacy
            Officer at (503) 371-1010.
          </Text>
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.5, textAlign: 'justify' }}>
          <Text style={styles.title}>Right to File a Complaint.</Text>
          {` `}
          <Text>
            If you believe your privacy rights have been violated, you may file
            a complaint with our practice or with our Secretary of the
            Department of Health and Human Service Office of Civil Rights, 500
            Summer St NE, Salem, Oregon 97301, (503) 945-5944. All complaints
            must be submitted in writing. You will not be penalized for filing a
            complaint.
          </Text>
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.5, textAlign: 'justify' }}>
          <Text style={styles.title}>
            Right to Provide and Authorization for Other Uses and Disclosures.
          </Text>
          {` `}
          <Text>
            Our practice will obtain your written authorization for uses and
            disclosures that are not covered by this notice or permitted by law,
            such as for research or marketing. Any authorization you provide to
            us regarding the use and disclosure of your PHI may be revoked at
            any time in writing. After you revoke your PHI for the reasons
            described in the authorization, we are required to retain records
            for your care.
          </Text>
        </Text>
      </View>
    </View>
  )
}

export default System
