import React from 'react'
import {
  Document,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer'
import moment from 'moment'

function PDFFormMedicalRecord({ fillableData }) {
  const styles = StyleSheet.create({
    pdfView: {
      width: window.innerWidth,
      height: window.innerHeight,
      backgroundColor: '#fff',
    },
    page: {
      width: '100%',
      flexDirection: 'column',
      backgroundColor: '#fff',
      alignItems: 'center',
      paddingLeft: 30,
      paddingRight: 30,
      paddingTop: 30,
      paddingBottom: 30,
    },
    sectionImg: {
      width: '100%',
      padding: 0,
      alignItems: 'center',
    },
    img: {
      width: '150px',
      height: 'auto',
      objectFit: 'cover',
    },
    boxtitle: {
      paddingTop: 2,
      fontSize: 10,
    },
    textBorderCenter: {
      borderBottom: 1,
      width: '250px',
      textAlign: 'center',
    },
    rowBox: {
      fontSize: 10,
      flexDirection: 'row',
    },
    textBorderCenterPatient: {
      borderBottom: 1,
      width: '400px',
      textAlign: 'center',
    },
    textAddress: {
      borderBottom: 1,
      width: '110px',
      textAlign: 'center',
    },
    columnBox: {
      fontSize: 10,
      flexDirection: 'column',
    },
    columnBoxBorder: {
      borderBottom: 1,
      width: '500px',
    },
    choiceContainer: {
      paddingTop: 10,
      width: '545px',
      paddingLeft: '20px',
      flexDirection: 'column',
      justifyContent: 'start',
      alignContent: 'start',
      gap: '2px',
      fontSize: 10,
    },
    trueFalseChoiceContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: '6px',
      fontSize: 10,
    },
    trueFalseChoicewidth: {
      flexDirection: 'row',
      width: '300px',
      alignItems: 'center',
      gap: '6px',
      fontSize: 10,
    },
    trueDrugwidth: {
      width: '220px',
      textAlign: 'center',
      fontSize: 10,
      borderBottom: 1,
    },
    authorizationwidth: {
      width: '239px',
      textAlign: 'center',
      fontSize: 10,
      borderBottom: 1,
    },
    periodwidth: {
      width: '273px',
      textAlign: 'center',
      fontSize: 10,
      borderBottom: 1,
    },
    injurieswidth: {
      width: '143px',
      textAlign: 'center',
      fontSize: 10,
      borderBottom: 1,
    },
    trueFalseChoiceRow: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: '6px',
      fontSize: 10,
    },
    stylesCheckImage: {
      border: 1,
      width: '19px',
      height: '18px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    imgcheck: {
      width: '12.5px',
      height: '12.5px',
    },
    sectionfour: {
      width: '500px',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    signatureImg: {
      height: '25px',
    },
  })
  return (
    <PDFViewer style={styles.pdfView}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.sectionImg}>
            <Image style={styles.img} src="/paincare-web.png" />
          </View>
          <View style={styles.boxtitle}>
            <Text>2480 Liberty Street NE, Suite 180, Salem, OR 97301</Text>
          </View>
          <View style={styles.boxtitle}>
            <Text>Office: (503) 371-1010 Fax: (503) 371-0805</Text>
          </View>
          <View style={{ fontSize: 10, paddingTop: 20 }}>
            <Text>
              This authorization must be completed, dated and signed by the
              patient or by a person authorized by law to give authorization.
            </Text>
          </View>
          <View>
            <View style={{ ...styles.rowBox, paddingTop: '20px' }}>
              <Text>I authorize</Text>
              <Text style={styles.textBorderCenter}>
                {fillableData?.authorizeForm3
                  ? fillableData?.authorizeForm3
                  : ''}
              </Text>
              <Text>to release a copy of the medical information for</Text>
            </View>
            <View style={{ ...styles.rowBox, paddingTop: '5px' }}>
              <Text style={styles.textBorderCenterPatient}>
                {fillableData?.authorizationDOBForm3
                  ? moment(fillableData?.authorizationDOBForm3).format(
                      'MM/DD/YYYY'
                    )
                  : ''}
              </Text>
              <Text>(Name of Patient/DOB)</Text>
            </View>
            <View style={{ ...styles.rowBox, paddingTop: '5px' }}>
              <Text>to</Text>
              <Text style={styles.textBorderCenterPatient}>
                {fillableData?.namepatientForm3
                  ? fillableData?.namepatientForm3
                  : ''}
              </Text>
              <Text>(Name of Recipient)</Text>
            </View>
            <View style={{ ...styles.rowBox, paddingTop: '5px' }}>
              <Text>Mailing Address</Text>
              <Text style={styles.textBorderCenter}>
                {fillableData?.maillingaddressForm3
                  ? fillableData?.maillingaddressForm3
                  : ''}
              </Text>
              <Text>or Fax Number</Text>
              <Text style={styles.textAddress}>
                {fillableData?.faxnoForm3 ? fillableData?.faxnoForm3 : ''}
              </Text>
            </View>
            <View style={{ ...styles.columnBox, paddingTop: '20px' }}>
              <Text>
                The information will be used on my behalf for the following
                purpose(s):
              </Text>
              <Text style={{ ...styles.columnBoxBorder, paddingTop: '4px' }}>
                {fillableData?.informationForm3
                  ? fillableData?.informationForm3
                  : ''}
              </Text>
            </View>
          </View>
          <View style={styles.choiceContainer}>
            <Text>
              By initialing the spaces below, I specifically authorize the
              release of the following records, if such records exist:
            </Text>
            <View style={{ ...styles.trueFalseChoiceContainer, paddingTop: 6 }}>
              <View style={styles.stylesCheckImage}>
                {fillableData?.racordsForm3.includes('hospitalrecord') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>
                All hospital records (including nursing records and progress
                notes)
              </Text>
            </View>
            <View style={styles.trueFalseChoiceRow}>
              <View style={styles.trueFalseChoicewidth}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.racordsForm3.includes('transcribreports') && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>Transcribed hospital reports</Text>
              </View>
              <View style={styles.trueFalseChoiceContainer}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.racordsForm3.includes('clinician') && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>Clinician office chart notes</Text>
              </View>
            </View>
            <View style={styles.trueFalseChoiceRow}>
              <View style={styles.trueFalseChoicewidth}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.racordsForm3.includes('medicalrecord') && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>Medical records needed for continuity of care</Text>
              </View>
              <View style={styles.trueFalseChoiceContainer}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.racordsForm3.includes('dental') && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>Dental records</Text>
              </View>
            </View>
            <View style={styles.trueFalseChoiceRow}>
              <View style={styles.trueFalseChoicewidth}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.racordsForm3.includes('yearhistory') && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>Most recent five year history</Text>
              </View>
              <View style={styles.trueFalseChoiceContainer}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.racordsForm3.includes('therapy') && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>Physical Therapy records</Text>
              </View>
            </View>
            <View style={styles.trueFalseChoiceRow}>
              <View style={styles.trueFalseChoicewidth}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.racordsForm3.includes('laboratory') && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>Laboratory reports</Text>
              </View>
              <View style={styles.trueFalseChoiceContainer}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.racordsForm3.includes('care') && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>Emergency and urgency care records</Text>
              </View>
            </View>
            <View style={styles.trueFalseChoiceRow}>
              <View style={styles.trueFalseChoicewidth}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.racordsForm3.includes('pathology') && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>Pathology reports</Text>
              </View>
              <View style={styles.trueFalseChoiceContainer}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.racordsForm3.includes('statement') && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>Billing statements</Text>
              </View>
            </View>
            <View style={styles.trueFalseChoiceRow}>
              <View style={styles.trueFalseChoicewidth}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.racordsForm3.includes('diagnostic') && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>Diagnostic imaging reports</Text>
              </View>
              <View style={styles.trueFalseChoiceContainer}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.other !== '' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>Other</Text>
              </View>
            </View>
          </View>
          <View style={styles.choiceContainer}>
            <Text style={{ paddingTop: 4 }}>
              Please send the entire medical record (all information) to the
              above named recipient. The recipient understands this record may
              be voluminous and agrees to pay all reasonable charges associated
              with providing this record.
            </Text>
            <View style={{ ...styles.trueFalseChoiceContainer, paddingTop: 6 }}>
              <View style={styles.stylesCheckImage}>
                {fillableData?.racords.includes('hiv') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>*HIV/AIDS-related records</Text>
            </View>
            <View style={styles.trueFalseChoiceContainer}>
              <View style={styles.stylesCheckImage}>
                {fillableData?.racords.includes('genetic') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>*Genetic testing information</Text>
            </View>
            <Text style={{ fontSize: 8, paddingTop: 2 }}>
              *Must be specifically selected to be included in other documents.
            </Text>
            <View
              style={{
                ...styles.trueFalseChoiceContainer,
                paddingTop: 10,
                alignItems: 'flex-end',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.racords.includes('drug') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>
                **Drug/Alcohol diagnosis, treatment or referral information
              </Text>
              <Text style={styles.trueDrugwidth}>
                {fillableData.otherDrug ? fillableData.otherDrug : ''}
              </Text>
            </View>
            <Text style={{ fontSize: 8, paddingTop: 2 }}>
              **Federal Regulation 42 CFR Part 2, requires a description of how
              much and what kind of information is to be disclosed.
            </Text>
            <View
              style={{
                ...styles.trueFalseChoiceContainer,
                paddingTop: 8,
                alignItems: 'flex-end',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.federal.includes('treatment') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>
                This authorization is limited to the following treatment:
              </Text>
              <Text style={styles.authorizationwidth}>
                {fillableData.otherFederal ? fillableData.otherFederal : ''}
              </Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoiceContainer,
                alignItems: 'flex-end',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.period.includes('limited') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>This authorization is limited to the time period:</Text>
              <Text style={styles.periodwidth}>
                {fillableData.otherperiod ? fillableData.otherperiod : ''}
              </Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoiceContainer,
                alignItems: 'flex-end',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.injuries.includes('worker') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>
                This authorization is limited to a worker’s compensation claim
                for injuries of:
              </Text>
              <Text style={styles.injurieswidth}>
                {fillableData.otherinjuries ? fillableData.otherinjuries : ''}
              </Text>
            </View>
            <Text style={{ width: '500px', paddingTop: 14, paddingRight: 8 }}>
              This authorization may be revoked at any time. The only exception
              is when action has been taken in reliance on the authorization,
              unless revoked earlier, this consent will expire 180 days from the
              date of signing or shall remain in effect for the period
              reasonably needed to complete the request.
            </Text>
            {/* <View> */}
            <View style={{ ...styles.sectionfour, paddingTop: 2 }}>
              <View
                style={{
                  width: '150px',
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Text style={{ borderBottom: 1, paddingBottom: 2 }}>
                  {fillableData?.createdAt
                    ? moment(fillableData?.createdAt).format('MM/DD/YYYY')
                    : ' '}
                </Text>
                <Text style={{ paddingTop: 2 }}>
                  <Text>(Date) </Text>
                </Text>
              </View>
              <View
                style={{
                  width: '150px',
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <View style={{ borderBottom: 1, paddingBottom: 2 }}>
                  <Image
                    style={styles.signatureImg}
                    src={`${fillableData?.signaturePatient}`}
                  />
                </View>
                <View style={{ paddingTop: 2 }}>
                  <Text>(Signature of patient)</Text>
                </View>
              </View>
            </View>
            <View style={{ ...styles.sectionfour, paddingTop: 4 }}>
              <View
                style={{
                  width: '150px',
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Text style={{ borderBottom: 1, paddingBottom: 2 }}>
                  {fillableData?.createdAt
                    ? moment(fillableData?.createdAt).format('MM/DD/YYYY')
                    : ' '}
                </Text>
                <Text style={{ paddingTop: 2 }}>
                  <Text>(Date) </Text>
                </Text>
              </View>
              <View
                style={{
                  width: '150px',
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <View style={{ borderBottom: 1, paddingBottom: 2 }}>
                  <Image
                    style={styles.signatureImg}
                    src={fillableData?.signaturePatientLaw}
                  />
                </View>
                <View style={{ paddingTop: 2 }}>
                  <Text>(Signature of patient)</Text>
                </View>
              </View>
            </View>
          </View>

          {/* </View> */}
        </Page>
      </Document>
    </PDFViewer>
  )
}

export default PDFFormMedicalRecord
